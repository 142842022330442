import { Navigate, Route, Routes } from "react-router-dom";

import { EmployeeProvider } from "@contexts/employee";

// Pages
import { ListEmployees } from "./ListEmployees";
import { AddEditEmployees } from "./AddEditEmployees";

export function Employees() {
  return (
    <EmployeeProvider>
      <Routes>
        <Route path="/" element={<Navigate to="listagem" />} />
        <Route path="listagem" element={<ListEmployees />} />
        <Route path="adicionar/*" element={<AddEditEmployees />} />
        <Route path="editar/:uuid/*" element={<AddEditEmployees />} />
      </Routes>
    </EmployeeProvider>
  );
}