import styled from "styled-components";
import chroma from "chroma-js";

import { convertToRem } from "@utils/convertToRem";


export const Icon = styled.span`
  position: absolute;
  top: ${convertToRem(15)};
  right: ${convertToRem(15)};

  width: ${convertToRem(60)};
  height: ${convertToRem(60)};

  > svg {
    width: auto;
    height: 100%;

    color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.1).css()};
  }
`;

export const Footer = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: space-between;
  
  width: 100%;
  height: ${convertToRem(40)};

  color: ${({ theme }) => theme.colors.primary.base};

  font-size: ${convertToRem(12)};
  line-height: ${convertToRem(20)};
  letter-spacing: ${convertToRem(0.2)};

  cursor: pointer;

  > span.line {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    width: 100%;
    height: ${convertToRem(1)};

    background-color: ${({ theme }) => theme.colors.neutral.border};
  }

  > svg {
    width: auto;
    height: ${convertToRem(20)};

    stroke-width: 1.2;
  }
`;

export const Details = styled.ul`
  width: 100%;
  max-height: 0;

  overflow: hidden;

  transition: all ease 0.5s;

  li {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      flex: 1;

      color: ${({ theme }) => theme.colors.neutral.black};

      font-size: ${convertToRem(12)};
      line-height: 1.2;
    }

    .value {
      margin-left: ${convertToRem(5)};

      color: ${({ theme }) => theme.colors.neutral.black};

      font-weight: 700;
      font-size: ${convertToRem(12)};
      line-height: ${convertToRem(13)};
      
      text-align: right;
    }

    &:not(:first-child) {
      margin-top: ${convertToRem(24)};
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
`;

export const Content = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: max-content;

  min-height: ${convertToRem(150)};

  padding: 0 ${convertToRem(20)};

  background-color: ${({ theme }) => chroma(theme.colors.neutral.border).alpha(0.2).css()};

  border-radius: ${convertToRem(5)};

  &.expanded {
    ${Details} {
      max-height: fit-content;

      padding: ${convertToRem(20)} 0 ${convertToRem(30)};
    }

    ${Footer} > svg {
      transform: rotate(180deg);
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;
  height: ${convertToRem(110)};

  padding: ${convertToRem(15)} 0;
`;

export const Title = styled.h4`
  max-width: ${convertToRem(120)};

  color: ${({ theme }) => theme.colors.neutral.text};

  font-weight: 400;
  font-size: ${convertToRem(13)};
  line-height: 1.2;
`;

export const Value = styled.h6`
  margin-top: auto;

  color: ${({ theme }) => theme.colors.neutral.text};

  font-weight: 500;
  font-size: ${convertToRem(24)};
  line-height: 1;
`;
