import { Route, Routes } from "react-router-dom";

import { Sidebar } from "@components/Sidebar";
import { Header } from "@components/Header";

import { Dashboard } from "@pages/ClinicAdmin/Dashboard";

import { Container, Main } from "./styles";

export function ClinicAdminRoutes() {
  return (
    <Container>
      <Sidebar />

      <Main>
        <Header />

        <Routes>
          <Route path="/" element={<Dashboard />} />
        </Routes>
      </Main>
    </Container>
  );
}
