import { useFormik } from "formik";
import * as Yup from "yup";
import { FiMinusCircle, FiPlusCircle } from "react-icons/fi";

import { Description, Title } from "@components/Header";
import { Switch } from "@components/Switch";
import { Input } from "@components/Input";
import { Button } from "@components/Button";

import { Content, ContentWrapper, FormContainer, HealthPlanContainer } from "./styles";

export function General() {
  const formik = useFormik({
    initialValues: {
      isAcceptedHealthPlan: false,
      healthPlans: [''],
    },
    validationSchema: Yup.object().shape({
      isAcceptedHealthPlan: Yup.boolean().required(),
      healthPlans: Yup.array().when("isAcceptedHealthPlan", {
        is: true,
        then: Yup.array().of(
          Yup.string().required("O campo é obrigatório")
        ),
        otherwise: Yup.array().notRequired(),
      })
    }),
    onSubmit: (values) => {
      if (!values.isAcceptedHealthPlan) {
        alert(JSON.stringify({ isAcceptedHealthPlan: false }));
        return;
      }
      
      alert(JSON.stringify(values));
    }
  });

  function handleChangePlan(position: number, value: string) {
    const updatedHealthPlans = [...formik.values.healthPlans].map((item, index) => {
      if (index !== position) return item;

      return value;
    });

    formik.setFieldValue("healthPlans", updatedHealthPlans, formik.submitCount > 0);
  }

  function handleAddPlan() {
    const updatedHealthPlans = [...formik.values.healthPlans];
    updatedHealthPlans.push('');

    formik.setFieldValue("healthPlans", updatedHealthPlans, false);
  }

  function handleRemovePlan(position: number) {
    const updatedHealthPlans = [...formik.values.healthPlans];
    updatedHealthPlans.splice(position, position + 1);

    formik.setFieldValue("healthPlans", updatedHealthPlans, false);
  }

  return (
    <Content>
      <ContentWrapper className="content">
        <Title>Gerais | Configurações</Title>
        <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam massa sapien velit ipsum, ac.
        </Description>

        <FormContainer onSubmit={formik.handleSubmit}>
          <Switch 
            label="Aceita Convênio"
            checked={formik.values.isAcceptedHealthPlan}
            onChange={e => formik.setFieldValue("isAcceptedHealthPlan", e.target.checked, false)}
          />

          {formik.values.isAcceptedHealthPlan && (
            <>
              <Button 
                variant="secondary"
                type="button"
                onClick={handleAddPlan}
              >
                <FiPlusCircle />
                Adicionar
              </Button>

              <HealthPlanContainer>
                {formik.values.healthPlans.map((plan, index, array) => (
                  <div key={index} className="health-plan">
                    <Input 
                      label="Convênio"
                      name={`healthvalidatePlan[${index}]`}
                      value={plan}
                      onChange={(e) => handleChangePlan(index, e.target.value)}
                      error={formik.errors.healthPlans && formik.errors.healthPlans[index]}
                    />

                    <div className="actions">
                      {array.length > 1 && (
                        <button 
                          type="button"
                          className="remove"
                          onClick={() => handleRemovePlan(index)}
                        >
                          <FiMinusCircle />
                        </button>
                      )}
                    </div>
                  </div>
                ))}
              </HealthPlanContainer>
            </>
          )}

          <Button type="submit">Atualizar</Button>
        </FormContainer>
      </ContentWrapper>
    </Content>
  );
}