import styled from "styled-components";
import { Link } from "react-router-dom";
import chroma from "chroma-js";

import { Container as Button } from "@components/Button/styles";
import { Container as TimeScheduling } from "@components/TimeScheduling/styles";
import { Container as Checkbox } from "@components/Checkbox/styles";

import { convertToRem } from "@utils/convertToRem";

export const ModalContent = styled.div`
  width: 100%;

  header {
    width: 100%;

    padding: ${convertToRem(20)};
    padding-bottom: ${convertToRem(40)};

    border-bottom: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

    .info {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      height: fit-content;

      padding-bottom: ${convertToRem(20)};

      border-bottom: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
    }

    .avatar {
      width: ${convertToRem(50)};
      height: ${convertToRem(50)};

      margin-right: ${convertToRem(15)};

      border-radius: 50%;
    }

    .separator {
      display: block;
      
      width: ${convertToRem(3)};
      height: ${convertToRem(40)};

      margin-left: ${convertToRem(20)};
      margin-right: ${convertToRem(10)};

      background-color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.1).css()};

      border-radius: ${convertToRem(20)};
    }

    h3 {
      font-weight: 500;
      font-size: ${convertToRem(15)};
    }

    h4 {
      margin-top: ${convertToRem(4)};
      
      color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.5).css()};

      font-size: ${convertToRem(10)};
      
      &.primary {
        color: ${({ theme }) => theme.colors.primary.highlight};
      }
    }

    .address {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      padding-top: ${convertToRem(20)};

      p {
        margin-top: ${convertToRem(10)};

        font-size: ${convertToRem(14)};
        line-height: 1.5;
      }

      ${Button} {
        min-height: ${convertToRem(40)};

        font-weight: 400;
      }
    }
  }

  footer {
    padding: ${convertToRem(20)};
    padding-bottom: ${convertToRem(40)};

    h2 {
      color: ${({ theme }) => theme.colors.neutral.black};

      font-weight: 400;
      font-size: ${convertToRem(30)};
    }

    p {
      margin-top: ${convertToRem(4)};

      font-weight: 400;
      font-size: ${convertToRem(12)};

      span {
        color: ${({ theme }) => theme.colors.primary.base};
      }
    }

    ${Checkbox} {
      margin-top: ${convertToRem(16)};
    }

    .dependent-data {
      margin: ${convertToRem(20)} 0;
    }

    .prime-promo {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin: ${convertToRem(15)} 0;
      padding: ${convertToRem(15)};

      background-color: #EBFBEE;

      border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.helpers.success};
      border-radius: ${convertToRem(10)};

      p {
        flex: 1;

        color: ${({ theme }) => theme.colors.neutral.black};

        font-size: ${convertToRem(14)};
        line-height: 1.5;
      }

      ${Button} {
        min-width: ${convertToRem(170)};
        height: ${convertToRem(60)};

        margin-left: ${convertToRem(50)};

        background-color: ${({ theme }) => theme.colors.helpers.success};
      }
    }

    .buttons-container {
      display: flex;
      
      margin-top: ${convertToRem(40)};
      padding: 0 ${convertToRem(30)};
      
      > button {
        flex: 1;

        margin: 0 ${convertToRem(15)};
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.text};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
  border-radius: ${convertToRem(5)};

  overflow: hidden;

  &:not(:first-of-type) {
    margin-top: ${convertToRem(30)};
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;
  height: ${convertToRem(60)};

  padding: 0 ${convertToRem(30)};

  background-color: ${({ theme }) => theme.colors.neutral.borderLight};
`;

export const HeaderButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  background-color: transparent;
  color: ${({ theme }) => theme.colors.neutral.text};

  font-size: ${convertToRem(12)};
  line-height: 1;

  border: 0;

  svg {
    width: auto;
    height: ${convertToRem(24)};

    margin-right: ${convertToRem(10)};
  }
  
  &:not(:first-child) {
    margin-left: ${convertToRem(30)};
  }

  &.whatsapp svg {
    color: #0FC237;
  }

  &.share svg {
    stroke-width: 1.2;
    color: ${({ theme }) => theme.colors.primary.highlight};
  }
`;

export const Avatar = styled.img`
  display: block;

  width: ${convertToRem(70)};
  height: ${convertToRem(70)};

  margin-top: ${convertToRem(-35)};
  margin-left: ${convertToRem(30)};

  border: solid ${convertToRem(2)} ${({ theme }) => theme.colors.neutral.borderLight};
  border-radius: 50%;

  object-fit: cover;
`;

export const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr ${convertToRem(350)};
  gap: ${convertToRem(40)};

  width: 100%;
  
  margin-top: ${convertToRem(20)};

  padding: 0 ${convertToRem(30)} ${convertToRem(40)};
`;

export const ProfessionalInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  > div {
    display: flex;
  } 

  h4 {
    font-weight: 500;
    font-size: ${convertToRem(20)};
    line-height: 1;
  }

  h5 {
    margin-top: ${convertToRem(5)};

    color: ${({ theme }) => theme.colors.primary.highlight};

    font-weight: 500;
    font-size: ${convertToRem(10)};
    line-height: 1;
  }

  .flags {
    display: flex;
    flex-wrap: wrap;
    gap: ${convertToRem(5)};
    
    margin-top: ${convertToRem(15)};
    margin-bottom: ${convertToRem(40)};
  }
`;

export const Name = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  width: 100%;

  margin-bottom: ${convertToRem(15)};

  > .info {
    flex: 1;
  }

  > span {
    margin-left: ${convertToRem(16)};
    
    color: ${({ theme }) => theme.colors.helpers.warning};
    
    svg {
      width: auto;
      height: ${convertToRem(16)};
    }
  }
`;

export const InfoTitle = styled.strong`
  color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.7).css()};

  font-weight: 500;
  font-size: ${convertToRem(10)};
  line-height: 1;

  &:not(:first-of-type) {
    margin-top: ${convertToRem(12)};
  }
`;

export const InfoDescription = styled.p`
  font-weight: 400;
  font-size: ${convertToRem(15)};
  line-height: 1.5;

  &:not(:first-of-type) {
    margin-top: ${convertToRem(6)};
  }

  &:last-of-type {
    margin-bottom: auto;
  }

  &.price strong {
    color: ${({ theme }) => theme.colors.primary.highlight};

    &:not(:last-of-type) {
      margin-right: ${convertToRem(15)};
    }
  }
`;

export const Flag = styled.span`
  padding: ${convertToRem(10)} ${convertToRem(12)};

  font-size: ${convertToRem(12)};
  line-height: 1;

  background-color: ${({ theme }) => theme.colors.neutral.border};
  color: ${({ theme }) => theme.colors.neutral.text};

  border-radius: ${convertToRem(5)};
`;

export const ReadMore = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;

  margin-top: ${convertToRem(30)};

  color: ${({ theme }) => theme.colors.neutral.text};

  font-weight: 400;
  font-size: ${convertToRem(12)};
  line-height: 1;

  > svg {
    width: auto;
    height: ${convertToRem(24)};

    margin-right: ${convertToRem(10)};
  }
`;

export const SchedulingContainer = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-weight: 500;
    font-size: ${convertToRem(15)};
    line-height: 1;
  }

  .local-buttons {
    display: flex;

    margin-top: ${convertToRem(14)};
   
    ${Button} {
      flex: 1;

      min-width: auto;
      min-height: ${convertToRem(35)};

      width: ${convertToRem(120)};

      font-size: ${convertToRem(10)};
      line-height: 1;

      border-radius: ${convertToRem(3)};

      svg {
        width: auto;
        height: ${convertToRem(18)};

        margin-right: ${convertToRem(10)};
      }

      &:not(:first-child) {
        margin-left: ${convertToRem(10)};
      }
    } 
  }

  ${TimeScheduling} {
    margin-top: ${convertToRem(30)};
  }

  > ${Button} {
    font-weight: 400;

    background-color: ${({ theme }) => chroma('#0FC237').alpha(0.1).css()};
    color: #0FC237;

    margin-top: ${convertToRem(40)};
  }
`;