import { Navigate, Route, Routes } from "react-router-dom";

import { ProfessionalProvider } from "@contexts/professional";

// Pages
import { ListProfessionals } from "./ListProfessionals";
import { AddEditProfessionals } from "./AddEditProfessionals";

export function Professionals() {
  return (
    <ProfessionalProvider>
      <Routes>
        <Route path="/" element={<Navigate to="listagem" />} />
        <Route path="listagem" element={<ListProfessionals />} />
        <Route path="adicionar/*" element={<AddEditProfessionals />} />
        <Route path="editar/:uuid/*" element={<AddEditProfessionals />} />
      </Routes>
    </ProfessionalProvider>
  );
}