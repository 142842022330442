import { useEffect, useState } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { format, getDate } from "date-fns";
import { ptBR } from "date-fns/locale";
import { toast } from "react-toastify";

import { useAuth } from "@contexts/auth";
import { useSearch } from "@contexts/search";

import { useDebounce } from "@hooks/useDebounce";

import { Loading } from "@components/Loading";
import { Select } from "@components/Select";
import { Switch } from "@components/Switch";
import { Slider } from "@components/Slider";
import { Modal } from "@components/Modal";
import { PlanCard } from "@components/PlanCard";

// Pages
import { ResultsPage } from "./ResultsPage";
import { DetailsPage } from "./DetailsPage";

import LocationsService from "@services/LocationsService";
import SpecialtiesService from "@services/SpecialtiesService";
import ConsultationsService, {
  Consultation,
} from "@services/ConsultationsService";

import { Calendar, Filter } from "@assets/images";
import nullAvatar from "@assets/images/null-avatar.jpg";

import { convertToRem } from "@utils/convertToRem";
import { formatToBRL } from "@utils/formatToBRL";
import { capitalizeFirstLetter } from "@utils/capitalizeFirstLetter";
import { delay } from "@utils/delay";

import { OptionTypeBase } from "@components/Select/Select";

import {
  Content,
  ContentWrapper,
  FilterIcon,
  FilterItem,
  FilterLabel,
  FiltersContainer,
  LoadingFiltersContainer,
  LoadingNextConsultationsContainer,
  NextConsultationAvatar,
  NextConsultationCard,
  NextConsultationDate,
  NextConsultationName,
  NextConsultationsContainer,
  SeeAllConsultationsButton,
  SidebarContainer,
  SidebarTitle,
} from "./styles";

type UFOption = OptionTypeBase & { acronym: string };

export function Professionals() {
  const { selectedAccount } = useAuth()
  const { searchValue } = useSearch();

  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoadingUFOptions, setIsLoadingUFOptions] = useState(false);
  const [isLoadingSpecialtyOptions, setIsLoadingSpecialtyOptions] =
    useState(false);

  const [UFOptions, setUFOptions] = useState<UFOption[]>([]);
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);
  const [specialtyOptions, setSpecialtyOptions] = useState<OptionTypeBase[]>(
    []
  );

  const [selectedUF, setSelectedUF] = useState<UFOption | null>(null);
  const [selectedCity, setSelectedCity] = useState<OptionTypeBase | null>(null);
  const [selectedSpecialty, setSelectedSpecialty] =
    useState<OptionTypeBase | null>(null);
  const [isPrime, setIsPrime] = useState(false);
  const [isAttendOnline, setIsAttendOnline] = useState(false);
  const [price, setPrice] = useState(0);

  const debouncedPrice = useDebounce(price, 700);

  const [isLoadingNextConsultations, setIsLoadingNextConsultations] =
    useState(false);
  const [nextConsultations, setNextConsultations] = useState<Consultation[]>(
    []
  );
  const [isAllConsultationsVisible, setIsAllConsultationsVisible] = useState(false);

  const [isNextConsultationModalVisible, setIsNextConsultationModalVisible] = useState(false);
  const [selectedNextConsultation, setSelectedNextConsultation] = useState<Consultation | null>(null);

  // Set UF and Specialty options
  useEffect(() => {
    async function getUFs() {
      try {
        setIsLoadingUFOptions(true);

        const { data: UFs } = await LocationsService.listUFs();

        const parsedUFs: UFOption[] = UFs.map((UF) => ({
          value: String(UF.id),
          acronym: UF.uf,
          label: UF.estado,
        }));

        setUFOptions(parsedUFs);
      } catch (error) {
        setUFOptions([]);

        toast.error("Não foi possível carregar as UFs");

        console.log(error);
      } finally {
        setIsLoadingUFOptions(false);
      }
    }

    async function getSpecialties() {
      try {
        setIsLoadingSpecialtyOptions(true);

        const specialties = await SpecialtiesService.listFakeSpecialties();

        const parsedSpecialties: OptionTypeBase[] = specialties.map(
          (specialty) => ({
            value: String(specialty.id),
            label: specialty.nome,
          })
        );

        setSpecialtyOptions(parsedSpecialties);
      } catch (error) {
        setSpecialtyOptions([]);

        toast.error("Não foi possível carregar as especialidades");

        console.log(error);
      } finally {
        setIsLoadingSpecialtyOptions(false);
      }
    }

    async function getNextConsultations() {
      try {
        setIsLoadingNextConsultations(true);

        const consultations = await ConsultationsService.listNextConsultations();

        await delay();

        setNextConsultations(consultations);
      } catch (error) {
        setSpecialtyOptions([]);

        toast.error("Não foi possível carregar as próximas consultas");

        console.log(error);
      } finally {
        setIsLoadingNextConsultations(false);
      }
    }

    getUFs();
    getSpecialties();
    getNextConsultations();
  }, []);

  // Set City options after user select a UF
  useEffect(() => {
    (async () => {
      if (selectedUF === null) {
        setSelectedCity(null);
        setCityOptions([]);
        return;
      }

      try {
        const { data: cities } = await LocationsService.listCitiesByUF(selectedUF.acronym);

        const parsedCities: OptionTypeBase[] = cities.map((city) => ({
          value: String(city.id),
          label: city.cidade,
        }));

        setCityOptions(parsedCities);
      } catch (error) {
        toast.error("Não foi possível carregar as cidades");

        console.log(error);
      }
    })();
  }, [selectedUF]);

  // Setting filters initial value according query params
  useEffect(() => {
    const UFParam = searchParams.get("uf");
    const cityParam = searchParams.get("city");
    const specialtyParam = searchParams.get("specialty");
    const isPrimeParam = searchParams.get("isPrime") === "true";
    const isAttendOnlineParam = searchParams.get("isAttendOnline") === "true";
    const priceParam = Number(searchParams.get("price"));

    setSelectedUF(UFOptions.find((uf) => uf.acronym === UFParam) || null);
    setSelectedCity(
      cityOptions.find((city) => city.label === cityParam) || null
    );
    setSelectedSpecialty(
      specialtyOptions.find(
        (specialty) => specialty.label === specialtyParam
      ) || null
    );
    setIsPrime(isPrimeParam);
    setIsAttendOnline(isAttendOnlineParam);
    setPrice(priceParam);
  }, [searchParams, UFOptions, cityOptions, specialtyOptions]);

  if (isLoadingUFOptions || isLoadingSpecialtyOptions) {
    return (
      <LoadingFiltersContainer>
        <Loading />

        <h4>Carregando opções de UFs e especialidades...</h4>
      </LoadingFiltersContainer>
    );
  }

  return (
    <Content>
      <Modal
        visible={selectedNextConsultation !== null && isNextConsultationModalVisible}
        title="Próxima consulta"
        onRequestClose={() => {
          setIsNextConsultationModalVisible(false);
          setSelectedNextConsultation(null);
        }}
      >
        <h4 className="subtitle">Dados do profissional</h4>
        <ul className="list">
          <li>
            <strong>Nome:</strong>
            <span>{selectedNextConsultation?.professional.name}</span>
          </li>

          <li>
            <strong>Especialidade:</strong>
            <span>{selectedNextConsultation?.professional.specialty}</span>
          </li>

          <li>
            <strong>{selectedNextConsultation?.professional.council}:</strong>
            <span>{selectedNextConsultation?.professional.councilNumber}</span>
          </li>
        </ul>

        <h4 className="subtitle">Dados da consulta</h4>
        <ul className="list">
          <li>
            <strong>Data do atendimento:</strong>
            <span>
              {selectedNextConsultation ? format(selectedNextConsultation.date, "dd/MM/yyyy HH:mm") : ''}
            </span>
          </li>

          <li>
            <strong>Local:</strong>
            <span>
              {selectedNextConsultation?.isOnline ? "Online" : "Inserir local"}
            </span>
          </li>

          <li>
            <strong>Valor:</strong>
            <span>{selectedNextConsultation ? formatToBRL(selectedNextConsultation.value) : ''}</span>
          </li>
        </ul>
      </Modal>

      <FiltersContainer
        className="content"
        onClick={() =>
          location.pathname.replaceAll("/", "") !== "profissionais"
            ? navigate("/profissionais/")
            : null
        }
      >
        <FilterIcon>
          <Filter />
        </FilterIcon>

        <Select
          value={selectedUF}
          options={UFOptions}
          onChange={(selectedOption) => {
            setSelectedUF(selectedOption);
            setSearchParams(
              selectedOption
                ? {
                    ...Object.fromEntries(searchParams),
                    uf: selectedOption.acronym,
                  }
                : {
                    ...Object.fromEntries(searchParams),
                    uf: "",
                    city: "",
                  }
            );
          }}
          placeholder="UF"
          customHeight={convertToRem(50)}
          isDisabled={!UFOptions.length}
        />

        <Select
          value={selectedCity}
          options={cityOptions}
          onChange={(selectedOption) => {
            setSelectedCity(selectedOption);
            setSearchParams({
              ...Object.fromEntries(searchParams),
              city: selectedOption?.label || "",
            });
          }}
          placeholder="Cidade"
          customHeight={convertToRem(50)}
          isDisabled={!cityOptions.length}
        />

        <Select
          value={selectedSpecialty}
          options={specialtyOptions}
          onChange={(selectedOption) => {
            setSelectedSpecialty(selectedOption);
            setSearchParams({
              ...Object.fromEntries(searchParams),
              specialty: selectedOption?.label || "",
            });
          }}
          placeholder="Especialidade"
          customHeight={convertToRem(50)}
          isDisabled={!specialtyOptions.length}
        />

        <Switch
          checked={isPrime}
          onChange={(e) => {
            setIsPrime(e.target.checked);
            setSearchParams({
              ...Object.fromEntries(searchParams),
              isPrime: String(e.target.checked),
            });
          }}
          label="Prime"
        />

        <Switch
          checked={isAttendOnline}
          onChange={(e) => {
            setIsAttendOnline(e.target.checked);
            setSearchParams({
              ...Object.fromEntries(searchParams),
              isAttendOnline: String(e.target.checked),
            });
          }}
          label="Online"
        />

        <FilterItem>
          <FilterLabel>Preço</FilterLabel>

          <div>
            <span>Até {formatToBRL(price)}</span>

            <Slider
              step={20}
              minValue={0}
              maxValue={340}
              value={price}
              onChange={(e) => {
                setPrice(Number(e.target.value));
                setSearchParams({
                  ...Object.fromEntries(searchParams),
                  price: String(e.target.value),
                });
              }}
            />
          </div>
        </FilterItem>
      </FiltersContainer>

      <ContentWrapper className="content">
        <Routes>
          <Route
            path="/"
            element={
              <ResultsPage
                filters={{
                  name: searchValue,
                  uf: selectedUF?.acronym,
                  city: selectedCity?.label,
                  specialty: selectedSpecialty?.label,
                  isPrime,
                  isAttendOnline,
                  price: debouncedPrice,
                }}
              />
            }
          />
          <Route path=":id/*" element={<DetailsPage />} />
        </Routes>

        <SidebarContainer>
          <SidebarTitle>
            <Calendar />
            Próximas Consultas
          </SidebarTitle>

          {isLoadingNextConsultations ? (
            <LoadingNextConsultationsContainer>
              <Loading />
            </LoadingNextConsultationsContainer>
          ) : (
            <>
              <NextConsultationsContainer>
                {nextConsultations.length > 0 ? (
                  nextConsultations.map((nextConsultation, index) => {
                    if (!isAllConsultationsVisible && index > 1) {
                      return null;
                    }

                    return (
                      <NextConsultationCard
                        key={`${nextConsultation.date.getDate()}--${index}`}
                        className={index === 0 ? "active" : ""}
                        onClick={() =>{
                          setSelectedNextConsultation(nextConsultation)
                          setIsNextConsultationModalVisible(true);
                        }}
                      >
                        <NextConsultationAvatar
                          src={
                            nextConsultation.professional.avatar || nullAvatar
                          }
                          alt={nextConsultation.professional.name}
                        />

                        <NextConsultationName>
                          <h4>{nextConsultation.professional.name}</h4>
                          <h5>
                            {nextConsultation.professional.specialty} |{" "}
                            {nextConsultation.professional.council}{" "}
                            {nextConsultation.professional.councilNumber}
                          </h5>
                        </NextConsultationName>

                        <NextConsultationDate>
                          <span className="day">
                            {getDate(nextConsultation.date)}
                          </span>

                          <span className="month">
                            {capitalizeFirstLetter(
                              format(nextConsultation.date, "MMMM", {
                                locale: ptBR,
                              })
                            )}
                          </span>
                        </NextConsultationDate>
                      </NextConsultationCard>
                    );
                  })
                ) : (
                  <span>Você não possui nenhuma consulta agendada.</span>
                )}
              </NextConsultationsContainer>

              {nextConsultations.length > 0 && (
                <SeeAllConsultationsButton
                  onClick={() =>
                    setIsAllConsultationsVisible((prevState) => !prevState)
                  }
                >
                  {isAllConsultationsVisible
                    ? "Ocultar datas"
                    : "Ver todas as datas"}
                </SeeAllConsultationsButton>
              )}
            </>
          )}

          <h3>Plano atual</h3>
          <PlanCard isPrime={!!selectedAccount?.isPrime} />
        </SidebarContainer>
      </ContentWrapper>
    </Content>
  );
}
