import { useEffect, useState } from 'react';
import { format, parseISO } from 'date-fns';
import { useTheme } from 'styled-components';

import { Description, Title } from '@components/Header';
import { DatePicker } from '@components/DatePicker';
import { Button } from '@components/Button';
import { Card } from '@components/Card';
import { Select } from '@components/Select';
import ResultCard, { ActionButton, ActionsContainer, ItemContainer, ItemDivider, ItemTitle, ItemValue } from '@components/ResultCard';

import { CancelSquare, Cart, CheckCircle, ChevronDown, Document, Info, List, Search } from '@assets/images';
import avatarUrl from '@assets/images/doctor-avatar.jpg';

import { formatToBRL } from '@utils/formatToBRL';
import { formatToPercent } from '@utils/formatToPercent';

import { OnChangeOptionType } from '@components/Select/Select.d';

import { CardsContainer, Content, ContentWrapper, FiltersContainer, Footer, Page, PageButton, Pagination, ResultsContainer, SearchContainer } from './styles';


const classifySelectItems = [
  { value: 'data', label: 'Data' },
  { value: 'profissional', label: 'Profissional' },
  { value: 'paciente', label: 'Paciente' },
  { value: 'valor', label: 'Valor' },
  { value: 'avaliação', label: 'Avaliação' },
];

const limitSelectItems = [
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
];

const statsData = {
  income: {
    value: 200200,
  },
  totalScheduled: {
    value: 820,
  },
  totalPaid: {
    value: 600,
  },
};

const doctorsData =  {
  date: parseISO("2020-02-01T00:00:00"),
  doctorName: "Dr. Juliano Luan",
  doctorAvatar: avatarUrl,
  doctorCRM: "123.5",
  rate: null,
  exam: "Ultrassonografia Toráxica",
  patient: "Pedro Paulo João",
  value: 300,
};

export function ExamMetrics() {
  const theme = useTheme();

  const [stats, setStats] = useState<typeof statsData | null>(null);
  const [doctors, setDoctors] = useState<Array<typeof doctorsData> | null>(null);

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setStats(statsData);
    setDoctors(Array(2).fill(doctorsData));
  }, []);

  useEffect(() => {
    if (!doctors) return;

    setLastPage(Math.ceil(doctors.length / itemsPerPage));
  }, [doctors, itemsPerPage]);

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleChangeLimit = (selectedItem: OnChangeOptionType) => {
    if (!selectedItem) return;
    
    setItemsPerPage(Number(selectedItem.value));

    if (currentPage > lastPage || lastPage === 1) {
      setCurrentPage(1);
    }
  };

  if (!stats || !doctors) return <div />;

  return (
    <Content>
      <ContentWrapper className="content">
        <Title>Métricas de Exames</Title>
        <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Description>

        <SearchContainer>
          <DatePicker
            label="Data inicial"
            name="initial-date"
            selectsStart
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            endDate={endDate}
          />

          <DatePicker
            label="Data final"
            name="final-date"
            selectsEnd
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            startDate={startDate}
            minDate={startDate}
          />

          <Button>
            Buscar
            <Search />
          </Button>
        </SearchContainer>

        <CardsContainer>
          <Card
            icon={<Cart />}
            title="Faturamento"
            value={formatToBRL(stats.income.value)}
          />

          <Card
            icon={<List />}
            title="Total Agendamentos"
            value={stats.totalScheduled.value}
          />

          <Card
            icon={<CheckCircle />}
            title="Total Pagos"
            value={stats.totalPaid.value}
          />
        </CardsContainer>

        <FiltersContainer>
          <Select
            label="Classificar por"
            options={classifySelectItems}
            defaultValue={classifySelectItems.find(
              (i) => i.label === "Profissional"
            )}
          />

          <Select
            label="Exibir últimos"
            options={limitSelectItems}
            onChange={handleChangeLimit}
            defaultValue={limitSelectItems.find(
              (i) => i.label === String(itemsPerPage)
            )}
          />
        </FiltersContainer>

        <ResultsContainer>
          {doctors
            .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
            .map((doctor, index) => (
              <ResultCard key={doctor.doctorCRM + "--" + index}>
                <ItemContainer>
                  <div>
                    <ItemValue>{format(doctor.date, "dd/MM/yyyy")}</ItemValue>
                    <ItemTitle>Data</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider />

                <ItemContainer>
                  <img src={doctor.doctorAvatar} alt={doctor.doctorName} />

                  <div>
                    <ItemValue>{doctor.doctorName}</ItemValue>
                    <ItemTitle>CRM {doctor.doctorCRM}</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider />

                <ItemContainer>
                  <div>
                    <ItemValue>{doctor.patient}</ItemValue>
                    <ItemTitle>{doctor.exam}</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider />

                <ItemContainer>
                  <div>
                    <ItemValue>{formatToBRL(doctor.value)}</ItemValue>
                    <ItemTitle>Valor</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider />

                <ItemContainer>
                  <div>
                    <ItemValue>
                      {doctor.rate ? formatToPercent(doctor.rate) : "Definir"}
                    </ItemValue>
                    <ItemTitle>Avaliação</ItemTitle>
                  </div>
                </ItemContainer>

                <ActionsContainer>
                  <ActionButton color={theme.colors.helpers.danger} disabled>
                    <CancelSquare />
                  </ActionButton>

                  <ActionButton color={theme.colors.helpers.info} disabled>
                    <Document />
                  </ActionButton>

                  <ActionButton color={theme.colors.helpers.success}>
                    <Info />
                  </ActionButton>
                </ActionsContainer>
              </ResultCard>
            ))}
        </ResultsContainer>
      </ContentWrapper>

      <Footer>
        <div className="content">
          <Pagination>
            <PageButton
              className="prev"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              <ChevronDown />
            </PageButton>

            <Page>{String(currentPage).padStart(2, "0")}</Page>

            <PageButton
              className="next"
              onClick={goToNextPage}
              disabled={currentPage === lastPage}
            >
              <ChevronDown />
            </PageButton>
          </Pagination>
        </div>
      </Footer>
    </Content>
  );
}