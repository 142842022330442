import { useEffect, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import { format } from 'date-fns';

import { Modal } from '@components/Modal';
import { Description, Title } from '@components/Header';
import { Select } from '@components/Select';
import ResultCard, { ItemDivider, ItemValue, ItemContainer, ItemTitle } from '@components/ResultCard';

import RecordsService, { Record } from '@services/RecordsService';

import { ChevronDown, Info, Printer } from '@assets/images';

import { convertToRem } from '@utils/convertToRem';

import { OnChangeOptionType } from '@components/Select/Select.d';

import { Content, ContentWrapper, FiltersContainer, Footer, ModalContent, ModalHeader, ModalPrintingArea, Page, PageButton, Pagination, ResultsContainer } from './styles';

export type RecordsOrder = 'Data' | 'Profissional';

interface OrderSelect {
  value: RecordsOrder,
  label: RecordsOrder,
};

export const orderSelectItems: OrderSelect[] = [
  { value: 'Data', label: 'Data' },
  { value: 'Profissional', label: 'Profissional' },
];


export const limitSelectItems = [
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
];

export function Records() {
  const [records, setRecords] = useState<Record[]>([]);
  
  const [selectedOrder, setSelectedOrder] = useState<RecordsOrder>("Profissional");

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  
  const [detailsModal, setDetailsModal] = useState<Record | null>(null);
  const printAreaRef = useRef<HTMLDivElement | null>(null);

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  function handleChangeLimit(selectedOption: OnChangeOptionType) {
    if (selectedOption === null) return;

    setItemsPerPage(Number(selectedOption.value));
  };

  function getOrderedRecords(orderBy: RecordsOrder) {
    switch(orderBy) {
      case 'Data': 
        return records.sort((a, b) => {
          if (a.date.getTime() < b.date.getTime()) return -1;
  
          if (a.date.getTime() > b.date.getTime()) return 1;
  
          return 0;
        });

      default: 
        return records.sort((a, b) => {
          if (a.professional < b.professional) return -1;

          if (a.professional > b.professional) return 1;

          return 0;
        });
    }
  }

  // Setting records
  useEffect(() => {
    async function getExams() {
      const recordsData = await RecordsService.listRecords();
      
      setRecords(recordsData);
    }

    getExams();
  }, []);


  // Pagination
  useEffect(() => {
    if (!records) return;

    const lastPage = Math.ceil(records.length / itemsPerPage);
    setLastPage(lastPage);
    
    if (currentPage > lastPage || lastPage === 1) {
      setCurrentPage(1);
    }
  }, [records, itemsPerPage, currentPage]);

  if (!records) return <div />;

  return (
    <Content>
      <Modal
        visible={detailsModal !== null }
        onRequestClose={() => setDetailsModal(null)}
        style={{ 
          maxWidth: convertToRem(650),

          padding: convertToRem(40),
        }}
      >
        <ModalPrintingArea ref={printAreaRef}>
          <ModalHeader>
            <h3>
              {detailsModal?.professional} | {format(detailsModal?.date as Date, 'dd/MM/yyyy')}
            </h3>

            <ReactToPrint
              trigger={() => (
                <button>
                  <Printer />

                  Imprimir
                </button>
              )}
              content={() => printAreaRef.current}
              pageStyle={`
                @page {
                  margin: ${convertToRem(80)} ${convertToRem(40)};
                }
              `}
            />
          </ModalHeader>

          <ModalContent>
            {detailsModal?.questions.map(question => (
              <li>
                <strong>{question.question}</strong>
                <span>{question.answer}</span>
              </li>
            ))}
          </ModalContent>
        </ModalPrintingArea>
      </Modal>

      <ContentWrapper className="content">
        <Title>Histórico de Prontuários</Title>
        <Description>
          Mantenha o seu cadastro atualizado para aproveitar ao máximo nossas
          vantagens e funcionalidades.
        </Description>

        <FiltersContainer>
          <Select
            label="Classificar por"
            options={orderSelectItems}
            value={orderSelectItems.find(option => option.label === selectedOrder)}
            onChange={option => {
              setSelectedOrder(option?.label || 'Profissional');
              setCurrentPage(1);
            }}
            isClearable={false}
          />

          <Select
            label="Exibir últimos"
            options={limitSelectItems}
            onChange={handleChangeLimit}
            defaultValue={limitSelectItems.find(
              (i) => i.label === String(itemsPerPage)
            )}
            isClearable={false}
          />
        </FiltersContainer>

        <ResultsContainer>
          {getOrderedRecords(selectedOrder)
            .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
            .map((record, index) => (
              <ResultCard key={record.id + "--" + index}>
                <ItemContainer>
                  <div>
                    <ItemValue>{record.professional}</ItemValue>
                    <ItemTitle>{record.questions.map(question => `${question.question} ${question.answer}`).join('  ')}</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider />

                <ItemContainer>
                  <div>
                    <ItemValue>{format(record.date, 'dd/MM/yyy')}</ItemValue>
                    <ItemTitle>Data</ItemTitle>
                  </div>
                </ItemContainer>
                
                <button
                  onClick={() => setDetailsModal(record)}
                >
                  <Info />
                  Visualizar
                </button>
              </ResultCard>
            ))}
        </ResultsContainer>
      </ContentWrapper>

      <Footer>
        <div className="content">
          <Pagination>
            <PageButton
              className="prev"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              <ChevronDown />
            </PageButton>

            <Page>{String(currentPage).padStart(2, "0")}</Page>

            <PageButton
              className="next"
              onClick={goToNextPage}
              disabled={currentPage === lastPage}
            >
              <ChevronDown />
            </PageButton>
          </Pagination>
        </div>
      </Footer>
    </Content>
  );
}