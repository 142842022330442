import styled from "styled-components";

import { InputGroup } from "@components/Input/styles";
import { Container as Button } from "@components/Button/styles";
import { Container as Checkbox } from "@components/Checkbox/styles";

import { convertToRem } from "@utils/convertToRem";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  max-width: ${convertToRem(730)};

  margin: 0 auto;
  padding: ${convertToRem(40)} ${convertToRem(15)} ${convertToRem(80)};
`;

export const Header = styled.header`
  width: 100%;

  .promo-image {
    width: 100%;

    border-radius: ${convertToRem(8)};

    overflow: hidden;

    img {
      display: block;

      width: 100%;

      &.mobile {
        display: none;
      }
    }
  }

  @media screen and (max-width: 992px) {
    .promo-image {
      height: auto;

      img {
        height: auto;

        object-fit: inherit;

        display: none;

        &.mobile {
          display: block;
        }
      }
    }
  }

  h1 {
    margin-top: ${convertToRem(24)};
    color: ${({ theme }) => theme.colors.neutral.textDark};

    font-weight: 400;
  }

  p {
    margin-top: ${convertToRem(8)};
  }
`;

export const Form = styled.form`
  width: 100%;

  padding-top: ${convertToRem(24)};

  ${InputGroup} {
    > .phone {
      ${Checkbox} {
        margin-top: ${convertToRem(8)};
      }
    }
  }
    

  > ${Button} {
    margin-top: ${convertToRem(48)};
    margin-left: auto;
  }

  @media (max-width: 576px) {
    ${InputGroup} {
      grid-template-columns: 1fr;
      gap: ${convertToRem(18)};
    }
  }
`;

export const Card = styled.section`
  width: 100%;

  padding: ${convertToRem(24)};

  background-color: ${({ theme }) => theme.colors.neutral.white};

  box-shadow: 0 ${convertToRem(2)} ${convertToRem(8)} rgba(0, 0, 0, 0.075);
  border-radius: ${convertToRem(8)};

  > h3 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    margin-bottom: ${convertToRem(24)};

    color: ${({ theme }) => theme.colors.primary.base};

    font-weight: 400;
    font-size: ${convertToRem(18)};

    > span {
      color: ${({ theme }) => theme.colors.neutral.text};

      font-size: ${convertToRem(14)};
    }
  }

  & + & {
    margin-top: ${convertToRem(16)};
  }
`;