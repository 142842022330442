import styled from "styled-components";
import React from "react";
import chroma from "chroma-js";

import { convertToRem } from "@utils/convertToRem";


export const Container = styled.div<{ isDisabled: boolean; error?: string; }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: ${convertToRem(6)};

    width: 100%;
    height: ${convertToRem(40)};

    padding: 0 ${convertToRem(12)};

    background-color: ${({ isDisabled, theme }) => isDisabled ? theme.colors.neutral.borderLight : theme.colors.neutral.white};

    border: solid ${convertToRem(1)} ${({ error, theme }) => error ? theme.colors.helpers.danger : theme.colors.neutral.borderDark};
    border-radius: ${convertToRem(5)};

    overflow: hidden;

    cursor: ${({ isDisabled }) => isDisabled ? 'not-allowed' : 'initial'};
  }

  input {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    height: 100%;
    
    background-color: transparent;
    color: ${({ theme }) => theme.colors.neutral.text};
    
    padding: 0 ${convertToRem(6)};

    font-size: ${convertToRem(14)};
    line-height: 1;

    cursor: ${({ isDisabled }) => isDisabled ? 'not-allowed' : 'initial'};
    border: 0;

    &[type='file'] {
      height: initial;
    }
  }
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.neutral.textDark};
  
  font-weight: 500;
  font-size: ${convertToRem(13)};
  line-height: 1;

  margin-bottom: ${convertToRem(8)};
`;

export const VisibilityButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${convertToRem(20)};
  height: ${convertToRem(20)};

  margin-left: ${convertToRem(8)};

  background: none;
  border: none;

  > svg {
    width: auto;
    height: ${convertToRem(16)};

    color: ${({ theme }) => chroma(theme.colors.neutral.borderDark).darken(0.8).css()};

    transition: color ease 0.4s;
  }

  &:hover > svg {
    color: ${({ theme }) => theme.colors.primary.base};
  }
`;

export const Icon = styled(VisibilityButton).attrs({ 
  as: "span"
})`
  width: ${convertToRem(24)};
  height: ${convertToRem(24)};

  margin: 0;

  > svg {
    color: ${({ theme }) => theme.colors.primary.base};
  }
`;

export const Error = styled.span`
  margin-top: ${convertToRem(4)};

  color: ${({ theme }) => theme.colors.helpers.danger};

  font-size: ${convertToRem(10)};
`;

type InputGroupType = {
  layout?: string;
}

export const InputGroup = styled.div<InputGroupType>`
  display: grid;
  grid-template-columns: ${({ layout, children }) => {
    const childrenCount =  React.Children.count(children)
    return layout ? layout : `repeat(${childrenCount}, 1fr);`
  }};
  gap: ${convertToRem(16)};

  div ~ & {
    margin-top: ${convertToRem(20)};
  }

  & + div {
    margin-top: ${convertToRem(20)};
  }
`;
