import styled, { keyframes } from 'styled-components';

import { Modal } from './';

import { convertToRem } from '@utils/convertToRem';

export const Overlay = styled.div`
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  min-height: 100vh;

  background-color: rgba(0, 0, 0, 0.4);

  z-index: 9999;
`;

const slideUp = keyframes`
  from {
    transform: translateY(${convertToRem(30)});
  }

  to {
    transform: translateY(0);
  }
`;

interface WrapperProps {
  disablePadding: boolean; 
  isLoadingModal?: boolean;
}

export const LoadingContent = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center !important;
  justify-content: center !important;

  width: ${convertToRem(250)} !important;
  height: ${convertToRem(250)} !important;

  padding: ${convertToRem(40)};

  h3 {
    font-size: ${convertToRem(20)};

    margin-bottom: ${convertToRem(16)};
  }
`;

export const Wrapper = styled.div<WrapperProps>`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: ${({ isLoadingModal }) => isLoadingModal ? 'auto' : '100%'};

  max-width: ${({ isLoadingModal }) => convertToRem(isLoadingModal ? 300 : 500)};
  max-height: 96vh;

  padding: ${({ disablePadding }) => convertToRem(disablePadding ? 0 : 30)};

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.text};

  border-radius: ${convertToRem(5)};
  box-shadow: 0 ${convertToRem(5)} ${convertToRem(20)} rgba(0, 0, 0, 0.15);

  animation: ${slideUp} 0.4s ease forwards;

  overflow-y: auto;

  &::-webkit-scrollbar {
    width: ${convertToRem(6)};
  }

  &::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.colors.neutral.borderLight};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors.neutral.borderDark};
    border-radius: ${convertToRem(8)};
  }

  .subtitle {
    margin-bottom: ${convertToRem(10)};

    font-size: ${convertToRem(16)};

    color: ${({ theme }) => theme.colors.primary.highlight};

    &:not(:first-of-type) {
      margin-top: ${convertToRem(28)};
    }
  }

  .list {
    li {
      font-size: ${convertToRem(14)};
      line-height: 1.5;
  
      strong {
        color: ${({ theme }) => theme.colors.neutral.textDark};
      }
  
      span {
        margin-left: ${convertToRem(4)};
      }
    }
  }

  .text {
    font-family: 'Graphik';

    color: ${({ theme }) => theme.colors.neutral.text};

    font-size: ${convertToRem(16)};
    line-height: 1.5;

    strong {
      font-weight: 500;
    }

    span {
      color: ${({ theme }) => theme.colors.primary.highlight};
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    margin-top: ${convertToRem(40)};
    margin-left: auto;

    button {
      border: 0;
      
      background-color: transparent;
      color: ${({ theme }) => theme.colors.neutral.text};

      font-size: ${convertToRem(16)};

      transition: ease 0.3s;

      &:hover {
        color: ${({ theme }) => theme.colors.neutral.textDark};
      }

      &:not(:first-child) {
        margin-left: ${convertToRem(12)};
      }

      &.primary {
        padding: ${convertToRem(10)} ${convertToRem(15)};

        background-color: ${({ theme }) => theme.colors.primary.highlight};
        color: ${({ theme }) => theme.colors.neutral.white};

        font-weight: 500;

        border-radius: ${convertToRem(4)};

        &:hover {
          background-color: ${({ theme }) => theme.colors.primary.base};
        }
      }
    }
  }
`;

export const Subtitle = styled.h3`
  margin-bottom: ${convertToRem(10)};

  font-size: ${convertToRem(16)};

  color: ${({ theme }) => theme.colors.primary.highlight};
`;

export const List = styled.ul`
  li {
    font-size: ${convertToRem(14)};
    line-height: 1.5;

    strong {
      color: ${({ theme }) => theme.colors.neutral.textDark};
    }

    span {
      margin-left: ${convertToRem(4)};
    }
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  margin-bottom: ${convertToRem(40)};

  h3 {
    color: ${({ theme }) => theme.colors.neutral.text};

    font-size: ${convertToRem(20)};
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    
    background: none;
    color: ${({ theme }) => theme.colors.neutral.text};
    
    font-size: ${convertToRem(24)};

    border: 0;
  }
`;