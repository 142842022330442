import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { Input, InputGroup } from "@components/Input";
import { DatePicker } from "@components/DatePicker";
import { Select } from "@components/Select";
import { Checkbox } from "@components/Checkbox";
import { Button } from "@components/Button";

import LocationsService from "@services/LocationsService";
import TreatmentsService from "@services/TreatmentsService";
import LeadsService, { CreateAffiliateLeadRequest } from "@services/LeadsService";

import { genderOptions } from "@constants/genders";

import { FormikEffect } from "@utils/FormikEffect";

import leadBanner from '@assets/images/lead-cadastro-banner.png';
import leadBannerMobile from '@assets/images/lead-cadastro-banner-mobile.png';

import { OptionTypeBase } from "@components/Select/Select";
import { AxiosAPIError } from "@/types/api";
import { Affiliate } from "@pages/General/RegisterLeads";

import { Card, Form, Header, Wrapper } from "./styles";

export interface Lead {
  name: string;
  gender: OptionTypeBase | null;
  birthDate?: string;
  phone: string;
  isWhatsapp: boolean;
  whatsapp: string;
  email: string;
  facebook: string;
  treatment: OptionTypeBase | null;
  uf: OptionTypeBase | null;
  city: OptionTypeBase | null;
}

interface RegisterLeadsProps {
  affiliate?: Affiliate;
}

export function RegisterLeads({ affiliate }: RegisterLeadsProps) {
  const navigate = useNavigate();

  const [isLoadingTreatmentOptions, setIsLoadingTreatmentOptions] = useState(true);
  const [isLoadingUFOptions, setIsLoadingUFOptions] = useState(true);
  const [isLoadingCityOptions, setIsLoadingCityOptions] = useState(false);
  
  const [treatmentOptions, setTreatmentOptions] = useState<OptionTypeBase[]>([]);
  const [UFOptions, setUFOptions] = useState<OptionTypeBase[]>([]);
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik<Lead>({
    initialValues: {
      name: '',
      gender: null,
      birthDate: undefined,
      phone: '',
      isWhatsapp: false,
      whatsapp: '',
      email: '',
      facebook: '',
      treatment: null,
      uf: null,
      city: null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required("O campo é obrigatório"),
      gender: Yup.object({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um gênero"),
      birthDate: Yup.date()
        .nullable()
        .default(undefined)
        .typeError("Insira uma data válida"),
      phone: Yup.string()
        .matches(/\(\d{2}\) \d{5}-\d{4}/g, "Insira um telefone válido")
        .required("O campo é obrigatório"),
      whatsapp: Yup.string()
        .matches(/\(\d{2}\) \d{5}-\d{4}/g, "Insira um WhatsApp válido"),
      email: Yup.string()
        .email("Insira um e-mail válido"),
      facebook: Yup.string()
        .url("Insira um link válido"),
      treatment: Yup.object({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um tratamento"),
      uf: Yup.object({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um estado"),
      city: Yup.object({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione uma cidade"),
    }),
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        // Block more than once submission
        setSubmitting(false);
        
        if (isSubmitting) return;
        setIsSubmitting(true);

        const newAffiliate: CreateAffiliateLeadRequest = {
          nome: values.name,
          data_nascimento: values?.birthDate,
          genero: String(values.gender?.value),

          telefone: values.phone,
          whatsApp: values.isWhatsapp ? values.phone : values.whatsapp,
          email: values.email,
          link_facebook: values.facebook,

          tratamento_id: Number(values.treatment?.value),
          uf_id: Number(values.uf?.value),
          cidade_id: Number(values.city?.value),
        };

        const { data } = await (
          affiliate 
          ? LeadsService.createAffiliateLeadByLink(affiliate.uuid, newAffiliate) 
          : LeadsService.createAffiliateLead(newAffiliate)
        );

        toast.success(data.menssagem);
        resetForm();

        if (affiliate) navigate('/cadastro-concluido');
      } catch (err) {
        const error = err as AxiosAPIError;

        console.error(error);

        if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
          error.response.data.dados.forEach(err => toast.error(err));
        } else {
          toast.error(error.message);
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  // Setting Treatment and UF options
  useEffect(() => {
    async function getTreatments() {
      try {
        setIsLoadingTreatmentOptions(true);
  
        const { data } = await TreatmentsService.listTreatments();

        const parsedTreatments: OptionTypeBase[] = data.map(treatment => ({
          value: String(treatment.id),
          label: treatment.nome,
        }));

        setTreatmentOptions(parsedTreatments);
      } catch (error) {
        setTreatmentOptions([]);
  
        toast.error("Não foi possível carregar os tratamentos");
  
        console.error(error);
      } finally {
        setIsLoadingTreatmentOptions(false);
      }
    }

    async function getUFs() {
      try {
        setIsLoadingUFOptions(true);
  
        const { data: UFs } = await LocationsService.listApprovedUFs();
  
        const parsedUFs: OptionTypeBase[] = UFs.dados.map(UF => ({
          value: String(UF.estado_id),
          label: UF.estado_uf,
        }));
  
        setUFOptions(parsedUFs);
      } catch (error) {
        setUFOptions([]);
  
        toast.error("Não foi possível carregar as UFs");
  
        console.error(error);
      } finally {
        setIsLoadingUFOptions(false);
      }
    }

    getTreatments();
    getUFs();
  }, []);

  // Setting city options
  useEffect(() => {
    (async () => {
      try {
        formik.setFieldValue("city", null);

        if (!UFOptions.length || !formik.values.uf) {
          setCityOptions([]);
          return;
        }

        setIsLoadingCityOptions(true);

        const UF = UFOptions.find((uf) => uf.label === formik.values.uf?.label);

        if (typeof UF === "undefined")
          throw new Error("Estado inválido! Não foi possível buscar as cidades");

        const { data: cities } = await LocationsService.listApprovedCities(UF.value);

        const parsedCities: OptionTypeBase[] = cities.dados.map((city) => ({
          value: String(city.cidade_id),
          label: city.cidade_nome,
        }));

        setCityOptions(parsedCities);
      } catch (error) {
        setCityOptions([]);

        toast.error("Não foi possível carregar as cidades");

        console.error(error);
      } finally {
        setIsLoadingCityOptions(false);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [UFOptions, formik.values.uf]);

  return (
    <Wrapper>
      <Header>
        <div className="promo-image">
          <img src={leadBanner} alt="Imagem Promocional" />
          <img src={leadBannerMobile} className="mobile" alt="Imagem Promocional" />
        </div>

        <h1>
          {affiliate ? 'Cadastro' : 'Cadastro de Leads'}
        </h1>

        {affiliate && (
          <p>
            <strong>Afiliado: </strong>
            {affiliate.name}
          </p>
        )}
      </Header>
      
      <Form onSubmit={formik.handleSubmit}>
        <FormikEffect formik={formik} />

        <Card>
          <h3>Dados Pessoais</h3>

          <Input 
            label="Nome"
            name="name"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={(formik.touched.name || undefined) && formik.errors.name}
          />

          <InputGroup>
            <Input 
              label="Data de Nascimento"
              name="birthDate"
              type="date"
              value={String(formik.values.birthDate)}
              onChange={formik.handleChange}
              error={(formik.touched.birthDate || undefined) && formik.errors.birthDate}
            />

            <Select 
              label="Gênero"
              name="gender"
              options={genderOptions}
              value={formik.values.gender}
              onChange={option => formik.setFieldValue('gender', option)}
              error={(formik.touched.gender || undefined) && formik.errors.gender} 
            />
          </InputGroup>
        </Card>

        <Card>
          <h3>Contatos</h3>

          <InputGroup>
            <div className="phone">
              <Input 
                label="Telefone"
                type="tel"
                name="phone"
                mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={(formik.touched.phone || undefined) && formik.errors.phone}
              />

              <Checkbox 
                label="É WhatsApp?"
                name="isWhatsapp"
                checked={formik.values.isWhatsapp}
                onChange={(e) => {
                  if(!e.target.checked) {
                    formik.setFieldValue("whatsapp", "", false);
                  }
                  
                  formik.setFieldValue("isWhatsapp", e.target.checked);
                }}
              />
            </div>

            <Input 
              label="WhatsApp"
              type="tel"
              name="whatsapp"
              mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              value={formik.values.isWhatsapp ? formik.values.phone : formik.values.whatsapp}
              onChange={formik.handleChange}
              error={(formik.touched.whatsapp || undefined) && formik.errors.whatsapp}
              disabled={formik.values.isWhatsapp}
            />
          </InputGroup>

          <InputGroup>
            <Input 
              label="E-mail"
              type='email'
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={(formik.touched.email || undefined) && formik.errors.email}
            />
          
            <Input 
              label="Link Facebook"
              type='url'
              name="facebook"
              value={formik.values.facebook}
              onChange={formik.handleChange}
              error={(formik.touched.facebook || undefined) && formik.errors.facebook}
            />
          </InputGroup>
        </Card>

        <Card>
          <h3>Dados Gerais</h3>

          <Select 
            label="Tratamento de Interesse"
            name="treatment"
            options={treatmentOptions}
            value={formik.values.treatment}
            onChange={option => formik.setFieldValue('treatment', option)}
            error={(formik.touched.treatment || undefined) && formik.errors.treatment} 
            isDisabled={isLoadingTreatmentOptions}
          />

          <InputGroup layout="1fr 2fr">
            <Select 
              label="UF"
              name="uf"
              options={UFOptions}
              value={formik.values.uf}
              onChange={option => formik.setFieldValue('uf', option)}
              error={(formik.touched.uf || undefined) && formik.errors.uf} 
              isDisabled={isLoadingUFOptions}
            />

            <Select 
              label="Cidade"
              name="city"
              options={cityOptions}
              value={formik.values.city}
              onChange={option => formik.setFieldValue('city', option)}
              error={(formik.touched.city || undefined) && formik.errors.city} 
              isDisabled={isLoadingUFOptions || formik.values.uf === null || isLoadingCityOptions}
            />
          </InputGroup>
        </Card>

        <Button type="submit" isLoading={isSubmitting}>
          Cadastrar
        </Button>
      </Form>
    </Wrapper>
  );
}