import { useEffect, useRef, useState } from "react";
import { RiArrowDownLine, RiArrowLeftRightLine, RiArrowUpLine, RiCheckDoubleLine, RiCloseLine, RiFile3Line, RiFileAddLine, RiFilterLine, RiFilterOffLine, RiMoneyDollarCircleLine, RiUserLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { useTheme } from "styled-components";
import { useFormik } from "formik";
import * as Yup from 'yup';
import axios from "axios";
import faker from "@faker-js/faker";

import { List, LoadingModal, Subtitle } from "@components/Modal";
import { Loading } from "@components/Loading";
import { Description, Title } from "@components/Header";
import { Input } from "@components/Input";
import { Error as StyledError, InputGroup } from "@components/Input/styles";
import { DatePicker } from "@components/DatePicker";

import { Button } from "@components/Button";
import { Select } from "@components/Select";
import { Tooltip } from "@components/Tooltip";
import ResultCard, {
  ActionButton,
  ActionsContainer,
  ItemContainer,
  ItemDivider,
  ItemTitle,
  ItemValue,
} from "@components/ResultCard";

import PaymentsService from "@services/PaymentsService";
import AffiliatesService, { ListAffiliateFilters, ListAdminAffiliatesOrdersBy } from "@services/AffiliatesService";
import LocationsService from "@services/LocationsService";
import FinanceService from "@services/FinanceService";

import { formatToBRL } from "@utils/formatToBRL";

import { ChevronDown } from "@assets/images";

import { OptionTypeBase } from "@components/Select/Select";
import { AxiosAPIError, StatusColors } from "@/types/api";

import {
  ChangeAffiliateSituationModal,
  ConfirmPaymentModal,
  Content,
  ContentWrapper,
  DataModal,
  FilterModal,
  Footer,
  Header,
  OrderingContainer,
  Page,
  PageButton,
  Pagination,
  ReceiptFile,
  Tab,
  Tabs,
  ResultsContainer,
  PaymentsListModal,
} from "./styles";

interface FilterFormik {
  name: string;
  cpf: string;
  uf: OptionTypeBase | null;
  city: OptionTypeBase | null;
  paymentStatus: OptionTypeBase | null;
  situation: OptionTypeBase | null;
}

interface Affiliate {
  id: string;
  name: string;
  address: {
    address: string;
    number: string;
    neighborhood: string;
    complement: string;
    cep: string;
    city: string;
    uf: string;
  };
  registeredLeads: number;
  goal: number;
  payment: {
    amount: number;
    status: {
      description: string;
      color: StatusColors;
    };
  };
  status: {
    id: number;
    description: string;
    color: StatusColors;
  };
  bank: {
    name: string;
    receiptType: 'pix' | 'transfer';

    keyType: string;
    keyValue: string;

    bankAgency: string;
    bankAccount: string;
  };
  contact: {
    email: string;
    phone: string;
    whatsApp: string;
  }
}

interface Payment {
  id: string;
  goal: string;
  amount: number;
  status: {
    name: string;
    color: StatusColors;
  };
}

interface ConfirmPaymentFormik {
  bank: OptionTypeBase | null;
  origin: OptionTypeBase | null;
  date: Date | null;
  receipt: File | null;
}

interface OrderSelectItems {
  value: ListAdminAffiliatesOrdersBy;
  label: string;
}

const orderSelectItems: OrderSelectItems[] = [
  { value: 'name', label: 'Nome' },
  { value: 'created_at', label: 'Data de Cadastro' },
  { value: 'status_id', label: 'Status' },
];

const limitSelectItems: OptionTypeBase[] = [
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
];

const paymentStatusOptions: OptionTypeBase[] = [
  { value: '23', label: 'Aguardando' },
  { value: '24', label: 'Em processamento' },
  { value: '25', label: 'Cancelado' },
  { value: '26', label: 'Estornado' },
  { value: '27', label: 'Liquidado' },
  { value: '28', label: 'Sem ordem de pagamento' },
];

const situationOptions: OptionTypeBase[] = [
  { value: '1', label: 'Ativo' },
  { value: '2', label: 'Inativo' },
];

const fakerPayments = Array(4).fill({} as Payment).map<Payment>(() => {
  const statusName = faker.random.arrayElement(['Aprovado', 'Aguardando Pagamento']);

  return ({
    id: faker.datatype.uuid(),
    amount: faker.datatype.float({ min: 1000, max: 10000 }),
    goal: faker.random.arrayElement(['Bronze', 'Prata', 'Ouro']),
    status: {
      name: statusName,
      color: statusName === 'Aprovado' ? 'success' : 'warning',
    },
  });
});

const allowedReceiptTypes = ['application/pdf', 'image/jpg', 'image/jpeg', 'image/png'];

export function Affiliates() {
  const theme = useTheme();
  
  const [isLoading, setIsLoading] = useState(true);
  const [affiliates, setAffiliates] = useState<Affiliate[]>([]);

  const [isClosingReport, setIsClosingReport] = useState(false);
  
  const [selectedOrder, setSelectedOrder] = useState<ListAdminAffiliatesOrdersBy>('created_at');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isOrderAscending, setIsOrderAscending] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const [isLoadingUFOptions, setIsLoadingUFOptions] = useState(true);
  const [isLoadingCityOptions, setIsLoadingCityOptions] = useState(false);
  
  const [UFOptions, setUFOptions] = useState<OptionTypeBase[]>([]);
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);

  const [isFilterModalOpened, setIsFilterModalOpened] = useState(false);
  const [filters, setFilters] = useState<ListAffiliateFilters | undefined>(undefined);

  const filterFormik = useFormik<FilterFormik>({
    initialValues: {
      name: '',
      cpf: '',
      uf: null,
      city: null,
      paymentStatus: null,
      situation: null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string(),
      cpf: Yup.string()
        .matches(/^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/, "Insira um CPF válido"),
      state: Yup.object({ value: Yup.string(), label: Yup.string() })
        .nullable(),
      city: Yup.object({ value: Yup.string(), label: Yup.string() })
        .when("uf", {
          is: null,
          then: Yup.object().nullable(),
          otherwise: Yup.object()
            .required("O campo é obrigatório")
            .typeError("Selecione uma cidade")
        }),
      paymentStatus: Yup.object({ value: Yup.string(), label: Yup.string() })
        .nullable(),
      situation: Yup.object({ value: Yup.string(), label: Yup.string() })
        .nullable(),
    }),
    onSubmit: (values) => {
      setIsFilterModalOpened(false);

      const isAllFieldsEmpty = Object.values(values).map(Boolean).every(e => e === false);
      if (isAllFieldsEmpty) {
        handleClearFilters();
        return;
      }
      console.log(filterFormik.values.name);
      setFilters({
        name: filterFormik.values.name || undefined,
        cpf: filterFormik.values.cpf || undefined,
        uf: filterFormik.values.uf ? Number(filterFormik.values.uf.value) : undefined,
        cidade: filterFormik.values.city ? Number(filterFormik.values.city.value) : undefined,
        payment_status_id: filterFormik.values.paymentStatus ? Number(filterFormik.values.paymentStatus.value) : undefined,
        status_id: filterFormik.values.situation ? Number(filterFormik.values.situation.value) : undefined,
      });
    }
  });
  
  const [selectedAffiliate, setSelectedAffiliate] = useState<Affiliate | null>(null);
  const [selectedPayment, setSelectedPayment] = useState<Payment | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const [isChangeAffiliateSituationModalVisible, setIsChangeAffiliateSituationModalVisible] = useState(false);
  const [isDataModalVisible, setIsDataModalVisible] = useState(false);

  const [selectedTab, setSelectedTab] = useState<'bank' | 'contacts' | 'addresses'>('bank');

  const [isLoadingBankOptions, setIsLoadingBankOptions] = useState(true);
  const [bankOptions, setBankOptions] = useState<OptionTypeBase[]>([]);
  const [isConfirmPaymentModalVisible, setIsConfirmPaymentModalVisible] = useState(false);
  

  const receiptRef = useRef<HTMLInputElement>(null);

  const confirmPaymentFormik = useFormik<ConfirmPaymentFormik>({
    initialValues: {
      bank: null,
      origin: null,
      date: null,
      receipt: null,
    },
    validationSchema: Yup.object().shape({
      bank: Yup.object({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um banco"),
      origin: Yup.object({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione uma origem"),
      date: Yup.date()
        .required("O campo é obrigatório")
        .typeError("Selecione uma data"),
      receipt: Yup
        .mixed()
        .required("O campo é obrigatório")
        .test("fileType", "Formato inválido! Apenas arquivos no formato de imagem ou pdf são aceitos.", (value: File | null) => {
          if (value !== null) {
            return allowedReceiptTypes.includes(value.type);
          }

          return false;
        })
        .test("fileSize", "Tamanho excedido! Só é permito o envio de arquivos com até 12MB.", (value: File | null) => {
          if (value !== null) {
            const fileSizeInMB = value.size / Math.pow(1024, 2);

            return fileSizeInMB <= 12.0;
          }

          return false;
        })
      ,
    }),
    onSubmit: (values, { resetForm }) => {
      alert(JSON.stringify(values));
      setIsConfirmPaymentModalVisible(false);
      resetForm();
    }
  });

  // Getting affiliates
  useEffect(() => {
    getAffiliates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrder, itemsPerPage, isOrderAscending, filters, currentPage]);

  // Getting UF ans bank options
  useEffect(() => {
    async function getUFs() {
      try {
        setIsLoadingUFOptions(true);

        const { data: UFs } = await LocationsService.listUFs();

        const parsedUFs: OptionTypeBase[] = UFs.map((UF) => ({
          value: String(UF.id),
          label: UF.uf,
        }));

        setUFOptions(parsedUFs);
      } catch (error) {
        toast.error("Não foi possível carregar as UFs");

        console.error(error);
      } finally {
        setIsLoadingUFOptions(false);
      }
    }

    async function getBanks() {
      try {
        setIsLoadingBankOptions(true);

        const { data: banks } = await FinanceService.listBanks();

        const parsedBanks: OptionTypeBase[] = banks.map((bank) => ({
          value: String(bank.nome),
          label: bank.nome,
        }));

        setBankOptions(parsedBanks);
      } catch (error) {
        toast.error("Não foi possível carregar os bancos");

        console.error(error);
      } finally {
        setIsLoadingBankOptions(false);
      }
    }

    getUFs();
    getBanks();
  }, []);

  // Getting city options
  useEffect(() => {
    async function getCities() {
      try {
        if (!UFOptions.length || !filterFormik.values.uf) {
          setCityOptions([]);
          return;
        }

        setIsLoadingCityOptions(true);

        const UF = UFOptions.find((uf) => uf.label === filterFormik.values.uf?.label);

        if (typeof UF === "undefined")
          throw new Error(
            "Estado inválido! Não foi possível buscar as cidades"
          );

        const { data: cities } = await LocationsService.listCitiesByUF(
          UF.label
        );

        const parsedCities: OptionTypeBase[] = cities.map((city) => ({
          value: String(city.id),
          label: city.cidade,
        }));

        setCityOptions(parsedCities);
      } catch (error) {
        setCityOptions([]);

        toast.error("Não foi possível carregar as cidades");

        console.error(error);
      } finally {
        setIsLoadingCityOptions(false);
      }
    }

    getCities();
  }, [UFOptions, filterFormik.values.uf]);

  async function getAffiliates() {
    try {
      setIsLoading(true);

      const { data } = await AffiliatesService.listAdminAffiliates({
        page: currentPage - 1,
        limit: itemsPerPage,
        orderBy: selectedOrder,
        order: isOrderAscending ? 'asc' : 'desc',
        filters
      });
      
      const maxPage = Math.ceil(data.total / itemsPerPage) || 1;
      if (data.pagina + 1 > maxPage) {
        setCurrentPage(maxPage);
      }

      const parsedAffiliates = data.afiliados.map<Affiliate>(affiliate => ({
        id: affiliate.afiliado.uuid,
        name:  affiliate.afiliado.nome,
        address: {
          address: affiliate.afiliado.endereco,
          number: affiliate.afiliado.numero,
          neighborhood: affiliate.afiliado.bairro,
          complement: affiliate.afiliado.complemento,
          cep: affiliate.afiliado.cep,
          city: affiliate.afiliado.cidade,
          uf: affiliate.afiliado.uf,
        },
        registeredLeads: affiliate.afiliado.count_leads,
        goal: affiliate.afiliado.meta_numero,
        payment: {
          amount: affiliate.pagamento.valor_total,
          status: {
            description: affiliate.pagamento.status_nome,
            color: affiliate.pagamento.status_cor,
          },
        },
        status: {
          id: affiliate.afiliado.status_id,
          description: affiliate.afiliado.status_nome,
          color: affiliate.afiliado.status_cor,
        },
        bank: {
          name: affiliate.banco.banco,
          receiptType: affiliate.banco.tipo_id === 2 ? 'pix' : 'transfer',

          keyType: affiliate.banco.tipo_id === 2 ? affiliate.banco.chave_conta : '',
          keyValue: affiliate.banco.tipo_id === 2 ? affiliate.banco.valor_agencia : '',

          bankAgency: affiliate.banco.tipo_id === 1 ? affiliate.banco.valor_agencia : '',
          bankAccount: affiliate.banco.tipo_id === 1 ? affiliate.banco.chave_conta : '',
        },
        contact: {
          email: affiliate.contatos.email,
          phone: affiliate.contatos.telefone,
          whatsApp: affiliate.contatos.whatsapp,
        }
      }));

      setAffiliates(parsedAffiliates);
      setLastPage(maxPage);
    } catch (error) {
      console.error(error);
      
      toast.error('Não foi possível carregar os afiliados!');
    } finally {
      setIsLoading(false);
    }
  }

  async function handleClosePaymentsReport() {
    try {
      setIsClosingReport(true);

      const { data } = await PaymentsService.generatePaymentsReport();

      toast.success(data.dados);

      getAffiliates();
    } catch (err) {
      const error = err as AxiosAPIError;
        
      console.error(error);
      
      if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
        error.response.data.dados.forEach(err => toast.error(err));
      } else {
        toast.error(error.message);
      }
    } finally {
      setIsClosingReport(false);
    }
  }

  async function handleChangeAffiliateSituation(isApproving: boolean) {
    try {
      if (!selectedAffiliate) throw new Error('Não foi possível capturar o uuid do afiliado');

      setIsSubmitting(true);

      const { data } = await AffiliatesService.updateAdminAffiliateStatus({
        uuid: selectedAffiliate.id,
        status_id: isApproving ? 1 : 2,
      });

      const updatedAffiliates = affiliates.map(affiliate => {
        if (affiliate.id !== data.uuid) return affiliate;

        return ({
          ...affiliate,
          status: {
            id: data.status_id,
            description: data.status_descricao,
            color: data.status_cor,
          },
        })
      });

      setAffiliates(updatedAffiliates);
      setIsChangeAffiliateSituationModalVisible(false);
      toast.success(data.resposta);
    } catch (err) {
      const error = err as AxiosAPIError;
        
      console.error(error);
      
      if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
        error.response.data.dados.forEach(err => toast.error(err));
      } else {
        toast.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  function handleFileClick() {
    if (!receiptRef.current) return;

    receiptRef.current.click();
  }

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function handleClearFilters() {
    filterFormik.resetForm();
    setIsFilterModalOpened(false);
    setFilters(undefined);
  }

  return (
    <>
      <FilterModal 
        visible={isFilterModalOpened}
        title='Filtros'
        onRequestClose={() => setIsFilterModalOpened(false)}
      >
        <form onSubmit={filterFormik.handleSubmit}>
          <Input 
            label="Nome"
            name="name"
            value={filterFormik.values.name}
            onChange={filterFormik.handleChange}
            error={(filterFormik.touched.name || undefined) && filterFormik.errors.name}
          />

          <InputGroup>
            <Input
              label="CPF"
              name="cpf"
              mask={[/\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, ".", /\d/, /\d/, /\d/, "-", /\d/, /\d/]}
              value={filterFormik.values.cpf}
              onChange={filterFormik.handleChange}
              error={(filterFormik.touched.cpf || undefined) && filterFormik.errors.cpf}
            />
          </InputGroup>
          
          <InputGroup layout="1fr 2fr">
            <Select
              label="UF"
              name="uf"
              options={UFOptions}
              value={filterFormik.values.uf}
              onChange={(selectedOption) => {
                filterFormik.setFieldValue('uf', selectedOption);
                filterFormik.setFieldValue('city', null, false);
              }}
              isDisabled={isLoadingUFOptions}
              error={(filterFormik.touched.uf || undefined) && filterFormik.errors.uf}
            />

            <Select
              label="Cidade"
              name="city"
              options={cityOptions}
              value={filterFormik.values.city}
              onChange={(selectedOption) => filterFormik.setFieldValue('city', selectedOption)}
              isDisabled={filterFormik.values.uf === null || isLoadingCityOptions}
              error={(filterFormik.touched.city || undefined) && filterFormik.errors.city}
            />
          </InputGroup>
          
          <Select 
            label="Status do pagamento"
            name="paymentStatus"
            options={paymentStatusOptions}
            value={filterFormik.values.paymentStatus}
            onChange={(selectedOption) => filterFormik.setFieldValue('paymentStatus', selectedOption)}
            error={(filterFormik.touched.paymentStatus || undefined) && filterFormik.errors.paymentStatus}
          />

          <InputGroup>
            <Select 
              label="Situação"
              name="situation"
              options={situationOptions}
              value={filterFormik.values.situation}
              onChange={(selectedOption) => filterFormik.setFieldValue('situation', selectedOption)}
              error={(filterFormik.touched.situation || undefined) && filterFormik.errors.situation}
            />
          </InputGroup>

          <footer>
            {filters !== undefined && (
              <Button variant='secondary' onClick={handleClearFilters}>
                Limpar filtros
              </Button>
            )}

            <Button type='submit'>
              Filtrar
            </Button>
          </footer>
        </form>
      </FilterModal>

      <ChangeAffiliateSituationModal 
        visible={isChangeAffiliateSituationModalVisible && selectedAffiliate !== null}
        title="Atenção"
        onRequestClose={() => setIsChangeAffiliateSituationModalVisible(false)} 
      >
        {selectedAffiliate 
          ? ![1, 2].includes(selectedAffiliate.status.id)
            ? (
              <>
                <p className="text">
                  O que você deseja fazer com a situação do afiliado <strong>{selectedAffiliate.name}</strong>?
                </p>

                <div>
                  <Button
                    variant="secondary"
                    onClick={() => handleChangeAffiliateSituation(false)}
                  >
                    Reprovar
                  </Button>

                  <Button
                    variant="primary"
                    onClick={() => handleChangeAffiliateSituation(true)}
                  >
                    Aprovar
                  </Button>
                </div>
              </>
            )
            : (
              <>
                <p className='text'>
                  Deseja realmente {selectedAffiliate.status.id === 1 ? 'desativar' : 'ativar'} o afiliado <strong>{selectedAffiliate.name}</strong>?
                </p>

                <div className='buttons'>
                  <button 
                    onClick={() => setIsChangeAffiliateSituationModalVisible(false)}
                  >
                    Não
                  </button>

                  <button 
                    className='primary'
                    onClick={() => handleChangeAffiliateSituation(selectedAffiliate.status.id === 2)}
                  >
                    Sim
                  </button>
                </div>
              </>
            )
          : <span />
        }
      </ChangeAffiliateSituationModal>

      <DataModal 
        visible={isDataModalVisible && selectedAffiliate !== null}
        title="Dados" 
        onRequestClose={() => setIsDataModalVisible(false)}
      >
        <Tabs>
          <Tab 
            className={selectedTab === "bank" ? 'active' : ''}
            onClick={() => setSelectedTab('bank')}
          >
            Dados bancários
          </Tab>

          <Tab 
            className={selectedTab === "contacts" ? 'active' : ''}
            onClick={() => setSelectedTab('contacts')}
          >
            Contatos
          </Tab>
          
          <Tab 
            className={selectedTab === "addresses" ? 'active' : ''}
            onClick={() => setSelectedTab('addresses')}
          >
            Endereço
          </Tab>
        </Tabs>
        {
          selectedTab === 'bank'
          ? (
            <>
              <Subtitle>Dados bancários</Subtitle>
              <List>
                <li>
                  <strong>Banco:</strong>
                  <span>{selectedAffiliate?.bank.name}</span>
                </li>

                <li>
                  <strong>{selectedAffiliate?.bank.receiptType === 'pix' ? 'Tipo de Chave' : 'Agência'}:</strong>
                  <span>{selectedAffiliate?.bank.receiptType === 'pix' ? selectedAffiliate.bank.keyType : selectedAffiliate?.bank.bankAgency}</span>
                </li>

                <li>
                  <strong>{selectedAffiliate?.bank.receiptType === 'pix' ? 'Chave' : 'Conta'}:</strong>
                  <span>{selectedAffiliate?.bank.receiptType === 'pix' ? selectedAffiliate.bank.keyValue : selectedAffiliate?.bank.bankAccount}</span>
                </li>
              </List>
            </>
          )
          : null
        }

        {
          selectedTab === 'contacts'
          ? (
            <>
              <Subtitle>Contatos</Subtitle>
              <List>
                <li>
                  <strong>E-mail:</strong>
                  <span>{selectedAffiliate?.contact.email || '--------'}</span>
                </li>

                <li>
                  <strong>Telefone:</strong>
                  <span>{selectedAffiliate?.contact.phone || '--------'}</span>
                </li>

                <li>
                  <strong>WhatsApp:</strong>
                  <span>{selectedAffiliate?.contact.whatsApp || '--------'}</span>
                </li>
              </List>
            </>
          )
          : null
        }

        {
          selectedTab === 'addresses'
          ? (
            <>
              <Subtitle>Endereço</Subtitle>
              <List>
                <li>
                  <strong>CEP:</strong>
                  <span>{selectedAffiliate?.address.cep || '--------'}</span>
                </li>

                <li>
                  <strong>Endereço:</strong>
                  <span>{selectedAffiliate?.address.address || '--------'}</span>
                </li>

                <li>
                  <strong>Número:</strong>
                  <span>{selectedAffiliate?.address.number || '--------'}</span>
                </li>

                <li>
                  <strong>Bairro:</strong>
                  <span>{selectedAffiliate?.address.neighborhood || '--------'}</span>
                </li>

                <li>
                  <strong>Complemento:</strong>
                  <span>{selectedAffiliate?.address.complement || '--------'}</span>
                </li>

                <li>
                  <strong>Cidade:</strong>
                  <span>{selectedAffiliate?.address.city || '--------'}</span>
                </li>

                <li>
                  <strong>Estado:</strong>
                  <span>{selectedAffiliate?.address.uf || '--------'}</span>
                </li>
              </List>
            </>
          )
          : null
        }
      </DataModal>
      
      <PaymentsListModal
        visible={isConfirmPaymentModalVisible && selectedAffiliate !== null && selectedPayment === null}
        onRequestClose={() => setIsConfirmPaymentModalVisible(false)}
        title="Pagamentos"
      >
        <Subtitle>Dados Bancários</Subtitle>
        <List>
          <li>
            <strong>Banco:</strong>
            <span>{selectedAffiliate?.bank.name}</span>
          </li>

          <li>
            <strong>{selectedAffiliate?.bank.receiptType === 'pix' ? 'Tipo de Chave' : 'Agência'}:</strong>
            <span>{selectedAffiliate?.bank.receiptType === 'pix' ? selectedAffiliate.bank.keyType : selectedAffiliate?.bank.bankAgency}</span>
          </li>

          <li>
            <strong>{selectedAffiliate?.bank.receiptType === 'pix' ? 'Chave' : 'Conta'}:</strong>
            <span>{selectedAffiliate?.bank.receiptType === 'pix' ? selectedAffiliate.bank.keyValue : selectedAffiliate?.bank.bankAccount}</span>
          </li>
        </List>

        <Subtitle>Listagem de Pagamentos</Subtitle>
        <div>
          {fakerPayments.map(payment => (
            <ResultCard key={payment.id}>
              <ItemContainer>
                <div>
                  <ItemValue>{payment.goal}</ItemValue>
                  <ItemTitle>Meta</ItemTitle>
                </div>
              </ItemContainer>

              <ItemDivider />

              <ItemContainer>
                <div>
                  <ItemValue>{formatToBRL(payment.amount)}</ItemValue>
                  <ItemTitle>Valor</ItemTitle>
                </div>
              </ItemContainer>

              <ItemDivider color={theme.colors.helpers[payment.status.color]} />

              <ItemContainer>
                <div>
                  <ItemValue color={theme.colors.helpers[payment.status.color]}>
                    {payment.status.name}
                  </ItemValue>
                  <ItemTitle>Status</ItemTitle>
                </div>
              </ItemContainer>

              <Button
                onClick={() => setSelectedPayment(payment)}
                disabled={payment.status.name.toLowerCase() === 'aprovado'}
              >
                Pagar
              </Button>
            </ResultCard>
          ))}
        </div>
      </PaymentsListModal>
      
      <ConfirmPaymentModal
        visible={isConfirmPaymentModalVisible && selectedAffiliate !== null && selectedPayment !== null}
        onRequestClose={() => setSelectedPayment(null)}
        title="Confirmar pagamento"
      >
        <form onSubmit={confirmPaymentFormik.handleSubmit}>
          <InputGroup>
            <Select 
              label="Banco"
              name="bank"
              options={bankOptions}
              value={confirmPaymentFormik.values.bank}
              onChange={(selectedOption) => confirmPaymentFormik.setFieldValue('bank', selectedOption)}
              isLoading={isLoadingBankOptions}
              isDisabled={isLoadingBankOptions}
              error={(confirmPaymentFormik.touched.bank || undefined) && confirmPaymentFormik.errors.bank}
            />

            <Select 
              label="Origem"
              name="origin"
              options={[
                { value: 'Origem 01', label: 'Origem 01' },
                { value: 'Origem 02', label: 'Origem 02' },
                { value: 'Origem 03', label: 'Origem 03' },
                { value: 'Origem 04', label: 'Origem 04' },
              ]}
              value={confirmPaymentFormik.values.origin}
              onChange={(selectedOption) => confirmPaymentFormik.setFieldValue('origin', selectedOption)}
              error={(confirmPaymentFormik.touched.origin || undefined) && confirmPaymentFormik.errors.origin}
            />
          </InputGroup>

          <DatePicker 
            label="Data de Pagamento"
            name="date"
            selected={confirmPaymentFormik.values.date}
            onChange={(date) => confirmPaymentFormik.setFieldValue('date', date)}
            error={(confirmPaymentFormik.touched.date || undefined) && confirmPaymentFormik.errors.date}
            showYearDropdown
            maxDate={new Date()}
            isClearable={false}
          /> 

          <ReceiptFile 
            type="button"
            className={
              confirmPaymentFormik.touched.receipt && confirmPaymentFormik.errors.receipt
              ? 'error'
              : ''
            }
            onClick={handleFileClick}
          >
            {
              confirmPaymentFormik.values.receipt === null
              ? (
                <>
                  <span>
                    <RiFileAddLine />
                  </span>
      
                  <p>Anexar comprovante</p>
                </>
              )
              : (
              <p>
                <RiFile3Line />
                {confirmPaymentFormik.values.receipt.name}
              </p>
              )
            }

            <input 
              ref={receiptRef}
              type='file' 
              accept={allowedReceiptTypes.join(',')}
              onChange={(e) => confirmPaymentFormik.setFieldValue('receipt', e.target.files ? e.target.files[0] : null)}
            />
          </ReceiptFile>

          {
            (confirmPaymentFormik.touched.receipt || undefined) 
            && confirmPaymentFormik.errors.receipt 
            && (
            <StyledError>{confirmPaymentFormik.errors.receipt}</StyledError>
            )
          }
          
          <Button type="submit">
            Confirmar
          </Button>
        </form>
      </ConfirmPaymentModal>
      
      <LoadingModal visible={isSubmitting} />

      <Content>
        <ContentWrapper className="content">
          <Header>
            <div>
              <Title>Configurações | Afiliados</Title>
              <Description>Listagem dos afiliados para pagamento.</Description>
            </div>

            <Button
              variant="tertiary" 
              onClick={() => handleClosePaymentsReport()}
              disabled={isClosingReport}
            >
              {isClosingReport ? <Loading /> : 'Fechar relatório de pagamentos'}
            </Button>
          </Header>

          <OrderingContainer>
            <Select
              label="Classificar por"
              options={orderSelectItems}
              value={orderSelectItems.find(option => option.value === selectedOrder)}
              onChange={(option) => {
                setSelectedOrder(option?.value || "created_at");
                setCurrentPage(1);
              }}
              isClearable={false}
            />

            <div className="order">
              <Select
                label="Exibir"
                options={limitSelectItems}
                value={limitSelectItems.find(option => option.label === String(itemsPerPage))}
                onChange={(selectedOption) => {
                  setItemsPerPage(Number(selectedOption?.value) || 10);
                  setCurrentPage(1);
                }}
                isClearable={false}
              />

              <Tooltip content={`Ordem ${isOrderAscending ? 'crescente' : 'decrescente'}`}>
                <Button 
                  variant='tertiary'
                  onClick={() => setIsOrderAscending((prevState) => !prevState)}
                >
                  {isOrderAscending ? <RiArrowDownLine /> : <RiArrowUpLine />}
                </Button>
              </Tooltip>

            </div>

            <Button 
              variant='tertiary' 
              onClick={() => setIsFilterModalOpened(true)}
            >
              <RiFilterLine />
              {filters === undefined ? 'Filtrar' : 'Exibir filtros'}
            </Button>

            {filters !== undefined && (
              <Button 
                variant='tertiary'
                onClick={handleClearFilters}
              >
                <RiFilterOffLine />
                Limpar filtros
              </Button>
            )}
          </OrderingContainer>

          <ResultsContainer>
            {isLoading
              ? <Loading /> 
              : affiliates.map(affiliate => (
                <ResultCard key={affiliate.id} onClick={() => setSelectedAffiliate(affiliate)}>
                  <ItemContainer>
                    <div>
                      <ItemValue title={affiliate.name}>
                        {affiliate.name}
                      </ItemValue>
                      <ItemTitle>Nome</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ItemDivider />

                  <ItemContainer>
                    <div>
                      <ItemValue title={`${affiliate.address.city}/${affiliate.address.uf}`}>
                        {affiliate.address.city || '--------'}/{affiliate.address.uf || '--'}
                      </ItemValue>
                      <ItemTitle>Cidade/UF</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ItemDivider />

                  <ItemContainer>
                    <div>
                      <ItemValue title={String(affiliate.registeredLeads + '/' + affiliate.goal)}>
                        {affiliate.registeredLeads}/{affiliate.goal}
                      </ItemValue>
                      <ItemTitle>Meta</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ItemDivider />

                  <ItemContainer>
                    <div>
                      <ItemValue title={formatToBRL(affiliate.payment.amount)}>
                        {formatToBRL(affiliate.payment.amount)}
                      </ItemValue>
                      <ItemTitle>Valor do pagamento</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ItemDivider 
                    color={theme.colors.helpers[affiliate.payment.status.color]} 
                  />

                  <ItemContainer>
                    <div>
                      <ItemValue 
                        title={affiliate.payment.status.description}
                        color={theme.colors.helpers[affiliate.payment.status.color]} 
                      >
                        {affiliate.payment.status.description}
                      </ItemValue>
                      <ItemTitle>Status do pagamento</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ItemDivider 
                    title={affiliate.status.description}
                    color={theme.colors.helpers[affiliate.status.color]} 
                  />

                  <ItemContainer>
                    <div>
                      <ItemValue 
                        title={affiliate.status.description}
                        color={theme.colors.helpers[affiliate.status.color]} 
                      >
                        {affiliate.status.description}
                      </ItemValue>
                      <ItemTitle>Situação</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ActionsContainer>
                    <Tooltip 
                      content={
                        affiliate.status.id === 1 
                        ? 'Desativar afiliado' 
                        : affiliate.status.id === 2 
                          ? 'Ativar afiliado'
                          : 'Ativar/Desativar afiliado'
                      }
                    >
                      <ActionButton
                        color={theme.colors.helpers.warning}
                        onClick={() => setIsChangeAffiliateSituationModalVisible(true)}
                      >
                        {
                          affiliate.status.id === 1
                          ? <RiCloseLine />
                          : affiliate.status.id === 2
                            ? <RiCheckDoubleLine /> 
                            : <RiArrowLeftRightLine />
                        }
                      </ActionButton>
                    </Tooltip>

                    <Tooltip content="Dados">
                      <ActionButton
                        color={theme.colors.helpers.info}
                        onClick={() => setIsDataModalVisible(true)}
                      >
                        <RiUserLine />
                      </ActionButton>
                    </Tooltip>

                    <Tooltip 
                      content={
                        affiliate.payment.status.description === 'Aprovado' 
                        ? 'Cancelar pagamento' 
                        : 'Confirmar pagamento'
                      }>
                      <ActionButton
                        color={
                          affiliate.payment.status.description === 'Aprovado' 
                          ? theme.colors.helpers.danger 
                          : theme.colors.helpers.success
                        }
                        onClick={() => {
                          if (affiliate.payment.status.description === 'Aprovado') {
                            alert('Pagamento cancelado!');
                            return;
                          }

                          setIsConfirmPaymentModalVisible(true);
                        }}
                      >
                        <RiMoneyDollarCircleLine />
                      </ActionButton>
                    </Tooltip>
                  </ActionsContainer>
                </ResultCard>
              ))
            }
          </ResultsContainer>
        </ContentWrapper>

        <Footer>
          <div className="content">
            <Pagination>
              <PageButton
                className="prev"
                onClick={goToPreviousPage}
                disabled={currentPage === 1}
              >
                <ChevronDown />
              </PageButton>

              <Page>{String(currentPage).padStart(2, "0")}</Page>

              <PageButton
                className="next"
                onClick={goToNextPage}
                disabled={currentPage === lastPage}
              >
                <ChevronDown />
              </PageButton>
            </Pagination>
          </div>
        </Footer>
      </Content>
    </>
  );
}
