import styled from "styled-components";
import chroma from "chroma-js";

import { Container as Button } from "@components/Button/styles";

import { convertToRem } from "@utils/convertToRem";

export const Container = styled.div<{ cardBorderColor: string; }>`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  padding: ${convertToRem(28)} ${convertToRem(20)};

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.text};

  font-size: ${convertToRem(15)};

  border-radius: ${convertToRem(8)};
  box-shadow: 0 ${convertToRem(2)} ${convertToRem(8)} rgba(0, 0, 0, 0.075);

  overflow: hidden;

  .info-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    width: 100%;
    
    .info {
      flex: 1;
      
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      > span {
        font-size: ${convertToRem(14)};
        
        &:not(:first-child) {
          margin-top: ${convertToRem(4)};
        }
    
        &.date {
          font-size: ${convertToRem(12)};
        }
    
        &.source {
          margin-top: ${convertToRem(8)};
        }
      }
    
      > strong {
        margin-top: ${convertToRem(8)};
    
        color: ${({ theme }) => theme.colors.primary.base};
        
        font-size: ${convertToRem(18)};
      }
    }

    > button {
      display: flex;
      align-items: center;
      justify-content: center;

      width: ${convertToRem(28)};
      height: ${convertToRem(28)};

      margin-left: ${convertToRem(8)};
      
      background-color: ${({ theme }) => chroma(theme.colors.primary.light).alpha(0.2).css()};
      color: ${({ theme }) => theme.colors.primary.base};

      border: 0;
      border-radius: ${convertToRem(4)};

      > svg {
        width: auto;
        height: ${convertToRem(20)};

        transition: transform ease 0.3s;
      }
    }
  }

  .details {
    display: none;
  }

  .procedures {
    width: 100%;

    margin-top: ${convertToRem(16)};
    padding: ${convertToRem(14)} 0;

    border-top: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

    li {
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        font-size: ${convertToRem(13)};
      }

      strong, button {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        margin-top: ${convertToRem(4)};

        background-color: transparent;

        font-weight: 500;
        font-size: ${convertToRem(13)};

        border: 0;

        svg {
          width: auto;
          height: ${convertToRem(16)};

          margin-right: ${convertToRem(4)};
        }
      }

      button {
        color: ${({ theme }) => theme.colors.primary.base};
      }

      &:not(:first-child) {
        margin-top: ${convertToRem(12)};
      }
    }
  }

  .contacts {
    width: 100%;

    margin-bottom: ${convertToRem(16)};
    padding: ${convertToRem(10)} 0;

    border-top: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
    border-bottom: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

    .contact {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        font-weight: 500;
        font-size: ${convertToRem(14)};

        svg {
          margin-right: ${convertToRem(6)};

          color: ${({ theme }) => theme.colors.primary.highlight};
        }
      }

      > a {
        padding: ${convertToRem(4)} ${convertToRem(6)};

        font-weight: 500;
        font-size: ${convertToRem(12)};

        border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.primary.highlight};
        border-radius: ${convertToRem(4)};
      }

      &.phone {
        span svg {
          color: ${({ theme }) => theme.colors.helpers.info};
        }

        > a {
          color: ${({ theme }) => theme.colors.helpers.info};

          border-color: ${({ theme }) => theme.colors.helpers.info};
        }
      }

      &.whatsapp {
        span svg {
          color: ${({ theme }) => theme.colors.helpers.success};
        }

        > a {
          color: ${({ theme }) => theme.colors.helpers.success};

          border-color: ${({ theme }) => theme.colors.helpers.success};
        }
      }

      &.mail span svg {
        color: ${({ theme }) => theme.colors.helpers.warning};
      }

      &:not(:first-child) {
        margin-top: ${convertToRem(8)};
      }
    }
  }

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: ${convertToRem(8)};
  
    width: 100%;

    ${Button} {
      min-width: auto;
      min-height: auto;

      padding: ${convertToRem(8)};

      font-size: ${convertToRem(14)};

      svg {
        width: auto;
        height: ${convertToRem(16)};

        margin-right: ${convertToRem(4)};
      }
    }
  }

  &::before {
    content: '';

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;

    width: ${convertToRem(6)};

    background-color: ${({ cardBorderColor }) => cardBorderColor};
  }

  &.expanded {
    .info-wrapper > button > svg {
      transform: rotate(180deg);
    }

    .details {
      display: block;
    }
  }


  & + & {
    margin-top: ${convertToRem(15)};
  }

  @media (max-width: 768px) {
    min-width: 320px;

    & + & {
      margin-top: 0;
    }
  }

 
`;