import React from 'react';
import { RadioGroupProps, RadioProps } from './Radio';

import { InputRadio, RadioContainer, RadioGroupContainer, RadioGroupLabel, RadioGroupOptions, RadioLabel } from './styles';

export function RadioGroup({ label, children, ...rest }: RadioGroupProps) {
  return (
    <RadioGroupContainer {...rest}>
      <RadioGroupLabel>{label}</RadioGroupLabel>

      <RadioGroupOptions>
        {children}
      </RadioGroupOptions>
    </RadioGroupContainer>
  );
}

export function RadioInput({ label, name, isChecked = false, value, handleChange, ...rest }: RadioProps) {
  function handleRadioChange(e: React.ChangeEvent<HTMLInputElement>) {
    const { value } = e.currentTarget;

    handleChange(value);
  }

  return (
    <RadioContainer htmlFor={name}>
      <InputRadio 
        type="radio"
        id={name}
        name={name}
        checked={isChecked}
        value={value}
        onChange={handleRadioChange}
        {...rest}
      />

      <RadioLabel>{label}</RadioLabel>
    </RadioContainer>
  );
}