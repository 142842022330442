import { useEffect, useState } from "react";
import { format } from "date-fns";
import { toast } from "react-toastify";

import { Description, Title } from "@components/Header";
import { Card } from "@components/Card";
import { Loading } from "@components/Loading";

import ClinicsService, { Clinic } from "@services/ClinicsService";
import PatientsService, { Patient } from "@services/PatientsService";

import {
  Cart,
  ChevronDown,
  Dentist,
  Doctor,
  Hospital,
  Stethoscope,
  User,
} from "@assets/images";

import { formatToBRL } from "@utils/formatToBRL";

import {
  AccessCard,
  AccessContainer,
  CardsContainer,
  ClinicCard,
  ClinicsContainer,
  Content,
  ContentWrapper,
  Footer,
  FutureIncome,
  Page,
  PageButton,
  Pagination,
} from "./styles";


const stats = {
  income: {
    value: 0,
    future: 1000,
  },
  consultations: 0,
  clinicProfessionals: {
    clinics: 12,
    professionals: 200,
  },
  patients: 1175,
};

const accessItemPerPage = 4;
const registeredClinicsPerPage = 9;

export function Reports() {
  const [isLoadingClinics, setIsLoadingClinics] = useState(true);
  const [clinics, setClinics] = useState<Clinic[]>([]);
  const [clinicCurrentPage, setClinicCurrentPage] = useState(1);
  const [registeredClinicsCurrentPage, setRegisteredClinicsCurrentPage] = useState(1);

  const [isLoadingPatients, setIsLoadingPatients] = useState(true);
  const [patients, setPatients] = useState<Patient[]>([]);
  const [patientCurrentPage, setPatientCurrentPage] = useState(1);
  
  const [isLoadingConsultationsType, setIsLoadingConsultationsType] = useState(true);

  useEffect(() => {
    async function getClinics() {
      try {
        setIsLoadingClinics(true);

        const clinicsData = await ClinicsService.listLastAccessedClinics();

        setClinics(clinicsData);
      } catch (error) {
        toast.error('Não foi possível carregar dados das clínicas!');

        console.log(error)
      } finally {
        setIsLoadingClinics(false);
      }
    }

    async function getPatients() {
      try {
        setIsLoadingPatients(true);

        const patientsData = await PatientsService.listLastAccessedPatients();

        setPatients(patientsData);
      } catch (error) {
        toast.error('Não foi possível carregar dados dos pacientes!');

        console.log(error)
      } finally {
        setIsLoadingPatients(false);
        setIsLoadingConsultationsType(false);
      }
    }

    getClinics();
    getPatients();
  }, []);

  const lastAccessedClinics = clinics
    .filter(clinic => !!clinic.lastAccess)
    .sort((a, b) => (b.lastAccess as Date).getTime() - (a.lastAccess as Date).getTime())
  ;

  const sortedClinicsByRegistrationDate = clinics.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());

  return (
    <Content>
      <ContentWrapper className="content">
        <Title>Painel Administrativo</Title>
        <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Description>

        <CardsContainer>
          <Card
            className="income"
            icon={<Cart />}
            title="Faturamento Mês"
            value={formatToBRL(stats.income.value)}
          >
            <FutureIncome>
              <span>Faturamento futuro</span>
              <strong>{formatToBRL(stats.income.future)}</strong>
            </FutureIncome>
          </Card>

          <Card
            icon={<Stethoscope />}
            title="Consultas do Mês"
            value={String(stats.consultations).padStart(2, "0")}
          />
          
          <Card
            icon={<Hospital />}
            title="Clínicas / Profissionais"
            value={stats.clinicProfessionals.clinics + ' / ' + stats.clinicProfessionals.professionals}
          />

          <Card
            icon={<User />}
            title="Pacientes"
            value={stats.patients}
          />
        </CardsContainer>
        
        <AccessContainer>
          <div>
            <h2>Últimos Acessos Clinicas</h2>

            <div className="access-grid">
              {isLoadingClinics 
                ? <Loading />
                : lastAccessedClinics
                  .slice((clinicCurrentPage - 1) * accessItemPerPage, clinicCurrentPage * accessItemPerPage)
                  .map(clinic => (
                    <AccessCard key={clinic.id}>
                      <img src={clinic.avatar} alt={clinic.name} />

                      <div>
                        <h5>{clinic.name}</h5>
                        <strong>{format(clinic.lastAccess as Date, 'dd/MM/yyyy')}</strong>
                      </div>
                    </AccessCard>
                  )
                )
              }
            </div>

            <div className="nav-btns">
              <PageButton
                onClick={() => {
                  if (clinicCurrentPage === 1) return;

                  setClinicCurrentPage(clinicCurrentPage - 1);
                }}
                disabled={clinicCurrentPage === 1}
              >
                <ChevronDown />
              </PageButton>

              <PageButton
                onClick={() => {
                  if (clinicCurrentPage === Math.ceil(lastAccessedClinics.length / accessItemPerPage)) return;

                  setClinicCurrentPage(clinicCurrentPage + 1);
                }}
                disabled={clinicCurrentPage === Math.ceil(lastAccessedClinics.length / accessItemPerPage)}
              >
                <ChevronDown />
              </PageButton>
            </div>
          </div>

          <div>
            <h2>Últimos Acessos Pacientes</h2>
            
            <div className="access-grid">
              {isLoadingPatients 
                ? <Loading />
                : patients
                  .slice((patientCurrentPage - 1) * accessItemPerPage, patientCurrentPage * accessItemPerPage)
                  .map(patient => (
                    <AccessCard key={patient.id}>
                      <img src={patient.avatar} alt={patient.name} />

                      <div>
                        <h5>{patient.name}</h5>
                        <strong>{format(patient.lastAccess, 'dd/MM/yyyy')}</strong>
                      </div>
                    </AccessCard>
                  )
                )
              }
            </div>

            <div className="nav-btns">
              <PageButton
                onClick={() => {
                  if (patientCurrentPage === 1) return;

                  setPatientCurrentPage(patientCurrentPage - 1);
                }}
                disabled={patientCurrentPage === 1}
              >
                <ChevronDown />
              </PageButton>

              <PageButton
                onClick={() => {
                  if (patientCurrentPage === Math.ceil(patients.length / accessItemPerPage)) return;

                  setPatientCurrentPage(patientCurrentPage + 1);
                }}
                disabled={patientCurrentPage === Math.ceil(patients.length / accessItemPerPage)}
              >
                <ChevronDown />
              </PageButton>
            </div>
          </div>

          <div className="consultation-type">
            <h2>Tipos de Consulta</h2>

            <div className="access-grid">
              {isLoadingConsultationsType
                ? <Loading />
                : (
                  <>
                    <AccessCard className="span-card">
                      <div>
                        <h5>Consultas Médicas</h5>

                        <div>
                          <h4>1175</h4>
                          <span>10%</span>
                        </div>
                      </div>

                      <Doctor />
                    </AccessCard>

                    <AccessCard className="span-card">
                      <div>
                        <h5>Consultas Odontológicas</h5>

                        <div>
                          <h4>1175</h4>
                          <span>10%</span>
                        </div>
                      </div>

                      <Dentist />
                    </AccessCard>
                  </>
                )
              }
            </div>
          </div>
        </AccessContainer>

        <ClinicsContainer>
          <h2>Clínicas Cadastradas</h2>

          <div className="clinics-grid">
            {isLoadingClinics
              ? <Loading />
              : sortedClinicsByRegistrationDate
                .slice((registeredClinicsCurrentPage - 1) * registeredClinicsPerPage, registeredClinicsCurrentPage * registeredClinicsPerPage)
                .map(clinic => (
                  <ClinicCard key={clinic.id}>
                    <div>
                      <h4>{clinic.name}</h4>
                      <h5>Nome</h5>
                    </div>

                    <button>
                      <ChevronDown />
                    </button>
                  </ClinicCard>
              ))
            }
          </div>
        </ClinicsContainer>
      </ContentWrapper>

      <Footer>
        <div className="content">
          <Pagination>
            <PageButton
              className="prev"
              onClick={() => {
                if (registeredClinicsCurrentPage === 1) return;

                setRegisteredClinicsCurrentPage(registeredClinicsCurrentPage - 1);
              }}
              disabled={registeredClinicsCurrentPage === 1}
            >
              <ChevronDown />
            </PageButton>

            <Page>{String(registeredClinicsCurrentPage).padStart(2, "0")}</Page>

            <PageButton
              className="next"
              onClick={() => {
                if (registeredClinicsCurrentPage === Math.ceil(clinics.length / registeredClinicsPerPage)) return;

                setRegisteredClinicsCurrentPage(registeredClinicsCurrentPage + 1);
              }}
              disabled={registeredClinicsCurrentPage === Math.ceil(clinics.length / registeredClinicsPerPage)}
            >
              <ChevronDown />
            </PageButton>
          </Pagination>
        </div>
      </Footer>
    </Content>
  );
}
