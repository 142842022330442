import { Navigate, Route, Routes } from "react-router-dom";

// Pages
import { Clinics } from "./Clinics";
import { Affiliates } from "./Affiliates";
import { Cidades } from "./Cidades";

export function Settings() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="clinicas" />} />
      <Route path="clinicas" element={<Clinics />} />
      <Route path="afiliados" element={<Affiliates />} />
      <Route path="cidades-cadastradas" element={<Cidades />} />
    </Routes>
  );
}