import { Navigate, Route, Routes } from "react-router-dom";

import { useAuth } from "@contexts/auth";

import { Sidebar } from "@components/Sidebar";
import { Header } from "@components/Header";

import { Professionals } from "@pages/Patient/Professionals";
import { Pharmacies } from "@pages/Patient/Pharmacies";
import { Cards } from "@pages/Patient/Cards";
import { EditRegistration } from "@pages/Patient/EditRegistration";
import { History } from "@pages/Patient/History";
import { Dependents } from "@pages/Patient/Dependents";
import { Plans } from "@pages/Patient/Plans";

import { NotFound } from "@components/NotFound";

import { Container, Main } from "./styles";

export function PatientRoutes() {
  const { selectedAccount } = useAuth();

  return (
    <Container>
      <Sidebar />

      <Main>
        <Header />

        <Routes>
          <Route path="/" element={<Navigate to="/profissionais/" />} />
          <Route path="profissionais/*" element={<Professionals />} />
          <Route path="farmacias/" element={<Pharmacies />} />
          <Route path="carteirinha-digital/" element={selectedAccount?.isPrime ? <Cards /> : <Navigate to="/" />} />
          <Route path="editar-cadastro/" element={<EditRegistration />} />
          <Route path="historico/*" element={<History />} />
          <Route path="dependentes/*" element={<Dependents />} />
          <Route path="planos/" element={<Plans />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Main>
    </Container>
  );
}
