import React, { ChangeEvent, forwardRef } from "react";
import ReactDatePicker, {
  ReactDatePickerCustomHeaderProps,
  registerLocale,
} from "react-datepicker";
import {
  eachYearOfInterval,
  format,
  getMonth,
  getYear,
  isSameYear,
  subYears,
} from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

import { Input } from "@components/Input";

import { Calendar } from "@assets/images";

import { capitalizeFirstLetter } from "@utils/capitalizeFirstLetter";

import { DatePickerProps } from "./DatePicker.d";

import "react-datepicker/dist/react-datepicker.css";
import { Container, CustomHeaderContainer } from "./styles";

registerLocale("pt-BR", ptBR);

export function DatePicker<
  CustomModifierNames extends string = never,
  WithRange extends boolean | undefined = undefined
>(props: DatePickerProps<CustomModifierNames, WithRange>) {
  const {
    label,
    name,
    value,
    dateFormat = "dd/MM/yyyy",
    placeholderText = "dd/mm/aaaa",
    onInputClick,
    style,
    error,
    disabled,
    ...rest
  } = props;

  const CustomInput = forwardRef(
    (
      { value, onClick, placeholder }: React.HTMLProps<HTMLInputElement>,
      ref: React.Ref<HTMLInputElement>
    ) => {
      return (
        <Input
          label={label}
          name={name}
          iconRight={<Calendar />}
          value={value}
          onClick={onClick}
          placeholder={placeholder}
          error={error}
          disabled={disabled}
          readOnly
        />
      );
    }
  );

  function CustomDatePickerHeader({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    changeYear,
    monthDate,
  }: ReactDatePickerCustomHeaderProps) {
    if (!date) return;

    const today = new Date();

    function handleChangeYear(event: ChangeEvent<HTMLSelectElement>) {
      changeYear(Number(event.target.value));
    }

    return (
      <CustomHeaderContainer>
        {props.showYearDropdown ? (
          <span>
            {capitalizeFirstLetter(format(date, "MMMM", { locale: ptBR }))}

            <select onChange={handleChangeYear}>
              {eachYearOfInterval({
                start: subYears(today, 100),
                end: today,
              }).map((date) => (
                <option
                  value={getYear(date)}
                  selected={isSameYear(date, monthDate)}
                  disabled={
                    isSameYear(date, today) &&
                    getMonth(monthDate) > getMonth(today)
                  }
                >
                  {getYear(date)}
                </option>
              ))}
            </select>
          </span>
        ) : (
          <span>
            {capitalizeFirstLetter(format(date, "MMMM yyyy", { locale: ptBR }))}
          </span>
        )}

        <button
          type="button"
          className="previous-month"
          onClick={decreaseMonth}
          disabled={prevMonthButtonDisabled}
        >
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M10.5429 0.542969L11.9571 1.95718L6.1642 7.75008L11.9571 13.543L10.5429 14.9572L3.33577 7.75008L10.5429 0.542969Z"
            />
          </svg>
        </button>

        <button
          type="button"
          className="next-month"
          onClick={increaseMonth}
          disabled={nextMonthButtonDisabled}
        >
          <svg
            width="1em"
            height="1em"
            viewBox="0 0 16 16"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M5.45712 14.957L4.04291 13.5428L9.8358 7.74992L4.04291 1.95703L5.45712 0.542818L12.6642 7.74992L5.45712 14.957Z"
            />
          </svg>
        </button>
      </CustomHeaderContainer>
    );
  }

  return (
    <Container style={style} hasLabel={!!label}>
      <ReactDatePicker
        locale="pt-BR"
        customInput={<CustomInput value={value} onClick={onInputClick} />}
        renderCustomHeader={CustomDatePickerHeader}
        placeholderText={placeholderText}
        dateFormat={dateFormat}
        showPopperArrow={false}
        isClearable
        {...rest}
      />
    </Container>
  );
}
