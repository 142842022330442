import { useEffect, useState } from 'react';
import { DragDropContext, Draggable, Droppable, DropResult,  } from 'react-beautiful-dnd';
import { RiCalendarCheckLine, RiCloseCircleLine, RiFilterLine, RiFilterOffLine, RiPencilLine,RiSearchLine,RiTimeLine, RiUser2Line } from 'react-icons/ri';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { addDays, format, isSameDay, isSameHour, isSameMinute, isSameMonth, isSameYear, parseISO, set } from 'date-fns';
import {  useTheme } from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { Loading } from '@components/Loading';
import { List, Subtitle } from '@components/Modal';
import { Input, InputGroup } from '@components/Input';
import { Select } from '@components/Select';
import { DatePicker } from '@components/DatePicker';
import { Button } from '@components/Button';
import { TimeInput } from '@components/TimeInput';
import { TextArea } from '@components/TextArea';
import { Error as StyledError } from '@components/Input/styles';
import { KanbanCard } from './KanbanCard';

import CustomersService, { Customer, CustomerStatus } from '@services/CustomersService';
import LocationsService from '@services/LocationsService';

import { genderOptions } from '@constants/genders';
import { bloodTypeOptions } from '@constants/blood-type';

import { useDebounce } from '@hooks/useDebounce';
import { useWindowSize } from '@hooks/useWindowSize';

import { delay } from '@utils/delay';

import { OptionTypeBase } from '@components/Select/Select';

import { ActionsModal, Content, ContentWrapper, ProfessionalModal, FiltersContainer, KanbanCol, KanbanContainer, NoInterestModal, PageContent, ScheduleConsultationModal, ScheduleTaskModal, DataModal, ContactModal, EditDataModal, AdvancedFiltersModal } from './styles';

interface KanbanData {
  id: string;
  order: number;
  title: string;
  customers: Customer[];
}

export type GroupedData = Record<CustomerStatus, Customer[]>;

const employeeOptions: OptionTypeBase[] = [
  { label: 'Todos os colaboradores', value: 'all' }
];

const proceduresOptions: OptionTypeBase[] = [
  { label: 'Todos os procedimentos', value: 'all' }
];

const dateTypeOptions: OptionTypeBase[] = [
  { label: 'Data de criação', value: '0' },
  { label: 'Data de atualização', value: '1' },
];

const sourceOptions: OptionTypeBase[] = [
  { label: 'Todas as fontes', value: 'all' }
];

const priorityOptions: OptionTypeBase[] = [
  { label: 'Todas as prioridades', value: 'all' }
];

const taskDueOptions: OptionTypeBase[] = [
  { label: 'Hoje', value: 'Hoje' },
  { label: 'Amanhã', value: 'Amanhã' },
  { label: 'Depois de amanhã', value: 'Depois de amanhã' },
];

const taskTypeOptions: OptionTypeBase[] = [
  { label: 'E-mail', value: 'E-mail' },
  { label: 'Ligação', value: 'Ligação' },
  { label: 'WhatsApp', value: 'WhatsApp' },
  { label: 'SMS', value: 'SMS' },
];

const taskPriorityOptions: OptionTypeBase[] = [
  { label: 'Baixa', value: 'Baixa' },
  { label: 'Média', value: 'Média' },
  { label: 'Alta', value: 'Alta' },
];

const taskStatusOptions: OptionTypeBase[] = [
  { label: 'Clientes sem contato', value: 'Clientes sem contato' },
  { label: 'Em contato', value: 'Em contato' },
  { label: 'Agendados WhatsApp', value: 'Agendados WhatsApp' },
  { label: 'Agendados Ligação', value: 'Agendados Ligação' },
  { label: 'Agendados Promotora', value: 'Agendados Promotora' },
  { label: 'Faltas', value: 'Faltas' },
  { label: 'Compareceu', value: 'Compareceu' },
  { label: 'Tratamento fechado', value: 'Tratamento fechado' },
  { label: 'Sem interesse', value: 'Sem interesse' },
];

const noInterestReasonOptions: OptionTypeBase[] = [
  { label: 'Não tem interesse', value: 'Não tem interesse' },
  { label: 'Só estava curioso', value: 'Só estava curioso' },
  { label: 'Já agendou em outra clínica', value: 'Já agendou em outra clínica' },
  { label: 'Vai agendar depois', value: 'Vai agendar depois' },
  { label: 'Não tem tempo', value: 'Não tem tempo' },
  { label: 'Não tem dinheiro', value: 'Não tem dinheiro' },
  { label: 'Era um concorrente', value: 'Era um concorrente' },
  { label: 'Outros', value: 'Outros' },
];

interface PersonalInfoFormik {
  name: string;
  rg: string;
  cpf: string;
  birthDate: Date | null;
  gender: OptionTypeBase | null;
  bloodType: OptionTypeBase | null;
  occupation: string;
}

interface AddressInfoFormik {
  zipCode: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  uf: OptionTypeBase | null;
  city: OptionTypeBase | null;
}

interface ScheduleTaskFormik {
  title: string;
  type: OptionTypeBase | null;
  priority: OptionTypeBase | null;
  status: OptionTypeBase | null;
  date: Date | null;
  time: string;
  details: string;
}

interface ScheduleConsultationFormik {
  patient: {
    id: string;
    name: string;
  } | null;
  professional: OptionTypeBase | null;
  date: Date | null;
  time: Date | null;
}

interface ProfessionalFormik {
  professional: OptionTypeBase | null;
}

interface NoInterestFormik {
  reason: OptionTypeBase | null;
  details: string;
}

export function CRM() {
  const theme = useTheme();
  const [windowWidth] = useWindowSize();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<KanbanData[]>([]);

  const [isLoadingUFOptions, setIsLoadingUFOptions] = useState(true);
  const [UFOptions, setUFOptions] = useState<OptionTypeBase[]>([]);
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);

  const [isFiltersContainerVisible, setIsFiltersContainerVisible] = useState(false);

  const [search, setSearch] = useState('');
  
  const debouncedSearch = useDebounce(search);
  const [selectedEmployee, setSelectedEmployee] = useState<OptionTypeBase | null>(null);
  const [selectedProcedure, setSelectedProcedure] = useState<OptionTypeBase | null>(null);
  const [selectedDateType, setSelectedDateType] = useState<OptionTypeBase | null>(null);
  const [selectedDate, setSelectedDate] = useState<[Date | null, Date | null]>([null, null]);
  const [isAdvancedFiltersModalVisible, setIsAdvancedFiltersModalVisible] = useState(false);

  const [selectedSource, setSelectedSource] = useState<OptionTypeBase | null>(null);
  const [selectedPriority, setSelectedPriority] = useState<OptionTypeBase | null>(null);
  const [selectedTaskDue, setSelectedTaskDue] = useState<OptionTypeBase | null>(null);

  const hasSomeFilterFilled = [debouncedSearch, selectedEmployee, selectedProcedure, selectedDateType, selectedDate[0], selectedDate[1], selectedSource, selectedPriority, selectedTaskDue].map(Boolean).some(Boolean);

  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(null);
  const [isDataModalVisible, setIsDataModalVisible] = useState(false);
  const [isEditPersonalInfoModalVisible, setIsEditPersonalInfoModalVisible] = useState(false);

  const personalInfoFormik = useFormik<PersonalInfoFormik>({
    enableReinitialize: true,
    initialValues: {
      name: selectedCustomer?.name || '',
      rg: '24.144.039-76',
      cpf: '241.440.397-69',
      birthDate: parseISO('1997-04-20 00:00') || null,
      gender: genderOptions.find(gender => gender.label === 'Feminino') || null,
      bloodType: bloodTypeOptions.find(type => type.label === 'AB+') || null,
      occupation: 'Assistente Administrativo',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .matches(/(\w.+\s).+/i, "Insira seu nome completo")
        .required("O campo é obrigatório"),
      rg: Yup.string()
        .matches(/^[0-9]{2}.?[0-9]{3}.?[0-9]{3}-?[0-9]{1,2}/, "Insira um RG válido")
        .required("O campo é obrigatório"),
      cpf: Yup.string()
        .matches(/^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/, "Insira um CPF válido")
        .required("O campo é obrigatório"),
      birthDate: Yup.date()
        .required("O campo é obrigatório")
        .typeError("Selecione uma data"),
      gender: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um gênero"),  
      bloodType: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um tipo sanguíneo"),
      occupation: Yup.string()
        .required("O campo é obrigatório"),
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values));
    }
  });

  const [isEditAddressInfoModalVisible, setIsEditAddressInfoModalVisible] = useState(false);

  const addressInfoFormik = useFormik<AddressInfoFormik>({
    enableReinitialize: true,
    initialValues: {
      zipCode: '42156-695',
      street: 'Rua da Amizade',
      number: '365',
      complement: '4º andar',
      neighborhood: 'Felicidade',
      uf: UFOptions.find(uf => uf.label === 'BA') || null,
      city: cityOptions.find(city => city.label === 'Salvador') || null,
    },
    validationSchema: Yup.object().shape({
      zipCode: Yup.string()
        .matches(/^[0-9]{5}-[0-9]{3}$/, "Infome um CEP válido")
        .required("O campo é obrigatório"),
      street: Yup.string()
        .required("O campo é obrigatório"),
      number: Yup.number()
        .typeError("Apenas números")
        .required("O campo é obrigatório"),
      neighborhood: Yup.string()
        .required("O campo é obrigatório"),
      uf: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um estado"),
      city: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione uma cidade"),
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values));
    },
  });

  const [isContactModalVisible, setIsContactModalVisible] = useState(false);

  const [isActionsModalVisible, setIsActionsModalVisible] = useState(false);
  const [isScheduleTaskModalVisible, setIsScheduleTaskModalVisible] = useState(false);

  const scheduleTaskFormik = useFormik<ScheduleTaskFormik>({
    enableReinitialize: true,
    initialValues: {
      title: '',
      type: null,
      priority: null,
      status: null,
      date: null,
      time: '',
      details: ''
    },
    validationSchema: Yup.object().shape({
      title: Yup.string()
        .required("O campo é obrigatório"),
      type: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um tipo"),
      priority: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione uma prioridade"),
      status: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um status"),
      date: Yup.date()
      .required("O campo é obrigatório")
      .typeError("Selecione uma data"),
      time: Yup.string(),
      details: Yup.string(),
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values));
    },
  });

  const [isScheduleConsultationModalVisible, setIsScheduleConsultationModalVisible] = useState(false);
  const [isLoadingAvailableTimes, setIsLoadingAvailableTimes] = useState(true);
  const [availableTimes, setAvailableTimes] = useState<Date[] | null>(null);

  const scheduleConsultationFormik = useFormik<ScheduleConsultationFormik>({
    enableReinitialize: true,
    initialValues: {
      patient: selectedCustomer ? {
        id: selectedCustomer.id,
        name: selectedCustomer.name
      } : null,
      professional: null,
      date: null,
      time: null,
    },
    validationSchema: Yup.object().shape({
      patient: Yup.object()
        .shape({ id: Yup.string(), name: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um cliente"),
      professional: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um tipo"),
      date: Yup.date()
        .required("O campo é obrigatório")
        .typeError("Selecione uma data"),
      time: Yup.date()
        .required("O campo é obrigatório")
        .typeError("Selecione um horário"),
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values));
    },
  });

  const [isProfessionalModalVisible, setIsProfessionalModalVisible] = useState(false);
  
  const professionalFormik = useFormik<ProfessionalFormik>({
    enableReinitialize: true,
    initialValues: {
      professional: null,
    },
    validationSchema: Yup.object().shape({
      professional: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um profissional"),
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values));
    },
  });

  const [isNoInterestModalVisible, setIsNoInterestModalVisible] = useState(false);
  
  const noInterestFormik = useFormik<NoInterestFormik>({
    enableReinitialize: true,
    initialValues: {
      reason: null,
      details: ''
    },
    validationSchema: Yup.object().shape({
      reason: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um motivo"),
      details: Yup.string(),
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values));
    },
  });

  // Getting customers
  useEffect(() => {
    async function getCustomers() {
      try {
        setIsLoading(true);

        const customers = await CustomersService.listCustomers();

        const groupedCustomers: GroupedData = customers.reduce((acc, cur) => {
          if (acc[cur.status]) {
            acc[cur.status].push(cur);
          } else {
            acc[cur.status] = [cur];
          }
          
          return acc;
        }, {} as GroupedData);

        const kanbanData: KanbanData[] = Object
          .entries(groupedCustomers)
          .map(([key, value]) => ({
            id: key.toLowerCase().split(' ').join('-'),
            order: getKanbanOrder(key),
            title: key,
            customers: value,
          }))
          .sort((a, b) => a.order - b.order)
        ;

        setData(kanbanData);
      } catch (error) {
        toast.error('Não foi possível carregar dados dos clientes!');

        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    getCustomers();
  }, [debouncedSearch, selectedEmployee, selectedProcedure, selectedDateType, selectedDate, selectedSource, selectedPriority, selectedTaskDue]);

  // Getting UFs
  useEffect(() => {
    async function getUFs() {
      try {
        setIsLoadingUFOptions(true);

        const { data: UFs } = await LocationsService.listUFs();

        const parsedUFs: OptionTypeBase[] = UFs.map((UF) => ({
          value: String(UF.id),
          label: UF.uf,
        }));

        setUFOptions(parsedUFs);
      } catch (error) {
        setUFOptions([]);

        toast.error("Não foi possível carregar as UFs");

        console.log(error);
      } finally {
        setIsLoadingUFOptions(false);
      }
    }

    getUFs();
  }, []);

  // Getting cities per selectedUF (Edit Address)
  useEffect(() => {
    async function getCities() {
      try {
        if (!isEditAddressInfoModalVisible || !UFOptions.length || !addressInfoFormik.values.uf) return;

        const UF = UFOptions.find((uf) => uf.label === addressInfoFormik.values.uf?.label);

        if (typeof UF === "undefined")
          throw new Error("Estado inválido! Não foi possível buscar as cidades");

        const { data: cities } = await LocationsService.listCitiesByUF(UF.label);

        const parsedCities: OptionTypeBase[] = cities.map((city) => ({
          value: String(city.id),
          label: city.cidade,
        }));

        setCityOptions(parsedCities);
      } catch (error) {
        setCityOptions([]);

        toast.error("Não foi possível carregar as cidades");

        console.log(error);
      }
    }

    getCities();
  }, [isEditAddressInfoModalVisible, UFOptions, addressInfoFormik.values.uf]);
  
  // Getting available times per selectedDate (Schedule Consultation)
  useEffect(() => {
    (async () => {
      if (!isScheduleConsultationModalVisible) return;

      scheduleConsultationFormik.setFieldValue('time', null);

      if (!scheduleConsultationFormik.values.professional || !scheduleConsultationFormik.values.date) return;
      
      try {
        setIsLoadingAvailableTimes(true);

        let times: Date[] = [];

        for (let initialTime = 8; initialTime <= 19; initialTime += 0.5) {
          times.push(set(
            scheduleConsultationFormik.values.date, 
            { 
              hours: initialTime, 
              minutes: (initialTime * 60) % 60, 
              seconds: 0, 
              milliseconds: 0 
            }
          ));
        }

        await delay();

        setAvailableTimes(times);
      } catch (error) {
        setAvailableTimes(null);

        toast.error("Não foi possível carregar os horários disponíveis do profissional");

        console.log(error);
      } finally {
        setIsLoadingAvailableTimes(false);
      }
    })()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isScheduleConsultationModalVisible, scheduleConsultationFormik.values.date]);

  function handleClearFilters() {
    setSearch('');
    setSelectedEmployee(null);
    setSelectedProcedure(null);
    setSelectedDateType(null);
    setSelectedDate([null, null]);
    setSelectedSource(null);
    setSelectedPriority(null);
    setSelectedTaskDue(null);
  }

  function getKanbanOrder(title: string) {
    switch (title) {
      case "Clientes sem contato": return 1;
      case "Em contato": return 2;
      case "Agendados WhatsApp": return 3;
      case "Agendados Ligação": return 4;
      case "Agendados Promotora": return 5;
      case "Faltas": return 6;
      case "Compareceu": return 7;
      case "Tratamento fechado": return 8;
      case "Sem interesse": return 9;
      default: return 1;
    }
  }

  async function onDragEnd(result: DropResult) {
    const { source, destination } = result;

    if (!destination || source.droppableId === destination.droppableId) return;

    const sourceColIndex = data.findIndex(e => e.id === source.droppableId);
    const destinationColIndex = data.findIndex(e => e.id === destination.droppableId);

    const sourceCol = data[sourceColIndex];
    const destinationCol = data[destinationColIndex];

    const sourceCustomers = [...sourceCol.customers];
    const destinationCustomers = [...destinationCol.customers];

    const [removed] = sourceCustomers.splice(source.index, 1);
    destinationCustomers.splice(destination.index, 0, removed);

    data[sourceColIndex].customers = sourceCustomers;
    data[destinationColIndex].customers = destinationCustomers;

    setData(data);
  }

  function handleCloseDataModal() {
    setSelectedCustomer(null);
    setIsDataModalVisible(false);
  }

  function handleCloseContactModal() {
    setSelectedCustomer(null);
    setIsContactModalVisible(false);
  }

  function handleOpenEditPersonalInfoModal() {
    setIsDataModalVisible(false);
    setIsEditPersonalInfoModalVisible(true);
  }

  function handleCloseEditPersonalInfoModal() {
    setIsEditPersonalInfoModalVisible(false);
    setIsDataModalVisible(true);
  }

  function handleOpenEditAddressInfoModal() {
    setIsDataModalVisible(false);
    setIsEditAddressInfoModalVisible(true);
  }

  function handleCloseEditAddressInfoModal() {
    setIsEditAddressInfoModalVisible(false);
    setIsDataModalVisible(true);
  }

  function handleCloseActionsModal() {
    setSelectedCustomer(null);
    setIsActionsModalVisible(false);
  }

  function handleOpenScheduleTaskModal() {
    setIsActionsModalVisible(false);
    setIsScheduleTaskModalVisible(true);
  }

  function handleCloseScheduleTaskModal() {
    setIsScheduleTaskModalVisible(false);
    setIsActionsModalVisible(true);
  }

  function handleOpenScheduleConsultationModal() {
    setIsActionsModalVisible(false);
    setIsScheduleConsultationModalVisible(true);
  }

  function handleCloseScheduleConsultationModal() {
    setIsScheduleConsultationModalVisible(false);
    setIsActionsModalVisible(true);
  }

  function isSameTime(a: Date, b: Date) {
    return isSameDay(a, b) && isSameMonth(a, b) && isSameYear(a, b) && isSameHour(a, b) && isSameMinute(a, b);
  }

  function handleOpenProfessionalModal() {
    setIsActionsModalVisible(false);
    setIsProfessionalModalVisible(true);
  }

  function handleCloseProfessionalModal() {
    setIsProfessionalModalVisible(false);
    setIsActionsModalVisible(true);
  }

  function handleOpenNoInterestModal() {
    setIsActionsModalVisible(false);
    setIsNoInterestModalVisible(true);
  }

  function handleCloseNoInterestModal() {
    setIsNoInterestModalVisible(false);
    setIsActionsModalVisible(true);
  }

  return (
    <Content>
      <AdvancedFiltersModal 
        visible={isAdvancedFiltersModalVisible}
        title="Filtros avançados"
        onRequestClose={() => setIsAdvancedFiltersModalVisible(false)}
      >
        <Select 
          label="Fonte"
          options={sourceOptions}
          value={selectedSource}
          onChange={(option) => setSelectedSource(option)}
        />

        <InputGroup>
          <Select 
            label="Prioridade"
            options={priorityOptions}
            value={selectedPriority}
            onChange={(option) => setSelectedPriority(option)}
          />
        </InputGroup>

        <InputGroup>
          <Select 
            label="Tarefas"
            options={taskDueOptions}
            value={selectedTaskDue}
            onChange={(option) => setSelectedTaskDue(option)}
          />
        </InputGroup>
      </AdvancedFiltersModal>

      <DataModal 
        visible={selectedCustomer !== null && isDataModalVisible}
        title="Dados" 
        onRequestClose={handleCloseDataModal}
      >
        <Subtitle>
          Informações pessoais

          <Button variant="secondary" onClick={handleOpenEditPersonalInfoModal}>
            <RiPencilLine />
            Editar
          </Button>
        </Subtitle>

        <List>
          <li>
            <strong>Nome:</strong>
            <span>{selectedCustomer?.name}</span>
          </li>

          <li>
            <strong>RG:</strong>
            <span>24.144.039-76</span>
          </li>

          <li>
            <strong>CPF:</strong>
            <span>241.440.397-69</span>
          </li>

          <li>
            <strong>Nascimento:</strong>
            <span>20/04/1997</span>
          </li>

          <li>
            <strong>Gênero:</strong>
            <span>Feminino</span>
          </li>

          <li>
            <strong>Tipo sanguíneo:</strong>
            <span>AB+</span>
          </li>

          <li>
            <strong>Profissão:</strong>
            <span>Assistente Administrativo</span>
          </li>

          <li>
            <strong>Responsável:</strong>
            <span></span>
          </li>
        </List>

        <Subtitle>
          Endereço

          <Button variant="secondary" onClick={handleOpenEditAddressInfoModal}>
            <RiPencilLine />
            Editar
          </Button>
        </Subtitle>

        <List>
          <li>
            <strong>Rua:</strong>
            <span>Rua da Amizade, nº 365</span>
          </li>

          <li>
            <strong>Bairro:</strong>
            <span>Felicidade</span>
          </li>

          <li>
            <strong>Cidade/UF:</strong>
            <span>Salvador/BA</span>
          </li>

          <li>
            <strong>CEP:</strong>
            <span>42156-695</span>
          </li>

          <li>
            <strong>Complemento:</strong>
            <span>4º andar</span>
          </li>
        </List>
      </DataModal>

      <ContactModal 
        visible={selectedCustomer !== null && isContactModalVisible}
        title="Contatos" 
        onRequestClose={handleCloseContactModal}
      >
        <div>
          <div>
            <Subtitle>E-mail</Subtitle>
            <List>
              <li>teste@gmail.com</li>
              <li>teste-2@gmail.com</li>
              <li>teste-3@gmail.com</li>
            </List>
          </div>

          <div>
            <Subtitle>Telefone</Subtitle>

            <List>
              <li>(71) 3622-8569</li>
              <li>(71) 3458-6936</li>
            </List>
          </div>
        </div>

        <div>
          <div>
            <Subtitle>Celular</Subtitle>

            <List>
              <li>(71) 9 9653-6985</li>
              <li>(71) 9 9458-7946</li>
            </List>
          </div>

          <div>
            <Subtitle>WhatsApp</Subtitle>

            <List>
              <li>(71) 9 9785-7452</li>
              <li>(71) 9 9452-7423</li>
            </List>
          </div>
        </div>

        <Button onClick={() => alert('Iniciar bot')}>
          Iniciar Bot Conversa
        </Button>
      </ContactModal>

      <EditDataModal
        visible={selectedCustomer !== null && isEditPersonalInfoModalVisible}
        title="Editar Dados"
        onRequestClose={handleCloseEditPersonalInfoModal}
      >
        <form onSubmit={personalInfoFormik.handleSubmit}>
          <Input 
            label="Nome"
            name="name"
            value={personalInfoFormik.values.name}
            onChange={personalInfoFormik.handleChange}
            error={(personalInfoFormik.touched.name || undefined) && personalInfoFormik.errors.name}
          />

          <InputGroup>
            <Input 
              label="RG"
              name="rg"
              value={personalInfoFormik.values.rg}
              onChange={personalInfoFormik.handleChange}
              error={(personalInfoFormik.touched.rg || undefined) && personalInfoFormik.errors.rg}
            />

            <Input 
              label="CPF"
              name="cpf"
              mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]} 
              value={personalInfoFormik.values.cpf}
              onChange={personalInfoFormik.handleChange}
              error={(personalInfoFormik.touched.cpf || undefined) && personalInfoFormik.errors.cpf}
            />
          </InputGroup>

          <InputGroup>
            <DatePicker 
              label="Data de nascimento"
              name="birthDate"
              selected={personalInfoFormik.values.birthDate}
              onChange={(date) => personalInfoFormik.setFieldValue('birthDate', date)}
              error={(personalInfoFormik.touched.birthDate || undefined) && personalInfoFormik.errors.birthDate}
              maxDate={new Date()}
              isClearable={false}
              showYearDropdown
            />

            <Select 
              label="Gênero"
              name="gender"
              options={genderOptions}
              value={personalInfoFormik.values.gender}
              onChange={option => personalInfoFormik.setFieldValue('gender', option)}
              error={(personalInfoFormik.touched.gender || undefined) && personalInfoFormik.errors.gender} 
              isClearable={false}
            />
          </InputGroup>

          <InputGroup>
            <Select 
              label="Tipo sanguíneo"
              name="bloodType"
              options={bloodTypeOptions}
              value={personalInfoFormik.values.bloodType}
              onChange={option => personalInfoFormik.setFieldValue('bloodType', option)}
              error={(personalInfoFormik.touched.bloodType || undefined) && personalInfoFormik.errors.bloodType} 
              isClearable={false}
            />

            <Input 
              label="Profissão"
              name="occupation"
              value={personalInfoFormik.values.occupation}
              onChange={personalInfoFormik.handleChange}
              error={(personalInfoFormik.touched.occupation || undefined) && personalInfoFormik.errors.occupation} 
            />
          </InputGroup>
          
          <Button type="submit">Editar</Button>
        </form>
      </EditDataModal>

      <EditDataModal
        visible={selectedCustomer !== null && isEditAddressInfoModalVisible}
        title="Editar Endereço"
        onRequestClose={handleCloseEditAddressInfoModal}
      >
        <form onSubmit={addressInfoFormik.handleSubmit}>
          <InputGroup layout="1fr 3fr">
            <Input 
              label="CEP"
              name="zipCode"
              value={addressInfoFormik.values.zipCode}
              onChange={addressInfoFormik.handleChange}
              error={(addressInfoFormik.touched.zipCode || undefined) && addressInfoFormik.errors.zipCode}
            />

            <Input 
              label="Endereço"
              name="street"
              value={addressInfoFormik.values.street}
              onChange={addressInfoFormik.handleChange}
              error={(addressInfoFormik.touched.street || undefined) && addressInfoFormik.errors.street}
            />
          </InputGroup>

          <InputGroup layout="1.5fr 3fr 3fr">
            <Input 
              label="Número"
              name="number"
              value={addressInfoFormik.values.number}
              onChange={addressInfoFormik.handleChange}
              error={(addressInfoFormik.touched.number || undefined) && addressInfoFormik.errors.number}
            />

            <Input 
              label="Bairro"
              name="neighborhood"
              value={addressInfoFormik.values.neighborhood}
              onChange={addressInfoFormik.handleChange}
              error={(addressInfoFormik.touched.neighborhood || undefined) && addressInfoFormik.errors.neighborhood}
            />

            <Input 
              label="Complemento"
              name="complement"
              value={addressInfoFormik.values.complement}
              onChange={addressInfoFormik.handleChange}
              error={(addressInfoFormik.touched.complement || undefined) && addressInfoFormik.errors.complement}
            />
          </InputGroup>

          <InputGroup layout="1fr 3fr">
            <Select 
              label="UF"
              name="uf"
              options={UFOptions}
              value={addressInfoFormik.values.uf}
              onChange={option => {
                addressInfoFormik.setFieldValue('uf', option);

                if (!option) {
                  addressInfoFormik.setFieldValue('city', null);
                }
              }}
              error={(addressInfoFormik.touched.uf || undefined) && addressInfoFormik.errors.uf} 
              isDisabled={isLoadingUFOptions}
            />

            <Select 
              label="Cidade"
              name="city"
              options={cityOptions}
              value={addressInfoFormik.values.city}
              onChange={option => addressInfoFormik.setFieldValue('city', option)}
              error={(addressInfoFormik.touched.city || undefined) && addressInfoFormik.errors.city} 
              isDisabled={!addressInfoFormik.values.uf || !cityOptions.length}
            />
          </InputGroup>
          
          <Button type="submit">
            Editar
          </Button>
        </form>
      </EditDataModal>

      <ActionsModal
        visible={selectedCustomer !== null && isActionsModalVisible}
        onRequestClose={handleCloseActionsModal}
        title="Ações"
      >
        <InputGroup>
          <Button 
            onClick={handleOpenScheduleTaskModal}
            style={{
              backgroundColor: theme.colors.helpers.info
            }}
          >
            <RiTimeLine />
            Agendar tarefa
          </Button>

          <Button 
            onClick={handleOpenScheduleConsultationModal}
            style={{
              backgroundColor: theme.colors.helpers.success
            }}
          >
            <RiCalendarCheckLine />
            Agendar consulta
          </Button>
        </InputGroup>

        <InputGroup>
          <Button 
            onClick={handleOpenProfessionalModal}
            style={{
              backgroundColor: theme.colors.helpers.warning
            }}
          >
            <RiUser2Line />
            Colaborador
          </Button>

          <Button 
            onClick={handleOpenNoInterestModal}
            style={{
              backgroundColor: theme.colors.helpers.danger
            }}
          >
            <RiCloseCircleLine />
            Sem interesse
          </Button>
        </InputGroup>
      </ActionsModal>

      <ScheduleTaskModal
        visible={selectedCustomer !== null && isScheduleTaskModalVisible}
        title="Agendar tarefa"
        onRequestClose={handleCloseScheduleTaskModal}
      >
        <form onSubmit={scheduleTaskFormik.handleSubmit}>
          <InputGroup layout="2fr 1fr">
            <Input 
              label="Título da tarefa"
              name="title"
              value={scheduleTaskFormik.values.title}
              onChange={scheduleTaskFormik.handleChange}
              error={(scheduleTaskFormik.touched.title || undefined) && scheduleTaskFormik.errors.title}
            />

            <Select 
              label="Tipo"
              name="type"
              options={taskTypeOptions}
              value={scheduleTaskFormik.values.type}
              onChange={option => scheduleTaskFormik.setFieldValue('type', option)}
              error={(scheduleTaskFormik.touched.type || undefined) && scheduleTaskFormik.errors.type} 
              isClearable={false}
            />
          </InputGroup>
          
          <InputGroup>
            <Select 
              label="Status"
              name="status"
              options={taskStatusOptions}
              value={scheduleTaskFormik.values.status}
              onChange={option => scheduleTaskFormik.setFieldValue('status', option)}
              error={(scheduleTaskFormik.touched.status || undefined) && scheduleTaskFormik.errors.status} 
              isClearable={false}
            />

            <Select 
              label="Prioridade"
              name="priority"
              options={taskPriorityOptions}
              value={scheduleTaskFormik.values.priority}
              onChange={option => scheduleTaskFormik.setFieldValue('priority', option)}
              error={(scheduleTaskFormik.touched.priority || undefined) && scheduleTaskFormik.errors.priority} 
              isClearable={false}
            />
          </InputGroup>
          
          <InputGroup layout="3fr 1fr">
            <DatePicker 
              label="Data"
              name="date"
              selected={scheduleTaskFormik.values.date}
              onChange={(date) => scheduleTaskFormik.setFieldValue('date', date)}
              error={(scheduleTaskFormik.touched.date || undefined) && scheduleTaskFormik.errors.date}
              isClearable={false}
            />

            <TimeInput 
              label="Horário"
              value={scheduleTaskFormik.values.time} 
              onChange={(e) => scheduleTaskFormik.setFieldValue('time', e.target.value)}
            />
          </InputGroup>

          <TextArea
            label="Detalhes"
            name="details"
            value={scheduleTaskFormik.values.details}
            onChange={scheduleTaskFormik.handleChange}
          />

          <Button type="submit">
            Agendar
          </Button>
        </form>
      </ScheduleTaskModal>

      <ScheduleConsultationModal
        visible={selectedCustomer !== null && isScheduleConsultationModalVisible}
        title="Agendar consulta"
        onRequestClose={handleCloseScheduleConsultationModal}
      >
        <form onSubmit={scheduleConsultationFormik.handleSubmit}>
          <InputGroup>
            <Input 
              label='Paciente'
              name="patient"
              value={scheduleConsultationFormik.values.patient?.name}
              error={(scheduleConsultationFormik.touched.patient || undefined) && scheduleConsultationFormik.errors.patient}
              disabled={true}
            />

            <Select 
              label="Profissional"
              name="professional"
              options={[
                { label: 'Dr. Roberto Nunes', value: 'Dr. Roberto Nunes', },
                { label: 'Dr. Marcos Araújo', value: 'Dr. Marcos Nunes', },
                { label: 'Dr. Pedro Carmo', value: 'Dr. Pedro Pereira', },
              ]}
              value={scheduleConsultationFormik.values.professional}
              onChange={option => {
                scheduleConsultationFormik.setFieldValue('professional', option);
                scheduleConsultationFormik.setFieldValue('date', null);
                scheduleConsultationFormik.setFieldValue('time', null);
              }}
              error={(scheduleConsultationFormik.touched.professional || undefined) && scheduleConsultationFormik.errors.professional} 
            />
          </InputGroup>

          <InputGroup layout='324px 1fr'>
            <DatePicker
              label="Data" 
              name="date"
              selected={scheduleConsultationFormik.values.date}
              onChange={date => {
                scheduleConsultationFormik.setFieldValue('date', date);
                scheduleConsultationFormik.setFieldValue('time', '00:00');
              }}
              open={!!scheduleConsultationFormik.values.professional}
              popperPlacement="bottom-start"
              minDate={new Date()}
              excludeDates={[addDays(new Date(), 13)]}
              error={(scheduleConsultationFormik.touched.date || undefined) && scheduleConsultationFormik.errors.date}
              disabled={!scheduleConsultationFormik.values.professional}
            />

            <div>
              <div className={`times ${(scheduleConsultationFormik.touched.time || undefined) && scheduleConsultationFormik.errors.time ? 'error' : ''}`}>
                {
                  scheduleConsultationFormik.values.professional !== null
                  ? scheduleConsultationFormik.values.date !== null
                    ? isLoadingAvailableTimes
                      ? (
                        <div className="center-container">
                          <Loading />
                        </div>
                      )
                      : availableTimes !== null
                        ? availableTimes.map(time => (
                          <Button 
                            key={time.toISOString()}
                            variant={
                              scheduleConsultationFormik.values.time !== null
                              ? isSameTime(scheduleConsultationFormik.values.time, time) ? 'primary' : 'secondary'
                              : 'secondary'
                            }
                            onClick={() => scheduleConsultationFormik.setFieldValue('time', isSameTime(scheduleConsultationFormik.values.time as Date, time) ? null : time)}
                          >
                            {format(time, 'HH:mm')}
                          </Button>
                        ))
                        : (
                          <div className="center-container">
                            <p>Não há horários disponíveis para este dia</p>
                          </div>
                        )
                    : (
                      <div className="center-container">
                        <p>Selecione uma data para exibir os horários disponíveis</p>
                      </div>
                    )
                  : (
                    <div className="center-container">
                      <p>Selecione um profissional para exibir as datas e horários disponíveis</p>
                    </div>
                  )
                }
              </div>

              {
                (scheduleConsultationFormik.touched.time || undefined) && 
                scheduleConsultationFormik.errors.time &&
                <StyledError>
                  {scheduleConsultationFormik.errors.time}
                </StyledError>
              }
            </div>
          </InputGroup>

          <Button type="submit">Agendar</Button>
        </form>
      </ScheduleConsultationModal>

      <ProfessionalModal
        visible={selectedCustomer !== null && isProfessionalModalVisible}
        title="Colaborador"
        onRequestClose={handleCloseProfessionalModal}
      >
        <form onSubmit={professionalFormik.handleSubmit}>
          <Select 
            label="Colaborador"
            name="professional"
            options={[
              { label: 'Fulano', value: 'Fulano' },
              { label: 'Beltrano', value: 'Beltrano' },
              { label: 'Ciclano', value: 'Ciclano' },
            ]}
            value={professionalFormik.values.professional}
            onChange={option => professionalFormik.setFieldValue('professional', option)}
            error={(professionalFormik.touched.professional || undefined) && professionalFormik.errors.professional} 
          />

          <Button type="submit">Adicionar</Button>
        </form>
      </ProfessionalModal>

      <NoInterestModal
        visible={selectedCustomer !== null && isNoInterestModalVisible}
        title="Sem interesse"
        onRequestClose={handleCloseNoInterestModal}
      >
        <form onSubmit={noInterestFormik.handleSubmit}>
          <Select 
            label="Motivo"
            name="reason"
            options={noInterestReasonOptions}
            value={noInterestFormik.values.reason}
            onChange={option => noInterestFormik.setFieldValue('reason', option)}
            error={(noInterestFormik.touched.reason || undefined) && noInterestFormik.errors.reason} 
            isClearable={false}
          />

          <TextArea
            label="Detalhes"
            name="details"
            value={noInterestFormik.values.details}
            onChange={noInterestFormik.handleChange}
          />

          <Button type="submit">Enviar</Button>
        </form>
      </NoInterestModal>
     
      <ContentWrapper className='content'>
        <FiltersContainer className={!isFiltersContainerVisible ? 'hide' : ''}>
          <div className='filters-container'>
            <h4>Filtrar por:</h4>
            
            <div className='filters'>
              <Input
                label='Pesquisar lead'
                name='search'
                iconLeft={<RiSearchLine />}
                value={search}
                onChange={e => setSearch(e.target.value)}
              />

              <Select 
                label="Colaborador"
                options={employeeOptions}
                name="employee"
                value={selectedEmployee}
                onChange={(option) => setSelectedEmployee(option)}
              />

              <Select 
                label="Procedimento"
                options={proceduresOptions}
                value={selectedProcedure}
                onChange={(option) => setSelectedProcedure(option)}
              />
              
              <Select 
                label="Filtrar por"
                options={dateTypeOptions}
                value={selectedDateType}
                onChange={(option) => setSelectedDateType(option)}
              />

              <DatePicker 
                label='Data'
                name='date'
                placeholderText='dd/mm/aaaa - dd/mm/aaaa'
                selected={selectedDate[0]}
                onChange={(dates) => setSelectedDate(dates)}
                startDate={selectedDate[0]}
                endDate={selectedDate[1]}
                selectsRange
              />
              
              <div className='buttons'>
                {hasSomeFilterFilled && (
                  <Button 
                    variant='tertiary'
                    onClick={handleClearFilters}
                  >
                    <RiFilterOffLine />
                    Limpar filtros
                  </Button>
                )}

                <Button 
                  variant='tertiary'
                  onClick={() => setIsAdvancedFiltersModalVisible(true)}
                >
                  <RiFilterLine />
                  Filtros avançados
                </Button>
              </div>
            </div>
          </div>

          <div className="expand-container">
            <Button 
              onClick={() => setIsFiltersContainerVisible(prevState => !prevState)}
            >
              {isFiltersContainerVisible ? <FiChevronUp /> : <FiChevronDown />}
              {isFiltersContainerVisible ? 'Ocultar filtros' : 'Exibir filtros'}
            </Button>
          </div>    
        </FiltersContainer>

        <PageContent>
          {isLoading
            ? <Loading />
            : (
              <DragDropContext onDragEnd={onDragEnd}>
                <KanbanContainer 
                  className={!isFiltersContainerVisible ? 'expanded' : ''}
                >
                  {data.map(group => (
                    <Droppable 
                      key={group.id} 
                      droppableId={group.id}
                      direction={windowWidth <= 768 ? 'horizontal' : 'vertical'}
                    >
                      {(provided) => (
                        <KanbanCol
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          <div className="title">
                            <h4>{group.title}</h4>
                            <span>{group.customers.length}</span>
                          </div>

                          <div className="cards">
                            {group.customers.map((customer, index) => (
                              <Draggable
                                key={customer.id}
                                draggableId={customer.id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <KanbanCard 
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    key={customer.id}
                                    ref={provided.innerRef}
                                    data={customer}
                                    onClick={() => setSelectedCustomer(customer)} 
                                    onDataButtonClick={() => setIsDataModalVisible(true)}
                                    onContactButtonClick={() => setIsContactModalVisible(true)}
                                    onActionsButtonClick={() => setIsActionsModalVisible(true)}
                                    cardBorderColor={theme.colors.primary.light}
                                    style={{
                                      ...provided.draggableProps.style,
                                      opacity: snapshot.isDragging ? '0.5' : '1.0',
                                    }}
                                  />
                                )}
                              </Draggable>
                            ))}
                          </div>
                        </KanbanCol>
                      )}
                    </Droppable>
                  ))}
                </KanbanContainer>
              </DragDropContext>
            )
          }
        </PageContent>
      </ContentWrapper>
    </Content>
  );
}