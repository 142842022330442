import { faker } from '@faker-js/faker';
import { subDays } from 'date-fns';

import HttpClient from './utils/HttpClient';

import { delay } from '@utils/delay';

import { StatusColors } from '@/types/api';

export type FakerLeadStatus = 'attended' | 'not attended' | 'closed plan';

export interface FakerLead {
  id: string;
  name: string;
  email: string;
  tel: string;
  date: Date;
  status: FakerLeadStatus;
};

export interface CreateAffiliateLeadRequest {
  nome: string;
  data_nascimento?: string;
  uf_id: number;
  cidade_id: number;
  genero: string;
  link_facebook: string;
  telefone: string;
  whatsApp: string;
  email: string;
  tratamento_id: number;
}

interface CreateAffiliateLeadResponse {
  codigo: string;
  menssagem: string;
}

export type ListAffiliateLeadsOrdersBy = 'name' | 'created_at' | 'status_id';

export type ListAdminLeadsCampaignOrdersBy = 'name' | 'created_at' | 'status_id';

export type ListAdminLeadsAffiliatesOrdersBy = 'name' | 'created_at' | 'status_id';
export interface ListAffiliateLeadsFilters {
  name?: string;
  email?: string;
  created_at?: string;
  status_id?: number;
}

export interface ListLeadsCampaignFilters {
  name?: string;
  documento?: string;
  uf?: number;
  cidade?: number;
  created_at?: string;
}

export interface ListAdminLeadsAffiliatesFilters {
  name?: string;
  afiliado_id?: number;
  uf?: number;
  cidade?: number;
  created_at_start?: string;
  created_at_end?: string;
  tratamento_id?: number;
  status_id?: number;
}

export interface ExportAdminLeadsAffiliatesFilters {
  nome?: string;
  afiliado_id?: number;
  uf_id?: number;
  cidade_id?: number;
  data_inicio?: string;
  data_fim?: string;
  tratamento_id?: number;
  status_id?: number;
}

interface ListAffiliateLeadsRequest {
  page?: number;
  limit?: number;
  orderBy?: ListAffiliateLeadsOrdersBy;
  order?: 'asc' | 'desc';
  filters?: ListAffiliateLeadsFilters;
}

interface ListAdminLeadsCampaignRequest {
  page?: number;
  limit?: number;
  orderBy?: ListAdminLeadsCampaignOrdersBy;
  order?: 'asc' | 'desc';
  campanha_id: string;
  filters?: ListLeadsCampaignFilters;
}

interface ListAdminLeadsAffiliatesRequest {
  export?: boolean;
  page?: number;
  limit?: number;
  orderBy?: ListAdminLeadsAffiliatesOrdersBy;
  order?: 'asc' | 'desc';
  filters?: ListAdminLeadsAffiliatesFilters;
}

interface ListAffiliateLeadsResponse {
  codigo: number;
  dados: Array<{
    uuid: string;
    nome: string;
    email: string;
    telefone: string;
    whatsapp: string;
    data_cadastro: string;
    data_nascimento: string;
    genero: string;
    status_id: number;
    status_nome: string;
    status_cor: StatusColors;
    tentativas_restantes: number;
  }>;
  limite: number;
  pagina: number;
  total: number;
}

interface ListAdminLeadsCampaignResponse {
  codigo: number;
  dados: Array<{
    id: number;
    campanha_id: number;
    nome: string;
    nome_clinica: string;
    email: string;
    whatsapp: string;
    data_cadastro: Date;
    data_nascimento: Date;
    documento: string;
    uf: string;
    cidade: string;
    status_id: number;
    status_nome: string;
    status_cor: StatusColors;
  }>;
  limite: number;
  pagina: number;
  total: number;
}
interface ListAdminLeadsAffiliatesResponse {
  codigo: number;
  dados: Array<{
    uuid: string;
    nome: string;
    email: string;
    afiliado_nome: string;
    afiliado_id: number;
    whatsapp: string;
    telefone: string;
    data_cadastro: Date;
    data_nascimento: Date;
    genero: string;
    tratamento_id: number;
    uf: string;
    cidade: string;
    status_id: number;
    status_nome: string;
    status_cor: StatusColors;
  }>;
  limite: number;
  pagina: number;
  total: number;
}

interface UpdateAffiliateLeadRequest {
  nome: string;
  data_nascimento: string;
  whatsApp: string;
  genero: string;
}

interface UpdateAffiliateLeadResponse {
  codigo: number;
  menssagem: string;
}

interface DeleteAffiliateLeadResponse {
  codigo: number;
  menssagem: string;
}

interface ResendLeadConfirmationMessageAffiliateResponse {
  codigo: number;
  menssagem: string;
}

faker.setLocale('pt_BR');
class LeadsService {
  private leads: FakerLead[];

  constructor() {
    this.leads = [];

    for (let i = 0; i < 20; i++) {
      const { datatype, name, phone, random } = faker;

      const randomGender = datatype.number({ min: 0, max: 1, precision: 1 });

      this.leads.push({
        id: datatype.uuid(),
        name: `${name.firstName(randomGender)} ${name.lastName(randomGender)}`,
        email: `${name.firstName().toLowerCase()}@mail.com`,
        tel: phone.phoneNumber('(##) ####-####'),
        date: datatype.datetime({ min: subDays(new Date(), 30).getTime(), max: new Date().getTime() }),
        status: random.arrayElement(['attended', 'not attended', 'closed plan']),
      });
    }
  }

  async listFakerLeads(): Promise<FakerLead[]> {
    await delay(500);

    return new Promise((resolve) => {
      resolve(this.leads);
    });
  }

  async createAffiliateLead(body: CreateAffiliateLeadRequest) {
    return HttpClient.post<CreateAffiliateLeadResponse>('/afiliado/leads/cadastro', body);
  }

  async listAffiliateLeads(params: ListAffiliateLeadsRequest) {
    return HttpClient.get<ListAffiliateLeadsResponse>('/afiliado/leads/listar', {
      params: { ...params, ...params.filters },
    });
  }

  async listAdminLeadsCampaign(params: ListAdminLeadsCampaignRequest) {
    return HttpClient.get<ListAdminLeadsCampaignResponse>('/admin/campanhas/leads/listar', {
      params: { ...params }
    });
  }

  async listAdminLeadsAffiliates(params: ListAdminLeadsAffiliatesRequest) {
    return HttpClient.get<ListAdminLeadsAffiliatesResponse>('/admin/afiliados/leads/listar', {
      params: { ...params }
    });
  }

  async exportAdminLeadsAffiliates(params: ExportAdminLeadsAffiliatesFilters) {
    return HttpClient.post<BlobPart>('/admin/export/leads', {
      ...params,
    }, {
      responseType: 'blob',
    });
  }

  async updateAffiliateLead(uuid: string, body: UpdateAffiliateLeadRequest) {
    return HttpClient.post<UpdateAffiliateLeadResponse>(`/afiliado/leads/editar/${uuid}`, body);
  }

  async createAffiliateLeadByLink(uuid: string, body: CreateAffiliateLeadRequest) {
    return HttpClient.post<CreateAffiliateLeadResponse>(`/afiliado/leads/cadastro/link/${uuid}`, body);
  }

  async deleteAffiliateLead(uuid: string) {
    return HttpClient.delete<DeleteAffiliateLeadResponse>(`/afiliado/leads/excluir/${uuid}`);
  }

  async resendLeadConfirmationMessageAffiliate(uuid: string) {
    return HttpClient.post<ResendLeadConfirmationMessageAffiliateResponse>(`/afiliado/leads/reenviar-confirmacao/${uuid}`);
  }
}

export default new LeadsService();