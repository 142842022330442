import { MouseEvent, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { RiCloseLine } from 'react-icons/ri';

import { Loading } from '@components/Loading';

import { LoadingModalProps, ModalProps } from './Modal';

import { Header, LoadingContent, Overlay, Wrapper } from './styles';

const modalRoot = document.getElementById('modal-root') as HTMLElement;

export function Modal(props: ModalProps) {
  const { children, title, onRequestClose, visible, ...rest } = props;

  const modalRef = useRef<HTMLDivElement | null>(null);

  function handleOutsideClick(event: MouseEvent<HTMLDivElement>) {
    if (modalRef.current === event.target) onRequestClose();
  }

  // Block scroll when modal is opened
  useEffect(() => {
    if (!visible) return;

    document.body.classList.add('hide-scroll');

    return () => document.body.classList.remove('hide-scroll');
  }, [visible]);

  if (!visible) {
    return null;
  }

  return ReactDOM.createPortal(
    <Overlay ref={modalRef} onClick={handleOutsideClick}>
      <Wrapper disablePadding={!!props.disablePadding} {...rest}>
        {title && (
          <Header>
            <h3>{title}</h3>
            
            <button onClick={onRequestClose}>
              <RiCloseLine />
            </button>
          </Header>
        )}

        {children}
      </Wrapper>
    </Overlay>,
    modalRoot
  );
}

export function LoadingModal(props: LoadingModalProps) {
  const { title, visible, ...rest } = props;

  if (!visible) {
    return null;
  }
  
  return (
    <LoadingContent visible={visible} onRequestClose={() => {}} {...rest}>
      <h3>{title ? title : 'Aguarde...'}</h3>

      <Loading />
    </LoadingContent>
  );
}

export { Subtitle, List } from './styles';