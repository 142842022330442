import { Container, SwitchWrapper, SwitchLabel, SwitchHandle } from "./styles";
import { SwitchProps } from "./Switch";

export function Switch({ label, checked = false, onChange }: SwitchProps) {
  return (
    <Container>
      <SwitchWrapper>
        <input 
          type="checkbox"
          checked={checked}
          onChange={e => onChange(e)}
        />

        <SwitchHandle />
      </SwitchWrapper>

      <SwitchLabel>{ label }</SwitchLabel>
    </Container>
  );
}