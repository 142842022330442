import styled from "styled-components";
import chroma from "chroma-js";

import { InputGroup } from "@components/Input/styles";
import { Container as Button } from "@components/Button/styles";

import { convertToRem } from "@utils/convertToRem";

export const ModalContent = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  ${InputGroup} {
    width: 100%;
  }

  ${Button} {
    width: 100%;

    margin-top: ${convertToRem(40)};
  }
`;

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(40)};
`;

export const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: ${convertToRem(30)};

  ${Button} {
    margin-top: auto;

    min-width: auto;
    height: ${convertToRem(50)};

    > svg {
      width: auto;
      height: ${convertToRem(24)};

      margin-right: ${convertToRem(14)};
    }
  }
`;

export const FormContainer = styled.form`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  gap: ${convertToRem(10)};

  margin-top: ${convertToRem(32)};

  ${Button} {
    height: ${convertToRem(40)};
    min-width: 100%;
    min-height: ${convertToRem(40)};
    
    margin-top: ${convertToRem(21)};
    padding: 0 ${convertToRem(16)};

    text-align: center;
  }
`;

export const ResultsContainer = styled.div`
  margin-top: ${convertToRem(40)};

  > div {
    > div {
      flex: 1;

      &:first-child {
        flex: 1.5;
      }
    }

    &:not(:first-child) {
      margin-top: ${convertToRem(30)};
    }
  }
`;

export const FilesContainer = styled.div`
  margin-top: ${convertToRem(40)};
`;

export const SidebarCard = styled.div`
  padding: ${convertToRem(15)};

  background-color: ${({ theme }) => theme.colors.neutral.white};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
  border-radius: ${convertToRem(5)};

  h3, p {
    padding: 0 ${convertToRem(15)};
  }

  h3 {
    padding-top: ${convertToRem(25)};

    font-size: ${convertToRem(18)};
    line-height: ${convertToRem(20)};
  }

  p {
    margin-top: ${convertToRem(10)};

    font-size: ${convertToRem(12)};
    line-height: ${convertToRem(18)};
  }

  ${Button} {
    width: 100%;

    margin-top: ${convertToRem(40)};

    > svg {
      width: auto;
      height: ${convertToRem(18)};
    }
  }

  & + & {
    margin-top: ${convertToRem(20)};
  }
`;

export const Footer = styled.footer`
  width: 100%;
  height: ${convertToRem(100)};

  margin-top: ${convertToRem(60)};

  border-top: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

  .content {
    display: flex;
    align-items: center;
  }
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-left: auto;
`;

export const PageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${convertToRem(40)};
  height: ${convertToRem(40)};

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.primary.base};

  border: ${convertToRem(1)} solid ${({ theme }) => theme.colors.neutral.border};
  border-radius: ${convertToRem(5)};

  &.prev {
    transform: rotate(90deg);
  }

  &.next {
    transform: rotate(270deg);
  }
  
  &:disabled {
    background-color: ${({ theme }) => chroma(theme.colors.neutral.border).alpha(0.3).css()};

    cursor: not-allowed;
  }

  > svg {
    width: auto;
    height: ${convertToRem(20)};
    
    stroke-width: 1.2;
  }
`;

export const Page = styled.span`
  margin: 0 ${convertToRem(20)};
  
  color: ${({ theme }) => theme.colors.neutral.text};

  font-weight: 500;
  font-size: ${convertToRem(15)};
  line-height: 1;
`;

