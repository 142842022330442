import { createContext, ReactNode, useContext, useState } from "react";

import { OptionTypeBase } from "@components/Select/Select.d";

interface ProfessionalProviderProps {
  children: ReactNode;
}

export interface ProfessionalTime {
  start: string; 
  end: string;
}

export interface ProfessionalSchedule {
  weekday: 'Domingo' | 'Segunda-feira' | 'Terça-feira' | 'Quarta-feira' | 'Quinta-feira' | 'Sexta-feira' | 'Sábado';
  times: ProfessionalTime[];
  isDisabled: boolean;
}

export interface Professional {
  name: string;
  email: string;
  document: string;
  gender: OptionTypeBase | null;
  specialties: OptionTypeBase[] | null;

  consultationDuration: string;
  schedule: ProfessionalSchedule[];

  councilType: OptionTypeBase | null;
  councilUF: OptionTypeBase | null;
  councilNumber: string;
  normalValue: number;
  primeValue: number;
  receiptType: OptionTypeBase | null;
  onlineConsultation: boolean;
  isUsingClinicWhatsapp: boolean;
  whatsapp: string;

  transferType: "pix" | "ted";
  keyType?: OptionTypeBase | null;
  keyValue?: string;
  bank?: OptionTypeBase | null;
  bankAgency?: string;
  bankAccount?: string;
  bankCpf?: string;

  isUsingClinicAddress: boolean;
  address: {
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: OptionTypeBase | null;
    state: OptionTypeBase | null;
    zipCode: string;
  };
}

export interface ProfessionalContextProps {
  professional: Professional;
  addProfessionalInfo: (info: Partial<Professional>) => Professional;
  clearProfessionalInfo: () => void;
  validation: {
    isGeneralTabEmpty: boolean;
    isSchedulesTabEmpty: boolean;
    isSettingsTabEmpty: boolean;
    isBankInfoTabEmpty: boolean;
    isAddressTabEmpty: boolean;
  }
}

export const ProfessionalContext = createContext<ProfessionalContextProps>({} as ProfessionalContextProps);

export function useProfessional(): ProfessionalContextProps {
  const context = useContext(ProfessionalContext);

  if (!context) throw new Error('useProfessional must be used within an ProfessionalProvider');

  return context;
}

const initialProfessionalData: Professional = {
  name: '',
  email: '',
  document: '',
  gender: null,
  specialties: null,

  consultationDuration: '00:00',
  schedule: [
    {
      weekday: 'Domingo',
      times: [{ start: '00:00', end: '00:00' }],
      isDisabled: true,
    },
    {
      weekday: 'Segunda-feira',
      times: [{ start: '00:00', end: '00:00' }],
      isDisabled: false,
    },
    {
      weekday: 'Terça-feira',
      times: [{ start: '00:00', end: '00:00' }],
      isDisabled: false,
    },
    {
      weekday: 'Quarta-feira',
      times: [{ start: '00:00', end: '00:00' }],
      isDisabled: false,
    },
    {
      weekday: 'Quinta-feira',
      times: [{ start: '00:00', end: '00:00' }],
      isDisabled: false,
    },
    {
      weekday: 'Sexta-feira',
      times: [{ start: '00:00', end: '00:00' }],
      isDisabled: false,
    },
    {
      weekday: 'Sábado',
      times: [{ start: '00:00', end: '00:00' }],
      isDisabled: true,
    }
  ],

  councilType: null,
  councilUF: null,
  councilNumber: '',
  normalValue: 0,
  primeValue: 0,
  receiptType: null,
  onlineConsultation: false,
  isUsingClinicWhatsapp: false,
  whatsapp: '',

  transferType: 'pix',
  keyType: null,
  keyValue: '',
  bank: null,
  bankAgency: '',
  bankAccount: '',
  bankCpf: '',
  
  address: {
    zipCode: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    state: null,
    city: null,
  },
  isUsingClinicAddress: false,
};

export function ProfessionalProvider({ children }: ProfessionalProviderProps) {
  const [professional, setProfessional] = useState<Professional>(initialProfessionalData);

  function addProfessionalInfo(info: Partial<Professional>) {
    const newProfessional = { ...professional, ...info };

    setProfessional(newProfessional);

    return newProfessional;
  }

  function clearProfessionalInfo() {
    setProfessional(initialProfessionalData);
  }

  const isGeneralTabEmpty = [professional.name, professional.email, professional.document].includes('') || professional.gender === null || professional.specialties === null || professional.specialties.length <= 0;
  
  const isSchedulesTabEmpty = professional.consultationDuration === '' || professional.schedule.some(e => !e.isDisabled && (e.times.some(time => time.start === '00:00' || time.end === '00:00')));
  
  const isSettingsTabEmpty = [professional.councilType, professional.councilUF, professional.receiptType].includes(null) || [professional.councilNumber, professional.whatsapp].includes('') || [professional.normalValue, professional.primeValue].includes(0);
  
  const isBankInfoTabEmpty = professional.transferType === 'pix'
    ? !professional.keyType || professional.keyValue === ''
    : !professional.bank || [professional.bankAgency, professional.bankAccount, professional.bankCpf].includes('')
  ;
  
  const isAddressTabEmpty = [professional.address.zipCode, professional.address.street, professional.address.complement, professional.address.neighborhood].includes('') || [professional.address.state === null, professional.address.city].includes(null);

  const validation = {
    isGeneralTabEmpty,
    isSchedulesTabEmpty,
    isSettingsTabEmpty,
    isBankInfoTabEmpty,
    isAddressTabEmpty,
  };

  return (
    <ProfessionalContext.Provider value={{ professional, addProfessionalInfo, clearProfessionalInfo, validation }}>
      {children}
    </ProfessionalContext.Provider>
  );
}