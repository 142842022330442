import { convertToRem } from '@utils/convertToRem';
import styled, { keyframes } from 'styled-components';

const loading = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const Container = styled.span<{ color?: string; }>`
  display: flex;

  width: ${convertToRem(30)};
  height: ${convertToRem(30)};

  background-color: transparent;

  border: solid ${convertToRem(3)} ${({ color, theme }) => color ? color : theme.colors.primary.base};
  border-top-color: transparent;
  border-radius: 50%;

  animation: ${loading} 1s infinite linear;
`;