import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import { useAuth } from "@contexts/auth";
import { useSearch } from "@contexts/search";

import { Description, Title } from "@components/Header";
import { Loading } from "@components/Loading";
import { Select } from "@components/Select";
import { Rating } from "@components/Rating";
import { Button } from "@components/Button";
import { PlanCard } from "@components/PlanCard";

import LocationsService from "@services/LocationsService";
import PharmaciesService, { Pharmacy } from "@services/PharmaciesService";

import { Filter } from "@assets/images";

import { convertToRem } from "@utils/convertToRem";

import { OptionTypeBase } from "@components/Select/Select";

import {
  Content,
  ContentWrapper,
  FilterIcon,
  FilterItem,
  FilterLabel,
  FiltersContainer,
  LoadingFiltersContainer,
  LoadingPharmaciesContainer,
  MainContent,
  PageTitle,
  PharmacyAddress,
  PharmacyCard,
  PharmacyInfo,
  PharmacyName,
  PharmacyPromos,
  SidebarContainer,
} from "./styles";

type UFOption = OptionTypeBase & { acronym: string };

export function Pharmacies() {
  const { selectedAccount } = useAuth();
  const { searchValue } = useSearch();

  const [searchParams, setSearchParams] = useSearchParams();

  const [isLoadingUFOptions, setIsLoadingUFOptions] = useState(false);
  const [UFOptions, setUFOptions] = useState<UFOption[]>([]);
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);

  const [selectedUF, setSelectedUF] = useState<UFOption | null>(null);
  const [selectedCity, setSelectedCity] = useState<OptionTypeBase | null>(null);
  const [orderBy, setOrderBy] = useState<"distance" | "popularity">("distance");

  const [isLoadingPharmacies, setIsLoadingPharmacies] = useState(false);
  const [pharmacies, setPharmacies] = useState<Pharmacy[]>([]);

  // Setting UF options
  useEffect(() => {
    async function getUFs() {
      try {
        setIsLoadingUFOptions(true);

        const { data: UFs } = await LocationsService.listUFs();

        const parsedUFs: UFOption[] = UFs.map((UF) => ({
          value: String(UF.id),
          acronym: UF.uf,
          label: UF.uf,
        }));

        setUFOptions(parsedUFs);
      } catch (error) {
        setUFOptions([]);

        toast.error("Não foi possível carregar as UFs");

        console.log(error);
      } finally {
        setIsLoadingUFOptions(false);
      }
    }

    getUFs();
  }, []);

  // Setting City options after user select a UF
  useEffect(() => {
    (async () => {
      if (selectedUF === null) {
        setSelectedCity(null);
        setCityOptions([]);
        return;
      }

      try {
        const { data: cities } = await LocationsService.listCitiesByUF(selectedUF.acronym);

        const parsedCities: OptionTypeBase[] = cities.map((city) => ({
          value: String(city.id),
          label: city.cidade,
        }));

        setCityOptions(parsedCities);
      } catch (error) {
        toast.error("Não foi possível carregar as cidades");

        console.log(error);
      }
    })();
  }, [selectedUF]);

  // Setting filters initial value according query params
  useEffect(() => {
    const UFParam = searchParams.get("uf");
    const cityParam = searchParams.get("city");
    const orderByParam = searchParams.get("orderBy");

    setSelectedUF(UFOptions.find((uf) => uf.acronym === UFParam) || null);
    setSelectedCity(
      cityOptions.find((city) => city.label === cityParam) || null
    );
    setOrderBy(orderByParam === "popularity" ? "popularity" : "distance");
  }, [searchParams, UFOptions, cityOptions]);

  // Filtering pharmacies according filters
  useEffect(() => {
    async function getPharmacies() {
      try {
        setIsLoadingPharmacies(true);

        const pharmacies = await PharmaciesService.listPharmacies({
          name: searchValue,
          uf: selectedUF?.acronym,
          city: selectedCity?.label,
          orderBy,
        });

        setPharmacies(pharmacies);
      } catch (error) {
        setPharmacies([]);

        toast.error("Não foi possível carregar as farmácias");

        console.log(error);
      } finally {
        setIsLoadingPharmacies(false);
      }
    }

    getPharmacies();
  }, [searchValue, selectedUF, selectedCity, orderBy]);

  if (isLoadingUFOptions) {
    return (
      <LoadingFiltersContainer>
        <Loading />

        <h4>Carregando estados...</h4>
      </LoadingFiltersContainer>
    );
  }

  return (
    <Content>
      <FiltersContainer className="content">
        <div>
          <FilterIcon>
            <Filter />
          </FilterIcon>

          <Select
            value={selectedUF}
            options={UFOptions}
            onChange={(selectedOption) => {
              setSelectedUF(selectedOption);
              setSearchParams(
                selectedOption
                  ? {
                      ...Object.fromEntries(searchParams),
                      uf: selectedOption.acronym,
                    }
                  : {
                      ...Object.fromEntries(searchParams),
                      uf: "",
                      city: "",
                    }
              );
            }}
            placeholder="UF"
            customHeight={convertToRem(50)}
            isDisabled={!UFOptions.length}
          />

          <Select
            value={selectedCity}
            options={cityOptions}
            onChange={(selectedOption) => {
              setSelectedCity(selectedOption);
              setSearchParams({
                ...Object.fromEntries(searchParams),
                city: selectedOption?.label || "",
              });
            }}
            placeholder="Cidade"
            customHeight={convertToRem(50)}
            isDisabled={!cityOptions.length}
          />

          <FilterItem>
            <FilterLabel>Ordenar exibição por</FilterLabel>

            <ul>
              <li>
                <button
                  className={orderBy === "distance" ? "active" : ""}
                  onClick={() => {
                    setOrderBy("distance");
                    setSearchParams({
                      ...Object.fromEntries(searchParams),
                      orderBy: "distance",
                    });
                  }}
                >
                  Distância
                </button>
              </li>

              <li>
                <button
                  className={orderBy === "popularity" ? "active" : ""}
                  onClick={() => {
                    setOrderBy("popularity");
                    setSearchParams({
                      ...Object.fromEntries(searchParams),
                      orderBy: "popularity",
                    });
                  }}
                >
                  Popularidade
                </button>
              </li>
            </ul>
          </FilterItem>
        </div>
      </FiltersContainer>

      <ContentWrapper className="content">
        <MainContent>
          <PageTitle>
            <Title>Farmácias</Title>
            <Description>
              Essas são as farmacias mais próximas da sua cidade
            </Description>
          </PageTitle>

          {isLoadingPharmacies ? (
            <LoadingPharmaciesContainer>
              <Loading />

              <h4>Buscando farmácias...</h4>
            </LoadingPharmaciesContainer>
          ) : (
            <div>
              {pharmacies.length > 0
                ? pharmacies.map((pharmacy) => (
                    <PharmacyCard>
                      <PharmacyInfo>
                        <PharmacyName>
                          <img
                            src={pharmacy.Foto}
                            alt={pharmacy.NomeFantasia}
                          />

                          <div>
                            <h4>{pharmacy.NomeFantasia}</h4>
                            <Rating rate={pharmacy.Avaliacao} readonly />

                            <Link to="">Ver avaliações dos pacientes</Link>
                          </div>
                        </PharmacyName>

                        {pharmacy.Descontos.length > 0 ? (
                          <PharmacyPromos>
                            <h5>Promoções</h5>

                            <ul>
                              {pharmacy.Descontos.map((desconto) => (
                                <li>
                                  <span>Até {desconto.porcentagem}%</span>
                                  <p>{desconto.categoria}</p>
                                </li>
                              ))}
                            </ul>
                          </PharmacyPromos>
                        ) : null}
                      </PharmacyInfo>

                      <PharmacyAddress>
                        <h5>Endereço</h5>
                        <p>
                          Avenida Getúlio Vargas, 585 - Centro. 35680037 -
                          Itaúna, MG
                        </p>

                        <a href="#">(98) 3232-2332</a>
                        <a href="#" className="routes">
                          Ver rotas
                        </a>
                      </PharmacyAddress>
                    </PharmacyCard>
                  ))
                : "Nenhuma farmácia foi encontrada"}
            </div>
          )}
        </MainContent>

        <SidebarContainer>
          <h2>Plano atual</h2>
          <PlanCard isPrime={!!selectedAccount?.isPrime} />

          <div className="search-buttons">
            <Button variant="secondary">Buscar por consultas</Button>
            <Button variant="secondary">Buscar por exames</Button>
          </div>                     
        </SidebarContainer>
      </ContentWrapper>
    </Content>
  );
}
