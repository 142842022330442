import TimeField from "react-simple-timefield";

import { Container, Error, Label, Time } from "./styles";

type TimeFieldProps = typeof TimeField.defaultProps;

type TimeInputProps = Omit<TimeFieldProps, "input"> & {
  disabled?: boolean;
  error?: string;
  hideError?: boolean;
  label?: string;
};

export function TimeInput({
  disabled = false,
  showSeconds = false,
  error,
  hideError,
  label,
  ...props
}: TimeInputProps) {
  return (
    <Container>
      {label && <Label>{label}</Label>}

      <TimeField
        input={<Time 
            showSeconds={showSeconds} 
            disabled={disabled} 
            error={!!error} 
        />}
        showSeconds={showSeconds}
        {...props}
      />

      {error && !hideError && <Error>{error}</Error>}
    </Container>
  );
}
