export function formatCPFCNPJ(cpf: string): string {
  const cleanedNumber = cpf.replace(/[^\d]/g, '');

  if (cleanedNumber.length === 11) {
    return cleanedNumber
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
    ;
  } else {
    return cleanedNumber
      .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, "$1.$2.$3/$4-$5")
    ;
  }
}