import { Navigate, Route, Routes } from "react-router-dom";

import { Consultations } from './Consultations';
import { Exams } from "./Exams";
import { Records } from "./Records";

export function History() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="consultas" />} />
      <Route path="consultas" element={<Consultations />} />
      <Route path="exames" element={<Exams />} />
      <Route path="prontuarios" element={<Records />} />
    </Routes>
  );
}