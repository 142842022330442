import { ButtonHTMLAttributes, forwardRef } from "react";

import { ResultCardProps } from "./ResultCard";

import { Container, ActionButton as ActionButtonStyled } from "./styles";

type ActionButtonType = ButtonHTMLAttributes<HTMLButtonElement> & {
  color?: string;
};

export { ItemValue, ItemTitle, ItemDivider, ItemContainer, ActionsContainer } from './styles';

export const ActionButton = forwardRef<HTMLButtonElement, ActionButtonType>((props, forwardedRef) => {
  const { children, type = 'button', color, ...rest  } = props;

  return (
    <ActionButtonStyled 
      ref={forwardedRef} 
      type={type} 
      color={color} 
      {...rest}
    >
      {children}
    </ActionButtonStyled>
  );
});

export default function ResultCard({ children, ...props }: ResultCardProps) {
  return (
    <Container {...props}>
      {children}
    </Container>
  );
}