import styled from "styled-components";

import { convertToRem } from "@utils/convertToRem";

export const Container = styled.div`
  width: 100%;
  max-width: ${convertToRem(870)};
  
  margin: 0 auto;
  padding-bottom: ${convertToRem(40)};
`;