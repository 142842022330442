import styled from "styled-components";
import { convertToRem } from "@utils/convertToRem";

import { Container as Button } from "@components/Button/styles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  padding: ${convertToRem(50)} 0;

  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;

  max-width: ${convertToRem(460)};

  h4 {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    margin-bottom: ${convertToRem(18)};

    font-weight: 400;
    font-size: ${convertToRem(15)};
    line-height: 1;

    color: red;

    > svg {
      width: auto;
      height: ${convertToRem(14)};

      margin-right: ${convertToRem(8)};
    }

    &:not(:first-child) {
      margin-top: ${convertToRem(50)};
    }
  }

  p {
    font-weight: 400;
    font-size: ${convertToRem(15)};
    line-height: 1.3;

    + p {
      margin-top: ${convertToRem(16)};
    }
  }

  > ${Button} {
    width: 100%;

    margin-top: ${convertToRem(20)};

    background-color: ${({ theme }) => theme.colors.neutral.borderLight};
    color: ${({ theme }) => theme.colors.neutral.text};

    font-weight: 400;
    font-size: ${convertToRem(12)};

    border-radius: ${convertToRem(3)};
  }

  hr {
    width: 100%;
    height: ${convertToRem(1)};

    margin-top: ${convertToRem(10)};

    background-color: ${({ theme }) => theme.colors.neutral.borderDark};

    border: none;
  }

  li {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    font-size: ${convertToRem(15)};
    line-height: 1.3;

    &:not(:first-child) {
      margin-top: ${convertToRem(6)};
    }

    &::before {
      content: "•";

      margin-right: ${convertToRem(10)};
    }
  }
`;