import styled from "styled-components";
import chroma from "chroma-js";

import { Button } from "@components/Button";
import { Container as StyledButton } from "@components/Button/styles";
import { Container as Callout } from '@components/Callout/styles';

import { convertToRem } from "@utils/convertToRem";

export const Container = styled.div`
  max-width: ${convertToRem(770)};

  padding-top: ${convertToRem(36)};
  padding-bottom: ${convertToRem(80)};

  ${Callout} {
    margin-top: ${convertToRem(28)};

    line-height: 1.5;
    
    text-align: center;
  }

  > ${StyledButton} {
    width: min-content;

    margin-top: ${convertToRem(60)};
    margin-left: auto;
  }

  @media (max-width: 576px) {
    > ${StyledButton} {
      width: 100%;
    }
  }
`;

export const PreviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  
  padding: ${convertToRem(48)} ${convertToRem(46)};
  
  background-color: ${({ theme }) => theme.colors.primary.lightest};
  
  outline: ${convertToRem(1)} solid ${({ theme }) => theme.colors.primary.light};
  border-radius: ${convertToRem(5)};

  @media (max-width: 576px) {
    flex-direction: column;
  }
`;

export const PreviewImage = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  
  width: ${convertToRem(200)};
  height: ${convertToRem(200)};
  
  background-color: ${({ theme }) => chroma(theme.colors.primary.light).alpha(0.2).css()};
  
  outline: ${convertToRem(1)} solid ${({ theme }) => theme.colors.primary.highlight};

  > svg {
    width: ${convertToRem(72)};
    height: ${convertToRem(72)};

    color: ${({ theme }) => theme.colors.primary.highlight};
  }
`;

export const ImageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.neutral.black};

  cursor: pointer;

  > img {
    width: 100%;
    height: 100%;

    object-fit: cover;

    opacity: 1;
    z-index: 1;
  }

  > svg {
    position: absolute;

    width: auto;
    height: ${convertToRem(48)};

    color: ${({ theme }) => theme.colors.neutral.white};

    visibility: hidden;
    z-index: 2;
  }

  &:hover {
    > img {
      opacity: 0.70;
    }
    
    > svg {
      visibility: visible;
    }
  }
`;

export const PreviewText = styled.div`
  flex: 1;
  
  max-width: ${convertToRem(340)};
  height: 100%;
  
  margin-left: ${convertToRem(70)};

  h3 {
    margin-bottom: ${convertToRem(18)};

    color: ${({ theme }) => theme.colors.neutral.textDark};

    font-size: ${convertToRem(20)};
    line-height: 1.5;
  }

  p {
    color: ${({ theme }) => theme.colors.neutral.textDark};
 
    font-size: ${convertToRem(14)};
    line-height: 1.5;
  }

  > ${StyledButton} {
    width: 100%;

    min-width: auto;

    margin-top: ${convertToRem(44)};
  }

  @media (max-width: 576px) {
    margin-left: 0;
    margin-top: ${convertToRem(70)};

    text-align: center;
  }
`;

export const FileInput = styled(Button)`
  height: ${convertToRem(60)};

  background-color: ${({ theme }) => theme.colors.primary.highlight};
  
  > svg {
    width: ${convertToRem(20)};
    height: auto;

    margin-right: ${convertToRem(8)};
  }
`;