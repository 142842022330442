import styled from "styled-components";

import { Container as ResultCard } from "@components/ResultCard/styles";
import { Container as Select } from "@components/Select/styles";

import { convertToRem } from "@utils/convertToRem";

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(40)};
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: ${convertToRem(30)};

  margin-top: ${convertToRem(28)};

  ${Select} {
    &:nth-child(1) {
      width: ${convertToRem(140)};
    }

    &:nth-child(2) {
      width: ${convertToRem(240)};
    }
  }
`;

export const ResultsContainer = styled.div`
  margin-top: ${convertToRem(30)};
  padding-bottom: ${convertToRem(80)};

  > ${ResultCard} {   
    &:not(:first-child) {
      margin-top: ${convertToRem(10)};
    }
  }
`;