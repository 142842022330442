import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { format } from 'date-fns';
import fileDownload from 'js-file-download';
import { toast } from 'react-toastify';
import { RiEyeLine, RiFilterLine, RiFilterOffLine, RiPhoneLine, RiDownload2Fill } from 'react-icons/ri';
import { BiTrashAlt } from 'react-icons/bi';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Loading } from '@components/Loading';
import { Modal, List, Subtitle } from '@components/Modal';
import { Description, Title } from '@components/Header';
import { Button } from '@components/Button';
import { Input, InputGroup } from '@components/Input';
import { DatePicker } from '@components/DatePicker';
import { Select } from '@components/Select';
import ResultCard, { ItemDivider, ItemValue, ItemContainer, ItemTitle, ActionsContainer, ActionButton } from '@components/ResultCard';
import { Tooltip } from '@components/Tooltip';

import LeadsService, { ListAdminLeadsAffiliatesFilters, ListAdminLeadsAffiliatesOrdersBy } from '@services/LeadsService';
import AffiliatesService from "@services/AffiliatesService";

import { ChevronDown, Whatsapp, Search } from '@assets/images';

import { clearPhoneNumber } from '@utils/clearPhoneNumber';

import { OnChangeOptionType, OptionTypeBase } from '@components/Select/Select.d';

import { Content, ContentWrapper, DataModal, FilterModal, FiltersContainer, Footer, Page, PageButton, Pagination, ResultsContainer } from './styles';
import { StatusColors } from '@/types/api';
import TreatmentsService from '@/services/TreatmentsService';
import { number } from 'yup/lib/locale';
import { formatPhoneNumber } from '@/utils/formatPhoneNumber';
import LocationsService from '@/services/LocationsService';
import axios from 'axios';
import HttpClient from '@/services/utils/HttpClient';

interface statusItems {
  label: string;
  value: number;
}

interface treatmentItems {
  label: string;
  value: number;
}

interface affiliateItems {
  label: string;
  value: number;
}

interface orderItems {
  label: string;
  value: ListAdminLeadsAffiliatesOrdersBy;
}

const orderSelectItems: orderItems[] = [
  { value: 'name', label: 'Nome' },
  { value: 'created_at', label: 'Data' },
  { value: 'status_id', label: 'Status' },
];

const statusSelectItems: statusItems[] = [
  { value: 29, label: 'Bloqueado' },
  { value: 30, label: 'Aprovado' },
  { value: 31, label: 'Em análise' },
  { value: 32, label: 'Reprovado' },
];

const limitSelectItems: OptionTypeBase[] = [
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
];

interface FilterFormik {
  name: string;
  affiliate: affiliateItems | null;
  treatment: treatmentItems | null;
  uf: OptionTypeBase | null;
  city: OptionTypeBase | null;
  initialDate: string; 
  endDate: string;
  status: statusItems | null;
}

interface DetailsFormik {
  name: string;
  guardian: string;
  mail: string;
  cpf: string;
  rg: string;
  birthDate: Date | null;
  phone: string;
  whatsapp: string;
  uf: OptionTypeBase | null;
  city: OptionTypeBase | null;
  address: string;
}

interface Lead {
  id: string;
  name: string;
  affiliateName: string;
  date: Date;
  bornDate: Date;
  gender: string;
  treatment: number;
  address: {
    city: string;
    uf: string;
  };
  status: {
    id: number;
    description: string;
    color: StatusColors;
  };
  contact: {
    email: string;
    whatsApp: string;
    phone: string;
  }
}

export function LeadsAfiliados() {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(true);
  const [leads, setLeads] = useState<Lead[]>([]);
  
  const [selectedOrder, setSelectedOrder] = useState<ListAdminLeadsAffiliatesOrdersBy>('created_at');
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const [affiliateLoading, setAffiliateLoading] = useState(false);

  const [total, setTotal] = useState<number>(0);

  const [exportLeads, setExportLeads] = useState(false);
  const [isLoadingTreatmentOptions, setIsLoadingTreatmentOptions] = useState(true);
  const [affiliateSearch, setAffiliateSearch] = useState('');
  
  const [affiliateOptions, setAffiliateOptions] = useState<affiliateItems[]>([]);
  const [treatmentOptions, setTreatmentOptions] = useState<treatmentItems[]>([]);

  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  const [filters, setFilters] = useState<ListAdminLeadsAffiliatesFilters | undefined>(undefined);
  
  const [isFiltering, setIsFiltering] = useState(false);

  const [isLoadingUFOptions, setIsLoadingUFOptions] = useState(true);
  const [isLoadingCityOptions, setIsLoadingCityOptions] = useState(false);

  const [UFOptions, setUFOptions] = useState<OptionTypeBase[]>([]);
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);

  const filterFormik = useFormik<FilterFormik>({
    initialValues: {
      name: '',
      treatment: null,
      affiliate: null,
      initialDate: new Date().toISOString().slice(0,10),
      endDate: new Date().toISOString().slice(0,10),
      status: { value: 30, label: 'Ativo' },
      uf: null,
      city: null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string(),
      treatment: Yup.object({ value: Yup.number(), label: Yup.string() })
        .nullable(),
      uf: Yup.object({ value: Yup.string(), label: Yup.string() })
        .nullable(),
      city: Yup.object({ value: Yup.string(), label: Yup.string() })
        .when("uf", {
          is: null,
          then: Yup.object().nullable(),
          otherwise: Yup.object()
            .required("O campo é obrigatório")
            .typeError("Selecione uma cidade")
        }),
      affiliate: Yup.object({ value: Yup.number(), label: Yup.string() })
        .nullable(),
    }),
    onSubmit: (values) => {
      setIsFilterModalVisible(false);

      const isAllFieldsEmpty = Object.values(values).map(Boolean).every(e => e === false);
      if (isAllFieldsEmpty) {
        handleClearFilters();
        return;
      }
      
      setFilters({
        name: filterFormik.values.name || undefined,
        afiliado_id: filterFormik.values.affiliate ? Number(filterFormik.values.affiliate.value) : undefined,
        tratamento_id: filterFormik.values.treatment ? Number(filterFormik.values.treatment.value) : undefined,
        uf: filterFormik.values.uf ? Number(filterFormik.values.uf.value) : undefined,
        cidade: filterFormik.values.city ? Number(filterFormik.values.city.value) : undefined,
        status_id: filterFormik.values.status ? Number(filterFormik.values.status.value) : undefined,
        created_at_start: filterFormik.values.initialDate || undefined,
        created_at_end: filterFormik.values.endDate || undefined,
      });
    }
  });

  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const detailsFormik = useFormik<DetailsFormik>({
    enableReinitialize: true,
    initialValues: {
      name: selectedLead?.name || '',
      guardian: '',
      mail: '',
      cpf: '',
      rg: '',
      birthDate: null,
      phone: '',
      whatsapp: '',
      uf: null,
      city: null,
      address: '',
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values));
    }
  });

  useEffect(() => {
    async function getUFs() {
      try {
        setIsLoadingUFOptions(true);

        const { data: UFs } = await LocationsService.listUFs();

        const parsedUFs: OptionTypeBase[] = UFs.map((UF) => ({
          value: String(UF.id),
          label: UF.uf,
        }));

        setUFOptions(parsedUFs);
      } catch (error) {
        toast.error("Não foi possível carregar as UFs");

        console.error(error);
      } finally {
        setIsLoadingUFOptions(false);
      }
    }

    getUFs();
  }, []);

  // Getting city options
  useEffect(() => {
    async function getCities() {
      try {
        if (!UFOptions.length || !filterFormik.values.uf) {
          setCityOptions([]);
          return;
        }

        setIsLoadingCityOptions(true);

        const UF = UFOptions.find((uf) => uf.label === filterFormik.values.uf?.label);

        if (typeof UF === "undefined")
          throw new Error(
            "Estado inválido! Não foi possível buscar as cidades"
          );

        const { data: cities } = await LocationsService.listCitiesByUF(
          UF.label
        );

        const parsedCities: OptionTypeBase[] = cities.map((city) => ({
          value: String(city.id),
          label: city.cidade,
        }));

        setCityOptions(parsedCities);
      } catch (error) {
        setCityOptions([]);

        toast.error("Não foi possível carregar as cidades");

        console.error(error);
      } finally {
        setIsLoadingCityOptions(false);
      }
    }

    getCities();
  }, [UFOptions, filterFormik.values.uf]);

  useEffect(() => {
    getLeads();
  }, [selectedOrder, filters, itemsPerPage, currentPage]);

  async function getLeads() {
    try {
        if (!exportLeads) {
          setIsLoading(true);
        }
        
        const { data } = await LeadsService.listAdminLeadsAffiliates({
            export: exportLeads,
            page: currentPage - 1,
            limit: itemsPerPage,
            orderBy: selectedOrder,
            order: 'desc',
            filters
        });

        if (!exportLeads) {
          setTotal(data.total);
          
          const parsedAffiliates = data.dados.map<Lead>(lead => ({
              id: lead.uuid,
              treatment: lead.tratamento_id,
              name:  lead.nome,
              date: lead.data_cadastro,
              affiliateName: lead.afiliado_nome,
              bornDate: lead.data_nascimento,
              gender: lead.genero,
              address: {
                  city: lead.cidade,
                  uf: lead.uf,
              },
              status: {
                  id: lead.status_id,
                  description: lead.status_nome,
                  color: lead.status_cor,
              },
              contact: {
                  email: lead.email,
                  whatsApp: lead.whatsapp,
                  phone: lead.telefone,
              }
          }));

          setLeads(parsedAffiliates);
        } else {
          setExportLeads(false);
        }
    } catch (error) {
        toast.error('Não foi possível carregar dados dos leads!');

        console.log(error);
    } finally {
        setIsLoading(false);
    }
  };

  async function exportFilteredLeads() {
    try {
        if (!filters?.status_id) {
          toast.error('Para exportar leads é necessário filtrar o status do mesmo.');
          return;
        }
        
        setExportLeads(true);
        
        const response = await LeadsService.exportAdminLeadsAffiliates({
            status_id: filters?.status_id,
            nome: filters?.name,
            tratamento_id: filters?.tratamento_id,
            uf_id: filters?.uf,
            cidade_id: filters?.cidade,
            data_inicio: filters?.created_at_start,
            data_fim: filters?.created_at_end,
            afiliado_id: filters?.afiliado_id
        });

        const blob = new Blob([response.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        fileDownload(blob, 'leads.xlsx');
    } catch (error) {
        toast.error('Não foi possível exportar os leads!');

        console.log(error);
    } finally {
        setExportLeads(false);
    }
  };

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  function handleChangeLimit(selectedOption: OnChangeOptionType) {
    if (selectedOption === null) return;

    setItemsPerPage(Number(selectedOption.value));
  }

  function handleClearFilters() {
    filterFormik.resetForm();
    setIsFilterModalVisible(false);
    setFilters(undefined);
  }

  function handleExportLeads() {
    exportFilteredLeads();
  }

  function handleDeleteLead() {
    setIsDeleteModalVisible(false);
    toast.success('Lead excluído com sucesso!');
  }

  function handleAffiliateSearch(event: any) {
    setAffiliateSearch(event.target.value);
  }

  useEffect(() => {
    async function onKeyUp() {
      if (affiliateLoading || affiliateSearch.length < 3) {
        return false;
      }

      setAffiliateLoading(true);

      const { data: affiliates } = await AffiliatesService.listAdminAffiliatesSelect({
        basic: true,
        filters: {
          name: affiliateSearch
        }
      });

      const parsedAffiliates: affiliateItems[] = affiliates.afiliados.map((afiliado) => ({
        value: afiliado.id,
        label: afiliado.nome,
      }));

      setAffiliateOptions(parsedAffiliates);
      setAffiliateLoading(false);
    }

    onKeyUp();
  }, [affiliateSearch]);

  useEffect(() => {
    async function getTreatments() {
      try {
        setIsLoadingTreatmentOptions(true);
  
        const { data } = await TreatmentsService.listTreatments();

        const parsedTreatments: treatmentItems[] = data.map(treatment => ({
          value: treatment.id,
          label: treatment.nome,
        }));

        setTreatmentOptions(parsedTreatments);
      } catch (error) {
        setTreatmentOptions([]);
  
        toast.error("Não foi possível carregar os tratamentos");
  
        console.error(error);
      } finally {
        setIsLoadingTreatmentOptions(false);
      }
    }

    getTreatments();
  }, []);

  return (
    <Content>
      <FilterModal
        visible={isFilterModalVisible}
        title="Filtrar por"
        onRequestClose={() => setIsFilterModalVisible(false)}
      >
        <form onSubmit={filterFormik.handleSubmit}>
          <InputGroup>
            <Input
              label='Nome'
              name='name'
              placeholder='Buscar por nome'
              iconRight={<Search />}
              value={filterFormik.values.name}
              onChange={filterFormik.handleChange}
              error={(filterFormik.touched.name || undefined) && filterFormik.errors.name}
            />
          </InputGroup>

          <InputGroup>
            <Select 
              label="Tratamento de Interesse"
              name="treatment"
              options={treatmentOptions}
              value={filterFormik.values.treatment}
              onChange={option => filterFormik.setFieldValue('treatment', option)}
              error={(filterFormik.touched.treatment || undefined) && filterFormik.errors.treatment} 
              isDisabled={isLoadingTreatmentOptions}
            />
          </InputGroup>

          <div>
            <InputGroup>
              <Input
                label='Buscar por afiliado'
                name='affiliate_search'
                type='search'
                autoComplete='off'
                onChange={handleAffiliateSearch}
              />
            </InputGroup>

            {affiliateLoading ? 
              <InputGroup>
                <Loading/>
              </InputGroup>
              : affiliateSearch.length ? (
                affiliateOptions ? (
                  <InputGroup>
                    <Select 
                      label='Selecione o afiliado'
                      name='affiliate'
                      value={filterFormik.values.affiliate}
                      options={affiliateOptions}
                      onChange={(selectedOption) => filterFormik.setFieldValue('affiliate', selectedOption)}
                      error={(filterFormik.touched.affiliate || undefined) && filterFormik.errors.affiliate}
                    />
                  </InputGroup>
                ) : <strong>Nenhum afiliado encontrado com: {affiliateSearch}</strong>
              ) : ''
            }
          </div>

          <InputGroup>
            <Select
              label='Status'
              name='status'
              options={statusSelectItems}
              onChange={(selectedOption) => filterFormik.setFieldValue('status', selectedOption)}
              defaultValue={statusSelectItems.find(
                (i) => i.value == 30
              )}
              error={(filterFormik.touched.status || undefined) && filterFormik.errors.status}
            />
          </InputGroup>

          <InputGroup layout="1fr 2fr">
            <Select
              label="UF"
              name="uf"
              options={UFOptions}
              value={filterFormik.values.uf}
              onChange={(selectedOption) => {
                filterFormik.setFieldValue('uf', selectedOption);
                filterFormik.setFieldValue('city', null, false);
              }}
              isDisabled={isLoadingUFOptions}
              error={(filterFormik.touched.uf || undefined) && filterFormik.errors.uf}
            />

            <Select
              label="Cidade"
              name="city"
              options={cityOptions}
              value={filterFormik.values.city}
              onChange={(selectedOption) => filterFormik.setFieldValue('city', selectedOption)}
              isDisabled={filterFormik.values.uf === null || isLoadingCityOptions}
              error={(filterFormik.touched.city || undefined) && filterFormik.errors.city}
            />
          </InputGroup>

          <InputGroup>
            <Input 
              label='Data de Ínicio'
              name='initialDate'
              type="date"
              value={filterFormik.values.initialDate}
              onChange={filterFormik.handleChange}
              max={new Date().toISOString().slice(0,10)}
            />

            <Input 
              label='Data de Fim'
              name='endDate'
              type="date"
              value={filterFormik.values.endDate}
              onChange={filterFormik.handleChange}
              min={filterFormik.values.initialDate}
              max={new Date().toISOString().slice(0,10)}
            />
          </InputGroup>

          <Button type='submit'>Filtrar</Button>
        </form>
      </FilterModal>

      <DataModal
        visible={selectedLead !== null && isDetailModalVisible}
        onRequestClose={() => setIsDetailModalVisible(false)}
        title={selectedLead?.name}
        style={{ maxWidth: '500px' }}
      >
        <Subtitle>Informações</Subtitle>
        <List>
          <li>
            <strong>Nome:</strong>
            <span>{selectedLead?.name}</span>
          </li>
          <li>
              <strong>Data de nascimento:</strong>
              <span>{selectedLead?.bornDate ? format(new Date(selectedLead?.bornDate), 'dd/MM/yyyy') : '-'}</span>
          </li>
          <li>
            <strong>Gênero:</strong>
            <span>{selectedLead?.gender == 'M' ? 'Masculino' : (selectedLead?.gender == 'F' ? 'Feminino' : 'Outro')}</span>
          </li>
          <li>
              <strong>Tratamento de interesse:</strong>
              <span>{
                treatmentOptions.map((treatment) => {
                  if (treatment.value === selectedLead?.treatment) {
                    return treatment.label;
                  }
                })
              }</span>
          </li>

          {selectedLead?.contact.whatsApp == selectedLead?.contact.phone ? (
            <li>
              <strong>WhatsApp/Telefone:</strong>
              <span>{selectedLead?.contact.whatsApp ? formatPhoneNumber(selectedLead.contact.whatsApp) : '-'}</span>
            </li>
            ) : (
              <>
                <li>
                  <strong>WhatsApp:</strong>
                  <span>{selectedLead?.contact.whatsApp ? formatPhoneNumber(selectedLead.contact.whatsApp) : '-'}</span>
                </li>
                <li>
                  <strong>Telefone:</strong>
                  <span>{selectedLead?.contact.phone ? formatPhoneNumber(selectedLead.contact.phone) : '-'}</span>
                </li>
              </>
            )
          }
          <li>
            <strong>E-mail:</strong>
            <span>{selectedLead?.contact.email}</span>
          </li>
          <li>
            <strong>Cidade:</strong>
            <span>{selectedLead?.address.city ? selectedLead?.address.city+' / '+selectedLead?.address.uf : '-'}</span>
          </li>
        </List>
      </DataModal>
      
      <Modal 
        visible={selectedLead !== null && isDeleteModalVisible}
        title="Atenção"
        onRequestClose={() => setIsDeleteModalVisible(false)} 
      >
        <p className='text'>
          Deseja realmente excluir o lead <strong>{selectedLead?.name}</strong>? Essa ação irá excluir todos os dados e é irreversível.
        </p>

        <div className='buttons'>
          <button onClick={() => setIsDeleteModalVisible(false)}>
            Não
          </button>

          <button onClick={handleDeleteLead} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <ContentWrapper className="content">
        <Title>Leads</Title>
        <Description>
          Leads cadastrados por afiliados.
        </Description>

        <FiltersContainer>
          <Select
            label="Classificar por"
            options={orderSelectItems}
            value={orderSelectItems.find(option => option.value === selectedOrder)}
            onChange={(option) => {
              setSelectedOrder(option?.value || "created_at");
              setCurrentPage(1);
            }}
            isClearable={false}
          />

          <Select
            label="Exibir últimos"
            options={limitSelectItems}
            value={limitSelectItems.find(option => option.label === String(itemsPerPage))}
            onChange={handleChangeLimit}
            isClearable={false}
          />

          <Button 
            variant='tertiary' 
            onClick={() => setIsFilterModalVisible(true)}
          >
            <RiFilterLine />
            Filtrar
          </Button>

          {filters !== undefined && (
            <Button 
              variant='tertiary' 
              onClick={handleClearFilters}
            >
              <RiFilterOffLine />
              Remover filtro
            </Button>
          )}

          {leads.length > 0 && (
            <Button 
              variant='tertiary' 
              onClick={handleExportLeads}
              disabled={exportLeads && leads.length > 0}
            >
              {exportLeads && leads.length > 0 ? (
                <Loading />
              ) : (
                <RiDownload2Fill />
              )}
              Exportar leads
            </Button>
          )}
        </FiltersContainer>

        <ResultsContainer>
          {leads.length > 0 ?
            <span>Encontramos um total de <strong>{total}</strong> leads.</span>
          : ''}
          {
            isLoading
            ? <Loading />
            : leads.length > 0
              ? leads.map(lead => (
                  <ResultCard 
                    key={lead.id} 
                    onClick={() => setSelectedLead(lead)}
                  >
                    <ItemContainer>
                      <div>
                        <ItemValue>{format(new Date(lead.date), 'dd/MM/yyyy HH:mm')}</ItemValue>
                        <ItemTitle>Data</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ItemDivider />

                    <ItemContainer>
                      <div>
                        <ItemValue>{lead.name}</ItemValue>
                        <ItemTitle>Nome</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ItemDivider />

                    <ItemContainer>
                      <div>
                        <ItemValue>{lead.affiliateName}</ItemValue>
                        <ItemTitle>Colaborador</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ItemDivider />

                    <ItemContainer>
                      <div>
                        <ItemValue>{lead.address.city}/{lead.address.uf}</ItemValue>
                        <ItemTitle>Cidade</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ActionsContainer>
                      <Tooltip content="Detalhes">
                        <ActionButton
                          color={theme.colors.helpers.warning} 
                          onClick={() => setIsDetailModalVisible(true)}
                        >
                          <RiEyeLine />
                        </ActionButton>
                      </Tooltip>

                      <Tooltip content="Ligar">
                        <a 
                          href={`tel:${clearPhoneNumber(lead.contact.phone)}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ActionButton color={theme.colors.helpers.info}>
                            <RiPhoneLine />
                          </ActionButton>
                        </a>
                      </Tooltip>

                      <Tooltip content="WhatsApp">
                        <a 
                          href={`https://wa.me/${clearPhoneNumber(lead.contact.whatsApp)}`}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <ActionButton color={theme.colors.helpers.success}>
                            <Whatsapp />
                          </ActionButton>
                        </a>
                      </Tooltip>

                      <Tooltip content="Excluir">
                        <ActionButton 
                          color={theme.colors.helpers.danger} 
                          onClick={() => setIsDeleteModalVisible(true)}
                        >
                          <BiTrashAlt />
                        </ActionButton>
                      </Tooltip>
                    </ActionsContainer>
                  </ResultCard>
                ))
              : 'Nenhum lead foi encontrado' 
          }
        </ResultsContainer>
      </ContentWrapper>

      <Footer>
        <div className="content">
          <Pagination>
            <PageButton
              className="prev"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              <ChevronDown />
            </PageButton>

            <Page>{String(currentPage).padStart(2, "0")}</Page>

            <PageButton
              className="next"
              onClick={goToNextPage}
              disabled={currentPage === lastPage}
            >
              <ChevronDown />
            </PageButton>
          </Pagination>
        </div>
      </Footer>
    </Content>
  );
}