import { useEffect, useState } from "react";

import { Description, Title } from "@components/Header";
import { Card } from "@components/Card";

import { CheckCircle, Return, UserRating } from "@assets/images";

import { formatToPercent } from "@utils/formatToPercent";

import { CardsContainer, Content, ContentWrapper } from "./styles";

const statsData = {
  currentMonthAttendance: {
    value: 600,
    details: [
      {
        title: "Consultas médicas",
        value: 200,
      },
      {
        title: "Estética",
        value: 200,
      },
      {
        title: "Terapias",
        value: 70,
      },
      {
        title: "Exames",
        value: 30,
      },
      {
        title: "Avaliação odontológica",
        value: 70,
      },
      {
        title: "Outros",
        value: 20,
      },
    ],
  },
  lastMonthAttendance: {
    value: 205,
    details: [
      {
        title: "Consultas médicas",
        value: 70,
      },
      {
        title: "Estética",
        value: 60,
      },
      {
        title: "Terapias",
        value: 30,
      },
      {
        title: "Exames",
        value: 20,
      },
      {
        title: "Avaliação odontológica",
        value: 20,
      },
      {
        title: "Outros",
        value: 5,
      },
    ],
  },
  rating: {
    value: 95.5,
  },
};

export function Reports() {
  const [stats, setStats] = useState<typeof statsData | null>(null);

  useEffect(() => {
    setStats(statsData);
  }, []);

  if (!stats) return <div />;

  return (
    <Content>
      <ContentWrapper className="content">
        <Title>Relatórios</Title>
        <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Description>

        <CardsContainer>
          <Card
            icon={<CheckCircle />}
            title="Atendimentos do mês atual"
            value={stats.currentMonthAttendance.value}
            details={stats.currentMonthAttendance.details}
          />

          <Card
            icon={<Return />}
            title="Atendimentos do mês atenrior"
            value={stats.lastMonthAttendance.value}
            details={stats.lastMonthAttendance.details}
          />

          <Card
            icon={<UserRating />}
            title="Avaliações"
            value={formatToPercent(stats.rating.value)}
          />
        </CardsContainer>
      </ContentWrapper>
    </Content>
  );
}
