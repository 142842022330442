import { convertToRem } from "@utils/convertToRem";
import chroma from "chroma-js";
import styled from "styled-components";

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: calc(100vh - ${convertToRem(80)} - ${convertToRem(50)} - ${convertToRem(40)});
`;

// Professional Not Found 
export const ProfessionalNotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: calc(100vh - ${convertToRem(80)} - ${convertToRem(50)} - ${convertToRem(40)});

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.text};

  a {
    margin-top: ${convertToRem(10)};

    font-weight: 500;
    font-size: ${convertToRem(14)};
    color: ${({ theme }) => theme.colors.primary.highlight};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: fit-content;
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${convertToRem(40)};
    height: ${convertToRem(40)};

    background-color: ${({ theme }) => theme.colors.neutral.borderLight};
    color: ${({ theme }) => theme.colors.primary.base};

    border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
    border-radius: ${convertToRem(5)};

    svg {
      width: auto;
      height: ${convertToRem(20)};

      transform: rotate(90deg);
    }
  }

  h1 {
    margin-left: ${convertToRem(14)};

    color: ${({ theme }) => theme.colors.neutral.black};

    font-weight: 400;
    font-size: ${convertToRem(20)};
    line-height: 1;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  
  margin-top: ${convertToRem(32)};

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.text};

  border: ${convertToRem(1)} solid ${({ theme }) => theme.colors.neutral.border};
  border-radius: ${convertToRem(5)};

  overflow: hidden;
`;

export const ProfessionalHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  padding-top: ${convertToRem(80)};
  
  background-color: ${({ theme }) => theme.colors.neutral.borderLight};

  border-bottom: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
`;

export const ProfessionalInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;

  > img {
    display: block;

    width: ${convertToRem(80)};
    height: ${convertToRem(80)};

    margin-right: ${convertToRem(16)};

    object-fit: cover;

    border: solid ${convertToRem(2)} ${({ theme }) => theme.colors.neutral.borderLight};
    border-radius: 50%;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    &:not(:first-of-type) {
      margin-left: ${convertToRem(40)};
    }
  }
  
  h2 {
    min-width: ${convertToRem(190)};

    font-weight: 500;
    font-size: ${convertToRem(20)};
    line-height: 1;
  }

  h3 {
    margin-top: ${convertToRem(5)};

    color: ${({ theme }) => theme.colors.primary.highlight};

    font-weight: 500;
    font-size: ${convertToRem(10)};
    line-height: 1;
  }

  p {
    font-size: ${convertToRem(15)};
  }

  .stars {
    color: ${({ theme }) => theme.colors.helpers.warning};
  }
`;

export const TabLinks = styled.nav`
  margin-top: ${convertToRem(50)};

  ul {
    display: flex;
  }

  a {
    display: block;

    padding: 0 ${convertToRem(20)} ${convertToRem(8)};

    color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.5).css()};

    font-weight: 400;
    font-size: ${convertToRem(15)};
    line-height: 1;

    &.active {
      font-weight: 500;

      color: ${({ theme }) => theme.colors.primary.highlight};

      border-bottom: solid ${convertToRem(2)} ${({ theme }) => theme.colors.primary.highlight};
    }
  }
`;