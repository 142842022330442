import faker from "@faker-js/faker";
import { addDays, set, subDays } from "date-fns";

export interface Record {
  id: string;
  date: Date;
  professional: string;
  questions: Array<{
    question: string;
    answer: string;
  }>;
}

class RecordsService {
  private records: Record[];

  constructor() {
    this.records = [];

    for (let i = 0; i < 21; i++) {
      const { name, datatype } = faker;

      const randomGender = datatype.number({ min: 0, max: 1, precision: 1 });

      const professionalName = `${randomGender === 0 ? 'Dr.' : 'Dra.'} ${name.firstName(randomGender)} ${name.lastName(randomGender)}`;

      const recordDate =  datatype.datetime({ 
        min: set(subDays(new Date(), 3), { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 }).getTime(), 
        max: set(addDays(new Date(), 3), { hours: 17, minutes: 0, seconds: 0, milliseconds: 0 }).getTime()
      });

      this.records.push({
        id: datatype.uuid(),
        date: recordDate,
        professional: professionalName,
        questions: [
          {
            question: 'Está fazendo uso de algum medicamento?',
            answer: 'Sim, Josepam'
          },
          {
            question: 'Está fazendo (ou já fez) algum tratamento médico?',
            answer: 'Não'
          },
          {
            question: 'Já esteve gravemente doente ou hospitalizado?',
            answer: 'Sim',
          },
          {
            question: 'Já sangrou de maneira anormal?',
            answer: 'Não',
          },
          {
            question: 'É fumante?',
            answer: 'Sim 10 anos',
          },
          {
            question: 'Ingere bebida alcoólica?',
            answer: 'Não',
          },
          {
            question: 'Alergia?',
            answer: 'Sim',
          },
          {
            question: 'Diabetes?',
            answer: 'Não',
          },
          {
            question: 'Doenças Crônicas?',
            answer: 'Não',
          },
          {
            question: 'Hipertensão?',
            answer: 'Sim',
          },
          {
            question: 'Faz pratica de atividade física?',
            answer: 'Não',
          },
          {
            question: 'Peso:',
            answer: '70kg',
          },
          {
            question: 'Altura:',
            answer: '1.80m',
          },
          {
            question: 'Outras Observações:',
            answer: 'Está tudo bem',
          },
          {
            question: 'Tratamento:',
            answer: 'Correr 10 dias',
          },
          {
            question: 'Receita:',
            answer: 'Dipirona',
          }
        ],
      });
    }
  }

  async listRecords(): Promise<Record[]> {
    return new Promise((resolve) => {
      resolve(this.records);
    });
  }
}

export default new RecordsService();
