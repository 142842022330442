import styled from "styled-components";
import chroma from "chroma-js";

import { Container as Select } from "@components/Select/styles";
import { Container as Button } from "@components/Button/styles";
import { Container as ResultCard } from "@components/ResultCard/styles";

import { convertToRem } from "@utils/convertToRem";


export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(40)};
`;

export const ReportContainer = styled.div`
  display: grid;
  grid-template-columns: ${convertToRem(370)} 1fr;
  gap: ${convertToRem(30)};

  width: 100%;

  margin: ${convertToRem(40)} 0;
`;

export const GenerateReportContainer = styled.form`
  height: fit-content;

  padding: ${convertToRem(15)} ${convertToRem(20)};

  background-color: ${({ theme }) => theme.colors.neutral.white};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
  border-radius: ${convertToRem(5)};

  > h3 {
    margin-bottom: ${convertToRem(40)};

    font-weight: 500;
    font-size: ${convertToRem(20)};
    line-height: 1.8;

    color: ${({ theme }) => theme.colors.neutral.text};
  }

  > ${Select}:not(:first-child) {
    margin-top: ${convertToRem(15)};
  }

  > ${Button} {
    width: 100%;

    margin-top: ${convertToRem(30)};
  }
`;

export const ResultsContainer = styled.div`
  max-height: inherit;

  background-color: ${({ theme }) => chroma(theme.colors.neutral.border).alpha(0.25).css()};
  
  padding: ${convertToRem(60)} ${convertToRem(30)};

  overflow: auto;

  border-radius: ${convertToRem(5)};

  
  > ${ResultCard} {
    padding: ${convertToRem(20)};
    
    &:not(:first-child) {
      margin-top: ${convertToRem(10)};
    }
  }

  &::-webkit-scrollbar {
    width: ${convertToRem(6)};
  }

  ::-webkit-scrollbar-track {
    background: ${({ theme }) => chroma(theme.colors.neutral.border).alpha(0.4).css()};
  }

  ::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.colors.neutral.borderDark};

    border-radius: ${convertToRem(10)};
  }
`;

export const DownButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${convertToRem(40)};
  height: ${convertToRem(40)};

  margin-left: auto;

  background-color: ${({ theme }) => chroma(theme.colors.primary.highlight).alpha(0.05).css()};
  color: ${({ theme }) => theme.colors.primary.base};

  border: 0;

  > svg {
    width: auto;
    height: ${convertToRem(25)};

    stroke-width: 1.2;
  }
`;
