import { CheckboxProps } from "./Checkbox";

import { Container } from "./styles";

export function Checkbox({ name, label, ...rest }: CheckboxProps) {
  return (
    <Container htmlFor={name}>
      <input type="checkbox" id={name} {...rest}/>
      
      <strong>{label}</strong>
    </Container>
  );
}