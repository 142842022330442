import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Professional, useProfessional } from '@contexts/professional';

import { Title, Description } from '@components/Header';
import { Tabs } from '@components/Tabs';

// Tabs
import { GeneralTab } from './GeneralTab';
import { SchedulesTab } from './SchedulesTab';
import { councilTypeOptions, receiptTypeOptions, SettingsTab } from './SettingsTab';
import { AddressTab } from './AddressTab';
import { BankInfoTab } from './BankInfoTab';
import { PictureTab } from './PictureTab';

import { Content, ContentWrapper } from './styles';
import { genderOptions } from '@/constants/genders';
import { pixKeyOptions } from '@/constants/pix-keys';

const mockProfessional: Professional = {
  name: 'Paulo Cavalcante',
  email: 'paulo@mail.com',
  document: '123.456.789.12',
  gender: genderOptions[0],
  specialties: [{ value: '16', label: 'Artroscopia' }],

  consultationDuration: '00:30',
  schedule: [
    {
      weekday: 'Domingo',
      times: [{ start: '00:00', end: '00:00' }],
      isDisabled: true,
    },
    {
      weekday: 'Segunda-feira',
      times: [{ start: '00:00', end: '00:00' }],
      isDisabled: true,
    },
    {
      weekday: 'Terça-feira',
      times: [{ start: '08:00', end: '12:00' }],
      isDisabled: false,
    },
    {
      weekday: 'Quarta-feira',
      times: [{ start: '14:00', end: '18:00' }],
      isDisabled: false,
    },
    {
      weekday: 'Quinta-feira',
      times: [{ start: '00:00', end: '00:00' }],
      isDisabled: true,
    },
    {
      weekday: 'Sexta-feira',
      times: [{ start: '00:00', end: '00:00' }],
      isDisabled: true,
    },
    {
      weekday: 'Sábado',
      times: [{ start: '00:00', end: '00:00' }],
      isDisabled: true,
    }
  ],

  councilType: councilTypeOptions[0],
  councilUF: { value: '2', label: 'AC' },
  councilNumber: '123456',
  normalValue: 79.99,
  primeValue: 54.99,
  receiptType: receiptTypeOptions[0],
  onlineConsultation: true,
  isUsingClinicWhatsapp: false,
  whatsapp: '(71) 99885-8965',

  transferType: 'pix',
  keyType: pixKeyOptions[0],
  keyValue: '087.709.795-02',
  bank: null,
  bankAgency: '',
  bankAccount: '',
  bankCpf: '',
  
  address: {
    zipCode: '42809-469',
    street: 'Rua Cantuária',
    number: '17',
    complement: 'Casa B',
    neighborhood: 'Piaçaveira',
    state: { value: '16', label: 'BA' },
    city: { value: '1898', label: 'Camaçari' },
  },
  isUsingClinicAddress: false,
};

export function AddEditProfessionals() {
  const { validation, clearProfessionalInfo, addProfessionalInfo } = useProfessional();

  const { pathname } = useLocation();
  const paths = pathname.split('/');
  const tab = paths[paths.length - 1].toLowerCase();
  const isEditing = paths[2].toLowerCase() === 'editar';

  const [title, setTitle] = useState('');

  const { isGeneralTabEmpty, isSchedulesTabEmpty, isSettingsTabEmpty, isBankInfoTabEmpty, isAddressTabEmpty } = validation;
  
  useEffect(() => {
    if (isEditing) {
      // Get professional info from API by uuid
      addProfessionalInfo(mockProfessional);
    } else {
      clearProfessionalInfo();
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);
  
  useEffect(() => {
    const pageTitle = isEditing ? 'Editar' : 'Adicionar';

    switch (tab) {
      case 'geral': return setTitle(`${pageTitle} Profissional de Saúde`);
      case 'horarios': return setTitle(`Horários | ${pageTitle} Profissional de Saúde`);
      case 'configuracoes': return setTitle(`Configurações | ${pageTitle} Profissional de Saúde`);
      case 'dados-bancarios': return setTitle(`Dados Bancários | ${pageTitle} Profissional de Saúde`);
      case 'endereco': return setTitle(`Endereço | ${pageTitle} Profissional de Saúde`);
      case 'foto': return setTitle(`Foto | ${pageTitle} Profissional de Saúde`);
      default: return setTitle(`${pageTitle} Profissional de Saúde`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);


  return (
    <Content>
      <ContentWrapper className="content">
        <Title>{title}</Title>
        <Description>Mantenha o seu cadastro atualizado para aproveitar ao máximo nossas vantagens e funcionalidades.</Description>

        <Tabs 
          tabs={[
            { 
              path: "/", 
              element: <Navigate to="geral" /> 
            },
            { 
              path: "geral", 
              title: "Geral", 
              element: <GeneralTab /> 
            },
            { 
              path: "horarios", 
              title: "Horários", 
              element: <SchedulesTab />, 
              isDisabled: isGeneralTabEmpty 
            },
            { 
              path: "configuracoes", 
              title: "Configurações", 
              element: <SettingsTab />, 
              isDisabled: isGeneralTabEmpty || isSchedulesTabEmpty,
            },
            {
              path: "dados-bancarios",
              title: "Dados Bancários",
              element: <BankInfoTab />,
              isDisabled: isGeneralTabEmpty || isSchedulesTabEmpty || isSettingsTabEmpty,
            },
            { 
              path: "endereco", 
              title: "Endereço", 
              element: <AddressTab />, 
              isDisabled: isGeneralTabEmpty || isSchedulesTabEmpty || isSettingsTabEmpty || isBankInfoTabEmpty, 
            },
            // { 
            //   path: "foto", 
            //   title: "Foto", 
            //   element: <PictureTab />, 
            //   isDisabled: isGeneralTabEmpty || isSchedulesTabEmpty || isSettingsTabEmpty || isAddressTabEmpty, 
            // },
          ]}
        />
      </ContentWrapper>
    </Content>
  );
}