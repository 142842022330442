import styled from "styled-components";
import chroma from "chroma-js";

import { ItemContainer, ItemDivider } from '@components/ResultCard/styles';

import { convertToRem } from "@utils/convertToRem";


export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(40)};
`;

export const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 2fr 6fr;
  gap: ${convertToRem(30)};

  margin-top: ${convertToRem(28)};
`;

export const ResultsContainer = styled.div`
  margin-top: ${convertToRem(30)};

  > div:not(:first-child) {
    margin-top: ${convertToRem(10)};
  }

  ${ItemContainer} {
    flex: 1;
  }

  ${ItemDivider} {
    margin: 0 ${convertToRem(20)};
  }
`;

export const AttendedElement = styled.span<{ attended?: boolean; }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${convertToRem(18)};
  height: ${convertToRem(18)};

  background-color: ${({ attended, theme }) => attended ? theme.colors.helpers.success : 'transparent'};
  color: ${({ theme }) => theme.colors.neutral.white};

  border: ${({ attended, theme }) => attended ? '0' : `solid ${convertToRem(2)} ${theme.colors.neutral.borderDark}`};

  border-radius: ${convertToRem(6)};
`;

export const Footer = styled.footer`
  width: 100%;
  height: ${convertToRem(100)};

  margin-top: ${convertToRem(60)};

  border-top: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

  .content {
    display: flex;
    align-items: center;
  }
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-left: auto;
`;

export const PageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${convertToRem(40)};
  height: ${convertToRem(40)};

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.primary.base};

  border: ${convertToRem(1)} solid ${({ theme }) => theme.colors.neutral.border};
  border-radius: ${convertToRem(5)};

  &.prev {
    transform: rotate(90deg);
  }

  &.next {
    transform: rotate(270deg);
  }
  
  &:disabled {
    background-color: ${({ theme }) => chroma(theme.colors.neutral.border).brighten(0.4).css()};

    cursor: not-allowed;
  }

  > svg {
    width: auto;
    height: ${convertToRem(20)};
    
    stroke-width: 1.2;
  }
`;

export const Page = styled.span`
  margin: 0 ${convertToRem(20)};
  
  color: ${({ theme }) => theme.colors.neutral.text};

  font-weight: 500;
  font-size: ${convertToRem(15)};
  line-height: 1;
`;

