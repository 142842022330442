import HttpClient from './utils/HttpClient';

interface ListTreatmentsResponse {
  id: number;
  nome: string;
}

class TreatmentsService {
  async listTreatments() {
    return HttpClient.get<ListTreatmentsResponse[]>('/base/tratamentos');
  }
}

export default new TreatmentsService(); 