export default {
  title: 'default',
  colors: {
    primary: {
      darkest: '#300A0A',
      dark: '#BA0606',
      base: '#C92A2A',
      light: '#FF8787',
      lightest: '#FFF8F8',
      highlight: '#FA5252',
    },
    neutral: {
      gray: '#4F4040',
      border: '#DFE0EB',
      borderLight: '#F8F9FA',
      borderDark: '#CED4DA',
      white: '#FFFFFF',
      black: '#000000',
      text: '#495057',
      textDark: '#231F20',
    },
    helpers: {
      success: '#12B886',
      danger: '#FA5252',
      info: '#5277FA',
      warning: '#F39C12',
    }
  },
} as const;