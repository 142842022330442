import { RiBarChartLine, RiCalendarCheckLine, RiCalendarLine, RiCustomerService2Line, RiDashboardLine, RiFileTextLine, RiPriceTag2Line, RiSettings4Line, RiSuitcaseLine, RiTeamLine, RiUser3Line } from 'react-icons/ri';

import { useAuth } from '@contexts/auth';

import { SidebarMenuItem } from '.';

export function ClinicSidebar() {
  const { selectedAccount } = useAuth();

  return (
    <>
      <SidebarMenuItem 
        path='/' 
        icon={<RiDashboardLine />} 
        title='Dashboard' 
      />

      <SidebarMenuItem 
        path='/crm' 
        icon={<RiCustomerService2Line />} 
        title='CRM' 
      />
      
      <SidebarMenuItem 
        path='/agendamentos' 
        icon={<RiCalendarLine />} 
        title='Agendamentos' 
      />

      <SidebarMenuItem 
        path='/agenda-digital' 
        icon={<RiCalendarLine />} 
        title='Agenda Digital' 
      />

      <SidebarMenuItem 
        path='/confirmacao-de-agenda' 
        icon={<RiCalendarCheckLine />} 
        title='Confirmação de Agenda' 
      />

      <SidebarMenuItem 
        path='/leads' 
        icon={<RiTeamLine />} 
        title='Leads' 
      />

      <SidebarMenuItem 
        path='/perfil' 
        icon={<RiUser3Line />} 
        title='Perfil'
        subnav={[
          { path: '/perfil/editar-cadastro', title: 'Editar Cadastro' },
          { path: '/perfil/alterar-senha', title: 'Alterar Senha' },
          { path: '/perfil/alterar-logotipo', title: 'Alterar Logotipo' },
        ]}
      />

      <SidebarMenuItem 
        path='/profissionais' 
        icon={<RiSuitcaseLine />} 
        title='Profissionais' 
        subnav={[
          { path: '/profissionais/listagem', title: 'Listagem' },
          { path: '/profissionais/adicionar/geral', title: 'Adicionar' },
        ]}
      />

      <SidebarMenuItem 
        path='/exames' 
        icon={<RiFileTextLine />} 
        title='Exames'
        subnav={[
          { path: '/exames/listagem', title: 'Listagem' },
          { path: '/exames/horarios', title: 'Horários' },
        ]}
      />

      <SidebarMenuItem 
        path='/relatorios' 
        icon={<RiBarChartLine />} 
        title='Relatórios'
        subnav={[
          { path: '/relatorios/metricas-de-atendimento', title: 'Métricas de Atendimento' },
          { path: '/relatorios/metricas-de-exames', title: 'Métricas de Exames' },
          { path: '/relatorios/metricas-gerais', title: 'Métricas Gerais' },
        ]} 
      />

      <SidebarMenuItem 
        path='/configuracoes' 
        icon={<RiSettings4Line />} 
        title='Configurações' 
        subnav={[
          { path: '/configuracoes/gerais', title: 'Gerais' },
          { path: '/configuracoes/formas-de-pagamento', title: 'Formas de Pagamento' },
          { path: '/configuracoes/motivos-de-consulta', title: 'Motivos de Consulta' },
          { path: '/configuracoes/colaboradores', title: 'Colaboradores' },
        ]} 
      />

      <SidebarMenuItem 
        path='/planos' 
        icon={<RiPriceTag2Line />} 
        title='Planos' 
        subnav={[
          { 
            path: '/planos/seja-prime', 
            title: 'Seja Prime', 
            isDisabled: selectedAccount?.isPrime 
          },
          { 
            path: '/planos/afiliado', 
            title: 'Afiliado' 
          },
        ]} 
      />
    </>
  );
}