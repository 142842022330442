import { TextAreaProps } from './TextArea';

import { Container, Error } from './styles';


export function TextArea({ error, label, name, ...rest }: TextAreaProps) {
  return (
    <Container error={!!error}>
      <label htmlFor={name}>{label}</label>
      <textarea id={name} name={name} {...rest}></textarea>
      {error && <Error>{error}</Error>}
    </Container>
  );
}