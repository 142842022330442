import styled from "styled-components";
import chroma from "chroma-js";

import { Modal } from "@components/Modal";
import { InputGroup } from "@components/Input/styles";
import { Container as Button } from "@components/Button/styles";

import { convertToRem } from "@utils/convertToRem";

export const EditPersonalDataModal = styled(Modal)`
  max-width: ${convertToRem(600)};

  form {
    width: 100%;
  }

  ${Button} {
    margin-top: ${convertToRem(24)};
    margin-left: auto;
  }

  @media (max-width: 576px) {
    ${InputGroup} {
      grid-template-columns: 1fr;
    }
  }
`;

export const EditBankDataModal = styled(Modal)`
  max-width: ${convertToRem(600)};

  form {
    width: 100%;
  }

  ${Button} {
    margin-top: ${convertToRem(24)};
    margin-left: auto;
  }

  @media (max-width: 576px) {
    ${InputGroup} {
      grid-template-columns: 1fr;
    }
  }
`;

export const EditAddressDataModal = styled(Modal)`
  max-width: ${convertToRem(600)};

  form {
    width: 100%;
  }

  ${Button} {
    margin-top: ${convertToRem(24)};
    margin-left: auto;
  }

  @media (max-width: 576px) {
    ${InputGroup} {
      grid-template-columns: 1fr;
    }
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: calc(100vh - ${convertToRem(140)});
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  max-width: ${convertToRem(730)};

  margin: 0 auto;
  padding: ${convertToRem(40)} ${convertToRem(15)} ${convertToRem(80)};

  .card {
    width: 100%;

    background-color: ${({ theme }) => theme.colors.neutral.white};

    box-shadow: 0 ${convertToRem(2)} ${convertToRem(8)} rgba(0, 0, 0, 0.075);
    border-radius: ${convertToRem(8)};
  }

  header {
    width: 100%;

    .promo-image {
      width: 100%;
  
      border-radius: ${convertToRem(8)};
  
      overflow: hidden;
  
      img {
        display: block;
  
        width: 100%;
  
        &.mobile {
          display: none;
        }
      }
    }
  
    @media screen and (max-width: 992px) {
      .promo-image {
        height: auto;
  
        img {
          height: auto;
  
          object-fit: inherit;
  
          display: none;
  
          &.mobile {
            display: block;
          }
        }
      }
    }

    h1 {
      margin-top: ${convertToRem(24)};
      color: ${({ theme }) => theme.colors.neutral.textDark};

      font-weight: 400;
    }
  }

  section {
    width: 100%;

    margin-top: ${convertToRem(24)};
  }

  .card {
    padding: ${convertToRem(24)};

    > * + * {
      margin-top: ${convertToRem(16)};
    }

    > h3 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: ${convertToRem(24)};

      color: ${({ theme }) => theme.colors.primary.base};

      font-weight: 400;
      font-size: ${convertToRem(18)};

      > div {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }

    }
  }
  
  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    background: none;
    color: ${({ theme }) => theme.colors.primary.base};

    font-weight: 500;

    border: 0;

    svg {
      margin-right: ${convertToRem(4)};
    }

    &:not(:first-child) {
      margin-left: ${convertToRem(16)};
    }
  }
  
  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    .label {
      color: ${({ theme }) => theme.colors.neutral.textDark};
  
      font-weight: 500;
      font-size: ${convertToRem(13)};
      line-height: 1;
    }

    .value {
      margin-top: ${convertToRem(8)};

      color: ${({ theme }) => theme.colors.neutral.text};
  
      font-size: ${convertToRem(15)};
      line-height: 1;

      text-transform: uppercase;
    }
    
    .avatar-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .avatar {
        position: relative;

        width: ${convertToRem(80)};
        height: ${convertToRem(80)};
        
        border-radius: 50%;
        overflow: hidden;

        .loading {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          display: flex;
          align-items: center;
          justify-content: center;

          background-color: ${({ theme }) => chroma(theme.colors.neutral.white).alpha(0.75).css()};
        }

        img {
          display: block;
  
          width: 100%;
          height: 100%;
          
          object-fit: cover;
        }
      }

      input[type='file'] {
        display: none;
      }
    }
  }

  @media (max-width: 576px) {
    ${InputGroup} {
      grid-template-columns: 1fr;
      gap: ${convertToRem(16)};
    }
  }
`;