import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RiCloseLine } from 'react-icons/ri';

import { Loading } from '@components/Loading';
import { Modal } from '@components/Modal';
import { Description, Title } from '@components/Header';
import { Input } from '@components/Input';
import { Select } from '@components/Select';
import { Button } from '@components/Button';
import ResultCard, { ItemDivider, ItemValue, ItemContainer, ItemTitle, ActionsContainer, ActionButton } from '@components/ResultCard';
import { Tooltip } from '@components/Tooltip';
import { TabsContainer } from '@components/Tabs';

import ClinicsService, { Clinic } from '@services/ClinicsService';

import { CancelSquare, ChevronDown, Info, PlusCircle, Search } from '@assets/images';

import { convertToRem } from '@utils/convertToRem';
import { formatCPFCNPJ } from '@utils/formatCPFCNPJ';

import { OnChangeOptionType } from '@components/Select/Select.d';

import { Content, ContentWrapper, FiltersContainer, Footer, LoadingContainer, ModalContent, Page, PageButton, Pagination, ResultsContainer } from './styles';

const limitSelectItems = [
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
];

type ModalTab = 'data' | 'bank-data' | 'change-whatsapp';

export function ListClinics() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [clinics, setClinics] = useState<Clinic[]>([]);
  
  const [search, setSearch] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);
  
  const filteredClinics = clinics.filter(clinic => clinic.name.toLowerCase().includes(search.toLowerCase()));
  const lastPage = Math.ceil(filteredClinics.length / itemsPerPage) || 1;

  const [selectedClinic, setSelectedClinic] = useState<Clinic | null>(null);

  const [isStatusModalVisible, setIsStatusModalVisible] = useState(false);
  const [isPrimeModalVisible, setIsPrimeModalVisible] = useState(false);
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [selectedModalTab, setSelectedModalTab] = useState<ModalTab>('data');
  const [whatsapp, setWhatsapp] = useState('');

  // Setting dependents
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const clinicsData = await ClinicsService.listClinics()

        setClinics(clinicsData);
      } catch (error) {
        toast.error('Não foi possível carregar dados das clínicas!');

        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })()
  }, []);

  useEffect(() => {
    if (selectedClinic === null) {
      setWhatsapp('');
      return;
    }

    setWhatsapp(selectedClinic.whatsapp);
  }, [selectedClinic])

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  function handleChangeLimit(selectedOption: OnChangeOptionType) {
    if (selectedOption === null) return;

    setItemsPerPage(Number(selectedOption.value));
  };


  function handleChangeStatus() {
    setIsStatusModalVisible(false);
    toast.success(`Clínica ${selectedClinic?.status === 'blocked' ? 'desbloqueada' : 'bloqueada'} com sucesso!`)
  }

  function handleChangePrime() {
    setIsPrimeModalVisible(false);
    toast.success(`Prime ${selectedClinic?.isPrime ? 'retirado' : 'adicionado'} com sucesso!`)
  }

  function handleDeleteClinic() {
    setIsDeleteModalVisible(false);
    toast.success('Clínica excluída com sucesso!');
  }

  function handleChangeWhatsApp() {
    alert(JSON.stringify({ whatsapp }));
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  return (
    <Content>
      <Modal 
        visible={selectedClinic !== null && isStatusModalVisible}
        title="Atenção"
        onRequestClose={() => setIsStatusModalVisible(false)} 
      >
        <p className='text'>
          Deseja realmente {selectedClinic?.status === 'cleared' ? 'bloquear' : 'desbloquear'} a clínica <strong>{selectedClinic?.name}</strong>?
        </p>

        <div className='buttons'>
          <button onClick={() => setIsStatusModalVisible(false)}>
            Não
          </button>

          <button onClick={handleChangeStatus} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <Modal 
        visible={selectedClinic !== null && isPrimeModalVisible}
        title="Atenção"
        onRequestClose={() => setIsPrimeModalVisible(false)} 
      >
        <p className='text'>
          Deseja realmente {selectedClinic?.isPrime ? 'retirar' : 'adicionar'} Prime da clínica <strong>{selectedClinic?.name}</strong>?
        </p>

        <div className='buttons'>
          <button onClick={() => setIsPrimeModalVisible(false)}>
            Não
          </button>

          <button onClick={handleChangePrime} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <Modal
        visible={selectedClinic !== null && isDetailModalVisible}
        onRequestClose={() => setIsDetailModalVisible(false)}
        disablePadding
        style={{ maxWidth: convertToRem(900) }}
      >
        <ModalContent>
          <header>
            <button className='close-btn' onClick={() => setIsDetailModalVisible(false)}>
              <RiCloseLine />
            </button>

            <h2>{selectedClinic?.name}</h2>

            <TabsContainer>
              <button
                className={selectedModalTab === 'data' ? 'selected' : ''}
                onClick={() => setSelectedModalTab('data')}
              >
                Dados
              </button>

              <button
                className={selectedModalTab === 'bank-data' ? 'selected' : ''}
                onClick={() => setSelectedModalTab('bank-data')}
              >
                Dados Bancários
              </button>

              <button
                className={selectedModalTab === 'change-whatsapp' ? 'selected' : ''}
                onClick={() => setSelectedModalTab('change-whatsapp')}
              >
                Alterar WhatsApp
              </button>
            </TabsContainer>
          </header>

          <footer>
            {
              selectedModalTab === 'data'
              ? (
                <>
                  <img 
                    src={selectedClinic?.avatar} 
                    alt={selectedClinic?.name} 
                  />

                  <div className='info-group'>
                    <div>
                      <h5 className="label">Nome Fantasia</h5>
                      <span className="value">{selectedClinic?.fantasyName}</span>
                    </div>

                    <div>
                      <h5 className="label">Nome</h5>
                      <span className="value">{selectedClinic?.name}</span>
                    </div>
                  </div>

                  <div className="info-group">
                    <div>
                      <h5 className="label">CNPJ</h5>
                      <span className="value">{selectedClinic ? formatCPFCNPJ(selectedClinic.cnpj) : ''}</span>
                    </div>

                    <div>
                      <h5 className="label">E-mail</h5>
                      <span className="value">{selectedClinic?.email}</span>
                    </div>

                    <div>
                      <h5 className="label">Telefone</h5>
                      <span className="value">{selectedClinic?.tel}</span>
                    </div>

                    <div>
                      <h5 className="label">CEP</h5>
                      <span className="value">{selectedClinic?.address.zipCode}</span>
                    </div>
                  </div>

                  <div className="info-group">
                    <div>
                      <h5 className="label">Endereço</h5>
                      <span className="value">{selectedClinic?.address.street}</span>
                    </div>

                    <div>
                      <h5 className="label">Número</h5>
                      <span className="value">{selectedClinic?.address.number}</span>
                    </div>

                    <div>
                      <h5 className="label">Complemento</h5>
                      <span className="value">{selectedClinic?.address.complement}</span>
                    </div>

                    <div>
                      <h5 className="label">Bairro</h5>
                      <span className="value">{selectedClinic?.address.neighborhood}</span>
                    </div>

                    <div>
                      <h5 className="label">Cidade</h5>
                      <span className="value">{selectedClinic?.address.city}/{selectedClinic?.address.state}</span>
                    </div>
                  </div>

                  <div className="info-group">
                    <div>
                      <h5 className="label">Descrição</h5>
                      <span className="value">{selectedClinic?.description}</span>
                    </div>
                  </div>
                </>
              )
              : selectedModalTab === 'bank-data'
              ? (
                <>
                  <div className="info-group">
                    <div>
                      <h5 className="label">Titular</h5>
                      <span className="value">{selectedClinic?.bankInfo.owner}</span>
                    </div>
                  </div>

                  <div className="info-group">
                    <div>
                      <h5 className="label">Banco</h5>
                      <span className="value">{selectedClinic?.bankInfo.bank}</span>
                    </div>

                    <div>
                      <h5 className="label">Agência</h5>
                      <span className="value">{selectedClinic?.bankInfo.agency}</span>
                    </div>

                    <div>
                      <h5 className="label">Conta</h5>
                      <span className="value">{selectedClinic?.bankInfo.account}</span>
                    </div>
                  </div>
                </>
              )
              : (
                <>
                  <Input 
                    label="WhatsApp da clínica" 
                    name="whatsapp" 
                    value={whatsapp}
                    onChange={(e) => setWhatsapp(e.target.value)}
                    mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  />

                  <Button onClick={handleChangeWhatsApp}>
                    Alterar
                  </Button>
                </>
              )
            }
          </footer>
        </ModalContent>
      </Modal>

      <Modal 
        visible={selectedClinic !== null && isDeleteModalVisible}
        title="Atenção"
        onRequestClose={() => setIsDeleteModalVisible(false)} 
      >
        <p className='text'>
          Deseja realmente excluir a clínica <strong>{selectedClinic?.name}</strong>?
        </p>

        <div className='buttons'>
          <button onClick={() => setIsDeleteModalVisible(false)}>
            Não
          </button>

          <button onClick={handleDeleteClinic} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <ContentWrapper className="content">
        <Title>Clínicas</Title>
        <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam massa sapien velit ipsum, ac.
        </Description>

        <FiltersContainer>
          <Input
            label='Buscar clínica'
            name='search'
            placeholder='Pesquisar pelo nome'
            iconRight={<Search />}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <Select
            label="Exibir últimos"
            options={limitSelectItems}
            onChange={handleChangeLimit}
            defaultValue={limitSelectItems.find(
              (i) => i.label === String(itemsPerPage)
            )}
            isClearable={false}
          />

          <Button variant="tertiary" onClick={() => navigate('/clinicas/adicionar')}>
            Cadastrar clínica

            <PlusCircle strokeWidth={1.5} />
          </Button>
        </FiltersContainer>

        <ResultsContainer>
          {
            filteredClinics.length > 0
            ? filteredClinics
              .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
              .map(clinic => (
                <ResultCard key={clinic.id}>
                  <ItemContainer>
                    <div>
                      <ItemValue>{clinic.name}</ItemValue>
                      <ItemTitle>Nome</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ItemDivider />

                  <ItemContainer>
                    <div>
                      <ItemValue>{clinic.email}</ItemValue>
                      <ItemTitle>E-mail</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ItemContainer className='buttons'>
                    <Tooltip content={clinic.status === 'cleared' ? 'Bloquear' : 'Desbloquear'}>
                      <Button 
                        variant='tertiary'
                        onClick={() => {
                          setSelectedClinic(clinic);
                          setIsStatusModalVisible(true);
                        }}
                      >
                        {clinic.status === 'cleared' ? 'Liberado' : 'Bloqueado'}
                      </Button>
                    </Tooltip>
                    
                    <Tooltip content={clinic.isPrime ? 'Retirar Prime' : 'Adicionar Prime'}>
                      <Button 
                        variant='tertiary'
                        onClick={() => {
                          setSelectedClinic(clinic);
                          setIsPrimeModalVisible(true);
                        }}
                      >
                        {clinic.isPrime ? 'Prime' : 'Não é Prime'}
                      </Button>
                    </Tooltip>
                  </ItemContainer>
                  
                  <ActionsContainer>
                    <Tooltip content="Dados">
                      <ActionButton
                        color={theme.colors.helpers.success} 
                        onClick={() => {
                          setSelectedClinic(clinic);
                          setIsDetailModalVisible(true);
                        }}
                      >
                        <Info />
                      </ActionButton>
                    </Tooltip>

                    <Tooltip content="Excluir">
                      <ActionButton 
                        color={theme.colors.helpers.danger} 
                        onClick={() => {
                          setIsDeleteModalVisible(true);
                          setSelectedClinic(clinic);
                        }}
                      >
                        <CancelSquare />
                      </ActionButton>
                    </Tooltip>
                  </ActionsContainer>
                </ResultCard>
              ))
            : 'Nenhum dependente foi encontrado' 
          }
        </ResultsContainer>
      </ContentWrapper>

      <Footer>
        <div className="content">
          <Pagination>
            <PageButton
              className="prev"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              <ChevronDown />
            </PageButton>

            <Page>{String(currentPage).padStart(2, "0")}</Page>

            <PageButton
              className="next"
              onClick={goToNextPage}
              disabled={currentPage === lastPage}
            >
              <ChevronDown />
            </PageButton>
          </Pagination>
        </div>
      </Footer>
    </Content>
  );
}