import { useFormik } from "formik";
import * as Yup from "yup";

import { Input, InputGroup } from "@components/Input";
import { Callout } from "@components/Callout";
import { Button } from "@components/Button";

import { FormikEffect } from "@utils/FormikEffect";

import { FormContainer } from "./styles";

export function EditPasswordTab() {
  const formik = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string()
        .required("O campo é obrigatório"),
      newPassword: Yup.string()
        .min(8, "Insira uma senha com no mínimo 8 caracteres")
        .required("O campo é obrigatório"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "As senhas não coincidem")
        .required("O campo é obrigatório")
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values));
    }
  });

  return (
    <FormContainer onSubmit={formik.handleSubmit}>
      <FormikEffect formik={formik} />

      <Input 
        type="password" 
        label="Senha atual" 
        name="oldPassword" 
        value={formik.values.oldPassword}
        onChange={formik.handleChange}
        error={(formik.touched.oldPassword || undefined) && formik.errors.oldPassword}
      />

      <InputGroup>
        <Input 
          type="password" 
          label="Nova Senha" 
          name="newPassword" 
          value={formik.values.newPassword}
          onChange={formik.handleChange}
          error={(formik.touched.newPassword || undefined) && formik.errors.newPassword}
        />

        <Input 
          type="password" 
          label="Confirmar Senha" 
          name="confirmPassword"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange} 
          error={(formik.touched.confirmPassword || undefined) && formik.errors.confirmPassword}
        />
      </InputGroup>

      <Callout>Essa Informação poderá ser editada após o cadastro</Callout>

      <Button type="submit">Atualizar</Button>
    </FormContainer>
  );
}