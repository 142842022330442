import styled, { keyframes } from 'styled-components';
import chroma from 'chroma-js';
import * as PopoverPrimitive from '@radix-ui/react-popover';

import { convertToRem } from '@utils/convertToRem';

export const slideUpAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateY(10%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const slideRightAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-10%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const slideDownAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-10%);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const slideLeftAndFade = keyframes`
  0% {
    opacity: 0;
    transform: translateX(10%);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const StyledArrow = styled(PopoverPrimitive.Arrow)`
  fill: white;
`;

export const StyledContent = styled(PopoverPrimitive.Content)`
  width: ${convertToRem(280)};
  
  padding: ${convertToRem(20)};
  
  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.text};

  box-shadow: 0 ${convertToRem(8)} ${convertToRem(20)} ${({ theme }) => chroma(theme.colors.neutral.black).alpha(0.1).css()};

  border-radius: ${convertToRem(6)};

  @media (prefers-reduced-motion: no-preference) {
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);

    animation-fill-mode: forwards;

    will-change: transform, opacity;

    &[data-state="open"] {
      &[data-side="top"] { 
        animation-name: ${slideDownAndFade};
      }

      &[data-side="right"] { 
        animation-name: ${slideLeftAndFade};
      }

      &[data-side="bottom"] {
        animation-name: ${slideUpAndFade}; 
      }

      &[data-side="left"] { 
        animation-name: ${slideRightAndFade};
      }
    }
  }
`;
