import { faker } from '@faker-js/faker';
import { parseISO } from 'date-fns';

import { Professional } from '@contexts/professional';

import HttpClient from './utils/HttpClient';

export type WeekDays = 0 | 1 | 2 | 3 | 4 | 5 | 6; // 0 - Sunday ... 7 - Saturday

export interface ProfessionalSchedule {
  duration: string;
  presential: Array<{
    weekDay: WeekDays;
    start: string;
    end: string;
  }>;
  online: Array<{
    weekDay: WeekDays;
    start: string;
    end: string;
  }>;
  specials: {
    presential: Array<{
      date: Date;
      start: string;
      end: string;
    }>;
    online: Array<{
      date: Date;
      start: string;
      end: string;
    }>;
  }
}

interface Feedback {
  name: string;
  avatar: string | null;
  isVerified: boolean;
  givenRating: number;
  comment: string;
  response: {
    name: string;
    avatar: string | null;
    comment: string;
  } | null;
};

export type ProfessionalsStatus = 'Liberado' | 'Bloqueado';
export interface Professionals {
  id: string;
  avatar: string | null;
  name: string;
  specialty: string;
  council: string;
  councilNumber: string;
  rating: {
    general: number;
    punctuality: number;
    attention: number;
    place: number;
  };
  status: ProfessionalsStatus;
  healthInsurance: string;
  isPrime: boolean;
  isAttendOnline: boolean;
  address: {
    street: string;
    number: string;
    complement?: string;
    zipCode: string;
    city: string;
    state: string;
  }
  whatsapp: string;
  normalValue: number;
  primeValue: number;
  coupon: string;
  about: string;
  experiences: string[];
  medicalConditions: string[];
  education: Array<{
    school: string;
    startYear: string;
    endYear: string;
    course: string;
  }>;
  feedbacks: Feedback[];
  schedule: ProfessionalSchedule;
  consultations: Date[];
};

export interface Filters {
  name?: string;
  uf?: string;
  city?: string;
  specialty?: string;
  isPrime?: boolean;
  isAttendOnline?: boolean;
  price?: number;
}


type Time = Array<string | null>;

interface ScheduleItemType {
  dom: Time;
  seg: Time;
  ter: Time;
  qua: Time;
  qui: Time;
  sex: Time;
  sab: Time;
}

interface CreateProfessionalRequest {
  clinica_id: number;

  nome: string;
  email: string;
  documento: string;
  genero: string;
  especialidades: string[];

  tempo_medio_consulta: string;
  horarios: ScheduleItemType;

  registro_tipo: string;
  registro_uf: string;
  registro_numero: string;
  valor: number;
  valor_prime: number;
  recebimento_tipo: string;
  atendimento_online: boolean;
  clinica_whatsApp: boolean;
  whatsApp: string;

  // transferType: "pix" | "ted";
  // keyType?: string;
  // keyValue?: string;
  // bank?: string;
  // bankAgency?: string;
  // bankAccount?: string;
  // bankCpf?: string;

  clinica_endereco: boolean;
  endereco: {
    endereco: string;
    numero: string;
    complemento: string;
    bairro: string;
    cidade: string;
    uf: string;
    cep: string;
  };
}

interface CreateProfessionalResponse {
  codigo: string;
  menssagem: string;
}

interface ListClinicProfessionalsRequest {
  clinica_id: number;
  limit?: number;
  page?: number; // Página 1 como 0
}

interface ListClinicProfessionalsResponse {
  codigo: string;
  profissionais: Array<{
    id: number;
    uuid: string;
    nome: string;
    cidade: string;
    registro_tipo: number;
    registro_numero: string;
    registro_uf: string;
    valor_consulta: number;
    valor_consulta_prime: number;
    status_id: number;
    status_descricao: string;
    status_cor: string;
  }>;
  pagina: number;
	limite: number;
	total: number;
}

interface UpdateClinicProfessionalStatusRequest {
  uuid: string;
  status_id: number; // 1 - Ativo / 2 - Inativo
}

interface UpdateClinicProfessionalStatusResponse {
  codigo: string;
  uuid: string;
  status_id: 1;
  status_descricao: string;
  status_cor: string;
  resposta: string;
}

interface DeleteClinicProfessionalRequest {
  uuid: string;
  clinica_id: number;
}

interface DeleteClinicProfessionalResponse {
  codigo: string;
  uuid: string;
  resposta: string;
}

faker.setLocale('pt_BR');
class ProfessionalsService {
  private professionals: Professionals[];

  constructor() {
    this.professionals = [];

    const randomizeArray = (array: any[]) => array.sort(() => 0.5 - Math.random());

    for (let i = 0; i < 22; i++) {
      const { name, address, datatype, lorem, phone, random, internet } = faker;

      const randomGender = datatype.number({ min: 0, max: 1, precision: 1 });

      const professionalName = `${randomGender === 0 ? 'Dr.' : 'Dra.'} ${name.firstName(randomGender)} ${name.lastName(randomGender)}`;
      const professionalAvatar = (i + 1) % 3 === 0 ? null : internet.avatar();

      let feedbacks: Feedback[] = [];

      for (let i = 0; i < datatype.float({ min: 10, max: 35, precision: 1 }); i++) {
        const patientName = `${name.firstName()} ${name.lastName()}`;

        feedbacks.push({
          name: patientName,
          avatar: random.arrayElement([internet.avatar(), null]),
          isVerified: datatype.boolean(),
          givenRating: datatype.float({ min: 0.5, max: 5.0, precision: 0.1 }),
          comment: lorem.paragraph(),
          response: random.arrayElement([null, {
            name: professionalName,
            avatar: professionalAvatar,
            comment: `Obrigado ${patientName.split(' ')[0]}, seu feedback é muito importante para nós!`,
          }]),
        });
      };

      const [addressNumber, addressStreetName, addressStreetType] = address.streetAddress().split(' ');

      const randomSpecialties = ['Ginecologia e Obstetrícia', 'Fonoaudiologia', 'Pediatria'];
      const randomCouncils = ['CRM', 'CRO', 'CRF'];
      
      const punctualityRating = datatype.float({ min: 0.5, max: 5.0, precision: 0.1 });
      const attentionRating = datatype.float({ min: 0.5, max: 5.0, precision: 0.1 });
      const placeRating = datatype.float({ min: 0.5, max: 5.0, precision: 0.1 });
      const generalRating = (punctualityRating + attentionRating + placeRating) / 3;

      this.professionals.push({
        id: datatype.uuid(),
        avatar: professionalAvatar,
        name: professionalName,
        address: {
          street: `${addressStreetType} ${addressStreetName}`,
          number: addressNumber,
          complement: datatype.boolean() ? address.secondaryAddress() : undefined,
          zipCode: address.zipCode(),
          city: address.city(2),
          state: address.stateAbbr(),
        },
        whatsapp: phone.phoneNumber('+55 ## ####-####'),
        specialty: random.arrayElement(randomSpecialties),
        council: random.arrayElement(randomCouncils),
        councilNumber: String(datatype.number({ min: 100000, max: 999999 })),
        rating: {
          punctuality: punctualityRating,
          attention: attentionRating,
          place: placeRating,
          general: generalRating,
        },
        status: random.arrayElement(['Liberado', 'Bloqueado']),
        healthInsurance: "Unimed Recife",
        isPrime: datatype.boolean(),
        isAttendOnline: datatype.boolean(),
        normalValue: datatype.float({ min: 100, max: 200, precision: 1 }),
        primeValue: datatype.float({ min: 50, max: 100, precision: 1 }),
        coupon: "NOMECUPOM15",
        about: lorem.paragraphs(3),
        experiences: randomizeArray(["Ginecologia", "Medicina", "Hospital", "Clínicas Populares"]),
        medicalConditions: randomizeArray(["Gripe", "Febre", "TPM", "Cólicas Crônicas", "Infecções", "Fraturas", "Inflamações", "Dores"]),
        education: [
          { school: 'UFMA',
            startYear: '1984',
            endYear: '1990',
            course: 'Medicina',
          },
          {
            school: 'UFMA',
            startYear: '1992',
            endYear: '1996',
            course: 'Pós-graduação em Ginecologia',
          },
        ],
        feedbacks,
        consultations: [parseISO('2022-03-18 10:00:00'), parseISO('2022-03-26 16:00:00')],
        schedule: {
          duration: '01:00:00',
          presential: [
            { weekDay: 1, start: '07:00:00', end: '13:00:00' },
            { weekDay: 2, start: '07:00:00', end: '13:00:00' },
            { weekDay: 3, start: '07:00:00', end: '13:00:00' },
            { weekDay: 4, start: '07:00:00', end: '13:00:00' },
            { weekDay: 5, start: '07:00:00', end: '13:00:00' },
          ],
          online: [
            { weekDay: 1, start: '13:00:00', end: '18:00:00' },
            { weekDay: 2, start: '13:00:00', end: '18:00:00' },
            { weekDay: 3, start: '13:00:00', end: '18:00:00' },
            { weekDay: 4, start: '13:00:00', end: '18:00:00' },
            { weekDay: 5, start: '13:00:00', end: '18:00:00' },
          ],
          specials: {
            presential: [
              { date: parseISO('2022-03-26'), start: '08:00:00', end: '13:00:00' },
            ],
            online: [
              { date: parseISO('2022-03-26'), start: '13:00:00', end: '18:00:00' },
            ],
          },
        }
      });
    }
  }

  async listProfessionals(filters?: Filters): Promise<Professionals[]> {
    return new Promise((resolve) => {
      let filteredProfessionals = [...this.professionals];

      if (filters?.name) {
        filteredProfessionals = filteredProfessionals.filter(professional => professional.name.toLowerCase().includes((filters.name as string).toLowerCase()))
      }

      if (filters?.uf) {
        filteredProfessionals = filteredProfessionals.filter(professional => professional.address.state.toUpperCase() === filters.uf?.substring(0, 2).toUpperCase());
      }
      
      if (filters?.city) {
        // filteredProfessionals = filteredProfessionals.filter(professional => professional.address.city.toLowerCase() === filters.city?.toLowerCase());
      }

      if (filters?.specialty) {
        filteredProfessionals = filteredProfessionals.filter(professional => professional.specialty.toLowerCase() === filters.specialty?.toLowerCase());
      }

      if (filters?.isPrime) {
        filteredProfessionals = filteredProfessionals.filter(professional => professional.isPrime === filters.isPrime);
      }

      if (filters?.isAttendOnline) {
        filteredProfessionals = filteredProfessionals.filter(professional => professional.isAttendOnline === filters.isAttendOnline);
      }

      if (filters?.price) {
        filteredProfessionals = filteredProfessionals.filter(professional => (filters.price !== undefined ? professional.normalValue <= filters.price || professional.primeValue <= filters.price : true));
      }

      resolve(filteredProfessionals);
    });
  }

  async listProfessionalById(id: string): Promise<Professionals | null> {
    return new Promise((resolve) => {
      const professionalById = this.professionals.find(professional => professional.id === id) || null;

      resolve(professionalById);
    });
  }

  async createProfessional(clinicId: number, professional: Professional) {
    function getWeekDayKeyValue(weekDay: string) {
      switch (weekDay.toLowerCase()) {
        case 'domingo': return 'dom';
        case 'segunda-feira': return 'seg';
        case 'terça-feira': return 'ter';
        case 'quarta-feira': return 'qua';
        case 'quinta-feira': return 'qui';
        case 'sexta-feira': return 'sex';
        case 'sábado': return 'sab';
        default: return '';
      }
    }

    const parsedProfessional: CreateProfessionalRequest = {
      clinica_id: clinicId,

      nome: professional.name,
      email: professional.email,
      documento: professional.document,
      genero: professional.gender?.value as string,
      especialidades: professional.specialties?.map(specialty => specialty.value) as string[],

      tempo_medio_consulta: professional.consultationDuration,
      horarios: professional.schedule.reduce<ScheduleItemType>((acc, cur) => {
        const parsedTimes = cur.isDisabled 
          ? [null] 
          : cur.times.map(time => time.start + '-' + time.end)
        ;

        return ({
          ...acc,
          [getWeekDayKeyValue(cur.weekday)]: parsedTimes,
        });
      }, {} as ScheduleItemType),

      registro_tipo: professional.councilType?.value as string,
      registro_uf: professional.councilUF?.value as string,
      registro_numero: professional.councilNumber,
      valor: professional.normalValue,
      valor_prime: professional.primeValue,
      recebimento_tipo: professional.receiptType?.value as string,
      atendimento_online: professional.onlineConsultation,
      clinica_whatsApp: professional.isUsingClinicWhatsapp,
      whatsApp: professional.whatsapp,

      clinica_endereco: professional.isUsingClinicAddress,
      endereco: {
        cep: professional.address.zipCode,
        endereco: professional.address.street,
        numero: professional.address.number,
        complemento: professional.address.complement,
        bairro: professional.address.neighborhood,
        uf: professional.address.state?.value as string,
        cidade: professional.address.city?.value as string,
      },
    };

    return HttpClient.post<CreateProfessionalResponse>('/profissional/empresa/cadastro', parsedProfessional);
  }

  async listClinicProfessionals(params: ListClinicProfessionalsRequest) {
    return HttpClient.get<ListClinicProfessionalsResponse>('/profissional/empresa/listar', { params });
  }

  async updateClinicProfessionalStatus(body: UpdateClinicProfessionalStatusRequest) {
    return HttpClient.post<UpdateClinicProfessionalStatusResponse>('/profissional/empresa/alterarStatus', body);
  }

  async deleteClinicProfessional(params: DeleteClinicProfessionalRequest) {
    return HttpClient.delete<DeleteClinicProfessionalResponse>('/profissional/empresa/deletar', { params });
  }
}

export default new ProfessionalsService();