import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useNavigate, useParams } from "react-router-dom";

import { useProfessional } from "@contexts/professional";

import { Loading } from "@components/Loading";
import { Input, InputGroup } from "@components/Input";
import { Select } from "@components/Select";
import { RadioInput, RadioGroup } from "@components/Radio";
import { Button } from "@components/Button";

import LocationsService from "@services/LocationsService";

import { currencyMask } from "@utils/currencyMask";
import { formatToBRL } from "@utils/formatToBRL";
import { convertBRLToNumber } from "@utils/convertBRLToNumber";
import { FormikEffect } from "@utils/FormikEffect";

import { OptionTypeBase } from "@components/Select/Select";

import { ButtonsContainer, FormContainer } from "./styles";

export const councilTypeOptions = [
  { value: '1', label: 'CRM' },
  { value: '2', label: 'CRP' },
  { value: '3', label: 'CRT' },
  { value: '4', label: 'CRF' },
  { value: '5', label: 'CRO' },
  { value: '6', label: 'CRN' },
  { value: '7', label: 'CREFITO' },
  { value: '8', label: 'COREN' },
];

export const receiptTypeOptions = [
  { value: '1', label: 'Clínica' },
  { value: '2', label: 'Aplicativo Agenda Consulta' },
];

interface InitialValues {
  councilType: OptionTypeBase | null;
  councilUF: OptionTypeBase | null;
  councilNumber: string;
  normalValue: string;
  primeValue: string;
  receiptType: OptionTypeBase | null;
  onlineConsultation: boolean;
  isUsingClinicWhatsapp: boolean;
  whatsapp: string;
}

export function SettingsTab() {
  const { professional, addProfessionalInfo, validation } = useProfessional();
  
  const navigate = useNavigate();
  const { uuid } = useParams();
  
  const [isLoading, setIsLoading] = useState(true);
  const [UFOptions, setUFOptions] = useState<OptionTypeBase[]>([]);


  const formik = useFormik<InitialValues>({
    enableReinitialize: true,
    initialValues: {
      councilType: councilTypeOptions.find(council => council.value === professional.councilType?.value) || null,
      councilUF: UFOptions.find(uf => uf.label === professional.councilUF?.label) || null,
      councilNumber: professional.councilNumber,
      normalValue: professional.normalValue ? formatToBRL(professional.normalValue) : '',
      primeValue: professional.primeValue ? formatToBRL(professional.primeValue) : '',
      receiptType: receiptTypeOptions.find(receipt => receipt.value === professional.receiptType?.value) || null,
      onlineConsultation: professional.onlineConsultation,
      isUsingClinicWhatsapp: professional.isUsingClinicWhatsapp,
      whatsapp: professional.whatsapp,
    },
    validationSchema: Yup.object().shape({
      councilType: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um tipo"),
      councilUF: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um estado"),
      councilNumber: Yup.string()
        .matches(/^[0-9]+$/, "Informe apenas números")
        .required("O campo é obrigatório"),
      normalValue: Yup.string()
        .required("O campo é obrigatório"),
      primeValue: Yup.string()
        .required("O campo é obrigatório"),
      receiptType: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione uma forma de recebimento"),
      onlineConsultation: Yup.boolean()
        .required("O campo é obrigatório"),
      isUsingClinicWhatsapp: Yup.boolean()
        .required("O campo é obrigatório"),
      whatsapp: Yup.string()
        .matches(/^[(]?\d{2}[)]?[-\s]?\d{5}[-\s]?\d{4}$/g, "Insira um WhatsApp válido")
        .required("O campo é obrigatório"),
    }),
    onSubmit: (values) => {
      addProfessionalInfo({ 
        councilType: values.councilType,
        councilUF: values.councilUF,
        councilNumber: values.councilNumber,

        normalValue: convertBRLToNumber(values.normalValue),
        primeValue: convertBRLToNumber(values.primeValue),
        receiptType: values.receiptType,
        onlineConsultation: values.onlineConsultation,

        isUsingClinicWhatsapp: values.isUsingClinicWhatsapp,
        whatsapp: values.whatsapp,
      });

      navigate(`/profissionais/${uuid !== undefined ? `editar/${uuid}` : 'adicionar'}/dados-bancarios`);
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
  
        const { data: UFs } = await LocationsService.listUFs();

        const parsedUFs: OptionTypeBase[] = UFs.map(uf => ({ value: String(uf.id), label: uf.uf }));

        setUFOptions(parsedUFs);
      } catch (error) {
        setUFOptions([]);

        toast.error("Não foi possível carregar as UFs");

        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  const { isGeneralTabEmpty, isSchedulesTabEmpty } = validation;

  if (isSchedulesTabEmpty) {
    navigate(`/profissionais/${uuid !== undefined ? `editar/${uuid}` : 'adicionar'}/horarios`);

    return <div />;
  }

  if (isGeneralTabEmpty) {
    navigate(`/profissionais/${uuid !== undefined ? `editar/${uuid}` : 'adicionar'}/geral`);

    return <div />;
  }

  if (isLoading) return <Loading />

  return (
    <FormContainer onSubmit={formik.handleSubmit}>
      <FormikEffect formik={formik} />

      <InputGroup layout="1fr 1fr 1.5fr">
        <Select 
          label="Tipo"
          name="councilType"
          options={councilTypeOptions}
          value={formik.values.councilType}
          onChange={option => formik.setFieldValue('councilType', option)}
          error={(formik.touched.councilType || undefined) && formik.errors.councilType}
        />

        <Select 
          label="UF de Registro"
          name="councilUF"
          options={UFOptions}
          value={formik.values.councilUF}
          onChange={option => formik.setFieldValue('councilUF', option)}
          error={(formik.touched.councilUF || undefined) && formik.errors.councilUF}
        />

        <Input 
          label="Número do Registro" 
          name="councilNumber" 
          value={formik.values.councilNumber}
          onChange={formik.handleChange}
          error={(formik.touched.councilNumber || undefined) && formik.errors.councilNumber}
        />
      </InputGroup>

      <InputGroup className="receipt">
        <Input
          label="Valor da consulta"
          mask={currencyMask}
          name="normalValue"
          value={convertBRLToNumber(formik.values.normalValue) ? formik.values.normalValue : undefined}
          onChange={formik.handleChange}
          error={(formik.touched.normalValue || undefined) && formik.errors.normalValue}
        />

        <Input
          label="Valor Prime"
          mask={currencyMask}
          name="primeValue"
          value={convertBRLToNumber(formik.values.primeValue) ? formik.values.primeValue : undefined}
          onChange={formik.handleChange}
          error={(formik.touched.primeValue || undefined) && formik.errors.primeValue}
        />
        
        <Select 
          label="Tipo de Recebimento"
          name="receiptType"
          options={receiptTypeOptions}
          value={formik.values.receiptType}
          onChange={option => formik.setFieldValue('receiptType', option)}
          error={(formik.touched.receiptType || undefined) && formik.errors.receiptType} 
        />

        <RadioGroup label="Consulta Online">
          <RadioInput 
            label="Sim" 
            value="sim"
            name="yes-online-consultation" 
            isChecked={formik.values.onlineConsultation}
            handleChange={() => formik.setFieldValue('onlineConsultation', true)}
          />

          <RadioInput 
            label="Não" 
            value="nao"
            name="no-online-consultation" 
            isChecked={!formik.values.onlineConsultation}
            handleChange={() => formik.setFieldValue('onlineConsultation', false)}
          />
        </RadioGroup>
      </InputGroup>

      <InputGroup layout="1.5fr 2fr">
        <RadioGroup label="Utilizar WhatsApp da Clínica">
          <RadioInput
            label="Sim"
            value="sim"
            name="yes-using-clinic-address"
            isChecked={formik.values.isUsingClinicWhatsapp}
            handleChange={() => formik.setFieldValue("isUsingClinicWhatsapp", true)}
          />

          <RadioInput
            label="Não"
            value="nao"
            name="no-using-clinic-address"
            isChecked={!formik.values.isUsingClinicWhatsapp}
            handleChange={() => formik.setFieldValue("isUsingClinicWhatsapp", false)}
          />
        </RadioGroup>

        <Input 
          label="WhatsApp" 
          name="whatsapp" 
          mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
          value={formik.values.whatsapp}
          onChange={formik.handleChange}
          error={(formik.touched.whatsapp || undefined) && formik.errors.whatsapp}
        />
      </InputGroup>

      <ButtonsContainer>
        <Button 
          variant="secondary"
          onClick={() => navigate(`/profissionais/${uuid !== undefined ? `editar/${uuid}` : 'adicionar'}/horarios`)}
        >
          Voltar
        </Button>

        <Button type="submit">
          Próximo
        </Button>
      </ButtonsContainer>
    </FormContainer>
  );
}