import styled from "styled-components";
import chroma from "chroma-js";

import { Container as Button } from "@components/Button/styles";
import { Container as TimeScheduling } from "@components/TimeScheduling/styles";
import { Container as Checkbox } from "@components/Checkbox/styles";

import { convertToRem } from "@utils/convertToRem";

export const ModalContent = styled.div`
  width: 100%;

  header {
    width: 100%;

    padding: ${convertToRem(20)};
    padding-bottom: ${convertToRem(40)};

    border-bottom: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

    .info {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      height: fit-content;

      padding-bottom: ${convertToRem(20)};

      border-bottom: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
    }

    .avatar {
      width: ${convertToRem(50)};
      height: ${convertToRem(50)};

      margin-right: ${convertToRem(15)};

      border-radius: 50%;
    }

    .separator {
      display: block;
      
      width: ${convertToRem(3)};
      height: ${convertToRem(40)};

      margin-left: ${convertToRem(20)};
      margin-right: ${convertToRem(10)};

      background-color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.1).css()};

      border-radius: ${convertToRem(20)};
    }

    h3 {
      font-weight: 500;
      font-size: ${convertToRem(15)};
    }

    h4 {
      margin-top: ${convertToRem(4)};
      
      color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.5).css()};

      font-size: ${convertToRem(10)};
      
      &.primary {
        color: ${({ theme }) => theme.colors.primary.highlight};
      }
    }

    .address {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;

      padding-top: ${convertToRem(20)};

      p {
        margin-top: ${convertToRem(10)};

        font-size: ${convertToRem(14)};
        line-height: 1.5;
      }

      ${Button} {
        min-height: ${convertToRem(40)};

        font-weight: 400;
      }
    }
  }

  footer {
    padding: ${convertToRem(20)};
    padding-bottom: ${convertToRem(40)};

    h2 {
      color: ${({ theme }) => theme.colors.neutral.black};

      font-weight: 400;
      font-size: ${convertToRem(30)};
    }

    p {
      margin-top: ${convertToRem(4)};

      font-weight: 400;
      font-size: ${convertToRem(12)};

      span {
        color: ${({ theme }) => theme.colors.primary.base};
      }
    }

    ${Checkbox} {
      margin-top: ${convertToRem(16)};
    }

    .dependent-data {
      margin: ${convertToRem(20)} 0;
    }

    .prime-promo {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin: ${convertToRem(15)} 0;
      padding: ${convertToRem(15)};

      background-color: #EBFBEE;

      border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.helpers.success};
      border-radius: ${convertToRem(10)};

      p {
        flex: 1;

        color: ${({ theme }) => theme.colors.neutral.black};

        font-size: ${convertToRem(14)};
        line-height: 1.5;
      }

      ${Button} {
        min-width: ${convertToRem(170)};
        height: ${convertToRem(60)};

        margin-left: ${convertToRem(50)};

        background-color: ${({ theme }) => theme.colors.helpers.success};
      }
    }

    .buttons-container {
      display: flex;
      
      margin-top: ${convertToRem(40)};
      padding: 0 ${convertToRem(30)};
      
      > button {
        flex: 1;

        margin: 0 ${convertToRem(15)};
      }
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  padding: ${convertToRem(50)} 0;

  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  max-width: ${convertToRem(420)};

  h3 {
    font-weight: 500;
    font-size: ${convertToRem(15)};
    line-height: 1;
  }

  .local-buttons {
    display: flex;

    margin: ${convertToRem(20)} 0 ${convertToRem(30)};

    ${Button} {
      min-width: ${convertToRem(120)};
      min-height: ${convertToRem(30)};

      font-size: ${convertToRem(10)};

      border-radius: ${convertToRem(3)};

      > svg {
        width: auto;
        height: ${convertToRem(16)};

        margin-right: ${convertToRem(8)};
      }

      &:not(:first-child) {
        margin-left: ${convertToRem(10)};
      }
    }
  }

  ${TimeScheduling} {
    max-height: ${convertToRem(260)};
    
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: ${convertToRem(4)};
    }

    &::-webkit-scrollbar-track {
      background-color: ${({ theme }) => theme.colors.neutral.borderLight};
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => theme.colors.neutral.borderDark};
      border-radius: ${convertToRem(8)};
    }
  }

  > ${Button} {
    width: 100%;
    max-width: ${convertToRem(340)};

    font-weight: 400;

    margin-top: ${convertToRem(40)};

    background-color: ${chroma('#0FC237').alpha(0.1).css()};
    color: #0FC237;
  }
`;