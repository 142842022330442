import styled from "styled-components";

import { Container as Switch, SwitchLabel } from '@components/Switch/styles';
import { Container as Button } from '@components/Button/styles';

import primeList from '@assets/images/prime-list-icon.svg';

import { convertToRem } from "@utils/convertToRem";


export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(40)};
`;

export const CardContainer = styled.div<{ image: string; }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  max-width: ${convertToRem(770)};

  margin: ${convertToRem(40)} 0 ${convertToRem(100)};
  padding: ${convertToRem(50)};

  background-color: ${({ theme }) => theme.colors.neutral.borderLight};
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top right;

  border-radius: ${convertToRem(10)};

  .texts {
    max-width: ${convertToRem(380)};
  }

  h2 {
    color: ${({ theme }) => theme.colors.neutral.textDark};

    font-weight: 400;
    font-size: ${convertToRem(35)};
    line-height: 1.2;
  }

  h3 {
    margin-top: ${convertToRem(10)};

    color: ${({ theme }) => theme.colors.neutral.text};

    font-weight: 400;
    font-size: ${convertToRem(15)};
    line-height: 1.4;
  }

  ul {
    margin-top: ${convertToRem(30)};
  }

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    color: ${({ theme }) => theme.colors.neutral.text};

    font-weight: 400;
    font-size: ${convertToRem(12)};
    line-height: 1.4;

    &:not(:first-child) {
      margin-top: ${convertToRem(15)};
    }

    &::before {
      content: '';

      display: block;
      
      width: ${convertToRem(15)};
      height: ${convertToRem(15)};

      margin-right: ${convertToRem(5)};

      background-image: url(${primeList});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }

  ${Button} {
    height: ${convertToRem(60)};
    margin-top: ${convertToRem(30)};
  }
`;

export const SwitchesContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  column-gap: ${convertToRem(30)};

  width: 100%;

  margin-top: ${convertToRem(40)};

  ${Switch} {
    flex: 1;

    flex-direction: row-reverse;

    padding: ${convertToRem(30)} ${convertToRem(36)};

    background-color: ${({ theme }) => theme.colors.neutral.white};

    border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
    border-radius: ${convertToRem(5)};

    ${SwitchLabel} {
      margin-left: 0;
      margin-right: auto;
    }
  }
`;
