import styled from "styled-components";

import { Container as Callout } from "@components/Callout/styles";
import { Container as Button } from "@components/Button/styles";

import { convertToRem } from "@utils/convertToRem";

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  padding-top: ${convertToRem(40)};
`;

export const PrimePromo = styled.div`
  display: grid;
  grid-template-columns: 8fr 4fr;
  gap: ${convertToRem(30)};

  margin-top: ${convertToRem(50)};

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  
    padding: ${convertToRem(20)};
  
    background-color: ${({ theme }) => theme.colors.primary.lightest};
  
    border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.primary.highlight};
    border-radius: ${convertToRem(5)};
  }

  p {
    flex: 1;

    font-size: ${convertToRem(14)};
    line-height: 1.5;
  }

  ${Button} {
    margin-left: ${convertToRem(100)};
    
    height: ${convertToRem(60)};
  }
`;

export const Main = styled.div<{ isPrime: boolean; }>`
  display: grid;
  grid-template-columns: 8fr 4fr;
  gap: ${convertToRem(30)};

  margin-top: ${({ isPrime }) => !isPrime && convertToRem(30)};
`;

export const Form = styled.form`
  min-height: 100vh;

  padding: ${convertToRem(50)} 0 ${convertToRem(100)};

  ${Callout} {
    margin-top: ${convertToRem(120)};

    strong {
      margin-left: ${convertToRem(4)};
      color: ${({ theme }) => theme.colors.primary.highlight};
    }
  }

  ${Button} {
    margin-top: ${convertToRem(80)};
    margin-left: auto;
  }
`;