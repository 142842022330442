import { faker } from '@faker-js/faker';
import { subYears } from 'date-fns';

import { delay } from '@utils/delay';

export interface Dependent {
  id: string;
  name: string;
  birthDate: Date;
  cpf: string;
  rg: string;
};

faker.setLocale('pt_BR');
class DependentsService {
  private dependents: Dependent[];

  constructor() {
    this.dependents = [];

    for (let i = 0; i < 11; i++) {
      const { name, datatype, random } = faker;

      const randomGender = datatype.number({ min: 0, max: 1, precision: 1 });

      const dependentName = `${name.firstName(randomGender)} ${name.lastName(randomGender)}`;
      const dependentBirthDate =  datatype.datetime({ 
        min: subYears(new Date(), 17).getTime(), 
        max: new Date().getTime()
      });

      this.dependents.push({
        id: datatype.uuid(),
        name: dependentName,
        birthDate: dependentBirthDate,
        cpf: random.arrayElement(['44650487005', '15488191070', '49910179040', '35056334022', '93117221034', '34227158001']),
        rg: random.arrayElement(['258534862', '452294368', '118737958', '167625676', '368909517', '490201015']),
      });
    }
  }

  async listDependents(): Promise<Dependent[]> {
    await delay();

    return new Promise((resolve) => {
      resolve(this.dependents);
    });
  }

  async listDependentById(id: string): Promise<Dependent | null> {
    await delay(500);

    return new Promise((resolve) => {
      const dependentById = this.dependents.find(dependent => dependent.id === id) || null;

      resolve(dependentById);
    });
  }
}

export default new DependentsService();