import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { delay } from "@utils/delay";

class HttpClient {
  private httpClient: AxiosInstance;

  constructor() {
    this.httpClient = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
    });
  }

  setToken(token?: string | null) {
    if (token) {
      this.httpClient.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    } else {
      delete this.httpClient.defaults.headers.common['Authorization'];
    }
  }

  getToken() {
    const token = this.httpClient.defaults.headers.common['Authorization'];
    
    return (typeof token === 'string') ? token : '';
  }

  async get<T>(path: string, config?: AxiosRequestConfig) {
    return this.httpClient.get<T>(path, config);
  }

  async post<T>(path: string, body?: any, config?: AxiosRequestConfig) {
    return this.httpClient.post<T>(path, body, config);
  }

  async put<T>(path: string, body?: any, config?: AxiosRequestConfig) {
    return this.httpClient.put<T>(path, body, config);
  }

  async delete<T>(path: string, config?: AxiosRequestConfig) {
    return this.httpClient.delete<T>(path, config);
  }

  async externalGet<T>(url: string): Promise<T> {
    await delay(500);
    
    const response = await fetch(url);

    return response.json();
  }
}

export default new HttpClient();