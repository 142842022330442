import { faker } from '@faker-js/faker';
import { subDays, subYears } from 'date-fns';

import { delay } from '@utils/delay';

type PatientPlans = 'Gratuito' | 'Prime PF' | 'Prime Familiar' | 'Prime Empresas' | 'Prime Empresas Familiar';
export interface Patient {
  id: string;
  avatar: string;
  name: string;
  plan: PatientPlans;
  company: string | null;
  email: string;
  cpf: string;
  cardNumber: string;
  rg: string;
  gender: 'male' | 'female';
  birthDate: Date;
  whatsapp: string;
  tel: string;
  address: {
    zipCode: string;
    street: string;
    number: string;
    complement: string | null;
    neighborhood: string;
    city: string;
    state: string;
  }
  lastAccess: Date;
};

faker.setLocale('pt_BR');
class PatientsService {
  private patient: Patient[];

  constructor() {
    this.patient = [];

    for (let i = 0; i < 20; i++) {
      const { address, datatype, name, phone, random, internet } = faker;

      const randomGender = datatype.number({ min: 0, max: 1, precision: 1 });
      const [addressNumber, addressStreetName, addressStreetType] = address.streetAddress().split(' ');

      this.patient.push({
        id: datatype.uuid(),
        name: `${name.firstName(randomGender)} ${name.lastName(randomGender)}`,
        avatar: internet.avatar(),
        plan: random.arrayElement(['Gratuito', 'Prime PF', 'Prime Familiar', 'Prime Empresas', 'Prime Empresas Familiar']),
        company: random.arrayElement(['EMPRESA TESTE LTDA', null]),
        email: `${name.firstName().toLowerCase()}@mail.com`,
        cpf: phone.phoneNumber('###########'),
        cardNumber: phone.phoneNumber('###.###.###.###'),
        rg: phone.phoneNumber('##########'),
        gender: randomGender === 0 ? 'male' : 'female',
        birthDate: datatype.datetime({ max: subYears(new Date(), 10).getTime() }),
        tel: phone.phoneNumber('(##) ####-####'),
        whatsapp: phone.phoneNumber('(##) 9 ####-####'),
        address: {
          street: `${addressStreetType} ${addressStreetName}`,
          number: addressNumber,
          complement: datatype.boolean() ? address.secondaryAddress() : null,
          zipCode: address.zipCode(),
          neighborhood: address.cityName(),
          city: address.city(2),
          state: address.stateAbbr(),
        },
        lastAccess: datatype.datetime({ min: subDays(new Date(), 30).getTime(), max: new Date().getTime() }),
      });
    }
  }

  async listPatients(): Promise<Patient[]> {
    await delay(500);

    return new Promise((resolve) => {
      resolve(this.patient);
    });
  }

  async listLastAccessedPatients(): Promise<Patient[]> {
    await delay(500);

    return new Promise((resolve) => {
      const orderedClinics = [...this.patient].sort((a, b) => b.lastAccess.getTime() - a.lastAccess.getTime());

      resolve(orderedClinics);
    });
  }
}

export default new PatientsService();