import { Navigate, Route, Routes } from "react-router-dom";

// Pages
import { General } from "./General";
import { PaymentMethods } from "./PaymentMethods";
import { ConsultationReasons } from "./ConsultationReasons";
import { Employees } from "./Employees";

export function Settings() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="gerais" />} />
      <Route path="gerais" element={<General />} />
      <Route path="formas-de-pagamento" element={<PaymentMethods />} />
      <Route path="motivos-de-consulta" element={<ConsultationReasons />} />
      <Route path="colaboradores/*" element={<Employees />} />
    </Routes>
  );
}