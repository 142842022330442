import { ChangeEvent, useRef, useState } from 'react';
import { FiPaperclip } from 'react-icons/fi';
import { RiCloseLine } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { Callout } from '@components/Callout';
import { Button } from '@components/Button';

import { Store } from "@assets/images";

import { Container, FileInput, ImageContainer, PreviewContainer, PreviewImage, PreviewText } from "./styles";

interface ImageProps {
  preview: string;
  raw: File;
}

export function EditLogoTab() {
  const fileRef = useRef<HTMLInputElement>(null);

  const [image, setImage] = useState<ImageProps | null>(null);

  function handleFileClick() {
    if (!fileRef.current) return;

    fileRef.current.click();
  }

  function handleFileChange(event: ChangeEvent<HTMLInputElement>) {
    if (!event.target.files) return;

    if (!["image/jpg", "image/jpeg", "image/png"].includes(event.target.files[0].type)) {
      toast.info("Tipo inválido");
      return;
    }
    
    const imageSizeMB = event.target.files[0].size / Math.pow(1024, 2);
    if (imageSizeMB > 1.0) {
      toast.info("Tamanho excedido. Selecione uma imagem com no máximo 1MB");
      return;
    }

    const img = {
      preview: URL.createObjectURL(event.target.files[0]),
      raw: event.target.files[0],
    }

    setImage(img);
  }

  function handleSubmit() {
    alert(JSON.stringify({ image }));
  }

  return (
    <Container>
      <PreviewContainer>
        <PreviewImage>
          { 
            image 
            ? (
              <ImageContainer onClick={() => setImage(null)}>
                <RiCloseLine />
                <img src={image.preview} alt="Preview da Logo" />
              </ImageContainer>
            ) : <Store /> 
          }
            
        </PreviewImage>

        <PreviewText>
          <h3>Nossa que lindo!</h3>

          <p>Usar um Logotipo dá mais profissionalismo ao seu perfil, rendendo mais pacientes!</p>

          <FileInput onClick={handleFileClick}>
            <FiPaperclip />

            Anexar imagem

            <input 
              type="file" 
              ref={fileRef}
              onChange={handleFileChange}
              style={{ display: 'none' }}
            />
          </FileInput>
        </PreviewText>
      </PreviewContainer>

      <Callout>
        Arquivos permitidos: PNG, JPG, JPEG <br />
        Tamanho máximo: 1.0MB
      </Callout>

      <Button
        onClick={handleSubmit}
        disabled={image === null}
      >
        Alterar
      </Button>
    </Container>
  );
}