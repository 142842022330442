import HttpClient from './utils/HttpClient';

import { StatusColors } from '@/types/api';

interface GeneratePaymentsReportResponse {
  codigo: number;
  dados: string;
}

interface ListPaymentsHistoryResponse {
  codigo: number;
  pagamentos: Array<{
    meta: string;
    valor_lead: number;
    quantidade: number;
    total: number;
    status: string;
    status_cor: StatusColors;
  }>;
}

class PaymentsService {
  async generatePaymentsReport() {
    return HttpClient.get<GeneratePaymentsReportResponse>('/admin/afiliados/gerar-pagamento');
  }

  async listPayments(showLastFour?: boolean) {
    return HttpClient.get<ListPaymentsHistoryResponse>(`/afiliado/pagamentos/${showLastFour ? '4' : 'todos'}`);
  }
}

export default new PaymentsService();