import styled from "styled-components";

import check from "@assets/images/check.svg";

import { convertToRem } from "@utils/convertToRem";

export const Container = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  input {
    position: relative;

    width: ${convertToRem(18)};
    height: ${convertToRem(18)};
      
    appearance: none;

    cursor: pointer;

    &::before {
      content: '';

      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;

      display: block;

      background: transparent;

      border: ${({ theme }) => `${convertToRem(1)} solid ${theme.colors.neutral.borderDark}`};
      border-radius: ${convertToRem(5)};

      z-index: 1;
    }

    &:checked {
      &::before {
        background: ${({ theme }) => theme.colors.neutral.borderDark}; 
        border: 0;
      }

      &:after {
        content: '';

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);

        width: ${convertToRem(8)};
        height: ${convertToRem(8)};

        color: ${({ theme }) => theme.colors.neutral.white};

        background: url(${check}) center center no-repeat;
        background-size: 100%;

        z-index: 2;
      }
    }
  }

  strong {
    flex: 1;
    
    display: block;
    color: ${({ theme }) => theme.colors.neutral.textDark};
  
    font-weight: 500;
    font-size: ${convertToRem(13)};
    line-height: 1;

    margin-left: ${convertToRem(8)};
  }
`;