import styled from "styled-components";
import chroma from "chroma-js";

import { PopoverContent } from "@components/Popover";

import { convertToRem } from "@utils/convertToRem";

export const Container = styled.header`
  width: 100%;
  height: ${convertToRem(80)};

  border-bottom: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const SearchContainer = styled.div`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  padding-right: ${convertToRem(30)};
`;

export const SearchInput = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  padding: ${convertToRem(10)} 0;

  > svg {
    width: auto;
    height: ${convertToRem(20)};
    
    margin-right: ${convertToRem(10)};
    
    color: ${({ theme }) => theme.colors.neutral.text};
  }

  input {
    flex: 1;

    width: 100%;
    height: 100%;

    background-color: transparent;
    color: ${({ theme }) => theme.colors.neutral.text};

    font-size: ${convertToRem(15)};
    line-height: 1;

    letter-spacing: ${convertToRem(0.3)};

    border: 0;

    &::placeholder {
      color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.7).css()};
    }
  }
`;

export const SelectedUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  width: 100%;
  height: 100%;

  h1 {
    color: ${({ theme }) => theme.colors.primary.dark};

    font-weight: 400;
    font-size: ${convertToRem(24)};
    line-height: 1;
  }

  p {
    color: ${({ theme }) => theme.colors.neutral.text};

    font-size: ${convertToRem(12)};
    line-height: 1;
  }

  @media (max-width: 768px) {
    h1 {
      font-size: ${convertToRem(18)};
    }

    p {
      font-size: ${convertToRem(10)};
    }
  }
`;

export const PrimeFlag = styled.span`
  margin: 0 ${convertToRem(16)};
  padding: ${convertToRem(6)} ${convertToRem(12)};

  background-color: ${({ theme }) => theme.colors.primary.highlight};
  color: ${({ theme }) => theme.colors.neutral.white};

  font-weight: 500;
  font-size: ${convertToRem(14)};

  border-radius: ${convertToRem(3)};

  @media (max-width: 768px) {
    padding: ${convertToRem(4)} ${convertToRem(8)};

    font-size: ${convertToRem(12)};
  }
`;

export const NotificationContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${convertToRem(80)};
  height: 100%;

  border-left: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

  > button {
    position: relative;
    
    display: flex;
    align-items: center;
    justify-content: center;
    
    padding: ${convertToRem(5)};
    
    background: none;
    border: none;
    
    svg {
      width: ${convertToRem(24)};
      height: auto;
  
      color: ${({ theme }) => chroma(theme.colors.neutral.borderDark).darken(0.8).css()};
    }

    &.has-notification {
      &::after {
        content: '';

        position: absolute;
        top: ${convertToRem(5)};
        right: ${convertToRem(5)};
        
        width: ${convertToRem(6)};
        height: ${convertToRem(6)};

        background: ${({ theme }) => theme.colors.primary.highlight};

        border: solid ${convertToRem(1.5)} ${({ theme }) => theme.colors.neutral.white};
        border-radius: 50%;
      }
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const NotificationContent = styled(PopoverContent)`
  width: ${convertToRem(300)};
  max-height: ${convertToRem(400)};

  overflow-y: auto;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    button {
      font-weight: 500;

      background: none;
      color: ${({ theme }) => theme.colors.primary.highlight};

      border: 0;

      &:disabled {
        opacity: 0.5;
        
        cursor: not-allowed;
      }
    }
  }

  ul {
    margin-top: ${convertToRem(24)};

    li {
      .wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;

        margin: ${convertToRem(12)} 0;
        padding: ${convertToRem(16)} ${convertToRem(8)};

        border-radius: ${convertToRem(6)};

        cursor: pointer;
      }

      &.unread .wrapper {
        background-color: ${({ theme }) => theme.colors.neutral.borderLight};
      }

      &.none {
        .wrapper {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &:not(:first-child) {
        border-top: solid 1px ${({ theme }) => theme.colors.neutral.border};
      }

      strong {
        font-size: ${convertToRem(14)};
      }

      span {
        margin-top: ${convertToRem(4)};

        font-size: ${convertToRem(13)};
      }
    }
  }
`;

export const AccountDropdown = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  height: 100%;
  
  border-left: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
`;

export const Account = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-left: ${convertToRem(24)};
  padding: ${convertToRem(10)} 0;

  background-color: transparent;

  border: 0;

  > svg {
    width: auto;
    height: ${convertToRem(14)};

    margin-top: ${convertToRem(-2)};
    margin-left: ${convertToRem(4)};

    stroke-width: 1.2;
    color: ${({ theme }) => theme.colors.neutral.text};
  }
`;

export const Photo = styled.div`
  width: ${convertToRem(34)};
  height: ${convertToRem(34)};

  outline: solid ${convertToRem(1.5)} ${({ theme }) => theme.colors.neutral.border};
  border-radius: 50%;

  overflow: hidden;

  img {
    width: 100%;
    height: 100%;

    object-fit: cover;

    overflow: hidden;
  }
`;

export const Name = styled.span`
  margin-left: ${convertToRem(10)}; 

  color: ${({ theme }) => theme.colors.neutral.text};

  font-size: ${convertToRem(14)};
`;

export const AccountMenu = styled(PopoverContent)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: ${convertToRem(180)};
  
  padding: ${convertToRem(20)};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.borderDark};

  li {
    color: ${({ theme }) => theme.colors.neutral.text};

    font-weight: 500;
    font-size: ${convertToRem(13)};
    line-height: 1;

    list-style-type: none;

    button, a {      
      font-weight: 500;
      font-size: ${convertToRem(13)};
      line-height: 1;
      
      background-color: transparent;
      color: inherit;

      border: 0;

      transition: color ease 0.4s;

      &:hover {
        color: ${({ theme }) => theme.colors.neutral.textDark};
      }
    }
    
    &.sign-out {
      width: 100%;

      margin-top: ${convertToRem(16)} !important;
      padding-top: ${convertToRem(16)};

      border-top: solid 1px ${({ theme }) => theme.colors.neutral.borderDark};

      button {
        color: ${({ theme }) => theme.colors.primary.highlight};
  
        &:hover {
          color: ${({ theme }) => theme.colors.primary.base};
        }
      }
    }

    &:not(:first-of-type) {
      margin-top: ${convertToRem(16)};
    }
  }

  &.active {
    transform: translateY(0);
    pointer-events: initial;
    opacity: 1;
  }
`;

export const SelectUserProfileContainer = styled.ul`
  width: max-content;
  min-width: 100%;
  
  margin-bottom: ${convertToRem(16)};
  padding-bottom: ${convertToRem(16)};
  
  border-bottom: solid 1px ${({ theme }) => theme.colors.neutral.borderDark};
`;

export const Title = styled.h1`
  font-weight: 400;
  font-size: ${convertToRem(30)};
  line-height: ${convertToRem(33)};

  color: ${({ theme }) => theme.colors.neutral.black};
`;

export const Description = styled.p`
  font-size: ${convertToRem(12)};
  line-height: ${convertToRem(13)};

  color: ${({ theme }) => theme.colors.neutral.text};

  margin-top: ${convertToRem(10)};
`;