import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Modal } from '@components/Modal';
import { Loading } from '@components/Loading';
import { Title } from '@components/Header';
import { Select } from '@components/Select';
import { Input, InputGroup } from '@components/Input';
import { Button } from '@components/Button';
import ResultCard, { ItemDivider, ItemValue, ItemContainer, ItemTitle, ActionsContainer, ActionButton } from '@components/ResultCard';
import { Tooltip } from '@components/Tooltip';

import ExamsService from '@services/ExamsService';

import { CancelSquare, ChevronDown, FileExcel, FileExport, FileImport, Memo, } from '@assets/images';

import { convertToRem } from '@utils/convertToRem';
import { formatToBRL } from '@utils/formatToBRL';
import { currencyMask } from '@utils/currencyMask';
import { convertBRLToNumber } from '@utils/convertBRLToNumber';

import { OptionTypeBase } from '@components/Select/Select';

import { Content, ContentGrid, ContentWrapper, SidebarCard, FilesContainer, Footer, Page, PageButton, Pagination, ResultsContainer, FormContainer, ModalContent } from './styles';

interface InitialValues {
  name: OptionTypeBase | null;
  normalValue: string
  primeValue: string;
};

interface Exam {
  name: string;
  normalValue: number;
  primeValue: number;
}

const itemsPerPage = 10;

export function ExamsList() {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(true);
  const [examsOptions, setExamsOptions] = useState<OptionTypeBase[]>([]);

  const [exams, setExams] = useState<Exam[]>([
    { name: "Eletrocardiograma (ECG)", normalValue: 300, primeValue: 250 },
    { name: "Raio X Clavicula", normalValue: 140, primeValue: 100 },
  ]);
  const [currentPage, setCurrentPage] = useState(1);
  const lastPage = Math.ceil(exams.length / itemsPerPage) || 1;

  const [selectedExam, setSelectedExam] = useState<Exam | null>(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  
  useEffect(() => {
    (async () => {
      try {
        const examsTypes = await ExamsService.listAllExamsType();

        const parsedExams: OptionTypeBase[] = examsTypes.map(exam => ({
          label: exam.descricao,
          value: exam.id,
        }));

        setExamsOptions(parsedExams);
      } catch (error) {
        setExamsOptions([]);

        toast.error("Não foi possível carregar os exames");

        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const formik = useFormik<InitialValues>({
    initialValues: {
      name: null,
      normalValue: "",
      primeValue: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.object({ value: Yup.string(), label: Yup.string() })
        .typeError("Selecione um exame")
        .required("O campo é obrigatório"),
      normalValue: Yup.string()
        .required("O campo é obrigatório"),
      primeValue: Yup.string()
      .required("O campo é obrigatório"),
    }),
    onSubmit: (values, { resetForm }) => {
      const parsedValues = {
        name: values.name?.label || "",
        normalValue: convertBRLToNumber(values.normalValue),
        primeValue: convertBRLToNumber(values.primeValue),
      };

      setExams(prevState => [...prevState, parsedValues]);
      resetForm();

      toast.success('Novo exame adicionado com sucesso!');
    }
  });

  const modalFormik = useFormik<InitialValues>({
    enableReinitialize: true,
    initialValues: {
      name: examsOptions.find(exam => exam.label === selectedExam?.name) || null,
      normalValue: formatToBRL(selectedExam?.normalValue || 0),
      primeValue: formatToBRL(selectedExam?.primeValue || 0),
    },
    validationSchema: Yup.object().shape({
      name: Yup.object({ value: Yup.string(), label: Yup.string() })
        .typeError("Selecione um exame")
        .required("O campo é obrigatório"),
      normalValue: Yup.string()
        .required("O campo é obrigatório"),
      primeValue: Yup.string()
      .required("O campo é obrigatório"),
    }),
    onSubmit: (values, { resetForm }) => {
      const parsedValues = {
        name: values.name?.label || "",
        normalValue: convertBRLToNumber(values.normalValue),
        primeValue: convertBRLToNumber(values.primeValue),
      };

      const modifiedExam = exams.map(exam => exam.name === selectedExam?.name  ? parsedValues : exam);

      setExams(modifiedExam);
      resetForm();

      setIsEditModalVisible(false);
      setSelectedExam(null);
      toast.success('Exame editado com sucesso!');
    }
  });

  function handleDeleteExam() {
    setIsDeleteModalVisible(false);
    
    const filteredExams = exams.filter(exam => exam.name !== selectedExam?.name);
    setExams(filteredExams);

    toast.success('Exame excluído com sucesso!');
    setSelectedExam(null);
  }

  if (isLoading) return <Loading />;

  return (
    <Content>
      <Modal
        visible={selectedExam !== null && isEditModalVisible}
        title="Editar exame"
        onRequestClose={() => setIsEditModalVisible(false)}
        style={{ maxWidth: convertToRem(500) }}
      >
        <ModalContent onSubmit={modalFormik.handleSubmit}>
          <Select
            label="Exame"
            placeholder="Escolha o exame"
            options={examsOptions}
            value={modalFormik.values.name}
            onChange={(option) => modalFormik.setFieldValue("name", option)}
            error={(modalFormik.touched.name || undefined) && modalFormik.errors.name}
            isClearable={false}
          />

          <InputGroup>
            <Input 
              label="Valor Normal" 
              name="normalValue" 
              mask={currencyMask}
              value={modalFormik.values.normalValue}
              onChange={modalFormik.handleChange}
              error={(modalFormik.touched.normalValue || undefined) && modalFormik.errors.normalValue}
            />
            
            <Input 
              label="Valor Prime" 
              name="primeValue" 
              mask={currencyMask}
              value={modalFormik.values.primeValue}
              onChange={modalFormik.handleChange}
              error={(modalFormik.touched.primeValue || undefined) && modalFormik.errors.primeValue}
            />
          </InputGroup>

          <Button type="submit">Alterar</Button>
        </ModalContent>
      </Modal>

      <Modal 
        visible={selectedExam !== null && isDeleteModalVisible}
        title="Atenção"
        onRequestClose={() => setIsDeleteModalVisible(false)} 
      >
        <p className='text'>
          Deseja realmente excluir o exame <strong>{selectedExam?.name}</strong>? 
        </p>

        <div className='buttons'>
          <button onClick={() => setIsDeleteModalVisible(false)}>
            Não
          </button>

          <button onClick={handleDeleteExam} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <ContentWrapper className="content">
        <Title>Exames</Title>

        <ContentGrid>
          <FormContainer onSubmit={formik.handleSubmit}>
            <Select
              label="Exame"
              placeholder="Escolha o exame"
              options={examsOptions}
              value={formik.values.name}
              onChange={(option) => formik.setFieldValue("name", option)}
              error={(formik.touched.name || undefined) && formik.errors.name}
            />

            <Input 
              label="Valor Normal" 
              name="normalValue" 
              mask={currencyMask}
              value={formik.values.normalValue}
              onChange={formik.handleChange}
              error={(formik.touched.normalValue || undefined) && formik.errors.normalValue}
            />
            
            <Input 
              label="Valor Prime" 
              name="primeValue" 
              mask={currencyMask}
              value={formik.values.primeValue}
              onChange={formik.handleChange}
              error={(formik.touched.primeValue || undefined) && formik.errors.primeValue}
            />

            <Button type="submit">Salvar</Button>
          </FormContainer>

          <Button variant="secondary">
            <FileExcel />
            Baixar modelo excel
          </Button>
        </ContentGrid>

        <ContentGrid>
          <ResultsContainer>
            {exams.length > 0
              ? exams
                .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                .map((exam, i) => (
                  <ResultCard 
                    key={exam.name + i} 
                    onClick={() => setSelectedExam(exam)}
                  >
                    <ItemContainer>
                      <div>
                        <ItemValue>{exam.name}</ItemValue>
                      </div>
                    </ItemContainer>

                    <ItemDivider />

                    <ItemContainer>
                      <div>
                        <ItemValue>{formatToBRL(exam.normalValue)}</ItemValue>
                        <ItemTitle>Valor Normal</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ItemDivider />

                    <ItemContainer>
                      <div>
                        <ItemValue>{formatToBRL(exam.primeValue)}</ItemValue>
                        <ItemTitle>Valor Prime</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ActionsContainer>
                      <Tooltip content="Editar">
                        <ActionButton 
                          color={theme.colors.helpers.success}
                          onClick={() => setIsEditModalVisible(true)}
                        >
                          <Memo />
                        </ActionButton>
                      </Tooltip>

                      <Tooltip content="Excluir">
                        <ActionButton 
                          color={theme.colors.helpers.danger}
                          onClick={() => setIsDeleteModalVisible(true)}
                        >
                          <CancelSquare />
                        </ActionButton>
                      </Tooltip>
                    </ActionsContainer>
                  </ResultCard>
                ))
              : 'Não há exames cadastrados.'
            }
          </ResultsContainer>

          <FilesContainer>
            <SidebarCard>
              <h3>Importar Exames</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit et moe.
              </p>

              <Button variant="secondary">
                <FileImport />
                Escolher arquivo
              </Button>
            </SidebarCard>

            <SidebarCard>
              <h3>Exportar Exames</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit et moe.
              </p>

              <Button variant="secondary">
                <FileExport />
                Escolher arquivo
              </Button>
            </SidebarCard>
          </FilesContainer>
        </ContentGrid>
      </ContentWrapper>

      <Footer>
        <div className="content">
          <Pagination>
            <PageButton
              className="prev"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              <ChevronDown />
            </PageButton>

            <Page>{String(currentPage).padStart(2, "0")}</Page>

            <PageButton
              className="next"
              onClick={goToNextPage}
              disabled={currentPage === lastPage}
            >
              <ChevronDown />
            </PageButton>
          </Pagination>
        </div>
      </Footer>
    </Content>
  );
}