import styled from "styled-components";
import chroma from "chroma-js";

import { Button } from "@components/Button";
import { Container as Select } from "@components/Select/styles";
import { Time } from "@components/TimeInput/styles";

import { convertToRem } from "@utils/convertToRem";

export const ActionButtonsInEditing = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;

  overflow: hidden;
`;

export const SaveButton = styled(Button)`
  min-width: ${convertToRem(135)};

  background-color: ${({ theme }) => theme.colors.helpers.success};

  border-radius: 0;

  &:hover {
    background-color: ${({ theme }) =>
      chroma(theme.colors.helpers.success).darken(0.5).css()};
  }
`;

export const CancelButton = styled(SaveButton)`
  background-color: ${({ theme }) => theme.colors.helpers.danger};

  &:hover {
    background-color: ${({ theme }) =>
      chroma(theme.colors.helpers.danger).darken(0.5).css()};
  }
`;

export const Container = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: ${convertToRem(80)};

  padding: ${convertToRem(20)} ${convertToRem(28)};

  background: ${({ theme }) => theme.colors.neutral.white};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.borderDark};

  ${Time} {
    margin: 0 ${convertToRem(15)};
  }

  &:first-of-type {
    border-top-left-radius: ${convertToRem(5)};
    border-top-right-radius: ${convertToRem(5)};

    ${ActionButtonsInEditing} {
      border-top-right-radius: ${convertToRem(5)};
    }
  }

  &:last-of-type {
    border-bottom-left-radius: ${convertToRem(5)};
    border-bottom-right-radius: ${convertToRem(5)};

    ${ActionButtonsInEditing} {
      border-bottom-right-radius: ${convertToRem(5)};
    }
  }

  &:not(:first-of-type) {
    border-top: 0;
  }

  ${Select} {
    width: ${convertToRem(180)};

    z-index: 99;
  }
`;

export const Day = styled.span`
  width: ${convertToRem(220)};

  color: ${({ theme }) => theme.colors.neutral.textDark};

  font-weight: 500;
  font-size: ${convertToRem(13)};
  line-height: 1;

  cursor: default;
`;

export const TimeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.5).css()};

  font-size: ${convertToRem(15)};
  line-height: 1;
  cursor: default;
`;

export const Buttons = styled.div`
  margin-left: auto;
`;

export const ScheduleAction = styled.button`
  display: inline-block;

  width: ${convertToRem(18)};
  height: ${convertToRem(18)};

  background: none;
  color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.5).css()};

  border: 0;

  &:not(:first-child) {
    margin-left: ${convertToRem(8)};
  }

  svg {
    width: auto;
    height: 100%;

    stroke-width: 1.2;
  }

  &:first-child svg {
    stroke-width: 1.5;
  }
`;
