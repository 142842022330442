import { Check } from "@/assets/images";

import { Wrapper } from "./styles";

export function SuccessfulLeadRegistration() {
  return (
    <Wrapper>
      <div className="content">
        <span>
          <Check />
        </span>

        <h1>Cadastro concluído!</h1>
        <p>Em breve entraremos em contato para confirmar seus dados.</p>

        <a 
          href="https://agendaconsulta.com/prime/" 
          target="_blank" 
          rel="noreferrer"
        >
          Seja Prime
        </a>
      </div>
    </Wrapper>
  );
}