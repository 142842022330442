import styled from "styled-components";
import chroma from "chroma-js";

import { convertToRem } from "@utils/convertToRem";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const Label = styled.label`
  color: ${({ theme }) => theme.colors.neutral.textDark};
  
  font-weight: 500;
  font-size: ${convertToRem(13)};
  line-height: 1;

  margin-bottom: ${convertToRem(8)};
`;

export const Time = styled.input<{ showSeconds: boolean; error?: boolean; }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${({ showSeconds }) => showSeconds ? convertToRem(82) : convertToRem(60)};
  height: ${convertToRem(30)};

  background-color: ${({ theme }) =>
    chroma(theme.colors.neutral.border).alpha(0.5).css()};
  color: ${({ theme }) => theme.colors.neutral.textDark};

  font-weight: 500;
  font-size: ${convertToRem(13)};
  line-height: 1.8;

  border: ${({ theme, error }) => error ? `solid ${convertToRem(1)} ${theme.colors.helpers.danger}` : '0'};
  border-radius: ${convertToRem(5)};

  text-align: center;
`;

export const Error = styled.span`
  margin-top: ${convertToRem(4)};

  color: ${({ theme }) => theme.colors.helpers.danger};

  font-size: ${convertToRem(10)};
`;