import { convertToRem } from "@utils/convertToRem";
import chroma from "chroma-js";
import styled, { css } from "styled-components";

export const Container = styled.div`
`;

export const RangeInput = styled.input`
  ${({ theme }) => css`
  --range: calc(var(--range-max) - var(--range-min));
  --ratio: calc((var(--range-val) - var(--range-min)) / var(--range));
  --sx: calc(0.5 * ${convertToRem(10)} + var(--ratio) * (100% - ${convertToRem(10)}));

  --range-bg: ${chroma(theme.colors.primary.highlight).alpha(0.3).css()};
  --dynamic-bg: 
      linear-gradient(var(--range-bg), var(--range-bg)) 
      0 / 
      var(--sx) 100% no-repeat ${theme.colors.neutral.border}
    ;
  
    appearance: none;

    width: 100%;
    height: ${convertToRem(4)};

    background: transparent;
    

    /* Chrome styles */
    &::-webkit-slider-runnable-track {
      height: ${convertToRem(4)};

      background: var(--dynamic-bg);

      border-radius: 0;
    }

    &::-webkit-slider-thumb {
      appearance: none;

      margin-top: ${convertToRem(-3)};
      
      width: ${convertToRem(10)};
      height: ${convertToRem(10)};
      
      border-radius: 50%;
      
      background: ${theme.colors.primary.highlight};
    }


    /* Mozilla Styles */
    &::-moz-range-track {
      width: 100%;
      height: ${convertToRem(4)};
      
      background: var(--dynamic-bg);

      border-radius: 0;
      
      background: ${theme.colors.neutral.border};
    }

    &::-moz-range-progress {
      background: var(--range-bg);
      
      height: ${convertToRem(4)};
      
      border-radius: 0;
    }

    &::-moz-range-thumb {
      width: ${convertToRem(10)};
      height: ${convertToRem(10)};
      
      background: var(--range-bg);
      
      border: none;
      border-radius: 50%;
    }
  `}
`;