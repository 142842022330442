import styled from "styled-components";

import { convertToRem } from "@utils/convertToRem";

export const Container = styled.div`
  display: flex;

  width: 100%;

  .schedule-content {
    flex: 1;

    display: flex;

    margin: 0 ${convertToRem(16)};

    > div {
      flex: 1;

      min-width: fit-content;

      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .hours {
      margin-top: ${convertToRem(25)};
    }
  }
`;

export const NavButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${convertToRem(24)};
  height: ${convertToRem(24)};

  background-color: ${({ theme }) => theme.colors.primary.lightest};
  color: ${({ theme }) => theme.colors.primary.highlight};

  border: 0;

  transition: all ease 0.4s;

  svg {
    width: auto;
    height: ${convertToRem(20)};
  }

  &:disabled {
    opacity: 0.6;

    cursor: not-allowed;
  }

  &.prev svg {
    transform: rotate(90deg);
  }

  &.next svg {
    transform: rotate(-90deg);
  }
`;

export const DayWeek = styled.strong`
  color: ${({ theme }) => theme.colors.neutral.black};

  font-weight: 500;
  font-size: ${convertToRem(10)};
  line-height: 1;
`;

export const Day = styled.span`
  margin-top: ${convertToRem(5)};

  color: ${({ theme }) => theme.colors.neutral.black};

  font-size: ${convertToRem(10)};
  line-height: 1;
`;

export const Time = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${convertToRem(46)};
  height: ${convertToRem(20)};

  background-color: ${({ theme }) => theme.colors.primary.lightest};
  color: ${({ theme }) => theme.colors.primary.highlight};

  font-weight: 400;
  font-size: ${convertToRem(10)};
  line-height: 1;

  border: 0;
  border-radius: ${convertToRem(3)};

  cursor: pointer;

  transition: all ease 0.3s;

  &:not(:first-child) {
    margin-top: ${convertToRem(6)};
  }

  &.selected {
    background-color: ${({ theme }) => theme.colors.primary.highlight};
    color: ${({ theme }) => theme.colors.primary.lightest};
  }
`;