import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useFormik } from "formik";
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { RiCloseLine, RiArrowLeftRightLine, RiFilterLine, RiFilterOffLine } from "react-icons/ri";
import * as Yup from 'yup';

import { Loading } from '@components/Loading';
import { Modal, List, LoadingModal, Subtitle } from "@components/Modal";
import { Description, Title } from '@components/Header';
import { CPFCNPJInput, Input } from '@components/Input';
import { OptionTypeBase } from "@components/Select/Select";
import { Select } from '@components/Select';
import { Button } from '@components/Button';
import ResultCard, { ItemDivider, ItemValue, ItemContainer, ItemTitle, ActionsContainer, ActionButton } from '@components/ResultCard';
import { Tooltip } from '@components/Tooltip';
import { formatPhoneNumber } from '@utils/formatPhoneNumber';

import LeadsService, { ListLeadsCampaignFilters, ListAdminLeadsCampaignOrdersBy } from '@services/LeadsService';
import LocationsService from "@services/LocationsService";

import { CancelSquare, ChevronDown, Info, PlusCircle, Search } from '@assets/images';

import { convertToRem } from '@utils/convertToRem';

import { OnChangeOptionType } from '@components/Select/Select.d';

import { Error as StyledError, InputGroup } from "@components/Input/styles";

import { Content, ContentWrapper, FiltersContainer, Footer, Header, LoadingContainer, DataModal, Page, PageButton, Pagination, ResultsContainer, FilterModal } from './styles';

import { AxiosAPIError, StatusColors } from "@/types/api";

interface FilterFormik {
  name: string;
  documento: string;
  uf: OptionTypeBase | null;
  city: OptionTypeBase | null;
  createdAt: string;
}

type LeadsStatus = 1 | 5 | 13 | 12;

interface StatusSelect {
  label: string;
  value: LeadsStatus | number | null;
}

type LeadsCampaign = '1' | '2';

interface CampaignSelect {
    label: string;
    value: LeadsCampaign;
}

type LeadsOrder = 'Nome' | 'Data' | 'Status';

interface OrderSelect {
  label: LeadsOrder;
  value: ListAdminLeadsCampaignOrdersBy;
}

interface Lead {
  id: number;
  name: string;
  nameClinic: string;
  document: string;
  date: Date,
  bornDate: Date,
  address: {
    city: string;
    uf: string;
  };
  status: {
    id: number;
    description: string;
    color: StatusColors;
  };
  contact: {
    email: string;
    whatsApp: string;
  }
}

const campaignSelectItems: CampaignSelect[] = [
  { value: '1', label: 'Agenda Consulta Crédito' },
  { value: '2', label: 'Crédito para Pacientes' },
];

const orderSelectItems: OrderSelect[] = [
  { value: 'name', label: 'Nome' },
  { value: 'created_at', label: 'Data' },
  { value: 'status_id', label: 'Status' },
];

const limitSelectItems = [
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
];

const statusSelectItems: StatusSelect[] = [
  { label: 'Aguardando', value: 1 },
  { label: 'Em contato', value: 5 },
  { label: 'Sem interesse', value: 13 },
  { label: 'Contratado', value: 12 },
];

export function LeadsCampanhas() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [leads, setLeads] = useState<Lead[]>([]);
  
  const [search, setSearch] = useState('');
  const [selectedOrder, setSelectedOrder] = useState<ListAdminLeadsCampaignOrdersBy>("created_at");
  const [selectedCampaign, setSelectedCampaign] = useState<LeadsCampaign>("1");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);

  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [isLoadingUFOptions, setIsLoadingUFOptions] = useState(true);
  const [isLoadingCityOptions, setIsLoadingCityOptions] = useState(false);

  const [UFOptions, setUFOptions] = useState<OptionTypeBase[]>([]);
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);

  const [note, setNote] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<LeadsStatus | number | null>(null);

  const [isFilterModalOpened, setIsFilterModalOpened] = useState(false);
  const [filters, setFilters] = useState<ListLeadsCampaignFilters | undefined>(undefined);

  const [total, setTotal] = useState<number>(0);

  const filterFormik = useFormik<FilterFormik>({
    initialValues: {
      name: '',
      documento: '',
      uf: null,
      city: null,
      createdAt: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string(),
      documento: Yup.string(),
      state: Yup.object({ value: Yup.string(), label: Yup.string() })
        .nullable(),
      city: Yup.object({ value: Yup.string(), label: Yup.string() })
        .when("uf", {
          is: null,
          then: Yup.object().nullable(),
          otherwise: Yup.object()
            .required("O campo é obrigatório")
            .typeError("Selecione uma cidade")
        }),
      paymentStatus: Yup.object({ value: Yup.string(), label: Yup.string() })
        .nullable(),
      situation: Yup.string()
        .nullable(),
    }),
    onSubmit: (values) => {
      setIsFilterModalOpened(false);

      const isAllFieldsEmpty = Object.values(values).map(Boolean).every(e => e === false);
      if (isAllFieldsEmpty) {
        handleClearFilters();
        return;
      }
      
      setFilters({
        name: filterFormik.values.name || undefined,
        documento: filterFormik.values.documento || undefined,
        uf: filterFormik.values.uf ? Number(filterFormik.values.uf.value) : undefined,
        cidade: filterFormik.values.city ? Number(filterFormik.values.city.value) : undefined,
        created_at: filterFormik.values.createdAt || undefined,
      });
    }
  });

  // Getting UF ans bank options
  useEffect(() => {
    async function getUFs() {
      try {
        setIsLoadingUFOptions(true);

        const { data: UFs } = await LocationsService.listUFs();

        const parsedUFs: OptionTypeBase[] = UFs.map((UF) => ({
          value: String(UF.id),
          label: UF.uf,
        }));

        setUFOptions(parsedUFs);
      } catch (error) {
        toast.error("Não foi possível carregar as UFs");

        console.error(error);
      } finally {
        setIsLoadingUFOptions(false);
      }
    }

    getUFs();
  }, []);

  // Getting city options
  useEffect(() => {
    async function getCities() {
      try {
        if (!UFOptions.length || !filterFormik.values.uf) {
          setCityOptions([]);
          return;
        }

        setIsLoadingCityOptions(true);

        const UF = UFOptions.find((uf) => uf.label === filterFormik.values.uf?.label);

        if (typeof UF === "undefined")
          throw new Error(
            "Estado inválido! Não foi possível buscar as cidades"
          );

        const { data: cities } = await LocationsService.listCitiesByUF(
          UF.label
        );

        const parsedCities: OptionTypeBase[] = cities.map((city) => ({
          value: String(city.id),
          label: city.cidade,
        }));

        setCityOptions(parsedCities);
      } catch (error) {
        setCityOptions([]);

        toast.error("Não foi possível carregar as cidades");

        console.error(error);
      } finally {
        setIsLoadingCityOptions(false);
      }
    }

    getCities();
  }, [UFOptions, filterFormik.values.uf]);

  useEffect(() => {
    getLeads();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrder, search, selectedCampaign, filters, itemsPerPage, currentPage]);

  // Setting dependents
  async function getLeads() {
    try {
        setIsLoading(true);
        
        const { data } = await LeadsService.listAdminLeadsCampaign({
            page: currentPage - 1,
            limit: itemsPerPage,
            orderBy: selectedOrder,
            order: 'desc',
            campanha_id: selectedCampaign,
            filters
        });

        setTotal(data.total);
        
        const parsedAffiliates = data.dados.map<Lead>(lead => ({
            id: lead.id,
            name:  lead.nome,
            nameClinic:  lead.nome_clinica,
            date: lead.data_cadastro,
            bornDate: lead.data_nascimento,
            document: lead.documento,
            address: {
                city: lead.cidade,
                uf: lead.uf,
            },
            status: {
                id: lead.status_id,
                description: lead.status_nome,
                color: lead.status_cor,
            },
            contact: {
                email: lead.email,
                whatsApp: lead.whatsapp,
            }
        }));

        setLeads(parsedAffiliates);
    } catch (error) {
        toast.error('Não foi possível carregar dados dos leads!');

        console.log(error);
    } finally {
        setIsLoading(false);
    }
  };

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  function handleClearFilters() {
    filterFormik.resetForm();
    setIsFilterModalOpened(false);
    setFilters(undefined);
  }

  function handleChangeLimit(selectedOption: OnChangeOptionType) {
    if (selectedOption === null) return;

    setItemsPerPage(Number(selectedOption.value));
  }

  function handleDeletePatient() {
    setIsDeleteModalVisible(false);
    toast.success('Lead excluído com sucesso!');
  }

  return (
    <Content>
      <FilterModal 
        visible={isFilterModalOpened}
        title='Filtros'
        onRequestClose={() => setIsFilterModalOpened(false)}
      >
        <form onSubmit={filterFormik.handleSubmit}>
          <InputGroup>
            <Input
              label='Nome'
              name='name'
              placeholder='Buscar por nome'
              iconRight={<Search />}
              value={filterFormik.values.name}
              onChange={filterFormik.handleChange}
              error={(filterFormik.touched.name || undefined) && filterFormik.errors.name}
            />
          </InputGroup>

          <InputGroup>
            <CPFCNPJInput
              label="CPF/CNPJ"
              name="documento"
              value={filterFormik.values.documento}
              onChange={filterFormik.handleChange}
              error={(filterFormik.touched.documento || undefined) && filterFormik.errors.documento}
            />
          </InputGroup>
          
          <InputGroup layout="1fr 2fr">
            <Select
              label="UF"
              name="uf"
              options={UFOptions}
              value={filterFormik.values.uf}
              onChange={(selectedOption) => {
                filterFormik.setFieldValue('uf', selectedOption);
                filterFormik.setFieldValue('city', null, false);
              }}
              isDisabled={isLoadingUFOptions}
              error={(filterFormik.touched.uf || undefined) && filterFormik.errors.uf}
            />

            <Select
              label="Cidade"
              name="city"
              options={cityOptions}
              value={filterFormik.values.city}
              onChange={(selectedOption) => filterFormik.setFieldValue('city', selectedOption)}
              isDisabled={filterFormik.values.uf === null || isLoadingCityOptions}
              error={(filterFormik.touched.city || undefined) && filterFormik.errors.city}
            />
          </InputGroup>

          <InputGroup>
            <Input
              label="Data de cadastro"
              name="createdAt"
              type="date"
              value={filterFormik.values.createdAt}
              onChange={filterFormik.handleChange}
              error={(filterFormik.touched.createdAt || undefined) && filterFormik.errors.createdAt}
            />
          </InputGroup>

          <footer>
            {filters !== undefined && (
              <Button variant='secondary' onClick={handleClearFilters}>
                Limpar filtros
              </Button>
            )}

            <Button type='submit'>
              Filtrar
            </Button>
          </footer>
        </form>
      </FilterModal>

      <DataModal
        visible={selectedLead !== null && isDetailModalVisible}
        onRequestClose={() => setIsDetailModalVisible(false)}
        title={selectedLead?.name}
        style={{ maxWidth: convertToRem(500) }}
      >
        <Subtitle>Informações</Subtitle>
        <List>
          <li>
            <strong>Nome:</strong>
            <span>{selectedLead?.name}</span>
          </li>
          { selectedCampaign == '1' ?
            (<li>
              <strong>Data de nascimento:</strong>
              <span>{selectedLead?.bornDate}</span>
            </li>) : '' }
          { selectedCampaign == '2' ?
            (<li>
              <strong>Nome da clínica:</strong>
              <span>{selectedLead?.nameClinic}</span>
            </li>) : '' }
          <li>
            <strong>CPF/CNPJ:</strong>
            <span>{selectedLead?.document}</span>
          </li>
          <li>
            <strong>WhatsApp:</strong>
            <span>{selectedLead?.contact.whatsApp ? formatPhoneNumber(selectedLead.contact.whatsApp) : '-'}</span>
          </li>
          <li>
            <strong>E-mail:</strong>
            <span>{selectedLead?.contact.email}</span>
          </li>
          <li>
            <strong>Cidade:</strong>
            <span>{selectedLead?.address.city}/{selectedLead?.address.uf}</span>
          </li>
        </List>
      </DataModal>

      <Modal 
        visible={selectedLead !== null && isDeleteModalVisible}
        title="Atenção"
        onRequestClose={() => setIsDeleteModalVisible(false)} 
      >
        <p className='text'>
          Deseja realmente excluir o lead <strong>{selectedLead?.name}</strong>? Essa ação irá excluir todos os dados e é irreversível.
        </p>

        <div className='buttons'>
          <button onClick={() => setIsDeleteModalVisible(false)}>
            Não
          </button>

          <button onClick={handleDeletePatient} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <ContentWrapper className="content">
        <Header>
          <div>
            <Title>Leads de Campanhas</Title>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam massa sapien velit ipsum, ac.
            </Description>
          </div>
        </Header>

        <FiltersContainer>

          <div className="campanha">
            <Select
              label="Campanha"
              options={campaignSelectItems}
              value={campaignSelectItems.find(
                (option) => option.value === selectedCampaign
              )}
              onChange={(option) => {
                setSelectedCampaign(option?.value || "1");
                setCurrentPage(1);
              }}
              isClearable={false}
            />
          </div>
          
          <div className="order">
            <Select
              label="Classificar por"
              options={orderSelectItems}
              value={orderSelectItems.find(
                (option) => option.value === selectedOrder
              )}
              onChange={(option) => {
                setSelectedOrder(option?.value || "created_at");
                setCurrentPage(1);
              }}
              isClearable={false}
            />
          </div>

          <div className="ultimos">
            <Select
              label="Exibir últimos"
              options={limitSelectItems}
              onChange={handleChangeLimit}
              defaultValue={limitSelectItems.find(
                (i) => i.label === String(itemsPerPage)
              )}
              isClearable={false}
            />
          </div>

          <div className="filter-buttons">
            <Button 
              variant='tertiary' 
              onClick={() => setIsFilterModalOpened(true)}
            >
              <RiFilterLine />
              {filters === undefined ? 'Filtrar' : 'Exibir filtros'}
            </Button>

            {filters !== undefined && (
              <Button 
                variant='tertiary'
                onClick={handleClearFilters}
              >
                <RiFilterOffLine />
                Limpar filtros
              </Button>
            )}
          </div>
        </FiltersContainer>

        <ResultsContainer>
          {leads.length > 0 ?
            <span>Encontramos um total de <strong>{total}</strong> leads.</span>
          : ''}
          {isLoading
            ? <Loading />
            : (leads.length > 0 ?
                leads.map(lead => (
                    <ResultCard key={lead.id}>

                        <ItemContainer>
                        <Tooltip content={lead.date ? format(new Date(lead.date), 'dd/MM/yyyy hh:mm:ss') : '-'}>
                          <div>
                              <ItemValue>{lead.date ? format(new Date(lead.date), 'dd/MM/yyyy hh:ii') : '-'}</ItemValue>
                              <ItemTitle>Data de cadastro</ItemTitle>
                          </div>
                        </Tooltip>
                        </ItemContainer>

                        <ItemDivider />

                        <ItemContainer>
                        <Tooltip content={lead.name || '-'}>
                          <div>
                              <ItemValue>{lead.name || '-'}</ItemValue>
                              <ItemTitle>Nome</ItemTitle>
                          </div>
                        </Tooltip>
                        </ItemContainer>

                        <ItemDivider />

                        <ItemContainer>
                        <Tooltip content={lead.nameClinic || '-'}>
                        <div>
                            <ItemValue>{lead.nameClinic || '-'}</ItemValue>
                            <ItemTitle>Nome clínica</ItemTitle>
                        </div>
                        </Tooltip>
                        </ItemContainer>

                        <ItemDivider />

                        <ItemContainer>
                        <Tooltip content={lead.contact.email || '-'}>
                        <div>
                            <ItemValue>{lead.contact.email || '-'}</ItemValue>
                            <ItemTitle>E-mail</ItemTitle>
                        </div>
                        </Tooltip>
                        </ItemContainer>

                        <ItemDivider />

                        <ItemContainer>
                        <div>
                            <ItemValue>{formatPhoneNumber(lead.contact.whatsApp) || '-'}</ItemValue>
                            <ItemTitle>WhatsApp</ItemTitle>
                        </div>
                        </ItemContainer>

                        {/* <ItemDivider color={lead.status.color} />

                        <ItemContainer>
                        <div>
                            <ItemValue 
                            style={{ 
                                color: lead.status.color
                            }}
                            >
                            {lead.status.description}
                            </ItemValue>

                            <ItemTitle>Status</ItemTitle>
                        </div>
                        </ItemContainer> */}

                        <ActionsContainer>
                        <Tooltip content="Dados">
                          <ActionButton
                            color={theme.colors.helpers.success} 
                            onClick={() => {
                              setIsDetailModalVisible(true);
                              setSelectedLead(lead);
                            }}
                          >
                            <Info />
                          </ActionButton>
                        </Tooltip>

                        {/* <Tooltip content="Alterar status">
                            <ActionButton
                            color={theme.colors.helpers.warning} 
                            onClick={() => {
                                setSelectedLead(lead);
                                setSelectedStatus(lead.status.id);
                                setIsStatusModalVisible(true);
                            }}
                            >
                            <RiArrowLeftRightLine />
                            </ActionButton>
                        </Tooltip> */}

                        <Tooltip content="Excluir">
                            <ActionButton 
                            color={theme.colors.helpers.danger} 
                            onClick={() => {
                                setIsDeleteModalVisible(true);
                                setSelectedLead(lead);
                            }}
                            >
                            <CancelSquare />
                            </ActionButton>
                        </Tooltip>
                        </ActionsContainer>
                    </ResultCard>
                )) 
            : 'Nenhum lead foi encontrado')
          }
        </ResultsContainer>
      </ContentWrapper>

      <Footer>
        <div className="content">
          <Pagination>
            <PageButton
              className="prev"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              <ChevronDown />
            </PageButton>

            <Page>{String(currentPage).padStart(2, "0")}</Page>

            <PageButton
              className="next"
              onClick={goToNextPage}
              disabled={currentPage === lastPage}
            >
              <ChevronDown />
            </PageButton>
          </Pagination>
        </div>
      </Footer>
    </Content>
  );
}