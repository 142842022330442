import styled from "styled-components";

import { convertToRem } from "@utils/convertToRem";

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;

  background-color: ${({ theme }) => theme.colors.neutral.borderLight};
  color: ${({ theme }) => theme.colors.neutral.textDark};

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${convertToRem(62)};
    height: ${convertToRem(62)};
    
    background-color: ${({ theme }) => theme.colors.helpers.success};
    color: ${({ theme }) => theme.colors.neutral.white};

    border-radius: 50%;

    svg {
      width: ${convertToRem(20)};
      height: auto;
    }
  }

  h1 {
    margin-top: ${convertToRem(16)};

    font-weight: 600;
    font-size: ${convertToRem(60)};

    @media (max-width: 768px) {
      font-size: ${convertToRem(48)};
    }
  }

  p {
    margin-top: ${convertToRem(6)};

    font-size: ${convertToRem(16)};
  }

  a {
    margin-top: ${convertToRem(32)};
    padding: ${convertToRem(16)} ${convertToRem(32)};

    background-color: ${({ theme }) => theme.colors.primary.base};
    color: ${({ theme }) => theme.colors.neutral.white};

    font-weight: 500;
    font-size: ${convertToRem(15)};

    border-radius: ${convertToRem(8)};
  }
`;
