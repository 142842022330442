import { ChangeEvent, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import faker from '@faker-js/faker';
import { format } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

import { useAuth } from '@contexts/auth';
import { useSearch } from '@contexts/search';

import { Popover, PopoverTrigger } from '@components/Popover';

import { useDebounce } from '@hooks/useDebounce';

import { Bell, ChevronDown, Search } from "@assets/images";
import profilePic from '@assets/images/profile-pic.png';

import { formatCPFCNPJ } from '@utils/formatCPFCNPJ';

import { Account, AccountDropdown, AccountMenu, Container, Name, NotificationContainer, NotificationContent, Photo, PrimeFlag, SearchContainer, SearchInput, SelectedUserInfo, SelectUserProfileContainer, Wrapper } from "./styles";


interface Notification {
  id: string;
  title: string;
  date: Date;
  isUnread: boolean;
}

const fakerNotifications = Array(3)
  .fill({} as Notification)
  .map<Notification>(() => ({
    id: faker.datatype.uuid(),
    title: faker.lorem.words(8),
    date: faker.datatype.datetime({ max: (new Date()).getTime() }),
    isUnread: faker.datatype.boolean(),
  }))
;

export function Header() {
  const { user, selectedAccount, selectedProfile, handleSignOut } = useAuth();

  // Search Input
  const location = useLocation();
  const navigate = useNavigate();
  const { changeSearchValue } = useSearch();
  
  const [professionalName, setProfessionalName] = useState('');
  const debouncedValue = useDebounce<string>(professionalName, 700);


  // Notifications
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const unreadNotifications = notifications.filter(notification => notification.isUnread);

  function getInputPlaceholderByUserProfileAndPage() {
    if (selectedProfile?.alias === "patient" && location.pathname.includes('farmacias')) return 'Buscar por nome da farmácia';
    
    return 'Buscar por médico';
  }

  function handleSearchContainerClick() {
    if (selectedProfile?.alias === "patient") {
      if (!location.pathname.includes('farmacias') && !location.pathname.includes('profissionais')) {
        navigate('/');
      }
    }
  }

  useEffect(() => {
    changeSearchValue(debouncedValue);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);
  
  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    setProfessionalName(event.target.value);
  }

  function handleReadAllNotifications() {
    setNotifications(notifications => notifications.map(notification => ({ ...notification, isUnread: false })));
  }

  function handleReadNotification(_notification: Notification) {
    setNotifications(notifications => notifications.map(notification => {
      if (notification.id === _notification.id) {
        return ({
          ...notification,
          isUnread: false,
        });
      }

      return notification;
    }));
  }

  if (!user) {
    navigate('/login');
    return null;
  }

  if (!selectedProfile) {
    navigate('/selecionar-perfil');
    return null;
  }

  if (selectedProfile.hasMoreThanOneAccount && !selectedAccount) {
    navigate('/selecionar-conta');
    return null; 
  }

  return (
    <Container>
      <Wrapper className="content">
        <SearchContainer onClick={handleSearchContainerClick}>
          {selectedProfile.alias === "patient" 
            ? (
              <SearchInput>
                <Search />

                <input  
                  type="text"
                  placeholder={getInputPlaceholderByUserProfileAndPage()}
                  value={professionalName}
                  onChange={handleInputChange}
                />
              </SearchInput> 
            )
            : selectedProfile.alias === "clinica" && selectedAccount
              ? (
                <SelectedUserInfo>
                  <h1>{selectedAccount.name}</h1>
                  <p>{formatCPFCNPJ(selectedAccount.document)}</p>
                </SelectedUserInfo>
              )
              : null
          }
        </SearchContainer>

        {selectedAccount?.isPrime && <PrimeFlag>Prime</PrimeFlag>}

        <NotificationContainer>
          <Popover>
            <PopoverTrigger>
              <button 
                type="button" 
                className={unreadNotifications.length > 0 ? 'has-notification' : ''}
              >
                <Bell />
              </button>
            </PopoverTrigger>

            <NotificationContent>
              <header>
                <h4>Notificações</h4>

                <button 
                  type='button'
                  onClick={handleReadAllNotifications}
                  disabled={unreadNotifications.length === 0}
                >
                  Ler tudo
                </button>
              </header>

              <ul>
                {notifications.length > 0
                  ? notifications.map(notification => (
                    <li 
                      key={notification.id} 
                      className={notification.isUnread ? 'unread' : ''}
                      onClick={() => handleReadNotification(notification)}
                    >
                      <div className="wrapper">
                        <strong>{notification.title}</strong>
                        <span>{format(notification.date, "dd LLL yyyy HH:mm", { locale: ptBR })}</span>
                      </div>
                    </li>
                  ))
                  : (
                    <li className='none'>
                      <div className="wrapper">
                        <strong>
                          Sem notificações
                        </strong>
                      </div>
                    </li>
                  )
                }
              </ul>
            </NotificationContent>
          </Popover>
        </NotificationContainer>

        <AccountDropdown>
          <Popover>
            <PopoverTrigger>
              <Account>
                <Photo>
                  <img src={profilePic} alt="Bruce Wayne" />
                </Photo>

                <Name>{user.name}</Name>

                <ChevronDown />
              </Account>
            </PopoverTrigger>

            <AccountMenu hideArrow>
              {(user.hasMoreThanOneProfile || selectedProfile.hasMoreThanOneAccount) && (
                <SelectUserProfileContainer>
                  <li>
                    <Link to={selectedProfile.hasMoreThanOneAccount ? "/selecionar-conta" : "/selecionar-perfil"}>
                      Trocar conta
                    </Link>
                  </li>
                </SelectUserProfileContainer>
              )}

              <li>
                <Link to="/editar-cadastro/">
                  Editar cadastro
                </Link>
              </li>

              <li>
                <button 
                  onClick={() => alert('Criar modal para alterar senha')}
                >
                  Alterar Senha
                </button>
              </li>

              <li className='sign-out'>
                <button 
                  onClick={() => handleSignOut()}
                >
                  Sair
                </button>
              </li>
            </AccountMenu>
          </Popover>
        </AccountDropdown>
      </Wrapper>
    </Container>
  );
}

export { Title, Description } from './styles'