import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FcGoogle } from 'react-icons/fc'
import { useFormik } from "formik";
import * as Yup from "yup";

import { useAuth } from "@contexts/auth";

import { LoadingModal } from "@components/Modal";
import { CPFCNPJInput, Input } from "@components/Input";
import { Checkbox } from "@components/Checkbox";
import { Button } from "@components/Button";

import { AgendaConsultaExpand } from "@assets/images";
import mockup from '@assets/images/mockup.png';

import { Banner, Container, Main } from "./styles";
import { toast } from "react-toastify";

interface LoginForm {
  user: string;
  password: string;
  rememberMe: boolean;
  isPhone: boolean;
}

export function Login() {
  const { handleSignIn } = useAuth();

  const navigate = useNavigate();
  
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik<LoginForm>({
    initialValues: {
      user: '',
      password: '',
      isPhone: false,
      rememberMe: true,
    },
    validationSchema: Yup.object().shape({
      user: Yup.string()
        .when("isPhone", {
          is: true,
          then: Yup.string().matches(
            /^(?:(?:\+|00)?(55)\s?)?(?:\(?([1-9][0-9])\)?\s?)(?:((?:(9|9 )\d|[2-9])\d{3})-?(\d{4}))$/gm, 
            "Insira um telefone válido"
          ),
          otherwise: Yup.string().matches(
            /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/gm,
            "Insira um CPF/CNPJ válido"
          )
        })
        .required("O campo é obrigatório"),
      password: Yup.string()
        .min(8, "A senha deve ter no mínimo 8 caracteres")
        .required("O campo é obrigatório"),
    }),
    onSubmit: async ({ user, password, rememberMe }) => {
      try {
        setIsSubmitting(true);
        
        await handleSignIn({ user, password, rememberMe });
      } catch (error) {
        console.log(error);
        
        toast.error('Não foi possível realizar o login');
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  return (
    <Container>
      <LoadingModal visible={isSubmitting} />
      
      <Main>
        <div className="wrapper">
          <AgendaConsultaExpand className="logo" />

          <h1>Faça seu login</h1>

          <form onSubmit={formik.handleSubmit}>
            {formik.values.isPhone
              ? (
                <Input 
                  label="Telefone"
                  type='tel'
                  name="user"
                  mask={['(', /\d/, /\d/, ')', ' ', /\d/, ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                  value={formik.values.user}
                  onChange={formik.handleChange}
                  error={(formik.touched.user || undefined) && formik.errors.user}
                />
              ) : (
                <CPFCNPJInput 
                  label="CPF ou CNPJ"
                  name="user"
                  value={formik.values.user}
                  onChange={formik.handleChange}
                  error={(formik.touched.user || undefined) && formik.errors.user}
                />
              )
            }
            
            <Checkbox 
              label="É telefone?"
              name="isPhone"
              checked={formik.values.isPhone}
              onChange={(e) => formik.setFieldValue("isPhone", e.target.checked)}
            />

            <Input
              type="password"
              label="Senha"
              name="password"
              value={formik.values.password}
              onChange={formik.handleChange}
              error={(formik.touched.password || undefined) && formik.errors.password}
            />

            <div className="forgot-password">
              <Checkbox 
                label="Lembrar-me"
                name="rememberMe"
                checked={formik.values.rememberMe}
                onChange={(e) => formik.setFieldValue("rememberMe", e.target.checked)}
              />

              <Link to="/esqueci-minha-senha">Esqueci minha senha</Link>
            </div>

            <Button type="submit">Entrar</Button>

            {/* <Button 
              variant="tertiary"
              onClick={(e) => {
                e.preventDefault();
                alert('Entrar com o Google');
              }}
            >
              <FcGoogle />
              
              Entrar com o Google
            </Button> */}
          </form>

          {/* <span className="register">
            Não possui uma conta? <Link to="/cadastro">Cadastre-se</Link><br />
          </span> */}
          {/* <Link to="/afiliado/cadastro" className="affiliate">Cadastre-se como afiliado(a)</Link> */}
        </div>
      </Main>

      <Banner>
        <img src={mockup} alt="Mockup" />
      </Banner>
    </Container>
  );
}