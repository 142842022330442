import styled from "styled-components";
import chroma from "chroma-js";

import { InputGroup } from "@components/Input";
import { Container as Button } from "@components/Button/styles";

import { convertToRem } from "@utils/convertToRem";

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  
  width: 100%;
  height: 100%;

  max-width: ${convertToRem(770)};

  padding-top: ${convertToRem(36)};
  padding-bottom: ${convertToRem(80)};

  fieldset {
    border: 0;

    legend {
      margin-bottom: ${convertToRem(30)};

      font-weight: 500;
      font-size: ${convertToRem(10)};
      line-height: ${convertToRem(11)};

      color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.5).css()};

      text-transform: uppercase;
    }

    &:not(:first-child) {
      margin-top: ${convertToRem(48)};
    }
  }

  @media (max-width: 992px) {
    max-width: 100%;
  }

  @media (max-width: 576px) {
    ${InputGroup} {
      grid-template-columns: 1fr;

      &.neighborhood {
        grid-template-columns: 1fr 2fr;

        > *:last-child {
          grid-column: 1 / 3;
        }
      }
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${convertToRem(24)};

  margin-top: ${convertToRem(100)} !important;

  > ${Button} {
    min-width: initial;

    width: 100%;
    max-width: ${convertToRem(270)};
  }
`;