import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { addDays, format, subYears } from "date-fns";
import { useFormik } from "formik";
import * as Yup from "yup";

import { Loading } from '@components/Loading';
import { Description, Title } from "@components/Header";
import { Input, InputGroup } from "@components/Input";
import { DatePicker } from "@components/DatePicker";
import { Callout } from "@components/Callout";
import { Button } from "@components/Button";

import DependentsService, { Dependent } from '@services/DependentsService';

import { Content, ContentWrapper, DependentNotFoundContainer, Form, LoadingContainer, Main } from "./styles";

export function EditDependent() {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [dependent, setDependent] = useState<Dependent | null>(null);

  const minDate = format(subYears(addDays(new Date(), 1), 18), 'yyyy-MM-dd');

  const formik = useFormik({
    initialValues: {
      name: dependent?.name || "",
      birthDate: dependent?.birthDate || null,
      cpf: dependent?.cpf || "",
      rg: dependent?.rg || "",
    },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .matches(
          /^[a-zA-Z]{2,}(?: [a-zA-Z]+){1,}$/g,
          "Insira seu nome completo"
        )
        .required("O campo é obrigatório"),
      cpf: Yup.string()
        .matches(
          /^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/,
          "Insira um CPF válido"
        )
        .required("O campo é obrigatório"),
      rg: Yup.string()
        .matches(
          /^[0-9]{2,3}\.?[0-9]{2,3}\.?[0-9]{3}-?[A-Za-z0-9]{1}$/,
          "Insira um RG válido"
        )
        .required("O campo é obrigatório"),
      birthDate: Yup.date()
        .min(minDate, 'O dependente deve ser menor de 18 anos')
        .required("O campo é obrigatório")
        .typeError("O campo é obrigatório")
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const dependentData = await DependentsService.listDependentById(id || '');

        setDependent(dependentData);
      } catch (error) {
        toast.error("Não foi possível carregar os dados do dependente");

        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })()
  }, [id]);

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  if (dependent === null) {
    return (
      <DependentNotFoundContainer>
        <h4>Dependente não encontrado</h4>

        <Link to="/dependentes/">Voltar para página de listagem</Link>
      </DependentNotFoundContainer>
    );
  }

  return (
    <Content>
      <ContentWrapper className="content">
        <Title>Atualizar dependente</Title>
        <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc, sed congue urna varius in condimentum.
        </Description>

        <Main>
          <Form onSubmit={formik.handleSubmit}>
            <InputGroup layout="6fr 2fr">
              <Input
                label="Nome completo"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={(formik.touched.name || undefined) && formik.errors.name}
              />

              <DatePicker
                label="Nascimento"
                name="birthDate"
                showYearDropdown
                maxDate={new Date()}
                selected={formik.values.birthDate}
                onChange={(e) => formik.setFieldValue("birthDate", e)}
                onBlur={formik.handleBlur}
                error={
                  (formik.touched.birthDate || undefined) &&
                  formik.errors.birthDate
                }
              />
            </InputGroup>

            <InputGroup>
              <Input
                label="CPF"
                name="cpf"
                mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                value={formik.values.cpf}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={(formik.touched.cpf || undefined) && formik.errors.cpf}
              />

              <Input
                label="RG"
                name="rg"
                value={formik.values.rg}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={(formik.touched.rg || undefined) && formik.errors.rg}
              />
            </InputGroup>

            <Callout>
              Dependentes devem possuir no <strong>máximo 17 anos</strong>.
            </Callout>

            <Button type="submit">Atualizar</Button>
          </Form>
        </Main>
      </ContentWrapper>
    </Content>
  );
}
