import styled from "styled-components";
import chroma from "chroma-js";

import { Container as Checkbox } from "@components/Checkbox/styles";
import { InputGroup } from "@components/Input/styles";
import { Container as Button } from "@components/Button/styles";

import { convertToRem } from "@utils/convertToRem";

export const Container = styled.div`
  width: 100vw;
  min-height: 100vh;

  background-color: ${({ theme }) => chroma(theme.colors.neutral.border).alpha(0.3).css()};
`;

export const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  max-width: ${convertToRem(730)};

  margin: 0 auto;
  padding: ${convertToRem(40)} ${convertToRem(15)} ${convertToRem(80)};

  .card {
    width: 100%;

    background-color: ${({ theme }) => theme.colors.neutral.white};

    box-shadow: 0 ${convertToRem(2)} ${convertToRem(8)} rgba(0, 0, 0, 0.075);
    border-radius: ${convertToRem(8)};
  }

  header {
    width: 100%;

    .promo-image {
      width: 100%;
  
      border-radius: ${convertToRem(8)};
  
      overflow: hidden;
  
      img {
        display: block;
  
        width: 100%;
  
        &.mobile {
          display: none;
        }
      }
    }
  
    @media screen and (max-width: 992px) {
      .promo-image {
        height: auto;
  
        img {
          height: auto;
  
          object-fit: inherit;
  
          display: none;
  
          &.mobile {
            display: block;
          }
        }
      }
    }

    .go-back {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      margin-top: ${convertToRem(24)};

      color: ${({ theme }) => theme.colors.primary.base};

      font-size: ${convertToRem(15)};
      font-weight: 500;

      > svg {
        width: auto;
        height: ${convertToRem(18)};
      }
    }

    h1 {
      margin-top: ${convertToRem(24)};
      color: ${({ theme }) => theme.colors.neutral.textDark};

      font-weight: 400;
    }
  }

  section {
    width: 100%;

    margin-top: ${convertToRem(24)};

  }

  .card {
    padding: ${convertToRem(24)};
    float: left;

    > * + * {
      margin-top: ${convertToRem(16)};
    }

    > h3 {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: ${convertToRem(24)};

      color: ${({ theme }) => theme.colors.primary.base};

      font-weight: 400;
      font-size: ${convertToRem(18)};

      > strong {
        color: ${({ theme }) => theme.colors.neutral.text};
        
        font-weight: 500;
        font-size: ${convertToRem(16)};

        > span {
          color: ${({ theme }) => theme.colors.primary.base};
        }
      }
    }

    > span.description {
      width: 100%;
      
      float: left;

      font-size: 0.8rem;
      color: ${({ theme }) => theme.colors.neutral.text};
    }

    .terms {
      max-height: ${convertToRem(500)};

      overflow-y: auto;

      * {
        color: ${({ theme }) => theme.colors.neutral.text};
        
        font-size: ${convertToRem(14)};
        line-height: 1.5;
      }

      h5 {
        margin-bottom: ${convertToRem(24)};

        text-align: center;
        text-transform: uppercase;

        &:not(:first-child) {
          margin-top: ${convertToRem(24)};
        }
      }

      > strong {
        display: block;

        margin-top: ${convertToRem(16)};

        font-size: ${convertToRem(15)};

        text-transform: uppercase;
      }

      p {
        margin-top: ${convertToRem(8)};
      }

      ul {
        margin-left: ${convertToRem(32)};

        list-style-type: initial;
      }

      ol {
        margin-left: ${convertToRem(32)};

        list-style-type: decimal;
      }

      table {
        background-color: ${({ theme }) => theme.colors.neutral.text};

        th, td {
          padding: ${convertToRem(6)} ${convertToRem(12)};
        }

        th {
          font-weight: 500;
        
          background-color: ${({ theme }) => theme.colors.primary.highlight};
          color: ${({ theme }) => theme.colors.neutral.white};
        }

        td {
          background-color: ${({ theme }) => theme.colors.neutral.borderLight};
        }
      }

      ${Checkbox} {
        margin-top: ${convertToRem(48)};
      }
    }
   
  }

  > ${Button} {
    margin-top: ${convertToRem(48)};
    margin-left: auto;
  }

  @media (max-width: 576px) {
    ${InputGroup} {
      grid-template-columns: 1fr;
      gap: ${convertToRem(16)};
    }
  }
`;