export function formatPhoneNumber(phone: string): string {
  const cleanedPhone = phone.replace(/[^\d]/g, "");

  let match = cleanedPhone.match(/^(\d{2})(\d{5})(\d{4})$/);

  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  };

  return '';
}