import { FocusEvent, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { Input, InputGroup } from "@components/Input";
import { Select } from "@components/Select";
import { DatePicker } from "@components/DatePicker";
import { Button } from "@components/Button";

import LocationsService from "@services/LocationsService";

import { SingleValue } from "react-select";
import { OptionTypeBase } from "@components/Select/Select";

import { Wrapper, Form, Content, ContentWrapper } from "./styles";
import { Description, Title } from "@/components/Header";

const genderOptions = [
  { label: "Masculino", value: "masculino" },
  { label: "Feminino", value: "feminino" },
  { label: "Não informar", value: "none" },
];

export function AddPatient() {
  const [isLoadingUFOptions, setIsLoadingUFOptions] = useState(false);

  const [UFOptions, setUFOptions] = useState<OptionTypeBase[]>([]);
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);

  const [isCEPValid, setIsCEPValid] = useState(true);
  const [isLoadingCEPInfo, setIsLoadingCEPInfo] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: "",
      gender: "",
      cpf: "",
      rg: "",
      whatsapp: "",
      phone: "",
      birthDate: null,
      zipCode: "",
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      state: "",
      city: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("O campo é obrigatório"),
      gender: Yup.string().required("O campo é obrigatório"),
      cpf: Yup.string()
        .matches(
          /^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/,
          "Insira um CPF válido"
        )
        .required("O campo é obrigatório"),
      rg: Yup.string()
        .matches(
          /^[0-9]{2,3}\.?[0-9]{2,3}\.?[0-9]{3}-?[A-Za-z0-9]{1}$/,
          "Insira um RG válido"
        )
        .required("O campo é obrigatório"),
      whatsapp: Yup.string()
        .matches(/\(\d{2,}\) \d{1} \d{4}-\d{4}/g, "Insira um número válido")
        .required("O campo é obrigatório"),
      phone: Yup.string().matches(
        /\(\d{2,}\) \d{4}-\d{4}/g,
        "Insira um telefone válido"
      ),
      birthDate: Yup.date()
        .required("O campo é obrigatório")
        .typeError("Insira uma data válida"),
      zipCode: Yup.string()
        .matches(/[0-9]{5}-?[0-9]{3}/g, "Infome um CEP válido")
        .required("O campo é obrigatório"),
      street: Yup.string().required("O campo é obrigatório"),
      number: Yup.number()
        .required("O campo é obrigatório")
        .typeError("Apenas números"),
      neighborhood: Yup.string().required("O campo é obrigatório"),
      state: Yup.string()
        .matches(/[A-Z]{2}/g, "Selecione um estado")
        .required("O campo é obrigatório"),
      city: Yup.string().required("O campo é obrigatório"),
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  // Setting UF options
  useEffect(() => {
    async function getUFs() {
      try {
        setIsLoadingUFOptions(true);

        const { data: UFs } = await LocationsService.listUFs();

        const parsedUFs: OptionTypeBase[] = UFs.map((UF) => ({
          value: String(UF.id),
          label: UF.uf,
        }));

        setUFOptions(parsedUFs);
      } catch (error) {
        setUFOptions([]);

        toast.error("Não foi possível carregar as UFs");

        console.log(error);
      } finally {
        setIsLoadingUFOptions(false);
      }
    }

    getUFs();
  }, []);

  function clearAddressFields() {
    formik.setFieldValue("street", "");
    formik.setFieldValue("complement", "");
    formik.setFieldValue("neighborhood", "");
    formik.setFieldValue("state", "");
    formik.setFieldValue("city", "");
  }

  async function changeCityOption(_uf: string) {
    try {
      const UF = UFOptions.find((uf) => uf.label === _uf);

      if (typeof UF === "undefined")
        throw new Error("Estado inválido! Não foi possível buscar as cidades");

      const { data: cities } = await LocationsService.listCitiesByUF(UF.label);

      const parsedCities: OptionTypeBase[] = cities.map((city) => ({
        value: String(city.id),
        label: city.cidade,
      }));

      setCityOptions(parsedCities);
    } catch (error) {
      toast.error("Não foi possível carregar as cidades");

      console.log(error);
    }
  }

  async function handleBlurZipCode(event: FocusEvent<HTMLInputElement>) {
    const cep = event.target.value.replace(/[^\d]/g, "");

    if (cep.length !== 8) return clearAddressFields();

    try {
      setIsLoadingCEPInfo(true);

      const data = await LocationsService.getAddressByZipCode(Number(cep));

      if (data.erro) throw new Error("CEP Inválido!");

      setIsCEPValid(true);

      formik.setFieldValue("street", data.logradouro);
      formik.setFieldValue("complement", data.complemento);
      formik.setFieldValue("neighborhood", data.bairro);
      formik.setFieldValue("state", data.uf);

      await changeCityOption(data.uf);
      formik.setFieldValue("city", data.localidade);
    } catch (error) {
      console.log(error);

      setIsCEPValid(false);

      clearAddressFields();
    } finally {
      setIsLoadingCEPInfo(false);
    }
  }

  async function handleChangeState(option: SingleValue<OptionTypeBase>) {
    if (!option) {
      formik.setFieldValue("state", "");
      formik.setFieldValue("city", "");

      setCityOptions([]);
      return;
    }

    formik.setFieldValue("state", option.label);
    changeCityOption(option.label);
  }

  return (
    <Content>
      <ContentWrapper className="content">
        <Title>Cadastrar Paciente</Title>
        <Description>
          Mantenha o seu cadastro atualizado para aproveitar ao máximo nossas
          vantagens e funcionalidades.
        </Description>

        <Wrapper>
          <Form onSubmit={formik.handleSubmit}>
            <InputGroup layout="6fr 2fr">
              <Input
                label="Nome completo"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={(formik.touched.name || undefined) && formik.errors.name}
              />

              <Select
                label="Gênero"
                name="gender"
                options={genderOptions}
                value={genderOptions.find(
                  (option) => option.value === formik.values.gender
                )}
                onChange={(option) =>
                  formik.setFieldValue("gender", option?.value || "")
                }
                error={(formik.touched.gender || undefined) && formik.errors.gender}
              />
            </InputGroup>

            <InputGroup>
              <Input
                label="CPF"
                name="cpf"
                mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                value={formik.values.cpf}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={(formik.touched.cpf || undefined) && formik.errors.cpf}
              />

              <Input
                label="RG"
                name="rg"
                value={formik.values.rg}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={(formik.touched.rg || undefined) && formik.errors.rg}
              />
            </InputGroup>

            <InputGroup layout="3fr 3fr 2fr">
              <Input
                label="Celular ou WhatsApp"
                name="whatsapp"
                mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                value={formik.values.whatsapp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  (formik.touched.whatsapp || undefined) && formik.errors.whatsapp
                }
              />

              <Input
                label="Telefone Fixo"
                name="phone"
                mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={(formik.touched.phone || undefined) && formik.errors.phone}
              />

              <DatePicker
                label="Nascimento"
                name="birthDate"
                showYearDropdown
                maxDate={new Date()}
                selected={formik.values.birthDate}
                onChange={(e) => formik.setFieldValue("birthDate", e)}
                onBlur={formik.handleBlur}
                error={
                  (formik.touched.birthDate || undefined) && formik.errors.birthDate
                }
              />
            </InputGroup>

            <InputGroup layout="2fr 5fr 1fr">
              <Input
                label="CEP"
                name="zipCode"
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                value={formik.values.zipCode}
                onChange={formik.handleChange}
                onBlur={handleBlurZipCode}
                error={
                  isCEPValid
                    ? (formik.touched.zipCode || undefined) && formik.errors.zipCode
                    : "CEP Inválido!"
                }
              />

              <Input
                label="Logradouro"
                name="street"
                value={formik.values.street}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={(formik.touched.street || undefined) && formik.errors.street}
                disabled={isLoadingCEPInfo}
              />

              <Input
                label="Número"
                name="number"
                value={formik.values.number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={(formik.touched.number || undefined) && formik.errors.number}
              />
            </InputGroup>

            <InputGroup>
              <Input
                label="Complemento"
                name="complement"
                value={formik.values.complement}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isLoadingCEPInfo}
              />

              <Input
                label="Bairro"
                name="neighborhood"
                value={formik.values.neighborhood}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  (formik.touched.neighborhood || undefined) &&
                  formik.errors.neighborhood
                }
                disabled={isLoadingCEPInfo}
              />

              <Select
                label="Estado"
                name="state"
                options={UFOptions}
                value={
                  UFOptions.find(
                    (uf) =>
                      uf.label.toUpperCase() === formik.values.state.toUpperCase()
                  ) || null
                }
                onChange={(selectedOption) => handleChangeState(selectedOption)}
                error={(formik.touched.state || undefined) && formik.errors.state}
                isDisabled={isLoadingUFOptions || isLoadingCEPInfo}
              />

              <Select
                label="Cidade"
                name="city"
                options={cityOptions}
                value={cityOptions.find(
                  (city) => city.label === formik.values.city
                )}
                onChange={(option) =>
                  formik.setFieldValue("city", option?.label || "")
                }
                error={(formik.touched.city || undefined) && formik.errors.city}
                isDisabled={isLoadingCEPInfo || formik.values.state === ""}
              />
            </InputGroup>

            <Button type="submit">Cadastrar</Button>
          </Form>
        </Wrapper>
      </ContentWrapper>
    </Content>
  );
}
