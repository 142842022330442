import { useState } from 'react';
import { addDays, format } from 'date-fns';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import { Description, Title } from '@components/Header';
import { Button } from '@components/Button';
import { DatePicker } from '@components/DatePicker';
import { Input, InputGroup } from '@components/Input';
import { Select } from '@components/Select';
import { TextArea } from '@components/TextArea';
import { TimeScheduling } from '@components/TimeScheduling';

import { useWindowSize } from '@hooks/useWindowSize';

import { FormikEffect } from '@utils/FormikEffect';

import { OptionTypeBase } from '@components/Select/Select';

import { Content, ContentWrapper, Sidebar, PageContent, ScheduleModal, TimeContainer } from './styles';

interface SearchFormik {
  professional: OptionTypeBase | null;
  date: Date | null;
}

interface ScheduleFormik {
  time: Date | null;
  professional: OptionTypeBase | null;
  patient: OptionTypeBase | null;
  consultationType: OptionTypeBase | null;
  scheduleBy: OptionTypeBase | null;
  specialty: OptionTypeBase | null;
  consultationReason: OptionTypeBase | null;
  details: string;
}

const consultationTypeOptions: OptionTypeBase[] = [
  { label: 'Particular', value: 'Particular' },
  { label: 'Convênio', value: 'Convênio' },
  { label: 'Contrato', value: 'Contrato' },
];

const scheduleByOptions: OptionTypeBase[] = [
  { label: 'WhatsApp', value: 'WhatsApp' },
  { label: 'Ligação', value: 'Ligação' },
  { label: 'Promotora', value: 'Promotora' },
];

const consultationReasonOptions: OptionTypeBase[] = [
  { label: 'Motivo 01', value: 'Motivo 01' },
  { label: 'Motivo 02', value: 'Motivo 02' },
  { label: 'Motivo 03', value: 'Motivo 03' },
];

export function DigitalSchedule() {
  const [windowWidth] = useWindowSize();

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [isScheduleModalVisible, setIsScheduleModalVisible] = useState(false);

  const [patientOptions] = useState<OptionTypeBase[]>([
    { label: 'Fulano da Silva', value: 'Fulano da Silva' },
    { label: 'Ciclano Alencar', value: 'Ciclano Alencar' },
    { label: 'Beltrano Oliveira', value: 'Beltrano Oliveira' },
  ]);

  const [specialtyOptions] = useState<OptionTypeBase[]>([
    { label: 'Implantodontia', value: 'Implantodontia' },
    { label: 'Ortodontia', value: 'Ortodontia' },
  ]);

  const searchFormik = useFormik<SearchFormik>({
    initialValues: {
      professional: null,
      date: null,
    },
    validationSchema: Yup.object().shape({
      professional: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um profissional"),
      date: Yup.date()
        .required("O campo é obrigatório")
        .typeError("Selecione uma data"),
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values));
    }
  });

  const scheduleFormik = useFormik<ScheduleFormik>({
    enableReinitialize: true,
    initialValues: {
      time: selectedDate,
      professional: searchFormik.values.professional,
      patient: null,
      consultationType: null,
      scheduleBy: null,
      specialty: null,
      consultationReason: null,
      details: '',
    },
    validationSchema: Yup.object().shape({
      time: Yup.date()
        .required("O campo é obrigatório")
        .typeError("Selecione um horário"),
      professional: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um profissional"),
      patient: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um paciente"),
      consultationType: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione o tipo da consulta"),
      scheduleBy: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione o meio de agendamento"),
      specialty: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione uma especialidade"),
      consultationReason: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione o motivo da consulta"),
      details: Yup.string(),
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values));
    }
  });

  return (
    <Content>
      <ScheduleModal
        visible={isScheduleModalVisible}
        title="Novo agendamento"
        onRequestClose={() => setIsScheduleModalVisible(false)}
      >
        <form onSubmit={scheduleFormik.handleSubmit}>
          <FormikEffect formik={scheduleFormik} />

          <InputGroup>
            <DatePicker 
              label='Data da Consulta'
              name='consultationDate'
              selected={scheduleFormik.values.time}
              onChange={() => {}}
              isClearable={false}
              disabled={true}
              error={(scheduleFormik.touched.time || undefined) && scheduleFormik.errors.time} 
            />

            <Input 
              label='Horário da Consulta'
              name='consultationTime'
              value={scheduleFormik.values.time ? format(scheduleFormik.values.time, 'HH:mm') : ''}
              disabled={true}
              error={(scheduleFormik.touched.time || undefined) && scheduleFormik.errors.time} 
            />
          </InputGroup>

          <InputGroup>
            <Select 
              label="Profissional"
              name="professional"
              value={scheduleFormik.values.professional}
              isDisabled={true}
              error={(scheduleFormik.touched.professional || undefined) && scheduleFormik.errors.professional} 
            />

            <Select 
              label="Paciente"
              name="patient"
              options={patientOptions}
              value={scheduleFormik.values.patient}
              onChange={option => scheduleFormik.setFieldValue('patient', option)}
              error={(scheduleFormik.touched.patient || undefined) && scheduleFormik.errors.patient} 
            />
          </InputGroup>

          <InputGroup>
            <Select 
              label="Tipo de Consulta"
              name="consultationType"
              options={consultationTypeOptions}
              value={scheduleFormik.values.consultationType}
              onChange={option => scheduleFormik.setFieldValue('consultationType', option)}
              error={(scheduleFormik.touched.consultationType || undefined) && scheduleFormik.errors.consultationType} 
            />

            <Select 
              label="Agendado via"
              name="scheduleBy"
              options={scheduleByOptions}
              value={scheduleFormik.values.scheduleBy}
              onChange={option => scheduleFormik.setFieldValue('scheduleBy', option)}
              error={(scheduleFormik.touched.scheduleBy || undefined) && scheduleFormik.errors.scheduleBy} 
            />
          </InputGroup>

          <InputGroup>
            <Select 
              label="Especialidade"
              name="specialty"
              options={specialtyOptions}
              value={scheduleFormik.values.specialty}
              onChange={option => scheduleFormik.setFieldValue('specialty', option)}
              error={(scheduleFormik.touched.specialty || undefined) && scheduleFormik.errors.specialty} 
            />

            <Select 
              label="Motivo da Consulta"
              name="consultationReason"
              options={consultationReasonOptions}
              value={scheduleFormik.values.consultationReason}
              onChange={option => scheduleFormik.setFieldValue('consultationReason', option)}
              error={(scheduleFormik.touched.consultationReason || undefined) && scheduleFormik.errors.consultationReason} 
            />
          </InputGroup>

          <TextArea
            label="Observações"
            name="details"
            value={scheduleFormik.values.details}
            onChange={scheduleFormik.handleChange}
            error={(scheduleFormik.touched.details || undefined) && scheduleFormik.errors.details} 
          />

          <Button type="submit">Confirmar</Button>
        </form>
      </ScheduleModal>
      
      <ContentWrapper className='content'>
        <Title>Agenda Digital</Title>
        <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam massa sapien velit ipsum, ac.
        </Description>

        <PageContent>
          <Sidebar>
            <FormikEffect formik={searchFormik} />

            <Select 
              label="Profissional"
              name="professional"
              options={[
                { label: 'Dr. Roberto Nunes', value: 'Dr. Roberto Nunes', },
                { label: 'Dr. Marcos Araújo', value: 'Dr. Marcos Nunes', },
                { label: 'Dr. Pedro Carmo', value: 'Dr. Pedro Pereira', },
              ]}
              value={searchFormik.values.professional}
              onChange={option => {
                searchFormik.setFieldValue('professional', option);
                searchFormik.setFieldValue('date', null);
              }}
              error={(searchFormik.touched.professional || undefined) && searchFormik.errors.professional}
            />

            <DatePicker 
              label="Data"
              name="date"
              selected={searchFormik.values.date}
              onChange={date => {
                searchFormik.setFieldValue('date', date);
                searchFormik.setFieldValue('time', '00:00');
              }}
              open={!!searchFormik.values.professional}
              popperPlacement="bottom-start"
              minDate={new Date()}
              excludeDates={[addDays(new Date(), 13)]}
              error={(searchFormik.touched.date || undefined) && searchFormik.errors.date}
              disabled={!searchFormik.values.professional}
            />
          </Sidebar>
          
          <TimeContainer>
            {searchFormik.values.professional === null
              ? 'Selecione um profissional'
              : searchFormik.values.date === null
              ? 'Selecione uma data'
              : (
                <>
                  <TimeScheduling 
                    initialDate={searchFormik.values.date}
                    daysPerPage={windowWidth <= 576 ? 3 : 5}
                    consultationDuration='00:30:00'
                    schedule={[
                      { weekDay: 1, start: '07:00:00', end: '12:00:00' },
                      { weekDay: 2, start: '07:00:00', end: '12:00:00' },
                      { weekDay: 3, start: '07:00:00', end: '12:00:00' },
                      { weekDay: 4, start: '07:00:00', end: '12:00:00' },
                      { weekDay: 5, start: '07:00:00', end: '12:00:00' },
                    ]}
                    specials={[
                      { date: addDays(new Date(), 5), start: '12:00', end: '17:00' },
                    ]}
                    blockedTimes={[addDays(new Date(), 1  )]}
                    selectedDate={selectedDate}
                    onChangeSelectedDate={(selectedDate) => setSelectedDate(selectedDate)}
                  />

                  <Button 
                    type="submit"
                    onClick={() => setIsScheduleModalVisible(true)}
                    disabled={selectedDate === null}
                  >
                    Agendar
                  </Button>
                </>
            )}
          </TimeContainer>
        </PageContent>
      </ContentWrapper>
    </Content>
  );
}