import styled from "styled-components";

import { convertToRem } from "@utils/convertToRem";


export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) => theme.colors.neutral.borderLight};
  color: ${({ theme }) => theme.colors.neutral.text};

  width: 100%;
  min-height: ${convertToRem(90)};

  font-weight: 500;
  font-size: ${convertToRem(12)};
  line-height: 1.2;
`;