import faker from "@faker-js/faker";
import { subYears } from "date-fns";

import HttpClient from "./utils/HttpClient";

import { delay } from "@utils/delay";

export interface Payment {
  id: string;
  date: Date;
  amount: number;
}

interface ListBanksResponse {
  nome: string;
  conta: string;
  agencia: string;
  cpf_titular: string;
}

class FinanceService {
  private payments: Payment[];

  constructor() {
    this.payments = [];

    for (let i = 0; i < 20; i++) {
      const { datatype } = faker;

      this.payments.push({
        id: datatype.uuid(),
        date: datatype.datetime({ 
          min: subYears(new Date(), 3).getTime(),
          max: new Date().getTime()
         }),
        amount: datatype.float({ min: 700, max: 2000, precision: 1 }),
      });
    }
  }

  async listPayments(): Promise<Payment[]> {
    await delay(500);

    return new Promise((resolve) => {
      resolve(this.payments);
    });
  }

  async listBanks() {
    return HttpClient.get<ListBanksResponse[]>('/base/bancos');
  }
}

export default new FinanceService();