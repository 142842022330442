import styled from "styled-components";
import chroma from "chroma-js";

import { Container as Select } from "@components/Select/styles";
import { Container as Switch } from "@components/Switch/styles";
import { Container as Slider } from "@components/Slider/styles";
import { Button } from "@components/Button";

import { convertToRem } from "@utils/convertToRem";

export const LoadingFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: calc(100vh - ${convertToRem(80)});

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.text};

  h4 {
    margin-top: ${convertToRem(20)};
  }
`;

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: ${convertToRem(40)} 2fr 2.5fr 3fr 2fr 2fr 3fr;

  min-height: ${convertToRem(50)};

  margin-top: ${convertToRem(-1)} !important;

  ${Select} {
    [class$="control"] {
      border-radius: 0;
      border-right: 0;
    }

    [class$="placeholder"] {
      color: ${({ theme }) => theme.colors.neutral.text};

      font-weight: 500;
      font-size: ${convertToRem(13)};
    }
  }

  ${Switch} {
    flex-direction: row-reverse;

    padding: 0 ${convertToRem(20)};

    border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.borderDark};
    border-right: 0;

    span {
      margin: 0;
      margin-right: auto;

      color: ${({ theme }) => theme.colors.neutral.text};
    }
  }
`;

export const FilterIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary.base};

  > svg {
    stroke-width: 1.25;
  }
`;

export const FilterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 ${convertToRem(20)};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.borderDark};

  > div {
    display: flex;
    flex-direction: column;

    height: 100%;

    > span {
      margin-top: ${convertToRem(10)};
      margin-left: auto;

      color: ${({ theme }) => theme.colors.primary.highlight};

      font-weight: 500;
      font-size: ${convertToRem(9)};
      line-height: 1;
    }
    
    ${Slider} {
      flex: 1;
  
      margin-top: ${convertToRem(-4)};
      margin-left: ${convertToRem(12)};
      margin-bottom: auto;
    }
  }
`;

export const FilterLabel = styled.span`
  color: ${({ theme }) => theme.colors.neutral.text};

  font-weight: 500;
  font-size: ${convertToRem(13)};
  line-height: 1;
`;

export const ContentWrapper = styled.div`
  flex: 1;

  position: relative;

  display: grid;
  grid-template-columns: 8fr 4fr;
  gap: ${convertToRem(30)};

  padding-top: ${convertToRem(40)};
  padding-bottom: ${convertToRem(200)};
`;

export const TitleContainer = styled.div`
  display: grid;
  grid-template-columns: 8fr 4fr;
  gap: ${convertToRem(30)};

  > span {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    color: ${({ theme }) => theme.colors.neutral.text};

    font-size: ${convertToRem(15)};
    line-height: 1;
    
    svg {
      width: auto;
      height: ${convertToRem(16)};

      margin-right: ${convertToRem(10)};
    }
  }
`;

export const SidebarContainer = styled.div`
  height: fit-content;

  padding-bottom: ${convertToRem(30)};

  > h3 {
    margin-top: ${convertToRem(40)};

    color: ${({ theme }) => theme.colors.neutral.text};

    font-weight: 500;
    font-size: ${convertToRem(20)};
    line-height: 1;
  }
`;

export const SidebarTitle = styled.h2`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  width: 100%;
  height: ${convertToRem(56)};

  margin-bottom: ${convertToRem(30)};

  color: ${({ theme }) => theme.colors.neutral.text};

  font-weight: 400;
  font-size: ${convertToRem(15)};
  line-height: 1;
  
  svg {
    width: auto;
    height: ${convertToRem(16)};

    margin-right: ${convertToRem(10)};
  }
`;

export const LoadingNextConsultationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: ${convertToRem(40)} 0;
`;

export const NextConsultationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  overflow: hidden;
`;

export const NextConsultationCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: ${convertToRem(100)};

  padding: 0 ${convertToRem(12)};

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.text};

  border: solid ${convertToRem(2)} ${({ theme }) => chroma(theme.colors.primary.highlight).alpha(0.3).css()};
  border-radius: ${convertToRem(5)};

  cursor: pointer;
  opacity: 0.4;
  transition: all ease 0.5s;

  &:hover {
    opacity: 1;
  }

  &.active {
    position: relative;

    opacity: 1;

    border-width: ${convertToRem(1)};

    &::after {
      content: '';
      
      position: absolute;
      left: ${convertToRem(4)};
      right: ${convertToRem(4)};
      bottom: 0;

      height: ${convertToRem(2)};

      background-color: ${({ theme }) => theme.colors.primary.highlight};
      
      border-radius: ${convertToRem(1)};
    }

    &:hover {
      box-shadow: 0 ${convertToRem(20)} ${convertToRem(10)} ${convertToRem(-10)} ${({ theme }) => chroma(theme.colors.primary.dark).alpha(0.05).css()};
    }
  }

  &:not(:first-of-type) {
    margin-top: ${convertToRem(12)};
  }
`;

export const NextConsultationAvatar = styled.img`
  width: ${convertToRem(70)};
  height: ${convertToRem(70)};

  margin-right: ${convertToRem(18)};

  object-fit: cover;

  border: solid ${convertToRem(2)} ${({ theme }) => theme.colors.neutral.borderLight};
  border-radius: 50%;
`;

export const NextConsultationName = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  h4 {
    color: ${({ theme }) => theme.colors.neutral.text};

    font-size: ${convertToRem(20)};
    line-height: 1;
  }

  h5 {
    margin-top: ${convertToRem(5)};

    color: ${({ theme }) => theme.colors.primary.highlight};

    font-size: ${convertToRem(10)};
    line-height: 1;
  }
`;

export const NextConsultationDate = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  margin-left: auto;

  .day {
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${convertToRem(60)};
    height: ${convertToRem(35)};

    background-color: ${({ theme }) => theme.colors.primary.lightest};
    color: ${({ theme }) => theme.colors.primary.base};

    font-weight: 40;
    font-size: ${convertToRem(18)};
    line-height: 1;

    border-radius: ${convertToRem(5)};
  }

  .month {
    margin-top: ${convertToRem(10)};

    font-weight: 400;
    font-size: ${convertToRem(12)};
    line-height: 1;
  }
`;

export const SeeAllConsultationsButton = styled(Button)`
  width: 100%;
  height: ${convertToRem(66)};

  margin-top: ${convertToRem(12)};

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.primary.highlight};

  font-weight: 600;
  font-size: ${convertToRem(16)};
  line-height: 1;

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
  border-radius: 0;


  &:hover {
    background-color: ${({ theme }) => theme.colors.neutral.white};
    color: ${({ theme }) => theme.colors.primary.highlight};
  }
`;