import styled from "styled-components";
import chroma from "chroma-js";

import { Modal, Subtitle, List } from "@components/Modal";
import { Container as Button } from "@components/Button/styles";
import { InputGroup } from "@components/Input/styles";
import { Container as Select } from "@components/Select/styles";
import { Container as ResultCard, ResponsiveCard } from "@components/ResultCard/styles";
import { Container as LoadingContainer } from "@/components/Loading/styles";

import { convertToRem } from "@utils/convertToRem";

export const FilterModal = styled(Modal)`
  form {
    width: 100%;

    > ${Button} {
      margin-top: ${convertToRem(48)};
      margin-left: auto;
    }
  }

  @media (max-width: 500px) {
    ${InputGroup} {
      grid-template-columns: 1fr;
    }
  }
`;

export const DetailsModal = styled(Modal)`
  max-width: ${convertToRem(700)};
  
  form {
    width: 100%;

    > ${Button} {
      margin-top: ${convertToRem(48)};
      margin-left: auto;
    }
  }

  @media (max-width: 600px) {
    ${InputGroup} {
      grid-template-columns: 1fr;
    }
  }
`;

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(40)};
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: ${convertToRem(30)};

  margin-top: ${convertToRem(48)};

  ${Select} {
    &:nth-of-type(1) {
      max-width: ${convertToRem(240)};
    }

    &:nth-of-type(2) {
      max-width: ${convertToRem(120)};
    }
  }

  ${Button} {
    min-width: auto;
    min-height: auto;
    
    height: ${convertToRem(40)};

    padding: 0 ${convertToRem(12)};

    font-size: ${convertToRem(13)};

    > svg {
      margin-left: 0;
      margin-right: ${convertToRem(8)};
    }

    > ${LoadingContainer} {
      width: 1rem;
      height: 1rem;

      margin-left: 0;
      margin-right: ${convertToRem(8)};
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;

    ${Select} {
      max-width: none !important;
    }
  }
`;

export const ResultsContainer = styled.div`
  margin-top: ${convertToRem(30)};

  > ${ResultCard} {   
    &:not(:first-child) {
      margin-top: ${convertToRem(10)};
    }

    > div {
      &:last-of-type {
        max-width: ${convertToRem(220)};
      }
    }
  }

  @media (max-width: 992px) {
    ${ResultCard} {
      ${ResponsiveCard};
    }
  }
`;

export const DataModal = styled(Modal)`
  ${Subtitle} {
    margin-top: ${convertToRem(30)};
  }

  ${List} {
    width: 100%;

    float: left;

    > li {
      width: 100%;

      float: left;

      padding: 0.5rem 0;
      margin-bottom: 0;

      border-top: ${convertToRem(1)} solid rgba(0,0,0,0.09);

      &:first-of-type {
        border-top: 0;
      }

      > strong {
        max-width: 100%;

        float: left;

        word-wrap: break-word;
      }

      > span {
        max-width: 100%;

        float: right;

        word-wrap: break-word;

        margin-left: 0;
      }
    }
  }
`;

export const Footer = styled.footer`
  width: 100%;
  height: ${convertToRem(100)};

  margin-top: ${convertToRem(60)};

  border-top: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

  .content {
    display: flex;
    align-items: center;
  }
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-left: auto;
`;

export const PageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${convertToRem(40)};
  height: ${convertToRem(40)};

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.primary.base};

  border: ${convertToRem(1)} solid ${({ theme }) => theme.colors.neutral.border};
  border-radius: ${convertToRem(5)};

  &.prev {
    transform: rotate(90deg);
  }

  &.next {
    transform: rotate(270deg);
  }
  
  &:disabled {
    background-color: ${({ theme }) => chroma(theme.colors.neutral.border).brighten(0.4).css()};

    cursor: not-allowed;
  }

  > svg {
    width: auto;
    height: ${convertToRem(20)};
    
    stroke-width: 1.2;
  }
`;

export const Page = styled.span`
  margin: 0 ${convertToRem(20)};
  
  color: ${({ theme }) => theme.colors.neutral.text};

  font-weight: 500;
  font-size: ${convertToRem(15)};
  line-height: 1;
`;

