import ReactSelect, { GroupBase, StylesConfig } from "react-select";
import { useTheme } from "styled-components";

import { convertToRem } from "@utils/convertToRem";

import { OptionTypeBase, SelectProps } from "./Select.d";

import { Container, Error } from "./styles";


export function Select<
  OptionType =  OptionTypeBase,
  IsMulti extends boolean = false,
  Group extends GroupBase<OptionType> = GroupBase<OptionType>
>({
  label,
  placeholder = "",
  customHeight = convertToRem(40),
  error,
  isDisabled = false,
  ...rest
}: SelectProps<OptionType, IsMulti, Group>) {
  const theme = useTheme();

  const customStyles: StylesConfig<OptionType, IsMulti, Group> = {
    container: (provided, state) => ({
      ...provided,

      width: "100%",
    }),
    control: (provided, state) => ({
      ...provided,

      height: state.isMulti ? 'auto' : customHeight,
      minHeight: customHeight,

      padding: state.isMulti ? `${convertToRem(9)} ${convertToRem(14)}` : `0 ${convertToRem(14)}`,
      paddingRight: convertToRem(6),

      backgroundColor: state.isDisabled ? theme.colors.neutral.borderLight : theme.colors.neutral.white,
      color: theme.colors.neutral.text,

      fontSize: convertToRem(14),
      lineHeight: 1,

      border: `solid ${convertToRem(1)} ${error ? theme.colors.helpers.danger : theme.colors.neutral.borderDark}`,
      boxShadow: "0",
      
      transition: 'all ease 0.3s',

      "&:hover": {
        border: `solid ${convertToRem(1)} ${error ? theme.colors.helpers.danger : theme.colors.neutral.borderDark}`,
      },
    }),
    option: (provided, state) => ({
      ...provided,

      display: "flex",
      alignItems: "center",
      justifyContent: "flex-start",

      minHeight: convertToRem(40),

      padding: `${convertToRem(8)} ${convertToRem(14)}`,

      backgroundColor: state.isSelected
        ? theme.colors.primary.base
        : state.isFocused
        ? theme.colors.neutral.borderLight
        : undefined
      ,
      color: state.isSelected
        ? theme.colors.neutral.white
        : theme.colors.neutral.text
      ,

      fontSize: convertToRem(14),
      lineHeight: 1,

      ":active": {
        ...provided[":active"],

        backgroundColor: state.isSelected
          ? theme.colors.primary.base
          : theme.colors.neutral.borderLight,
      },
    }),
    singleValue: (provided, state) => ({
      ...provided,

      color: theme.colors.neutral.text,
    }),
    multiValue: (provided) => ({
      ...provided,

      padding: `${convertToRem(8)} ${convertToRem(12)}`,

      backgroundColor: theme.colors.neutral.text,

      borderRadius: convertToRem(20),
    }),
    multiValueLabel: (provided) => ({
      ...provided,

      color: theme.colors.neutral.white,

      fontWeight: 500,
      fontSize: convertToRem(10),
      lineHeight: convertToRem(18),
    }),
    multiValueRemove: (provided) => ({
      ...provided,

      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      backgroundColor: theme.colors.neutral.text,
      color: theme.colors.neutral.white,

      marginLeft: convertToRem(8),

      "svg": {
        strokeWidth: '1.2',
      },

      ":hover": {
        backgroundColor: theme.colors.neutral.text,
        color: theme.colors.helpers.danger,
      },
    }),
    input: (provided) => ({
      ...provided,

      margin: 0,
    }),
    valueContainer: (provided, state) => ({
      ...provided,

      height: state.isMulti ? 'auto' : convertToRem(40),

      padding: 0,
    }),
    indicatorSeparator: (provided, state) => ({
      ...provided,

      height: convertToRem(20),

      margin: "auto 0",

      backgroundColor: theme.colors.neutral.border,
    }),
    indicatorsContainer: (provided) => ({
      ...provided,

      height: '100%',

      margin: 'auto 0',
      padding: 0,

      cursor: "pointer",

      "svg": {
        fill: theme.colors.primary.base,
      },

      "> div": {
        display: "flex",
        alignItems: "center",
        
        height: convertToRem(20),

        paddingTop: 0,
        paddingBottom: 0
      },
    }),
    menuList: (provided) => ({
      ...provided,

      maxHeight: convertToRem(200),

      padding: 0,

      "::-webkit-scrollbar": {
        width: convertToRem(8),
      },
      
      "::-webkit-scrollbar-thumb": {
        backgroundColor: theme.colors.neutral.borderDark,
        
        borderRadius: convertToRem(10),
      },
    }),
    noOptionsMessage: (provided) => ({
      ...provided,

      fontSize: convertToRem(14),
      lineHeight: 1,
    }),
  };

  return (
    <Container style={{ cursor: isDisabled ? 'not-allowed' : 'initial' }}>
      {label ? <label>{label}</label> : null}

      <ReactSelect
        isClearable
        placeholder={placeholder}
        noOptionsMessage={() => "Opção não encontrada"}
        isDisabled={isDisabled}
        styles={customStyles}
        {...rest}
      />

      {error ? <Error>{error}</Error> : null}
    </Container>
  );
}
