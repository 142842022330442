import styled from "styled-components";

import { Container as Callout } from "@components/Callout/styles";
import { Container as Button } from "@components/Button/styles";

import { convertToRem } from "@utils/convertToRem";

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: calc(100vh - ${convertToRem(80)});

  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const DependentNotFoundContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: calc(100vh - ${convertToRem(80)} - ${convertToRem(50)} - ${convertToRem(40)});

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.text};

  a {
    margin-top: ${convertToRem(10)};

    font-weight: 500;
    font-size: ${convertToRem(14)};
    color: ${({ theme }) => theme.colors.primary.highlight};
  }
`;

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  padding-top: ${convertToRem(40)};
`;

export const Main = styled.div`
  display: grid;
  grid-template-columns: 8fr 4fr;
  gap: ${convertToRem(30)};

  margin-top: ${convertToRem(30)};
`;

export const Form = styled.form`
  min-height: 100vh;

  padding: ${convertToRem(50)} 0 ${convertToRem(100)};

  ${Callout} {
    margin-top: ${convertToRem(120)};

    strong {
      margin-left: ${convertToRem(4)};
      color: ${({ theme }) => theme.colors.primary.highlight};
    }
  }

  ${Button} {
    margin-top: ${convertToRem(80)};
    margin-left: auto;
  }
`;