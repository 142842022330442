import { useEffect } from "react";
import { toast } from "react-toastify";

interface FormikEffectProps {
  formik: any;
  onSubmissionError?: () => any;
  message?: string;
}

export function FormikEffect(props: FormikEffectProps) {
  useEffect(() => {
    if (props.formik.isValid || props.formik.submitCount <= 0) return;

    if (props.onSubmissionError) {
      props.onSubmissionError();
      return;
    }

    toast.info(props.message || 'Erro de validação! Por favor, confira os campos do formulário.');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.formik.submitCount]);
  
  return null;
}