import styled from "styled-components";
import chroma from "chroma-js";

import { convertToRem } from "@utils/convertToRem";

export const Container = styled.div`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  margin-top: ${convertToRem(15)};
  padding: ${convertToRem(40)} ${convertToRem(30)} 0;

  background-color: ${({ theme }) => theme.colors.primary.highlight};
  color: ${({ theme }) => theme.colors.neutral.white};

  border-radius: ${convertToRem(5)};
  overflow: hidden;

  &::after {
    content: "";

    position: absolute;
    right: ${convertToRem(-40)};
    bottom: ${convertToRem(-110)};

    width: ${convertToRem(260)};
    height: ${convertToRem(260)};

    background-color: ${({ theme }) =>
      chroma(theme.colors.primary.highlight).brighten(0.5).css()};

    border-radius: 50%;

    z-index: 0;
  }

  h5 {
    max-width: ${convertToRem(260)};

    font-weight: 500;
    font-size: ${convertToRem(20)};
    line-height: 1.4;
  }

  .button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${convertToRem(130)};
    height: ${convertToRem(40)};

    margin-top: ${convertToRem(14)};

    background-color: ${({ theme }) => theme.colors.neutral.white};
    color: ${({ theme }) => theme.colors.primary.highlight};

    font-weight: 500;
    font-size: ${convertToRem(13)};
    line-height: 1;

    border: 0;
    border-radius: ${convertToRem(5)};
  }

  > svg {
    margin-left: auto;

    z-index: 1;
  }
`;

export const PrimeContainer = styled(Container)`
  padding: ${convertToRem(30)};
  padding-bottom: ${convertToRem(35)};

  background-color: ${({ theme }) => theme.colors.primary.lightest};
  color: ${({ theme }) => theme.colors.neutral.text};

  &::after {
    top: ${convertToRem(30)};
    right: ${convertToRem(30)};

    width: ${convertToRem(124)};
    height: ${convertToRem(124)};

    background-color: ${({ theme }) => theme.colors.neutral.white};
  }

  > * {
    z-index: 1;
  }

  h5 {
    color: ${({ theme }) => theme.colors.primary.highlight};
  }

  li,
  p,
  strong {
    font-size: ${convertToRem(12)};
  }

  ul {
    margin-top: ${convertToRem(5)};
  }

  li:not(:first-child) {
    margin-top: ${convertToRem(10)};
  }

  p {
    margin-top: ${convertToRem(25)};
  }

  strong {
    margin-top: ${convertToRem(5)};
  }

  .button {
    width: auto;
    height: auto;

    margin-top: ${convertToRem(20)};
    padding: ${convertToRem(10)};

    background-color: ${({ theme }) => theme.colors.primary.highlight};
    color: ${({ theme }) => theme.colors.neutral.white};

    font-weight: 400;
  }

  > img {
    position: absolute;
    bottom: ${convertToRem(-50)};
    right: ${convertToRem(-100)};
  }
`;
