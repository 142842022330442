import { createContext, ReactNode, useContext, useState } from "react";

import { OptionTypeBase } from "@components/Select/Select.d";

interface EmployeeProviderProps {
  children: ReactNode;
}

export interface Employee {
  name: string;
  email: string;
  position: OptionTypeBase | null;
  birthDate: Date | null;
  avatar: File | null;
  rg: string;
  cpf: string;
  ctps: string;
  phone: string;
  whatsApp: string;
  paymentDate: Date | null;
  admissionDate: Date | null;
  demissionDate: Date | null;

  transferType: "pix" | "ted";
  bank: string;
  keyType?: OptionTypeBase | null;
  keyValue?: string;
  bankAgency?: string;
  bankAccount?: string;

  address: {
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: OptionTypeBase | null;
    state: OptionTypeBase | null;
    zipCode: string;
  };
}

export interface EmployeeContextProps {
  employee: Employee;
  addEmployeeInfo: (info: Partial<Employee>) => Employee;
  clearEmployeeInfo: () => void;
  validation: {
    isPersonalDataTabEmpty: boolean;
    isBankInfoTabEmpty: boolean;
    isAddressTabEmpty: boolean;
  } 
}

export const EmployeeContext = createContext<EmployeeContextProps>({} as EmployeeContextProps);

export function useEmployee(): EmployeeContextProps {
  const context = useContext(EmployeeContext);

  if (!context) throw new Error('useEmployee must be used within an EmployeeProvider');

  return context;
}

const initialEmployeeData: Employee = {
  name: '',
  email: '',
  position: null,
  birthDate: null,
  avatar: null,
  rg: '',
  cpf: '',
  ctps: '',
  phone: '',
  whatsApp: '',
  paymentDate: null,
  admissionDate: null,
  demissionDate: null,

  transferType: 'pix',
  bank: '',
  keyType: null,
  keyValue: '',
  bankAgency: '',
  bankAccount: '',

  address: {
    zipCode: '',
    street: '',
    number: '',
    complement: '',
    neighborhood: '',
    state: null,
    city: null,
  },
};

export function EmployeeProvider({ children }: EmployeeProviderProps) {
  const [employee, setEmployee] = useState<Employee>(initialEmployeeData);

  function addEmployeeInfo(info: Partial<Employee>) {
    const newEmployee = { ...employee, ...info };

    setEmployee(newEmployee);

    return newEmployee;
  }

  function clearEmployeeInfo() {
    setEmployee(initialEmployeeData);
  }

  // const isGeneralTabEmpty = [employee.name, employee.email, employee.document].includes('') || employee.gender === null || employee.specialties === null || employee.specialties.length <= 0;
  
  // const isSchedulesTabEmpty = employee.consultationDuration === '' || employee.schedule.some(e => !e.isDisabled && (e.times.some(time => time.start === '00:00' || time.end === '00:00')));
  
  // const isSettingsTabEmpty = [employee.councilType, employee.councilUF, employee.receiptType].includes(null) || [employee.councilNumber, employee.whatsapp].includes('') || [employee.normalValue, employee.primeValue].includes(0);
  
  // const isBankInfoTabEmpty = employee.transferType === 'pix'
  //   ? !employee.keyType || employee.keyValue === ''
  //   : !employee.bank || [employee.bankAgency, employee.bankAccount, employee.bankCpf].includes('')
  // ;
  
  // const isAddressTabEmpty = [employee.address.zipCode, employee.address.street, employee.address.complement, employee.address.neighborhood].includes('') || [employee.address.state === null, employee.address.city].includes(null);

  const validation = {
    isPersonalDataTabEmpty: false,
    isBankInfoTabEmpty: false,
    isAddressTabEmpty: false,
  };

  return (
    <EmployeeContext.Provider value={{ employee, addEmployeeInfo, clearEmployeeInfo, validation }}>
      {children}
    </EmployeeContext.Provider>
  );
}