import styled from "styled-components";
import chroma from "chroma-js";

import { Container as Button } from "@components/Button/styles";
import { ItemContainer } from "@components/ResultCard/styles";
import { TabsContainer } from "@components/Tabs";
import { Container as Input } from "@components/Input/styles";

import { convertToRem } from "@utils/convertToRem";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    padding: ${convertToRem(40)} ${convertToRem(40)} 0;

    background-color: ${({ theme }) => theme.colors.neutral.white};

    border-bottom: solid ${convertToRem(1.5)} ${({ theme }) => theme.colors.neutral.border};

    .close-btn {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-left: auto;

      background: none;
      color: ${({ theme }) => theme.colors.neutral.text};

      border: 0;

      svg {
        width: auto;
        height: ${convertToRem(20)};
      }
    }

    h2 {
      margin-top: ${convertToRem(20)};
      
      font-size: ${convertToRem(20)};
    }

    ${TabsContainer} {
      width: auto;

      margin-top: ${convertToRem(50)};

      border-bottom: 0;
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    padding: ${convertToRem(50)} ${convertToRem(70)};

    background-color: ${({ theme }) => theme.colors.neutral.borderLight};

    .info-group {
      display: flex;

      width: 100%;

      > *:not(:last-child) {
        margin-right: auto;
      }

      & + .info-group {
        margin-top: ${convertToRem(30)};
      }
    }

    .label {
      color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.5).css()};

      font-weight: 700;
      font-size: ${convertToRem(13)};
    }

    .value {
      display: block;

      margin-top: ${convertToRem(8)};

      font-weight: 500;
      font-size: ${convertToRem(15)};
    }

    ${Input} {
      width: 100%;

      max-width: ${convertToRem(480)};
    }

    ${Button} {
      margin-top: ${convertToRem(50)};
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: calc(100vh - ${convertToRem(80)});

  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(40)};
`;

export const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 2fr 6fr;
  gap: ${convertToRem(30)};

  margin-top: ${convertToRem(28)};

  ${Button} {
    align-self: flex-end;

    margin-left: auto;
  }
`;

export const ResultsContainer = styled.div`
  margin-top: ${convertToRem(30)};

  > div {   
    &:not(:first-child) {
      margin-top: ${convertToRem(10)};
    }

    > div {
      flex: 1;

      &:last-child {
        flex: 0.75;
      }
    }

    ${ItemContainer}.buttons {
      justify-content: flex-start;

      > *:not(:first-child) {
        margin-left: ${convertToRem(15)};
      }
      
      ${Button} {
        min-width: ${convertToRem(120)};
      }
    }
  }

`;

export const Footer = styled.footer`
  width: 100%;
  height: ${convertToRem(100)};

  margin-top: ${convertToRem(60)};

  border-top: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

  .content {
    display: flex;
    align-items: center;
  }
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-left: auto;
`;

export const PageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${convertToRem(40)};
  height: ${convertToRem(40)};

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.primary.base};

  border: ${convertToRem(1)} solid ${({ theme }) => theme.colors.neutral.border};
  border-radius: ${convertToRem(5)};

  &.prev {
    transform: rotate(90deg);
  }

  &.next {
    transform: rotate(270deg);
  }
  
  &:disabled {
    background-color: ${({ theme }) => chroma(theme.colors.neutral.border).brighten(0.4).css()};

    cursor: not-allowed;
  }

  > svg {
    width: auto;
    height: ${convertToRem(20)};
    
    stroke-width: 1.2;
  }
`;

export const Page = styled.span`
  margin: 0 ${convertToRem(20)};
  
  color: ${({ theme }) => theme.colors.neutral.text};

  font-weight: 500;
  font-size: ${convertToRem(15)};
  line-height: 1;
`;

