import { Navigate } from "react-router-dom";

import { Tabs } from "@components/Tabs";

// Pages
import { RegisterLeads } from "./Register";
import { HistoryLeads } from "./History";

import { Container } from "./styles";

export function Leads() {
  return (
    <Container>
      <Tabs
        tabs={[
          { path: '/', element: <Navigate to="cadastro" /> },
          { title: 'Cadastro', path: 'cadastro', element: <RegisterLeads /> },
          { title: 'Histórico', path: 'historico', element: <HistoryLeads /> },
        ]}
      />
    </Container>
  );
}