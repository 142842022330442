import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { TooltipProps } from "./Tooltip";

import { Content, TooltipArrow } from "./styles";

export function Tooltip({
  children,
  content,
  disabled = false,
  side = 'top',
  open,
  defaultOpen,
  onOpenChange,
  asChild = true,
  ...props 
}: TooltipProps) {

  if (disabled) return <>{children}</>

  return (
    <TooltipPrimitive.Root
      open={open}
      defaultOpen={defaultOpen}
      onOpenChange={onOpenChange}
    >
      <TooltipPrimitive.Trigger asChild={asChild}>
        {children}
      </TooltipPrimitive.Trigger>

      <Content side={side} sideOffset={8} {...props}>
        {content}
        
        <TooltipArrow />
      </Content>
    </TooltipPrimitive.Root>
  );
}