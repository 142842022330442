import styled from "styled-components";

import { Container as Input } from "@components/Input/styles";
import { Container as ResultCard } from "@components/ResultCard/styles";

import { convertToRem } from "@utils/convertToRem";

export const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: calc(100vh - ${convertToRem(140)});
`;

export const Container = styled.div`
  padding-bottom: ${convertToRem(30)};
`;

export const IndicationLink = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: ${convertToRem(32)};

  background-color: ${({ theme }) => theme.colors.neutral.white};
    
  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
  border-radius: ${convertToRem(8)};

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    background: none;
    color: ${({ theme }) => theme.colors.primary.base};

    font-weight: 500;
    font-size: ${convertToRem(14)};

    border: 0;

    > svg {
      width: auto;
      height: ${convertToRem(18)};

      margin-right: ${convertToRem(4)};
    }
  }

  header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    h2 {
      color: ${({ theme }) => theme.colors.neutral.textDark};
  
      font-size: ${convertToRem(18)};
    }
  
    span {
      margin-left: ${convertToRem(6)};
      padding: ${convertToRem(5)} ${convertToRem(10)};
  
      background-color: ${({ theme }) => theme.colors.neutral.border};
      color: ${({ theme }) => theme.colors.neutral.text};
  
      font-weight: 600;
      font-size: ${convertToRem(13)};
  
      border-radius: ${convertToRem(6)};
    }

    button {
      display: none;
    }
  }

  .link {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    margin-left: auto;

    ${Input} {
      width: ${convertToRem(300)};
    }
  
    button {
      margin-left: ${convertToRem(16)};
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;

    h2 {
      font-size: ${convertToRem(16)} !important;
    }

    header {
      width: 100%;

      justify-content: flex-start;

      button {
        display: flex;

        font-size: ${convertToRem(13)};

        margin-left: auto;
      }
    }
    
    .link {
      flex-direction: column;
      justify-content: flex-start;

      margin-left: 0;
      margin-top: ${convertToRem(24)};
      
      width: 100%;

      ${Input} {
        flex: 1;

        width: 100%;
      }

      > button {
        display: none;
      }
    }
  }
`;

export const ResumeCards = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${convertToRem(30)};

  margin-top: ${convertToRem(30)};

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    padding: ${convertToRem(24)} ${convertToRem(32)};

    background-color: ${({ theme }) => theme.colors.neutral.white};
    
    border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
    border-radius: ${convertToRem(8)};

    cursor: pointer;
    transition: border-color ease 0.5s;

    span {
      color: ${({ theme }) => theme.colors.neutral.text};

      font-size: ${convertToRem(18)};

      text-transform: uppercase;
    }

    strong {
      margin-top: ${convertToRem(12)};

      color: ${({ theme }) => theme.colors.neutral.textDark};

      font-weight: 500;
      font-size: ${convertToRem(36)};
    }

    &:hover {
      border-color: ${({ theme }) => theme.colors.neutral.text};
    }

    &.approved {
      span, strong {
        color: ${({ theme }) => theme.colors.helpers.success};
      }

      &:hover {
        border-color: ${({ theme }) => theme.colors.helpers.success};
      }
    }

    &.pending {
      span, strong {
        color: ${({ theme }) => theme.colors.helpers.warning};
      }

      &:hover {
        border-color: ${({ theme }) => theme.colors.helpers.warning};
      }
    }

    &.rejected {
      span, strong {
        color: ${({ theme }) => theme.colors.helpers.danger};
      }

      &:hover {
        border-color: ${({ theme }) => theme.colors.helpers.danger};
      }
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const ChartContainer = styled.div`
  display: grid;
  grid-template-columns: auto 300px;

  margin-top: ${convertToRem(30)};
  
  background-color: ${({ theme }) => theme.colors.neutral.white};
    
  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
  border-radius: ${convertToRem(8)};

  overflow: hidden;
  
  .chart-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    
    padding: ${convertToRem(32)};

    h1 {
      font-size: ${convertToRem(19)};
    }

    .legend {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      margin-top: ${convertToRem(8)};
      margin-left: auto;

      > span {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        color: ${({ theme }) => theme.colors.neutral.text};

        font-size: ${convertToRem(12)};
        line-height: 1;

        .line {
          display: block;

          width: ${convertToRem(16)};
          height: ${convertToRem(2)};

          margin-right: ${convertToRem(4)};

          border-radius: ${convertToRem(16)};
        }

        &:not(:first-child) {
          margin-left: ${convertToRem(16)};
        }
      }

      .leads .line {
        background-color: ${({ theme }) => theme.colors.primary.highlight};
      }

      .average .line {
        background-color: ${({ theme }) => theme.colors.primary.light};
      }
    }

    .chart {
      width: 100%;

      margin-top: ${convertToRem(54)};

      font-size: ${convertToRem(12)};
    }
  }

  .resume {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    border-left: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

    > div {
      flex: 1;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      width: 100%;

      border-bottom: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

      span {
        color: ${({ theme }) => theme.colors.neutral.text};

        font-size: ${convertToRem(16)};

        text-align: center;
      }

      strong {
        margin-top: ${convertToRem(6)};
        
        color: ${({ theme }) => theme.colors.neutral.textDark};

        font-weight: 500;
        font-size: ${convertToRem(24)};

        text-align: center;
      }

      &:last-child {
        border-bottom: 0;
      }
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;

    .resume {
      flex-direction: row;
      align-items: stretch;
      flex-wrap: wrap;

      border-left: 0;
      border-top: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

      > div {
        min-width: 120px;

        padding: ${convertToRem(24)} ${convertToRem(8)};

        border-bottom: 0;
        border-right: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

        span {
          font-size: ${convertToRem(14)};
        }

        strong {
          font-size: ${convertToRem(16)};
        }

        &:last-child {
          border-right: 0;
        }
      }
    }
  }

  @media (max-width: 570px) {
    .resume {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: ${convertToRem(1)};

      background-color: ${({ theme }) => theme.colors.neutral.border};

      > div {
        background-color: ${({ theme }) => theme.colors.neutral.white};

        border: 0 !important;
      }
    }
  }
`;

export const HistoryContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${convertToRem(30)};

  margin-top: ${convertToRem(30)};

  > div {
    background-color: ${({ theme }) => theme.colors.neutral.white};
    
    border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
    border-radius: ${convertToRem(8)};

    header {
      display: flex;
      align-items: center;
      justify-content: space-between;

      width: 100%;

      padding: ${convertToRem(32)};

      h2 {
        color: ${({ theme }) => theme.colors.neutral.textDark};

        font-size: ${convertToRem(18)};
      }

      > a {
        color: ${({ theme }) => theme.colors.primary.base};

        font-weight: 500;
        font-size: ${convertToRem(14)};
      }
    }

    .history {
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: ${convertToRem(20)} ${convertToRem(32)};

        font-size: ${convertToRem(14)};

        border-bottom: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

        .title {
          color: ${({ theme }) => theme.colors.neutral.textDark};

          font-weight: 500;
        }

        .value {
          color: ${({ theme }) => theme.colors.neutral.text};
        }

        &:not(:only-child):last-child {
          border-bottom: 0;
        }
      }

      ${ResultCard} {
        border: 0;
        border-radius: 0;

        border-bottom: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
      }
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;

    > div header {
      h2 {
        font-size: ${convertToRem(16)};
      }

      a {
        font-size: ${convertToRem(13)};
      }
    }
  }
`;