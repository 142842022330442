import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

import { usePersistedState } from '@hooks/usePersistedState';

import HttpClient from '@services/utils/HttpClient';
import SessionsService, { InactivatedAccountResponse, ProfileAliasType } from '@services/SessionsService';

import { delay } from '@utils/delay';

import { AxiosAPIError } from '@/types/api';

interface AuthProviderProps {
  children: ReactNode;
}

export interface AuthContextProps {
  isAuthLoading: boolean;
  handleSignIn: (credentials: SignInCredentials) => Promise<void>;
  handleSignOut: () => void;
  authenticated: boolean;
  user: User | null;
  selectedProfile: Profile | null;
  changeSelectedProfile: (type: Profile) => void;
  selectedAccount: SelectedAccount | null;
  changeSelectedAccount: (user: SelectedAccount) => void;
  addPrime: () => void;
  updateUser: (user: Partial<User>) => void;
}

interface SignInCredentials {
  user: string;
  password: string;
  rememberMe: boolean;
}

interface Profile {
  id: number;
  name: string;
  description: string;
  alias: ProfileAliasType;
  hasMoreThanOneAccount: boolean;
}

interface User {
  id: number;
  uuid: string;
  name: string;
  avatar: string;
  profiles: Profile[];
  login: string;
  activationCode: string | null;
  hasMoreThanOneProfile: boolean;
}

export interface SelectedAccount {
  id: number;
  uuid: string;
  name: string;
  document: string;
  avatar: string;
  isPrime: boolean;
  isActive: boolean;
}

const TOKEN_KEY = "AGENDA_CONSULTA@TOKEN";
const USER_KEY = "AGENDA_CONSULTA@USER";
const PROFILE_KEY = "AGENDA_CONSULTA@PROFILE";
const SELECTED_ACCOUNT_KEY = "AGENDA_CONSULTA@SELECTED_ACCOUNT";

export const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export function useAuth(): AuthContextProps {
  const context = useContext(AuthContext);

  if (!context) throw new Error('useAuth must be used within an AuthProvider');

  return context;
}

export function AuthProvider({ children }: AuthProviderProps) {
  const navigate = useNavigate();

  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [authenticated, setAuthenticated] = useState(false);

  const [user, setUser] = usePersistedState<User | null>(USER_KEY, null);
    
  const [selectedProfile, setSelectedProfile] = usePersistedState<Profile | null>(PROFILE_KEY, null);
  const [selectedAccount, setSelectedUser] = usePersistedState<SelectedAccount | null>(SELECTED_ACCOUNT_KEY, null);

  useEffect(() => {
    (async () => {
      const token = window.localStorage.getItem(TOKEN_KEY);
  
      if (!token) {
        setIsAuthLoading(false);
        
        setAuthenticated(false);
        setUser(null);
        setSelectedProfile(null);

        return;
      }

      try {
        setIsAuthLoading(true);
        
        HttpClient.setToken(JSON.parse(token));
        const { data } = await SessionsService.validateToken();

        // Remove after
        await delay();

        if (data.codigo === "200") {
          setAuthenticated(true);
        }
      } catch (error) {
        console.error(error);

        toast.error('Token inválido!');

        handleSignOut();
      } finally {
        setIsAuthLoading(false);
      }
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  function changeSelectedProfile(profile: Profile) {
    setSelectedProfile(profile);
    setSelectedUser(null);
    
    navigate(profile.hasMoreThanOneAccount ? '/selecionar-conta' : '/');
  }

  function changeSelectedAccount(user: SelectedAccount) {
    setSelectedUser(user);
    navigate('/');
  }

  function addPrime() {
    if (!selectedAccount) return;

    setSelectedUser({ ...selectedAccount, isPrime: true });
  }

  function updateUser(user: Partial<User>) {
    setUser(prevState => ({ ...prevState as User, ...user }));
  }

  async function handleSignIn(credentials: SignInCredentials) {
    try {
      const { data } = await SessionsService.login(credentials.user, credentials.password);

      if (credentials.rememberMe) {
        window.localStorage.setItem(TOKEN_KEY, JSON.stringify(data.token));
      }
      
      HttpClient.setToken(data.token);
      setUser({
        id: data.usuario_id,
        uuid: data.uuid,
        name: data.usuario_nome,
        avatar: data.usuario_foto,
        profiles: data.usuario_perfil.map(profile => ({
          id: profile.id,
          name: profile.nome,
          description: profile.descricao,
          alias: profile.alias,
          hasMoreThanOneAccount: profile.alias === 'clinica',
        })),
        login: credentials.user,
        activationCode: data.codigo,
        hasMoreThanOneProfile: data.usuario_perfil.length > 1,
      });

      if (data.usuario_perfil.length === 1) {
        setSelectedProfile({
          id: data.usuario_perfil[0].id,
          name: data.usuario_perfil[0].nome,
          description: data.usuario_perfil[0].descricao,
          alias: data.usuario_perfil[0].alias,
          hasMoreThanOneAccount: data.usuario_perfil[0].alias === 'clinica',
        });
      }

      setAuthenticated(true);

      navigate('/');
    } catch (err) {
      const error = err as AxiosAPIError<InactivatedAccountResponse>;

      setAuthenticated(false);

      console.error(error);

      if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
        error.response.data.dados.forEach(err => toast.error(err));

        const isUserInactived = error.response.data.codigo === 1007;

        if (isUserInactived) {
          const inactivatedUserUuid = error.response.data.acesso;

          navigate(`/auth/conta-usuario/ativacao/${inactivatedUserUuid}?login=${credentials.user}`);
        }
      } else {
        toast.error(error.message);
      }
    }
  }

  function handleSignOut() {
    window.localStorage.removeItem(TOKEN_KEY);
    
    HttpClient.setToken(null);
    setUser(null);
    setSelectedProfile(null);
    setSelectedUser(null);
    
    setAuthenticated(false);

    navigate("/login");
  }

  return (
    <AuthContext.Provider value={{ isAuthLoading, authenticated, handleSignIn, handleSignOut, user, selectedProfile, changeSelectedProfile, selectedAccount, changeSelectedAccount, addPrime, updateUser }}>
      {children}
    </AuthContext.Provider>
  );
}

