import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { format } from 'date-fns';
import { BiTrashAlt } from 'react-icons/bi';
import { RiEyeLine, RiPhoneLine, RiWhatsappLine } from 'react-icons/ri';
import faker from '@faker-js/faker';

import { List, Modal } from '@components/Modal';
import { Description, Title } from '@components/Header';
import { Select } from '@components/Select';
import { DatePicker } from '@components/DatePicker';
import { Loading } from '@components/Loading';
import ResultCard, { ActionButton, ActionsContainer, ItemContainer, ItemDivider, ItemTitle, ItemValue } from '@components/ResultCard';
import { Switch } from '@components/Switch';
import { Tooltip } from '@components/Tooltip';

import ConsultationsService from '@services/ConsultationsService';

import { ChevronDown } from '@assets/images';

import { clearPhoneNumber } from '@utils/clearPhoneNumber';
import { delay } from '@utils/delay';
import { formatPhoneNumber } from '@utils/formatPhoneNumber';

import { OptionTypeBase } from '@components/Select/Select';

import { Content, ContentWrapper, FiltersContainer, Footer, OrderingContainer, Page, PageButton, Pagination, ResultsContainer } from './styles';

const orderSelectItems: OptionTypeBase[] = [
  { value: 'date', label: 'Data' },
  { value: 'patient', label: 'Paciente' },
  { value: 'professional', label: 'Profissional' },
];

const limitSelectItems: OptionTypeBase[] = [
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
];

interface Schedule {
  id: string;
  date: Date;
  patient: string;
  professional: string;
  isCanceled: boolean;
  isConfirmed: boolean;
  phone: string;
  type: 'WhatsApp' | 'Ligação';
}

export function ScheduleConfirmation() {
  const theme = useTheme();

  const [selectedOrder, setSelectedOrder] = useState('date');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [isLoading, setIsLoading] = useState(false);
  const [schedules, setSchedules] = useState<Schedule[]>([]);

  const orderedSchedules = schedules.sort((a, b) => {
    switch (selectedOrder) {
      case 'professional':
        if (a.professional < b.professional) return -1;

        if (a.professional > b.professional) return 1;
      return 0; 

      case 'patient':
        if (a.patient < b.patient) return -1;

        if (a.patient > b.patient) return 1;

      return 0;

      default: 
        if (a.date.getTime() < b.date.getTime()) return 1;

        if (a.date.getTime() > b.date.getTime()) return -1;
      
      return 0; 
    }
  });
  const lastPage = Math.ceil(orderedSchedules.length / itemsPerPage) || 1;

  const [selectedSchedule, setSelectedSchedule] = useState<Schedule | null>(null);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  useEffect(() => {
    async function getSchedules() {
      try {
        setIsLoading(true);
  
        await delay();
        const consultationData = await ConsultationsService.listAllConsultations();

        const parsedSchedules = consultationData.map<Schedule>(consultation => ({
          id: consultation.id,
          date: consultation.date,
          patient: faker.name.firstName(),
          professional: consultation.professional.name,
          phone: faker.phone.phoneNumber('###########').toString(),
          isConfirmed: faker.datatype.boolean(),
          isCanceled: faker.datatype.boolean(),
          type: faker.random.arrayElement(['WhatsApp', 'Ligação']),
        }));

        setSchedules(parsedSchedules);
      } catch (error) {
        
      } finally {
        setIsLoading(false);
      }
    }
    
    getSchedules()
  }, []);

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function toggleIsCanceledSchedule(scheduleId: string) {
    setSchedules((prevState) => prevState.map(schedule => {
      if (schedule.id !== scheduleId) return schedule;

      return ({
        ...schedule,
        isCanceled: !schedule.isCanceled
      })
    }));
  }

  function toggleIsConfirmedSchedule(scheduleId: string) {
    setSchedules((prevState) => prevState.map(schedule => {
      if (schedule.id !== scheduleId) return schedule;

      return ({
        ...schedule,
        isConfirmed: !schedule.isConfirmed
      })
    }));
  }

  function handleDeleteSchedule(scheduleId: string) {
    setSchedules(prevState => prevState.filter(schedule => schedule.id !== scheduleId));
    setIsDeleteModalVisible(false);
  }

  return (
    <>
      <Modal
        visible={selectedSchedule !== null && isDetailsModalVisible}
        title="Detalhes"
        onRequestClose={() => setIsDetailsModalVisible(false)}
      >
        <List>
          <li>
            <strong>Paciente:</strong>
            <span>{selectedSchedule?.patient}</span>
          </li>

          <li>
            <strong>Data e Hora da Consulta:</strong>
            <span>{selectedSchedule?.date ? format(selectedSchedule.date, 'dd/MM/yyyy HH:mm') : ''}</span>
          </li>

          <li>
            <strong>Tipo:</strong>
            <span>Particular</span>
          </li>

          <li>
            <strong>Especialidade:</strong>
            <span>Ortodontia</span>
          </li>

          <li>
            <strong>Observação:</strong>
            <span>Lorem ipsum dolor sit amet consectetur adipisicing elit. Error, accusamus?</span>
          </li>
        </List>
      </Modal>
      
      <Modal 
        visible={selectedSchedule !== null && isDeleteModalVisible}
        title="Atenção"
        onRequestClose={() => setIsDeleteModalVisible(false)} 
      >
        <p className='text'>
          Deseja realmente excluir o agendamento do paciente <strong>{selectedSchedule?.patient}</strong>?
        </p>

        <div className='buttons'>
          <button onClick={() => setIsDeleteModalVisible(false)}>
            Não
          </button>

          <button onClick={() => handleDeleteSchedule(selectedSchedule?.id as string)} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <Content>
        <ContentWrapper className='content'>
          <Title>Confirmação de Agenda</Title>
          <Description>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam massa sapien velit ipsum, ac.
          </Description>

          <FiltersContainer>
            <h4>Filtrar por:</h4>

            <div className="filters">
              <Select 
                label='Profissional'
              />

              <Select 
                label='Especialidade'
              />

              <DatePicker 
                label='Data de Ínicio'
                name='initialDate'
                onChange={initialDate => console.log({ initialDate })}
              />

              <DatePicker 
                label='Data de Fim'
                name='initialDate'
                onChange={initialDate => console.log({ initialDate })}
              />

              <Select 
                label='Cancelamento'
              />

              <Select 
                label='Confirmação'
              />
            </div>
          </FiltersContainer>

          <OrderingContainer>
            <Select
              label="Classificar por"
              options={orderSelectItems}
              value={orderSelectItems.find(option => option.value === selectedOrder)}
              onChange={(option) => {
                setSelectedOrder(option?.value || "date");
                setCurrentPage(1);
              }}
              isClearable={false}
            />

            <Select
              label="Exibir"
              options={limitSelectItems}
              value={limitSelectItems.find(option => option.label === String(itemsPerPage))}
              onChange={(selectedOption) => {
                setItemsPerPage(Number(selectedOption?.value) || 10);
                setCurrentPage(1);
              }}
              isClearable={false}
            />
          </OrderingContainer>

          <ResultsContainer>
            {
              isLoading
              ? <Loading />
              : orderedSchedules.length > 0
                ? orderedSchedules
                  .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                  .map(schedule => (
                    <ResultCard
                      key={schedule.id}
                      onClick={() => setSelectedSchedule(schedule)}
                    >
                      <ItemContainer>
                        <div>
                          <ItemValue 
                            title={format(schedule.date, 'dd/MM/yyyy HH:mm')}
                          >
                            {format(schedule.date, 'dd/MM/yyyy')}
                          </ItemValue>
                          <ItemTitle>Data</ItemTitle>
                        </div>
                      </ItemContainer>

                      <ItemDivider />

                      <ItemContainer>
                        <div>
                          <ItemValue title={schedule.patient}>
                            {schedule.patient}
                          </ItemValue>
                          <ItemTitle>Paciente</ItemTitle>
                        </div>
                      </ItemContainer>

                      <ItemDivider />

                      <ItemContainer>
                        <div>
                          <ItemValue title={schedule.professional}>
                            {schedule.professional}
                          </ItemValue>
                          <ItemTitle>Profissional</ItemTitle>
                        </div>
                      </ItemContainer>

                      <ItemDivider />

                      <ItemContainer>
                        <div>
                          <ItemValue title={formatPhoneNumber(schedule.phone)}>
                            {formatPhoneNumber(schedule.phone)}
                          </ItemValue>
                          <ItemTitle>Telefone</ItemTitle>
                        </div>
                      </ItemContainer>

                      <ItemDivider /> 

                      <ItemContainer>
                        <div>
                          <ItemValue>{schedule.type}</ItemValue>
                          <ItemTitle>Tipo</ItemTitle>
                        </div>
                      </ItemContainer>

                      <ItemDivider /> 

                      <ItemContainer>
                        <div>
                          <Switch 
                            checked={schedule.isCanceled}
                            onChange={() => toggleIsCanceledSchedule(schedule.id)}
                          />
                          <ItemTitle>Cancelamento</ItemTitle>
                        </div>
                      </ItemContainer>

                      <ItemDivider /> 

                      <ItemContainer className='confirmation'>
                        <div>
                          <Switch 
                            checked={schedule.isConfirmed}
                            onChange={() => toggleIsConfirmedSchedule(schedule.id)}
                          />
                          <ItemTitle>Confirmação</ItemTitle>
                        </div>
                      </ItemContainer>

                      <ActionsContainer>
                        <Tooltip content="Detalhes">
                          <ActionButton
                            color={theme.colors.helpers.warning} 
                            onClick={() => setIsDetailsModalVisible(true)}
                          >
                            <RiEyeLine />
                          </ActionButton>
                        </Tooltip>

                        <Tooltip content="Ligar">
                          <a 
                            href={`tel:${clearPhoneNumber(schedule.phone)}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <ActionButton color={theme.colors.helpers.info}>
                              <RiPhoneLine />
                            </ActionButton>
                          </a>
                        </Tooltip>

                        <Tooltip content="WhatsApp">
                          <a 
                            href={`https://wa.me/${clearPhoneNumber(schedule.phone)}`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <ActionButton color={theme.colors.helpers.success}>
                              <RiWhatsappLine />
                            </ActionButton>
                          </a>
                        </Tooltip>

                        <Tooltip content="Excluir">
                          <ActionButton 
                            color={theme.colors.helpers.danger} 
                            onClick={() => setIsDeleteModalVisible(true)}
                          >
                            <BiTrashAlt />
                          </ActionButton>
                        </Tooltip>
                      </ActionsContainer>
                    </ResultCard>
                  ))
                : 'Não existem'
            }
          </ResultsContainer>

          <Footer>
            <div className="content">
              <Pagination>
                <PageButton
                  className="prev"
                  onClick={goToPreviousPage}
                  disabled={currentPage === 1}
                >
                  <ChevronDown />
                </PageButton>

                <Page>{String(currentPage).padStart(2, "0")}</Page>

                <PageButton
                  className="next"
                  onClick={goToNextPage}
                  disabled={currentPage === lastPage}
                >
                  <ChevronDown />
                </PageButton>
              </Pagination>
            </div>
          </Footer>
        </ContentWrapper>
      </Content>
    </>
  );
}