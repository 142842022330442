import styled from "styled-components";
import chroma from "chroma-js";

import { Modal, Subtitle, List } from "@/components/Modal";
import { Container as Button } from "@components/Button/styles";
import { InputGroup } from "@/components/Input/styles";
import { Container as Select } from "@components/Select/styles";
import { Container as ResultCard, ResponsiveCard, ActionButton, ItemContainer } from "@/components/ResultCard/styles";

import { convertToRem } from "@utils/convertToRem";

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100%;

  ${Button} {
    width: 100%;

    margin-top: ${convertToRem(24)};
  }

  ${Select} {
    margin-bottom: ${convertToRem(40)};
  }

  header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    padding: ${convertToRem(40)} ${convertToRem(40)} 0;

    background-color: ${({ theme }) => theme.colors.neutral.white};

    border-bottom: solid ${convertToRem(1.5)} ${({ theme }) => theme.colors.neutral.border};

    .close-btn {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-left: auto;

      background: none;
      color: ${({ theme }) => theme.colors.neutral.text};

      border: 0;

      svg {
        width: auto;
        height: ${convertToRem(20)};
      }
    }

    h2 {
      margin-top: ${convertToRem(20)};
      
      font-size: ${convertToRem(20)};
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;

    padding: ${convertToRem(50)} ${convertToRem(70)};

    background-color: ${({ theme }) => theme.colors.neutral.borderLight};

    .info-group {
      display: flex;

      width: 100%;

      > *:not(:last-child) {
        margin-right: auto;
      }

      & + .info-group {
        margin-top: ${convertToRem(30)};
      }
    }

    .label {
      color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.5).css()};

      font-weight: 700;
      font-size: ${convertToRem(13)};
    }

    .value {
      display: block;

      margin-top: ${convertToRem(8)};

      font-weight: 500;
      font-size: ${convertToRem(15)};
    }

    ${Button} {
      margin-top: ${convertToRem(50)};
    }
  }
`;

export const DataModal = styled(Modal)`
  ${Subtitle} {
    margin-top: ${convertToRem(30)};
  }

  ${List} {
    width: 100%;

    float: left;

    > li {
      width: 100%;

      float: left;

      padding: 0.5rem 0;
      margin-bottom: 0;

      border-top: ${convertToRem(1)} solid rgba(0,0,0,0.09);

      &:first-of-type {
        border-top: 0;
      }

      > strong {
        max-width: 100%;

        float: left;

        word-wrap: break-word;
      }

      > span {
        max-width: 100%;

        float: right;

        word-wrap: break-word;

        margin-left: 0;
      }
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: calc(100vh - ${convertToRem(80)});

  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(40)};
`;

export const Header = styled.header`
  display: flex;
  align-items: flex-end;

  > div {
    flex: 1;

    margin-right: ${convertToRem(24)};
  }
`;

export const FilterModal = styled(Modal)`
  form {
    display: flex;
    flex-direction: column;

    width: 100%;
    
    footer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: ${convertToRem(24)};

      margin-top: ${convertToRem(36)};
      
      ${Button} {
        flex: 1;

        min-width: auto;
        max-width: ${convertToRem(270)};

        margin-left: auto;
      }
    }
  }

  @media (max-width: 576px) {
    ${InputGroup} {
      grid-template-columns: 1fr;
    }
    
    footer {
      flex-direction: column;

      ${Button} {
        width: 100%;

        max-width: none;
      }
    }
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  // grid-template-columns: 3fr 3fr 2fr 1.5fr;
  gap: ${convertToRem(30)};

  margin-top: ${convertToRem(28)};

  ${Button} {
    align-self: flex-end;

    margin-left: auto;
  }

  ${Button} {
    min-width: auto;
    min-height: auto;
    
    height: ${convertToRem(40)};

    padding: 0 ${convertToRem(12)};

    font-size: ${convertToRem(13)};

    > svg {
      margin-left: 0;
      margin-right: ${convertToRem(8)};
    }
  }

  > .campanha {
    width: ${convertToRem(300)};
  }

  > .order {
    width: ${convertToRem(150)};
  }

  > .ultimos {
    width: ${convertToRem(130)};
  }

  > .filter-buttons {
    display: flex;

    > * {
      margin-right: ${convertToRem(16)}
    }
  }

  @media screen and (max-width: 768px) {

    width: 100%;

    display: block;

    > .campanha {
      width: 100%;
      margin-top: ${convertToRem(16)};
    }
  
    > .order {
      width: 100%;
      margin-top: ${convertToRem(16)};
    }
  
    > .ultimos {
      width: 100%;
      margin-top: ${convertToRem(16)};
    }
  
    > .filter-buttons {
      width: 100%;

      display: flex;

      margin-top: ${convertToRem(16)};
  
      > * {
        margin-right: auto}
      }
    }
  }
`;

export const ResultsContainer = styled.div`
  margin-top: ${convertToRem(30)};

  > div {   
    &:not(:first-child) {
      margin-top: ${convertToRem(10)};
    }

    > div {
      flex: 1;
    }

    ${ItemContainer}.buttons {
      justify-content: flex-start;

      > *:not(:first-child) {
        margin-left: ${convertToRem(15)};
      }
      
      ${Button} {
        min-width: ${convertToRem(120)};
      }
    }
  }

  @media (max-width: 992px) {
    ${ResultCard} {   
      ${ResponsiveCard};
    }
  }

`;

export const Footer = styled.footer`
  width: 100%;
  height: ${convertToRem(100)};

  margin-top: ${convertToRem(60)};

  border-top: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

  .content {
    display: flex;
    align-items: center;
  }
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-left: auto;
`;

export const PageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${convertToRem(40)};
  height: ${convertToRem(40)};

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.primary.base};

  border: ${convertToRem(1)} solid ${({ theme }) => theme.colors.neutral.border};
  border-radius: ${convertToRem(5)};

  &.prev {
    transform: rotate(90deg);
  }

  &.next {
    transform: rotate(270deg);
  }
  
  &:disabled {
    background-color: ${({ theme }) => chroma(theme.colors.neutral.border).brighten(0.4).css()};

    cursor: not-allowed;
  }

  > svg {
    width: auto;
    height: ${convertToRem(20)};
    
    stroke-width: 1.2;
  }
`;

export const Page = styled.span`
  margin: 0 ${convertToRem(20)};
  
  color: ${({ theme }) => theme.colors.neutral.text};

  font-weight: 500;
  font-size: ${convertToRem(15)};
  line-height: 1;
`;