import { FocusEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { SingleValue } from "react-select";
import axios from "axios";
import { useTheme } from "styled-components";

import { useAuth } from "@contexts/auth";

import { Loading } from "@components/Loading";
import { LoadingModal, Modal } from "@components/Modal";
import { Select } from "@components/Select";
import { Input, InputGroup } from "@components/Input";
import { RadioGroup, RadioInput } from "@components/Radio";
import { TextArea } from "@components/TextArea";
import { Button } from "@components/Button";
import { Tooltip } from "@components/Tooltip";
import { ActionButton, ActionsContainer } from "@components/ResultCard";

import UsersService from "@services/UsersService";
import LocationsService from "@services/LocationsService";
import SpecialtiesService from "@services/SpecialtiesService";

import { clinicTypeOptions } from "@constants/clinic-type";
import { personOptions } from "@constants/person-type";
import { pixKeyOptions } from "@constants/pix-keys";
import { bankOptions } from "@constants/banks";

import { CancelSquare, ExternalLink } from "@assets/images";

import { FormikEffect } from "@utils/FormikEffect";

import { OptionTypeBase } from "@components/Select/Select";
import { AxiosAPIError } from "@/types/api";

import { Container, SpecialtiesContainer, Fieldset, FormContainer } from "./styles";

interface InitialValues {
  type: OptionTypeBase | null;
  person: OptionTypeBase | null;
  cpfCNPJ: string;
  name: string;
  fantasyName: string;
  email: string;
  tel: string;
  phone: string;
  whatsapp: string;
  specialties: OptionTypeBase[] | null;
  description: string;

  zipCode: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  uf: OptionTypeBase | null;
  city: OptionTypeBase | null;

  transferType: "pix" | "ted";
  keyType: OptionTypeBase | null;
  keyValue: string;
  bank: OptionTypeBase | null;
  bankAgency: string;
  bankAccount: string;
  bankCpf: string;
}

export function EditRegisterTab() {
  const { selectedAccount } = useAuth();
  const navigate = useNavigate();
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSpecialties, setIsLoadingSpecialties] = useState(false);
  const [isLoadingCityOptions, setIsLoadingCityOptions] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [specialtyOptions, setSpecialtyOptions] = useState<OptionTypeBase[]>([]);

  const [UFOptions, setUFOptions] = useState<OptionTypeBase[]>([]);
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);
  const [registeredSpecialties, setRegisteredSpecialties] = useState<OptionTypeBase[]>([]);

  const [isLoadingCEPInfo, setIsLoadingCEPInfo] = useState(false);
  const [isCEPValid, setIsCEPValid] = useState(true);

  const [selectedSpecialty, setSelectedSpecialty] = useState<string | null>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const formik = useFormik<InitialValues>({
    enableReinitialize: true,
    initialValues: {
      type: null,
      person: null,
      cpfCNPJ: "",
      name: "",
      fantasyName: "",
      email: "",
      tel: "",
      phone: "",
      whatsapp: "",
      specialties: null,
      description: "",

      zipCode: "",
      street: "",
      number: "",
      complement: "",
      neighborhood: "",
      uf: null,
      city: null,

      transferType: 'pix',
      keyType: null,
      keyValue: '',
      bank: null,
      bankAgency: '',
      bankAccount: '',
      bankCpf: '',
    },
    validationSchema: Yup.object().shape({
      type: Yup.object()
        .typeError("Selecione um tipo")
        .required("O campo é obrigatório"),
      person: Yup.object()
        .typeError("Selecione um tipo de pessoa")
        .required("O campo é obrigatório"),
      cpfCNPJ: Yup.string()
        .required("O campo é obrigatório")
        .when("person.label", {
          is: "Pessoa Jurídica",
          then: Yup.string().matches(/\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}/, "Informe um CNPJ válido"),
          otherwise: Yup.string().matches(/\d{3}\.?\d{3}\.?\d{3}-?\d{2}/, "Informe um CPF válido")
        }),
      name: Yup.string()
        .required("O campo é obrigatório"),
      fantasyName: Yup.string()
        .when("person.label", {
          is: "Pessoa Jurídica",
          then: Yup.string().required("O campo é obrigatório")
        }),
      email: Yup.string()
        .email("Informe um e-mail válido")
        .required("O campo é obrigatório"),
      tel: Yup.string()
        .matches(/^[(]?\d{2}[)]?[-\s]?\d{4}[-\s]?\d{4}$/g, "Insira um telefone válido"),
      phone: Yup.string()
        .matches(/^[(]?\d{2}[)]?[-\s]?\d{5}[-\s]?\d{4}$/g, "Insira um celular válido"),
      whatsapp: Yup.string()
        .matches(/^[(]?\d{2}[)]?[-\s]?\d{5}[-\s]?\d{4}$/g, "Insira um WhatsApp válido"),
      specialties: registeredSpecialties.length === 0
        ? Yup.array().of(
          Yup.object().shape({
            value: Yup.string(),
            label: Yup.string(),
          })
        )
          .min(1, "Selecione pelo menos uma especialidade")
          .required("O campo é obrigatório")
          .typeError("Selecione uma especialidade")
        : Yup.array().nullable().notRequired(),

      zipCode: Yup.string()
        .matches(/^\d{5}-?\d{3}$/, "Infome um CEP válido")
        .required("O campo é obrigatório"),
      street: Yup.string()
        .required("O campo é obrigatório"),
      number: Yup.number()
        .typeError("Apenas números")
        .required("O campo é obrigatório"),
      neighborhood: Yup.string()
        .required("O campo é obrigatório"),
      uf: Yup.object()
        .typeError("Selecione um estado")
        .required("O campo é obrigatório"),
      city: Yup.object()
        .typeError("Selecione uma cidade")
        .required("O campo é obrigatório"),

      transferType: Yup.string()
        .required("O campo é obrigatório"),
      keyType: Yup.object()
        .when("transferType", {
          is: "pix",
          then: Yup.object()
            .shape({ value: Yup.string(), label: Yup.string() })
            .required("O campo é obrigatório")
            .typeError("Selecione um tipo de chave"),
          otherwise: Yup.object().nullable()
        }),
      keyValue: Yup.string()
        .when("transferType", {
          is: "pix",
          then: Yup.string()
            .when("keyType.label", {
              is: "CPF",
              then: Yup.string().matches(/^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/, "Insira um CPF válido")
            })
            .when("keyType.label", {
              is: "E-mail",
              then: Yup.string().email("Insira um e-mail válido")
            })
            .when("keyType.label", {
              is: "Telefone",
              then: Yup.string().matches(/\(\d{2,}\) \d{4,5}-\d{4}/g, "Insira um telefone válido")
            })
            .required("O campo é obrigatório")
        }),
      bank: Yup.object()
        .when("transferType", {
          is: "ted",
          then: Yup.object()
            .shape({ value: Yup.string(), label: Yup.string() })
            .required("O campo é obrigatório")
            .typeError("Selecione um banco"),
          otherwise: Yup.object().nullable()
        }),
      bankAgency: Yup.string()
        .when("transferType", {
          is: "ted",
          then: Yup.string().required("O campo é obrigatório"),
        }),
      bankAccount: Yup.string()
        .when("transferType", {
          is: "ted",
          then: Yup.string().required("O campo é obrigatório"),
        }),
      bankCpf: Yup.string()
        .when("transferType", {
          is: "ted",
          then: Yup.string()
            .matches(/^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/, "Insira um CPF válido")
            .required("O campo é obrigatório"),
        }),
    }),
    onSubmit: async (values) => {
      try {
        if (!selectedAccount) throw new Error("User must be selected");

        setIsSubmitting(true);

        const parsedSpecialties = Array.from(new Set<string>([
          ...registeredSpecialties.map(specialty => specialty.value) || [],
          ...formik.values.specialties?.map(specialty => specialty.value) || [],
        ]));
        await SpecialtiesService.updateSpecialties(selectedAccount.id, parsedSpecialties);

        await UsersService.updateData(selectedAccount.uuid, {
          clinicTypeID: values.type?.value as string,
          document: values.cpfCNPJ,
          name: values.name,
          fantasyName: values.fantasyName,
          email: values.email,
          tel: values.tel,
          phone: values.phone,
          whatsapp: values.whatsapp,
          address: {
            street: values.street,
            number: values.number,
            complement: values.complement,
            neighborhood: values.neighborhood,
            city: values.city?.label as string,
            uf: values.uf?.label as string,
            zipCode: values.zipCode,
          }
        });

        toast.success("Os dados foram atualizados com sucesso!");
      } catch (err) {
        const error = err as AxiosAPIError;

        console.error(error);

        if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
          error.response.data.dados.forEach(err => toast.error(err));
        } else {
          toast.error(error.message);
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  const isLegalPerson = formik.values.person?.label === "Pessoa Jurídica" || formik.values.cpfCNPJ.length > 11;

  useEffect(() => {
    (async () => {
      // Get UF Options
      try {
        setIsLoading(true);

        const { data: UFs } = await LocationsService.listUFs();

        const parsedUFs: OptionTypeBase[] = UFs.map((UF) => ({
          value: String(UF.id),
          label: UF.uf,
        }));

        setUFOptions(parsedUFs);
        
        // Get User Data
        try {
          const { data } = await UsersService.listData(selectedAccount?.uuid as string);
    
          const isDocumentAnCNPJ = data.clinica.dados.documento.length > 11;
    
          formik.setFieldValue("type", clinicTypeOptions.find(option => data.clinica.dados.tipo === option.label));
          
          formik.setFieldValue("cpfCNPJ", data.clinica.dados.documento);
          if (isDocumentAnCNPJ) {
            formik.setFieldValue("person", personOptions.find(person => person.label === "Pessoa Jurídica"));
          } else {
            formik.setFieldValue("person", personOptions.find(person => person.label === "Pessoa Física"));
          }
          
          formik.setFieldValue("fantasyName", isDocumentAnCNPJ ? data.clinica.dados.nome_fantasia : "");
          formik.setFieldValue("name", data.clinica.dados.nome_razao_social);
    
          formik.setFieldValue("email", data.clinica.emails.filter(email => email.chave === "email").pop()?.valor || "");
    
          formik.setFieldValue("tel", data.clinica.contatos.filter(contact => contact.chave === "telefone").pop()?.valor || "");
          formik.setFieldValue("phone", data.clinica.contatos.filter(contact => contact.chave === "celular").pop()?.valor || "");
          formik.setFieldValue("whatsapp", data.clinica.contatos.filter(contact => contact.chave === "whatsApp").pop()?.valor || "");
          
          const parsedRegisteredSpecialties = data.clinica.especialidades?.map(specialty => ({
            label: specialty.nome,
            value: String(specialty.especialidade_id),
          }));
          setRegisteredSpecialties(parsedRegisteredSpecialties || []);
    
          if (Array.isArray(data.clinica.endereco)) {
            formik.setFieldValue("zipCode", "");
            clearAddressFields();
          } else {
            formik.setFieldValue("zipCode", data.clinica.endereco.cep);
            formik.setFieldValue("street", data.clinica.endereco.endereco);
    
            formik.setFieldValue("number", Number(data.clinica.endereco.numero));
            formik.setFieldValue("complement", data.clinica.endereco.complemento);
            formik.setFieldValue("neighborhood", data.clinica.endereco.bairro);
    
            const locatedUF = parsedUFs.find(uf => (data.clinica.endereco as any).uf === uf.label) || null;

            if (locatedUF) {
              formik.setFieldValue("uf", locatedUF);

              const cities = await changeCityOptions(locatedUF);
              const locatedCity = cities?.find(city => (data.clinica.endereco as any).cidade.toLowerCase() === city.label.toLowerCase()) || null;
              
              formik.setFieldValue("city", locatedCity);
            } else {
              formik.setFieldValue("uf", null);
              formik.setFieldValue("city", null);
            }
          }
        } catch (error) {
          toast.error("Não foi possível carregar os dados do usuário");
    
          console.log(error);
        }
      } catch (error) {
        setUFOptions([]);

        toast.error("Não foi possível carregar as UFs");

        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Executing every time selectedUF changes
  useEffect(() => {
    formik.setFieldValue("city", null);

    if (!formik.values.uf) {
      setCityOptions([]);

      return;
    }

    changeCityOptions(formik.values.uf);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.uf]);

  // Executing every time selectedClinicType changes
  useEffect(() => {
    async function getSpecialtities() {
      if (!formik.values.type) {
        return;
      }
      
      formik.setFieldValue("specialties", null);

      try {
        setIsLoadingSpecialties(true);

        const { data } = await SpecialtiesService.listSpecialties(formik.values.type.value);

        const parsedSpecialties: OptionTypeBase[] = data.dados[0].map(
          (specialty) => ({
            value: String(specialty.id),
            label: specialty.nome,
          })
        );

        setSpecialtyOptions(parsedSpecialties);
      } catch (error) {
        setSpecialtyOptions([]);

        toast.error("Não foi possível carregar as especialidades");
      } finally {
        setIsLoadingSpecialties(false);
      }
    }

    getSpecialtities();
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.type]);

  function clearLegalPersonFields() {
    formik.setFieldValue("cpfCNPJ", "");
    formik.setFieldValue("fantasyName", "");
  }

  function clearAddressFields() {
    formik.setFieldValue("street", "");
    formik.setFieldValue("complement", "");
    formik.setFieldValue("neighborhood", "");
    formik.setFieldValue("uf", null);
    formik.setFieldValue("city", null);
  }

  async function changeCityOptions(ufOption: SingleValue<OptionTypeBase>) {
    try {
      setIsLoadingCityOptions(true);

      if (!ufOption) throw new Error("Estado inválido! Não foi possível buscar as cidades");

      const { data: cities } = await LocationsService.listCitiesByUF(ufOption.label);

      const parsedCities: OptionTypeBase[] = cities.map((city) => ({
        value: String(city.id),
        label: city.cidade,
      }));

      setCityOptions(parsedCities);
      
      return parsedCities;
    } catch (error) {
      toast.error("Não foi possível carregar as cidades");

      console.log(error);

      return null;
    } finally {
      setIsLoadingCityOptions(false);
    }
  }

  async function handleBlurZipCode(event: FocusEvent<HTMLInputElement>) {
    const cep = event.target.value.replace(/[^\d]/g, "");

    if (cep.length !== 8) return clearAddressFields();

    try {
      setIsLoadingCEPInfo(true);

      const data = await LocationsService.getAddressByZipCode(Number(cep));

      if (data.erro) throw new Error("CEP Inválido!");

      setIsCEPValid(true);

      formik.setFieldValue("street", data.logradouro);
      formik.setFieldValue("complement", data.complemento);
      formik.setFieldValue("neighborhood", data.bairro);
      formik.setFieldValue("uf", UFOptions.find(uf => uf.label.toUpperCase() === data.uf.toUpperCase()) || null);

      const cities = await changeCityOptions(UFOptions.find(uf => uf.label.toUpperCase() === data.uf.toUpperCase()) || null);
      formik.setFieldValue("city", cities?.find(city => city.label.toUpperCase() === data.localidade.toUpperCase()) || null);
    } catch (error) {
      console.log(error);

      setIsCEPValid(false);

      clearAddressFields();
    } finally {
      setIsLoadingCEPInfo(false);
    }
  }

  function handleDeleteSpecialty() {
    setIsDeleteModalVisible(false);
    toast.success('Especialidade excluída com sucesso!');
  }

  if (isLoading) return <Loading />

  return (
    <Container>
      <Modal 
        visible={selectedSpecialty !== null && isDeleteModalVisible}
        title="Atenção"
        onRequestClose={() => setIsDeleteModalVisible(false)} 
      >
        <p className='text'>
          Deseja realmente excluir a especialidade <strong>{selectedSpecialty}</strong>? Essa ação irá excluir todos os procedimentos relacionados a especialidade e é irreversível.
        </p>

        <div className='buttons'>
          <button onClick={() => setIsDeleteModalVisible(false)}>
            Não
          </button>

          <button onClick={handleDeleteSpecialty} className='primary'>
            Sim
          </button>
        </div>
      </Modal>
      
      <LoadingModal visible={isSubmitting} />

      <FormContainer onSubmit={formik.handleSubmit}>
        <FormikEffect formik={formik} />

        <Fieldset>
          <legend>Dados básicos</legend>

          <Select 
            label="Sua Clínica ou Consultório é"
            name="type"
            placeholder="Selecione um tipo"
            options={clinicTypeOptions}
            value={formik.values.type}
            onChange={(option) => formik.setFieldValue('type', option)}
            error={(formik.touched.type || undefined) && formik.errors.type}
            isClearable={false}
          />

          <InputGroup>
            <Select 
              label="Tipo de Pessoa"
              name="person"
              options={personOptions}
              value={formik.values.person}
              onChange={(option) => {
                formik.setFieldValue("person", option);
                clearLegalPersonFields();
              }}
              error={(formik.touched.person || undefined) && formik.errors.person}
              isClearable={false}
            />

            <Input 
              label={isLegalPerson ? "CNPJ" : "CPF"} 
              name="cpfCNPJ"
              mask={isLegalPerson 
                ? [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
                : [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
              } 
              value={formik.values.cpfCNPJ}
              onChange={formik.handleChange}
              error={(formik.touched.cpfCNPJ || undefined) && formik.errors.cpfCNPJ}
            />
          </InputGroup>

          <InputGroup layout={isLegalPerson ? "1fr 1fr" : "1fr"}>
            <Input 
              label={isLegalPerson ? "Razão Social" : "Nome Completo"}
              name="name" 
              value={formik.values.name}
              onChange={formik.handleChange}
              error={(formik.touched.name || undefined) && formik.errors.name}
            />

            {isLegalPerson && (
              <Input 
                label="Nome Fantasia" 
                name="fantasyName" 
                value={formik.values.fantasyName}
                onChange={formik.handleChange}
                error={(formik.touched.fantasyName || undefined) && formik.errors.fantasyName}
              />
            )}
          </InputGroup>
          
          <InputGroup>
            <Input 
              label="Email" 
              name="email" 
              value={formik.values.email}
              onChange={formik.handleChange}
              error={(formik.touched.email || undefined) && formik.errors.email}
            />
          </InputGroup>

          <InputGroup>
            <Input 
              label="Telefone" 
              name="tel"
              mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              value={formik.values.tel}
              onChange={formik.handleChange}
              error={(formik.touched.tel || undefined) && formik.errors.tel}
            />

            <Input 
              label="Celular" 
              name="phone"
              mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              value={formik.values.phone}
              onChange={formik.handleChange}
              error={(formik.touched.phone || undefined) && formik.errors.phone}
            />

            <Input 
              label="WhatsApp" 
              name="whatsapp" 
              mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              value={formik.values.whatsapp}
              onChange={formik.handleChange}
              error={(formik.touched.whatsapp || undefined) && formik.errors.whatsapp}
            />
          </InputGroup>

          <Select 
            isMulti
            label="Especialidade"
            name="specialties"
            options={specialtyOptions}
            value={formik.values.specialties}
            onChange={(options) => formik.setFieldValue("specialties", options)}
            isDisabled={isLoadingSpecialties}
            error={(formik.touched.specialties || undefined) && formik.errors.specialties}
          />

          <TextArea 
            label="Descrição" 
            name="description" 
            value={formik.values.description}
            onChange={formik.handleChange}
          />
        </Fieldset>

        <Fieldset>
          <legend>Dados bancários</legend>

          <RadioGroup label="Tipo de Transferência">
            <RadioInput 
              label="PIX" 
              value="pix"
              name="pix" 
              isChecked={formik.values.transferType === 'pix'}
              handleChange={() => formik.setFieldValue('transferType', 'pix')}
            />

            <RadioInput 
              label="TED/DOC" 
              value="ted"
              name="ted" 
              isChecked={formik.values.transferType === 'ted'}
              handleChange={() => formik.setFieldValue('transferType', 'ted')}
            />
          </RadioGroup>

          {formik.values.transferType === 'pix'
            ? (
              <InputGroup layout="1fr 2fr">
                <Select 
                  label="Tipo de Chave"
                  name="keyType"
                  options={pixKeyOptions}
                  value={formik.values.keyType}
                  onChange={(option) => {
                    formik.setFieldValue("keyType", option);
                    formik.setFieldValue("keyValue", "");
                  }}
                  error={(formik.touched.keyType || undefined) && formik.errors.keyType}
                  isClearable={false}
                />
                
                <Input
                  label="Valor da Chave"
                  name="keyValue"
                  mask={
                    formik.values.keyType?.label === 'CPF'
                    ? [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
                    : undefined
                  }
                  value={formik.values.keyValue}
                  onChange={formik.handleChange}
                  error={(formik.touched.keyValue || undefined) && formik.errors.keyValue}
                />
              </InputGroup>
            )
            : (
                <>
                  <InputGroup layout="2fr 1fr 1fr" className="bank">
                    <Select 
                      label="Banco"
                      name="bank"
                      options={bankOptions}
                      value={formik.values.bank}
                      onChange={(option) => formik.setFieldValue("bank", option)}
                      error={(formik.touched.bank || undefined) && formik.errors.bank}
                    />

                    <Input 
                      label="Agência"
                      name="bankAgency"
                      value={formik.values.bankAgency}
                      onChange={formik.handleChange}
                      error={(formik.touched.bankAgency || undefined) && formik.errors.bankAgency}
                    />

                    <Input 
                      label="Conta"
                      name="bankAccount"
                      value={formik.values.bankAccount}
                      onChange={formik.handleChange}
                      error={(formik.touched.bankAccount || undefined) && formik.errors.bankAccount}
                    />
                  </InputGroup>
                  
                  <Input 
                    label="CPF do Titular"
                    name="bankCpf"
                    mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                    value={formik.values.bankCpf}
                    onChange={formik.handleChange}
                    error={(formik.touched.bankCpf || undefined) && formik.errors.bankCpf}
                  />
                </>
            )
          }
        </Fieldset>

        <Fieldset>
          <legend>Dados de endereço</legend>

          <InputGroup layout="1fr 4fr">
            <Input 
              label="CEP" 
              name="zipCode"
              mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
              value={formik.values.zipCode}
              onChange={formik.handleChange}
              onBlur={handleBlurZipCode}
              error={
                isCEPValid
                ? (formik.touched.zipCode || undefined) && formik.errors.zipCode
                : "CEP Inválido!"
              }
            />

            <Input 
              label="Endereço" 
              name="street"
              value={formik.values.street}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={(formik.touched.street || undefined) && formik.errors.street}
              disabled={isLoadingCEPInfo}
            />
          </InputGroup>

          <InputGroup layout="1fr 4fr 3fr" className="neighborhood">
            <Input 
              label="Número" 
              name="number"
              value={formik.values.number}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={(formik.touched.number || undefined) && formik.errors.number} 
            />

            <Input 
              label="Complemento" 
              name="complement" 
              value={formik.values.complement}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              disabled={isLoadingCEPInfo}
            />

            <Input 
              label="Bairro" 
              name="neighborhood"
              value={formik.values.neighborhood}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={(formik.touched.neighborhood || undefined) && formik.errors.neighborhood}
              disabled={isLoadingCEPInfo}
            />
          </InputGroup>

          <InputGroup layout="1fr 4fr">
            <Select 
              label="UF" 
              name="uf" 
              options={UFOptions}
              value={formik.values.uf}
              onChange={(selectedOption) => formik.setFieldValue('uf', selectedOption)}
              error={(formik.touched.uf || undefined) && formik.errors.uf}
              isDisabled={isLoading || isLoadingCEPInfo} 
            />

            <Select 
              label="Cidade" 
              name="city"
              options={cityOptions}
              value={formik.values.city}
              onChange={option => formik.setFieldValue("city", option)}
              error={(formik.touched.city || undefined) && formik.errors.city}
              isDisabled={isLoadingCEPInfo || formik.values.uf === null || isLoadingCityOptions} 
            />
          </InputGroup>
        </Fieldset>

        <Button type="submit">Atualizar</Button>
      </FormContainer>

      {!!registeredSpecialties.length && (
        <Fieldset>
          <legend>Especialidades</legend>

          <SpecialtiesContainer>
            {
              registeredSpecialties.map(registeredSpecialty => (
                <div>
                  <strong>{registeredSpecialty.label}</strong>

                  <ActionsContainer>
                      <Tooltip content="Ver">
                        <ActionButton
                          color={theme.colors.helpers.success}
                          onClick={() => navigate(`/procedimentos/${registeredSpecialty.value}`)}
                        >
                          <ExternalLink />
                        </ActionButton>
                      </Tooltip>

                      <Tooltip content="Excluir">
                        <ActionButton
                          color={theme.colors.helpers.danger}
                          onClick={() => {
                            setIsDeleteModalVisible(true);
                            setSelectedSpecialty(registeredSpecialty.label);
                          }}
                        >
                          <CancelSquare />
                        </ActionButton>
                      </Tooltip>
                  </ActionsContainer>
                </div>
              ))
            }
          </SpecialtiesContainer>
        </Fieldset>
      )}
    </Container>
  );
}