import { useState } from "react";

import { Select } from "@components/Select";
import { TimeInput } from "@components/TimeInput";

import { Memo, Trash } from "@assets/images";

import {
  Container,
  Day,
  TimeContainer,
  ActionButtonsInEditing,
  SaveButton,
  CancelButton,
  Buttons,
  ScheduleAction,
} from "./styles";

export type ScheduleType = {
  day: string;
  startTime: string;
  endTime: string;
  isEditing?: boolean;
  isNew?: boolean;
};

type ScheduleProps = ScheduleType & {
  onSave: (schedule: ScheduleType) => void;
  onRemove: () => void;
};

const dayOptions = [
  { value: "Sunday", label: "Domingo" },
  { value: "Monday", label: "Segunda-feira" },
  { value: "Tuesday", label: "Terça-feira" },
  { value: "Wednesday", label: "Quarta-feira" },
  { value: "Thursday", label: "Quinta-feira" },
  { value: "Friday", label: "Sexta-feira" },
  { value: "Saturday", label: "Sábado" },
];

export function Schedule({
  day: _day,
  startTime: _startTime,
  endTime: _endTime,
  onSave,
  onRemove,
  isEditing: _isEditing = false,
  isNew: _isNew = false,
}: ScheduleProps) {
  const [day, setDay] = useState(
    dayOptions.find((option) => option.label === _day)?.label
  );
  const [startTime, setStartTime] = useState(_startTime);
  const [endTime, setEndTime] = useState(_endTime);

  const [isEditing, setIsEditing] = useState(_isEditing);
  const [isNew, setIsNew] = useState(_isNew);

  function handleSaveButton() {
    if (day === undefined) return alert("Selecione um dia da semana");
    if (startTime === "00:00")
      return alert("Informe um horário de início válido");
    if (endTime === "00:00")
      return alert("Informe um horário de término válido");

    setIsNew(false);
    setIsEditing(false);

    if (day) onSave({ day, startTime, endTime });
  }

  function handleCancelButton() {
    setDay(_day);

    if (isNew) onRemove();

    setIsEditing(false);
  }

  return (
    <Container>
      <Day>
        {isEditing ? (
          <Select
            defaultValue={dayOptions.find((option) => option.label === day)}
            onChange={(option) => setDay(option?.label)}
            options={dayOptions}
            isClearable={false}
          />
        ) : (
          day
        )}
      </Day>

      <TimeContainer>
        <>
          De
          <TimeInput
            value={startTime}
            onChange={(e) => setStartTime(e.target.value)}
            disabled={!isEditing}
          />
          até
          <TimeInput
            value={endTime}
            onChange={(e) => setEndTime(e.target.value)}
            disabled={!isEditing}
          />
        </>
      </TimeContainer>

      {isEditing ? (
        <ActionButtonsInEditing>
          <SaveButton onClick={handleSaveButton}>Salvar</SaveButton>
          <CancelButton onClick={handleCancelButton}>Cancelar</CancelButton>
        </ActionButtonsInEditing>
      ) : (
        <Buttons>
          <ScheduleAction type="button" onClick={(e) => setIsEditing(true)}>
            <Memo />
          </ScheduleAction>

          <ScheduleAction type="button" onClick={() => onRemove()}>
            <Trash />
          </ScheduleAction>
        </Buttons>
      )}
    </Container>
  );
}
