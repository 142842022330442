import HttpClient from "./utils/HttpClient";

interface ListUsersResponse {
  empresas: Array<{
    empresa_id: number;
    empresa_uuid: string;
    empresa_nome_razao_social: string;
    empresa_fantasia: string;
    empresa_cnpj: string;
    empresa_logo: string;
    empresa_prime: number;
    status: "ativa" | "inativa";
  }>;
  codigo: string;
  status: string;
}

interface ListDataResponse {
  clinica: {
    contatos: Array<{
      chave: string;
      valor: string;
    }>;
    dados: {
      documento: string;
      nome_fantasia: string;
      nome_razao_social: string;
      status_id: number;
      tipo: string;
      usuario_responsavel_id: string;
      usuario_responsavel_nome: string;
    };
    documentos: Array<{
      chave: string;
      valor: string;
    }>;
    emails: Array<{
      chave: string;
      valor: string;
    }>;
    endereco: {
      cep: string;
			uf: string;
			cidade: string;
			endereco_tipo: string;
			endereco: string;
			numero: string;
			bairro: string;
			complemento: string;
    } | [];
    especialidades?: Array<{
      clinica_espc_id: number;
      nome: string;
      especialidade_id: number;
    }>;
  };
  codigo: string;
}

interface UpdateDataRequest {
  clinicTypeID: string;
  document: string;
  name: string;
  fantasyName: string;
  email: string;
  tel: string;
  phone: string;
  whatsapp: string;
  address: {
    street: string;
    number: string;
    complement: string;
    neighborhood: string;
    city: string;
    uf: string;
    zipCode: string;
  };
}

export interface UpdateDataResponse {
  codigo: string;
  menssagem: string;
}

interface ActivatePrimeResponse extends UpdateDataResponse {}

class UsersService {
  async listUsers(userId: number, profileId: number) {
    return HttpClient.post<ListUsersResponse>('/base/perfil/selecionar', {
      usuario_id: userId,
	    perfil_id: profileId,
    });
  }

  async listData(userId: string) {
    return HttpClient.get<ListDataResponse>(`/empresa/dados/${userId}`);
  }

  async updateData(userUuid: string, data: UpdateDataRequest) {
    return HttpClient.post<UpdateDataResponse>(`/empresa/cadastro/editar/${userUuid}`, {
      tipo_id: data.clinicTypeID,
      document: data.document,
      nome_razao_social: data.name,
      nome_fantasia: data.fantasyName,
      email: data.email,
      telefone: data.tel,
      whatsApp: data.whatsapp,
      celular: data.phone,
      endereco: {
        endereco_tipo: "1",
        endereco: data.address.street,
        numero: data.address.number,
        bairro: data.address.neighborhood,
        complemento: data.address.complement,
        uf: data.address.uf,
        cidade: data.address.city,
        cep: data.address.zipCode,
      },
    });
  }

  async activatePrime(clinicUuid: string, userId: number) {
    return HttpClient.post<ActivatePrimeResponse>('/empresa/add-prime', {
      empresa_uuid: clinicUuid,
      usuario_id: userId,
    });
  }
}

export default new UsersService();