import { forwardRef } from "react";
import { useTheme } from "styled-components";

import { Loading } from "@components/Loading";

import { ButtonProps } from "./Button";

import { Container } from "./styles";

export const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, forwardedRef) => {
  const { variant = 'primary', children, type = 'button', isLoading, ...rest } = props;

  const theme = useTheme();

  return (
    <Container 
      ref={forwardedRef} 
      variant={variant} 
      type={type} 
      {...rest}
    >
      {isLoading ? <Loading color={theme.colors.neutral.white} /> : children}
    </Container>
  );
});