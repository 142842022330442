import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';

import { Loading } from '@components/Loading';
import ResultCard, { ItemValue, ItemContainer, ItemDivider, ItemTitle } from '@components/ResultCard';
import { Select } from '@components/Select';

import PaymentsService from '@services/PaymentsService';

import { ChevronDown } from '@assets/images';

import { formatToBRL } from '@utils/formatToBRL';

import { OptionTypeBase } from '@components/Select/Select';
import { StatusColors } from '@/types/api';

import { Footer, Header, ResultsContainer, Wrapper } from "./styles";

const orderSelectOptions: OptionTypeBase[] = [
  { value: 'Data do Pagamento', label: 'Data do Pagamento' },
  { value: 'Valor', label: 'Valor' },
];

const limitSelectOptions: OptionTypeBase[] = [
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
];

interface Payment {
  goal: string;
  leadValue: number;
  quantity: number;
  amount: number;
  status: {
    name: string;
    color: StatusColors;
  }
}

export function HistoryPayments() {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(true);
  const [payments, setPayments] = useState<Payment[]>([]);

  const [selectedOrder, setSelectedOrder] = useState("Data do Pagamento");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const lastPage = Math.ceil(payments.length / itemsPerPage) || 1;

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const { data } = await PaymentsService.listPayments();
        
        const parsedPayments = data.pagamentos.map<Payment>(payment => ({
          goal: payment.meta,
          amount: payment.total,
          leadValue: payment.valor_lead,
          quantity: payment.quantidade,
          status: {
            name: payment.status,
            color: payment.status_cor,
          },
        }));

        setPayments(parsedPayments);
      } catch (error) {
        toast.error('Não foi possível carregar os pagamentos!');

        console.error(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  return (
    <Wrapper>
      <Header>
        <h1>Histórico de Pagamentos</h1>

        <div className="filters">
          <Select
            label="Classificar por"
            options={orderSelectOptions}
            value={orderSelectOptions.find(option => option.label === selectedOrder)}
            onChange={option => setSelectedOrder(option?.label || "Data do Pagamento")}
            isClearable={false}
          />

          <Select
            label="Exibir últimos"
            options={limitSelectOptions}
            value={limitSelectOptions.find(option => option.label === String(itemsPerPage))}
            onChange={option => setItemsPerPage(Number(option?.label) || 10)}
            isClearable={false}
          />
        </div>
      </Header>

      <ResultsContainer isEmpty={isLoading || payments.length === 0}>
        {isLoading
          ? <Loading />
          : payments.length > 0
          ? payments
            .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
            .map((payment, index) => (
              <ResultCard key={index}>
                <ItemContainer>
                  <div>
                    <ItemValue title={payment.goal}>{payment.goal}</ItemValue>
                    <ItemTitle>Meta</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider />

                <ItemContainer>
                  <div>
                    <ItemValue 
                      title={formatToBRL(payment.leadValue)}
                    >
                      {formatToBRL(payment.leadValue)}
                    </ItemValue>
                    <ItemTitle>Valor/Lead</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider />

                <ItemContainer>
                  <div>
                    <ItemValue 
                      title={String(payment.quantity)}
                    >
                      {payment.quantity}
                    </ItemValue>
                    <ItemTitle>Quantidade</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider />

                <ItemContainer>
                  <div>
                    <ItemValue title={formatToBRL(payment.amount)}>
                      {formatToBRL(payment.amount)}
                    </ItemValue>
                    <ItemTitle>Total</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider />

                <ItemContainer>
                  <div>
                    <ItemValue 
                      title={payment.status.name} 
                      color={theme.colors.helpers[payment.status.color]}
                    >
                      {payment.status.name}
                    </ItemValue>
                    <ItemTitle>Status</ItemTitle>
                  </div>
                </ItemContainer>
              </ResultCard>
            ))
          : 'Nenhum pagamento encontrado...'
        }
      </ResultsContainer>

      <Footer>
        <button 
          type='button' 
          className='page-btn prev' 
          onClick={goToPreviousPage}
          disabled={currentPage === 1}
        >
          <ChevronDown />
        </button>

        <span>{String(currentPage).padStart(2, "0")}</span>

        <button 
          type='button' 
          className='page-btn next'
          onClick={goToNextPage}
          disabled={currentPage === lastPage}
        >
          <ChevronDown />
        </button>
      </Footer>
    </Wrapper>
  );
}