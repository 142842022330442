import ReactRating from "react-rating";
import { RiStarFill, RiStarLine } from "react-icons/ri";

import { RatingProps } from "./Rating";

export function Rating({ rate, ...props }: RatingProps) {
  
  return (
    <ReactRating
      initialRating={
        +rate.toFixed(1) % 1 === 0.5 
        ? rate 
        : Math.round(rate)
      }
      fullSymbol={<RiStarFill />}
      emptySymbol={<RiStarLine />}
      className="stars"
      {...props}
    />
  );
}
