import { Navigate } from "react-router-dom";

import { Tabs } from "@components/Tabs";

// Pages
import { HistoryPayments } from "./History";

import { Container } from "./styles";

export function Payments() {
  return (
    <Container>
      <Tabs
        tabs={[
          { path: '/', element: <Navigate to="historico" /> },
          { title: 'Histórico', path: 'historico', element: <HistoryPayments /> },
        ]}
      />
    </Container>
  );
}