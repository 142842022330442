import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';
import { FiLock, FiUnlock } from 'react-icons/fi';
import axios from 'axios';

import { useAuth } from '@contexts/auth';

import { Description, Title } from '@components/Header';
import { LoadingModal, Modal } from '@components/Modal';
import { Button } from '@components/Button';
import { Select } from '@components/Select';
import { Loading } from '@components/Loading';
import ResultCard, { ItemDivider, ItemValue, ItemContainer, ItemTitle, ActionsContainer, ActionButton } from '@components/ResultCard';
import { Tooltip } from '@components/Tooltip';

import ProfessionalsService from '@services/ProfessionalsService';

import { CancelSquare, ChevronDown, Memo, PlusCircle, Share } from '@assets/images';

import { formatToBRL } from '@utils/formatToBRL';
import { copyTextToClipboard } from '@utils/copyTextToClipboard';

import { OnChangeOptionType } from '@components/Select/Select.d';
import { AxiosAPIError } from '@/types/api';

import { Content, ContentWrapper, FiltersContainer, Footer, Header, Page, PageButton, Pagination, ResultsContainer } from './styles';

type ProfessionalsOrder = 'Nome' | 'Valor' | 'Status';

interface OrderSelect {
  label: ProfessionalsOrder;
  value: ProfessionalsOrder;
}

const orderSelectItems: OrderSelect[] = [
  { value: 'Nome', label: 'Nome' },
  { value: 'Valor', label: 'Valor' },
  { value: 'Status', label: 'Status' },
];

const limitSelectItems = [
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
];

interface Professional {
  id: number;
  uuid: string;
  name: string;
  address: {
    city: string;
  };
  council: {
    type: number;
    uf: string;
    number: string;
  }
  consultationPrice: {
    normal: number;
    prime: number;
  };
  status: {
    id: number;
    description: string;
    color: string;
  }
}

export function ListProfessionals() {
  const { selectedAccount } = useAuth();

  const theme = useTheme();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [professionals, setProfessionals] = useState<Professional[]>([]);

  const [selectedOrder, setSelectedOrder] = useState<ProfessionalsOrder>("Nome");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const [selectedProfessional, setSelectedProfessional] = useState<Professional | null>(null);

  const [isStatusModalVisible, setIsStatusModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // const orderedProfessionals = professionals.sort((a, b) => {
  //   switch (selectedOrder) {
  //     case 'Status': 
  //       if (a.status === 'Liberado') return -1;
  //       if (a.status === 'Bloqueado') return 1;
  //     return 0;
      
  //     case 'Valor': return a.primeValue - b.primeValue;

  //     default:
  //       if (a.name < b.name) return -1;

  //       if (a.name > b.name) return 1;

  //     return 0;
  //   }
  // });

  useEffect(() => {
    (async () => {
      try {
        if (!selectedAccount) return;
  
        setIsLoading(true);
        
        const { data } = await ProfessionalsService.listClinicProfessionals({
          clinica_id: selectedAccount.id,
          limit: itemsPerPage,
          page: currentPage - 1,
        });
        
        const maxPage = Math.ceil(data.total / itemsPerPage) || 1;
        if (data.pagina + 1 > maxPage) {
          setCurrentPage(maxPage);
        }
  
        const parsedProfessionals: Professional[] = data.profissionais.map(professional => ({
          id: professional.id,
          uuid: professional.uuid,
          name: professional.nome,
          address: {
            city: professional.cidade,
          },
          council: {
            type: professional.registro_tipo,
            uf: professional.registro_uf,
            number: professional.registro_numero,
          },
          consultationPrice: {
            normal: professional.valor_consulta,
            prime: professional.valor_consulta_prime,
          },
          status: {
            id: professional.status_id,
            description: professional.status_descricao,
            color: professional.status_cor,
          },
        })) 
  
        setProfessionals(parsedProfessionals);
        setLastPage(maxPage);
      } catch (err) {
        const error = err as AxiosAPIError;
  
        console.error(error);
  
        if (axios.isAxiosError(error) && error.response) {
          toast.error(error.response.data.menssagem);
        } else {
          toast.error(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    })()
  }, [selectedAccount, itemsPerPage, currentPage]);
  
  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  function handleChangeLimit(selectedOption: OnChangeOptionType) {
    if (selectedOption === null) return;

    setItemsPerPage(Number(selectedOption.value));
    setCurrentPage(1);
  };


  function handleShareButtonClick(id: string) {
    const { origin } = window.location;

    const professionalLink = `${origin}/profissionais/${id}`;

    copyTextToClipboard(professionalLink)
      .then(() => {
        toast.success("Link de compartilhamento copiado com sucesso!");
      })
      .catch((error) => {
        console.log(error);

        toast.error("Não foi possível copiar o link de compartilhamento!");
      })
    ;

    setSelectedProfessional(null);
  }

  async function handleToggleStatus() {
    if (!selectedProfessional || isSubmitting) return;

    try {
      setIsSubmitting(true);

      const { data } = await ProfessionalsService.updateClinicProfessionalStatus({
        uuid: selectedProfessional.uuid,
        status_id: selectedProfessional.status.id === 1 ? 2 : 1, // 1 - Ativo | 2 - Inativo
      })

      const updatedProfessionals: Professional[] = professionals.map(professional => {
        if (professional.uuid !== selectedProfessional.uuid) return professional;

        return {
          ...professional,
          status: {
            id: data.status_id,
            description: data.status_descricao,
            color: data.status_cor,
          }
        };
      })
      
      setProfessionals(updatedProfessionals);
      toast.success(data.resposta);
    } catch (err) {
      const error = err as AxiosAPIError;
  
      console.error(error);

      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.menssagem);
      } else {
        toast.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
      setIsStatusModalVisible(false);
      setSelectedProfessional(null);
    }
  }

  async function handleDeleteProfessional() {
    if (!selectedAccount || !selectedProfessional || isSubmitting) return;

    try {
      setIsSubmitting(true);

      const { data } = await ProfessionalsService.deleteClinicProfessional({
        clinica_id: selectedAccount.id,
        uuid: selectedProfessional.uuid,
      });

      const updatedProfessionals: Professional[] = professionals.filter(professional => professional.uuid !== data.uuid);
      
      setProfessionals(updatedProfessionals);
      
      if (updatedProfessionals.length < 1) {
        setCurrentPage((prevState) => (prevState - 1) || 1)
      }
      
      toast.success(data.resposta);
    } catch (err) {
      const error = err as AxiosAPIError;
  
      console.error(error);

      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.menssagem);
      } else {
        toast.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
      setIsDeleteModalVisible(false);
      setSelectedProfessional(null);
    }
  }

  return (
    <Content>
      <LoadingModal visible={isSubmitting} />

      <Modal  
        visible={selectedProfessional !== null && isStatusModalVisible}
        title="Atenção"
        onRequestClose={() => setIsStatusModalVisible(false)} 
      >
        <p className='text'>
          Deseja realmente alterar para <strong>{selectedProfessional?.status.id === 1 ? 'Inativo' : 'Ativo'}</strong> o status do profissional <strong>{selectedProfessional ? selectedProfessional.name : ''}</strong>?
        </p>

        <div className='buttons'>
          <button onClick={() => setIsStatusModalVisible(false)}>
            Não
          </button>

          <button onClick={handleToggleStatus} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <Modal 
        visible={selectedProfessional !== null && isDeleteModalVisible}
        title="Atenção"
        onRequestClose={() => setIsDeleteModalVisible(false)} 
      >
        <p className='text'>
          Deseja realmente excluir o profissional <strong>{selectedProfessional ? selectedProfessional.name : ''}</strong>? <br /><br />
          
          Essa ação irá excluir todos os dados e é irreversível.
        </p>

        <div className='buttons'>
          <button onClick={() => setIsDeleteModalVisible(false)}>
            Não
          </button>

          <button onClick={handleDeleteProfessional} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <ContentWrapper className="content">
        <Header>
          <div>
            <Title>Profissionais de Saúde</Title>
            <Description>
              Mantenha o seu cadastro atualizado para aproveitar ao máximo nossas
              vantagens e funcionalidades.
            </Description>
          </div>

          <Button 
            variant="tertiary" 
            onClick={() => navigate('/profissionais/adicionar/')}
          >
            Adicionar

            <PlusCircle strokeWidth={1.5} />
          </Button>
        </Header>

        <FiltersContainer>
          <Select
            label="Classificar por"
            options={orderSelectItems}
            value={orderSelectItems.find(option => option.label === selectedOrder)}
            onChange={(option) => {
              setSelectedOrder(option?.label || "Nome");
              setCurrentPage(1);
            }}
            isClearable={false}
          />

          <Select
            label="Exibir últimos"
            options={limitSelectItems}
            value={limitSelectItems.find(option => option.label === String(itemsPerPage))}
            onChange={handleChangeLimit}
            isClearable={false}
          />
        </FiltersContainer>

        <ResultsContainer>
          {isLoading
            ? <Loading />
            : professionals.length === 0
              ? 'Não existem profissionais cadastrados'
              : professionals
                .map(professional => (
                  <ResultCard 
                    key={professional.uuid} 
                    onClick={() => setSelectedProfessional(professional)}
                  >
                    <ItemContainer>
                      <div>
                        <ItemValue>{professional.name || '-------'}</ItemValue>
                        <ItemTitle>Nome</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ItemDivider />

                    <ItemContainer>
                      <div>
                        <ItemValue>{professional.address.city || '-------'}</ItemValue>
                        <ItemTitle>Cidade</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ItemDivider />

                    <ItemContainer>
                      <div>
                        <ItemValue>{professional.council.number}</ItemValue>
                        <ItemTitle>Registro</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ItemDivider />

                    <ItemContainer>
                      <div>
                        <ItemValue>{formatToBRL(professional.consultationPrice.normal)}</ItemValue>
                        <ItemTitle>Valor Normal</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ItemDivider />

                    <ItemContainer>
                      <div>
                        <ItemValue>{formatToBRL(professional.consultationPrice.prime)}</ItemValue>
                        <ItemTitle>Valor Prime</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ItemDivider
                      // @ts-ignore 
                      color={theme.colors.helpers[professional.status.color]}
                    />

                    <ItemContainer>
                      <div>
                        <ItemValue 
                          // @ts-ignore 
                          color={theme.colors.helpers[professional.status.color]}
                        >
                          {professional.status.description}
                        </ItemValue>
                        <ItemTitle>Status</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ActionsContainer>
                      <Tooltip content='Compartilhar'>
                        <ActionButton 
                          color={theme.colors.helpers.info}
                          onClick={() => handleShareButtonClick(professional.uuid)}
                        >
                          <Share />
                        </ActionButton>
                      </Tooltip>

                      <Tooltip content='Editar'>
                        <ActionButton 
                          color={theme.colors.helpers.success}
                          onClick={() => {
                            setSelectedProfessional(null);
                            navigate(`/profissionais/editar/${professional.uuid}`);
                          }}
                        >
                          <Memo />
                        </ActionButton>
                      </Tooltip>

                      <Tooltip content={professional.status.id === 1 ? 'Desativar' : 'Ativar'}>
                        <ActionButton 
                          color={theme.colors.helpers.warning}
                          onClick={() => setIsStatusModalVisible(true)}
                        >
                          {
                            professional.status.id === 1 
                            ? <FiLock strokeWidth={2.5}/> 
                            : <FiUnlock strokeWidth={2.5} /> 
                          }
                        </ActionButton>
                      </Tooltip>

                      <Tooltip content='Excluir'>
                        <ActionButton 
                          color={theme.colors.helpers.danger}
                          onClick={() => setIsDeleteModalVisible(true)}
                        >
                          <CancelSquare />
                        </ActionButton>
                      </Tooltip>
                    </ActionsContainer>
                  </ResultCard>
                ))
          }
        </ResultsContainer>
      </ContentWrapper>

      <Footer>
        <div className="content">
          <Pagination>
            <PageButton
              className="prev"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              <ChevronDown />
            </PageButton>

            <Page>{String(currentPage).padStart(2, "0")}</Page>

            <PageButton
              className="next"
              onClick={goToNextPage}
              disabled={currentPage === lastPage}
            >
              <ChevronDown />
            </PageButton>
          </Pagination>
        </div>
      </Footer>
    </Content>
  );
}