import { Route, Routes } from "react-router-dom";

import { Sidebar } from "@components/Sidebar";
import { Header } from "@components/Header";

import { Dashboard } from "@pages/AffiliateAdmin/Dashboard";
import { Affiliates } from "@pages/AffiliateAdmin/Affiliates";
import { Leads } from "@pages/AffiliateAdmin/Leads";
import { Finance } from "@pages/AffiliateAdmin/Finance";
import { Reports } from "@pages/AffiliateAdmin/Reports";
import { Settings } from "@pages/AffiliateAdmin/Settings";

import { Container, Main } from "./styles";

export function AffiliateAdminRoutes() {
  return (
    <Container>
      <Sidebar />

      <Main>
        <Header />

        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="afiliados/" element={<Affiliates />} />
          <Route path="leads/" element={<Leads />} />
          <Route path="financeiro/" element={<Finance />} />
          <Route path="relatorios/" element={<Reports />} />
          <Route path="configuracoes/" element={<Settings />} />
        </Routes>
      </Main>
    </Container>
  );
}
