import HttpClient from './utils/HttpClient';

export interface Pharmacy {
  ID: number;
  Foto: string;
  Nome: string;
  NomeFantasia: string;
  Telefone: string;
  Avaliacao: number;
  CNPJ: string;
  Descricao: string;
  CEP: string;
  Logradouro: string;
  Numero: string;
  Complemento: string;
  Bairro: string;
  Estado: string;
  Cidade: string;
  WhatsApp: string;
  Descontos: Array<{
    categoria: string;
    porcentagem: number;
  }>;
}

interface Filters {
  name?: string;
  uf?: string;
  city?: string;
  orderBy?: 'distance' | 'popularity';
}

class PharmaciesService {
  async listPharmacies(filters: Filters): Promise<Pharmacy[]> {
    let filteredPharmacies = await HttpClient.externalGet<Pharmacy[]>('http://localhost:8080/pharmacies');

    return new Promise((resolve) => {
      if (filters.name) {
        filteredPharmacies = filteredPharmacies.filter(pharmacy => pharmacy.Nome.toLowerCase().includes((filters.name as string).toLowerCase()) || pharmacy.NomeFantasia.toLowerCase().includes((filters.name as string).toLowerCase()));
      }

      if (filters.uf) {
        filteredPharmacies = filteredPharmacies.filter(pharmacy => pharmacy.Estado.toUpperCase() === (filters.uf as string).substring(0, 2).toUpperCase());
      }
      
      if (filters.city) {
        filteredPharmacies = filteredPharmacies.filter(pharmacy => pharmacy.Cidade.toLowerCase() === (filters.city as string).toLowerCase());
      }

      if (filters.orderBy === "popularity") {
        filteredPharmacies = filteredPharmacies.sort((a, b) => {
          if (a.Avaliacao > b.Avaliacao) return -1;
          if (a.Avaliacao < b.Avaliacao) return 1;
          return 0;
        });
      }

      resolve(filteredPharmacies);
    });
  }
}

export default new PharmaciesService();