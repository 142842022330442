import { useEffect, useState } from "react";
import { RiHospitalLine } from "react-icons/ri";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import { SelectedAccount, useAuth } from "@contexts/auth";

import { Loading } from "@components/Loading";
import { Button } from "@components/Button";

import UsersService from "@services/UsersService";

import { formatCPFCNPJ } from "@utils/formatCPFCNPJ";

import { AgendaConsultaExpand, Check } from "@assets/images";

import { Container, Wrapper } from "./styles";

export function SelectAccount() {
  const { user, selectedProfile, selectedAccount, changeSelectedAccount, handleSignOut } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [users, setUsers] = useState<SelectedAccount[]>([]);

  useEffect(() => {
    if (!user || !selectedProfile) return;

    (async () => {
      try {
        setIsLoading(true);
  
        const { data } = await UsersService.listUsers(user.id, selectedProfile.id);

        if (data.empresas.length === 1) {
          changeSelectedAccount({
            id: data.empresas[0].empresa_id,
            uuid: data.empresas[0].empresa_uuid,
            name: data.empresas[0].empresa_fantasia,
            document: data.empresas[0].empresa_cnpj,
            avatar: data.empresas[0].empresa_logo,
            isPrime: data.empresas[0].empresa_prime === 1,
            isActive: data.empresas[0].status === "ativa",
          });
        }

        setUsers(data.empresas.map(user => ({
          id: user.empresa_id,
          uuid: user.empresa_uuid,
          name: user.empresa_fantasia,
          document: user.empresa_cnpj,
          avatar: user.empresa_logo,
          isPrime: user.empresa_prime === 1,
          isActive: user.status === "ativa",
        })));
      } catch (error) {
        console.error(error);

        toast.error("Não foi possível buscar contas");
      } finally {
        setIsLoading(false);
      }
    })();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!user || !selectedProfile) return null;

  return (
    <Wrapper>
      <Container className="content">
        <header>
          <Link to="/selecionar-perfil">Alterar perfil</Link>

          <AgendaConsultaExpand />

          <button onClick={() => handleSignOut()}>Sair</button>
        </header>

        <h1>Selecione uma conta</h1>

        {isLoading
          ? <Loading />
          : users.length > 0
            ? (
              <ul>
                {users.map(user => (
                  <li 
                    key={user.id} 
                    className={!user.isActive ? 'inactive' : ''}
                  >
                    <span className="icon">
                      {user.avatar 
                        ? <img src={user.avatar} alt={user.name} />
                        : <RiHospitalLine />
                      }
                    </span>
                    
                    <h3>{user.name}</h3>
                    <p>{formatCPFCNPJ(user.document)}</p>

                    <span className="inactive-message">{!user.isActive && 'Usuário desativado'}</span>
                    
                    {user.isActive
                      ? (
                        <Button
                          variant={selectedAccount?.id === user.id ? "secondary" : "primary"}
                          onClick={() => changeSelectedAccount(user)}
                        >
                          {selectedAccount?.id === user.id
                            ? (
                              <>
                                <Check />
                                Selecionado
                              </>
                            )
                            : 'Selecionar'
                          }
                        </Button>
                      )
                      : (
                        <Button 
                          variant="secondary"
                          onClick={() => alert('Redirecionar para tela de ativação')}
                        >
                          Ativar
                        </Button>
                      )
                    }
                  </li> 
                ))}
              </ul>
            )
          : <p>Não existem usuários cadastrados</p>
        }
      </Container>
    </Wrapper>
  );
}