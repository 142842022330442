import { useState } from "react";

import { ChevronDown } from "@assets/images";

import { CardProps } from "./Card";

import { Container, Content, Details, Footer, Header, Icon, Title, Value } from "./styles";


export function Card({ icon, title, value, details, children, ...props }: CardProps) {
  const [isExpanded, setIsExpanded] = useState(false);
  
  const toggleCard = () => {
    setIsExpanded(prevState => !prevState);
  }

  return (
    <Container>
      <Content className={isExpanded ? 'expanded' : ''} {...props}>
        <Icon>{icon}</Icon>

        <Header>
          <Title>{title}</Title>
          <Value>{value}</Value>
        </Header>

        <Details>
          {details?.map((detail, index) => (
            <li key={detail.title+index}>
              <span className="title">{detail.title}</span>
              <span className="value">{detail.value}</span>
            </li>
          ))}
        </Details>

        {details 
          ? (
            <Footer onClick={toggleCard}>
              <span className="line" />

              {isExpanded ? 'Ver menos' : 'Ver mais'}
              <ChevronDown />
            </Footer>
          ) : <Footer style={{ cursor: 'initial' }}/>
        }
      </Content>

      {children}
    </Container>
  );
}