import { addDays, format, isToday, isTomorrow, isYesterday } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

import { Button } from "@components/Button";
import { DatePicker } from "@components/DatePicker";
import { Description, Title } from "@components/Header";
import { Select } from "@components/Select";

import { ChevronDown } from "@assets/images";

import { capitalizeFirstLetter } from "@utils/capitalizeFirstLetter";

import {
  Buttons,
  Content,
  ContentWrapper,
  Day,
  DayWeek,
  Header,
  Hour,
  NavButton,
  ResultContainer,
  ScheduleContainer,
  ScheduleContent,
  ScheduleForm,
  SubmitContainer,
} from "./styles";


const professionalsList = [
  { value: "dra. bianca estevez", label: "Dra. Bianca Estevez" },
  { value: "dr. juliano luan", label: "Dr. Juliano Luan" },
];

const schedulesData = [
  {
    date: new Date(),
    schedule: [
      "07:00:00",
      "08:00:00",
      "09:00:00",
      "10:00:00",
      "11:00:00",
      "12:00:00",
      "13:00:00",
      "14:00:00",
      "15:00:00",
      "16:00:00",
      "17:00:00",
      "18:00:00",
    ],
  },
  {
    date: addDays(new Date(), 1),
    schedule: [
      "07:00:00",
      "08:00:00",
      "09:00:00",
      "10:00:00",
      "11:00:00",
      "12:00:00",
      "13:00:00",
      "14:00:00",
      "15:00:00",
      "16:00:00",
      "17:00:00",
      "18:00:00",
    ],
  },
  {
    date: addDays(new Date(), 2),
    schedule: [
      "07:00:00",
      "08:00:00",
      "09:00:00",
      "10:00:00",
      "11:00:00",
      "12:00:00",
      "13:00:00",
      "14:00:00",
      "15:00:00",
      "16:00:00",
      "17:00:00",
      "18:00:00",
    ],
  },
  {
    date: addDays(new Date(), 3),
    schedule: [
      "07:00:00",
      "08:00:00",
      "09:00:00",
      "10:00:00",
      "11:00:00",
      "12:00:00",
      "13:00:00",
      "14:00:00",
      "15:00:00",
      "16:00:00",
      "17:00:00",
      "18:00:00",
    ],
  },
  {
    date: addDays(new Date(), 4),
    schedule: [
      "07:00:00",
      "08:00:00",
      "09:00:00",
      "10:00:00",
      "11:00:00",
      "12:00:00",
      "13:00:00",
      "14:00:00",
      "15:00:00",
      "16:00:00",
      "17:00:00",
      "18:00:00",
    ],
  },
];

export function Schedules() {
  function formatDayWeek(date: Date) {
    if (isYesterday(date)) return "Ontem";
    if (isToday(date)) return "Hoje";
    if (isTomorrow(date)) return "Amanhã";

    return capitalizeFirstLetter(
      format(date, "E", { locale: ptBR }).slice(0, 3)
    );
  }

  return (
    <Content>
      <ContentWrapper className="content">
        <Header>
          <div>
            <Title>Agendamentos</Title>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Description>
          </div>

          <Buttons>
            <Button variant="secondary">Ativar horário especial</Button>
            <Button variant="secondary">Bloquear Sab. e Dom</Button>
          </Buttons>
        </Header>

        <ScheduleContainer>
          <ScheduleForm>
            <h3>Profissionais</h3>

            <Select
              label="Selecione Profissional"
              options={professionalsList}
            />

            <SubmitContainer>
              <DatePicker
                label="Exibir últimos"
                name="show-last"
                onChange={() => {}}
                open
                popperPlacement="bottom-start"
              />

              <Button>Ok</Button>
            </SubmitContainer>
          </ScheduleForm>

          <ResultContainer>
            <NavButton isPrev>
              <ChevronDown />
            </NavButton>

            <ScheduleContent>
              {schedulesData.map((schedule) => (
                <div>
                  <DayWeek>{formatDayWeek(schedule.date)}</DayWeek>
                  <Day>
                    {
                      format(schedule.date, "dd")
                      + " " +
                      capitalizeFirstLetter(
                        format(schedule.date, "LLL", { locale: ptBR })
                      )
                    }
                  </Day>

                  {schedule.schedule.map((hour) => (
                    <Hour>{hour.slice(0, 5)}</Hour>
                  ))}
                </div>
              ))}
            </ScheduleContent>

            <NavButton>
              <ChevronDown />
            </NavButton>
          </ResultContainer>
        </ScheduleContainer>
      </ContentWrapper>
    </Content>
  );
}
