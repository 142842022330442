import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { Loading } from '@components/Loading';
import { LoadingModal, Modal } from '@components/Modal';
import { Input } from '@components/Input';
import { Description, Title } from '@components/Header';
import ResultCard, { ActionButton, ActionsContainer, ItemContainer, ItemTitle, ItemValue } from '@components/ResultCard';
import { Tooltip } from '@components/Tooltip';
import { Button } from '@components/Button';

import { delay } from '@utils/delay';

import { CancelSquare, Memo, PlusCircle } from '@assets/images';

import { ConsultationReasonModal, Content, ContentWrapper, Header, ResultsContainer } from './styles';

interface ConsultationReason {
  id: string;
  name: string;
}

const mockConsultationReason: ConsultationReason[] = [
  { id:'Motivo 01', name: 'Motivo 01' },
  { id:'Motivo 02', name: 'Motivo 02' },
  { id:'Motivo 03', name: 'Motivo 03' },
];

interface ConsultationReasonFormik {
  name: string;
}

export function ConsultationReasons() {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(true);
  const [consultationReasons, setConsultationReasons] = useState<ConsultationReason[]>([]);
  
  const [selectedConsultationReason, setSelectedConsultationReason] = useState<ConsultationReason | null>(null);
  const [isAddConsultationReasonModalVisible, setIsAddConsultationReasonModalVisible] = useState(false);
  const [isEditConsultationReasonModalVisible, setIsEditConsultationReasonModalVisible] = useState(false);
  const [isDeleteConsultationReasonModalVisible, setIsDeleteConsultationReasonModalVisible] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  const addConsultationReasonFormik = useFormik<ConsultationReasonFormik>({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('O campo é obrigatório')
    }),
    onSubmit: async (values, { resetForm }) => {
      const newConsultationReasons: ConsultationReason[] = [
        ...consultationReasons,
        { id: values.name, name: values.name },
      ];

      await delay();
      setConsultationReasons(newConsultationReasons);

      setIsAddConsultationReasonModalVisible(false);
      resetForm();
      toast.success('Motivo de consulta cadastrado com sucesso!')
    }
  });

  const editConsultationReasonFormik = useFormik<ConsultationReasonFormik>({
    enableReinitialize: true,
    initialValues: {
      name: selectedConsultationReason?.name || '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('O campo é obrigatório')
    }),
    onSubmit: async (values, { resetForm }) => {
      if (!selectedConsultationReason) throw new Error('Selected consultation reason is required!');

      const editedConsultationReasons: ConsultationReason[] = consultationReasons.map(reason => {
        if (reason.id === selectedConsultationReason.id) {
          return {
            id: values.name,
            name: values.name,
          };
        }

        return reason;
      });
      
      await delay();

      setConsultationReasons(editedConsultationReasons);

      setIsEditConsultationReasonModalVisible(false);
      resetForm();
      toast.success('Motivo de consulta editado com sucesso!');
    }
  });

  useEffect(() => {
    async function getConsultationReasons() {
      setIsLoading(true);

      setConsultationReasons(mockConsultationReason);
      await delay();

      setIsLoading(false);
    }

    getConsultationReasons();
  }, []);

  async function handleDeleteConsultationReason() {
    if (!selectedConsultationReason) throw new Error('Selected consultation reason is required!');

    setIsDeleteConsultationReasonModalVisible(false);
    setIsDeleting(true);

    const updatedConsultationReasons: ConsultationReason[] = consultationReasons.filter(reason => reason.id !== selectedConsultationReason.id);
    
    await delay();

    setConsultationReasons(updatedConsultationReasons);

    setIsDeleting(false);
    setIsDeleteConsultationReasonModalVisible(false);
    toast.success('Motivo de consulta excluído com sucesso!')
  }

  return (
    <Content>
      <LoadingModal 
        visible={isDeleting} 
        title="Excluindo..." 
      />

      <ConsultationReasonModal
        visible={isAddConsultationReasonModalVisible}
        title="Novo Motivo de Consulta"
        onRequestClose={() => setIsAddConsultationReasonModalVisible(false)}
      >
        <form onSubmit={addConsultationReasonFormik.handleSubmit}>
          <Input
            label="Nome"
            name='name'
            value={addConsultationReasonFormik.values.name}
            onChange={addConsultationReasonFormik.handleChange}
            error={(addConsultationReasonFormik.touched.name || undefined) && addConsultationReasonFormik.errors.name}
          />

          <Button 
            type="submit"
            isLoading={addConsultationReasonFormik.isSubmitting}
            disabled={addConsultationReasonFormik.isSubmitting}
          >
            Adicionar
          </Button>
        </form>
      </ConsultationReasonModal>

      <ConsultationReasonModal
        visible={selectedConsultationReason !== null && isEditConsultationReasonModalVisible}
        title="Editar Motivo de Consulta"
        onRequestClose={() => setIsEditConsultationReasonModalVisible(false)}
      >
        <form onSubmit={editConsultationReasonFormik.handleSubmit}>
          <Input
            label="Nome"
            name='name'
            value={editConsultationReasonFormik.values.name}
            onChange={editConsultationReasonFormik.handleChange}
            error={(editConsultationReasonFormik.touched.name || undefined) && editConsultationReasonFormik.errors.name}
          />

          <Button 
            type="submit"
            isLoading={editConsultationReasonFormik.isSubmitting}
            disabled={editConsultationReasonFormik.isSubmitting}
          >
            Alterar
          </Button>
        </form>
      </ConsultationReasonModal>

      <Modal 
        visible={selectedConsultationReason !== null && isDeleteConsultationReasonModalVisible}
        title="Atenção"
        onRequestClose={() => setIsDeleteConsultationReasonModalVisible(false)} 
      >
        <p className='text'>
          Deseja realmente excluir o motivo de consulta <strong>{selectedConsultationReason ? selectedConsultationReason.name : ''}</strong>? <br /><br />
          
          Essa ação irá excluir todos os dados e é irreversível.
        </p>

        <div className='buttons'>
          <button onClick={() => setIsDeleteConsultationReasonModalVisible(false)}>
            Não
          </button>

          <button onClick={handleDeleteConsultationReason} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <ContentWrapper className='content'>
        <Header>
          <div>
            <Title>Motivos de Consulta | Configurações</Title>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam massa sapien velit ipsum, ac.
            </Description>
          </div>

          <Button 
            variant="tertiary" 
            onClick={() => setIsAddConsultationReasonModalVisible(true)}
          >
            Adicionar

            <PlusCircle strokeWidth={1.5} />
          </Button>
        </Header>

        <ResultsContainer>
          {isLoading
            ? <Loading />
            : consultationReasons.length > 0 
              ? consultationReasons.map(reason => (
                <ResultCard 
                  key={reason.id}
                  onClick={() => setSelectedConsultationReason(reason)}
                >
                  <ItemContainer>
                    <div>
                      <ItemValue title={reason.name}>
                        {reason.name}
                      </ItemValue>
                      <ItemTitle>Nome</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ActionsContainer>
                    <Tooltip content='Editar'>
                      <ActionButton
                        color={theme.colors.helpers.success}
                        onClick={() => setIsEditConsultationReasonModalVisible(true)}
                      >
                        <Memo />
                      </ActionButton>
                    </Tooltip>

                    <Tooltip content='Excluir'>
                      <ActionButton
                        color={theme.colors.helpers.danger}
                        onClick={() => setIsDeleteConsultationReasonModalVisible(true)}
                      >
                        <CancelSquare />
                      </ActionButton>
                    </Tooltip>
                  </ActionsContainer>
                </ResultCard>
              ))
              : 'Não existem motivos de consulta cadastrados...'
          }
        </ResultsContainer>
      </ContentWrapper>
    </Content>
  );
}