import styled, { keyframes } from "styled-components";
import chroma from "chroma-js";
import * as TooltipPrimitive from '@radix-ui/react-tooltip';

import { convertToRem } from '@utils/convertToRem';

const slideUp = keyframes`
  0% { 
    opacity: 0;
    transform: translateY(${convertToRem(10)});
  }

  100% { 
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideRight = keyframes`
  0% { 
    opacity: 0;
    transform: translateX(${convertToRem(-10)});
  }

  100% { 
    opacity: 1;
    transform: translateX(0);
  }
`;

const slideDown = keyframes`
  0% { 
    opacity: 0;
    transform: translateY(${convertToRem(-10)});
  }

  100% { 
    opacity: 1;
    transform: translateY(0);
  }
`;

const slideLeft = keyframes`
  0% { 
    opacity: 0;
    transform: translateX(${convertToRem(10)});
  }

  100% { 
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Content = styled(TooltipPrimitive.Content)`
  padding: ${convertToRem(12)};

  background-color: ${({ theme }) => theme.colors.neutral.text};
  color: ${({ theme }) => theme.colors.neutral.borderLight};

  font-weight: 500;
  font-size: ${convertToRem(13)};
  line-height: 1;

  border-radius: ${convertToRem(6)};

  text-align: center;

  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  animation-fill-mode: forwards;
  will-change: 'transform, opacity';

  box-shadow: 0 0 ${convertToRem(4)} ${({ theme }) => chroma(theme.colors.neutral.borderDark).alpha(1.0).css()};
  
  &[data-state="delayed-open"] {
    &[data-side="top"] { 
      animation-name: ${slideDown}; 
    }

    &[data-side="right"] { 
      animation-name: ${slideLeft}; 
    }

    &[data-side="bottom"] { 
      animation-name: ${slideUp}; 
    }

    &[data-side="left"] { 
      animation-name: ${slideRight}; 
    }
  }
`;

export const TooltipArrow = styled(TooltipPrimitive.Arrow)`
  fill: ${({ theme }) => theme.colors.neutral.text};
`;