import { ChangeEvent, CSSProperties } from "react";

import { SliderProps } from "./Slider";

import { Container, RangeInput } from "./styles";


type Variables = CSSProperties & {
  "--range-min": string | number;
  "--range-max": string | number;
  "--range-val": string | number;
};

export function Slider({ value = 0, minValue, maxValue, onChange, ...props }: SliderProps) {
  const style: Variables = {
    "--range-min": minValue,
    "--range-max": maxValue,
    "--range-val": +value
  };
  
  function handleRange(e: ChangeEvent<HTMLInputElement>) {
    e.persist();

    const value = e.currentTarget.value;
    const element = e.currentTarget;

    element.style.setProperty("--range-val", value);

    onChange(e);
  }

  return (
    <Container>
      <RangeInput 
        type="range" 
        min={minValue}
        max={maxValue}
        value={value}
        onChange={handleRange}
        style={style}
        {...props}
      />
    </Container>
  );
}