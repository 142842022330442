import { convertToRem } from '@utils/convertToRem';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const SwitchHandle = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: ${({ theme }) => theme.colors.neutral.borderDark};
  
  border-radius: ${convertToRem(45)};

  transition: background-color ease 0.2s;

  cursor: pointer;

  &::before {
    content: '';

    position: absolute;
    top: ${convertToRem(3)};
    bottom: ${convertToRem(3)};
    left: ${convertToRem(3)};

    width: ${convertToRem(18)};
    height: ${convertToRem(18)};

    background-color: ${({ theme }) => theme.colors.neutral.white};

    border-radius: 50%;

    transition: transform 0.3s ease;
  }
`;

export const SwitchWrapper = styled.label`
  position: relative;

  display: block;

  width: ${convertToRem(44)};
  height: ${convertToRem(24)};

  input[type="checkbox"] {
    display: none;

    &:checked + ${SwitchHandle} {
      background-color: ${({ theme }) => theme.colors.primary.highlight};

      &::before {
        transform: translateX(${convertToRem(20)});
      }
    }
  }
`;

export const SwitchLabel = styled.span`
  margin-left: ${convertToRem(8)};
  
  color: ${({ theme }) => theme.colors.neutral.textDark};

  font-weight: 500;
  font-size: ${convertToRem(13)};
  line-height: ${convertToRem(23)};
`;

