import { Navigate, Route, Routes } from "react-router-dom";

import { AttendanceMetrics } from "./AttendanceMetrics";
import { ExamMetrics } from "./ExamMetrics";
import { GeneralMetrics } from "./GeneralMetrics";


export function Reports() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="metricas-de-atendimento" />} />
      <Route path="metricas-de-atendimento" element={<AttendanceMetrics />} />
      <Route path="metricas-de-exames" element={<ExamMetrics />} />
      <Route path="metricas-gerais" element={<GeneralMetrics />} />
    </Routes>
  );
}