import styled from "styled-components";
import chroma from "chroma-js";

import { convertToRem } from "@utils/convertToRem";

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.text};

  h4 {
    margin-top: ${convertToRem(20)};
  }
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => chroma(theme.colors.neutral.border).alpha(0.3).css()};
`;