import styled from "styled-components";

import { Container as Button } from "@components/Button/styles";

import { convertToRem } from "@utils/convertToRem";


export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(40)};
`;

export const Header = styled.div`
  display: grid;
  grid-template-columns: 4fr 8fr;
  gap: ${convertToRem(30)};
`;

export const Buttons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${convertToRem(30)};

  align-items: end;
`;

export const ScheduleContainer = styled.div`
  display: grid;
  grid-template-columns: ${convertToRem(370)} 1fr;
  gap: ${convertToRem(30)};

  width: 100%;
  min-height: ${convertToRem(570)};

  margin: ${convertToRem(40)} 0;
`;

export const ScheduleForm = styled.form`
  background-color: ${({ theme }) => theme.colors.neutral.white};

  padding: ${convertToRem(15)} ${convertToRem(20)};

  border-radius: ${convertToRem(5)};
  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

  h3 {
    margin-bottom: ${convertToRem(40)};

    color: ${({ theme }) => theme.colors.neutral.text};

    font-weight: 500;
    font-size: ${convertToRem(20)};
    line-height: 1.8;
  }
`;

export const SubmitContainer = styled.div`
  display: flex;
  align-items: flex-end;

  margin-top: ${convertToRem(20)};

  .react-datepicker-popper {
    top: ${convertToRem(450)} !important;
    left: ${convertToRem(152)} !important;
    transform: none !important;

    padding: 0 !important;
    padding-top: ${convertToRem(10)}  !important;
  }

  > ${Button} {
    height: ${convertToRem(40)};
    
    min-height: ${convertToRem(40)};
    min-width: ${convertToRem(55)};

    margin-left: ${convertToRem(10)};
  }
`;

export const ResultContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  padding: ${convertToRem(50)} ${convertToRem(80)};

  background-color: ${({ theme }) => theme.colors.neutral.white};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
  border-radius: ${convertToRem(5)};
`;

export const NavButton = styled.button<{ isPrev?: boolean; }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${convertToRem(30)};
  height: ${convertToRem(30)};

  background-color: ${({ theme }) => theme.colors.primary.lightest};
  color: ${({ theme }) => theme.colors.primary.highlight};

  border: 0;
  
  > svg {
    width: auto;
    height: ${convertToRem(20)};
    
    transform: ${({ isPrev }) => isPrev ? 'rotate(90deg)' : 'rotate(270deg)'};
  }
`;

export const ScheduleContent = styled.div`
  flex: 1;

  display: flex;

  margin: 0 ${convertToRem(30)};

  > div {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;
  }
`;

export const DayWeek = styled.strong`
  display: block;
  
  font-weight: 500;
  font-size: ${convertToRem(13)};
`;

export const Day = styled.span`
  display: block;

  margin: ${convertToRem(4)} 0 ${convertToRem(40)};

  font-weight: 400;
  font-size: ${convertToRem(15)};
`;

export const Hour = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${convertToRem(55)};
  height: ${convertToRem(25)};

  background-color: ${({ theme }) => theme.colors.primary.lightest};
  color: ${({ theme }) => theme.colors.primary.highlight};

  font-weight: 400;
  font-size: ${convertToRem(13)};
  line-height: 1;

  border-radius: ${convertToRem(4)};

  &:not(:first-of-type) {
    margin-top: ${convertToRem(10)};
  }
`;