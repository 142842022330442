import { FocusEvent, useEffect, useState } from "react";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { SingleValue } from "react-select";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import { useAuth } from "@contexts/auth";
import { useEmployee } from "@contexts/employee";

import { Loading } from "@components/Loading";
import { Input, InputGroup } from "@components/Input";
import { Select } from "@components/Select";
import { Button } from "@components/Button";

import LocationsService from "@services/LocationsService";

import { FormikEffect } from "@utils/FormikEffect";

import { OptionTypeBase } from "@components/Select/Select";
import { AxiosAPIError } from "@/types/api";

import { ButtonsContainer, FormContainer } from "./styles";

interface InitialValues {
  zipCode: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  uf: OptionTypeBase | null;
  city: OptionTypeBase | null;
}

export function AddressTab() {
  const { selectedAccount } = useAuth();
  const { employee, addEmployeeInfo, clearEmployeeInfo, validation } = useEmployee();

  const navigate = useNavigate();
  const { uuid } = useParams();
  
  const [isLoadingUFOptions, setIsLoadingUFOptions] = useState(true);
  const [isLoadingCityOptions, setIsLoadingCityOptions] = useState(false);
  
  const [UFOptions, setUFOptions] = useState<OptionTypeBase[]>([]);
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);

  const [isLoadingCEPInfo, setIsLoadingCEPInfo] = useState(false);
  const [isCEPValid, setIsCEPValid] = useState(true);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const formik = useFormik<InitialValues>({
    initialValues: {
      zipCode: employee.address.zipCode,
      street: employee.address.street,
      number: employee.address.number,
      complement: employee.address.complement,
      neighborhood: employee.address.neighborhood,
      uf: UFOptions.find(uf => uf.label === employee.address.state?.label) || null,
      city: cityOptions.find(city => city.label === employee.address.city?.label) || null,
    },
    validationSchema: Yup.object().shape({
      zipCode: Yup.string()
        .matches(/^[0-9]{5}-[0-9]{3}$/, "Infome um CEP válido")
        .required("O campo é obrigatório"),
      street: Yup.string()
        .required("O campo é obrigatório"),
      number: Yup.number()
        .typeError("Apenas números")
        .required("O campo é obrigatório"),
      neighborhood: Yup.string()
        .required("O campo é obrigatório"),
      uf: Yup.object({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um estado"),
      city: Yup.object({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione uma cidade"),
    }),
    onSubmit: async (values) => {
      if (!values.uf || !values.city || !selectedAccount) return;

      try {
        setIsSubmitting(true);

        const newProfessional = addEmployeeInfo({ 
          address: {
            zipCode: values.zipCode,
            street: values.street,
            number: values.number,
            complement: values.complement,
            neighborhood: values.neighborhood,
            state: values.uf,
            city: values.city,
          }
        });

        // const { data } = await ProfessionalsService.createProfessional(selectedAccount.id, newProfessional);

        // if (data.codigo === "200") {
        //   toast.success('Profissional cadastrado com sucesso!');
          
        //   clearProfessionalInfo();
        // }

        alert(JSON.stringify(newProfessional))
      } catch (err) {
        const error = err as AxiosAPIError;

        console.error(error);

        if (axios.isAxiosError(error)) {
          if (error.response) {
            toast.error(`${error.response.data.codigo} - ${error.response.data.menssagem}`);
          }
        } else {
          toast.error("Não foi possível atualizar os procedimentos");
        }
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  // Setting UF options
  useEffect(() => {
    async function getUFs() {
      try {
        setIsLoadingUFOptions(true);

        const { data: UFs } = await LocationsService.listUFs();

        const parsedUFs: OptionTypeBase[] = UFs.map((UF) => ({
          value: String(UF.id),
          label: UF.uf,
        }));

        setUFOptions(parsedUFs);

        return parsedUFs;
      } catch (error) {
        setUFOptions([]);

        toast.error("Não foi possível carregar as UFs");

        console.log(error);

        return null;
      } finally {
        setIsLoadingUFOptions(false);
      }
    }

    async function setAddress() {
      const UFs = await getUFs();
      
      if (uuid !== undefined) {
        const cities = await changeCityOptions(employee.address.state?.label as string);
  
        formik.setFieldValue('zipCode', employee.address.zipCode);
        formik.setFieldValue('street', employee.address.street);
        formik.setFieldValue('number', employee.address.number);
        formik.setFieldValue('complement', employee.address.complement);
        formik.setFieldValue('neighborhood', employee.address.neighborhood);
        formik.setFieldValue('uf', UFs?.find(uf => uf.label === employee.address.state?.label) || null);
        formik.setFieldValue('city', cities?.find(city => city.label === employee.address.city?.label) || null);
      }
    }

    setAddress();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Executing every time selectedUF changes
  useEffect(() => {
    formik.setFieldValue("city", null);

    if (!formik.values.uf) {
      setCityOptions([]);

      return;
    }

    changeCityOptions(formik.values.uf.label);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.uf]);

  function clearAddressFields() {
    formik.setFieldValue("street", "");
    formik.setFieldValue("number", "");
    formik.setFieldValue("complement", "");
    formik.setFieldValue("neighborhood", "");
    formik.setFieldValue("uf", null);
    formik.setFieldValue("city", null);
  }

  async function changeCityOptions(uf: string) {
    try {
      setIsLoadingCityOptions(true);

      if (!uf) throw new Error("Estado inválido! Não foi possível buscar as cidades");

      const { data: cities } = await LocationsService.listCitiesByUF(uf);

      const parsedCities: OptionTypeBase[] = cities.map((city) => ({
        value: String(city.id),
        label: city.cidade,
      }));

      setCityOptions(parsedCities);

      return parsedCities;
    } catch (error) {
      toast.error("Não foi possível carregar as cidades");

      console.log(error);

      return null;
    } finally {
      setIsLoadingCityOptions(false);
    }
  }

  async function handleBlurZipCode(event: FocusEvent<HTMLInputElement>) {
    const cep = event.target.value.replace(/[^\d]/g, "");

    if (cep.length !== 8) return clearAddressFields();

    try {
      setIsLoadingCEPInfo(true);

      const data = await LocationsService.getAddressByZipCode(Number(cep));

      if (data.erro) throw new Error("CEP Inválido!");

      setIsCEPValid(true);
      clearAddressFields();

      formik.setFieldValue("street", data.logradouro);
      formik.setFieldValue("complement", data.complemento);
      formik.setFieldValue("neighborhood", data.bairro);
      formik.setFieldValue("uf", UFOptions.find(uf => uf.label.toUpperCase() === data.uf.toUpperCase()) || null);

      const cities = await changeCityOptions(data.uf);
      formik.setFieldValue("city", cities?.find(city => city.label.toUpperCase() === data.localidade.toUpperCase()) || null);
    } catch (error) {
      console.log(error);

      setIsCEPValid(false);

      clearAddressFields();
    } finally {
      setIsLoadingCEPInfo(false);
    }
  }

  async function handleChangeUF(option: SingleValue<OptionTypeBase>) {
    formik.setFieldValue("uf", option);
    formik.setFieldValue("city", null);

    if (!option) {
      setCityOptions([]);
      return;
    }
    
    changeCityOptions(option.label);
  }

  const { isPersonalDataTabEmpty, isBankInfoTabEmpty, } = validation;

  if (isBankInfoTabEmpty) {
    navigate(`/configuracoes/colaboradores/${uuid !== undefined ? `editar/${uuid}` : 'adicionar'}/dados-bancarios`);

    return <div />;
  }

  if (isPersonalDataTabEmpty) {
    navigate(`/configuracoes/colaboradores/${uuid !== undefined ? `editar/${uuid}` : 'adicionar'}/dados-pessoais`);

    return <div />;
  }

  if (isLoadingUFOptions) return <Loading />

  return (
    <FormContainer onSubmit={formik.handleSubmit}>
      <FormikEffect formik={formik} />

      <InputGroup layout="1fr 4fr">
        <Input
          label="CEP"
          name="zipCode"
          mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
          value={formik.values.zipCode}
          onChange={formik.handleChange}
          onBlur={handleBlurZipCode}
          error={
            isCEPValid
            ? (formik.touched.zipCode || undefined) && formik.errors.zipCode
            : "CEP Inválido!"
          }
        />

        <Input 
          label="Endereço" 
          name="street"
          value={formik.values.street}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={(formik.touched.street || undefined) && formik.errors.street}
          disabled={isLoadingCEPInfo}
        />
      </InputGroup>

      <InputGroup layout="1fr 4fr 3fr" className="neighborhood">
        <Input 
          label="Número" 
          name="number" 
          value={formik.values.number}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={(formik.touched.number || undefined) && formik.errors.number}
        />

        <Input
          label="Complemento"
          name="complement"
          value={formik.values.complement}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          disabled={isLoadingCEPInfo}
        />

        <Input
          label="Bairro"
          name="neighborhood"
          value={formik.values.neighborhood}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={(formik.touched.neighborhood || undefined) && formik.errors.neighborhood}
          disabled={isLoadingCEPInfo}
        />
      </InputGroup>

      <InputGroup layout="1fr 4fr">
        <Select
          label="UF"
          name="uf"
          options={UFOptions}
          value={formik.values.uf}
          onChange={(selectedOption) => handleChangeUF(selectedOption)}
          error={(formik.touched.uf || undefined) && formik.errors.uf}
          isDisabled={isLoadingUFOptions || isLoadingCEPInfo} 
        />

        <Select
          label="Cidade"
          name="city"
          options={cityOptions}
          value={formik.values.city}
          onChange={option => formik.setFieldValue("city", option)}
          error={(formik.touched.city || undefined) && formik.errors.city}
          isDisabled={isLoadingCEPInfo || formik.values.uf === null || isLoadingCityOptions}
        />
      </InputGroup>

      <ButtonsContainer>
        <Button 
          variant="secondary"
          onClick={() => navigate(`/configuracoes/colaboradores/${uuid !== undefined ? `editar/${uuid}` : 'adicionar'}/dados-bancarios`)}
        >
          Voltar
        </Button>

        <Button type="submit" isLoading={isSubmitting}>
          Cadastrar
        </Button>
      </ButtonsContainer>
    </FormContainer>
  );
}