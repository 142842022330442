import faker from '@faker-js/faker';
import { delay } from '@utils/delay';

export type CustomerStatus = 'Clientes sem contato' | 'Em contato' | 'Agendados WhatsApp' | 'Agendados Ligação' | 'Agendados Promotora' | 'Faltas' | 'Compareceu' | 'Tratamento fechado' | 'Sem interesse';

export interface Customer {
  id: string;
  name: string;
  status: CustomerStatus; 
}

faker.setLocale('pt_BR');
class CustomersService {
  private customers: Customer[];

  constructor() {
    this.customers = [];

    for (let i = 0; i < 20; i++) {
      const { datatype, name, random } = faker;

      const customerName = `${name.firstName()} ${name.lastName()}`;
      const randomStatus = ['Clientes sem contato', 'Em contato', 'Agendados WhatsApp', 'Agendados Ligação', 'Agendados Promotora', 'Faltas', 'Compareceu', 'Tratamento fechado', 'Sem interesse'];

      this.customers.push({
        id: datatype.uuid(),
        name: customerName,
        status: random.arrayElement(randomStatus) as CustomerStatus,
      });
    }
  }

  async listCustomers(): Promise<Customer[]> {
    await delay();

    return new Promise((resolve) => {
      resolve(this.customers);
    });
  }
}

export default new CustomersService();