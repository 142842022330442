import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { format, parseISO } from 'date-fns';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { RiArrowDownLine, RiArrowUpLine, RiFilterLine, RiFilterOffLine, RiPencilLine, RiSendPlaneLine } from 'react-icons/ri';
import { BiTrashAlt } from 'react-icons/bi';
import axios from 'axios';

import { LoadingModal, Modal } from '@components/Modal';
import { Loading } from '@components/Loading';
import ResultCard, { ItemValue, ItemContainer, ItemDivider, ItemTitle, ActionsContainer, ActionButton } from '@components/ResultCard';
import { Select } from '@components/Select';
import { Tooltip } from '@components/Tooltip';
import { Button } from '@components/Button';
import { Input, InputGroup } from '@components/Input';
import { DatePicker } from '@components/DatePicker';

import LeadsService, { ListAffiliateLeadsFilters, ListAffiliateLeadsOrdersBy } from '@services/LeadsService';

import { ChevronDown } from '@assets/images';

import { genderOptions } from '@constants/genders';

import { formatPhoneNumber } from '@utils/formatPhoneNumber';

import { OptionTypeBase } from '@components/Select/Select';
import { AxiosAPIError, StatusColors } from '@/types/api';

import { EditModal, FilterModal, Footer, Header, ResultsContainer, Wrapper } from "./styles";

interface OrderSelectOptions {
  label: string;
  value: ListAffiliateLeadsOrdersBy;
}

const orderSelectOptions: OrderSelectOptions[] = [
  { value: 'name', label: 'Nome' },
  { value: 'created_at', label: 'Data de Cadastro' },
  { value: 'status_id', label: 'Status' },
];

const limitSelectOptions: OptionTypeBase[] = [
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
];

const statusSelectOptions: OptionTypeBase[] = [
  { value: '30', label: 'Aprovado' },
  { value: '31', label: 'Em análise' },
  { value: '29', label: 'Bloqueado' },
  { value: '32', label: 'Reprovado' },
];

interface FilterFormik {
  name: string;
  mail: string;
  registerDate: Date | null;
  status: OptionTypeBase | null;
}

interface EditFormik {
  name: string;
  whatsApp: string;
  gender: OptionTypeBase | null;
  birthDate: Date | null;
}

interface Lead {
  id: string;
  name: string;
  email: string;
  tel: string;
  whatsApp: string;
  registerDate: Date;
  birthDate: Date | null;
  gender: string;
  status: {
    id: number;
    name: string;
    color: StatusColors;
  };
  confirmationTrials: number;
};

export function HistoryLeads() {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(true);
  const [leads, setLeads] = useState<Lead[]>([]);

  const [selectedOrder, setSelectedOrder] = useState<ListAffiliateLeadsOrdersBy>('created_at');
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [isOrderAscending, setIsOrderAscending] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const [isFilterModalOpened, setIsFilterModalOpened] = useState(false);
  const [filters, setFilters] = useState<ListAffiliateLeadsFilters | undefined>(undefined);

  const filterFormik = useFormik<FilterFormik>({
    enableReinitialize: true,
    initialValues: {
      name: '',
      mail: '',
      registerDate: null,
      status: null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string(),
      mail: Yup.string()
        .email("Infome um e-mail válido"),
      registerDate: Yup.date()
        .typeError('Informa uma data válida')
        .nullable(),
      status: Yup.object({ value: Yup.string(), label: Yup.string() })
        .typeError('Selecione um status')
        .nullable(),
    }),
    onSubmit: (values) => {
      setIsFilterModalOpened(false);

      const isAllFieldsEmpty = Object.values(values).map(Boolean).every(e => e === false);
      if (isAllFieldsEmpty) {
        handleClearFilters();
        return;
      }

      setFilters({
        name: filterFormik.values.name || undefined,
        email: filterFormik.values.mail || undefined,
        created_at: filterFormik.values.registerDate ? format(filterFormik.values.registerDate, 'yyyy-MM-dd') : undefined,
        status_id: filterFormik.values.status ? Number(filterFormik.values.status.value) : undefined,
      });
    }
  });

  const [selectedLead, setSelectedLead] = useState<Lead | null>(null);
  const [isEditModalVisible, setIsEditModalVisible] = useState(false);
  const [isResendConfirmationModalVisible, setIsResendConfirmationModalVisible] = useState(false);
  const [isResending, setIsResending] = useState(false);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const editFormik = useFormik<EditFormik>({
    enableReinitialize: true,
    initialValues: {
      name: selectedLead?.name || '',
      whatsApp: selectedLead ? formatPhoneNumber(selectedLead.whatsApp) : '',
      birthDate: selectedLead?.birthDate || null,
      gender: genderOptions.find(gender => gender.value === selectedLead?.gender) || null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required("O campo é obrigatório"),
      whatsApp: Yup.string()
        .matches(/\(\d{2,}\) \d{5}-\d{4}/g, "Insira um WhatsApp válido")
        .required("O campo é obrigatório"),
      birthDate: Yup.date()
        .required("O campo é obrigatório")
        .typeError("O campo é obrigatório"),
      gender: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um gênero"),
    }),
    onSubmit: async (values) => {
      try {
        if (!selectedLead) throw new Error('Selecione um lead!');

        if (!values.birthDate) throw new Error('Selecione uma data de nascimento');
        if (!values.gender) throw new Error('Selecione um gênero!');

        const { data } = await LeadsService.updateAffiliateLead(selectedLead.id, {
          nome: values.name,
          whatsApp: values.whatsApp,
          data_nascimento: format(values.birthDate, 'yyyy-MM-dd'),
          genero: values.gender.value,
        });

        const newLeads = leads.map(lead => {
          if (lead.id !== selectedLead?.id) return lead;

          return ({
            ...lead,
            name: values.name,
            whatsApp: values.whatsApp,
            birthDate: values.birthDate,
            gender: values.gender?.value || '',
          });
        });

        setLeads(newLeads);
        setIsEditModalVisible(false);
        setSelectedLead(null);
        toast.success(data.menssagem);
      } catch (err) {
        const error = err as AxiosAPIError;

        console.error(error);

        if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
          error.response.data.dados.forEach(err => toast.error(err));
        } else {
          toast.error(error.message);
        }
      }
    }
  });

  useEffect(() => {
    async function getLeadsHistory() {
      try {
        setIsLoading(true);
  
        const { data } = await LeadsService.listAffiliateLeads({
          page: currentPage - 1,
          limit: itemsPerPage,
          order: isOrderAscending ? 'asc' : 'desc',
          orderBy: selectedOrder,
          filters
        });
        
        const maxPage = Math.ceil(data.total / itemsPerPage) || 1;
        if (data.pagina + 1 > maxPage) {
          setCurrentPage(maxPage);
        }
  
        const parsedLeads: Lead[] = data.dados.map((lead, index) => ({
          id: lead.uuid,
          name: lead.nome,
          email: lead.email,
          tel: lead.telefone,
          whatsApp: lead.whatsapp,
          registerDate: parseISO(lead.data_cadastro),
          birthDate: lead.data_nascimento ? parseISO(lead.data_nascimento) : null,
          gender: lead.genero,
          status: {
            id: lead.status_id,
            name: lead.status_nome,
            color: lead.status_cor,
          },
          confirmationTrials: lead.tentativas_restantes,
        }));
  
        setLeads(parsedLeads);
        setLastPage(maxPage);
      } catch (err) {
        const error = err as AxiosAPIError;

        console.error(error);

        if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
          error.response.data.dados.forEach(err => toast.error(err));
        } else {
          toast.error(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    }
    
    getLeadsHistory();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOrder, itemsPerPage, isOrderAscending, filters, currentPage]);

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  }

  function handleClearFilters() {
    filterFormik.resetForm();
    setIsFilterModalOpened(false);
    setFilters(undefined);
  }

  async function handleDeleteLead() {
    try {
      if (!selectedLead) throw new Error('Selecione um lead!');

      setIsDeleteModalVisible(false);
      setIsDeleting(true);
      
      const { data } = await LeadsService.deleteAffiliateLead(selectedLead.id);
      
      setLeads((prevState) => prevState.filter(lead => lead.id !== selectedLead.id));
      setSelectedLead(null);
      toast.success(data.menssagem);
    } catch (err) {
      const error = err as AxiosAPIError;

      console.error(error);

      if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
        error.response.data.dados.forEach(err => toast.error(err));
      } else {
        toast.error(error.message);
      }
    } finally {
      setIsDeleting(false);
    }
  }

  async function handleResendLeadConfirmation() {
    try {
      if (!selectedLead) throw new Error('Selecione um lead!');

      setIsResendConfirmationModalVisible(false);
      setIsResending(true);
      
      const { data } = await LeadsService.resendLeadConfirmationMessageAffiliate(selectedLead.id);
      
      setLeads((prevState) => prevState.map(lead => {
        if (lead.id !== selectedLead.id) return lead;

        return ({
          ...lead,
          confirmationTrials: lead.confirmationTrials - 1,
        });
      }));
      setSelectedLead(null);
      toast.success(data.menssagem);
    } catch (err) {
      const error = err as AxiosAPIError;

      console.error(error);

      if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
        error.response.data.dados.forEach(err => toast.error(err));
      } else {
        toast.error(error.message);
      }
    } finally {
      setIsResending(false);
    }
  }

  return (
    <>
      <FilterModal 
        visible={isFilterModalOpened}
        title='Filtros'
        onRequestClose={() => setIsFilterModalOpened(false)}
      >
        <form onSubmit={filterFormik.handleSubmit}>
          <Input 
            label="Nome"
            name="name"
            value={filterFormik.values.name}
            onChange={filterFormik.handleChange}
            error={(filterFormik.touched.name || undefined) && filterFormik.errors.name}
          />

          <Input 
            label="E-mail"
            name="mail"
            value={filterFormik.values.mail}
            onChange={filterFormik.handleChange}
            error={(filterFormik.touched.mail || undefined) && filterFormik.errors.mail}
          />

          <DatePicker 
            label="Data de Cadastro"
            name='registerDate' 
            selected={filterFormik.values.registerDate}
            onChange={selectedDate => filterFormik.setFieldValue('registerDate', selectedDate)}
            error={(filterFormik.touched.registerDate || undefined) && filterFormik.errors.registerDate}
            maxDate={new Date()}
            showYearDropdown
          />

          <Select 
            label="Status"
            name="status"
            options={statusSelectOptions}
            value={filterFormik.values.status}
            onChange={(selectedOptions) => filterFormik.setFieldValue("status", selectedOptions)}
            error={(filterFormik.touched.status || undefined) && filterFormik.errors.status}
          />

          <footer>
            {filters !== undefined && (
              <Button variant='secondary' onClick={handleClearFilters}>
                Limpar filtros
              </Button>
            )}

            <Button type='submit'>
              Filtrar
            </Button>
          </footer>
        </form>
      </FilterModal>

      <EditModal
        visible={selectedLead !== null && isEditModalVisible}
        onRequestClose={() => setIsEditModalVisible(false)}
        title="Editar Lead"
      >
        <form onSubmit={editFormik.handleSubmit}>
           <Input 
            label="Nome"
            name="name"
            value={editFormik.values.name}
            onChange={editFormik.handleChange}
            error={(editFormik.touched.name || undefined) && editFormik.errors.name}
          />

          <InputGroup>
            <Input 
              label="WhatsApp"
              type='tel'
              name="whatsApp"
              mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              value={editFormik.values.whatsApp}
              onChange={editFormik.handleChange}
              error={(editFormik.touched.whatsApp || undefined) && editFormik.errors.whatsApp}
            />
          </InputGroup>

          <InputGroup>
            <DatePicker 
              label="Data de nascimento"
              name="birthDate"
              selected={editFormik.values.birthDate}
              onChange={(date) => editFormik.setFieldValue('birthDate', date)}
              error={(editFormik.touched.birthDate || undefined) && editFormik.errors.birthDate}
              maxDate={new Date()}
              isClearable={false}
              showYearDropdown
            />

            <Select 
              label="Gênero"
              name="gender"
              options={genderOptions}
              value={editFormik.values.gender}
              onChange={option => editFormik.setFieldValue('gender', option)}
              error={(editFormik.touched.gender || undefined) && editFormik.errors.gender} 
              isClearable={false}
            />
          </InputGroup>

          <Button type='submit' isLoading={editFormik.isSubmitting}>
            Editar
          </Button>
        </form>
      </EditModal>

      <Modal 
        visible={selectedLead !== null && isDeleteModalVisible}
        title="Atenção"
        onRequestClose={() => setIsDeleteModalVisible(false)} 
      >
        <p className='text'>
          Deseja realmente excluir o lead <strong>{selectedLead ? selectedLead.name : ''}</strong>? <br /><br />
          
          Essa ação irá excluir todos os dados e é irreversível.
        </p>

        <div className='buttons'>
          <button onClick={() => setIsDeleteModalVisible(false)}>
            Não
          </button>

          <button onClick={handleDeleteLead} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <LoadingModal visible={isDeleting} title='Excluindo...' />

      <Modal 
        visible={selectedLead !== null && isResendConfirmationModalVisible}
        title="Atenção"
        onRequestClose={() => setIsResendConfirmationModalVisible(false)} 
      >
        <p className='text'>
          Deseja realmente reenviar mensagem de confirmação ao lead <strong>{selectedLead ? selectedLead.name : ''}</strong>?
        </p>

        <div className='buttons'>
          <button onClick={() => setIsResendConfirmationModalVisible(false)}>
            Não
          </button>

          <button onClick={handleResendLeadConfirmation} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <LoadingModal visible={isResending} title='Reenviando...' />

      <Wrapper>
        <Header>
          <h1>Histórico de Leads</h1>

          <div className="filters">
            <Select
              label="Classificar por"
              options={orderSelectOptions}
              value={orderSelectOptions.find(option => option.value === selectedOrder)}
              onChange={option => setSelectedOrder(option?.value || "created_at")}
              isClearable={false}
            />            

            <div className="order">
              <Select
                label="Exibir"
                options={limitSelectOptions}
                value={limitSelectOptions.find(option => option.label === String(itemsPerPage))}
                onChange={option => setItemsPerPage(Number(option?.label) || 10)}
                isClearable={false}
              />

              <Tooltip content={`Ordem ${isOrderAscending ? 'crescente' : 'decrescente'}`}>
                <Button 
                  variant='tertiary'
                  onClick={() => setIsOrderAscending((prevState) => !prevState)}
                >
                  {isOrderAscending ? <RiArrowDownLine /> : <RiArrowUpLine />}
                </Button>
              </Tooltip>
            </div>

            <Button 
              variant='tertiary' 
              onClick={() => setIsFilterModalOpened(true)}
            >
              <RiFilterLine />
              {filters === undefined ? 'Filtrar' : 'Exibir filtros'}
            </Button>
            
            {filters !== undefined && (
              <Button 
                variant='tertiary'
                onClick={handleClearFilters}
              >
                <RiFilterOffLine />
                Limpar filtros
              </Button>
            )}
          </div>
        </Header>

        <ResultsContainer isEmpty={isLoading || leads.length === 0}>
          {isLoading
            ? <Loading />
            : leads.length > 0
              ? leads
                .map(lead => (
                  <ResultCard 
                    key={lead.id} 
                    onClick={() => setSelectedLead(lead)}
                  >
                    <ItemContainer>
                      <div>
                        <ItemValue title={lead.name}>{lead.name}</ItemValue>
                        <ItemTitle>Nome</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ItemDivider />

                    <ItemContainer>
                      <div>
                        <ItemValue title={formatPhoneNumber(lead.tel)}>
                          {formatPhoneNumber(lead.tel)}
                        </ItemValue>
                        <ItemTitle>Telefone</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ItemDivider />

                    <ItemContainer>
                      <div>
                        <ItemValue title={lead.email}>
                          {lead.email || '-----------'}
                        </ItemValue>
                        <ItemTitle>E-mail</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ItemDivider />

                    <ItemContainer>
                      <div>
                        <ItemValue 
                          title={format(lead.registerDate, 'dd/MM/yyyy HH:mm:ss')}
                        >
                          {format(lead.registerDate, 'dd/MM/yyyy')}
                        </ItemValue>
                        <ItemTitle>Data de Cadastro</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ItemDivider />

                    <ItemContainer className="status">
                      <div>
                        <ItemValue
                          title={lead.status.name} 
                          style={{ 
                            backgroundColor: theme.colors.helpers[lead.status.color],
                          }}>
                            {lead.status.name}
                          </ItemValue>
                        <ItemTitle>Status</ItemTitle>
                      </div>
                    
                    </ItemContainer>
                    
                    <ActionsContainer>
                      <Tooltip content='Editar'>
                        <ActionButton 
                          color={theme.colors.helpers.warning}
                          onClick={() => setIsEditModalVisible(true)}
                        >
                          <RiPencilLine />
                        </ActionButton>
                      </Tooltip>

                      <Tooltip content='Reenviar confirmação'>
                        <ActionButton
                          disabled={lead.status.id !== 31 || lead.confirmationTrials <= 0} 
                          color={theme.colors.helpers.info}
                          onClick={() => setIsResendConfirmationModalVisible(true)}
                        >
                          <RiSendPlaneLine />
                        </ActionButton>
                      </Tooltip>
                      
                      <Tooltip content='Excluir'>
                        <ActionButton
                          color={theme.colors.helpers.danger}
                          onClick={() => setIsDeleteModalVisible(true)}
                        >
                          <BiTrashAlt />
                        </ActionButton>
                      </Tooltip>
                    </ActionsContainer>
                  </ResultCard>
                ))
              : 'Nenhum lead encontrado...'
          }
        </ResultsContainer>

        <Footer>
          <button 
            type='button' 
            className='page-btn prev' 
            onClick={goToPreviousPage}
            disabled={currentPage === 1}
          >
            <ChevronDown />
          </button>

          <span>{String(currentPage).padStart(2, "0")}</span>

          <button 
            type='button' 
            className='page-btn next'
            onClick={goToNextPage}
            disabled={currentPage === lastPage}
          >
            <ChevronDown />
          </button>
        </Footer>
      </Wrapper>
    </>
  );
}