export const clinicTypeOptions = [
  // { label: "Médica", value: "Médica" },
  // { label: "Odontológica", value: "Odontológica" },
  // { label: "Laboratório", value: "Laboratório" },
  // { label: "Médica e Laboratório", value: "Médica e Laboratório" },
  // { label: "Terapia", value: "Terapia" },
  { label: "ODONTOLOGICA", value: "1" },
  { label: "MEDICA", value: "2" },
  { label: "EXAMES", value: "3" },
  { label: "TRATAMENTOS", value: "4" },
];