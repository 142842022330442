import { createContext, ReactNode, useContext, useState } from 'react';

interface SearchProviderProps {
  children: ReactNode;
}

export interface SearchContextProps {
  searchValue: string;
  changeSearchValue: (searchValue: string) => void;
}

export const SearchContext = createContext<SearchContextProps>({} as SearchContextProps);

export function useSearch(): SearchContextProps {
  const context = useContext(SearchContext);

  if (!context) throw new Error('useSearch must be used within an SearchProvider');

  return context;
}

export function SearchProvider({ children }: SearchProviderProps) {
  const [searchValue, setSearchValue] = useState<string>('');

  function changeSearchValue(searchValue: string) {
    setSearchValue(searchValue);
  }

  return (
    <SearchContext.Provider value={{ searchValue, changeSearchValue }}>
      {children}
    </SearchContext.Provider>
  );
}

