import { useEffect, useState } from "react";
import { format, parseISO } from "date-fns";
import { useTheme } from "styled-components";

import { Description, Title } from "@components/Header";
import { DatePicker } from "@components/DatePicker";
import { Select } from "@components/Select";
import { Button } from "@components/Button";
import { Card } from "@components/Card";
import ResultCard, {
  ActionButton,
  ItemContainer,
  ItemDivider,
  ItemTitle,
  ItemValue,
  ActionsContainer,
} from "@components/ResultCard";

import {
  CancelSquare,
  Cart,
  CheckCircle,
  ChevronDown,
  Document,
  Info,
  List,
  Return,
  Search,
  UserRating,
} from "@assets/images";
import avatarUrl from "@assets/images/doctor-avatar.jpg";

import { formatToBRL } from "@utils/formatToBRL";
import { formatToPercent } from "@utils/formatToPercent";

import { OnChangeOptionType } from "@components/Select/Select.d";

import {
  CardsContainer,
  Content,
  ContentWrapper,
  FiltersContainer,
  Footer,
  Page,
  PageButton,
  Pagination,
  ResultsContainer,
  SearchContainer,
} from "./styles";
import { Tooltip } from "@components/Tooltip";

const classifySelectItems = [
  { value: "data", label: "Data" },
  { value: "profissional", label: "Profissional" },
  { value: "paciente", label: "Paciente" },
  { value: "valor", label: "Valor" },
  { value: "avaliação", label: "Avaliação" },
];

const limitSelectItems = [
  { value: "10", label: "10" },
  { value: "15", label: "15" },
  { value: "20", label: "20" },
  { value: "25", label: "25" },
];

const statsData = {
  income: {
    value: 200200,
    details: [
      {
        title: "Consultas médicas",
        value: 100200,
      },
      {
        title: "Estética",
        value: 1200,
      },
      {
        title: "Terapias",
        value: 1200,
      },
      {
        title: "Exames",
        value: 1200,
      },
      {
        title: "Outros",
        value: 1200,
      },
    ],
  },
  totalScheduled: {
    value: 820,
    details: [
      {
        title: "Consultas médicas",
        value: 200,
      },
      {
        title: "Estética",
        value: 200,
      },
      {
        title: "Terapias",
        value: 200,
      },
      {
        title: "Exames",
        value: 80,
      },
      {
        title: "Avaliação odontológica",
        value: 70,
      },
      {
        title: "Outros",
        value: 20,
      },
    ],
  },
  totalPaid: {
    value: 600,
    details: [
      {
        title: "Consultas médicas",
        value: 200,
      },
      {
        title: "Estética",
        value: 200,
      },
      {
        title: "Terapias",
        value: 200,
      },
      {
        title: "Exames",
        value: 80,
      },
      {
        title: "Outros",
        value: 20,
      },
    ],
  },
  totalConsultationReturns: {
    value: 205,
  },
  rating: {
    value: 95.54,
  },
};

const doctorsData = {
  date: parseISO("2020-02-01T00:00:00"),
  doctorName: "Dr. Juliano Luan",
  doctorAvatar: avatarUrl,
  doctorCRM: "123.5",
  rate: null,
  exam: "Ultrassonografia Toráxica",
  patient: "Pedro Paulo João",
  value: 300,
};

export function AttendanceMetrics() {
  const theme = useTheme();

  const [stats, setStats] = useState<typeof statsData | null>(null);
  const [doctors, setDoctors] = useState<Array<typeof doctorsData> | null>(
    null
  );

  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleChangeLimit = (selectedOption: OnChangeOptionType) => {
    if (selectedOption === null) return;

    setItemsPerPage(Number(selectedOption.value));
  };

  useEffect(() => {
    setStats(statsData);
    setDoctors(Array(21).fill(doctorsData));
  }, []);

  useEffect(() => {
    if (!doctors) return;

    const lastPage = Math.ceil(doctors.length / itemsPerPage);
    setLastPage(lastPage);

    if (currentPage > lastPage || lastPage === 1) {
      setCurrentPage(1);
    }
  }, [doctors, itemsPerPage, currentPage]);

  if (!stats || !doctors) return <div />;

  return (
    <Content>
      <ContentWrapper className="content">
        <Title>Métricas de Atendimento</Title>
        <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Description>

        <SearchContainer>
          <DatePicker
            label="Data inicial"
            name="initial-date"
            selectsStart
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            endDate={endDate}
          />

          <DatePicker
            label="Data final"
            name="final-date"
            selectsEnd
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            startDate={startDate}
            minDate={startDate}
          />

          <Select
            label="Profissional"
            placeholder="Escolha o profissional"
            options={[
              {
                value: "1",
                label: "Roberto Araújo",
              },
              {
                value: "2",
                label: "Carlos Souza",
              },
            ]}
          />

          <Select
            label="Comparecimento"
            options={[
              {
                value: "1",
                label: "Indefinido",
              },
              {
                value: "2",
                label: "Confirmado",
              },
            ]}
          />

          <Button>
            Buscar <Search />
          </Button>
        </SearchContainer>

        <CardsContainer>
          <Card
            icon={<Cart />}
            title="Faturamento"
            value={formatToBRL(stats.income.value)}
            details={stats.income.details.map((detail) => ({
              ...detail,
              value: formatToBRL(detail.value),
            }))}
          />

          <Card
            icon={<List />}
            title="Total Agendamentos"
            value={stats.totalScheduled.value}
            details={stats.totalScheduled.details}
          />

          <Card
            icon={<CheckCircle />}
            title="Total Pagos"
            value={stats.totalPaid.value}
            details={stats.totalPaid.details}
          />

          <Card
            icon={<Return />}
            title="Total de Retornos Consultas Médicas"
            value={stats.totalConsultationReturns.value}
          />

          <Card
            icon={<UserRating />}
            title="Avaliações"
            value={formatToPercent(stats.rating.value)}
          />
        </CardsContainer>

        <FiltersContainer>
          <Select
            label="Classificar por"
            options={classifySelectItems}
            defaultValue={classifySelectItems.find(
              (i) => i.label === "Profissional"
            )}
          />

          <Select
            label="Exibir últimos"
            options={limitSelectItems}
            onChange={handleChangeLimit}
            defaultValue={limitSelectItems.find(
              (i) => i.label === String(itemsPerPage)
            )}
          />
        </FiltersContainer>

        <ResultsContainer>
          {doctors
            .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
            .map((doctor, index) => (
              <ResultCard key={doctor.doctorCRM + "--" + index}>
                <ItemContainer>
                  <div>
                    <ItemValue>{format(doctor.date, "dd/MM/yyyy")}</ItemValue>
                    <ItemTitle>Data</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider />

                <ItemContainer>
                  <img src={doctor.doctorAvatar} alt={doctor.doctorName} />

                  <div>
                    <ItemValue>{doctor.doctorName}</ItemValue>
                    <ItemTitle>CRM {doctor.doctorCRM}</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider />

                <ItemContainer>
                  <div>
                    <ItemValue>{doctor.patient}</ItemValue>
                    <ItemTitle>{doctor.exam}</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider />

                <ItemContainer>
                  <div>
                    <ItemValue>{formatToBRL(doctor.value)}</ItemValue>
                    <ItemTitle>Valor</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider />

                <ItemContainer>
                  <div>
                    <ItemValue>
                      {doctor.rate ? formatToPercent(doctor.rate) : "Definir"}
                    </ItemValue>
                    <ItemTitle>Avaliação</ItemTitle>
                  </div>
                </ItemContainer>

                <ActionsContainer>
                  <Tooltip content="Inserir tooltip aqui">
                    <ActionButton color={theme.colors.helpers.danger} disabled>
                      <CancelSquare />
                    </ActionButton>
                  </Tooltip>

                  <Tooltip content="Inserir tooltip aqui">
                    <ActionButton color={theme.colors.helpers.info} disabled>
                      <Document />
                    </ActionButton>
                  </Tooltip>

                  <Tooltip content="Inserir tooltip aqui">
                    <ActionButton color={theme.colors.helpers.success}>
                      <Info />
                    </ActionButton>
                  </Tooltip>
                </ActionsContainer>
              </ResultCard>
            ))}
        </ResultsContainer>
      </ContentWrapper>

      <Footer>
        <div className="content">
          <Pagination>
            <PageButton
              className="prev"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              <ChevronDown />
            </PageButton>

            <Page>{String(currentPage).padStart(2, "0")}</Page>

            <PageButton
              className="next"
              onClick={goToNextPage}
              disabled={currentPage === lastPage}
            >
              <ChevronDown />
            </PageButton>
          </Pagination>
        </div>
      </Footer>
    </Content>
  );
}
