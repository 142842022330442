import { Route, Routes, Link } from "react-router-dom";

import { useAuth } from "@contexts/auth";

import { usePersistedState } from "@hooks/usePersistedState";

import { ProtectedRoute } from "./ProtectedRoute";

import { Loading } from "@components/Loading";
import { Button } from "@components/Button";

// Pages
import { Login } from "@pages/General/Login";
import { ForgotPassword } from "@pages/General/ForgotPassword";
import { ResetPassword } from "@pages/General/ResetPassword";
import { ActivateAccount } from "@pages/General/ActivateAccount";
import { SelectProfile } from "@pages/General/SelectProfile";
import { SelectAccount } from "@pages/General/SelectAccount";
import { RegisterAffiliate } from "@pages/General/RegisterAffiliate";
import { PrivacyPolicy } from "@pages/General/PrivacyPolicy";
import { RegisterLeads } from "@pages/General/RegisterLeads";
import { SuccessfulLeadRegistration } from "@pages/General/SuccessfulLeadRegistration";

// Dashboard Routes
import { ClinicRoutes } from "./ClinicRoutes";
import { ClinicAdminRoutes } from "./ClinicAdminRoutes";
import { ProfessionalRoutes } from "./ProfessionalRoutes";
import { AdminRoutes } from "./AdminRoutes";
import { PatientRoutes } from "./PatientRoutes";
import { AffiliateRoutes } from "./AffiliateRoutes";
import { AffiliateAdminRoutes } from "./AffiliateAdminRoutes";

import { ProfileAliasType } from "@services/SessionsService";

import { CookiesModal, LoadingContainer } from "./styles";

export type RoutesPerUser = Record<ProfileAliasType, JSX.Element>;

const routesPerUser: RoutesPerUser = {
  "clinica": <ClinicRoutes />,
  "clinica_admin": <ClinicAdminRoutes />,
  "profissional": <ProfessionalRoutes />,
  "admin": <AdminRoutes />,
  "patient": <PatientRoutes />,
  "enterprise": <div />,
  "promotor": <AffiliateRoutes />,
  "promotor_admin": <AffiliateAdminRoutes />,
};


export function AppRoutes() {
  const { isAuthLoading, authenticated, user, selectedProfile } = useAuth();

  const [isCookiesAccepted, setIsCookiesAccepted] = usePersistedState('AGENDA_CONSULTA@COOKIES_ACCEPTED', false);

  function handleAcceptCookies() {
    setIsCookiesAccepted(true);
  }

  if (isAuthLoading) {
    return (
      <LoadingContainer>
        <Loading />
        
        <h4>Autenticando...</h4>
      </LoadingContainer>
    );
  }

  return (
    <>
      <CookiesModal visible={!isCookiesAccepted} onRequestClose={() => {}}>
        <p>
          Usamos cookies para personalizar conteúdos e melhorar a sua experiência. Ao navegar nesse site, você concorda com nossa <Link to="/politica-de-privacidade">Política de Privacidade</Link>.
        </p>

        <Button type="button" onClick={handleAcceptCookies}>
          Concordo e fechar
        </Button>
      </CookiesModal>

      <Routes>
        <Route path="politica-de-privacidade" element={<PrivacyPolicy />} />
        <Route path="login/" element={<Login />} />
        <Route path="esqueci-minha-senha/" element={<ForgotPassword />} />
        <Route path="redefinir-senha/" element={<ResetPassword />} />
        <Route path="auth/conta-usuario/ativacao/:uuid" element={<ActivateAccount />} />
        <Route path="afiliado/cadastro" element={<RegisterAffiliate />} />
        <Route path="cadastro/*" element={<RegisterLeads />} />
        <Route path="cadastro/:uuid" element={<RegisterLeads />} />
        <Route path="cadastro-concluido/" element={<SuccessfulLeadRegistration />} />
        
        <Route 
          path="selecionar-perfil/" 
          element={
            <ProtectedRoute isAllowed={authenticated} redirectPath="/login">
              <ProtectedRoute isAllowed={user?.hasMoreThanOneProfile === true} redirectPath="/">
                <SelectProfile />
              </ProtectedRoute>
            </ProtectedRoute>
          } 
        />

        <Route 
          path="selecionar-conta/" 
          element={
            <ProtectedRoute isAllowed={authenticated} redirectPath="/login">
              <ProtectedRoute isAllowed={selectedProfile !== null} redirectPath="/selecionar-perfil">
                <ProtectedRoute isAllowed={selectedProfile?.hasMoreThanOneAccount === true} redirectPath="/">
                  <SelectAccount />
                </ProtectedRoute>
              </ProtectedRoute>
            </ProtectedRoute>
          } 
        />

        <Route 
          path="*" 
          element={
            <ProtectedRoute isAllowed={authenticated} redirectPath="/login">
              <ProtectedRoute isAllowed={selectedProfile !== null} redirectPath="/selecionar-perfil">
                {selectedProfile !== null 
                  ? routesPerUser[selectedProfile.alias] 
                  : <div />
                }
              </ProtectedRoute>
            </ProtectedRoute>
          } 
        />
      </Routes>
    </>
  );
}