import { useState } from "react";

import { Button } from "@components/Button";

import { Professionals } from "@services/ProfessionalsService";

import { Chip, OpenBook, User, WorkBag } from "@assets/images";

import { Container, Content } from "./styles";

interface ExperienceTabProps {
  data: Professionals;
}

export function ExperienceTab({ data }: ExperienceTabProps) {
  const [isFullDescriptionVisible, setIsFullDescriptionVisible] = useState(false);
  const [isFullMedicalConditionsVisible, setIsFullMedicalConditionsVisible] = useState(false);

  return (
    <Container>
      <Content>
        <h4>
          <User />
          <span>Sobre mim</span>
        </h4>
        {data.about.split("\n").map((paragraph, index) => {
          if (!isFullDescriptionVisible && index >= 1) return null;

          return <p key={index}>{paragraph}</p>;
        })}
        <Button
          onClick={() => setIsFullDescriptionVisible((prevState) => !prevState)}
        >
          {isFullDescriptionVisible
            ? "Ocultar descrição"
            : "Descrição completa"}
        </Button>
        <hr />

        <h4>
          <WorkBag />
          <span>Experiência em</span>
        </h4>
        <ul>
          {data.experiences.map((experience, index) => (
            <li key={experience + "--" + index}>{experience}</li>
          ))}
        </ul>

        <h4>
          <Chip />
          <span>Tratar condições médicas</span>
        </h4>
        <ul>
          {data.medicalConditions.map((medicalCondition, index) => {
            if (!isFullMedicalConditionsVisible && index >= 4) return null;

            return (
              <li key={medicalCondition + "--" + index}>{medicalCondition}</li>
            );
          })}
        </ul>
        <Button
          onClick={() =>
            setIsFullMedicalConditionsVisible((prevState) => !prevState)
          }
        >
          {isFullMedicalConditionsVisible ? "Ocultar lista" : "Lista completa"}
        </Button>
        <hr />

        <h4>
          <OpenBook />
          <span>Formação</span>
        </h4>
        <ul>
          {data.education.map((educate, index) => (
            <li key={educate.course + "--" + index}>
              {educate.school} ({educate.startYear}-{educate.endYear})<br />
              {educate.course}
            </li>
          ))}
        </ul>
      </Content>
    </Container>
  );
}
