import styled from 'styled-components';
import chroma from 'chroma-js';

import { convertToRem } from '@utils/convertToRem';


export const Container = styled.div<{ hasLabel: boolean; }>`
  width: 100%;
  
  margin-top: ${convertToRem(-3)};
  
  .react-datepicker {
    background-color: ${({ theme }) => theme.colors.neutral.white};
    color: ${({ theme }) => theme.colors.neutral.text};

    font-family: "Graphik";

    border-radius: ${convertToRem(8)};
    border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

    padding: ${convertToRem(20)};
  }

  .react-datepicker__header {
    background-color: transparent;

    padding: 0;
    border: 0;
    border-radius: 0;
  }

  .react-datepicker__day-names, 
  .react-datepicker__week { 
    display: flex;
  }

  .react-datepicker__day-names {
    margin-top: ${convertToRem(16)};
    margin-bottom: 0;

    border: solid ${convertToRem(0.5)} ${({ theme }) => theme.colors.neutral.border};
    border-bottom: 0;
  }

  .react-datepicker__day-name, 
  .react-datepicker__day, 
  .react-datepicker__time-name {
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${convertToRem(40)};
    height: ${convertToRem(40)}; 
    
    margin: 0;

    background-color: transparent;

    border: solid ${convertToRem(0.5)} ${({ theme }) => theme.colors.neutral.border};
    border-radius: 0;
  }

  .react-datepicker__day-name {
    color: ${({ theme }) => theme.colors.neutral.black};

    font-weight: 500;
    font-size: ${convertToRem(12)};
    line-height: ${convertToRem(14)};
    
    text-transform: capitalize;
  }

  .react-datepicker__month {
    margin: 0;

    border: solid ${convertToRem(0.5)} ${({ theme }) => theme.colors.neutral.border};
    border-top: 0;
  }

  .react-datepicker__day {
    color: ${({ theme }) => theme.colors.neutral.black};

    font-size: ${convertToRem(12)};
    line-height: ${convertToRem(15)};

    &:hover {
      background-color: ${({ theme }) => chroma(theme.colors.neutral.border).alpha(0.4).css()};
    }

    &.react-datepicker__day--today {
      font-weight: 500;

      color: ${({ theme }) => theme.colors.primary.highlight};
    }

    &.react-datepicker__day--outside-month {
      color: ${({ theme }) => chroma(theme.colors.neutral.black).alpha(0.3).css()};
    }

    &.react-datepicker__day--disabled {
      cursor: not-allowed;
      background-color: ${({ theme }) => chroma(theme.colors.neutral.border).alpha(0.25).css()};
      color: ${({ theme }) => chroma(theme.colors.neutral.black).alpha(0.3).css()};
    }
    
    &.react-datepicker__day:not(.react-datepicker__day--disabled):hover,
    &.react-datepicker__day--selected,
    &.react-datepicker__day--range-start,
    &.react-datepicker__day--range-end,
    &.react-datepicker__day--selecting-range-start,
    &.react-datepicker__day--selecting-range-end {
      font-weight: 600;

      background-color: ${({ theme }) => theme.colors.primary.highlight} !important;
      color: ${({ theme }) => theme.colors.neutral.white} !important;

      border-radius: 0;

      &:hover {
        background-color: ${({ theme }) => chroma(theme.colors.primary.highlight).darken(0.05).css()};
      }
    }

    &.react-datepicker__day--in-selecting-range,
    &.react-datepicker__day--in-range {
      background-color: ${({ theme }) => chroma(theme.colors.primary.highlight).brighten(3.25).css()};
      color: ${({ theme }) => theme.colors.primary.highlight};
    }
  }

  .react-datepicker__close-icon {
    position: absolute;
    top: ${({ hasLabel }) => hasLabel ? 'calc('+ convertToRem(21) +' + 50%)' : '50%'};
    right: ${convertToRem(12)};

    width: ${convertToRem(20)};
    height: ${convertToRem(20)};

    padding: 0;
    
    transform: ${({ hasLabel }) => hasLabel ? 'translateY(-100%) ': 'translateY(-50%)'};

    &::after {
      display: flex;
      align-items: center;
      justify-content: center;

      width: ${convertToRem(20)};
      height: ${convertToRem(20)};

      padding: 0;

      font-weight: 600;
      font-size: ${convertToRem(13)};

      background-color: ${({ theme }) => theme.colors.primary.highlight};
      color: ${({ theme }) => theme.colors.neutral.white};
    }

  }
`;

export const CustomHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  color: ${({ theme }) => theme.colors.neutral.black};

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${convertToRem(16)};
    height: ${convertToRem(16)};

    background-color: transparent;
    color: ${({ theme }) => theme.colors.neutral.black};

    border: 0;

    &.previous-month {
      margin-left: auto;
    }

    &.next-month {
      margin-left: ${convertToRem(8)};
    }

    &:disabled {
      opacity: 0.2;
      cursor: not-allowed;
    }
  }

  span {
    font-weight: 500;
    font-size: ${convertToRem(13)};
    line-height: 1;
    
    select {
      border: 0;
      background: none;

      margin-left: ${convertToRem(5)};

      font-family: 'Graphik';
      font-weight: 500;
      font-size: ${convertToRem(13)};
      line-height: 1;

      cursor: pointer;
    }
  }

`;