import { FocusEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { SingleValue } from "react-select";
import { RiArrowLeftLine, RiExternalLinkLine } from "react-icons/ri";
import { format, subYears } from "date-fns";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";

import { CPFCNPJInput, Input, InputGroup } from "@components/Input";
import { Select } from "@components/Select";
import { DatePicker } from "@components/DatePicker";
import { RadioGroup, RadioInput } from "@components/Radio";
import { Checkbox } from "@components/Checkbox";
import { Button } from "@components/Button";

import { genderOptions } from "@constants/genders";
import { pixKeyOptions } from "@constants/pix-keys";

import LocationsService from "@services/LocationsService";
import AffiliatesService from "@services/AffiliatesService";

import affiliateBanner from '@assets/images/affiliate-banner.png';
import affiliateBannerMobile from '@assets/images/affiliate-banner-mobile.png';

import { FormikEffect } from "@utils/FormikEffect";

import { OptionTypeBase } from "@components/Select/Select";
import { AxiosAPIError } from "@/types/api";

import { Container, Wrapper } from "./styles";

interface InitialValues {
  name: string;
  email: string;
  cpf: string;
  phone: string;
  gender: OptionTypeBase | null;
  birthDate: Date | null;

  facebook: string;
  instagram: string;

  receiptType: "pix" | "transfer";
  bank: string;
  keyType: OptionTypeBase | null;
  keyValue: string;
  bankAgency: string;
  bankAccount: string;

  zipCode: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  uf: OptionTypeBase | null;
  city: OptionTypeBase | null;

  isAgree: boolean;
}

export function RegisterAffiliate() {
  const navigate = useNavigate();

  const [isLoadingUFOptions, setIsLoadingUFOptions] = useState(true);
  const [isLoadingCityOptions, setIsLoadingCityOptions] = useState(false);
  
  const [UFOptions, setUFOptions] = useState<OptionTypeBase[]>([]);
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);

  const [isLoadingCEPInfo, setIsLoadingCEPInfo] = useState(false);
  const [isCEPValid, setIsCEPValid] = useState(true);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const maxDate = subYears(new Date(), 18);

  const formik = useFormik<InitialValues>({
    initialValues: {
      name: '',
      email: '',
      cpf: '',
      phone: '',
      gender: null,
      birthDate: null,

      facebook: '',
      instagram: '',

      receiptType: 'pix',
      keyType: null,
      keyValue: '',
      bank: '',
      bankAgency: '',
      bankAccount: '',

      zipCode: '',
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      uf: null,
      city: null,

      isAgree: false,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .matches(/(\w.+\s).+/i, "Insira seu nome completo")
        .required("O campo é obrigatório"),
      email: Yup.string()
        .email("Informe um e-mail válido")
        .required("O campo é obrigatório"),
      cpf: Yup.string()
        .matches(/^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/, "Insira um CPF válido")
        .required("O campo é obrigatório"),
      phone: Yup.string()
        .matches(/\(\d{2,}\) \d{5}-\d{4}/g, "Insira um telefone válido")
        .required("O campo é obrigatório"),
      gender: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um gênero"),
      birthDate: Yup.date()
        .max(format(maxDate, "yyyy-MM-dd"), 'O afiliado deve ser maior de 18 anos')
        .required("O campo é obrigatório")
        .typeError("O campo é obrigatório"),
      facebook: Yup.string()
        .required("O campo é obrigatório")
        .url("Insira um link válido"),
      instagram: Yup.string()
        .nullable(),
      receiptType: Yup.string()
        .required("O campo é obrigatório"),
      bank: Yup.string()
        .required("O campo é obrigatório"),
      keyType: Yup.object()
        .when("receiptType", {
          is: "pix",
          then: Yup.object()
            .shape({ value: Yup.string(), label: Yup.string() })
            .required("O campo é obrigatório")
            .typeError("Selecione um tipo de chave"),
          otherwise: Yup.object().nullable()
        }),
      keyValue: Yup.string()
        .when("receiptType", {
          is: "pix",
          then: Yup.string()
            .when("keyType.label", {
              is: "CPF/CNPJ",
              then: Yup.string().matches(
                /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/gm,
                "Insira um CPF/CNPJ válido"
              )
            })
            .when("keyType.label", {
              is: "E-mail",
              then: Yup.string().email("Insira um e-mail válido")
            })
            .when("keyType.label", {
              is: "Telefone",
              then: Yup.string().matches(/\(\d{2,}\) \d{4,5}-\d{4}/g, "Insira um telefone válido")
            })
            .required("O campo é obrigatório")
        }),
      bankAgency: Yup.string()
        .when("receiptType", {
          is: "transfer",
          then: Yup.string().required("O campo é obrigatório"),
        }),
      bankAccount: Yup.string()
        .when("receiptType", {
          is: "transfer",
          then: Yup.string().required("O campo é obrigatório"),
        }),
      zipCode: Yup.string()
        .matches(/^[0-9]{5}-[0-9]{3}$/, "Infome um CEP válido")
        .required("O campo é obrigatório"),
      street: Yup.string()
        .required("O campo é obrigatório"),
      number: Yup.number()
        .typeError("Apenas números")
        .required("O campo é obrigatório"),
      neighborhood: Yup.string()
        .required("O campo é obrigatório"),
      uf: Yup.object({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um estado"),
      city: Yup.object({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione uma cidade"),
      isAgree: Yup.boolean().required("O campo é obrigatório"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        if (isSubmitting || !values.uf || !values.city) return;
        
        if (!values.isAgree) throw new Error("É preciso ler e concordar com os termos!");

        setIsSubmitting(true);
  
        const { data } = await AffiliatesService.createAffiliate({
          nome: values.name,
          email: values.email,
          cpf: values.cpf,
          telefone: values.phone,
          genero: values.gender?.value as string,
          data_nascimento: values.birthDate ? format(new Date(String(values.birthDate)+' 00:00:00'), 'dd.MM.yyyy') : '',

          facebook: values.facebook,
          instagram: values.instagram,

          tipo_id: values.receiptType === 'pix' ? '2' : '1',
          banco: values.bank,
          chave_conta: values.receiptType === 'pix' ? values.keyType?.value as string : values.bankAccount,
          valor_agencia: values.receiptType === 'pix' ? values.keyValue : values.bankAgency, 

          endereco: {
            cep: values.zipCode,
            endereco: values.street,
            numero: values.number,
            bairro: values.neighborhood,
            complemento: values.complement,
            cidade: values.city.value,
            uf: values.uf.value,
          },

          aceite: values.isAgree,
        });

        toast.success(data.menssagem);
        resetForm();

        navigate('/login');
      } catch (err) {
        const error = err as AxiosAPIError;

        console.error(error);

        if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
          error.response.data.dados.forEach(err => toast.error(err));
        } else {
          toast.error(error.message);
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  // Setting UF options
  useEffect(() => {
    async function getUFs() {
      try {
        setIsLoadingUFOptions(true);

        const { data: UFs } = await LocationsService.listUFs();

        const parsedUFs: OptionTypeBase[] = UFs.map((UF) => ({
          value: String(UF.id),
          label: UF.uf,
        }));

        setUFOptions(parsedUFs);
      } catch (error) {
        setUFOptions([]);

        toast.error("Não foi possível carregar as UFs");

        console.log(error);
      } finally {
        setIsLoadingUFOptions(false);
      }
    }

    getUFs();
  }, []);

  // Settting cities (executing every time selectedUF changes)
  useEffect(() => {
    formik.setFieldValue("city", null);

    if (!formik.values.uf) {
      setCityOptions([]);

      return;
    }

    changeCityOptions(formik.values.uf);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.uf]);
  
  function clearAddressFields() {
    formik.setFieldValue("street", "");
    formik.setFieldValue("complement", "");
    formik.setFieldValue("neighborhood", "");
    formik.setFieldValue("uf", null);
    formik.setFieldValue("city", null);
  }

  async function changeCityOptions(ufOption: SingleValue<OptionTypeBase>) {
    try {
      setIsLoadingCityOptions(true);

      if (!ufOption) throw new Error("Estado inválido! Não foi possível buscar as cidades");

      const { data: cities } = await LocationsService.listCitiesByUF(ufOption.label);

      const parsedCities: OptionTypeBase[] = cities.map((city) => ({
        value: String(city.id),
        label: city.cidade,
      }));

      setCityOptions(parsedCities);

      return parsedCities;
    } catch (error) {
      toast.error("Não foi possível carregar as cidades");

      console.log(error);

      return null;
    } finally {
      setIsLoadingCityOptions(false);
    }
  }

  async function handleBlurZipCode(event: FocusEvent<HTMLInputElement>) {
    const cep = event.target.value.replace(/[^\d]/g, "");

    if (cep.length !== 8) return clearAddressFields();

    try {
      setIsLoadingCEPInfo(true);

      const data = await LocationsService.getAddressByZipCode(Number(cep));

      if (data.erro) throw new Error("CEP Inválido!");

      setIsCEPValid(true);

      formik.setFieldValue("street", data.logradouro);
      formik.setFieldValue("complement", data.complemento);
      formik.setFieldValue("neighborhood", data.bairro);
      formik.setFieldValue("uf", UFOptions.find(uf => uf.label.toUpperCase() === data.uf.toUpperCase()) || null);

      const cities = await changeCityOptions(UFOptions.find(uf => uf.label.toUpperCase() === data.uf.toUpperCase()) || null);
      formik.setFieldValue("city", cities?.find(city => city.label.toUpperCase() === data.localidade.toUpperCase()) || null);
    } catch (error) {
      console.log(error);

      setIsCEPValid(false);

      clearAddressFields();
    } finally {
      setIsLoadingCEPInfo(false);
    }
  }

  async function handleChangeUF(option: SingleValue<OptionTypeBase>) {
    if (!option) {
      formik.setFieldValue("uf", null);
      formik.setFieldValue("city", "");

      setCityOptions([]);
      return;
    }

    formik.setFieldValue("uf", option);
    changeCityOptions(option);
  }

  return (
    <Container>
      <Wrapper onSubmit={formik.handleSubmit}>
        <FormikEffect formik={formik} />

        <header>
          <div className="promo-image">
            <img src={affiliateBanner} alt="Imagem Promocional" />
            <img src={affiliateBannerMobile} className="mobile" alt="Imagem Promocional" />
          </div>

          <Link to="/login" className="go-back">
            <RiArrowLeftLine />
            Voltar para Login
          </Link>

          <h1>Cadastro de Afiliados</h1>
        </header>

        <section>
          <div className="card">
            <h3>Informações pessoais</h3>

            <Input 
              label="Nome"
              name="name"
              value={formik.values.name}
              onChange={formik.handleChange}
              error={(formik.touched.name || undefined) && formik.errors.name}
            />

            <Input 
              label="E-mail"
              type='email'
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={(formik.touched.email || undefined) && formik.errors.email}
            />

            <InputGroup>
              <Input 
                label="CPF"
                type='tel'
                name="cpf"
                mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                value={formik.values.cpf}
                onChange={formik.handleChange}
                error={(formik.touched.cpf || undefined) && formik.errors.cpf}
              />

              <Input 
                label="Telefone"
                type='tel'
                name="phone"
                mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                value={formik.values.phone}
                onChange={formik.handleChange}
                error={(formik.touched.phone || undefined) && formik.errors.phone}
              />
            </InputGroup>

            <InputGroup>
              <Select 
                label="Gênero"
                name="gender"
                options={genderOptions}
                value={formik.values.gender}
                onChange={(option) => formik.setFieldValue("gender", option)}
                error={(formik.touched.gender || undefined) && formik.errors.gender}
                isClearable={false}
              />

              <Input 
                label="Data de Nascimento"
                name="birthDate"
                type="date"
                value={String(formik.values.birthDate)}
                onChange={formik.handleChange}
                error={(formik.touched.birthDate || undefined) && formik.errors.birthDate}
              />
            </InputGroup>
          </div>
        </section>

        <section>
          <div className="card">
            <h3>Redes sociais</h3>

            <InputGroup>
              <Input 
                label="Link Facebook*"
                type='url'
                name="facebook"
                value={formik.values.facebook}
                onChange={formik.handleChange}
                error={(formik.touched.facebook || undefined) && formik.errors.facebook}
              />

              <Input 
                label="Instagram"
                type='text'
                name="instagram"
                value={formik.values.instagram}
                onChange={formik.handleChange}
                error={(formik.touched.instagram || undefined) && formik.errors.instagram}
              />
            </InputGroup>

            <span className="description">*O campo "Link Facebook" é obrigatório, considerando que o afiliado atuará através da rede social.</span>
          </div>
        </section>

        <section>
          <div className="card">
            <h3>
              Dados bancários
              <strong>em nome do titular<span>*</span></strong>
            </h3>
            
            <RadioGroup label="Tipo de Recebimento">
              <RadioInput 
                label="Pix"
                name="pix"
                value="pix"
                isChecked={formik.values.receiptType === 'pix'}
                handleChange={() => formik.setFieldValue("receiptType", "pix")}
              />

              <RadioInput 
                label="Transferência"
                name="transfer"
                value="transfer"
                isChecked={formik.values.receiptType === 'transfer'}
                handleChange={() => formik.setFieldValue("receiptType", "transfer")}
              />
            </RadioGroup>

            <Input 
              label="Banco"
              name="bank"
              value={formik.values.bank}
              onChange={formik.handleChange}
              error={(formik.touched.bank || undefined) && formik.errors.bank}
            />

            {formik.values.receiptType === 'pix'
              ? (
                <InputGroup>
                  <Select 
                    label="Tipo de Chave"
                    name="keyType"
                    options={pixKeyOptions}
                    value={formik.values.keyType}
                    onChange={(option) => {
                      formik.setFieldValue("keyType", option);

                      switch (option?.label) {
                        case 'CPF/CNPJ': return formik.setFieldValue("keyValue", formik.values.cpf);
                        case 'E-mail': return formik.setFieldValue("keyValue", formik.values.email);
                        case 'Telefone': return formik.setFieldValue("keyValue", formik.values.phone);
                        default: return formik.setFieldValue("keyValue", "");
                      }
                      
                    }}
                    error={(formik.touched.keyType || undefined) && formik.errors.keyType}
                    isClearable={false}
                  />

                  {formik.values.keyType?.label === 'CPF/CNPJ'
                    ? (
                      <CPFCNPJInput 
                        label="Chave"
                        name="keyValue"
                        value={formik.values.keyValue}
                        onChange={formik.handleChange}
                        error={(formik.touched.keyValue || undefined) && formik.errors.keyValue}
                      />
                    )
                    : (
                      <Input 
                        label="Chave"
                        type={
                          formik.values.keyType?.label === 'Telefone' 
                          ? 'tel' 
                          : formik.values.keyType?.label === 'E-mail' 
                            ? 'email'
                            : 'text'
                        }
                        mask={
                          formik.values.keyType?.label === 'Telefone'
                          ? ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
                          : undefined
                        }
                        name="keyValue"
                        value={formik.values.keyValue}
                        onChange={formik.handleChange}
                        error={(formik.touched.keyValue || undefined) && formik.errors.keyValue}
                      />
                    )
                  }
                </InputGroup>
              )
              : (
                <InputGroup>
                  <Input 
                    label="Agência"
                    name="bankAgency"
                    value={formik.values.bankAgency}
                    onChange={formik.handleChange}
                    error={(formik.touched.bankAgency || undefined) && formik.errors.bankAgency}
                  />

                  <Input 
                    label="Conta"
                    name="bankAccount"
                    value={formik.values.bankAccount}
                    onChange={formik.handleChange}
                    error={(formik.touched.bankAccount || undefined) && formik.errors.bankAccount}
                  />
                </InputGroup>
              )
            }
          </div>
        </section>

        <section>
          <div className="card">
            <h3>Endereço</h3>

            <InputGroup layout="1fr 4fr">
              <Input
                label="CEP"
                name="zipCode"
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                value={formik.values.zipCode}
                onChange={formik.handleChange}
                onBlur={handleBlurZipCode}
                error={
                  isCEPValid
                  ? (formik.touched.zipCode || undefined) && formik.errors.zipCode
                  : "CEP Inválido!"
                }
              />

              <Input 
                label="Endereço" 
                name="street"
                value={formik.values.street}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={(formik.touched.street || undefined) && formik.errors.street}
                disabled={isLoadingCEPInfo}
              />
            </InputGroup>

            <InputGroup layout="1fr 2.5fr 3fr" className="neighborhood">
              <Input 
                label="Número" 
                name="number" 
                value={formik.values.number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={(formik.touched.number || undefined) && formik.errors.number}
              />

              <Input
                label="Complemento"
                name="complement"
                value={formik.values.complement}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isLoadingCEPInfo}
              />

              <Input
                label="Bairro"
                name="neighborhood"
                value={formik.values.neighborhood}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={(formik.touched.neighborhood || undefined) && formik.errors.neighborhood}
                disabled={isLoadingCEPInfo}
              />
            </InputGroup>

            <InputGroup layout="1fr 4fr">
              <Select
                label="UF"
                name="uf"
                options={UFOptions}
                value={formik.values.uf}
                onChange={(selectedOption) => handleChangeUF(selectedOption)}
                error={(formik.touched.uf || undefined) && formik.errors.uf}
                isDisabled={isLoadingUFOptions || isLoadingCEPInfo} 
              />

              <Select
                label="Cidade"
                name="city"
                options={cityOptions}
                value={formik.values.city}
                onChange={option => formik.setFieldValue("city", option)}
                error={(formik.touched.city || undefined) && formik.errors.city}
                isDisabled={isLoadingCEPInfo || formik.values.uf === null || isLoadingCityOptions}
              />
            </InputGroup>
          </div>
        </section>

        <section>
          <div className="card">
            <h3>Termos e Condições</h3>

            <div className="terms">
              <h5>
                PROGRAMA DE AFILIADOS AGENDA CONSULTA<br />
                Termos e Condições de Uso - Afiliados Agenda Consulta
              </h5>

              <p>Data da última alteração: 16/05/2021</p>
              
              <strong>Introdução</strong>
              <p>Ao acessar ou utilizar a plataforma Agenda Consulta, bem como ao fazer sua indicação no programa de Afiliados da Agenda Consulta, você concorda com este Termos e Condições de Uso (Termo) e sujeita-se a ele. Leia atentamente, porque aqui você vai encontrar informações importantes sobre seus direitos, recursos e deveres.</p>
              <p>O Termo tem o objetivo de regular o programa de Afiliados da Agenda Consulta (GAC SERVIÇOS TECNOLÓGICOS LTDA), inscrita no Cadastro Nacional de Pessoas Jurídicas nº 33.505288/0001-05, sediada na Rua Godolfredo Gonçalves, 375, Sala 01 – Centro - CEP 35.680-047, Itaúna/MG.</p>

              <strong>Definições</strong>
              <p>Para auxiliar o entendimento deste Termo utilizam-se algumas palavras abaixo:</p>
              <ul>
                <li><strong>Anúncio:</strong> Publicação de tratamentos odontológicos em Grupos do Facebook.</li>
                <li><strong>Clínicas:</strong> Instituições de Saúde Credenciada pelo CRO, é a empresa de saúde que presta o serviço de odontológico independente na plataforma Agenda Consulta.</li>
                <li><strong>Lead:</strong> Um lead é um consumidor em potencial que de alguma maneira demonstrou interesse em um serviço ou produto.</li>
                <li><strong>Afiliado/usuário:</strong> Pessoa que indica e ou coleta leads de pessoas interessadas em tratamentos odontológicos através de Anúncios em Grupos do Facebook.</li>
              </ul>

              <strong>Escopo da Indicação/coleta</strong>
              <p>A indicação de lead será realizada através do preenchimento de formulário de Indicação da Agenda Consulta. A responsabilidade de fornecer, atualizar e garantir que os dados são verdadeiros é exclusiva dos Usuários. A Agenda Consulta não tem responsabilidade civil ou criminal em relação aos dados fornecidos a Agenda Consulta, sejam eles falsos, incorretos ou incompletos. A Agenda Consulta não espera nenhuma contrapartida do Afiliado/Usuário, sendo o ato de indicar/coletar uma mera obrigação de dar. Para evitar fraudes e garantir a segurança de operações, a Agenda Consulta reserva-se o direito de utilizar todos os meios válidos para identificar Afiliados/Usuários e solicitar informações e documentos adicionais que confirmem a autenticidade dos dados. Só podem ser indicados/coletados leads de pessoas interessadas em tratamentos odontológicos, que estejam dentro das cidades e regiões de atendimento da Agenda Consulta.</p>
              <p>Só haverá pagamento pelas indicações/coletas com características adequadas aos padrões da Agenda Consulta e nas condições previstas neste Termo.</p>
              <p>Consideram-se inválidas e não serão objeto de pagamento as indicações: (i) que sejam feitas contra a vontade do Lead; (ii) de pessoas que não forneçaram seus dados; (iii) referentes a leads já coletados anteriormente ou (iv) referentes a um Lead que já tenha sido indicado/coletado nos últimos 60 dias por outro afiliado.</p>
              <p>O Usuário/Afiliado aceita que as indicações em desacordo com este Termo serão descartadas, não gerando, assim, qualquer direito a valor ou outros direitos em razão da Indicação/coleta.</p>
              <p>As Indicações feitas através do formulário são válidas por 48 (quarenta e oito) horas corridas, contados a partir da data de preenchimento do formulário de interesse pelo Lead. Após esse período, se o lead não for importado para a Agenda Consulta não deverá nenhum valor ao Usuário/Afiliado cadastrado, por qualquer motivo.</p>
              <p>A segunda forma de realizar uma Indicação/Coleta é através de um link individual que a Agenda Consulta disponibiliza para que cada Usuário/Afiliado possa enviar diretamente para as pessoas interessadas. Esse tipo de indicação não tem prazo de validade e haverá pagamento pela Indicação/coleta se o Lead se cadastrar com o Nome e Telefone.</p>

              <strong>Condições de Uso</strong>
              <p>A aceitação de Indicações pela Agenda Consulta destina-se, exclusivamente, para as pessoas capazes de exercer atos da vida civil, qualquer acesso ou uso das plataformas Agenda Consulta por menores de 18 anos não emancipados é expressamente proibido.</p>
              <p>Ao realizar uma Indicação/coleta, os Usuários/Afiliados expressamente afirmam que os Leads estão cientes da indicação/coleta ou seja forneceram seus dados como nome e telefone demonstrando interesse na oportunidade, e são integralmente responsáveis pela veracidade das informações e pelo compartilhamento de dados. A Agenda Consulta reserva-se o direito de revelar as informações dos Usuários/Afiliados diretamente aos Leads em caso de reclamações devidamente fundamentadas sobre Indicações/coletas realizadas contra sua vontade.</p>
              <p>O cadastro do Usuário/Afiliado permite apenas a vinculação a um número de CPF, um único telefone, um endereço de e-mail, e a uma conta bancária, não podendo haver duplicidade de dados em nenhum caso.</p>
              <p>O Usuário/Afiliado irá acessar sua conta com seu número de telefone ou e-mail e se compromete a não informar seus dados a outras pessoas, assumindo a responsabilidade pelo uso que fizerem.</p>
              <p>Se o Usuário/Afiliado identificar uso não autorizado de sua conta, deverá notificar a Agenda Consulta imediatamente.</p>
              <p>Veja o que é esperado dos Usuários/Afiliados na Agenda Consulta nosso Manual do usuário/afiliado. <a href="#" target="_blank" rel="noreferrer"><RiExternalLinkLine /></a></p>
              <p>A Agenda Consulta poderá a seu exclusivo critério, em caso de suspeita de fraude e do descumprimento deste Termo ou do Manual do usuário , interromper e/ou excluir o Usuário/Afiliado responsável e cancelar a participação nas Indicações/Coletas temporária ou permanentemente, podendo gerar também bloqueio dos pagamentos.</p>
              <p>Por meio da adesão a este Termo, não são cedidos quaisquer direitos de uso do nome, marca ou logotipo e os Usuários/Afiliados não podem se vincular à empresa, para não induzir outras pessoas a erro. Também não é permitida a divulgação, por meio de anúncios patrocinados ou outros meios, do link de Indicação exclusiva do Usuário/Afiliado.</p>

              <strong>Responsabilidades</strong>
              <p><strong>A Agenda Consulta não se responsabiliza:</strong></p>
              <ul>
                <li>Por qualquer transação realizada diretamente entre Leads e Usuários/Afiliados, mesmo que tenham se conhecido por meio da Agenda Consulta.</li>
                <li>Por garantir a eficácia de qualquer contratação, ou a qualidade de qualquer serviço. A Agenda Consulta não é responsável por quaisquer danos ou indenizações decorrentes de falha no cumprimento de obrigação por qualquer Usuário/Afiliado.</li>
                <li>Pelas obrigações tributárias relativas às atividades dos Usuários/Afiliado na Plataforma. O Usuário/Afiliado é exclusiva e integralmente responsável por cumprir com as obrigações tributárias necessárias decorrentes de suas atividades na Plataforma ou Serviços da Agenda Consulta.</li>
                <li>Pela conduta dos Usuários/Afiliados ou de terceiros contratados ou contatados por meio da Plataforma. A Agenda Consulta não se responsabiliza por qualquer ato ou omissão de qualquer Usuário em relação a outros Usuários ou terceiros.</li>
                <li>Por quaisquer indisponibilidades ou erros apresentados na Plataforma, assim como por eventual defraudação da utilidade que o Usuário possa atribuir à Plataforma.</li>
                <li>Por erros ou eventuais inconsistências na transmissão de dados, pela qualidade ou disponibilidade da conexão de Internet, que impeçam o Usuário/Afiliado ou a Agenda Consulta de receber informações.</li>
                <li>Por danos e prejuízos por conta de falhas relacionadas ao Usuário/Afiliados ou a terceiros que fujam do controle da Agenda Consulta.</li>
              </ul>

              <p><strong>O Usuário/Afiliado concorda e se compromete a não:</strong></p>
              <ul>
                <li>Realizar indicações/coleta sem a prévia autorização do lead ou o interesse do mesmo que forneceu os dados por livre e espontãnea vontade. Prejudicar a reputação, imagem ou boa-fé da Agenda Consulta</li>
                <li>Fazer-se  passar por qualquer entidade ou prestar informações falsas sobre os Serviços da Agenda Consulta, executar qualquer manipulação para disfarçar a identidade ou a procedência de qualquer mensagem ou contato do lead.</li>
                <li>Utilizar qualquer “robô”, “spider”, “web scraping”, programa, “script”, algoritmo ou metodologia automatizada, ou qualquer processo manual, para acessar, copiar ou monitorar sites, redes sociais ou plataformas terceiras, atuando em desacordo com seus respectivos Termos e Condições de Uso.</li>
                <li>Utilizar qualquer Serviço da Agenda Consulta para inserir qualquer tipo de “spam”, Mensagem não solicitada, fraude, “scam”, “phishing”, “correntes”, “pirâmides” ou conduta similar.</li>
                <li>Descumprir o presente Termo de Uso ou qualquer lei ou requisito aplicável ao uso dos Serviços da Agenda Consulta.</li>
              </ul>

              <strong>Preço</strong>
              <p>Os valores de recompensa pelas Indicações/Coletas de Leads estão disponíveis neste link <a href="#" target="_blank" rel="noreferrer"><RiExternalLinkLine /></a>.</p>
              <p>A Agenda Consulta poderá realizar campanhas e aumentar ou diminuir os valores das recompensas. Essas alterações podem abarcar todos os Usuários/Afiliados cadastrados, sem qualquer necessidade de igualar as recompensas de diferentes regiões ou qualquer outra diferenciação entre eles.</p>
              <p>As recompensas podem ter valores diferentes, dependendo do resultado da Indicação/Coleta. Se a recompensa for diferente, todas as informações sobre valores e outros detalhes estarão na campanha.</p>
              <p>A Indicação/coleta será creditada no mês em que realizada caso seja registrada até as 21h do horário de Brasília no último dia do mês. Após isso, a Indicação/coleta será creditada no mês seguinte.</p>
              <p>As recompensas serão pagas aos Usuários/Afiliados cadastrados, conforme as condições mostradas neste Termo, levando-se em consideração as indicações/coletas realizadas dentro da área de atuação da Agenda Consulta, por meio de depósito ou PIX direto na conta corrente indicada pelo Usuário/Afiliado.</p>
              
              <strong>Propriedade Intelectual</strong>
              <p>A Agenda Consulta é o único detentor de todos os direitos de propriedade intelectual relacionados aos seus serviços, tais como: código-fonte, módulos, pacotes, estrutura de funcionamento, modelo de negócios, algoritmos e todas as informações relativas ao uso, funcionamento e demais dados e documentações.</p>
              <p>A Agenda Consulta garante que é titular da tecnologia e das ferramentas autorizadas para execução dos serviços e, quando não for, tem as autorizações para usar as ferramentas.</p>
              <p>O banco de dados das Plataformas e os dados que o integram, brutos ou elaborados, são propriedade exclusiva da Agenda Consulta, que sobre eles possui todos os direitos de propriedade intelectual.</p>
              <p>Nenhum item neste Termo é considerado como transferência ou cessão de direito de propriedade intelectual, somente é permitido ao Usuário/Afiliado a utilização da propriedade intelectual da Agenda Consulta enquanto houver o cumprimento e a observância deste Termo.</p>

              <strong>Entre em contato</strong>
              <p>A Agenda Consulta poderá entrar em contato com você por telefone, e-mail, WhatsApp, ou outros canais que você tenha compartilhado para comunicar questões relativas à prestação de serviços, ofertar produtos/serviços, convidar você para contribuir com pesquisas, entre outros.</p>
              <p>São consideradas válidas as comunicações dos Usuários/Afiliados realizadas por escrito e enviadas para e-mail <strong>contato@agendaconsulta.com</strong>.</p>

              <strong>Aceitação</strong>
              <p>O Usuário/Afiliado declara estar ciente e de acordo com todos os itens mencionados neste Termo.</p>

              <strong>Disposições Gerais</strong>
              <p>Qualquer condição deste Termo que, por qualquer razão, venha a ser reputada nula ou ineficaz por qualquer juízo ou tribunal, não afetará a validade das demais disposições do Termo, as quais permanecerão plenamente válidas e vinculantes, gerando efeitos em sua máxima extensão.</p>
              <p>A Agenda Consulta busca, constantemente, melhorar o Termo, seus produtos e serviços, razão pela qual estas mudanças poderão ser refletidas neste Termo, reservando o direito de adicionar, remover ou atualizar conteúdo, funcionalidades ou softwares utilizados na prestações dos serviços.</p>
              <p>Os Termos são regidos pelas leis vigentes na República Federativa do Brasil. Para todos os assuntos referentes à interpretação, ao cumprimento ou qualquer outro questionamento relacionado a estes Termos de Uso, as Partes concordam em se submeter ao Foro da Comarca de Itaúna/MG, com exceção de reclamações apresentadas por Usuários/Afiliados que se enquadrem no legalmente como consumidores, que poderão submeter tais reclamações ao foro de seu domicílio.</p>


              <h5>Indique/Colete Leads e ganhe prêmios em dinheiro</h5>
              <p>O Programa de Afiliados Agenda Consulta é o programa de indicação e geração de leads de pessoas interessadas em tratamentos odontológicos</p>

              <strong>Como funciona?</strong>
              <ol>
                <li>Poste os anúncios da Agenda Consulta nos Grupos do Facebook nas cidades;</li>
                <li>Indique/colete leads dos comentários das publicação postadas ou compartilhe seu link de indicação;</li>
                <li>Quando você coletar o lead e adicionar na plataforma da Agenda Consulta, você ganha de R$ 1,00 a R$ 1,80 por cada lead.</li>
                <li>
                  Veja a tabela de recompensa abaixo:
                  <table>
                    <tr>
                      <th>Meta</th>
                      <th>Valor por indicação</th>
                    </tr>

                    <tr>
                      <td>De 1 a 299</td>
                      <td>R$ 1,00</td>
                    </tr>

                    <tr>
                      <td>De 300 a 499</td>
                      <td>R$ 1,50</td>
                    </tr>

                    <tr>
                      <td>De 500 a 999</td>
                      <td>R$ 1,70</td>
                    </tr>

                    <tr>
                      <td>Acima de 1000</td>
                      <td>R$ 1,80</td>
                    </tr>
                  </table>
                </li>
                <li>Se indicar/coletar 1000 leads você será recompensado com R$ 1.800,00 em dinheiro.</li>
                <li>Os pagamentos serão realizados todo dia 15 de cada mês.</li>
              </ol>

              <strong>Como me cadastrar?</strong>
              <ol>
                <li>Acesse o site da Agenda Consulta <strong><a href="https://www.agendaconsulta.com/" target="_blank" rel="noreferrer">www.agendaconsulta.com</a></strong> ou entre em contato com o nosso RH pelo e-mail <strong>rh@agendaconsulta.com</strong>.</li>
                <li>Informe seu número de cellular, e-mail, nome completo, CPF e conta bancária. Pronto. Você fará parte do Programa de Afiliados.</li>
                <li>Assista o video treinamento para começar a indicar/coletar leads.</li>
              </ol>

              <strong>E os prêmios?</strong>
              <p>Sempre que um lead entra na plataforma da Agenda Consulta indicado/coleta por você.</p>
              <p>Você será premiado em 4 momentos:</p>
              <ol>
                <li>1 a 299 leads – coletados/indicados = R$ 1,00 por cada lead</li>
                <li>300 a 499 leads - coletados/indicados = R$ 1,50 por cada lead</li>
                <li>500 a 999 leads - coletados/indicados = R$ 1,70 por cada lead</li>
                <li>Acima de 1000 leads - coletados/indicados = R$ 1,80 por cada lead</li>
              </ol>

              <strong>Quais são os requisitos para participar do Programa de Afiliados Agenda Consulta?</strong>
              <p>Para participar, você precisa ser pessoa física, ter mais de 18 anos de idade e CPF.</p>
              <p>Se esse é o seu caso, indique e colete leads para começar a ganhar.</p>

              <Checkbox 
                label="Declaro que li e concordo com os termos"
                name="isAgree"
                checked={formik.values.isAgree}
                onChange={formik.handleChange}
              />
            </div>
          </div>
        </section>

        <Button type="submit" isLoading={isSubmitting}>
          Cadastrar
        </Button>
      </Wrapper>
    </Container>
  );
}