import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { FiLock, FiMinusCircle, FiPlusCircle, FiUnlock } from "react-icons/fi";

import { useProfessional } from "@contexts/professional";

import { TimeInput } from "@components/TimeInput";
import { Tooltip } from "@components/Tooltip";
import { Button } from "@components/Button";
import { Error } from "@components/Input/styles";

import { ProfessionalSchedule, ProfessionalTime } from "@contexts/professional";

import { FormikEffect } from "@utils/FormikEffect";

import { Container, Sidebar, SchedulesContainer, SchedulesMenu, SchedulesMenuButton, Label, ConsultationDurationContainer, ButtonsContainer, ScheduleItem } from "./styles";


interface InitialValues {
  consultationDuration: string;
  schedule: ProfessionalSchedule[];
}

export function SchedulesTab() {
  const { professional, addProfessionalInfo, validation } = useProfessional();

  const navigate = useNavigate();
  const { uuid } = useParams();

  const [activeTab, setActiveTab] = useState(1);

  const formik = useFormik<InitialValues>({
    enableReinitialize: true,
    initialValues: {
      consultationDuration: professional.consultationDuration,
      schedule: professional.schedule,
    },
    validationSchema: Yup.object().shape({
      consultationDuration: Yup.string()
        .notOneOf(["00:00"], "Informe uma duração")
        .required("O campo é obrigatório"),
      schedule: Yup.array().of(
        Yup.object().shape({
          weekday: Yup.string().required("O campo é obrigatório"),
          times: Yup.array().when("isDisabled", {
            is: false,
            then: Yup.array().of(
              Yup.object().shape({
                start: Yup.string()
                  .notOneOf(["00:00"], "Informe um horário de início")
                  .required("O campo é obrigatório"),
                end: Yup.string()
                  .notOneOf(["00:00"], "Informe um horário de término")
                  .required("O campo é obrigatório"),
              })
            ),
            otherwise: Yup.array().of(
              Yup.object().shape({
                start: Yup.string(),
                end: Yup.string(),
              })
            ),
          }),
          isDisabled: Yup.boolean().required("O campo é obrigatório"),
        })
      ),
    }),
    onSubmit: (values) => {
      addProfessionalInfo({ 
        consultationDuration: values.consultationDuration,
        schedule: values.schedule,
      });

      navigate(`/profissionais/${uuid !== undefined ? `editar/${uuid}` : 'adicionar'}/configuracoes`);
    }
  });


  function handleChangeTime(position: number, timePosition: number, value: Partial<ProfessionalTime>) {
    const updatedSchedule = formik.values.schedule.map((item, index) => {
      if (index !== position) return item;

      return {
        ...item,
        times: item.times.map((item, index) => {
          if (index !== timePosition) return item;

          return { ...item, ...value };
        }),
      };
    });

    formik.setFieldValue("schedule", updatedSchedule, formik.submitCount > 0);
  }

  function handleAddTime(position: number) {
    const updatedSchedule = formik.values.schedule.map((item, index) => {
      if (index !== position) return item;
      
      return {
        ...item,
        times: item.times.concat({ start: '00:00', end: '00:00' }),
      };
    });

    formik.setFieldValue("schedule", updatedSchedule, formik.submitCount > 0);
  }

  function handleRemoveTime(position: number, timePosition: number) {
    const updatedSchedule = formik.values.schedule.map((item, index) => {
      if (index !== position) return item;
      
      return {
        ...item,
        times: item.times.filter((_, i) => i !== timePosition),
      };
    });

    formik.setFieldValue("schedule", updatedSchedule, formik.submitCount > 0);
  }

  function handleToggleDisableWeekDay(position: number) {
    const updatedSchedule = formik.values.schedule.map((item, index) => {
      if (index !== position) return item;

      return { ...item, isDisabled: !item.isDisabled }
    });

    formik.setFieldValue("schedule", updatedSchedule, formik.submitCount > 0);
  }
  
  const { isGeneralTabEmpty } = validation;

  if (isGeneralTabEmpty) {
    navigate(`/profissionais/${uuid !== undefined ? `editar/${uuid}` : 'adicionar'}/geral`);

    return <div />;
  }

  return (
    <Container onSubmit={formik.handleSubmit}>
      <FormikEffect formik={formik} />

      <Sidebar>
        <div>
          <Label>Horários</Label>
          <SchedulesMenu>
            <SchedulesMenuButton
              type="button"
              className={activeTab === 1 ? "active" : ""}
              onClick={(e) => setActiveTab(1)}
            >
              Horários fixos
            </SchedulesMenuButton>

            <SchedulesMenuButton
              type="button"
              className={activeTab === 2 ? "active" : ""}
              onClick={(e) => setActiveTab(2)}
            >
              Horários online
            </SchedulesMenuButton>
            <SchedulesMenuButton
              type="button"
              className={activeTab === 3 ? "active" : ""}
              onClick={(e) => setActiveTab(3)}
            >
              Horários especiais
            </SchedulesMenuButton>
          </SchedulesMenu>
        </div>

        <div>
          <Label>Duração da Consulta</Label>
          <ConsultationDurationContainer>
            <TimeInput 
              value={formik.values.consultationDuration} 
              onChange={(e) => formik.setFieldValue("consultationDuration", e.target.value, formik.submitCount > 0)}
              error={formik.errors.consultationDuration}
            />
          </ConsultationDurationContainer>
        </div>
      </Sidebar>

      <SchedulesContainer>
        <div>
          {formik.values.schedule.map((item, position) => (
            <ScheduleItem 
              key={`${item.weekday}--${position}`} 
              disabled={item.isDisabled}
            >
              <span className="day">{item.weekday}</span>

              <div className="times">
                {item.times.map((time, timePosition, timeArr) => (
                  <div className="time">
                    <div className="time-wrapper">
                      <button 
                        type="button" 
                        className={`remove-time ${timeArr.length === 1 ? 'hide' : ''}`}
                        onClick={() => handleRemoveTime(position, timePosition)}
                      >
                        <FiMinusCircle />
                      </button>

                      <p>De</p>

                      <TimeInput
                        value={time.start}
                        onChange={(e) => handleChangeTime(position, timePosition, { start: e.target.value})}
                        disabled={item.isDisabled}
                        error={
                          formik.errors.schedule && 
                          formik.errors.schedule[position] && 
                          (formik.errors.schedule[position] as any).times && 
                          (formik.errors.schedule[position] as any).times[timePosition] && 
                          (formik.errors.schedule[position] as any).times[timePosition].start
                        }
                        hideError
                      />

                      <p>até</p>

                      <TimeInput
                        value={time.end}
                        onChange={(e) => handleChangeTime(position, timePosition, { end: e.target.value})}
                        disabled={item.isDisabled}
                        error={
                          formik.errors.schedule && 
                          formik.errors.schedule[position] &&
                          (formik.errors.schedule[position] as any).times &&
                          (formik.errors.schedule[position] as any).times[timePosition] &&
                          (formik.errors.schedule[position] as any).times[timePosition].end
                        }
                        hideError
                      />

                      <button 
                        type="button" 
                        className={`add-time ${timePosition !== (timeArr.length - 1) ? 'hide' : ''}`}
                        onClick={() => handleAddTime(position)}
                      >
                        <FiPlusCircle />
                      </button>
                    </div>

                    <div className="errors">
                      {
                        (formik.errors.schedule && formik.errors.schedule[position] && (formik.errors.schedule[position] as any).times && (formik.errors.schedule[position] as any).times[timePosition] && (formik.errors.schedule[position] as any).times[timePosition].start)
                        ? <Error>{(formik.errors.schedule[position] as any).times[timePosition].start}</Error>
                        : null
                      }

                      {
                        (formik.errors.schedule && formik.errors.schedule[position] && (formik.errors.schedule[position] as any).times && (formik.errors.schedule[position] as any).times[timePosition] && (formik.errors.schedule[position] as any).times[timePosition].end)
                        ? <Error>{(formik.errors.schedule[position] as any).times[timePosition].end}</Error>
                        : null
                      }
                    </div>
                  </div>
                ))}
              </div>

              <Tooltip content={item.isDisabled ? 'Ativar' : 'Desativar'}>
                <button 
                  type="button"
                  className="disable-btn" 
                  onClick={() => handleToggleDisableWeekDay(position)}
                >
                  {item.isDisabled ? <FiUnlock /> : <FiLock />}
                </button>
              </Tooltip>
            </ScheduleItem>
          ))}
        </div>

        <ButtonsContainer>
          <Button 
            variant="secondary"
            onClick={() => navigate(`/profissionais/${uuid !== undefined ? `editar/${uuid}` : 'adicionar'}/geral`)}
          >
            Voltar
          </Button>

          <Button type="submit">
            Próximo
          </Button>
        </ButtonsContainer>
      </SchedulesContainer>
    </Container>
  );
}
