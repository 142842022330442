import { Link } from "react-router-dom";

import { AgendaConsultaExpand } from "@assets/images";

import { Container, Footer, Header, Wrapper } from "./styles";

export function PrivacyPolicy() {
  return (
    <Container>
      <Header>
        <div className="content">
          <Link to="/">
            <AgendaConsultaExpand />
          </Link>

          <Link to="/login">Voltar para login</Link>
        </div>
      </Header>

      <Wrapper className="content">
        <h1>Política de Privacidade</h1>
        <p>Nós, da GAC – Serviços Tecnológicos Ltda, (a “AGENDA CONSULTA” ou “nós”), estamos comprometidos em resguardar sua privacidade e proteger seus dados. O intuito deste documento é esclarecer quais informações são coletadas dos usuários de nossos sites – <a href="https://agendaconsulta.com">https://agendaconsulta.com</a> – e respectivos serviços – (“Você”) e de que forma esses dados são utilizados</p>

        <p>Para oferecer nossos serviços, a AGENDA CONSULTA, por meio de seu site e de seus aplicativos e sistemas, do equipamento onde este está instalado ou sendo utilizado e dos dispositivos sincronizados com este, coleta diversos dados e informações, visando, sobretudo, proporcionar uma experiência cada vez melhor para você. A AGENDA CONSULTA reconhece que a sua privacidade é muito importante, portanto, tomamos todas as medidas possíveis para protegê-la. Nesse sentido, a presente Política de Privacidade (“Política”) visa lhe informar como as suas informações e dados pessoais serão coletados, usados, compartilhados e armazenados por meio dos nossos sites e respectivos serviços. Este documento é parte integrante dos nossos <a href="https://dentistas.agendaconsulta.com/termosdeuso">Termos de Uso do Site</a>, que contém uma visão geral da nossa plataforma.</p>

        <p><strong>Esta Política de Privacidade se aplica quando você utiliza os nossos Serviços que envolvem coleta de dados online e offline, incluindo os Dados Pessoais que coletados por meio de nossos vários serviços e canais, incluindo websites, aplicativos, redes sociais de terceiros, Serviço de Atendimento ao Consumidor, pontos de venda e eventos. Nossos usuários podem escolher, em determinados casos, os tipos de dados que coletamos, utilizamos e compartilhamos, conforme descrito nesta Política de Privacidade.</strong></p>

        <p><strong>A aceitação da nossa Política será feita quando você se cadastrar em nossa plataforma para usufruir de um dos serviços mencionados acima. Tal aceitação se dará mediante o momento em que você, de forma afirmativa, concordar com o uso dos seus dados para cada uma das finalidades por nós aqui descritas. Isso indicará que você está ciente e em total acordo com a forma como utilizaremos as suas informações e os seus dados. Se você optar por não nos fornecer os Dados Pessoais necessários para utilizar os nossos produtos, ou para a prestação dos nossos serviços (nós indicaremos quando for o caso), ou não concordar com essa Política, nós não poderemos fornecer a você nossos produtos e/ou serviços. Caso não concorde com esta Política, por favor, não continue o seu procedimento de registro e não use os nossos serviços. Todavia, por favor, nos informe a sua discordância para que possamos esclarecê-las ou melhorá-las</strong></p>

        <p>Este documento foi redigido de forma simples e acessível, contando com vários exemplos de coleta e de uso dos dados, justamente para que você possa ler e entender a forma como utilizamos os seus dados para lhe oferecer uma experiência segura e confortável no uso dos serviços que oferecemos a você.</p>

        <p>A presente Política está dividida da seguinte forma para facilitar a sua compreensão:</p>
        <ol>
          <li>Quais são as fontes de dados pessoais?</li>
          <li>Que tipos de dados a AGENDA CONSULTA coleta?</li>
          <li>Com quem a AGENDA CONSULTA compartilha os seus dados?</li>
          <li>Transferência internacional de dados</li>
          <li>Por quanto tempo os dados serão armazenados?</li>
          <li>Como funciona a Segurança da Informação na AGENDA CONSULTA?</li>
          <li>Como falar com o DPO da AGENDA CONSULTA?</li>
          <li>Mudanças na Política</li>
        </ol>


        <h2>1. Quais são as fontes de dados pessoais?</h2>
        <p>A quantidade e o tipo de informações coletadas pela AGENDA CONSULTA varia conforme o uso que você faz dos nossos serviços. Coletaremos diferentes dados caso você esteja visitando nosso site, utilizando nossos softwares, utilizando os serviços de clientes dos nossos softwares ou os serviços da AGENDA CONSULTA. E para tornar mais acessível essas definições e diferenças, segmentamos em 04 partes a coleta dos dados dos usuários, para que você possa compreender o que se aplica especificamente à você.</p>

        <p>Esta Política se aplica aos Dados Pessoais que coletamos de ou sobre você por meio das seguintes fontes:</p>

        <ul>
          <li>Sites da Agenda Consulta. Sites voltados para o consumidor e operados pela ou para a AGENDA CONSULTA incluindo sites que Nós operamos sob nossos próprios domínios/URLs e mini sites que Nós mantemos em redes sociais de terceiros, como o Facebook (sites).</li>

          <li>Sites/aplicativos móveis Agenda Consulta. Sites ou aplicativos móveis voltados para o consumidor e operados pela ou para a AGENDA CONSULTA, como aplicativos para smartphones.</li>

          <li>Interações com anúncios. Interações com nossos anúncios (por exemplo, se você interage com um ou mais de nossos anúncios em um site de um terceiro, nós podemos receber informações sobre aquela interação).</li>

          <li>Dados criados por Nós. Durante nossas interações com Você, poderemos criar Dados Pessoais sobre Você (por exemplo, mas não limitado a, registros de suas compras em nossos sites, e registros de suas interações com os nossos conteúdos e e-mails).</li>

          <li>Eventos. Eventos realizados e organizados pela AGENDA CONSULTA, bem como eventos nos quais a AGENDA CONSULTA participa como patrocinador ou expositor.</li>
        </ul>

        <p>Cabe ainda mencionar que armazenamos informações a respeito de todos os contatos já realizados com nossos usuários, independentemente do tipo de usuário que realizou o contato. Tais informações incluem conteúdos baixados a partir de nossas páginas e interações via e-mail, como abertura e cliques.</p>

        <p>Ao clicar nos botões de compartilhamento de conteúdo nas redes sociais disponíveis em nossas páginas, o usuário estará publicando o conteúdo por meio de seu perfil na rede selecionada. A AGENDA CONSULTA não tem acesso ao login e senha dos usuários nessas redes, nem publicará conteúdo em nome do usuário sem que ele realize esta ação.</p>
        
        <p>Vale ressaltar que todos os dados que coletamos são utilizados para a prestação de nossos serviços. Por isso, todos os dados e informações sobre você são tratados como confidenciais, e somente os usaremos para os fins aqui descritos e autorizados por você, principalmente para que você possa utilizar nossos serviços de forma plena, visando sempre melhorar a sua experiência como usuário. Nesse sentido, veja na seção 2 o que se aplica a você.</p>

        <h3>1.1. Quais são as Bases Legais para coleta dos dados?</h3>
        <p>Utilizamos como base legal de coleta de dados apenas formas autorizadas por lei, como, mas não limitada ao consentimento, o legítimo interesse e a regular execução dos contratos firmados. Quando a base legal para a coleta não for o consentimento, sempre observaremos a legislação vigente, bem como respeitaremos os direitos e liberdades individuais dos titulares dos dados.</p>


        <h2>2. Que tipos de dados a AGENDA CONSULTA coleta?</h2>

        <h3>2.1. Visitantes dos sites</h3>
        <p>Caso você apenas acesse os nossos sites, coletaremos seus dados de navegação. No caso, quando você visita o nosso site é inserido um “cookie” no seu navegador por meio do software Google Analytics, para identificar quantas vezes você retorna ao nosso endereço.</p>

        <p>Também usamos Cookies e dados de navegação, como URLs (Uniform Resource Locators) para coletar informações sobre a data e a hora da sua visita e as soluções e informações para as quais você pesquisou e visualizou. Como a maioria das empresas de tecnologia, coletamos automaticamente esses dados pessoais e os armazenamos em arquivos de registro sempre que você visita nosso site ou acessa sua conta em nossa rede. Podemos usar cookies de sessão (que expiram depois de fechar o navegador) e cookies persistentes (que permanecem no seu computador até que você os exclua) para fornecer uma experiência mais pessoal e interativa em nosso site. Os Cookies Persistentes podem ser removidos seguindo as instruções do arquivo de ajuda do seu navegador da Internet. Você pode optar por limitar, recusar ou desabilitar os Cookies através das configurações do seu navegador. Contudo, ao fazer isso, algumas áreas do nosso site podem não funcionar corretamente, o que poderá impedir que você se beneficie de alguns de nossos recursos.</p>

        <p>Novamente, esta Política de Privacidade não cobre o uso de Cookies por terceiros, e não somos responsáveis por suas políticas e práticas de privacidade. Tenha em atenção que os Cookies colocados por terceiros podem eventualmente continuar a monitorizar as suas atividades online mesmo depois de ter saído dos nossos Serviços.</p>

        <p>Novamente, esta Política de Privacidade não cobre o uso de Cookies por terceiros, e não somos responsáveis por suas políticas e práticas de privacidade. Tenha em atenção que os Cookies colocados por terceiros podem eventualmente continuar a monitorizar as suas atividades online mesmo depois de ter saído dos nossos Serviços.</p>

        <h3>2.2. Leads da AGENDA CONSULTA</h3>
        <p>Se você interagir com os nossos materiais e conteúdos, coletaremos, além de seus dados de navegação mencionados na parte de Visitantes, os seus dados de visita às nossas páginas, e outras informações que você optar compartilhar conosco, através das suas interações com os nossos formulários, Landing Pages e e-mails, por exemplo.</p>

        <p>Para oferecer conteúdos e materiais do seu interesse, muitas vezes é necessário realizar o cruzamento de dados que coletamos de você, para que as nossas ações de marketing sejam direcionadas àquilo que você espera receber de nós. Garantimos que esse cruzamento não é feito de forma discriminatória, não ferindo quaisquer direitos ou liberdades individuais suas. Além disso, você pode, a qualquer tempo, solicitar alteração de remoção de alterar ou remover tais permissões de tratamento de dados, através do nosso e-mail: contato@agendaconsulta.com.</p>

        <p>Considerando todos esses pontos, algumas possibilidades de uso dos seus dados poderão ocorrer. Nesse sentido, recomendamos que leia os exemplos abaixo:</p>
        <ul>
          <li>Seu e-mail é utilizado para a operação de envio do material ou informação por você requisitada no preenchimento do formulário. Também pode ser usado para envio de Newsletters, sempre relacionadas a temas de saúde, anúncios de clínicas parceiras, tratamentos odontológicos, promoções de saúde, divulgação de vagas em saúde, e, ainda comunicação de lançamento de novos materiais gratuitos ou de novos produtos da AGENDA CONSULTA e parceiros. No entanto, o usuário pode cancelar a assinatura a qualquer momento;</li>

          <li>Funcionários da AGENDA CONSULTA poderão eventualmente entrar em contato via e-mail ou telefone para fazer pesquisas ou apresentar produtos e serviços;</li>

          <li>Enviar a você mensagens a respeito de suporte ou serviço, como alertas, notificações e atualizações;</li>
          
          <li>Nos comunicar com você sobre produtos, serviços, promoções, notícias, atualizações, eventos e outros assuntos que você possa ter interesse;</li>

          <li>Realizar publicidade direcionada conforme seus gostos, interesses e outras informações coletadas.</li>
        </ul>

        <h3>2.3. Usuários dos Sites e Serviços da AGENDA CONSULTA</h3>
        <p>Se você utilizar algum de nossos serviços e/ou produtos, iremos coletar as seguintes categorias e tipos de dados pessoais: dados de contato (nome, e-mail e telefone), dados locacionais (país, estado e cidade), dados de perfil profissional (Nº de registro no conselho, cargo, nome e segmento da empresa onde você trabalha, número de funcionários dessa empresa, formação acadêmica e anos de experiência), dados de faturamento (nome, CPF, endereço, cartão de crédito), dados para fins de marketing (suas preferências de marketing e informações sobre seu CRM).</p>

        <p>Caso você se cadastre pela sua conta no Facebook/Google, iremos coletar os dados pessoais publicamente acessíveis no seu perfil da rede social, como nome e foto de perfil. O fornecimento do e-mail é opcional, você pode definir esses dados no momento do seu cadastro. E para se integrar aos serviços oferecidos, poderemos coletar todos os dados que forem disponibilizados como publicamente acessíveis pelo Facebook/Google ao nosso sistema, além de informações sobre seus anúncios, com quais materiais e conteúdo interagiu, quantos clicks eles receberam, informações sobre os visitantes em seus websites que estejam disponíveis nessa plataforma.</p>

        <p>Para o cadastro de acesso aos serviços e/ou produtos na modalidade de teste gratuito (trial), são coletados:</p>
        <ul>
          <li>Dados de contato (nome, e-mail e telefone)</li>

          <li>Dados de perfil profissional (cargo, empresa, segmento da empresa, número de funcionários da empresa.</li>
        </ul>

        <p>Para a contratação dos nossos serviços e/ou produtos, são coletados:</p>
        <ul>
          <li>Dados de contato (nome, e-mail e telefone);</li>

          <li>Dados de perfil profissional (Nº de registro no conselho cargo, empresa, segmento da empresa, número de funcionários da empresa);</li>

          <li>Dados locacionais (país, estado e cidade);</li>

          <li>Dados de faturamento e cobrança (razão social ou nome, nome fantasia, CNPJ ou CPF, responsável legal e seu CPF, endereço da sede, dados do cartão de crédito, quando feita a opção por este meio de pagamento, opções quanto ao Simples Nacional, dados complementares para emissão de Nota Fiscal e moeda de pagamento para clientes internacionais).</li>
        </ul>
        
        <h3>2.4. Usuários dos sites dos Clientes AGENDA CONSULTA</h3>
        <p>A AGENDA CONSULTA disponibiliza aos seus clientes, por meio dos softwares da AGENDA CONSULTA, ferramentas para automação de marketing e vendas, criação de Landing Pages, Pop-ups, Formulários, envio de e-mail marketing, CRM Odontológico, CRM de Cobranças, entre outras que envolvem o gerenciamento de informações pessoais de usuários com quem seus clientes mantêm relacionamento.</p>

        <p>Entretanto, é importante mencionar que a AGENDA CONSULTA não controla as informações coletadas por terceiros utilizando seu sistema. Esses dados pertencem aos Clientes da AGENDA CONSULTA, que os utilizam, divulgam e protegem de acordo com suas respectivas políticas de privacidade. São eles também os responsáveis por obter diretamente o consentimento dos usuários, e com este poder, coletar, gerenciar e processar as informações confidenciais.</p>

        <p>Já que a AGENDA CONSULTA não controla os dados que são coletados diretamente por seus Clientes, com o intuito de minimizar incidentes envolvendo dados pessoais, a AGENDA CONSULTA adota as melhores práticas de segurança da informação (ver item 6) e desenvolvimento seguro existentes no mercado, a fim de que seus Clientes possam utilizar uma ferramenta segura para o gerenciamento dos seus dados pessoais e dos dados dos clientes deles também. Caso você tenha alguma dúvida sobre como os seus dados estão sendo gerenciados, recomendamos que você entre em contato com a empresa que está oferecendo serviços para você.</p>


        <h2>3. Com quem a AGENDA CONSULTA compartilha os seus dados?</h2>
        <p>Por ser uma plataforma online, a AGENDA CONSULTA poderá operar em conjunto de outras empresas numa ampla gama de atividades, incluindo para fornecer as funcionalidades de localização, anunciantes, patrocinadores e parceiros comerciais, online e offline, além de ferramentas de divulgação e análises de desempenho. Desta forma, nos reservamos no direito de compartilhar suas informações, incluindo dados de localização, cadastro e de interesses, com as empresas abaixo indicadas, sempre que for possível, de forma anônima, visando preservar ao máximo a sua privacidade.</p>

        <p>Nós não alugamos, vendemos ou transferimos seus dados pessoais para ninguém, exceto para as empresas parceiras listadas abaixo. Nós poderemos vir a compartilhar seus dados pessoais, nos casos citados abaixo:</p>
        
        <p><strong>Anunciantes:</strong> Você poderá eventualmente receber diretamente anúncios, conteúdos e links exibidos de forma personalizada da AGENDA CONSULTA, de acordo com seus interesses e comportamentos em nossa plataforma ou em outros serviços com os quais você interaja. Para tanto, dados podem ser compartilhados entre a AGENDA CONSULTA e a plataforma de publicidade direcionada, principalmente identificadores únicos, endereços IP, cookies e outras informações coletadas do seu navegador, que podem ser utilizados para medir a eficiência da publicidade online. Você pode sempre se opor a esta publicidade entrando em contato conosco através do nosso e-mail: <a href="mailto::contato@agendaconsulta.com">contato@agendaconsulta.com</a>.</p>

        <p><strong>Estatísticas:</strong> Os dados armazenados pela AGENDA CONSULTA podem vir a ser utilizados para fins de estatísticas (analytics). Estes dados são agrupados, com o intuito de proporcionar uma análise macro do cenário, e, portanto, não buscam identificar ou tornar identificável os titulares dos dados pessoais, mas tão somente compreender melhor como é o acesso deles nas plataformas da AGENDA CONSULTA, a fim de melhorar a prestação de serviços e customizar produtos mais direcionados aos interesses dos usuários.</p>

        <p><strong>Perfis de redes sociais ou outras submissões:</strong> O cadastro nas plataformas da AGENDA CONSULTA permite que você se cadastre fazendo login de sua conta pelo Facebook ou pelo Gmail, dessa forma, certos dados da sua conta do Facebook e do Gmail podem vir a ser compartilhados com a AGENDA CONSULTA. Nesse sentido, quando for fazer esse login verifique os dados pessoais publicamente acessíveis que são autorizados por essas plataformas para que você possa decidir o que quer ou não compartilhar conosco. Não controlamos as políticas e práticas de qualquer outro site ou serviço de terceiros, por isso, caso faça seu cadastro usando seu perfil em uma rede social, esteja atento a isso.</p>

        <p><strong>Para resguardar e proteger direitos da AGENDA CONSULTA:</strong> Reservamo-nos o direito de acessar, ler, preservar e divulgar quaisquer dados que acreditamos serem necessários para cumprir uma obrigação legal ou uma ordem judicial; fazer cumprir ou aplicar nossos Termos de Uso e outros acordos; ou proteger os direitos, propriedade ou segurança da AGENDA CONSULTA, nossos funcionários, nossos usuários ou outros.</p>


        <h2>4. Transferência Internacional de Dados</h2>
        <p>A AGENDA CONSULTA coleta e transfere dados pessoais coletados no Brasil para países localizados na União Europeia e para os EUA. Essa transferência ocorre para Parceiros da AGENDA CONSULTA que atuam no processamento de dados pessoais, e essas transferências envolvem apenas empresas Parceiras da AGENDA CONSULTA que demonstraram estar em processo de conformidade ou em conformidade com a GDPR e com as leis setoriais brasileiras de proteção de dados.</p>

        <p>A AGENDA CONSULTA possui sua sede no Brasil e os dados que coletamos são regidos pela lei brasileira. Ao acessar ou usar os Serviços da AGENDA CONSULTA ou fornecer dados pessoais para nós, você concorda com o processamento e a transferência de tais dados para o Brasil e para outros países, acima mencionados.</p>


        <h2>5. Quais são os direitos dos titulares de dados?</h2>
        <p>Você sempre poderá optar em não divulgar seus dados para nós, mas tenha em mente que alguns desses dados podem ser necessários para se registrar em nossas plataformas ou usar alguns de nossos recursos e produtos que oferecemos à você. Independente disso, você sempre possuirá direitos relativos a privacidade e a proteção dos seus dados pessoais, e nós além de nos preocuparmos com a segurança desses dados, também nos preocupamos que você tenha acesso e conhecimento de todos os seus direitos relativos a dados pessoais.</p>

        <p>Dessa forma, abaixo resumimos todos os direitos que você tem sob a Lei Geral de Proteção de Dados (LGPD), às demais leis setoriais brasileiras relativas proteção de dados e a General Data Protection Regulation (GDPR), os quais são:</p>
        <ul>
          <li>Direito de acesso;</li>

          <li>Direito de retificação;</li>
          
          <li>Direito de exclusão;</li>
          
          <li>Direito de confirmação sobre a existência de tratamento;</li>
          
          <li>Direito de solicitar o bloqueio ou eliminação;</li>
          
          <li>Direito à informação das entidades públicas ou privadas;</li>
          
          <li>Direito de restringir o processamento;</li>
          
          <li>Direito à revisão de decisão automatizada;</li>
          
          <li>Direito de oposição a um tratamento;</li>
          
          <li>Direito à explicação da lógica por trás da coleta dos seus dados;</li>
          
          <li>Direito à informação sobre a possibilidade de não fornecer consentimento e sobre as consequências da negativa;</li>
          
          <li>Direito de retirar o seu consentimento.</li>
        </ul>

        <p>Nesse sentido, segue abaixo uma breve explicação e exemplos sobre alguns desses direitos:</p>
        <ul>
          <li>Requisição de acesso aos seus dados pessoais. Este direito permite que você possa requisitar e receber uma cópia dos seus dados pessoais que nós possuímos sobre você.</li>

          <li>Requisição de retificação dos seus dados pessoais na AGENDA
          CONSULTA. Este direito permite que você a qualquer momento possa solicitar a correção e/ou retificação dos seus dados pessoais, caso você identifique que alguns deles estão incorretos. Contudo, para ser efetivada essa correção, teremos que checar a validade dos dados que você nos fornece.</li>

          <li>Requisição de exclusão dos seus dados pessoais. Este direito permite que você possa nos solicitar a exclusão dos seus dados pessoais da nossa plataforma. Todos os dados coletados serão excluídos de nossos servidores quando você assim requisitar ou quando estes não forem mais necessários ou relevantes para lhe oferecermos os nossos serviços, salvo se houver qualquer outra razão para a sua manutenção, como eventual obrigação legal de retenção de dados ou necessidade de preservação destes para resguardo de direitos da AGENDA CONSULTA. Para alterar suas informações pessoais ou excluí-las do nosso banco de dados, basta enviar um e-mail para <a href="mailto:contato@agendaconsulta.com">contato@agendaconsulta.com</a>.</li>
          
          <li>Direito de objeção a um processamento de dados. Quando estivermos confiando em um legítimo interesse (ou de terceiros) e houver algo sobre sua situação específica que faça com que você deseje se opor ao processamento nesse campo, conforme julgar que isso causa impacto aos seus direitos e liberdades fundamentais. Você também tem o direito de contestar onde estamos processando seus dados pessoais para fins de marketing direto p. ex.. Em alguns casos, podemos demonstrar que temos motivos legítimos para processar seus dados, os quais se sobrepõem aos seus direitos, já que nesses casos podem ser essenciais para o fornecimento do produto e/ou serviço.</li>

          <li>Direito de solicitar o bloqueio ou eliminação. Este direito permite que Você nos peça para suspender o processamento de seus dados pessoais nos seguintes cenários: (a) se Você quiser que nós estabeleçamos a precisão dos dados; (b) quando Você precisar que sejam mantidos os dados mesmo se não precisarmos mais deles, conforme necessário, para estabelecer, exercer ou defender reivindicações legais; ou (c) se Você se opôs ao uso de seus dados, mas nesta hipótese precisamos verificar se temos motivos legítimos para usá-los.</li>

          <li>Restringir o processamento dos seus dados pessoais. Este direito permite que você nos peça para suspender o processamento de seus dados pessoais nos seguintes cenários: (a) se você quiser que nós estabeleçamos a precisão dos dados; (b) quando você precisar que mantenha os dados mesmo se não precisarmos mais deles, conforme necessário, para estabelecer, exercer ou defender reivindicações legais; ou (c) você se opôs ao uso de seus dados, mas precisamos verificar se temos motivos legítimos para usá-los.</li>

          <li>Direito de retirar o consentimento a qualquer momento. Você tem o direito de retirar o seu consentimento, no entanto, isso não afetará a legalidade de qualquer processamento realizado antes de você retirar o seu consentimento. Se você retirar o seu consentimento, talvez não possamos fornecer determinados produtos. E se for este o caso, iremos avisá-lo no momento em que isso ocorrer.</li>

          <li>Direito a revisão de decisões automatizadas. Você também tem o direito de solicitar a revisão de decisões tomadas unicamente com base em tratamento automatizado de seus dados pessoais que afetem seus interesses.</li>
        </ul>

        <p>Talvez seja necessário solicitar informações específicas suas para nos ajudar a confirmar sua identidade e garantir seu direito de acessar seus dados pessoais (ou de exercer seus outros direitos). Esta é uma medida de segurança para garantir que os dados pessoais não sejam divulgados a qualquer pessoa que não tenha direito de recebê-los. Podemos também contatá-lo para obter mais informações em relação à sua solicitação, a fim de acelerar nossa resposta. Suas solicitações legítimas serão atendidas em até 5 (cinco) dias úteis. Caso sua solicitação seja particularmente complexa ou se você tiver feito várias solicitações iremos notificá-lo e mantê-lo atualizado sobre o andamento da sua solicitação, caso a mesma demande mais tempo do que o previsto neste item.</p>

        <p>Caso você tenha alguma dúvida sobre essas questões e sobre como pode exercer esses direitos, fique à vontade para entrar em contato conosco através do nosso e-mail: <a href="mailto::contato@agendaconsulta.com">contato@agendaconsulta.com</a>.</p>


        <h2>6. Por quanto tempo a AGENDA CONSULTA irá armazenar os dados?</h2>
        <p>Nós manteremos seus dados pessoais somente pelo tempo que for necessário para cumprir com as finalidades para as quais os coletamos, inclusive para fins de cumprimento de quaisquer obrigações legais, contratuais, ou requisição de autoridades competentes.</p>

        <p>Para determinar o período de retenção adequado para os dados pessoais, consideramos a quantidade, a natureza e a sensibilidade dos dados pessoais, o risco potencial de danos decorrentes do uso não autorizado ou da divulgação de seus dados pessoais, a finalidade de processamento dos seus dados pessoais e se podemos alcançar tais propósitos através de outros meios, e os requisitos legais aplicáveis.</p>

        <p>Se você utiliza os nossos produtos e serviços, as informações da sua conta serão mantidas enquanto você mantiver essa conta ativa. Caso você solicite o cancelamento, as suas informações serão excluídas em até 60 dias do cancelamento da conta. Caso você seja um lead da AGENDA CONSULTA, as informações referentes às suas interações conosco, serão armazenadas pelo prazo de 5 (cinco) anos. Assim como, se você solicitar a exclusão dos seus dados e/ou da sua conta, neste caso, a AGENDA CONSULTA manterá os dados por ainda 72 (setenta e duas horas) após a sua solicitação de exclusão.</p>


        <h2>7. Como funciona a Segurança da Informação na AGENDA CONSULTA</h2>
        <p>Sua conta é protegida com uma senha para sua privacidade e segurança. Se você acessar sua conta por meio de um site ou serviço de terceiros, poderá ter proteções de login adicionais ou diferentes por meio desse site ou serviço. Você deve impedir e evitar o acesso não autorizado de terceiros à sua conta e aos dados pessoais selecionando e protegendo sua senha e/ou outro mecanismo de conexão de forma adequada e limitando o acesso ao seu computador ou dispositivo e navegador, fazendo logout após ter concluído o acesso à sua conta.</p>

        <p>Com a finalidade de garantir a sua privacidade e a proteção dos seus dados pessoais, a AGENDA CONSULTA adota as melhores práticas de segurança da informação e desenvolvimento seguro existentes no mercado, ou seja, desenhamos produtos e serviços que promovem a proteção dos seus dados e nos quais você pode gerenciar as suas informações diretamente.</p>

        <p>Nós nos esforçamos para proteger a privacidade de sua conta e de outros dados pessoais que mantemos seus em nossos registros, mas infelizmente não podemos garantir total segurança. Entrada ou uso não autorizado de conta, falha de hardware ou software e outros fatores podem comprometer a segurança dos seus dados pessoais a qualquer momento, por isso, nos ajude a manter um ambiente seguro para todos. Além de adotar boas práticas de segurança em relação a sua conta e aos seus dados, caso você identifique ou tome conhecimento de algo que comprometa a segurança dos seus dados.</p>


        <h2>8. Como falar com a AGENDA CONSULTA</h2>
        <p>Se você acredita que suas informações pessoais foram usadas de maneira incompatível com a Política de Privacidade ou suas escolhas, ou se você tiver outras dúvidas, comentários ou sugestões relacionadas a esta Política de Privacidade, você pode entrar em contato nos seguintes endereços de contato:</p>

        <p>Endereço: Rua Godolfredo Gonçalves, 375 – Sl 01 - Centro<br/>
        Itaúna/Minas Gerais, CEP: 35680-047.</p>
        <p>E-mail: <a href="mailto::contato@agendaconsulta.com">contato@agendaconsulta.com</a></p>


        <h2>9. Mudanças na Política de Privacidade</h2>
        <p>Como estamos sempre buscando melhorar nossos serviços, essa Política de Privacidade pode passar por atualizações. Desta forma, recomendamos visitar periodicamente esta página para que você tenha conhecimento sobre as modificações. Caso sejam feitas alterações relevantes que ensejem em um novo consentimento seu, iremos publicar essa atualização e solicitar um novo consentimento para você.</p>
      </Wrapper>

      <Footer>
        <div className="content">
          <p>Agenda Consulta © 2022</p>
        </div>
      </Footer>
    </Container>
  );
}