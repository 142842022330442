import { FormEvent, useState } from "react";

import { Button } from "@components/Button";
import { Input } from "@components/Input";
import { Rating } from "@components/Rating";

import { Professionals } from "@services/ProfessionalsService";

import nullAvatar from "@assets/images/null-avatar.jpg";

import {
  Container,
  Content,
  Feedback,
  FeedbackAvatar,
  FeedbackComment,
  FeedbackResponse,
  Feedbacks,
  FeedbacksTabs,
  RatingContainer,
  SearchContainer,
} from "./styles";

interface FeedbacksTabProps {
  data: Professionals;
}

type FeedbackTypes = "all" | "positive" | "negative";

export function FeedbacksTab({ data }: FeedbacksTabProps) {
  const [selectedTab, setSelectedTab] = useState<FeedbackTypes>("all");
  
  const [search, setSearch] = useState("");
  const [feedbacks, setFeedbacks] = useState(data.feedbacks);

  function getFeedbacksByRatingType(type: FeedbackTypes) {
    const filteredFeedbacksByRatingType = feedbacks.filter(feedback => {
      if (type === "positive") return feedback.givenRating >= 2.5;
      if (type === "negative") return feedback.givenRating < 2.5;
      return true;
    });

    return filteredFeedbacksByRatingType;
  }

  function handleSearch(event?: FormEvent) {
    if (event) event.preventDefault();
    
    const filteredFeedbacks = data.feedbacks.filter(feedback => 
      (feedback.name.toLowerCase().includes(search.toLowerCase()))
      || (feedback.comment.toLowerCase().includes(search.toLowerCase()))
    );

    setFeedbacks(filteredFeedbacks);
  }

  return (
    <Container>
      <Content>
        <RatingContainer>
          <span className="general-score">
            {data.rating.general.toFixed(1)}
          </span>

          <div className="general-description">
            <Rating rate={data.rating.general} readonly />
            <h4>Classificação Geral</h4>
            <p>{data.feedbacks.length} opiniões</p>
          </div>

          <ul className="others-rating">
            <li>
              <Rating rate={data.rating.punctuality} readonly />
              <h6>Pontualidade</h6>
            </li>
            <li>
              <Rating rate={data.rating.attention} readonly />
              <h6>Atenção</h6>
            </li>
            <li>
              <Rating rate={data.rating.place} readonly />
              <h6>Clínicas e Hospitais</h6>
            </li>
          </ul>
        </RatingContainer>

        <SearchContainer onSubmit={(e) => handleSearch(e)}>
          <Input
            name="opinions"
            label="Pesquisar em opiniões"
            placeholder="Escreva a palavra que deseja"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <Button type="submit">Buscar</Button>
        </SearchContainer>

        <FeedbacksTabs>
          <button
            className={selectedTab === "all" ? "active" : ""}
            onClick={() => setSelectedTab("all")}
          >
            Todas as opiniões ({getFeedbacksByRatingType("all").length})
          </button>

          <button
            className={selectedTab === "positive" ? "active" : ""}
            onClick={() => setSelectedTab("positive")}
          >
            Positivas ({getFeedbacksByRatingType("positive").length})
          </button>

          <button
            className={selectedTab === "negative" ? "active" : ""}
            onClick={() => setSelectedTab("negative")}
          >
            Negativas ({getFeedbacksByRatingType("negative").length})
          </button>
        </FeedbacksTabs>

        <Feedbacks>
          {feedbacks.length
            ? getFeedbacksByRatingType(selectedTab)
              .map((feedback, index) => (
                <Feedback key={feedback.name + "--" + index}>
                  <FeedbackComment>
                    <header>
                      <FeedbackAvatar url={feedback.avatar}>
                        <span>{feedback.name.charAt(0)}</span>
                      </FeedbackAvatar>

                      <div>
                        <strong>{feedback.name}</strong>
                        {feedback.isVerified && (
                          <span>Paciente verificado</span>
                        )}
                      </div>

                      <Rating rate={feedback.givenRating} readonly />
                    </header>

                    <p>{feedback.comment}</p>
                  </FeedbackComment>

                  {feedback.response && (
                    <FeedbackResponse>
                      <p>{feedback.response.comment}</p>

                      <img
                        src={feedback.response.avatar || nullAvatar}
                        alt={feedback.response.name}
                      />
                    </FeedbackResponse>
                  )}
                </Feedback>
              ))
            : "Nenhuma avaliação foi encontrada"}
        </Feedbacks>
      </Content>
    </Container>
  );
}
