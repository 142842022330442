import { useEffect, useState } from "react";
import { useParams, Link, NavLink, Routes, Route, Navigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Loading } from "@components/Loading";
import { Rating } from "@components/Rating";

// Tabs
import { ConsultationsTab } from "./ConsultationsTab";
import { ExperienceTab } from "./ExperienceTab";
import { FeedbacksTab } from "./FeedbacksTab";

import ProfessionalsService, { Professionals } from "@services/ProfessionalsService";

import { ChevronDown } from "@assets/images";
import nullAvatar from "@assets/images/null-avatar.jpg";

import { delay } from "@utils/delay";

import { LoadingContainer, ProfessionalNotFoundContainer, Container, Header, Content, ProfessionalHeader, ProfessionalInfo, TabLinks } from "./styles";

export function DetailsPage() {
  const { id } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [professional, setProfessional] = useState<Professionals | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        if (!id) throw new Error('Professional ID is required');

        const professionalData = await ProfessionalsService.listProfessionalById(id);

        await delay(500);

        setProfessional(professionalData);
      } catch (error) {
        setProfessional(null);

        toast.error("Não foi possível carregar os dados do profissional");

        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [id]);

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  if (professional === null) {
    return (
      <ProfessionalNotFoundContainer>
        <h4>Profissional não encontrado</h4>

        <Link to="/profissionais/">Voltar para Página Inicial</Link>
      </ProfessionalNotFoundContainer>
    );
  }

  return (
    <Container>
      <Header>
        <Link to='/profissionais/'>
          <ChevronDown />
        </Link>

        <h1>{professional.name}</h1>
      </Header>
      
      <Content>
        <ProfessionalHeader>
          <ProfessionalInfo>
            <img 
              src={professional.avatar || nullAvatar}
              alt={professional.name}
            />

            <div>
              <h2>{professional.name}</h2>
              <h3>{professional.specialty} | {professional.council} {professional.councilNumber}</h3>
            </div>

            <div>
              <p>{professional.healthInsurance}</p>

              <Rating rate={professional.rating.general} readonly />
            </div>
          </ProfessionalInfo>

          <TabLinks>
            <ul>
              <li><NavLink to="consultas/">Consultas</NavLink></li>
              <li><NavLink to="experiencia/">Experiência</NavLink></li>
              <li><NavLink to="opinioes/">Opiniões ({professional.feedbacks.length})</NavLink></li>
            </ul>
          </TabLinks>
        </ProfessionalHeader>

        <Routes>
          <Route path="/" element={<Navigate to="consultas/" />} />
          <Route path="consultas/" element={<ConsultationsTab data={professional} />} />
          <Route path="experiencia/" element={<ExperienceTab data={professional} />} />
          <Route path="opinioes/" element={<FeedbacksTab data={professional} />} />
        </Routes>
      </Content>
    </Container>
  );
}