import styled from "styled-components";

import { InputGroup } from "@components/Input";
import { Container as Button } from "@components/Button/styles";

import { convertToRem } from "@utils/convertToRem";

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  
  width: 100%;
  height: 100%;

  padding-top: ${convertToRem(36)};
  padding-bottom: ${convertToRem(80)};

  max-width: ${convertToRem(770)};

  @media (max-width: 992px) {
    max-width: 100%;

    ${InputGroup} {
      &.receipt {
       grid-template-columns: 1fr 1fr;

       > * {
        :nth-child(3) {
          grid-column: 1 / 3;
        }

        :nth-child(4) {
          grid-row: 2;
          grid-column: 1 / 3;
        }
       }
      }
    }
  }

  @media (max-width: 576px) {
    ${InputGroup} {
      grid-template-columns: 1fr;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${convertToRem(24)};

  margin-top: ${convertToRem(100)} !important;

  > ${Button} {
    min-width: initial;

    width: 100%;
    max-width: ${convertToRem(270)};
  }
`;