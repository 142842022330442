import styled from "styled-components";
import chroma from "chroma-js";

import { Container as Button } from "@components/Button/styles"

import { convertToRem } from "@utils/convertToRem";

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: calc(100vh - ${convertToRem(80)});

  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(40)};
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  > div {
    flex: 1;

    margin-right: ${convertToRem(40)};
  }
`;

export const Tabs = styled.div`
  margin-top: ${convertToRem(40)};

  border-bottom: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
`;

export const Tab = styled.button`
  padding: 0 ${convertToRem(25)} ${convertToRem(8)};

  background-color: transparent;
  color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.5).css()};

  font-size: ${convertToRem(15)};

  border: 0;

  &.active {
    font-weight: 500;

    color: ${({ theme }) => theme.colors.primary.highlight};

    border-bottom: solid ${convertToRem(2)} ${({ theme }) => theme.colors.primary.highlight};
  }
`;

export const PageContent = styled.div`
  display: grid;
  grid-template-columns: 9fr 3fr;
  gap: ${convertToRem(30)};

  margin: ${convertToRem(30)} 0;
`;

export const PlanCards = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${convertToRem(30)};

  height: fit-content;
`;

export const PlanCard = styled.div<{ isOwner?: boolean; }>`
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: ${convertToRem(180)};

  padding: ${convertToRem(30)};

  background-color: ${({ isOwner, theme }) => isOwner ? theme.colors.primary.base : theme.colors.neutral.text};
  color: ${({ theme }) => theme.colors.neutral.white};

  border-radius: ${convertToRem(5)};

  overflow: hidden;

  > div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    width: 100%;
  }

  h5 {
    color: ${({ theme }) => chroma(theme.colors.neutral.white).alpha(0.4).css()};

    font-size: ${convertToRem(11)};
  }

  h4 {
    margin-top: ${convertToRem(8)};

    font-size: ${convertToRem(14)};
  }

  .logo {
    width: auto;
    height: ${convertToRem(24)};

    margin-left: ${convertToRem(10)};

    fill: ${({ theme }) => theme.colors.neutral.white};
  }

  .number {
    align-items: flex-end;
    margin-top: auto;

    span {
      font-family: 'IBM Plex Mono', monospace;
      font-size: ${convertToRem(16)};
    }

    strong {
      font-size: ${convertToRem(12)};
    }
  }

  .logo-bg {
    position: absolute;
    bottom: ${convertToRem(-15)};
    right: ${convertToRem(-110)};

    width: auto;
    height: ${convertToRem(300)};

    fill: ${({ theme }) => chroma(theme.colors.neutral.white).alpha(0.1).css()};
  }
`;

export const SidebarCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  padding: ${convertToRem(30)};

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.text};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.borderDark};
  border-radius: ${convertToRem(5)};

  &:not(:first-child) {
    margin-top: ${convertToRem(30)};
  }

  > svg {
    width: auto;
    height: ${convertToRem(30)};

    color: ${({ theme }) => theme.colors.primary.base};
  }

  h3 {
    margin-top: ${convertToRem(10)};

    font-size: ${convertToRem(18)};
  }

  p {
    margin-top: ${convertToRem(10)};

    font-size: ${convertToRem(14)};
    line-height: 1.5;
  }

  ${Button} {
    margin-top: ${convertToRem(20)};

    svg {
      width: auto;
      height: ${convertToRem(24)};

      margin-left: ${convertToRem(10)};
    }
  }
`;