import { RiBarChartLine, RiHomeLine, RiHospitalLine, RiSettings4Line, RiStethoscopeLine, RiTeamLine, RiTrophyLine, RiUser3Line } from 'react-icons/ri';

import { SidebarMenuItem } from '.';

export function AdminSidebar() {
  return (
    <>
      <SidebarMenuItem 
        path='/painel-administrativo' 
        icon={<RiHomeLine />} 
        title='Página Inicial' 
      />

      <SidebarMenuItem 
        path='/cartoes-prime' 
        icon={<RiTrophyLine />} 
        title='Cartões Prime'
      />

      <SidebarMenuItem 
        path='/clinicas' 
        icon={<RiHospitalLine />} 
        title='Clínicas' 
        subnav={[
          { path: '/clinicas/listagem', title: 'Listagem' },
          { path: '/clinicas/adicionar', title: 'Adicionar' },
          { path: '/clinicas/planos', title: 'Planos' },
        ]} 
      />

      <SidebarMenuItem 
        path='/profissionais' 
        icon={<RiStethoscopeLine />} 
        title='Profissionais' 
      />

      <SidebarMenuItem 
        path='/pacientes' 
        icon={<RiUser3Line />} 
        title='Pacientes' 
        subnav={[
          { path: '/pacientes/listagem', title: 'Listagem' },
          { path: '/pacientes/adicionar', title: 'Adicionar' },
        ]} 
      />
      
      <SidebarMenuItem 
        path='/leads' 
        icon={<RiTeamLine />} 
        title='Leads'
        subnav={[
          { path: '/leads', title: 'Leads' },
          { path: '/leads-afiliados', title: 'Leads de Afiliados' },
          { path: '/leads-campanhas', title: 'Leads de Campanhas' },
        ]} 
      />

      <SidebarMenuItem 
        path='/relatorios' 
        icon={<RiBarChartLine />} 
        title='Relatórios' 
        subnav={[
          { path: '/relatorios/gerais', title: 'Gerais' },
          { path: '/relatorios/clinicas', title: 'Clínicas' },
          { path: '/relatorios/pacientes', title: 'Pacientes' },
          { path: '/relatorios/vendedores', title: 'Vendedores' },
        ]}
      />

      <SidebarMenuItem 
        path='/configuracoes' 
        icon={<RiSettings4Line />} 
        title='Configurações' 
        subnav={[
          { path: '/configuracoes/clinicas', title: 'Clínicas' },
          { path: '/configuracoes/afiliados', title: 'Afiliados' },
          { path: '/configuracoes/cidades-cadastradas', title: 'Cidades para Afiliados' },
        ]}
      />
    </>
  );
}