import styled from "styled-components";
import chroma from "chroma-js";

import { Container as Button } from "@components/Button/styles";

import { convertToRem } from "@utils/convertToRem";

export const Wrapper = styled.div`  
  position: relative;

  width: 100%;
  min-height: 100vh;

  background-color: ${({ theme }) => theme.colors.neutral.borderLight};

  overflow-x: hidden;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: ${convertToRem(60)} 0;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    padding: 0 ${convertToRem(24)};

    * {
      flex: 1;
    }

    a,
    button {
      background: none;
      color: ${({ theme }) => theme.colors.primary.base};

      font-weight: 500;
      font-size: ${convertToRem(15)};

      border: 0;
    }

    a {
      font-size: ${convertToRem(18)};
    }

    .logo {
      margin: 0 auto;
    }

    button {
      text-align: end;
    }
  }

  h1 {
    margin: ${convertToRem(40)} 0 ${convertToRem(60)};

    color: ${({ theme }) => theme.colors.neutral.textDark};
  }

  ul {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: ${convertToRem(24)};

    width: 100%;
    max-width: ${convertToRem(900)};

    padding: 0 ${convertToRem(24)};

    li {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      padding: ${convertToRem(36)} ${convertToRem(24)};

      width: 100%;

      background-color: ${({ theme }) => theme.colors.neutral.white};

      border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.borderDark};
      border-radius: ${convertToRem(8)};

      text-align: center;
      transition: all ease 0.3s;
      cursor: pointer;

      .icon {
        display: flex;
        align-items: center;
        justify-content: center;

        width: ${convertToRem(48)};
        height: ${convertToRem(48)};

        background-color: ${({ theme }) => theme.colors.primary.lightest};

        border-radius: 50%;
        overflow: hidden;

        img {
          width: 100%;
          height: 100%;

          object-fit: cover;
        }

        svg {
          width: auto;
          height: ${convertToRem(20)};
          
          color: ${({ theme }) => theme.colors.primary.base};
        }
      }

      h3 {
        margin-top: ${convertToRem(16)};

        font-size: ${convertToRem(20)};
      }

      p {
        margin-top: ${convertToRem(8)};

        color: ${({ theme }) => theme.colors.neutral.text};

        font-size: ${convertToRem(13)};
      }

      .inactive-message {
        margin-top: ${convertToRem(24)};
        margin-bottom: ${convertToRem(16)};

        color: ${({ theme }) => theme.colors.helpers.danger};

        font-size: ${convertToRem(12)};
      }

      ${Button} {
        width: 100%;
        
        min-width: auto;

        margin-top: auto;

        svg {
          width: auto;
          height: ${convertToRem(10)};

          margin-right: ${convertToRem(8)};
        }
      }

      &:hover {
        box-shadow: 0 ${convertToRem(2)} ${convertToRem(10)} ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.1).css()};
      }

      &.inactive {
        opacity: 0.5;
        cursor: initial;
      }
    }
  }
  
  @media (max-width: 768px) {
    header {
      a {
        font-size: ${convertToRem(15)};
      }
    }
    
    ul {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  @media (max-width: 540px) {
    ul {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;