import styled, { css } from "styled-components";

import { Container as Input } from '@components/Input/styles';
import { Container as Button } from '@components/Button/styles';

import primeList from '@assets/images/prime-list-icon.svg';

import { convertToRem } from "@utils/convertToRem";

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(40)};
`;

export const Header = styled.header`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  > div {
    flex: 1;
  }

  ${Button} {
    height: ${convertToRem(40)};
    min-height: ${convertToRem(40)};

    background-color: ${({ theme }) => theme.colors.helpers.info};
  }
`;

export const GridContent = styled.div<{ hideSidebar?: boolean; }>`
  display: grid;
  grid-template-columns: 8fr 4fr;
  gap: ${convertToRem(30)};

  margin: ${convertToRem(40)} 0 ${convertToRem(100)};

  ${({ hideSidebar }) => hideSidebar && css`
    grid-template-columns: 1fr;

    ${SidebarCard} {
      display: none;
    }
  `}
`;

export const CardContainer = styled.div<{ image: string; }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;

  padding: ${convertToRem(50)};

  background-color: ${({ theme }) => theme.colors.neutral.borderLight};
  background-image: ${({ image }) => `url(${image})`};
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-position: top right;

  border-radius: ${convertToRem(10)};

  .texts {
    max-width: ${convertToRem(380)};
  }

  h2 {
    color: ${({ theme }) => theme.colors.neutral.textDark};

    font-weight: 400;
    font-size: ${convertToRem(34)};
    line-height: 1.2;
  }

  h3 {
    margin-top: ${convertToRem(20)};

    color: ${({ theme }) => theme.colors.neutral.text};

    font-weight: 400;
    font-size: ${convertToRem(14)};
    line-height: 1.4;
  }

  ul {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    margin-top: ${convertToRem(15)};
  }

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    color: ${({ theme }) => theme.colors.neutral.text};

    font-weight: 400;
    font-size: ${convertToRem(12)};
    line-height: 1.4;

    &:not(:first-child) {
      margin-left: ${convertToRem(15)};
    }

    &::before {
      content: '';

      display: block;
      
      width: ${convertToRem(15)};
      height: ${convertToRem(15)};

      margin-right: ${convertToRem(5)};

      background-image: url(${primeList});
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }

  h5 {
    margin-top: ${convertToRem(20)};

    color: #868E96;
    
    font-weight: 400;
    font-size: ${convertToRem(15)};

    .old-price {
      text-decoration: line-through;
    }
  }

  h4 {
    color: ${({ theme }) => theme.colors.primary.base};

    font-weight: 600;
    font-size: ${convertToRem(25)};
  }

  .buttons {
    display: flex;
  }

  ${Button} {
    height: ${convertToRem(60)};

    margin-top: ${convertToRem(30)};

    &:not(:first-child) {
      margin-left: ${convertToRem(20)};
    }
  }
`;

export const SidebarCard = styled.div`
  width: 100%;
  height: fit-content;

  padding: ${convertToRem(40)} ${convertToRem(20)} ${convertToRem(25)};

  background-color: ${({ theme }) => theme.colors.neutral.borderLight};
  color: ${({ theme }) => theme.colors.neutral.text};

  border-radius: ${convertToRem(5)};

  h2 {
    font-weight: 500;
    font-size: ${convertToRem(20)};
  }

  h3 {
    margin-top: ${convertToRem(10)};

    font-weight: 400;
    font-size: ${convertToRem(15)};
  }

  ${Input} {
    margin-top: ${convertToRem(40)};
  }

  ${Button} {
    width: 100%;

    margin-top: ${convertToRem(25)};
  }
`;
