import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Loading } from "@components/Loading";
import { Description, Title } from "@components/Header";
import { ProfessionalCard } from "./ProfessionalCard";

import ProfessionalsService, { Filters, Professionals } from "@services/ProfessionalsService";

import { Container, LoadingContainer, ResultTitle } from "./styles";

interface ResultsPageProps {
  filters: Filters;
}

export function ResultsPage({ filters }: ResultsPageProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [professionals, setProfessionals] = useState<Professionals[]>([]);

  // Filtering professionals according filters prop
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const filteredProfessionals = await ProfessionalsService.listProfessionals(filters);
  
        setProfessionals(filteredProfessionals);
      } catch (error) {
        toast.error("Não foi possível carregar os profissionais");

        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [filters]);

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }
  
  return (
    <Container>
      <ResultTitle>
        <Title>Resultado de busca</Title>
        <Description>{professionals ? professionals.length : '0'} resultados encontrados</Description>
      </ResultTitle>

      <div>
        {!!professionals.length ?
          professionals.map((professional, index) => (
            <ProfessionalCard
              key={professional.id}
              data={professional}
            />
          ))
          : 'Nenhum profissional foi encontrado...'
      }
      </div>
    </Container>
  );
}
