import { forwardRef, HtmlHTMLAttributes, useState } from "react";
import { format } from "date-fns";
import { RiContactsBook2Line, RiMailLine, RiMouseLine, RiPhoneLine, RiUser3Line, RiUserAddLine, RiWhatsappLine } from "react-icons/ri";
import { useTheme } from "styled-components";

import { Button } from "@components/Button";

import { Customer } from "@services/CustomersService";

import { ChevronDown } from "@assets/images";

import { Container } from "./KanbanCard.styles";

interface KanbanCardProps extends HtmlHTMLAttributes<HTMLDivElement> {
  data: Customer;
  onDataButtonClick: () => any;
  onContactButtonClick: () => any;
  onActionsButtonClick: () => any;
  cardBorderColor: string;
}

export const KanbanCard = forwardRef<HTMLDivElement, KanbanCardProps>((props, forwardedRef) => {
  const { data, onDataButtonClick, onContactButtonClick, onActionsButtonClick, cardBorderColor, ...rest } = props;

  const theme = useTheme();

  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <Container 
      ref={forwardedRef} 
      className={isExpanded ? 'expanded' : ''} 
      cardBorderColor={cardBorderColor}
      {...rest}
    > 
      <div className="info-wrapper">
        <div className='info'>
          <span className="date">{format(new Date(), 'dd/MM/yyyy   HH:mm')}</span>

          <strong>{data.name}</strong>
          <span>Clínico Geral/Ortodontia</span>
          
          <span className="source">Fonte: <strong>Leads Novos</strong></span>
          <span>Origem: <strong>Facebook</strong></span>
        </div>

        <button 
          type="button" 
          title={isExpanded ? 'Esconder' : 'Expandir'}
          onClick={() => setIsExpanded((prevState) => !prevState)}
        >
          <ChevronDown />
        </button>
      </div>

      <div className="details">
        <ul className="procedures">
          <li>
            <div>
              <span>Raio-X Panorâmico</span>
              <span>R$ 279,99</span>
            </div>

            <strong>
              <RiUser3Line />
              
              Dr. Oséias Alencar
            </strong>
          </li>

          <li>
            <div>
              <span>Limpeza</span>
              <span>R$ 89,99</span>
            </div>
            
            <button>
              <RiUserAddLine />
              Vincular Colaborador
            </button>
          </li>
        </ul>

        <div className="contacts">
          <div className="contact phone">
            <span>
              <RiPhoneLine />
              (71) 3644-5896
            </span>

            <a 
              href="tel:7136445896" 
              target="_blank" 
              rel="noreferrer"
            >
              Ligar
            </a>
          </div>

          <div className="contact whatsapp">
            <span>
              <RiWhatsappLine />
              (71) 9 9954-6458
            </span>

            <a 
              href="https://wa.me/71999546458" 
              target="_blank" 
              rel="noreferrer"
            >
              WhatsApp
            </a>
          </div>

          <div className="contact mail">
            <span>
              <RiMailLine />
              fulano@gmail.com
            </span>
          </div>
        </div>

        <div className="actions">
          <Button 
            onClick={() => onDataButtonClick()}
            style={{ backgroundColor: theme.colors.helpers.info }}
          >
            <RiUser3Line />
            Dados
          </Button>

          <Button 
            onClick={() => onContactButtonClick()}
            style={{ backgroundColor: theme.colors.helpers.success }}
          >
            <RiContactsBook2Line />
            Contatos
          </Button>

          <Button onClick={() => onActionsButtonClick()}>
            <RiMouseLine />
            Ações
          </Button>
        </div>
      </div>
    </Container>
  );
});
