import { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import { Input } from "@components/Input";
import { Button } from "@components/Button";

import { delay } from "@utils/delay";

import { AgendaConsultaExpand } from "@assets/images";
import mockup from '@assets/images/mockup.png';

import { Banner, Container, Main } from "./styles";

interface InitialValues {
  code: string;
  newPassword: string;
  confirmPassword: string;
}

const timer = 60;

export function ResetPassword() {
  const timerRef = useRef<NodeJS.Timeout | null>(null);

  const [isResendButtonDisabled, setIsResendButtonDisabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const [counter, setCounter] = useState(timer);

  const formatedMinutes = String(Math.floor(counter / 60)).padStart(2, "0");
  const formatedSeconds = String(Math.floor(counter % 60)).padStart(2, "0");

  const formik = useFormik<InitialValues>({
    initialValues: {
      code: '',
      newPassword: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object().shape({
      code: Yup.string().required("O campo é obrigatório"),
      newPassword: Yup.string()
        .min(8, "Insira uma senha com no mínimo 8 caracteres")
        .required("O campo é obrigatório"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "As senhas não coincidem")
        .required("O campo é obrigatório")
    }),
    onSubmit: async () => {
      setIsSubmitting(true);

      await delay(2000);

      setIsSubmitting(false);

      toast.success('A senha foi redefinida com sucesso!');
    }
  });
  
  function resendCode() {
    setIsResendButtonDisabled(true);

    const counterInterval = setInterval(() => {
      setCounter(prevState => prevState - 1);
    }, 1000);

    timerRef.current = setTimeout(() => {
      clearInterval(counterInterval);
      
      setCounter(timer);
      setIsResendButtonDisabled(false);
    }, timer * 1000);
  }

  useEffect(() => {
    return () => {
      if(!timerRef.current) return;

      clearTimeout(timerRef.current);
    };
  }, []);

  return (
    <Container>
      <Main>
        <div className="wrapper">
          <Link to="/" className="logo">
            <AgendaConsultaExpand />
          </Link>

          <h1>Redefinição de senha</h1>
          <p>Escolha uma nova senha para você acessar a plataforma da Agenda Consulta.</p>

          <form onSubmit={formik.handleSubmit}>
            <Input 
              label="Código"
              name="code"
              value={formik.values.code}
              onChange={formik.handleChange}
              error={(formik.touched.code || undefined) && formik.errors.code}
            />

            <Input 
              label="Nova senha"
              type="password"
              name="newPassword"
              value={formik.values.newPassword}
              onChange={formik.handleChange}
              error={(formik.touched.newPassword || undefined) && formik.errors.newPassword}
            />

            <Input 
              label="Confirmar senha"
              type="password"
              name="confirmPassword"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              error={(formik.touched.confirmPassword || undefined) && formik.errors.confirmPassword}
            />

            <Button type="submit" isLoading={isSubmitting}>
              Resetar
            </Button>

            <Button 
              variant="secondary" 
              onClick={resendCode} 
              disabled={isResendButtonDisabled}
            >
              {isResendButtonDisabled 
                ? `Reenviar e-mail (em ${formatedMinutes}:${formatedSeconds})` 
                : 'Reenviar e-mail'
              }
            </Button>
          </form>
        </div>
      </Main>

      <Banner>
        <img src={mockup} alt="Mockup" />
      </Banner>
    </Container>
  );
}