import styled from "styled-components";

import { Container as Button } from "@components/Button/styles";

import { convertToRem } from "@utils/convertToRem";

export const Content = styled.section`
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(40)};
  padding-bottom: ${convertToRem(80)};
`;

export const Wrapper = styled.div`
  max-width: ${convertToRem(870)};

  > ${Button} {
    margin-top: ${convertToRem(100)};
    margin-left: auto;
  }
`;

export const AddSchedulesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin-top: ${convertToRem(40)};
  padding: ${convertToRem(18)} ${convertToRem(30)};

  background-color: ${({ theme }) => theme.colors.primary.lightest};
  color: ${({ theme }) => theme.colors.neutral.textDark};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.primary.light};
  border-radius: ${convertToRem(5)};

  h3,
  button {
    font-weight: 500;
    font-size: ${convertToRem(13)};
    line-height: 1;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    background: none;
    border: 0;

    > svg {
      width: auto;
      height: ${convertToRem(24)};

      margin-right: ${convertToRem(10)};

      color: ${({ theme }) => theme.colors.primary.light};
    }
  }
`;

export const SchedulesContainer = styled.div`
  display: block;

  margin-top: ${convertToRem(40)};
`;
