import { Navigate, Route, Routes } from "react-router-dom";

import { Prime } from "./Prime";
import { Affiliate } from "./Affiliate";

export function Plan() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="seja-prime/" />} />
      <Route path="seja-prime/" element={<Prime />} />
      <Route path="afiliado/" element={<Affiliate />} />
    </Routes>
  );
}