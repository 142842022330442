import { RiBarChartLine, RiCoinsLine, RiHomeLine, RiSettings4Line, RiTeamLine, RiUser3Line } from 'react-icons/ri';

import { SidebarMenuItem } from '.';

export function AffiliateAdminSidebar() {
  return (
    <>
      <SidebarMenuItem 
        path='/' 
        icon={<RiHomeLine />} 
        title='Dashboard' 
      />

      <SidebarMenuItem 
        path='/afiliados' 
        icon={<RiUser3Line />} 
        title='Afiliados' 
      />
      
      <SidebarMenuItem 
        path='/leads' 
        icon={<RiTeamLine />} 
        title='Leads' 
      />

      <SidebarMenuItem 
        path='/financeiro' 
        icon={<RiCoinsLine />} 
        title='Financeiro' 
      />

      <SidebarMenuItem 
        path='/relatorios' 
        icon={<RiBarChartLine />} 
        title='Relatórios' 
      />

      <SidebarMenuItem 
        path='/configuracoes' 
        icon={<RiSettings4Line />} 
        title='Configurações' 
      />
    </>
  );
}