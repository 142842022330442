import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

import { Modal } from '@components/Modal';
import { Description, Title } from '@components/Header';
import { Select } from '@components/Select';
import ResultCard, { ItemDivider, ItemValue, ItemContainer, ItemTitle, ActionsContainer, ActionButton } from '@components/ResultCard';
import { Tooltip } from '@components/Tooltip';

import ConsultationsService, { Consultation, ConsultationStatus } from '@services/ConsultationsService';

import nullAvatar from '@assets/images/null-avatar.jpg'
import { CancelSquare, ChevronDown, Document, Info } from '@assets/images';

import { formatToBRL } from '@utils/formatToBRL';
import { capitalizeFirstLetter } from '@utils/capitalizeFirstLetter';

import { OnChangeOptionType } from '@components/Select/Select.d';

import { Content, ContentWrapper, FiltersContainer, Footer, Page, PageButton, Pagination, ResultsContainer } from './styles';

export type ConsultationsOrder = 'Data da Consulta' | 'Profissional' | 'Valor' | 'Status' | 'Vencimento' | 'Local de Atendimento';

interface OrderSelect {
  value: ConsultationsOrder,
  label: ConsultationsOrder,
};

export const orderSelectItems: OrderSelect[] = [
  { value: 'Data da Consulta', label: 'Data da Consulta' },
  { value: 'Profissional', label: 'Profissional' },
  { value: 'Valor', label: 'Valor' },
  { value: 'Status', label: 'Status' },
  { value: 'Vencimento', label: 'Vencimento'},
  { value: 'Local de Atendimento' , label: 'Local de Atendimento' },
];

export const limitSelectItems = [
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
];

export function Consultations() {
  const theme = useTheme();

  const [consultations, setConsultations] = useState<Consultation[]>([]);
  
  const [selectedOrder, setSelectedOrder] = useState<ConsultationsOrder>("Profissional");

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [detailsModal, setDetailsModal] = useState<Consultation | null>(null);
  const [cancelModal, setCancelModal] = useState<Consultation | null>(null);

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  function handleChangeLimit(selectedOption: OnChangeOptionType) {
    if (selectedOption === null) return;

    setItemsPerPage(Number(selectedOption.value));
  };

  function getOrderedConsultations(orderBy: ConsultationsOrder) {
    switch(orderBy) {
      case 'Data da Consulta': 
        return consultations.sort((a, b) => {
          if (a.date.getTime() < b.date.getTime()) return -1;
  
          if (a.date.getTime() > b.date.getTime()) return 1;
  
          return 0;
        });

      case 'Valor': 
        return consultations.sort((a, b) => a.value - b.value);

      case 'Status':
        return consultations.sort((a, b) => {
          if (a.status < b.status) return 1;
  
          if (a.status > b.status) return -1;
  
          return 0;
        });

      case 'Vencimento':
        return consultations.sort((a, b) => {
          if (a.due === null) return 1;
          
          if (b.due === null) return -1;
          
          if (a.due.getTime() < b.due.getTime()) return -1;
  
          if (a.due.getTime() > b.due.getTime()) return 1;
  
          return 0;
        });

      case 'Local de Atendimento':
        return consultations.sort((a, b) => {
          if (a.isOnline === b.isOnline) return 0;

          if (a.isOnline === true) return -1;

          return 1;
        });
      
      default: 
        return consultations.sort((a, b) => {
          if (a.professional.name.replace(/(Dr. |Dra. )/g, '') < b.professional.name.replace(/(Dr. |Dra. )/g, '')) return -1;

          if (a.professional.name.replace(/(Dr. |Dra. )/g, '') > b.professional.name.replace(/(Dr. |Dra. )/g, '')) return 1;

          return 0;
        });
    }
  }

  function getDividerColorByStatus(status: ConsultationStatus) {
    switch (status.toLowerCase()) {
      case 'confirmado': return theme.colors.helpers.info;
      case 'pendente': return theme.colors.helpers.warning;
      case 'cancelado': return theme.colors.helpers.danger;
    }
  }

  function handleCancelConsultation() {
    setCancelModal(null);
    toast.success('Consulta cancelada com sucesso!');
  }
  
  // Setting consultations
  useEffect(() => {
    async function getConsultations() {
      const consultationsData = await ConsultationsService.listAllConsultations();
      
      setConsultations(consultationsData);
    }

    getConsultations();
  }, []);


  // Pagination
  useEffect(() => {
    if (!consultations) return;

    const lastPage = Math.ceil(consultations.length / itemsPerPage);
    setLastPage(lastPage);
    
    if (currentPage > lastPage || lastPage === 1) {
      setCurrentPage(1);
    }
  }, [consultations, itemsPerPage, currentPage]);

  if (!consultations) return <div />;

  return (
    <Content>
      <Modal 
        visible={detailsModal !== null}
        title="Detalhes da consulta"
        onRequestClose={() => setDetailsModal(null)}
      >
        <h4 className='subtitle'>Dados do profissional</h4>
        <ul className='list'>
          <li>
            <strong>Nome:</strong>
            <span>{detailsModal?.professional.name}</span>
          </li>

          <li>
            <strong>Especialidade:</strong>
            <span>{detailsModal?.professional.specialty}</span>
          </li>

          <li>
            <strong>{detailsModal?.professional.council}:</strong> 
            <span>{detailsModal?.professional.councilNumber}</span>
          </li>
        </ul>

        <h4 className='subtitle'>Dados da consulta</h4>
        <ul className='list'>
          <li>
            <strong>Data do atendimento:</strong>
            <span>{format(detailsModal?.date as Date, 'dd/MM/yyyy HH:mm')}</span>
          </li>

          <li>
            <strong>Local:</strong>
            <span>{detailsModal?.isOnline ? 'Online' : 'Inserir local'}</span>
          </li>
        </ul>

        <h4 className='subtitle'>Dados da fatura</h4>
        <ul className='list'>
          <li>
            <strong>Valor:</strong> 
            <span>{formatToBRL(detailsModal?.value as number)}</span>
          </li>

          <li>
            <strong>Status:</strong>
            <span>{capitalizeFirstLetter(detailsModal?.status as string)}</span>
          </li>

          <li>
            <strong>Vencimento:</strong> 
            <span>{detailsModal?.due ? format(detailsModal?.due, 'dd/MM/yyyy') : 'Não existe'}</span>
          </li>
        </ul>
      </Modal>

      <Modal 
        visible={cancelModal !== null}
        title="Confirmação de cancelamento"
        onRequestClose={() => setCancelModal(null)} 
      >
        <p className='text'>
          Você confirma o cancelamento da consulta com <strong>{cancelModal?.professional.name}</strong>, em <strong>{format(cancelModal?.date as Date, "dd/MM/yyy 'às' HH:mm")}</strong>, no local <strong>{cancelModal?.isOnline ? 'Online' : 'Inserir local'}</strong>?
        </p>

        <div className='buttons'>
          <button onClick={() => setCancelModal(null)}>
            Não
          </button>

          <button onClick={handleCancelConsultation} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <ContentWrapper className="content">
        <Title>Histórico de Consultas</Title>
        <Description>
          Mantenha o seu cadastro atualizado para aproveitar ao máximo nossas
          vantagens e funcionalidades.
        </Description>

        <FiltersContainer>
          <Select
            label="Classificar por"
            options={orderSelectItems}
            value={orderSelectItems.find(option => option.label === selectedOrder)}
            onChange={option => {
              setSelectedOrder(option?.label || 'Profissional');
              setCurrentPage(1);
            }}
            isClearable={false}
          />

          <Select
            label="Exibir últimos"
            options={limitSelectItems}
            onChange={handleChangeLimit}
            defaultValue={limitSelectItems.find(
              (i) => i.label === String(itemsPerPage)
            )}
            isClearable={false}
          />
        </FiltersContainer>

        <ResultsContainer>
          {getOrderedConsultations(selectedOrder)
            .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
            .map((consultation, index) => (
              <ResultCard key={consultation.professional.name + "--" + index}>
                <ItemContainer>
                  <img src={consultation.professional.avatar || nullAvatar} alt={consultation.professional.name} />
                  
                  <div>
                    <ItemValue>{consultation.professional.name}</ItemValue>
                    <ItemTitle>{format(consultation.date, 'dd/MM/yyyy HH:mm')}</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider />

                <ItemContainer>
                  <div>
                    <ItemValue>{formatToBRL(consultation.value)}</ItemValue>
                    <ItemTitle>Valor</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider
                  width={4}
                  color={getDividerColorByStatus(consultation.status)}
                />

                <ItemContainer>
                  <div>
                    <ItemValue 
                      style={{ 
                        color: getDividerColorByStatus(consultation.status),
                        textTransform: 'capitalize', 
                      }}
                    >{consultation.status}</ItemValue>
                    <ItemTitle>Status</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider />

                <ItemContainer>
                  <div>
                    <ItemValue>{consultation.due ? format(consultation.due, 'dd/MM/yyy') : '--------'}</ItemValue>
                    <ItemTitle>Vencimento</ItemTitle>
                  </div>
                </ItemContainer>
                
                <ItemDivider />
                
                <ItemContainer>
                  <div>
                    <ItemValue>{consultation.isOnline ? 'Sim' : 'Não'}</ItemValue>
                    <ItemTitle>Online</ItemTitle>
                  </div>
                </ItemContainer>
                

                <ActionsContainer>
                  <Tooltip content="Cancelar" disabled={consultation.status !== "pendente"}>
                    <ActionButton 
                      color={theme.colors.helpers.danger} 
                      disabled={consultation.status !== "pendente"}
                      onClick={() => setCancelModal(consultation)}
                    >
                      <CancelSquare />
                    </ActionButton>
                  </Tooltip>
                  
                  <Tooltip content="Imprimir fatura" disabled={consultation.status === "cancelado"}>
                    <ActionButton 
                      color={theme.colors.helpers.info} 
                      disabled={consultation.status === "cancelado"}
                      onClick={() => alert('Redirecionar para fatura')}
                    >
                      <Document />
                    </ActionButton>
                  </Tooltip>
                  
                  <Tooltip content="Detalhes">
                    <ActionButton 
                      color={theme.colors.helpers.success}
                      onClick={() => setDetailsModal(consultation)}
                    >
                      <Info />
                    </ActionButton>
                  </Tooltip>
                </ActionsContainer>
              </ResultCard>
            ))}
        </ResultsContainer>
      </ContentWrapper>

      <Footer>
        <div className="content">
          <Pagination>
            <PageButton
              className="prev"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              <ChevronDown />
            </PageButton>

            <Page>{String(currentPage).padStart(2, "0")}</Page>

            <PageButton
              className="next"
              onClick={goToNextPage}
              disabled={currentPage === lastPage}
            >
              <ChevronDown />
            </PageButton>
          </Pagination>
        </div>
      </Footer>
    </Content>
  );
}