import styled from "styled-components";
import chroma from "chroma-js";

import { Modal, Subtitle } from "@components/Modal";
import { InputGroup } from "@components/Input/styles";
import { Container as Button } from "@components/Button/styles";
import { Time } from "@components/TimeInput/styles";
import { Container as TextArea } from "@components/TextArea/styles";

import { convertToRem } from "@utils/convertToRem";

export const AdvancedFiltersModal = styled(Modal)`
  max-width: ${convertToRem(400)};

  ${InputGroup} {
    width: 100%;
  }
`;

export const DataModal = styled(Modal)`
  ${Subtitle} {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    ${Button} {
      min-width: auto;
      min-height: auto;

      padding: ${convertToRem(8)} ${convertToRem(16)};

      font-size: ${convertToRem(14)};

      border: 0;

      > svg {
        margin-right: ${convertToRem(4)};
      }
    }

    &:not(:first-of-type) {
      margin-top: ${convertToRem(30)};
    }
  }
`;

export const ContactModal = styled(Modal)`
  > div {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${convertToRem(16)};

    width: 100%;

    &:not(:first-of-type) {
      margin-top: ${convertToRem(16)};
    }
  }

  > ${Button} {
    min-width: auto;
    min-height: auto;

    margin-top: ${convertToRem(32)};
    padding: ${convertToRem(16)};
  }

  @media (max-width: 576px) {
    > div {
      grid-template-columns: 1fr;
    }
  }
`;

export const ActionsModal = styled(Modal)`
  ${InputGroup} {
    width: 100%;
  }

  ${Button} {
    min-width: auto;

    > svg {
      width: auto;
      height: ${convertToRem(20)};

      margin-right: ${convertToRem(6)};
    }
  }

  @media (max-width: 576px) {
    ${InputGroup} {
      grid-template-columns: 1fr;
    }
  }
`;

export const EditDataModal = styled(Modal)`
  max-width: ${convertToRem(550)};

  form {
    width: 100%;
  }

  ${InputGroup} {
    width: 100%
  }

  ${Button} {
    width: 100%;

    margin-top: ${convertToRem(24)};
  }

  @media (max-width: 576px) {
    ${InputGroup} {
      grid-template-columns: 1fr;
    }
  }
`;

export const ScheduleTaskModal = styled(Modal)`
  max-width: ${convertToRem(600)};

  form {
    width: 100%;
  }

  ${InputGroup} {
    width: 100%
  }

  ${Time} {
    width: 100%;
    height: ${convertToRem(40)};

    padding: 0 ${convertToRem(16)};

    background-color: ${({ theme }) => theme.colors.neutral.white};
    color: ${({ theme }) => theme.colors.neutral.text};

    border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.borderDark};

    text-align: left;
  }

  ${Button} {
    width: 100%;
    min-width: auto;

    margin-top: ${convertToRem(40)};
  }

  @media (max-width: 576px) {
    ${InputGroup} {
      grid-template-columns: 1fr;

      &:nth-of-type(2) {
        grid-template-columns: 1fr 1fr;

        > *:nth-child(1) {
          grid-column: 1 / 3;
        }
      }
    }
  }
`;

export const ScheduleConsultationModal = styled(Modal)`
  max-width: ${convertToRem(800)};

  form {
    flex: 1;

    width: 100%;

    .react-datepicker-popper {
      position: relative !important;
      transform: translate(0, 0) !important;
    }

    .times {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      align-content: space-around;
      flex-wrap: wrap;
      gap: ${convertToRem(8)};

      height: ${convertToRem(370)};

      margin-top: ${convertToRem(20)};
      padding: ${convertToRem(12)};

      background-color: ${({ theme }) => theme.colors.neutral.borderLight};

      border-radius: ${convertToRem(6)};

      .center-container {
        flex: 1;

        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;

        text-align: center;

        p {
          line-height: 1.5;
        }
      }

      ${Button} {
        min-width: ${convertToRem(70)};
        min-height: ${convertToRem(30)};

        font-size: ${convertToRem(14)};
      }

      &.error {
        border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.helpers.danger};
      }
    }

    > ${Button} {
      width: 100%;
      min-width: auto;

      margin-top: ${convertToRem(32)};
    }
  }

  @media (max-width: 768px) {
    ${InputGroup}:nth-of-type(2) {
      grid-template-columns: 1fr;
    }
  }

  @media (max-width: 576px) {
    ${InputGroup} {
      grid-template-columns: 1fr;
    }
  }
`;

export const ProfessionalModal = styled(Modal)`
  max-width: ${convertToRem(400)};

  form {
    width: 100%;
  }

  ${Button} {
    width: 100%;
    min-width: auto;

    margin-top: ${convertToRem(40)};
  }
`;

export const NoInterestModal = styled(Modal)`
  form {
    width: 100%;
  }

  ${TextArea} {
    margin-top: ${convertToRem(16)};
  }

  ${Button} {
    width: 100%;
    min-width: auto;

    margin-top: ${convertToRem(32)};
  }
`;

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(24)};
`;

export const FiltersContainer = styled.div`
  .filters-container {
    width: 100%;
    height: ${convertToRem(160)};
    
    transition: height 0.6s ease;

    overflow: hidden;

    h4 {
      font-size: ${convertToRem(14)};
    }
    
    .filters {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: ${convertToRem(16)};
  
      margin-top: ${convertToRem(6)};
  
      .buttons {
        align-self: flex-end;
  
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        gap: ${convertToRem(16)};
        
        height: ${convertToRem(40)};
        
        ${Button} {
          flex: 1;
  
          height: 100%;
  
          min-width: auto;
          min-height: auto;
  
          font-size: ${convertToRem(13)};
  
          > svg {
            margin-left: 0;
            margin-right: ${convertToRem(4)};
          }
        }
      }
    }
  }

  .expand-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    
    width: 100%;

    margin-top: ${convertToRem(24)};

    border-top-style: solid;
    border-top-width: ${convertToRem(1)};
    border-top-color: ${({ theme }) => theme.colors.neutral.borderDark};

    transition: all 0.6s ease;

    > ${Button} {
      min-height: ${convertToRem(34)};
      min-width: auto;
  
      padding: 0 ${convertToRem(24)};
  
      font-size: ${convertToRem(14)};

      border-top-left-radius: 0;
      border-top-right-radius: 0;
  
      > svg {
        width: auto;
        height: ${convertToRem(20)};
  
        margin-right: ${convertToRem(4)};
      }
    }
  }

  &.hide {
    .filters-container {
      height: 0;
    }

    .expand-container {
      margin-top: ${convertToRem(-25)};

      border-top-color: transparent;
    }
  }

  @media (max-width: 768px) {
    .filters-container {
      height: ${convertToRem(240)};

      .filters {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }

  @media (max-width: 576px) {
    .filters-container {
      height: ${convertToRem(450)};

      .filters {
        grid-template-columns: 1fr;
      }
    }
  }
`;

export const PageContent = styled.div`
  margin-top: ${convertToRem(24)};
`;

export const KanbanContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: ${convertToRem(15)};

  height: calc(100vh - ${convertToRem(80 + 24 + 160 + 24 + 35 + 24 + 6)});

  padding-bottom: ${convertToRem(16)};

  background-color: ${({ theme }) => theme.colors.neutral.white};

  overflow-x: auto;
  overflow-y: hidden;

  transition: height 0.4s ease;

  &.expanded {
    height: calc(100vh - ${convertToRem(80 + 35 + 24 + 6)});
  }

  &::-webkit-scrollbar {
    width: ${convertToRem(4)};
    
    background-color: ${({ theme }) => chroma(theme.colors.neutral.border).alpha(0.4).css()};
    
    &:horizontal {
      height: ${convertToRem(4)};
    }
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => chroma(theme.colors.neutral.black).alpha(0.3).css()};

    border-radius: ${convertToRem(8)};
  }

  &::-webkit-scrollbar:horizontal {
    height: ${convertToRem(6)};
  }

  @media (max-width: 768px) {
    flex-direction: column;

    height: fit-content !important;
    overflow-y: hidden;
  }
`;

export const KanbanCol = styled.div`
  display: flex;
  flex-direction: column;

  height: 100%;

  min-width: ${convertToRem(350)};
  min-height: ${convertToRem(240)};

  padding: ${convertToRem(10)} ${convertToRem(15)} ${convertToRem(15)};
  
  background-color: ${({ theme }) => chroma(theme.colors.neutral.border).alpha(0.4).css()};
  
  border-radius: ${convertToRem(10)};

  overflow: hidden;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    margin: ${convertToRem(12)} 0 ${convertToRem(24)};

    h4 {
      flex: 1;
  
      color: ${({ theme }) => theme.colors.neutral.textDark};
  
      font-weight: 500;
      font-size: ${convertToRem(16)};
    }

    span {
      display: flex;
      align-items: center;
      justify-content: center;

      width: ${convertToRem(20)};
      height: ${convertToRem(20)};

      background-color: ${({ theme }) => theme.colors.neutral.borderDark};
      color: ${({ theme }) => theme.colors.neutral.textDark};

      font-weight: 500;
      font-size: ${convertToRem(12)};

      border-radius: 6px;
    }
  }

  .cards {
    flex: 1;
    
    position: relative;
    
    overflow: auto;

    &::-webkit-scrollbar {
      width: ${convertToRem(4)};
      
      background-color: ${({ theme }) => chroma(theme.colors.neutral.border).alpha(0.4).css()};
      
      &:horizontal {
        height: ${convertToRem(4)};
      }
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => chroma(theme.colors.neutral.black).alpha(0.3).css()};
  
      border-radius: ${convertToRem(8)};
    }
  }

  @media (max-width: 768px) {
    width: 100%;

    min-width: auto;
    min-height: ${convertToRem(250)};

    overflow-x: auto;

    &::-webkit-scrollbar {
      width: ${convertToRem(6)};
      height: ${convertToRem(6)};
    }
  
    &::-webkit-scrollbar-thumb:horizontal {
      background-color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.3).css()};
  
      border-radius: ${convertToRem(8)};
    }

    &.expanded {
      height: fit-content;
    }

    .cards {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      gap: ${convertToRem(15)};

      padding-bottom: ${convertToRem(16)};
    }
  }
`;