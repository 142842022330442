import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

import { useAuth } from "@contexts/auth";

import { LoadingModal, Modal } from "@components/Modal";
import { Description, Title } from "@components/Header";
import { Button } from "@components/Button";

import UsersService from "@services/UsersService";

import doctorPrime from '@assets/images/prime-card-doctor.png';

import { AxiosAPIError } from '@/types/api';

import { Content, ContentWrapper, CardContainer } from "./styles";

export function Prime() {
  const { selectedAccount, user, addPrime } = useAuth();
  const navigate = useNavigate();

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (!selectedAccount?.isPrime) return;

    toast.info('O plano Prime já foi ativado!');
    navigate('/');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  async function handlePrimeSubscription() {
    try {
      if (!selectedAccount || !user) throw new Error("User must be selected");
      
      setIsConfirmModalVisible(false);
      setIsSubmitting(true);
  
      const response = await UsersService.activatePrime(selectedAccount?.uuid, user.id);

      if (response.status === 200) {
        addPrime();
        toast.success('Prime ativado!');
        navigate('/');
      }
    } catch (err) {
      const error = err as AxiosAPIError;

      console.error(error);

      if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
        error.response.data.dados.forEach(err => toast.error(err));
      } else {
        toast.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <Content>
      <Modal 
        visible={isConfirmModalVisible}
        title="Atenção"
        onRequestClose={() => setIsConfirmModalVisible(false)} 
      >
        <p className='text'>
          Deseja realmente tornar-se PRIME? Após confirmada, essa ação é irreversível e não poderá ser desfeita.
        </p>

        <div className='buttons'>
          <button onClick={() => setIsConfirmModalVisible(false)}>
            Não
          </button>

          <button onClick={handlePrimeSubscription} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <LoadingModal visible={isSubmitting} />

      <ContentWrapper className="content">
        <Title>Plano Prime Clínicas</Title>
        <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus vestibulum, tellus ac rhoncus.
        </Description>

        <CardContainer image={doctorPrime}>
          <div className="texts">
            <h2>Conheça a Agenda consulta PRIME Para clínicas e consultórios</h2>
            <h3>Seja o maior parceiro dos seus pacientes</h3>

            <ul>
              <li>Redução de no-show</li>
              <li>Recebimento em até 30 dias</li>
              <li>Triplique o faturamento de sua clínica</li>
              <li>Sem taxa de adesão ou mensalidades</li>
              <li>Sem burocracia de emitir guias</li>
              <li>Mais de 20.000 vidas na região</li>
              <li>Aumento de pacientes</li>
            </ul>
            
            <Button 
              variant={selectedAccount?.isPrime ? 'secondary' : 'primary'}
              onClick={() => setIsConfirmModalVisible(true)}
              disabled={selectedAccount?.isPrime}
              style={{ opacity: 1 }}
            >
              {selectedAccount?.isPrime ? 'Prime ativado' : 'Quero ser PRIME'}
            </Button>
          </div>
        </CardContainer>
      </ContentWrapper>
    </Content>
  );
}