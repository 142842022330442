import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import * as Yup from 'yup';

import { useFormik } from "formik";
import { LoadingModal, Modal } from '@components/Modal';
import { Loading } from '@components/Loading';
import { Description, Title } from '@components/Header';
import { Select } from '@components/Select';
import ResultCard, { ItemContainer, ItemDivider, ItemTitle, ItemValue } from '@components/ResultCard';
import { Switch } from '@components/Switch';

import LocationsService from '@services/LocationsService';
import ClinicsService from '@services/ClinicsService';

import { formatCPFCNPJ } from '@/utils/formatCPFCNPJ';

import { OptionTypeBase } from '@components/Select/Select';
import { AxiosAPIError } from '@/types/api';

import { Content, ContentWrapper, FiltersContainer, ResultsContainer } from './styles';
import { Button } from '@/components/Button';
import { RiFilterLine } from 'react-icons/ri';

interface CreateFormik {
  uf: number | null;
  city: OptionTypeBase | null;
}

interface City {
  id: number;
  name: string;
  uf: string;
  status: {
    name: string;
    color: string;
  }
}

interface createOptions {
  cidade_id: number | null;
}

export function Cidades() {
  const [isLoadingUFOptions, setIsLoadingUFOptions] = useState(true);
  const [isLoadingCityOptions, setIsLoadingCityOptions] = useState(false);

  const [UFOptions, setUFOptions] = useState<OptionTypeBase[]>([]);
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);

  const [selectedUF, setSelectedUF] = useState<OptionTypeBase | null>(null);
  const [selectedCity, setSelectedCity] = useState<OptionTypeBase | null>(null);
  const [selectedApprovedCity, setSelectedApprovedCity] = useState<City | null>(null);
  
  const [approvedCities, setApprovedCities] = useState<City[]>([]);

  const [create, setCreate] = useState<createOptions>();

  const [isConfirmCityChangeStatusModal, setIsConfirmCityChangeStatusModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [isLoadingStatus, setIsLoadingStatus] = useState(false);

  const createFormik = useFormik<CreateFormik>({
    initialValues: {
      uf: null,
      city: null,
    },
    validationSchema: Yup.object().shape({
      city: Yup.object({ value: Yup.string(), label: Yup.string() })
        .when("uf", {
          is: null,
          then: Yup.object().nullable(),
          otherwise: Yup.object()
            .required("O campo é obrigatório")
            .typeError("Selecione uma cidade")
        }),
    }),
    onSubmit: (values) => {
      setCreate({
        cidade_id: createFormik.values.city ? Number(createFormik.values.city.value) : null,
      });
    }
  });

  // Setting UF options
  useEffect(() => {
    async function getUFs() {
      try {
        setIsLoadingUFOptions(true);

        const { data: UFs } = await LocationsService.listUFs();

        const parsedUFs: OptionTypeBase[] = UFs.map((UF) => ({
          value: String(UF.id),
          label: UF.uf,
        }));

        setUFOptions(parsedUFs);
      } catch (error) {
        setUFOptions([]);

        toast.error("Não foi possível carregar as UFs");

        console.log(error);
      } finally {
        setIsLoadingUFOptions(false);
      }
    }

    getUFs();
  }, []);

  // Setting city options
  useEffect(() => {
    async function getCities() {
      try {
        if (!UFOptions.length || !selectedUF) {
          setCityOptions([]);
          return;
        }
        
        setIsLoadingCityOptions(true);

        const UF = UFOptions.find((uf) => uf.label === selectedUF?.label);

        if (typeof UF === "undefined")
          throw new Error("Estado inválido! Não foi possível buscar as cidades");

        const { data: cities } = await LocationsService.listCitiesByUF(UF.label);

        const parsedCities: OptionTypeBase[] = cities.map((city) => ({
          value: String(city.id),
          label: city.cidade,
        }));

        setCityOptions(parsedCities);
      } catch (error) {
        setCityOptions([]);

        toast.error("Não foi possível carregar as cidades");

        console.error(error);
      } finally {
        setIsLoadingCityOptions(false);
      }
    }

    getCities();
  }, [UFOptions, selectedUF]);

  // Setting cities list
  async function getApprovedCities() {
    try {

      setIsLoading(true);

      const { data } = await LocationsService.listApprovedCities();

      const parsedCities: City[] = data.dados.map(city => ({
        id: city.cidade_id,
        name: city.cidade_nome,
        uf: city.estado_uf,
        status: {
          name: city.status_nome,
          color: city.status_cor,
        }
      }));

      setApprovedCities(parsedCities);
      
    } catch (err) {
      setApprovedCities([]);
      toast.error("Não foi possível carregar as cidades");
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getApprovedCities();
  }, []);

  // Approving city
  useEffect(() => {
    async function approveCity() {
      try {
        if (!create) {
          return;
        }

        setIsCreating(true);

        const { data } = await LocationsService.approveCity({
          cidadeId: create?.cidade_id,
        });

        handleClearCreate();

        toast.success(data.dados);

        getApprovedCities();
        
      } catch (err) {
        toast.error("Não foi possível aprovar a cidade");
      } finally {
        setIsCreating(false);
      }
    }

    approveCity();
  }, [create]);

  function handleClearCreate() {
    createFormik.resetForm();
    setSelectedUF(null);
    setSelectedCity(null);
  }

  async function changeCityStatus() {
    try {
      if (!selectedApprovedCity) {
        return;
      }

      setIsLoadingStatus(true);

      if (selectedApprovedCity.status.name == 'Ativo') {
        const { data } = await LocationsService.unapproveCity({
          cidadeId: selectedApprovedCity.id,
        });
        toast.success(data.dados);
      } else {
        const { data } = await LocationsService.approveCity({
          cidadeId: selectedApprovedCity.id,
        });
        toast.success(data.dados);
      }

      handleClearCreate();

      getApprovedCities();
      
    } catch (err) {
      if (selectedApprovedCity?.status.name == 'Ativo') {
        toast.error("Não foi possível reprovar a cidade");
      } else {
        toast.error("Não foi possível aprovar a cidade");
      }
    } finally {
      setIsLoadingStatus(false);
      setIsConfirmCityChangeStatusModal(false);
    }
  }

  function handleChangeCityStatus() {
    changeCityStatus();
  }

  function handleOpenStatusToggleConfirmation() {
    setIsConfirmCityChangeStatusModal(true);
  }

  function handleCloseStatusToggleConfirmation() {
    setIsConfirmCityChangeStatusModal(false);
  }

  return (
    <Content>

      <Modal
        visible={isConfirmCityChangeStatusModal && selectedApprovedCity !== null}
        onRequestClose={handleCloseStatusToggleConfirmation}
        title="Atenção"
      >
        {selectedApprovedCity?.status.name == 'Ativo' 
          ? (
            <p className='text'>
              A cidade <strong>{selectedApprovedCity?.name}</strong> será REPROVADA para novos cadastros de leads. Deseja continuar?
            </p>
          )
          : (
            <p className='text'>
              A cidade <strong>{selectedApprovedCity?.name}</strong> será APROVADA para novos cadastros de leads. Deseja continuar?
            </p>
          )
        }

        <div className='buttons'>
          <button onClick={handleCloseStatusToggleConfirmation}>
            Não
          </button>

          <button className="primary" onClick={handleChangeCityStatus}>
            {isLoadingStatus ? <Loading color='white'/> : 'Sim'}
          </button>
        </div>
      </Modal>
      
      <ContentWrapper className='content'>
        <Title>Configurações | Cidades para Afiliados</Title>
        <Description>Cidades cadastradas e disponibilizadas para cadastros de leads a partir de afiliados.</Description>

        <form onSubmit={createFormik.handleSubmit}>
          <FiltersContainer>
            <Select 
              label="UF"
              name="uf"
              options={UFOptions}
              value={selectedUF}
              onChange={(selectedOption) => {
                setSelectedUF(selectedOption);
                setSelectedCity(null);
              }}
              isDisabled={isLoadingUFOptions} 
            />

            <Select 
              label="Cidade"
              name="city"
              options={cityOptions}
              value={selectedCity}
              onChange={(selectedOption) => {
                createFormik.setFieldValue('city', selectedOption);
                setSelectedCity(selectedOption);
              }}
              isDisabled={selectedUF === null || isLoadingCityOptions}
            />

            <Button 
              className='btn-aprovar'
              variant='primary' 
              type='submit'
              disabled={selectedUF === null || selectedCity === null || isCreating}
            >
              {isCreating ? <Loading color='white'/> : 'Aprovar cidade'}
            </Button>
          </FiltersContainer>
        </form>
        
        <ResultsContainer>
          {isLoading
          ? <Loading />
          : approvedCities.length > 0
              ? approvedCities.map(city => (
                <ResultCard key={city.id}>
                  <ItemContainer>
                    <div>
                      <ItemValue>{city.name}/{city.uf}</ItemValue>
                      <ItemTitle>Nome</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ItemDivider />

                  <ItemContainer>
                    <div>
                      <ItemValue>
                        <Switch 
                          checked={city.status.name == 'Ativo' ? true : false}
                          onChange={() => {
                            setSelectedApprovedCity(city);
                            handleOpenStatusToggleConfirmation();
                          }}
                        />
                      </ItemValue>
                      <ItemTitle>Status</ItemTitle>
                    </div>
                  </ItemContainer>
                </ResultCard>
              ))
            : 'Nenhuma cidade foi encontrada....'
          }
        </ResultsContainer>
      </ContentWrapper>
    </Content>
  );
}