import HttpClient from "./utils/HttpClient";

import { StatusColors } from "@/types/api";

interface CreateAffiliateRequest {
  nome: string;
	email: string;
	cpf: string;
	telefone: string;
	genero: string;
	data_nascimento: string;

  facebook: string;
  instagram: string;

	tipo_id: string;
	banco: string;
	chave_conta: string;
	valor_agencia: string; 

  endereco: {
    cep: string;
    endereco: string;
    numero: string;
    complemento: string;
    bairro: string;
    cidade: string;
    uf: string;
  };

	aceite: boolean;
}

interface CreateAffiliateResponse {
  codigo: string;
  menssagem: string;
}

interface ListAffiliateResponse {
  codigo: number;
  afiliado: {
		nome: string;
		documento: string;
		genero: string;
		data_nascimento: string;
		telefone: string;
		email: string;
    foto: string;
	};
	banco: {
		tipo_id: number;
		banco: string;
		chave_conta: string;
		valor_agencia: string;
	};
  endereco: {
    cep: string;
    endereco: string;
    numero: string;
    complemento: string;
    bairro: string;
    uf: string;
    cidade: string;
  }
}

export interface UpdateAffiliatePersonalDataRequest {
  tipo_dado: "dados";
	nome: string;
	data_nascimento: string;
	genero: string;
	telefone: string;
	email: string;
}

export interface UpdateAffiliateBankDataRequest {
  tipo_dado: "banco";
  tipo_id: string;
	banco: string;
	chave_conta: string;
	valor_agencia: string;
}

export interface UpdateAffiliateAddressDataRequest {
  tipo_dado: "endereco";
  endereco: {
    cep: string;
    endereco: string;
    numero: string;
    complemento: string;
    bairro: string;
    uf: string;
    cidade: string;
  };
}

interface UpdateAffiliateResponse {
  codigo: string;
  menssagem: string;
}

interface ValidateAffiliateUuidResponse {
  codigo: number;
  afiliado: string;
}

export type ListAdminAffiliatesOrdersBy = 'name' | 'created_at' | 'status_id';

export interface ListAffiliateFilters {
  name?: string;
  cpf?: string;
  uf?: number;
  cidade?: number;
  payment_status_id?: number;
  status_id?: number;
}

interface ListAdminAffiliatesRequest {
  page?: number;
  limit?: number;
  orderBy?: ListAdminAffiliatesOrdersBy;
  order?: 'asc' | 'desc';
  filters?: ListAffiliateFilters;
}

interface ListAdminAffiliatesRequestSelect {
  basic: boolean;
  page?: number;
  limit?: number;
  orderBy?: ListAdminAffiliatesOrdersBy;
  order?: 'asc' | 'desc';
  filters?: ListAffiliateFilters;
}

interface ListAdminAffiliatesResponse {
  codigo: number;
  afiliados: Array<{
    codigo: number;
    afiliado: {
      id: number;
      uuid: string;
      nome: string;
      documento: string;
      email: string;
      genero: string;
      endereco: string;
      numero: string;
      bairro: string;
      complemento: string;
      cep: string;
      cidade: string;
      uf: string;
      data_nascimento: string;
      count_leads: number;
      meta_numero: number;
      meta_nome: string;
      status_id: number;
      status_nome: string;
      status_cor: StatusColors
    };
    pagamento: {
      valor_por_lead: number;
      valor_total: number;
      status_nome: string;
      status_cor: StatusColors;
    };
    banco: {
      tipo_id: number;
      banco: string;
      chave_conta: string;
      valor_agencia: string;
    };
    contatos: {
      email: string;
      telefone: string;
      whatsapp: string;
    };
  }>;
  limite: number;
  pagina: number;
  total: number;
}

interface ListAdminAffiliatesResponseSelect {
  codigo: number;
  afiliados: Array<{
    id: number;
    nome: string;
  }>;
  limite: number;
  pagina: number;
  total: number;
}

interface UpdateAdminAffiliateStatusRequest {
  uuid: string;
  status_id: number;
}

interface UpdateAdminAffiliateStatusResponse {
  codigo: string;
  uuid: string;
  status_id: number;
  status_descricao: string;
  status_cor: StatusColors;
  resposta: string;
}

interface ListAffiliateStatsResponse {
  codigo: number;
  numeros: {
    total: number;
    aprovados: number;
    analise: number;
    reprovados: number;
  };
}

interface ListAffiliateBalanceResponse {
  codigo: number;
  saldo: number;
  total_leads: number;
  valor_por_lead: number;
}

interface ChangeAffiliateAvatarResponse {
  codigo: number;
  menssagem: string;
  foto: string;
}

interface DeleteAffiliateAvatarResponse {
  codigo: number;
  menssagem: string;
}

class AffiliatesService {
  async createAffiliate(affiliate: CreateAffiliateRequest) {
    return HttpClient.post<CreateAffiliateResponse>('/afiliado/cadastro/base', affiliate);
  }

  async listAffiliate() {
    return HttpClient.get<ListAffiliateResponse>('/afiliado/dados');
  }

  async updateAffiliatePersonalData(uuid: string, affiliate: UpdateAffiliatePersonalDataRequest) {
    return HttpClient.put<UpdateAffiliateResponse>(`/afiliado/editar/${uuid}`, affiliate);
  }

  async updateAffiliateBankData(uuid: string, affiliate: UpdateAffiliateBankDataRequest) {
    return HttpClient.put<UpdateAffiliateResponse>(`/afiliado/editar/${uuid}`, affiliate);
  }

  async updateAffiliateAddressData(uuid: string, affiliate: UpdateAffiliateAddressDataRequest) {
    return HttpClient.put<UpdateAffiliateResponse>(`/afiliado/editar/${uuid}`, affiliate);
  }

  async validateAffiliateUuid(uuid: string) {
    return HttpClient.get<ValidateAffiliateUuidResponse>(`/afiliado/leads/cadastro/link/${uuid}`);
  }

  async listAdminAffiliates(params: ListAdminAffiliatesRequest) {
    return HttpClient.get<ListAdminAffiliatesResponse>('/admin/afiliados/listar', {
      params: { ...params, ...params.filters }
    });
  }

  async listAdminAffiliatesSelect(params: ListAdminAffiliatesRequestSelect) {
    return HttpClient.get<ListAdminAffiliatesResponseSelect>('/admin/afiliados/listar', {
      params: { ...params, ...params.filters }
    });
  }

  async updateAdminAffiliateStatus(affiliate: UpdateAdminAffiliateStatusRequest) {
    return HttpClient.post<UpdateAdminAffiliateStatusResponse>('/admin/afiliados/alterarStatus', affiliate);
  }

  async listAffiliateStats() {
    return HttpClient.get<ListAffiliateStatsResponse>('/afiliado/dashboard');
  }

  async listAffiliateBalance() {
    return HttpClient.get<ListAffiliateBalanceResponse>('/afiliado/saldo');
  }

  async changeAffiliateAvatar(avatar: File): Promise<ChangeAffiliateAvatarResponse> {
    const body = new FormData();

    body.append('picture', avatar);
    
    const response = await fetch(`${process.env.REACT_APP_API_URL}/afiliado/alterar-foto`, {
      method: 'POST',
      headers: {
        'Authorization': HttpClient.getToken(),
      },
      body: body,
    });

    return response.json();
  }

  async deleteAffiliateAvatar() {
    return HttpClient.post<DeleteAffiliateAvatarResponse>('/afiliado/remover-foto');
  }
}

export default new AffiliatesService();