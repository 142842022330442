import { Route, Routes } from "react-router-dom";

import { Sidebar } from "@components/Sidebar";
import { Header } from "@components/Header";

import { ConsultationsHistory } from "@pages/Professional/ConsultationsHistory";
import { Reports } from "@pages/Professional/Reports";

import { Container, Main } from "./styles";

export function ProfessionalRoutes() {
  return (
    <Container>
      <Sidebar />

      <Main>
        <Header />

        <Routes>
          <Route path="/" element={<ConsultationsHistory />} />
          <Route path="relatorios/" element={<Reports />} />
        </Routes>
      </Main>
    </Container>
  );
}
