import styled from "styled-components";
import chroma from "chroma-js";

import { Modal } from "@components/Modal";
import { ActionsContainer, Container as ResultCard, ItemContainer, ItemValue, ResponsiveCard } from "@components/ResultCard/styles";
import { Container as Select } from "@components/Select/styles";
import { Container as Button } from "@components/Button/styles";
import { InputGroup } from "@/components/Input";

import { convertToRem } from "@utils/convertToRem";

export const FilterModal = styled(Modal)`
  form {
    display: flex;
    flex-direction: column;
    gap: ${convertToRem(20)};

    width: 100%;
    
    footer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: ${convertToRem(24)};

      margin-top: ${convertToRem(36)};
      
      ${Button} {
        flex: 1;

        min-width: auto;
        max-width: ${convertToRem(270)};

        margin-left: auto;
      }
    }
  }

  @media (max-width: 500px) {
    form {
      footer {
        flex-direction: column;

        ${Button} {
          width: 100%;

          max-width: none;
        }
      }
    }
  }
`;

export const EditModal = styled(Modal)`
  max-width: ${convertToRem(600)};

  form {
    width: 100%;
  }

  ${Button} {
    margin-top: ${convertToRem(24)};
    margin-left: auto;
  }

  @media (max-width: 576px) {
    ${InputGroup} {
      grid-template-columns: 1fr;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;

  margin-top: ${convertToRem(40)};

  background-color: ${({ theme }) => theme.colors.neutral.white};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.borderDark};
  border-radius: ${convertToRem(8)};

  overflow: hidden;
`;

export const Header = styled.header`
  width: 100%;

  padding: ${convertToRem(32)};

  border-bottom: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.borderDark};

  h1 {
    color: ${({ theme }) => theme.colors.neutral.textDark};

    font-size: ${convertToRem(18)};
  }

  .filters {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    gap: ${convertToRem(20)};

    margin-top: ${convertToRem(24)};
    
    ${Select} {
      &:nth-child(1) {
        width: ${convertToRem(220)};
      }
    }

    ${Button} {
      min-width: auto;
      min-height: auto;
      
      height: ${convertToRem(40)};

      padding: 0 ${convertToRem(12)};

      font-size: ${convertToRem(13)};

      > svg {
        margin-left: 0;
        margin-right: ${convertToRem(8)};
      }
    }

    .order {
      display: flex;
      align-items: flex-end;
      justify-content: flex-start;
      
      ${Select} {
        width: ${convertToRem(110)};
      }

      ${Button} {
        display: flex;
        align-items: center;
        justify-content: center;

        width: ${convertToRem(40)};

        margin-left: ${convertToRem(8)};
        padding: 0;

        > svg {
          width: auto;
          height: ${convertToRem(20)};

          margin: 0;
        }
      }
    }
  }

  @media (max-width: 768px) {
    padding: ${convertToRem(32)} ${convertToRem(24)};
    
    .filters {
      flex-direction: column;
      align-items: flex-start;
      gap: ${convertToRem(16)};
  
      ${Select}, .order {
        width: 100% !important;
      }
    }
  }
`;

export const ResultsContainer = styled.div<{ isEmpty?: boolean; }>`
  width: 100%;

  padding: ${({ isEmpty }) => isEmpty ? `${convertToRem(40)} ${convertToRem(30)}` : '0'};

  ${ResultCard} {
    border: 0;
    border-radius: 0;
    
    &:not(:first-child) {
      border-top: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
    }
    
    ${ItemContainer} {
      &:last-of-type {
        padding-right: 0;
      }

      &.status {
        ${ItemValue} {
          padding: ${convertToRem(6)};

          color: ${({ theme }) => theme.colors.neutral.white};

          border-radius: ${convertToRem(14)};
        }
      }
    }

    ${ItemValue} {
      font-size: ${convertToRem(13)};
    }
  }
  
  @media (max-width: 768px) {
    ${ResultCard} {
      ${ResponsiveCard};
    }
  }

  @media (min-width: 768px) {
    ${ResultCard} {
      ${ActionsContainer} {
        flex: 0;

        background: none;

        padding-left: 0;
        padding-right: 0;

        button {
          padding: 0 ${convertToRem(12)};

          color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.5).css()};
        }
      }
    }
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;

  padding: ${convertToRem(24)};

  border-top: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

  .page-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${convertToRem(34)};
    height: ${convertToRem(34)};

    background-color: ${({ theme }) => theme.colors.neutral.white};
    color: ${({ theme }) => theme.colors.primary.base};

    border: solid ${convertToRem(1)} ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.2).css()};
    border-radius: ${convertToRem(8)};

    &.prev {
      transform: rotate(90deg);
    }

    &.next {
      transform: rotate(270deg);
    }
    
    &:disabled {
      background-color: ${({ theme }) => chroma(theme.colors.neutral.border).brighten(0.4).css()};

      cursor: not-allowed;
    }

    > svg {
      width: auto;
      height: ${convertToRem(20)};
      
      stroke-width: 1.2;
    }
  }

  span {
    margin: 0 ${convertToRem(10)};

    color: ${({ theme }) => theme.colors.neutral.text};

    font-weight: 500;
    font-size: ${convertToRem(15)};
    line-height: 1;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`;