import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "styled-components";
import { RiFileCopyLine } from "react-icons/ri";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { format, subMonths } from "date-fns";
import { ptBR } from "date-fns/locale";
import axios from "axios";
import { toast } from "react-toastify";
import chroma from "chroma-js";

import { useAuth } from "@contexts/auth";

import { Loading } from "@components/Loading";
import { Input } from "@components/Input";

import AffiliatesService from "@services/AffiliatesService";
import LeadsService from "@services/LeadsService";
import PaymentsService from "@services/PaymentsService";

import { getRandomNumber } from "@utils/getRandomNumber";
import { formatToBRL } from "@utils/formatToBRL";
import { copyTextToClipboard } from "@utils/copyTextToClipboard";

import { AxiosAPIError, StatusColors } from "@/types/api";

import { ChartContainer, Container, HistoryContainer, LoadingWrapper, IndicationLink, ResumeCards } from "./styles";
import ResultCard, { ItemContainer, ItemDivider, ItemTitle, ItemValue } from "@/components/ResultCard";

const resumeData = {
  aprovados: 378,
  analise: 273,
  reprovados: 107,
};

interface Resume {
  total: number;
  approved: number;
  pending: number;
  reproved: number;
}

interface Chart {
  chart: Array<{
    x: string;
    y: number;
  }>;
  average: number;
  approvedTotal: number;
  pendingTotal: number;
  reprovedTotal: number;
  registersOnDay: number;
}

interface LastLeads {
  id: string;
  name: string;
  status: {
    name: string;
    color: StatusColors;
  }
}

interface LastMovements {
  goal: string;
  amount: number;
  status: {
    name: string;
    color: StatusColors;
  }
}

let fetchInterval: NodeJS.Timer;

export function Dashboard() {
  const { user } = useAuth();

  const indicationLink = `${window.location.origin}/cadastro/${user?.uuid}`;

  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(true);
  const [resume, setResume] = useState<Resume | null>(null); 
  const [chart, setChart] = useState<Chart | null>(null); 
  const [lastLeads, setLastLeads] = useState<LastLeads[]>([]);
  const [lastMovements, setLastMovements] = useState<LastMovements[]>([]);
  
  async function getResume() {
    const { data } = await AffiliatesService.listAffiliateStats();

    const parsedResume: Resume = {
      total: data.numeros.total,
      approved: data.numeros.aprovados,
      pending: data.numeros.analise,
      reproved: data.numeros.reprovados,
    };

    setResume(parsedResume);
  }

  async function getChart() {
    let parsedChart: Array<{ x: string; y: number; }> = [];

    for (let i = 12; i > 0; i--) {
      parsedChart.push({
        x: format(subMonths(new Date(), i), 'MMM/yy', { locale: ptBR }).toLowerCase(),
        y: getRandomNumber(300, 20),
      })
    }

    setChart({
      chart: parsedChart,
      approvedTotal: resumeData.aprovados * 3,
      pendingTotal: resumeData.analise * 3,
      reprovedTotal: resumeData.reprovados * 3,
      average: parsedChart.reduce((acc, cur) => acc + cur.y, 0) / 12,
      registersOnDay: getRandomNumber(127),
    });
  }

  async function getLastLeads() {
    const { data } = await LeadsService.listAffiliateLeads({
      limit: 4,
      order: "desc",
      orderBy: "created_at",
    });

    const parsedLastLeads = data.dados.map<LastLeads>((lead, index) => ({
      id: `${lead.nome}--${index}`,
      name: lead.nome,
      status: {
        name: lead.status_nome,
        color: lead.status_cor,
      }
    }));

    setLastLeads(parsedLastLeads);
  }

  async function getLastMovements() {
    const { data } = await PaymentsService.listPayments(true);

    const parsedLastMovements = data.pagamentos.map<LastMovements>(payment => ({
      goal: payment.meta,
      amount: payment.total,
      status: {
        name: payment.status,
        color: payment.status_cor,
      },
    }));

    setLastMovements(parsedLastMovements);
  }

  useEffect(() => {
    async function handleFetch() {
      try {
        setIsLoading(true);

        await getResume();
        // await getChart();
        await getLastLeads();
        await getLastMovements();
      } catch (err) {
        const error = err as AxiosAPIError;

        console.error(error);

        if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
          error.response.data.dados.forEach(err => toast.error(err));
        } else {
          toast.error(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    }

    handleFetch();
    
    // Refetching every 30 seconds
    fetchInterval = setInterval(handleFetch, 30 * 1000);

    return () => clearInterval(fetchInterval);
  }, []);

  async function copyIndicationLink() {
    try {
      await copyTextToClipboard(indicationLink);
      
      toast.success("Link de indicação copiado com sucesso!");
    } catch (error) {
      console.error(error);
  
      toast.error("Não foi possível copiar o link de indicação!");
    }
  }

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }

  return (
    <Container className="content">
      <IndicationLink>
        <header>
          <h2>Link de indicação</h2>
          <span>{resume?.total || 0}</span>

          <button type="button" onClick={copyIndicationLink}>
            <RiFileCopyLine />

            Copiar link
          </button>
        </header>

        <div className="link">
          <Input 
            name="link"
            value={indicationLink}
            readOnly
          />

          <button type="button" onClick={copyIndicationLink}>
            <RiFileCopyLine />

            Copiar link
          </button>
        </div>
      </IndicationLink>

      <ResumeCards>
        <div className="all">
          <span>Leads</span>
          <strong>{resume?.total || 0}</strong>
        </div>

        <div className="approved">
          <span>Aprovados</span>
          <strong>{resume?.approved || 0}</strong>
        </div>

        <div className="pending">
          <span>Em análise</span>
          <strong>{resume?.pending || 0}</strong>
        </div>

        <div className="rejected">
          <span>Reprovados</span>
          <strong>{resume?.reproved || 0}</strong>
        </div>
      </ResumeCards>

      {/* <ChartContainer>
        <div className="chart-wrapper">
          <h1>Desempenho nos últimos 12 meses</h1>

          <div className="legend">
            <span className="leads">
              <span className="line" />
              Leads Cadastrados
            </span>
          </div>

          <div className="chart">
            {chart !== null && (
              <ResponsiveContainer width="100%" aspect={9/5}>
                <LineChart 
                  data={chart.chart} 
                  margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                >
                  <CartesianGrid vertical={false} />

                  <XAxis 
                    dataKey="x" 
                    interval="preserveStartEnd"
                    tickSize={0} 
                    tickMargin={16}
                    strokeWidth={0.25}
                    stroke={chroma(theme.colors.neutral.text).alpha(0.7).css()}
                  />
                  <YAxis 
                    orientation="right"
                    axisLine={false}
                    tickSize={0} 
                    tickMargin={16} 
                    stroke={chroma(theme.colors.neutral.text).alpha(0.7).css()}
                  />

                  <Tooltip />

                  <Line 
                    type="monotone" 
                    dataKey="y" 
                    strokeWidth={2}
                    stroke={theme.colors.primary.highlight} 
                    dot={{ r: 0 }}
                    activeDot={{ r: 6 }}
                  />
                </LineChart>
              </ResponsiveContainer>
            )}
          </div>
        </div>

        <div className="resume">
          <div>
            <span>Média Geral</span>
            <strong>{chart?.average.toFixed(2) || 0}</strong>
          </div>

          <div>
            <span>Total de Aprovados</span>
            <strong>{chart?.approvedTotal || 0}</strong>
          </div>

          <div>
            <span>Total em Análise</span>
            <strong>{chart?.pendingTotal || 0}</strong>
          </div>

          <div>
            <span>Total de Reprovados</span>
            <strong>{chart?.reprovedTotal || 0}</strong>
          </div>

          <div>
            <span>Total de Cadastros no Dia</span>
            <strong>{chart?.registersOnDay || 0}</strong>
          </div>
        </div>
      </ChartContainer> */}

      <HistoryContainer>
        <div>
          <header>
            <h2>Últimos cadastros</h2>

            <Link to="/afiliado/leads/historico">Ver todos</Link>
          </header>

          <div className="history">
            {lastLeads.length > 0
              ? lastLeads.map(lead => (
                <div key={lead.id}>
                  <span className="title">{lead.name}</span>
                  
                  <span 
                    className="value"
                    style={{ color: theme.colors.helpers[lead.status.color] }}
                  >
                    {lead.status.name}
                  </span>
                </div>
              ))
              : (
                <div >
                  <span className="title">Não há registros de últimos cadastros...</span>
                </div>
              )
            }
          </div>
        </div>

        <div>
          <header>
            <h2>Últimas movimentações</h2>
            
            <Link to="/afiliado/pagamentos/historico">Ver todas</Link>
          </header>

          <div className="history">
            {lastMovements.length > 0
              ? lastMovements.map((movement, index) => (
                <ResultCard key={index}>
                  <ItemContainer>
                    <div>
                      <ItemValue 
                        title={movement.goal}
                      >
                        {movement.goal}
                      </ItemValue>
                      <ItemTitle>Meta</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ItemDivider />

                  <ItemContainer>
                    <div>
                      <ItemValue 
                        title={formatToBRL(movement.amount)}
                      >
                        {formatToBRL(movement.amount)}
                      </ItemValue>
                      <ItemTitle>Valor</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ItemDivider />

                  <ItemContainer>
                    <div>
                      <ItemValue 
                        title={movement.status.name}
                        color={theme.colors.helpers[movement.status.color]}
                      >
                        {movement.status.name}
                      </ItemValue>
                      <ItemTitle>Status</ItemTitle>
                    </div>
                  </ItemContainer>
                </ResultCard>
              ))
              : (
                <div>
                  <span className="title">Não há registros de últimas movimentações...</span>
                </div>
              )
            }
          </div>
        </div>
      </HistoryContainer>
    </Container>
  );
}