import faker from "@faker-js/faker";
import { addDays, set, subDays } from "date-fns";

import HttpClient from "./utils/HttpClient";

export type ExamsStatus = "confirmado" | "pendente" | "cancelado";

export interface Exam {
  id: string;
  name: string;
  local: string;
  value: number;
  status: ExamsStatus;
  date: Date;
}

export interface ExamType {
  id: string;
  codigo: string;
  descricao: string;
  valor: string;
  valorprime: string;
  obs: string;
}

class ExamsService {
  private exams: Exam[];

  constructor() {
    this.exams = [];

    for (let i = 0; i < 20; i++) {
      const { datatype, random } = faker;

      const examDate =  datatype.datetime({ 
        min: set(subDays(new Date(), 3), { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 }).getTime(), 
        max: set(addDays(new Date(), 3), { hours: 17, minutes: 0, seconds: 0, milliseconds: 0 }).getTime()
      });

      this.exams.push({
        id: datatype.uuid(),
        name: random.arrayElement(["Eletrocardiograma (ECG)", "Ultrassonografia", "Raio X", "Exame de sangue"]),
        local: random.arrayElement(["Clínica do Coração", "Medicimais Divinópolis", "Clínica Radioimagem", "Clínica Veine"]),
        date: examDate,
        value: datatype.float({ min: 200, max: 400, precision: 1 }),
        status: random.arrayElement(["confirmado", "pendente", "cancelado"]),
      });
    }
  }

  async listExams(): Promise<Exam[]> {
    return new Promise((resolve) => {
      resolve(this.exams);
    });
  }

  async listAllExamsType() {
    return await HttpClient.externalGet<ExamType[]>('http://localhost:8080/exams?_sort=descricao&_order=asc');
  }
}

export default new ExamsService();
