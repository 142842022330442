import styled from "styled-components";

import { convertToRem } from "@utils/convertToRem";


export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: calc(100vh - ${convertToRem(80)} - ${convertToRem(50)} - ${convertToRem(40)});
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  > div {
    width: 100%;
  }
`;

export const ResultTitle = styled.div`
  margin-bottom: ${convertToRem(30)};
`;