import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "axios";
import { toast } from "react-toastify";

import { Loading } from "@components/Loading";

import { RegisterLeads as AffiliateRegisterLeads } from "@pages/Affiliate/Leads/Register";

import AffiliatesService from "@services/AffiliatesService";

import { AxiosAPIError } from "@/types/api";

import { LoadingContainer, Wrapper } from "./styles";

export interface Affiliate {
  uuid: string;
  name: string;
}

export function RegisterLeads() {
  const { uuid } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [affiliate, setAffiliate] = useState<Affiliate | undefined>(undefined);

  useEffect(() => {
    async function validateAffiliateLink() {
      try {
        setIsLoading(true);

        if (!uuid) {
          setAffiliate(undefined);
          throw new Error('O uuid do afiliado é obrigatório!');
        }

        const { data } = await AffiliatesService.validateAffiliateUuid(uuid);

        setAffiliate({
          name: data.afiliado,
          uuid: uuid,
        });
      } catch (err) {
        const error = err as AxiosAPIError;

        console.error(error);

        if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
          error.response.data.dados.forEach(err => toast.error(err));
        } else {
          toast.error(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    }

    validateAffiliateLink()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading />
        
        <h4>Validando link do afiliado...</h4>
      </LoadingContainer>
    );
  }

  if (affiliate === undefined) {
    return (
      <LoadingContainer>
        <h1>Link do afiliado inválido</h1>
      </LoadingContainer>
    );
  }

  return (
    <Wrapper>
      <Helmet>
        <title>Cadastro - Agenda Consulta</title>
        <meta name="description" content="Cadastre-se grátis e ganhe tratamentos" />
      </Helmet>
      <AffiliateRegisterLeads affiliate={affiliate} />
    </Wrapper>
  );
}