import styled from "styled-components";
import { NavLink } from "react-router-dom";
import chroma from "chroma-js";

import { convertToRem } from "@utils/convertToRem";


export const TabContainer = styled.div`
  display: flex;
  flex-direction: column;

  margin-top: ${convertToRem(36)};
`;

export const TabTitleContainer = styled.div`
  width: 100%;
  
  border-bottom: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

  overflow-x: auto;

  > div {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
  }
`;

export const TabTitle = styled(NavLink)<{ disabled?: boolean; }>`
  flex-shrink: 0;

  display: block;

  padding: ${convertToRem(8)} ${convertToRem(12)};

  color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.5).css()};

  font-weight: 400;
  font-size: ${convertToRem(15)};
  line-height: 1;

  &.active {
    color: ${({ theme }) => theme.colors.primary.highlight};
    
    font-weight: 500;

    box-shadow: inset 0 ${convertToRem(-2)} 0 ${({ theme }) => theme.colors.primary.highlight};
  }

  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'pointer'};
`;

export const TabContentContainer = styled.div`
  width: 100%;
`;

export const TabsContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  width: 100%;
  
  border-bottom: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
  
  button {
    background: none;
    border: 0;

    margin-bottom: ${convertToRem(-1)};
    padding: 0 ${convertToRem(25)} ${convertToRem(10)};

    font-size: ${convertToRem(15)};
    
    color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.5).css()};
    
    &.selected {
      color: ${({ theme }) => theme.colors.primary.highlight};
     
      font-weight: 500;

      box-shadow: inset 0 ${convertToRem(-2)} ${({ theme }) => theme.colors.primary.highlight};
    }
  }
`;