import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { FiLock, FiUnlock } from 'react-icons/fi';

import { Loading } from '@components/Loading';
import { Modal } from '@components/Modal';
import { Description, Title } from '@components/Header';
import { TabsContainer } from '@components/Tabs';
import { Input } from '@components/Input';
import { Select } from '@components/Select';
import ResultCard, { ItemDivider, ItemValue, ItemContainer, ItemTitle, ActionsContainer, ActionButton } from '@components/ResultCard';
import { Tooltip } from '@components/Tooltip';

import CardsService, { Card } from '@services/CardsService';

import { ChevronDown, Search } from '@assets/images';

import { formatToBRL } from '@utils/formatToBRL';

import { OnChangeOptionType } from '@components/Select/Select.d';

import { Content, ContentWrapper, FiltersContainer, Footer, Page, PageButton, Pagination, ResultsContainer } from './styles';


type CardsOrder = 'Nome' | 'Mensalidade' | 'Data de Validade' | 'Data de Ativação';

interface OrderSelect {
  value: CardsOrder,
  label: CardsOrder,
};

type TabsType = 'all' | 'activated' | 'deactivated';

const orderSelectItems: OrderSelect[] = [
  { value: 'Nome', label: 'Nome' },
  { value: 'Mensalidade', label: 'Mensalidade' },
  { value: 'Data de Validade', label: 'Data de Validade' },
  { value: 'Data de Ativação', label: 'Data de Ativação' },
];

const limitSelectItems = [
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
];

export function Prime() {
  const theme = useTheme();

  const [selectedTab, setSelectedTab] = useState<TabsType>('all');

  const [cards, setCards] = useState<Card[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  
  const [search, setSearch] = useState("");
  const [selectedOrder, setSelectedOrder] = useState<CardsOrder>("Nome");
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);

  const [modal, setModal] = useState<Card | null>(null);

  // Setting cards
  useEffect(() => {
    async function getCards() {
      try {
        const cardsData = await CardsService.listCards();
        
        setCards(cardsData);
      } catch (error) {
        toast.error('Não foi possível carregar dados dos cartões!');

        console.log(error);
      } finally {
        setIsLoading(false);
      }
    }

    getCards();
  }, []);

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  function handleChangeLimit(selectedOption: OnChangeOptionType) {
    if (selectedOption === null) return;

    setItemsPerPage(Number(selectedOption.value));
  };

  const filteredCards = cards
    .filter(card => {
      switch(selectedTab) {
        case 'activated': return card.isActivated === true;
        case 'deactivated': return card.isActivated === false;
        default: return true;
      }
    })
    .filter(card => card.name.toLowerCase().includes(search.toLowerCase()))
  ;

  const orderedCards = filteredCards.sort((a, b) => {
    switch(selectedOrder) {
      case 'Mensalidade': 
        return a.monthly - b.monthly;

      case 'Data de Validade':
        if (a.due === null) return 1;
          
        if (b.due === null) return -1;
        
        if (a.due.getTime() < b.due.getTime()) return -1;

        if (a.due.getTime() > b.due.getTime()) return 1;

        return 0;
      
      case 'Data de Ativação':
        if (a.activatedAt.getTime() < b.activatedAt.getTime()) return -1;
  
        if (a.activatedAt.getTime() > b.activatedAt.getTime()) return 1;

        return 0;
      
      default: 
        if (a.name < b.name) return -1;

        if (a.name > b.name) return 1;

        return 0;
    }
  });

  const lastPage = Math.ceil(orderedCards.length / itemsPerPage) || 1;

  function handleToggleIsActivated(isActivated: boolean) {
    setModal(null);
    toast.success(`Cartão ${isActivated ? 'desativado' : 'ativado'} com sucesso!`);
  }

  function handleChangeTab(tab: TabsType) {
    setCurrentPage(1);
    setSelectedTab(tab);
  }

  return (
    <Content>
      <Modal 
        visible={modal !== null}
        title="Atenção"
        onRequestClose={() => setModal(null)} 
      >
        <p className='text'>
          Deseja realmente {modal?.isActivated ? 'desativar' : 'ativar'} o cartão de <strong>{modal?.name}</strong>?
        </p>

        <div className='buttons'>
          <button onClick={() => setModal(null)}>
            Não
          </button>

          <button onClick={() => handleToggleIsActivated(modal?.isActivated as boolean)} className='primary'>
            Sim
          </button>
        </div>
      </Modal>
      
      <ContentWrapper className="content">
        <Title>Cartões Prime</Title>
        <Description>
          Mantenha o seu cadastro atualizado para aproveitar ao máximo nossas
          vantagens e funcionalidades.
        </Description>

        <TabsContainer>
          <button 
            className={selectedTab === 'all' ? 'selected' : ''}
            onClick={() => handleChangeTab('all')}
          >
            Ver todos
          </button>

          <button 
            className={selectedTab === 'activated' ? 'selected' : ''}
            onClick={() => handleChangeTab('activated')}
          >
            Ativados
          </button>

          <button 
            className={selectedTab === 'deactivated' ? 'selected' : ''}
            onClick={() => handleChangeTab('deactivated')}
          >
            Desativados
          </button>
        </TabsContainer>

        <FiltersContainer>
          <Input
            label="Buscar"
            iconRight={<Search />}
            name="search"
            placeholder="Pesquisar nome"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <Select
            label="Classificar por"
            options={orderSelectItems}
            value={orderSelectItems.find(
              (option) => option.label === selectedOrder
            )}
            onChange={(option) => {
              setSelectedOrder(option?.label || "Nome");
              setCurrentPage(1);
            }}
            isClearable={false}
          />

          <Select
            label="Exibir últimos"
            options={limitSelectItems}
            onChange={handleChangeLimit}
            defaultValue={limitSelectItems.find(
              (i) => i.label === String(itemsPerPage)
            )}
            isClearable={false}
          />
        </FiltersContainer>

        <ResultsContainer>
          {isLoading 
            ? <Loading /> 
            : orderedCards.length > 0
              ? orderedCards
                .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                .map(card => (
                  <ResultCard key={card.id}>
                    <ItemContainer>
                      <div>
                        <ItemValue>{card.name}</ItemValue>
                        <ItemTitle>Nome</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ItemDivider />

                    <ItemContainer>
                      <div>
                        <ItemValue>{formatToBRL(card.monthly)}</ItemValue>
                        <ItemTitle>Mensalidade</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ItemDivider />

                    <ItemContainer>
                      <div>
                        <ItemValue>
                          {card.due ? format(card.due, "dd/MM/yyy") : "--------"}
                        </ItemValue>
                        <ItemTitle>Validade</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ItemDivider />

                    <ItemContainer>
                      <div>
                        <ItemValue>
                          {format(card.activatedAt, "dd/MM/yyy")}
                        </ItemValue>
                        <ItemTitle>Data de Ativação</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ActionsContainer>
                      <Tooltip content={card.isActivated ? 'Desativar' : 'Ativar'}>
                        <ActionButton
                          color={card.isActivated ? theme.colors.helpers.danger : theme.colors.helpers.success}
                          onClick={() => setModal(card)}
                        >
                          {card.isActivated ? <FiLock strokeWidth={2.5} /> : <FiUnlock strokeWidth={2.5} />}
                        </ActionButton>
                      </Tooltip>
                    </ActionsContainer>
                  </ResultCard>
                ))
              : 'Nenhum cartão foi encontrado'
          }
        </ResultsContainer>
      </ContentWrapper>

      <Footer>
        <div className="content">
          <Pagination>
            <PageButton
              className="prev"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              <ChevronDown />
            </PageButton>

            <Page>{String(currentPage).padStart(2, "0")}</Page>

            <PageButton
              className="next"
              onClick={goToNextPage}
              disabled={currentPage === lastPage}
            >
              <ChevronDown />
            </PageButton>
          </Pagination>
        </div>
      </Footer>
    </Content>
  );
}