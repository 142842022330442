import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

import { Modal } from '@components/Modal';
import { Description, Title } from '@components/Header';
import { Select } from '@components/Select';
import ResultCard, { ItemDivider, ItemValue, ItemContainer, ItemTitle, ActionsContainer, ActionButton } from '@components/ResultCard';
import { Tooltip } from '@components/Tooltip';

import ExamsService, { Exam, ExamsStatus } from '@services/ExamsService';

import { CancelSquare, ChevronDown, Document, Info } from '@assets/images';

import { formatToBRL } from '@utils/formatToBRL';
import { capitalizeFirstLetter } from '@utils/capitalizeFirstLetter';

import { OnChangeOptionType } from '@components/Select/Select.d';

import { Content, ContentWrapper, FiltersContainer, Footer, Page, PageButton, Pagination, ResultsContainer } from './styles';

export type ExamsOrder = 'Data do Exame' | 'Exame' | 'Valor' | 'Status' | 'Local de Atendimento';

interface OrderSelect {
  value: ExamsOrder,
  label: ExamsOrder,
};

export const orderSelectItems: OrderSelect[] = [
  { value: 'Data do Exame', label: 'Data do Exame' },
  { value: 'Exame', label: 'Exame' },
  { value: 'Valor', label: 'Valor' },
  { value: 'Status', label: 'Status' },
  { value: 'Local de Atendimento' , label: 'Local de Atendimento' },
];

export const limitSelectItems = [
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
];

export function Exams() {
  const theme = useTheme();

  const [exams, setExams] = useState<Exam[]>([]);
  
  const [selectedOrder, setSelectedOrder] = useState<ExamsOrder>("Exame");

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [detailsModal, setDetailsModal] = useState<Exam | null>(null);
  const [cancelModal, setCancelModal] = useState<Exam | null>(null);

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  function handleChangeLimit(selectedOption: OnChangeOptionType) {
    if (selectedOption === null) return;

    setItemsPerPage(Number(selectedOption.value));
  };

  function getOrderedExams(orderBy: ExamsOrder) {
    switch(orderBy) {
      case 'Data do Exame': 
        return exams.sort((a, b) => {
          if (a.date.getTime() < b.date.getTime()) return -1;
  
          if (a.date.getTime() > b.date.getTime()) return 1;
  
          return 0;
        });

      case 'Valor': 
        return exams.sort((a, b) => a.value - b.value);

      case 'Status':
        return exams.sort((a, b) => {
          if (a.status < b.status) return 1;
  
          if (a.status > b.status) return -1;
  
          return 0;
        });

      case 'Local de Atendimento':
        return exams.sort((a, b) => {
          if (a.local < b.local) return 1;
  
          if (a.local > b.local) return -1;
  
          return 0;
        });
      
      default: 
        return exams.sort((a, b) => {
          if (a.name < b.name) return -1;

          if (a.name > b.name) return 1;

          return 0;
        });
    }
  }

  function getDividerColorByStatus(status: ExamsStatus) {
    switch (status.toLowerCase()) {
      case 'confirmado': return theme.colors.helpers.info;
      case 'pendente': return theme.colors.helpers.warning;
      case 'cancelado': return theme.colors.helpers.danger;
    }
  }

  function handleCancelExam() {
    setCancelModal(null);
    toast.success('Exame cancelado com sucesso!');
  }

  // Setting exams
  useEffect(() => {
    async function getExams() {
      const examsData = await ExamsService.listExams();
      
      setExams(examsData);
    }

    getExams();
  }, []);


  // Pagination
  useEffect(() => {
    if (!exams) return;

    const lastPage = Math.ceil(exams.length / itemsPerPage);
    setLastPage(lastPage);
    
    if (currentPage > lastPage || lastPage === 1) {
      setCurrentPage(1);
    }
  }, [exams, itemsPerPage, currentPage]);

  if (!exams) return <div />;

  return (
    <Content>
      <Modal 
        visible={detailsModal !== null}
        title="Detalhes do exame"
        onRequestClose={() => setDetailsModal(null)}
      >
        <h4 className='subtitle'>Dados do exame</h4>
        <ul className='list'>
          <li>
            <strong>Nome:</strong>
            <span>{detailsModal?.name}</span>
          </li>

          <li>
            <strong>Data do atendimento:</strong>
            <span>{format(detailsModal?.date as Date, 'dd/MM/yyyy HH:mm')}</span>
          </li>

          <li>
            <strong>Local:</strong>
            <span>{detailsModal?.local}</span>
          </li>
        </ul>

        <h4 className='subtitle'>Dados da fatura</h4>
        <ul className='list'>
          <li>
            <strong>Valor:</strong> 
            <span>{formatToBRL(detailsModal?.value as number)}</span>
          </li>

          <li>
            <strong>Status:</strong>
            <span>{capitalizeFirstLetter(detailsModal?.status as string)}</span>
          </li>
        </ul>
      </Modal>

      <Modal 
        visible={cancelModal !== null}
        title="Confirmação de cancelamento"
        onRequestClose={() => setCancelModal(null)} 
      >
        <p className='text'>
          Você confirma o cancelamento do <strong>{cancelModal?.name}</strong>, em <strong>{format(cancelModal?.date as Date, "dd/MM/yyy 'às' HH:mm")}</strong>, no local <strong>{cancelModal?.local}</strong>?
        </p>

        <div className='buttons'>
          <button onClick={() => setCancelModal(null)}>
            Não
          </button>

          <button onClick={handleCancelExam} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <ContentWrapper className="content">
        <Title>Histórico de Exames</Title>
        <Description>
          Mantenha o seu cadastro atualizado para aproveitar ao máximo nossas
          vantagens e funcionalidades.
        </Description>

        <FiltersContainer>
          <Select
            label="Classificar por"
            options={orderSelectItems}
            value={orderSelectItems.find(option => option.label === selectedOrder)}
            onChange={option => {
              setSelectedOrder(option?.label || 'Exame');
              setCurrentPage(1);
            }}
            isClearable={false}
          />

          <Select
            label="Exibir últimos"
            options={limitSelectItems}
            onChange={handleChangeLimit}
            defaultValue={limitSelectItems.find(
              (i) => i.label === String(itemsPerPage)
            )}
            isClearable={false}
          />
        </FiltersContainer>

        <ResultsContainer>
          {getOrderedExams(selectedOrder)
            .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
            .map((exam, index) => (
              <ResultCard key={exam.name + "--" + index}>
                <ItemContainer>
                  <div>
                    <ItemValue>{exam.name}</ItemValue>
                    <ItemTitle>{exam.local}</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider />

                <ItemContainer>
                  <div>
                    <ItemValue>{formatToBRL(exam.value)}</ItemValue>
                    <ItemTitle>Valor exame</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider
                  width={4}
                  color={getDividerColorByStatus(exam.status)}
                />

                <ItemContainer>
                  <div>
                    <ItemValue 
                      style={{ 
                        color: getDividerColorByStatus(exam.status),
                        textTransform: 'capitalize', 
                      }}
                    >{exam.status}</ItemValue>
                    <ItemTitle>Status</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider />

                <ItemContainer>
                  <div>
                    <ItemValue>{format(exam.date, 'dd/MM/yyy')}</ItemValue>
                    <ItemTitle>Data</ItemTitle>
                  </div>
                </ItemContainer>
                
                <ActionsContainer>
                  <Tooltip content="Cancelar" disabled={exam.status !== "pendente"}>
                    <ActionButton 
                      color={theme.colors.helpers.danger} 
                      disabled={exam.status !== "pendente"}
                      onClick={() => setCancelModal(exam)}
                    >
                      <CancelSquare />
                    </ActionButton>
                  </Tooltip>
                  
                  <Tooltip content="Imprimir fatura" disabled={exam.status === "cancelado"}>
                    <ActionButton 
                      color={theme.colors.helpers.info} 
                      disabled={exam.status === "cancelado"}
                      onClick={() => alert('Redirecionar para link da fatura')}
                    >
                      <Document />
                    </ActionButton>
                  </Tooltip>
                  
                  <Tooltip content="Detalhes">
                    <ActionButton 
                      color={theme.colors.helpers.success}
                      onClick={() => setDetailsModal(exam)}
                    >
                      <Info />
                    </ActionButton>
                  </Tooltip>
                </ActionsContainer>
              </ResultCard>
            ))}
        </ResultsContainer>
      </ContentWrapper>

      <Footer>
        <div className="content">
          <Pagination>
            <PageButton
              className="prev"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              <ChevronDown />
            </PageButton>

            <Page>{String(currentPage).padStart(2, "0")}</Page>

            <PageButton
              className="next"
              onClick={goToNextPage}
              disabled={currentPage === lastPage}
            >
              <ChevronDown />
            </PageButton>
          </Pagination>
        </div>
      </Footer>
    </Content>
  );
}