import { faker } from '@faker-js/faker';
import { subDays } from 'date-fns';

import HttpClient from './utils/HttpClient';

import { delay } from '@utils/delay';

export interface Clinic {
  id: string;
  avatar: string;
  name: string;
  fantasyName: string;
  email: string;
  cnpj: string;
  address: {
    street: string;
    number: string;
    complement?: string;
    zipCode: string;
    neighborhood: string;
    city: string;
    state: string;
  }
  tel: string;
  whatsapp: string;
  description: string;
  isPrime: boolean;
  status: 'cleared' | 'blocked';
  bankInfo: {
    owner: string;
    bank: string;
    agency: string;
    account: string;
  }
  lastAccess: Date | null;
  createdAt: Date;
};

interface ListAdminClinicsByCityResponse {
  clinicas: Array<{
    id: number;
    nome: string;
    documento: string;
    cidade: string;
    estado: string;
    status: string;
    status_id: number;
  }>;
}

interface UpdateAdminClinicStatusRequest {
  clinicaId: number;
}

interface UpdateAdminClinicStatusResponse {
  codigo: number;
  menssagem: string;
}

faker.setLocale('pt_BR');
class ClinicsService {
  private clinics: Clinic[];

  constructor() {
    this.clinics = [];

    for (let i = 0; i < 20; i++) {
      const { name, address, datatype, phone, lorem, random, internet } = faker;

      const clinicName = `Clínica ${name.lastName()}`;
      const [addressNumber, addressStreetName, addressStreetType] = address.streetAddress().split(' ');

      this.clinics.push({
        id: datatype.uuid(),
        name: clinicName.toUpperCase(),
        fantasyName: clinicName,
        email: internet.email(),
        avatar: internet.avatar(),
        isPrime: datatype.boolean(),
        cnpj: phone.phoneNumber('##############'),
        address: {
          street: `${addressStreetType} ${addressStreetName}`,
          number: addressNumber,
          complement: datatype.boolean() ? address.secondaryAddress() : undefined,
          zipCode: address.zipCode(),
          neighborhood: address.cityName(),
          city: address.city(2),
          state: address.stateAbbr(),
        },
        tel: phone.phoneNumber('(##) ####-####'),
        whatsapp: phone.phoneNumber('(##) ####-####'),
        status: random.arrayElement(['cleared', 'blocked']),
        description: lorem.lines(4),
        bankInfo: {
          owner: clinicName.toUpperCase(),
          bank: '001 - Banco do Brasil',
          agency: '0000',
          account: '00000000-0',
        },
        lastAccess: random.arrayElement([datatype.datetime({ min: subDays(new Date(), 30).getTime(), max: new Date().getTime() }), null]),
        createdAt: datatype.datetime(),
      });
    }
  }

  async listClinics(): Promise<Clinic[]> {
    await delay(500);

    return new Promise((resolve) => {
      resolve(this.clinics);
    });
  }

  async listLastAccessedClinics(): Promise<Clinic[]> {
    await delay(500);

    return new Promise((resolve) => {
      const orderedClinics = [...this.clinics]
        .filter(clinic => !!clinic.lastAccess)
        .sort((a, b) => (b.lastAccess as Date).getTime() - (a.lastAccess as Date).getTime())
      ;

      resolve(orderedClinics);
    });
  }

  async listAdminClinicsByCity(cityID: number) {
    return HttpClient.get<ListAdminClinicsByCityResponse>(`/admin/configuracoes/clinicas/${cityID}`);
  }

  async updateAdminClinicStatus(body: UpdateAdminClinicStatusRequest) {
    return HttpClient.post<UpdateAdminClinicStatusResponse>('/admin/configuracoes/clinicas/ativar-promotor', body);
  }
}

export default new ClinicsService();