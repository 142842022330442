import styled, { css } from "styled-components";
import chroma from "chroma-js";

import { convertToRem } from "@utils/convertToRem";

export const Container = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;

  height: ${convertToRem(90)};

  padding: ${convertToRem(20)} ${convertToRem(30)};

  background-color: ${({ theme }) => theme.colors.neutral.white};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
  border-radius: ${convertToRem(5)};
`;

export const ItemContainer = styled.div`
  flex: 1;

  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 0;
  max-width: ${convertToRem(320)};

  padding-right: ${convertToRem(20)};

  > img {
    display: block;

    width: ${convertToRem(50)};
    height: ${convertToRem(50)};

    margin-right: ${convertToRem(15)};
    
    object-fit: cover;

    border-radius: 50%;
  }

  > div {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    max-width: 100%;
  }
`;

export const ItemValue = styled.strong<{ color?: string; }>`
  max-width: 100%;
  
  color: ${({ theme, color }) => color || theme.colors.neutral.text};
  
  font-weight: 500;
  font-size: ${convertToRem(15)};
  line-height: 1;
  
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ItemTitle = styled.span`
  margin-top: ${convertToRem(8)};

  color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.5).css()};

  font-weight: 500;
  font-size: ${convertToRem(13)};
  line-height: 1;
`;

export const ItemDivider = styled.span<{ width?: number; color?: string; }>`
  width: ${({ width }) => convertToRem(width || 2)};
  height: ${convertToRem(60)};

  background-color: ${({ theme, color }) => color || chroma(theme.colors.neutral.text).alpha(0.1).css()};

  margin: 0 ${convertToRem(10)};

  border-radius: ${convertToRem(20)};
`;

export const ActionsContainer = styled.div`
  flex: 1.5;

  display: flex;

  width: ${convertToRem(235)};
  height: ${convertToRem(60)};

  padding: ${convertToRem(15)};

  margin-left: auto;

  background-color: ${({ theme }) => theme.colors.neutral.borderLight};
  
  border-radius: ${convertToRem(5)};

  > * {
    flex: 1;

    &:not(:last-child) {
      border-right: ${convertToRem(1)} solid ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.3).css()};
    }
  }
`;

export const ActionButton = styled.button<{ color?: string; }>`
  flex: 1;

  width: 100%;
  height: 100%;

  background-color: transparent;
  border: 0;

  color: ${({ color, theme }) => color || theme.colors.neutral.borderDark};

  > svg {
    width: auto;
    height: ${convertToRem(20)};
  }

  &:disabled {
    cursor: not-allowed;

    > svg {
      opacity: 0.3;
    }
  }

  /* &:not(:first-of-type) {
    border-left: ${convertToRem(1)} solid ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.3).css()};
  } */
`;

export const ResponsiveCard = css`
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${convertToRem(16)};

  height: auto;

  padding: ${convertToRem(20)};

  ${ItemContainer} {
    width: 100%;
    max-width: none;

    padding-right: 0;

    > div {
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-between;

      ${ItemTitle}, ${ItemValue} {
        font-size: ${convertToRem(13)};
      }

      ${ItemTitle} {
        margin-top: 0;
      }

      /* 
      // Estilização mobile (status c/ fundo colorido)
      ${ItemValue} {
        padding: ${convertToRem(6)} ${convertToRem(10)};
        
        background-color: ${({ theme }) => theme.colors.helpers.success};
        color: ${({ theme }) => theme.colors.neutral.white};

        border-radius: ${convertToRem(16)};
      } 
      */
    }
  }

  ${ItemDivider} {
    display: none;
  }

  ${ActionsContainer} {
    margin-left: 0;
    margin-top: ${convertToRem(20)};
  }
`;