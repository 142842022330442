import { useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { Employee, useEmployee } from '@contexts/employee';

import { Title, Description } from '@components/Header';
import { Tabs } from '@components/Tabs';

// Tabs
import { PersonalDataTab } from './PersonalDataTab';
import { BankInfoTab } from './BankInfoTab';
import { AddressTab } from './AddressTab';

import { Content, ContentWrapper } from './styles';
import { pixKeyOptions } from '@/constants/pix-keys';

const mockEmployee: Employee = {
  name: 'Paulo Cavalcante',
  email: 'paulo@mail.com',
  position: null,
  birthDate: null,
  avatar: null,
  rg: '',
  cpf: '123.456.789.12',
  ctps: '',
  phone: '',
  whatsApp: '',
  paymentDate: null,
  admissionDate: null,
  demissionDate: null,

  transferType: 'pix',
  bank: 'Itaú',
  keyType: pixKeyOptions[0],
  keyValue: '087.709.795-02',
  bankAgency: '',
  bankAccount: '',
  
  address: {
    zipCode: '42809-469',
    street: 'Rua Cantuária',
    number: '17',
    complement: 'Casa B',
    neighborhood: 'Piaçaveira',
    state: { value: '16', label: 'BA' },
    city: { value: '1898', label: 'Camaçari' },
  },
};

export function AddEditEmployees() {
  const { validation, clearEmployeeInfo, addEmployeeInfo } = useEmployee();

  const { pathname } = useLocation();
  const paths = pathname.split('/');
  const tab = paths[paths.length - 1].toLowerCase();
  const isEditing = paths[3].toLowerCase() === 'editar';

  const [title, setTitle] = useState('');

  const { isPersonalDataTabEmpty, isBankInfoTabEmpty, isAddressTabEmpty } = validation;
  
  useEffect(() => {
    if (isEditing) {
      // Get Employee info from API by uuid
      addEmployeeInfo(mockEmployee);
    } else {
      clearEmployeeInfo();
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditing]);
  
  useEffect(() => {
    const pageTitle = isEditing ? 'Editar' : 'Adicionar';

    switch (tab) {
      case 'dados-pessoais': return setTitle(`Dados Pessoais | ${pageTitle} Colaborador`);
      case 'dados-bancarios': return setTitle(`Dados Bancários | ${pageTitle} Colaborador`);
      case 'endereco': return setTitle(`Endereço | ${pageTitle} Colaborador`);
      default: return setTitle(`${pageTitle} Colaborador`);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return (
    <Content>
      <ContentWrapper className="content">
        <Title>{title}</Title>
        <Description>Mantenha o seu cadastro atualizado para aproveitar ao máximo nossas vantagens e funcionalidades.</Description>

        <Tabs 
          tabs={[
            { 
              path: "/", 
              element: <Navigate to="dados-pessoais" /> 
            },
            { 
              path: "dados-pessoais", 
              title: "Dados Pessoais", 
              element: <PersonalDataTab /> 
            },
            {
              path: "dados-bancarios",
              title: "Dados Bancários",
              element: <BankInfoTab />,
              isDisabled: isPersonalDataTabEmpty,
            },
            { 
              path: "endereco", 
              title: "Endereço", 
              element: <AddressTab />, 
              isDisabled: isPersonalDataTabEmpty || isBankInfoTabEmpty, 
            },
          ]}
        />
      </ContentWrapper>
    </Content>
  );
}