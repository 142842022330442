import styled from 'styled-components';

import { convertToRem } from '@utils/convertToRem';

export const RadioContainer = styled.label`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:not(:first-child) {
    margin-left: ${convertToRem(40)};
  }
`;

export const RadioLabel = styled.span`
display: block;

  margin-left: ${convertToRem(12)};

  color: ${({ theme }) => theme.colors.neutral.text};

  font-weight: 500;
  font-size: ${convertToRem(15)};
  line-height: 1;
  pointer-events: none;
`;

export const InputRadio = styled.input`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: ${convertToRem(20)};
  height: ${convertToRem(20)};

  appearance: none;

  cursor: pointer;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    background-color: transparent;

    border: solid ${convertToRem(2)} ${({ theme }) => theme.colors.neutral.borderDark};
    border-radius: 50%;
  }

  &:checked {
    &::after {
      content: '';

      width: ${convertToRem(12)};
      height: ${convertToRem(12)};

      background-color: ${({ theme }) => theme.colors.primary.highlight};

      border-radius: 50%;
    }
  }
`;

export const RadioGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

export const RadioGroupLabel = styled.strong`
  display: block;
  
  color: ${({ theme }) => theme.colors.neutral.textDark};
  
  font-weight: 500;
  font-size: ${convertToRem(13)};
  line-height: 1;

  margin-bottom: ${convertToRem(8)};
`;

export const RadioGroupOptions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;