import styled from "styled-components";
import chroma from "chroma-js";

import { Time } from "@components/TimeInput/styles";
import { Container as Button } from "@components/Button/styles";

import { convertToRem } from "@utils/convertToRem";

export const Container = styled.form`
  display: grid;
  grid-template-columns: 3fr 9fr;
  gap: ${convertToRem(30)};

  padding-top: ${convertToRem(36)};
  padding-bottom: ${convertToRem(80)};

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

export const Label = styled.strong`
  display: block;

  margin-bottom: ${convertToRem(8)};

  color: ${({ theme }) => theme.colors.neutral.textDark};

  font-weight: 500;
  font-size: ${convertToRem(13)};
  line-height: 1;
`;

export const Sidebar = styled.aside`
  width: 100%;
  height: fit-content;

  > div:not(:first-of-type) {
    margin-top: ${convertToRem(30)};
  }

  @media (max-width: 992px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: ${convertToRem(30)};

    > div:not(:first-of-type) {
      margin-top: 0;
    }
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
    gap: ${convertToRem(30)};
  }
`;


export const SchedulesMenu = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
`;

export const SchedulesMenuButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: ${convertToRem(60)};

  font-weight: 500;
  font-size: ${convertToRem(13)};
  line-height: 1;

  padding-left: ${convertToRem(28)};

  background-color: ${({ theme }) => theme.colors.neutral.borderLight};
  color: ${({ theme }) => chroma(theme.colors.neutral.borderLight).darken(2.25).css()};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

  transition: all ease 0.3s;

  &:first-of-type {
    border-top-left-radius: ${convertToRem(5)};
    border-top-right-radius: ${convertToRem(5)};

    &.active {
      border-top-left-radius: 0;
    }
  }

  &:last-of-type {
    border-bottom-left-radius: ${convertToRem(5)};
    border-bottom-right-radius: ${convertToRem(5)};

    &.active {
      border-bottom-left-radius: 0;
    }
  }

  &:not(:first-of-type) {
    border-top: 0;
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.neutral.white};
    color: ${({ theme }) => theme.colors.neutral.textDark};

    border-left-color: transparent;

    box-shadow: inset ${convertToRem(2)} 0 ${({ theme }) => theme.colors.primary.highlight};
  }
`;

export const ConsultationDurationContainer = styled.div`
  padding: ${convertToRem(15)};

  background-color: ${({ theme }) => theme.colors.neutral.white};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
  border-radius: ${convertToRem(5)};
`;

export const SchedulesContainer = styled.div`
  padding-top: ${convertToRem(21)};
`;

export const ScheduleItem = styled.div<{ disabled?: boolean;}>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  min-height: ${convertToRem(80)};

  padding: ${convertToRem(20)} ${convertToRem(28)};

  background: ${({ theme }) => theme.colors.neutral.white};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.borderDark};
  cursor: ${({ disabled }) => disabled ? 'not-allowed' : 'initial'};

  &:first-of-type {
    border-top-left-radius: ${convertToRem(5)};
    border-top-right-radius: ${convertToRem(5)};
  }

  &:last-of-type {
    border-bottom-left-radius: ${convertToRem(5)};
    border-bottom-right-radius: ${convertToRem(5)};
  }

  &:not(:first-of-type) {
    border-top: 0;
  }

  .day {
    min-width: ${convertToRem(100)};

    color: ${({ theme, disabled }) => disabled ? theme.colors.helpers.danger : theme.colors.neutral.textDark};

    font-weight: 500;
    font-size: ${convertToRem(13)};
    line-height: 1;

    opacity: ${({ disabled }) => disabled ? 0.5 : 1};
  }

  .times {
    margin: 0 auto;

    opacity: ${({ disabled }) => disabled ? 0.5 : 1};
    pointer-events: ${({ disabled }) => disabled ? 'none' : 'initial'};
  }

  .time {
    .time-wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      p {
        color: ${({ theme }) => theme.colors.neutral.text};
        
        font-size: ${convertToRem(14)};
      }

      ${Time}, p {
        margin: 0 ${convertToRem(5)};
      }
        
      .add-time, .remove-time {
        display: flex;
        align-items: center;
        justify-content: center;

        width: ${convertToRem(24)};
        height: ${convertToRem(24)};

        background: none;

        border: 0;

        svg {
          width: auto;
          height: 70%;

          stroke-width: 2.5;
        }

        &.hide {
          opacity: 0;
          pointer-events: none;
        }
      }

      .add-time {
        color: ${({ theme }) => theme.colors.helpers.success};
      }

      .remove-time {
        color: ${({ theme }) => theme.colors.helpers.danger};
      }
    }

    .errors {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;

      margin-top: ${convertToRem(4)};
      padding-left: ${convertToRem(55)};
    }

    &:not(:first-child) {
      margin-top: ${convertToRem(16)};
    }
  }

  .disable-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${convertToRem(30)};
    height: ${convertToRem(30)};

    margin-left: auto;

    background: ${({ theme }) => chroma(theme.colors.neutral.border).alpha(0.3).css()};
    color: ${({ theme }) => theme.colors.neutral.textDark};

    border: 0;
    border-radius: ${convertToRem(6)};
    
    svg {
      width: auto;
      height: ${convertToRem(14)};
      
      opacity: 0.7;
      stroke-width: 2.5;
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;

    .day {
      min-width: initial;
    }

    .times {
      margin: ${convertToRem(24)} 0;
    }

    .disable-btn {
      margin-left: 0;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${convertToRem(24)};

  margin-top: ${convertToRem(100)};

  ${Button} {
    min-width: initial;
    
    width: 100%;
    max-width: ${convertToRem(270)};
  }
`;
