import { FocusEvent, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

import { Input, InputGroup } from "@components/Input";
import { Select } from "@components/Select";
import { TextArea } from "@components/TextArea";
import { Button } from "@components/Button";

import LocationsService from "@services/LocationsService";

import { clinicTypeOptions } from "@constants/clinic-type";
import { personOptions } from "@constants/person-type";

import { SingleValue } from "react-select";
import { OptionTypeBase } from "@components/Select/Select";

import { Content, ContentWrapper, Wrapper, Form } from "./styles";
import { Description, Title } from "@/components/Header";

const specialtyOptions = [
  { label: "Fonoaudiologia", value: "Fonoaudiologia" },
  { label: "Clínico Geral", value: "Clínico Geral" },
  { label: "Farmacêutico", value: "Farmacêutico" },
  { label: "Anestesiologista", value: "Anestesiologista" },
];

interface InitialValues {
  type: OptionTypeBase | null;
  person: OptionTypeBase | null;
  fullName: string;
  clinicName: string;
  socialName: string;
  fantasyName: string;
  document: string;
  email: string;
  tel: string;
  whatsApp: string;
  specialty: OptionTypeBase | null;
  description: string;
  zipCode: string;
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  uf: OptionTypeBase | null;
  city: OptionTypeBase | null;
}

export function AddClinic() {
  const [isLoadingUFOptions, setIsLoadingUFOptions] = useState(false);

  const [UFOptions, setUFOptions] = useState<OptionTypeBase[]>([]);
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);

  const [isCEPValid, setIsCEPValid] = useState(true);
  const [isLoadingCEPInfo, setIsLoadingCEPInfo] = useState(false);

  const formik = useFormik<InitialValues>({
    initialValues: {
      type: null,
      person: personOptions[0],
      fullName: '',
      clinicName: '',
      socialName: '',
      fantasyName: '',
      document: '',
      email: '',
      tel: '',
      whatsApp: '',
      specialty: null,
      description: '',
      zipCode: '',
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      uf: null,
      city: null,
    },
    validationSchema: Yup.object().shape({
      type: Yup.object()
        .typeError("Selecione um tipo")
        .required("O campo é obrigatório"),
      person: Yup.object()
        .typeError("Selecione um tipo de pessoa")
        .required("O campo é obrigatório"),
      fullName: Yup.string()
        .when("person.label", {
          is: "Pessoa Física",
          then: Yup.string().required("O campo é obrigatório")
        }),
      clinicName: Yup.string()
        .when("person.label", {
          is: "Pessoa Física",
          then: Yup.string().required("O campo é obrigatório")
        }),
      socialName: Yup.string()
        .when("person.label", {
          is: "Pessoa Jurídica",
          then: Yup.string().required("O campo é obrigatório")
        }),
      fantasyName: Yup.string()
        .when("person.label", {
          is: "Pessoa Jurídica",
          then: Yup.string().required("O campo é obrigatório")
        }),
      document: Yup.string()
        .required("O campo é obrigatório")
        .when("person.label", {
          is: "Pessoa Jurídica",
          then: Yup.string().matches(/\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2}/, "Informe um CNPJ válido"),
          otherwise: Yup.string().matches(/\d{3}\.?\d{3}\.?\d{3}-?\d{2}/, "Informe um CPF válido")
        }),
      tel: Yup.string()
        .matches(/^[(]?\d{2}[)]?[-\s]?\d{4}[-\s]?\d{4}$/g, "Insira um telefone válido"),
      whatsApp: Yup.string()
        .matches(/^[(]?\d{2}[)]?[-\s]?\d{5}[-\s]?\d{4}$/g, "Insira um WhatsApp válido"),
      email: Yup.string()
        .email("Insira um e-mail válido")
        .required("O campo é obrigatório"),
      specialty: Yup.object()
        .typeError("Selecione uma especialidade")
        .required("O campo é obrigatório"),
      description: Yup.string().required("O campo é obrigatório"),
      zipCode: Yup.string()
        .matches(/[0-9]{5}-?[0-9]{3}/g, "Infome um CEP válido")
        .required("O campo é obrigatório"),
      street: Yup.string().required("O campo é obrigatório"),
      number: Yup.number()
        .required("O campo é obrigatório")
        .typeError("Apenas números"),
      neighborhood: Yup.string().required("O campo é obrigatório"),
      uf: Yup.object()
        .typeError("Selecione uma UF")
        .required("O campo é obrigatório"),
      city: Yup.object()
        .typeError("Selecione uma cidade")
        .required("O campo é obrigatório"),
    }),
    onSubmit: (values) => {
      const parsedValues = Object.assign({}, values) as Partial<InitialValues>;

      if (isLegalPerson) {
        delete parsedValues.fullName;
        delete parsedValues.clinicName;
      } else {
        delete parsedValues.socialName;
        delete parsedValues.fantasyName;
      }

      alert(JSON.stringify(parsedValues, null, 2));
    },
  });

  const isLegalPerson = formik.values.person?.label === "Pessoa Jurídica";

  // Setting UF options
  useEffect(() => {
    async function getUFs() {
      try {
        setIsLoadingUFOptions(true);

        const { data: UFs } = await LocationsService.listUFs();

        const parsedUFs: OptionTypeBase[] = UFs.map((UF) => ({
          value: String(UF.id),
          label: UF.uf,
        }));

        setUFOptions(parsedUFs);
      } catch (error) {
        setUFOptions([]);

        toast.error("Não foi possível carregar as UFs");

        console.log(error);
      } finally {
        setIsLoadingUFOptions(false);
      }
    }

    getUFs();
  }, []);

  function clearAddressFields() {
    formik.setFieldValue("street", "");
    formik.setFieldValue("complement", "");
    formik.setFieldValue("neighborhood", "");
    formik.setFieldValue("uf", null);
    formik.setFieldValue("city", null);
  }

  async function changeCityOption(_uf: string) {
    try {
      const UF = UFOptions.find((uf) => uf.label === _uf);

      if (typeof UF === "undefined")
        throw new Error("UF inválida! Não foi possível buscar as cidades");

      const { data: cities } = await LocationsService.listCitiesByUF(UF.label);

      const parsedCities: OptionTypeBase[] = cities.map((city) => ({
        value: String(city.id),
        label: city.cidade,
      }));

      setCityOptions(parsedCities);

      return parsedCities;
    } catch (error) {
      toast.error("Não foi possível carregar as cidades");

      console.log(error);

      return null;
    }
  }

  async function handleBlurZipCode(event: FocusEvent<HTMLInputElement>) {
    const cep = event.target.value.replace(/[^\d]/g, "");

    if (cep.length !== 8) return clearAddressFields();

    try {
      setIsLoadingCEPInfo(true);

      const data = await LocationsService.getAddressByZipCode(Number(cep));

      if (data.erro) throw new Error("CEP Inválido!");

      setIsCEPValid(true);

      formik.setFieldValue("street", data.logradouro);
      formik.setFieldValue("complement", data.complemento);
      formik.setFieldValue("neighborhood", data.bairro);
      formik.setFieldValue("uf", UFOptions.find(uf => uf.label === data.uf) || null);

      const cities = await changeCityOption(data.uf);
      formik.setFieldValue("uf", UFOptions.find(uf => uf.label === data.uf) || null);
      formik.setFieldValue("city", cities?.find(city => city.label === data.localidade));
    } catch (error) {
      console.log(error);

      setIsCEPValid(false);

      clearAddressFields();
    } finally {
      setIsLoadingCEPInfo(false);
    }
  }

  async function handleChangeUF(option: SingleValue<OptionTypeBase>) {
    if (!option) {
      formik.setFieldValue("uf", "");
      formik.setFieldValue("city", "");

      setCityOptions([]);
      return;
    }

    formik.setFieldValue("uf", option.label);
    await changeCityOption(option.label);
  }

  return (
    <Content>
      <ContentWrapper className="content">
        <Title>Adicionar Clínica</Title>
        <Description>
          Mantenha o seu cadastro atualizado para aproveitar ao máximo nossas
          vantagens e funcionalidades.
        </Description>

        <Wrapper>
          <Form onSubmit={formik.handleSubmit}>
            <InputGroup>
              <Select
                label="Sua clínica ou consultório é"
                name="type"
                options={clinicTypeOptions}
                value={formik.values.type}
                onChange={option => formik.setFieldValue("type", option)}
                error={(formik.touched.type || undefined) && formik.errors.type}
              />

              <Select
                label="Tipo de Pessoa"
                name="person"
                options={personOptions}
                value={formik.values.person}
                onChange={option => formik.setFieldValue("person", option)}
                error={(formik.touched.person || undefined) && formik.errors.person}
                isClearable={false}
              />
            </InputGroup>
            
            {isLegalPerson
              ? (
                <InputGroup>
                  <Input
                    label="Razão Social"
                    name="socialName"
                    value={formik.values.socialName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={(formik.touched.socialName || undefined) && formik.errors.socialName}
                  />

                  <Input
                    label="Nome Fantasia"
                    name="fantasyName"
                    value={formik.values.fantasyName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={(formik.touched.fantasyName || undefined) && formik.errors.fantasyName}
                  />
                </InputGroup>
              )
              : (
                <InputGroup>
                  <Input
                    label="Nome completo"
                    name="fullName"
                    value={formik.values.fullName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={(formik.touched.fullName || undefined) && formik.errors.fullName}
                  />

                  <Input
                    label="Nome da Clínica"
                    name="clinicName"
                    value={formik.values.clinicName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={(formik.touched.clinicName || undefined) && formik.errors.clinicName}
                  />
                </InputGroup>
              )
            }

            <InputGroup>
              <Input 
                label={isLegalPerson ? "CNPJ" : "CPF"} 
                name="document"
                mask={isLegalPerson 
                  ? [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/]
                  : [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]
                } 
                value={formik.values.document}
                onChange={formik.handleChange}
                error={(formik.touched.document || undefined) && formik.errors.document}
              />

              <Input
                label="E-mail"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={(formik.touched.email || undefined) && formik.errors.email}
              />
            </InputGroup>
            
            <InputGroup>
              <Input 
                label="Telefone" 
                name="tel"
                mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                value={formik.values.tel}
                onChange={formik.handleChange}
                error={(formik.touched.tel || undefined) && formik.errors.tel}
              />
              
              <Input 
                label="WhatsApp" 
                name="whatsApp" 
                mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                value={formik.values.whatsApp}
                onChange={formik.handleChange}
                error={(formik.touched.whatsApp || undefined) && formik.errors.whatsApp}
              />
            </InputGroup>
            
            <InputGroup>
              <Select
                label="Especialidade"
                name="specialty"
                options={specialtyOptions}
                value={formik.values.specialty}
                onChange={option => formik.setFieldValue("specialty", option)}
                error={
                  (formik.touched.specialty || undefined) && formik.errors.specialty
                }
              />
            </InputGroup>

            <TextArea
              label="Descrição"
              name="description"
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                (formik.touched.description || undefined) &&
                formik.errors.description
              }
            />

            <InputGroup layout="2fr 5fr">
              <Input
                label="CEP"
                name="zipCode"
                mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
                value={formik.values.zipCode}
                onChange={formik.handleChange}
                onBlur={handleBlurZipCode}
                error={
                  isCEPValid
                    ? (formik.touched.zipCode || undefined) && formik.errors.zipCode
                    : "CEP Inválido!"
                }
              />

              <Input
                label="Logradouro"
                name="street"
                value={formik.values.street}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={(formik.touched.street || undefined) && formik.errors.street}
                disabled={isLoadingCEPInfo}
              />
            </InputGroup>

            <InputGroup layout="1fr 2fr 2fr">
              <Input
                label="Número"
                name="number"
                value={formik.values.number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={(formik.touched.number || undefined) && formik.errors.number}
              />

              <Input
                label="Complemento"
                name="complement"
                value={formik.values.complement}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={isLoadingCEPInfo}
              />

              <Input
                label="Bairro"
                name="neighborhood"
                value={formik.values.neighborhood}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  (formik.touched.neighborhood || undefined) &&
                  formik.errors.neighborhood
                }
                disabled={isLoadingCEPInfo}
              />
            </InputGroup>

            <InputGroup layout="2fr 5fr">
              <Select
                label="Estado"
                name="uf"
                options={UFOptions}
                value={formik.values.uf}
                onChange={(selectedOption) => handleChangeUF(selectedOption)}
                error={(formik.touched.uf || undefined) && formik.errors.uf}
                isDisabled={isLoadingUFOptions || isLoadingCEPInfo}
              />

              <Select
                label="Cidade"
                name="city"
                options={cityOptions}
                value={formik.values.city}
                onChange={option => formik.setFieldValue("city", option)}
                error={(formik.touched.city || undefined) && formik.errors.city}
                isDisabled={isLoadingCEPInfo || formik.values.uf === null}
              />
            </InputGroup>

            <Button type="submit">Cadastrar</Button>
          </Form>
        </Wrapper>
      </ContentWrapper>
    </Content>
  );
}
