import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Link, Navigate, useNavigate, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";

import { LoadingModal } from "@components/Modal";
import { CPFCNPJInput, Input, InputGroup } from "@components/Input";
import { Button } from "@components/Button";

import SessionsService from "@services/SessionsService";

import { AgendaConsultaExpand } from "@assets/images";
import mockup from '@assets/images/mockup.png';

import { AxiosAPIError } from "@/types/api";

import { Banner, Container, Main } from "./styles";

interface InitialValues {
  login: string;
  password: string;
  newPassword: string;
  confirmNewPassword: string;
  code: string;
}

export function ActivateAccount() {
  const { uuid } = useParams<{ uuid: string; }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const formik = useFormik<InitialValues>({
    enableReinitialize: true,
    initialValues: {
      login: searchParams.get("login") || '',
      password: '',
      newPassword: '',
      confirmNewPassword: '',
      code: '',
    },
    validationSchema: Yup.object().shape({
      login: Yup.string()
        .required("O campo é obrigatório"),
      password: Yup.string()
        .min(8, "Insira uma senha com no mínimo 8 caracteres")
        .required("O campo é obrigatório"),
      newPassword: Yup.string()
        .min(8, "Insira uma senha com no mínimo 8 caracteres")
        .required("O campo é obrigatório"),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "As senhas não coincidem")
        .required("O campo é obrigatório"),
      code: Yup.string().required("O campo é obrigatório"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        if (isSubmitting) return;
  
        setIsSubmitting(true);
  
        const { data } = await SessionsService.activateAccount({
          login: values.login,
          codigo: values.code,
          senha: values.password,
          nova_senha: values.newPassword,
          nova_senha_confirmar: values.confirmNewPassword
        });

        toast.success(data.mensagem);
        resetForm();

        navigate('/login');
      } catch (err) {
        const error = err as AxiosAPIError;

        console.error(error);

        if (axios.isAxiosError(error) && error.response && error.response) {
          // error.response.data.dados.forEach(err => toast.error(err));
          toast.error(error.response.data.menssagem);
        } else {
          toast.error(error.message);
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  if (!uuid) {
    toast.error('Acesso negado!');

    return <Navigate to='/login' />
  }

  return (
    <Container>
      <LoadingModal 
        visible={isSubmitting} 
        title="Ativando..."
      />

      <Main>
        <div className="wrapper">
          <Link to="/" className="logo">
            <AgendaConsultaExpand />
          </Link>

          <h1>Ativar conta</h1>

          <form onSubmit={formik.handleSubmit}>
            <CPFCNPJInput 
              label="Login"
              name="login"
              value={formik.values.login}
              onChange={formik.handleChange}
              error={(formik.touched.login || undefined) && formik.errors.login}
            />

            <InputGroup>
              <Input 
                type="password"
                label="Senha"
                name="password"
                value={formik.values.password}
                onChange={formik.handleChange}
                error={(formik.touched.password || undefined) && formik.errors.password}
              />
            </InputGroup>

            <InputGroup>
              <Input 
                type="password"
                label="Nova Senha"
                name="newPassword"
                value={formik.values.newPassword}
                onChange={formik.handleChange}
                error={(formik.touched.newPassword || undefined) && formik.errors.newPassword}
              />

              <Input 
                type="password"
                label="Confirmar Nova Senha"
                name="confirmNewPassword"
                value={formik.values.confirmNewPassword}
                onChange={formik.handleChange}
                error={(formik.touched.confirmNewPassword || undefined) && formik.errors.confirmNewPassword}
              />
            </InputGroup>

            <Input 
              label="Código de Ativação"
              name="code"
              value={formik.values.code}
              onChange={formik.handleChange}
              error={(formik.touched.code || undefined) && formik.errors.code}
            />

            <Button type="submit">
              Ativar
            </Button>
          </form>
        </div>
      </Main>

      <Banner>
        <img src={mockup} alt="Mockup" />
      </Banner>
    </Container>
  );
}