import styled, { css } from "styled-components";
import chroma from "chroma-js";

import { Container as Input } from "@components/Input/styles";
import { Container as Button } from "@components/Button/styles";

import { convertToRem } from "@utils/convertToRem";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;

  padding: ${convertToRem(50)} 0;

  background-color: ${({ theme }) => theme.colors.neutral.white};

  ul {
    list-style: inside;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;

  max-width: ${convertToRem(460)};

`;

export const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;

  padding: ${convertToRem(22)};

  background-color: ${({ theme }) => theme.colors.primary.lightest};

  border-radius: ${convertToRem(5)};

  .general-score {
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${convertToRem(55)};
    height: ${convertToRem(55)};

    background-color: ${({ theme }) => theme.colors.primary.highlight};
    color: ${({ theme }) => theme.colors.neutral.white};

    font-weight: 500;
    font-size: ${convertToRem(20)};

    border-radius: ${convertToRem(3)};
  }

  .general-description {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    height: ${convertToRem(55)};

    margin-left: ${convertToRem(20)};

    color: ${({ theme }) => theme.colors.neutral.black};

    .stars {
      color: ${({ theme }) => theme.colors.helpers.warning};
    }

    h4 {
      margin-top: auto;

      font-weight: 500;
      font-size: ${convertToRem(12)};
    }

    p {
      margin-top: ${convertToRem(4)};

      font-size: ${convertToRem(10)};
    }
  }


  .others-rating {
    margin-left: auto;

    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      .stars {
        margin-top: ${convertToRem(-4)};
        margin-right: ${convertToRem(10)};

        color: ${({ theme }) => theme.colors.helpers.warning};

        svg {
          width: auto;
          height: ${convertToRem(10)};
        }
      }

      h6 {
        font-weight: 400;
        font-size: ${convertToRem(10)};
      }

      &:not(:first-child) {
        margin-top: ${convertToRem(5)};
      }
    }
  }
`;

export const SearchContainer = styled.form`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  width: 100%;

  margin: ${convertToRem(40)} 0;

  ${Input} {
    flex: 1;
  }

  ${Button} {
    min-width: 0;
    min-height: 0;

    height: ${convertToRem(40)};

    margin-left: ${convertToRem(10)};
    padding: 0 ${convertToRem(20)};

    font-weight: 400;
    font-size: ${convertToRem(12)};
  }
`;

export const FeedbacksTabs = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;

  width: 100%;

  margin-bottom: ${convertToRem(40)};

  > button {
    background-color: transparent;
    border: 0;

    &:first-of-type {
      flex: 1.5;
    }

    flex: 1;

    padding-bottom: ${convertToRem(8)};

    color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.5).css()};

    font-weight: 400;
    font-size: ${convertToRem(15)};

    border-bottom: solid ${convertToRem(2)} transparent;
    
    &.active {
      color: ${({ theme }) => theme.colors.primary.highlight};

      font-weight: 500;

      border-bottom-color: ${({ theme }) => theme.colors.primary.highlight};
    }
  }
`;

export const Feedbacks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
`;

export const Feedback = styled.div`
  width: 100%;

  &:not(:first-of-type) {
    margin-top: ${convertToRem(35)};
  }
`;

export const FeedbackComment = styled.div`
  width: 100%;

  header {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 100%;

    > div {
      display: flex;
      flex-direction: column;

      margin-left: ${convertToRem(10)};
      
      strong {
        font-weight: 500;
        font-size: ${convertToRem(12)};
      }
  
      span {
        margin-top: ${convertToRem(4)};
  
        font-size: ${convertToRem(10)};
      }
    }

    .stars {
      margin-left: auto;
      margin-right: ${convertToRem(45)};

      color: ${({ theme }) => theme.colors.helpers.warning};

      svg {
        width: auto;
        height: ${convertToRem(10)};
      }
    }
  }

  > p {
    margin-top: ${convertToRem(15)};
    margin-left: ${convertToRem(45)};

    font-weight: 400;
    font-size: ${convertToRem(15)};
    line-height: 1.3;
  }
`;

export const FeedbackAvatar = styled.span<{ url: string | null; }>`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${convertToRem(35)};
  height: ${convertToRem(35)};

  background-color: ${({ theme }) => theme.colors.neutral.borderDark};
  color: ${({ theme }) => theme.colors.neutral.black};

  font-weight: 400;
  font-size: ${convertToRem(20)};

  ${({ url }) => url && css`
    background-image: url(${url});
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;

    span {
      display: none;
    }
  `}
`;

export const FeedbackResponse = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;

  width: 100%;

  margin-top: ${convertToRem(10)};
  padding-left: ${convertToRem(45)};
  
  p {
    min-height: ${convertToRem(90)};

    margin-right: ${convertToRem(10)};
    padding: ${convertToRem(20)};

    background-color: ${({ theme }) => theme.colors.neutral.borderLight};
    color: ${({ theme }) => theme.colors.neutral.text};

    font-size: ${convertToRem(12)};
    line-height: 1.3;

    border-radius: ${convertToRem(3)};
  }

  img {
    width: ${convertToRem(35)};
    height: ${convertToRem(35)};

    object-fit: cover;

    border: solid ${convertToRem(2)} ${({ theme }) => theme.colors.neutral.borderLight};
    border-radius: 50%;
  }
`;