import styled from "styled-components";

import { Container as Button } from "@components/Button/styles";

import { convertToRem } from "@utils/convertToRem";

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: calc(100vh - ${convertToRem(80)});

  background-color: ${({ theme }) => theme.colors.neutral.white};
`;

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(40)};
`;

export const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: 4fr 2fr 6fr;
  gap: ${convertToRem(30)};

  margin-top: ${convertToRem(28)};

  ${Button} {
    align-self: flex-end;

    margin-left: auto;
  }
`;

export const ResultsContainer = styled.div`
  margin-top: ${convertToRem(30)};

  > div {   
    height: auto;

    &:not(:first-child) {
      margin-top: ${convertToRem(10)};
    }

    > div {
      flex: 0.5;

      &:first-child {
        flex: 2;

        max-width: initial;
      }

      &:last-child {
        padding-right: 0;
      }
    }
  }

`;

export const Footer = styled.footer`
  width: 100%;
  height: ${convertToRem(100)};

  margin-top: ${convertToRem(60)};

  border-top: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

  .content {
    display: flex;
    align-items: center;
  }

  ${Button} {
    margin-left: auto;
  }
`;
