import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { format, parseISO } from 'date-fns';

import { Description, Title } from '@components/Header';
import { Select } from '@components/Select';
import ResultCard, { ItemDivider, ItemValue, ItemContainer, ItemTitle, ActionsContainer, ActionButton } from '@components/ResultCard';
import { Tooltip } from '@components/Tooltip';

import { CancelSquare, Check, ChevronDown, Document, ExternalLink } from '@assets/images';

import { OnChangeOptionType } from '@components/Select/Select.d';

import { AttendedElement, Content, ContentWrapper, FiltersContainer, Footer, Page, PageButton, Pagination, ResultsContainer } from './styles';


const classifySelectItems = [
  { value: 'data', label: 'Data' },
  { value: 'paciente', label: 'Paciente' },
  { value: 'valor', label: 'Valor' },
  { value: 'avaliação', label: 'Avaliação' },
];


const limitSelectItems = [
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
];

const consultationsData =  [
  {
    patient: "Raquel Barroso",
    date: parseISO("2019-05-09T00:00:00"),
    type: 'On-line',
    status: 'Agendada',
    attended: false,
  },
  {
    patient: "Sandro Passos",
    date: parseISO("2018-12-05T00:00:00"),
    type: 'Presencial',
    status: 'Cancelada',
    attended: false,
  },
  {
    patient: "Arlene Bosco",
    date: parseISO("2020-03-20T00:00:00"),
    type: 'Presencial',
    status: 'Paga',
    attended: true,
  },
  {
    patient: "Túlio Nogueira",
    date: parseISO("2018-09-06T00:00:00"),
    type: 'On-line',
    status: 'Paga',
    attended: true,
  },
];

export function ConsultationsHistory() {
  const theme = useTheme();

  const [consultations, setConsultations] = useState<typeof consultationsData | null>(null);

  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);


  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleChangeLimit = (selectedOption: OnChangeOptionType) => {
    if (selectedOption === null) return;

    setItemsPerPage(Number(selectedOption.value));
  };

  const getDividerColorByStatus = (status: string) => {
    switch (status.toLowerCase()) {
      case 'agendada': return theme.colors.helpers.warning;
      case 'cancelada': return theme.colors.helpers.danger;
      case 'paga': return theme.colors.helpers.success;
    }
  }

  useEffect(() => {
    setConsultations(consultationsData);
  }, []);

  useEffect(() => {
    if (!consultations) return;

    const lastPage = Math.ceil(consultations.length / itemsPerPage);
    setLastPage(lastPage);
    
    if (currentPage > lastPage || lastPage === 1) {
      setCurrentPage(1);
    }
  }, [consultations, itemsPerPage, currentPage]);

  if (!consultations) return <div />;

  return (
    <Content>
      <ContentWrapper className="content">
        <Title>Históricos de Consulta</Title>
        <Description>
          Mantenha o seu cadastro atualizado para aproveitar ao máximo nossas vantagens e funcionalidades.
        </Description>

        <FiltersContainer>
          <Select
            label="Classificar por"
            options={classifySelectItems}
            defaultValue={classifySelectItems.find(
              (i) => i.label === "Paciente"
            )}
          />

          <Select
            label="Exibir últimos"
            options={limitSelectItems}
            onChange={handleChangeLimit}
            defaultValue={limitSelectItems.find(
              (i) => i.label === String(itemsPerPage)
            )}
          />
        </FiltersContainer>
        <ResultsContainer>
          {consultations
            .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
            .map((consultation, index) => (
              <ResultCard key={consultation.patient + "--" + index}>
                <ItemContainer>
                  <div>
                    <ItemValue>{consultation.patient}</ItemValue>
                    <ItemTitle>Paciente</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider />

                <ItemContainer>
                  <div>
                    <ItemValue>{format(consultation.date, 'dd/MM/yyyy')}</ItemValue>
                    <ItemTitle>Data</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider />

                <ItemContainer>
                  <div>
                    <ItemValue>{consultation.type}</ItemValue>
                    <ItemTitle>Tipo de consulta</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider
                  width={4}
                  color={getDividerColorByStatus(consultation.status)}
                />

                <ItemContainer>
                  <div>
                    <ItemValue>{consultation.status}</ItemValue>
                    <ItemTitle>Status</ItemTitle>
                  </div>
                </ItemContainer>

                <ItemDivider />

                <ItemContainer>
                  <div>
                    <AttendedElement attended={consultation.attended}>
                      {consultation.attended && (<Check />)}
                    </AttendedElement>
                    <ItemTitle>Compareceu</ItemTitle>
                  </div>
                </ItemContainer>

                <ActionsContainer>
                  <ActionButton color={theme.colors.helpers.info}>
                    <Document />
                  </ActionButton>

                  <Tooltip content="ir para o link da consulta">
                    <ActionButton color={theme.colors.helpers.success}>
                      <ExternalLink />
                    </ActionButton>
                  </Tooltip>

                  <ActionButton color={theme.colors.helpers.danger}>
                    <CancelSquare />
                  </ActionButton>
                </ActionsContainer>
              </ResultCard>
            ))}
        </ResultsContainer>
      </ContentWrapper>

      <Footer>
        <div className="content">
          <Pagination>
            <PageButton
              className="prev"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              <ChevronDown />
            </PageButton>

            <Page>{String(currentPage).padStart(2, "0")}</Page>

            <PageButton
              className="next"
              onClick={goToNextPage}
              disabled={currentPage === lastPage}
            >
              <ChevronDown />
            </PageButton>
          </Pagination>
        </div>
      </Footer>
    </Content>
  );
}