import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";

import { useProfessional } from "@contexts/professional";

import { RadioGroup, RadioInput } from "@components/Radio";
import { CPFCNPJInput, Input, InputGroup } from "@components/Input";
import { Button } from "@components/Button";
import { Select } from "@components/Select";

import { pixKeyOptions } from "@constants/pix-keys";
import { bankOptions } from "@constants/banks";

import { FormikEffect } from "@utils/FormikEffect";

import { OptionTypeBase } from "@components/Select/Select";

import { ButtonsContainer, FormContainer } from "./styles";

interface InitialValues {
  transferType: "pix" | "ted";
  keyType: OptionTypeBase | null;
  keyValue: string;
  bank: OptionTypeBase | null;
  bankAgency: string;
  bankAccount: string;
  bankCpf: string;
};

export function BankInfoTab() {
  const { professional, addProfessionalInfo, validation } = useProfessional();

  const navigate = useNavigate();
  const { uuid } = useParams();

  const formik = useFormik<InitialValues>({
    enableReinitialize: true,
    initialValues: {
      transferType: professional.transferType || 'pix',
      keyType: pixKeyOptions.find(pixKey => pixKey.label === professional.keyType?.label) || null,
      keyValue: professional.keyValue || '',
      bank: bankOptions.find(bank => bank.value === professional.bank?.value) || null,
      bankAgency: professional.bankAgency || '',
      bankAccount: professional.bankAccount || '',
      bankCpf: professional.bankCpf || '',
    },
    validationSchema: Yup.object().shape({
      transferType: Yup.string()
        .required("O campo é obrigatório"),
      keyType: Yup.object()
        .when("transferType", {
          is: "pix",
          then: Yup.object()
            .shape({ value: Yup.string(), label: Yup.string() })
            .required("O campo é obrigatório")
            .typeError("Selecione um tipo de chave"),
          otherwise: Yup.object().nullable()
        }),
      keyValue: Yup.string()
        .when("transferType", {
          is: "pix",
          then: Yup.string()
            .when("keyType.label", {
              is: "CPF/CNPJ",
              then: Yup.string().matches(
                /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/gm,
                "Insira um CPF/CNPJ válido"
              )
            })
            .when("keyType.label", {
              is: "E-mail",
              then: Yup.string().email("Insira um e-mail válido")
            })
            .when("keyType.label", {
              is: "Telefone",
              then: Yup.string().matches(/\(\d{2,}\) \d{4,5}-\d{4}/g, "Insira um telefone válido")
            })
            .required("O campo é obrigatório")
        }),
      bank: Yup.object()
        .when("transferType", {
          is: "ted",
          then: Yup.object()
            .shape({ value: Yup.string(), label: Yup.string() })
            .required("O campo é obrigatório")
            .typeError("Selecione um banco"),
          otherwise: Yup.object().nullable()
        }),
      bankAgency: Yup.string()
        .when("transferType", {
          is: "ted",
          then: Yup.string().required("O campo é obrigatório"),
        }),
      bankAccount: Yup.string()
        .when("transferType", {
          is: "ted",
          then: Yup.string().required("O campo é obrigatório"),
        }),
      bankCpf: Yup.string()
        .when("transferType", {
          is: "ted",
          then: Yup.string()
            .matches(/^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/, "Insira um CPF válido")
            .required("O campo é obrigatório"),
        }),
    }),
    onSubmit: (values) => {
      if (values.transferType === 'pix') {
        addProfessionalInfo({
          transferType: values.transferType,
          keyType: values.keyType,
          keyValue: values.keyValue
        });
      } else {
        addProfessionalInfo({
          bank: values.bank,
          bankAgency: values.bankAgency,
          bankAccount: values.bankAccount,
          bankCpf: values.bankCpf
        });
      }

      navigate(`/profissionais/${uuid !== undefined ? `editar/${uuid}` : 'adicionar'}/endereco`);
    },
  });

  const { isGeneralTabEmpty, isSchedulesTabEmpty, isSettingsTabEmpty } = validation;

  if (isSettingsTabEmpty) {
    navigate(`/profissionais/${uuid !== undefined ? `editar/${uuid}` : 'adicionar'}/configuracoes`);

    return <div />;
  }

  if (isSchedulesTabEmpty) {
    navigate(`/profissionais/${uuid !== undefined ? `editar/${uuid}` : 'adicionar'}/horarios`);

    return <div />;
  }

  if (isGeneralTabEmpty) {
    navigate(`/profissionais/${uuid !== undefined ? `editar/${uuid}` : 'adicionar'}/geral`);

    return <div />;
  }

  return (
    <FormContainer onSubmit={formik.handleSubmit}>
      <FormikEffect formik={formik} />

      <RadioGroup label="Tipo de Transferência">
        <RadioInput 
          label="PIX" 
          value="pix"
          name="pix" 
          isChecked={formik.values.transferType === 'pix'}
          handleChange={() => formik.setFieldValue('transferType', 'pix')}
        />

        <RadioInput 
          label="TED/DOC" 
          value="ted"
          name="ted" 
          isChecked={formik.values.transferType === 'ted'}
          handleChange={() => formik.setFieldValue('transferType', 'ted')}
        />
      </RadioGroup>

      {formik.values.transferType === 'pix'
        ? (
          <InputGroup layout="1fr 2fr">
            <Select 
              label="Tipo de Chave"
              name="keyType"
              options={pixKeyOptions}
              value={formik.values.keyType}
              onChange={(option) => {
                formik.setFieldValue("keyType", option);
                formik.setFieldValue("keyValue", "");
              }}
              error={(formik.touched.keyType || undefined) && formik.errors.keyType}
              isClearable={false}
            />
            
            {formik.values.keyType?.label === 'CPF/CNPJ'
              ? (
                <CPFCNPJInput 
                  label="Valor da Chave"
                  name="keyValue"
                  value={formik.values.keyValue}
                  onChange={formik.handleChange}
                  error={(formik.touched.keyValue || undefined) && formik.errors.keyValue}
                />
              )
              : (
                <Input
                  label="Valor da Chave"
                  type={
                    formik.values.keyType?.label === 'Telefone' 
                    ? 'tel' 
                    : formik.values.keyType?.label === 'E-mail' 
                      ? 'email'
                      : 'text'
                  }
                  mask={
                    formik.values.keyType?.label === 'Telefone'
                    ? ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
                    : undefined
                  }
                  name="keyValue"
                  value={formik.values.keyValue}
                  onChange={formik.handleChange}
                  error={(formik.touched.keyValue || undefined) && formik.errors.keyValue}
                />
              )
            }
          </InputGroup>
        )
        : (
            <>
              <InputGroup layout="2fr 1fr 1fr" className="bank">
                <Select 
                  label="Banco"
                  name="bank"
                  options={bankOptions}
                  value={formik.values.bank}
                  onChange={(option) => formik.setFieldValue("bank", option)}
                  error={(formik.touched.bank || undefined) && formik.errors.bank}
                />

                <Input 
                  label="Agência"
                  name="bankAgency"
                  value={formik.values.bankAgency}
                  onChange={formik.handleChange}
                  error={(formik.touched.bankAgency || undefined) && formik.errors.bankAgency}
                />

                <Input 
                  label="Conta"
                  name="bankAccount"
                  value={formik.values.bankAccount}
                  onChange={formik.handleChange}
                  error={(formik.touched.bankAccount || undefined) && formik.errors.bankAccount}
                />
              </InputGroup>
              
              <Input 
                label="CPF do Titular"
                name="bankCpf"
                mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                value={formik.values.bankCpf}
                onChange={formik.handleChange}
                error={(formik.touched.bankCpf || undefined) && formik.errors.bankCpf}
              />
            </>
        )
      }

      <ButtonsContainer>
        <Button 
          variant="secondary"
          onClick={() => navigate(`/profissionais/${uuid !== undefined ? `editar/${uuid}` : 'adicionar'}/configuracoes`)}
        >
          Voltar
        </Button>

        <Button type="submit">
          Próximo
        </Button>
      </ButtonsContainer>
    </FormContainer>
  );
}