import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import { Loading } from '@components/Loading';
import { LoadingModal, Modal } from '@components/Modal';
import { Input } from '@components/Input';
import { Description, Title } from '@components/Header';
import ResultCard, { ActionButton, ActionsContainer, ItemContainer, ItemTitle, ItemValue } from '@components/ResultCard';
import { Tooltip } from '@components/Tooltip';
import { Button } from '@components/Button';

import { delay } from '@utils/delay';

import { CancelSquare, Memo, PlusCircle } from '@assets/images';

import { PaymentMethodModal, Content, ContentWrapper, Header, ResultsContainer } from './styles';

interface PaymentMethod {
  id: string;
  name: string;
}

const mockPaymentMethods: PaymentMethod[] = [
  { id:'Boleto', name: 'Boleto' },
  { id:'Cartão de Crédito', name: 'Cartão de Crédito' },
  { id:'Cartão de Débito', name: 'Cartão de Débito' },
];

interface PaymentMethodFormik {
  name: string;
}

export function PaymentMethods() {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(true);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);
  
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethod | null>(null);
  const [isAddPaymentMethodModalVisible, setIsAddPaymentMethodModalVisible] = useState(false);
  const [isEditPaymentMethodModalVisible, setIsEditPaymentMethodModalVisible] = useState(false);
  const [isDeletePaymentMethodModalVisible, setIsDeletePaymentMethodModalVisible] = useState(false);

  const [isDeleting, setIsDeleting] = useState(false);

  const addPaymentMethodFormik = useFormik<PaymentMethodFormik>({
    initialValues: {
      name: '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('O campo é obrigatório')
    }),
    onSubmit: async (values, { resetForm }) => {
      const newPaymentMethods: PaymentMethod[] = [
        ...paymentMethods,
        { id: values.name, name: values.name },
      ];

      await delay();
      setPaymentMethods(newPaymentMethods);

      setIsAddPaymentMethodModalVisible(false);
      resetForm();
      toast.success('Forma de pagamento cadastrada com sucesso!')
    }
  });

  const editPaymentMethodFormik = useFormik<PaymentMethodFormik>({
    enableReinitialize: true,
    initialValues: {
      name: selectedPaymentMethod?.name || '',
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required('O campo é obrigatório')
    }),
    onSubmit: async (values, { resetForm }) => {
      if (!selectedPaymentMethod) throw new Error('Selected payment method is required!');

      const editedPaymentMethods: PaymentMethod[] = paymentMethods.map(method => {
        if (method.id === selectedPaymentMethod.id) {
          return {
            id: values.name,
            name: values.name,
          };
        }

        return method;
      });
      
      await delay();

      setPaymentMethods(editedPaymentMethods);

      setIsEditPaymentMethodModalVisible(false);
      resetForm();
      toast.success('Forma de pagamento editada com sucesso!');
    }
  });

  useEffect(() => {
    async function getPaymentMethods() {
      setIsLoading(true);

      setPaymentMethods(mockPaymentMethods);
      await delay();

      setIsLoading(false);
    }

    getPaymentMethods();
  }, []);

  async function handleDeletePaymentMethod() {
    if (!selectedPaymentMethod) throw new Error('Selected payment method is required!');

    setIsDeleting(true);

    const updatedPaymentMethods: PaymentMethod[] = paymentMethods.filter(method => method.id !== selectedPaymentMethod.id);
    
    await delay();

    setPaymentMethods(updatedPaymentMethods);

    setIsDeleting(false);
    setIsDeletePaymentMethodModalVisible(false);
    toast.success('Forma de pagamento excluída com sucesso!')
  }

  return (
    <Content>
      <LoadingModal 
        visible={isDeleting} 
        title="Excluindo..." 
      />

      <PaymentMethodModal
        visible={isAddPaymentMethodModalVisible}
        title="Nova Forma de Pagamento"
        onRequestClose={() => setIsAddPaymentMethodModalVisible(false)}
      >
        <form onSubmit={addPaymentMethodFormik.handleSubmit}>
          <Input
            label="Nome"
            name='name'
            value={addPaymentMethodFormik.values.name}
            onChange={addPaymentMethodFormik.handleChange}
            error={(addPaymentMethodFormik.touched.name || undefined) && addPaymentMethodFormik.errors.name}
          />

          <Button 
            type="submit"
            isLoading={addPaymentMethodFormik.isSubmitting}
            disabled={addPaymentMethodFormik.isSubmitting}
          >
            Adicionar
          </Button>
        </form>
      </PaymentMethodModal>

      <PaymentMethodModal
        visible={selectedPaymentMethod !== null && isEditPaymentMethodModalVisible}
        title="Editar Forma de Pagamento"
        onRequestClose={() => setIsEditPaymentMethodModalVisible(false)}
      >
        <form onSubmit={editPaymentMethodFormik.handleSubmit}>
          <Input
            label="Nome"
            name='name'
            value={editPaymentMethodFormik.values.name}
            onChange={editPaymentMethodFormik.handleChange}
            error={(editPaymentMethodFormik.touched.name || undefined) && editPaymentMethodFormik.errors.name}
          />

          <Button 
            type="submit"
            isLoading={editPaymentMethodFormik.isSubmitting}
            disabled={editPaymentMethodFormik.isSubmitting}
          >
            Alterar
          </Button>
        </form>
      </PaymentMethodModal>

      <Modal 
        visible={selectedPaymentMethod !== null && isDeletePaymentMethodModalVisible}
        title="Atenção"
        onRequestClose={() => setIsDeletePaymentMethodModalVisible(false)} 
      >
        <p className='text'>
          Deseja realmente excluir a forma de pagamento <strong>{selectedPaymentMethod ? selectedPaymentMethod.name : ''}</strong>? <br /><br />
          
          Essa ação irá excluir todos os dados e é irreversível.
        </p>

        <div className='buttons'>
          <button onClick={() => setIsDeletePaymentMethodModalVisible(false)}>
            Não
          </button>

          <button onClick={handleDeletePaymentMethod} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <ContentWrapper className='content'>
        <Header>
          <div>
            <Title>Formas de Pagamento | Configurações</Title>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam massa sapien velit ipsum, ac.
            </Description>
          </div>

          <Button 
            variant="tertiary" 
            onClick={() => setIsAddPaymentMethodModalVisible(true)}
          >
            Adicionar

            <PlusCircle strokeWidth={1.5} />
          </Button>
        </Header>

        <ResultsContainer>
          {isLoading
            ? <Loading />
            : paymentMethods.length > 0 
              ? paymentMethods.map(method => (
                <ResultCard 
                  key={method.id}
                  onClick={() => setSelectedPaymentMethod(method)}
                >
                  <ItemContainer>
                    <div>
                      <ItemValue title={method.name}>
                        {method.name}
                      </ItemValue>
                      <ItemTitle>Nome</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ActionsContainer>
                    <Tooltip content='Editar'>
                      <ActionButton
                        color={theme.colors.helpers.success}
                        onClick={() => setIsEditPaymentMethodModalVisible(true)}
                      >
                        <Memo />
                      </ActionButton>
                    </Tooltip>

                    <Tooltip content='Excluir'>
                      <ActionButton
                        color={theme.colors.helpers.danger}
                        onClick={() => setIsDeletePaymentMethodModalVisible(true)}
                      >
                        <CancelSquare />
                      </ActionButton>
                    </Tooltip>
                  </ActionsContainer>
                </ResultCard>
              ))
              : 'Não existem formas de pagamento cadastradas...'
          }
        </ResultsContainer>
      </ContentWrapper>
    </Content>
  );
}