import styled from "styled-components";

import { convertToRem } from "@utils/convertToRem";


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;

  label {
    display: block;
    
    color: ${({ theme }) => theme.colors.neutral.textDark};

    font-weight: 500;
    font-size: ${convertToRem(13)};
    line-height: 1;

    margin-bottom: ${convertToRem(8)};
  }
`;

export const Error = styled.span`
  margin-top: ${convertToRem(4)};

  color: ${({ theme }) => theme.colors.helpers.danger};

  font-size: ${convertToRem(10)};
`;