import { useEffect, useState } from "react";
import { addDays, format, getDay, hoursToMinutes, isAfter, isEqual, isSameDay, isSameMonth, isSameYear, isToday, isTomorrow, secondsToMinutes, set } from "date-fns";
import { ptBR } from "date-fns/locale";

import { ChevronDown } from "@assets/images";

import { capitalizeFirstLetter } from "@utils/capitalizeFirstLetter";
import { clearTimeInDate } from "@/utils/clearTimeInDate";

import { TimeSchedulingProps } from "./TimeScheduling";

import { Container, Day, DayWeek, NavButton, Time } from "./styles";

export function TimeScheduling({ 
  initialDate: _initialDate, 
  daysPerPage = 5, 
  consultationDuration,
  schedule,
  blockedTimes,
  specials,
  selectedDate: _selectedDate, 
  onChangeSelectedDate 
}: TimeSchedulingProps) {
  const [initialDate, setInitialDate] = useState(clearTimeInDate(_initialDate || new Date()));
  const [currentPage, setCurrentPage] = useState<number>(0);

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  
  useEffect(() => {
    const formatedInitialDate = clearTimeInDate(_initialDate || new Date());

    setInitialDate(formatedInitialDate);
    setCurrentPage(0);
  }, [_initialDate]);

  useEffect(() => {
    setSelectedDate(_selectedDate);
  }, [_selectedDate]);

  const pageDay = addDays(initialDate, daysPerPage * currentPage);

  function getPageDays() {
    let date = [];

    for (let i = 0; i < daysPerPage; i++) {
      const newDate = addDays(pageDay, i);

      date.push(newDate);
    }

    return date;
  }

  function getAvailableTimes(date: Date) {
    let times: Date[] = [];
    
    const [hours, minutes, seconds] = consultationDuration.split(':').map(Number);
    const durationInMinutes = (hours * 60) + minutes + (seconds / 60);

    const specialTimesOnPageDay = specials.filter(({ date: specialDate }) => isSameDay(date, specialDate) && isSameMonth(date, specialDate) && isSameYear(date, specialDate));
    const hasSpecialTimesOnPageDay = specialTimesOnPageDay.length > 0;

    const scheduleOnPageDay = hasSpecialTimesOnPageDay
      ? specialTimesOnPageDay
      : schedule.filter(({ weekDay }) => weekDay === getDay(date))
    ;
    const hasAttendanceOnPageDay = scheduleOnPageDay.length > 0;

    if (hasAttendanceOnPageDay) {
      scheduleOnPageDay.forEach(({ start, end }) => {
        const [startHours, startMinutes, startSeconds] = start.split(':').map(Number);
        const [endHours, endMinutes, endSeconds] = end.split(':').map(Number);
        
        const startInMinutes = hoursToMinutes(startHours) + startMinutes + secondsToMinutes(startSeconds);
        const endInMinutes = hoursToMinutes(endHours) + endMinutes + secondsToMinutes(endSeconds);

        const maxTime = set(date, { minutes: endInMinutes - durationInMinutes });

        let incrementedMinutes = startInMinutes;
        while (incrementedMinutes < endInMinutes) {
          const consultationTime = set(date, { minutes: incrementedMinutes });

          const isConsultationTimeAfterNow = isAfter(consultationTime, new Date());
          if (isConsultationTimeAfterNow) {
            const isConsultationTimeAlreadyScheduled = !!blockedTimes.filter(consultation => isEqual(consultationTime, consultation)).length;

            const isConsultationTimeExceedsMaxTime = isAfter(consultationTime, maxTime);

            if (!isConsultationTimeAlreadyScheduled && !isConsultationTimeExceedsMaxTime) {
              times.push(consultationTime);
            }
          }

          incrementedMinutes += durationInMinutes;
        }
      })
    }

    return times;
  }

  function formatDayWeek(date: Date) {
    if (isToday(date)) return "Hoje";
    if (isTomorrow(date)) return "Amanhã";

    return capitalizeFirstLetter(
      format(date, "E", { locale: ptBR }).slice(0, 3)
    );
  }

  function formatDay(date: Date) {
    return (
      format(date, "dd") +
      " " +
      capitalizeFirstLetter(format(date, "LLL", { locale: ptBR }))
    );
  }

  function verifySelectedDate(time: Date) {
    if (selectedDate === null) return "";

    return isEqual(selectedDate, time) ? "selected" : "";
  }

  function handleSelectedDate(time: Date) {
    setSelectedDate((prevState) => {
      const isSameDate = isEqual(prevState as Date, time);

      if (isSameDate) {
        onChangeSelectedDate(null);
        return null;
      }
      
      onChangeSelectedDate(time);
      return time;
    });
  }

  return (
    <Container>
      <NavButton
        className="prev"
        onClick={() => setCurrentPage((prevState) => prevState - 1)}
        disabled={currentPage === 0}
      >
        <ChevronDown />
      </NavButton>

      <div className="schedule-content">
        {
          getPageDays()
          .map((date, index) => (
            <div key={`${date.toISOString()}--${index}`}>
              <DayWeek>{formatDayWeek(date)}</DayWeek>
              <Day>{formatDay(date)}</Day>

              <div className="hours">
                {
                  getAvailableTimes(date)
                  .map((time, index) => (
                    <Time
                      key={`${time.toISOString()}--${index}`}
                      className={verifySelectedDate(time)}
                      onClick={() => handleSelectedDate(time)}
                    >
                      {format(time, "HH:mm")}
                    </Time>
                ))}
              </div>
            </div>
          ))
        }
      </div>

      <NavButton
        className="next"
        onClick={() => setCurrentPage((prevState) => prevState + 1)}
      >
        <ChevronDown />
      </NavButton>
    </Container>
  );
}