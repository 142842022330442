import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';

import { LoadingModal, Modal } from '@components/Modal';
import { Loading } from '@components/Loading';
import { Description, Title } from '@components/Header';
import { Select } from '@components/Select';
import ResultCard, { ItemContainer, ItemDivider, ItemTitle, ItemValue } from '@components/ResultCard';
import { Switch } from '@components/Switch';

import LocationsService from '@services/LocationsService';
import ClinicsService from '@services/ClinicsService';

import { formatCPFCNPJ } from '@/utils/formatCPFCNPJ';

import { OptionTypeBase } from '@components/Select/Select';
import { AxiosAPIError } from '@/types/api';

import { Content, ContentWrapper, FiltersContainer, ResultsContainer } from './styles';

interface Clinic {
  id: number;
  name: string;
  document: string;
  state: string;
  city: string;
  isActive: boolean;
}

export function Clinics() {
  const [isLoadingUFOptions, setIsLoadingUFOptions] = useState(true);
  const [isLoadingCityOptions, setIsLoadingCityOptions] = useState(false);

  const [UFOptions, setUFOptions] = useState<OptionTypeBase[]>([]);
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);

  const [selectedUF, setSelectedUF] = useState<OptionTypeBase | null>(null);
  const [selectedCity, setSelectedCity] = useState<OptionTypeBase | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [clinics, setClinics] = useState<Clinic[]>([]);

  const [activatedClinic, setActivatedClinic] = useState<Clinic | null>(null);
  const [selectedClinic, setSelectedClinic] = useState<Clinic | null>(null);

  const [isConfirmClinicActivationModal, setIsConfirmClinicActivationModal] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Setting UF options
  useEffect(() => {
    async function getUFs() {
      try {
        setIsLoadingUFOptions(true);

        const { data: UFs } = await LocationsService.listUFs();

        const parsedUFs: OptionTypeBase[] = UFs.map((UF) => ({
          value: String(UF.id),
          label: UF.uf,
        }));

        setUFOptions(parsedUFs);
      } catch (error) {
        setUFOptions([]);

        toast.error("Não foi possível carregar as UFs");

        console.log(error);
      } finally {
        setIsLoadingUFOptions(false);
      }
    }

    getUFs();
  }, []);

  // Setting city options
  useEffect(() => {
    async function getCities() {
      try {
        if (!UFOptions.length || !selectedUF) {
          setCityOptions([]);
          return;
        }
        
        setIsLoadingCityOptions(true);

        const UF = UFOptions.find((uf) => uf.label === selectedUF?.label);

        if (typeof UF === "undefined")
          throw new Error("Estado inválido! Não foi possível buscar as cidades");

        const { data: cities } = await LocationsService.listCitiesByUF(UF.label);

        const parsedCities: OptionTypeBase[] = cities.map((city) => ({
          value: String(city.id),
          label: city.cidade,
        }));

        setCityOptions(parsedCities);
      } catch (error) {
        setCityOptions([]);

        toast.error("Não foi possível carregar as cidades");

        console.error(error);
      } finally {
        setIsLoadingCityOptions(false);
      }
    }

    getCities();
  }, [UFOptions, selectedUF]);

  // Setting clinics
  useEffect(() => {
    async function getClinics() {
      try {
        if (!selectedUF || !selectedCity) return;

        setIsLoading(true);

        const { data } = await ClinicsService.listAdminClinicsByCity(Number(selectedCity.value));

        const parsedClinics: Clinic[] = data.clinicas.map(clinic => ({
          id: clinic.id,
          name: clinic.nome,
          document: clinic.documento,
          state: clinic.estado,
          city: clinic.cidade,
          isActive: clinic.status_id === 1,
        }));

        setClinics(parsedClinics);
        
        const activatedClinic = parsedClinics.find(clinic => clinic.isActive);
        setActivatedClinic(activatedClinic || null);
      } catch (err) {
        const error = err as AxiosAPIError;
        
        console.error(error);
        setClinics([]);
        
        if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
          error.response.data.dados.forEach(err => toast.error(err));
        } else {
          toast.error(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    }

    getClinics();
  }, [selectedUF, selectedCity]);

  function handleOpenConfirmClinicActivationModal(clinic: Clinic) {
    setIsConfirmClinicActivationModal(true);
    setSelectedClinic(clinic);
  }

  function handleCloseConfirmClinicActivationModal() {
    setIsConfirmClinicActivationModal(false);
    setSelectedClinic(null);
  }
  
  async function handleChangeClinicStatus() {
    if (!selectedClinic) {
      toast.error('Selecione uma clínica!');
      return;
    }
    
    try {
      setIsConfirmClinicActivationModal(false);
      setIsSubmitting(true);
      
      const { data } = await ClinicsService.updateAdminClinicStatus({
        clinicaId: selectedClinic.id
      });
  
      const updatedClinics = clinics.map(clinic => ({
        ...clinic,
        isActive: clinic.id === selectedClinic.id
      }));
      setClinics(updatedClinics);

      const activatedClinic = updatedClinics.find(clinic => clinic.isActive);
      setActivatedClinic(activatedClinic || null);

      toast.success(data.menssagem);
    } catch (err) {
      const error = err as AxiosAPIError;
        
      console.error(error);
      
      if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
        error.response.data.dados.forEach(err => toast.error(err));
      } else {
        toast.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
      setSelectedClinic(null);
    }
  }

  return (
    <Content>
      <Modal
        visible={isConfirmClinicActivationModal && selectedClinic !== null}
        onRequestClose={handleCloseConfirmClinicActivationModal}
        title="Atenção"
      >
        {activatedClinic 
          ? (
            <p className='text'>
              Ao ativar a clínica <strong>{selectedClinic?.name}</strong>, a clínica <strong>{activatedClinic.name}</strong> será desativada. Deseja continuar?
            </p>
          )
          : (
            <p className='text'>
              A clínica <strong>{selectedClinic?.name}</strong> será ativada. Deseja continuar?
            </p>
          )
        }

        <div className='buttons'>
          <button onClick={handleCloseConfirmClinicActivationModal}>
            Não
          </button>

          <button className="primary" onClick={handleChangeClinicStatus}>
            Sim
          </button>
        </div>
      </Modal>

      <LoadingModal visible={isSubmitting} title="Ativando..." />
      
      <ContentWrapper className='content'>
        <Title>Configurações | Clínicas</Title>
        <Description>Clínicas habilitadas a receber leads por afiliados.</Description>

        <FiltersContainer>
          <Select 
            label="UF"
            name="uf"
            options={UFOptions}
            value={selectedUF}
            onChange={(selectedOption) => {
              setSelectedUF(selectedOption);
              setSelectedCity(null);
            }}
            isDisabled={isLoadingUFOptions} 
          />

          <Select 
            label="Cidade"
            name="city"
            options={cityOptions}
            value={selectedCity}
            onChange={(selectedOption) => setSelectedCity(selectedOption)}
            isDisabled={selectedUF === null || isLoadingCityOptions}
          />
        </FiltersContainer>
        
        <ResultsContainer>
          {isLoading
          ? <Loading />
          : selectedUF !== null
            ? selectedCity !== null
              ? clinics.length > 0
                  ? clinics.map(clinic => (
                    <ResultCard key={clinic.id}>
                      <ItemContainer>
                        <div>
                          <ItemValue>{clinic.name}</ItemValue>
                          <ItemTitle>Nome</ItemTitle>
                        </div>
                      </ItemContainer>

                      <ItemDivider />

                      <ItemContainer>
                        <div>
                          <ItemValue>{formatCPFCNPJ(clinic.document)}</ItemValue>
                          <ItemTitle>CPF/CNPJ</ItemTitle>
                        </div>
                      </ItemContainer>

                      <ItemDivider />

                      <ItemContainer>
                        <div>
                          <ItemValue>{clinic.city}/{clinic.state}</ItemValue>
                          <ItemTitle>Cidade/UF</ItemTitle>
                        </div>
                      </ItemContainer>

                      <ItemDivider />

                      <ItemContainer>
                        <div>
                          <ItemValue>
                            <Switch 
                              checked={clinic.isActive}
                              onChange={() => {
                                if (clinic.isActive) {
                                  toast.info('Ao menos uma clínica deve estar ativada! Caso queira desativá-la, ative uma outra clínica ou entre em contato com nosso suporte.')
                                  return;
                                }

                                handleOpenConfirmClinicActivationModal(clinic);
                              }}
                            />
                          </ItemValue>
                          <ItemTitle>Status</ItemTitle>
                        </div>
                      </ItemContainer>
                    </ResultCard>
                  ))
                  : 'Nenhuma clínica foi encontrada....'
              : 'Selecione uma cidade'
            : 'Selecione um estado'
          }
        </ResultsContainer>
      </ContentWrapper>
    </Content>
  );
}