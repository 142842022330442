import { RiDashboardLine } from 'react-icons/ri';

import { SidebarMenuItem } from './index';

export function ClinicAdminSidebar() {
  return (
    <>
      <SidebarMenuItem 
        path='/'
        icon={<RiDashboardLine />}
        title='Dashboard'
      />
    </>
  );
}