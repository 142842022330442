import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { RiEyeLine, RiFilterLine, RiFilterOffLine } from 'react-icons/ri';
import { BiTrashAlt } from 'react-icons/bi';
import { useFormik } from 'formik';

import { Loading } from '@components/Loading';
import { Modal } from '@components/Modal';
import { Description, Title } from '@components/Header';
import { Button } from '@components/Button';
import { Input, InputGroup } from '@components/Input';
import { DatePicker } from '@components/DatePicker';
import { Select } from '@components/Select';
import ResultCard, { ItemDivider, ItemValue, ItemContainer, ItemTitle, ActionsContainer, ActionButton } from '@components/ResultCard';
import { Tooltip } from '@components/Tooltip';

import LeadsService from '@services/LeadsService';

import { ChevronDown } from '@assets/images';

import { getRandomNumber } from '@/utils/getRandomNumber';

import { OnChangeOptionType, OptionTypeBase } from '@components/Select/Select.d';

import { Content, ContentWrapper, DetailsModal, FilterModal, FiltersContainer, Footer, Page, PageButton, Pagination, ResultsContainer } from './styles';
import { FiLock, FiUnlock } from 'react-icons/fi';

const orderSelectItems: OptionTypeBase[] = [
  { value: 'Nome', label: 'Nome' },
  { value: 'Data', label: 'Data' },
];

const limitSelectItems: OptionTypeBase[] = [
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
];

interface FilterFormik {
  employee: string;
  patient: string;
  initialDate: Date | null; 
  endDate: Date | null; 
}

interface DetailsFormik {
  name: string;
  guardian: string;
  mail: string;
  cpf: string;
  rg: string;
  birthDate: Date | null;
  phone: string;
  whatsapp: string;
  uf: OptionTypeBase | null;
  city: OptionTypeBase | null;
  address: string;
}

interface Affiliate {
  id: string;
  date: Date;
  name: string;
  affiliateCount: number;
  goal: number;
  tel: string;
  isActivated: boolean;
}

export function Affiliates() {
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(true);
  const [affiliates, setAffiliates] = useState<Affiliate[]>([]);
  
  const [selectedOrder, setSelectedOrder] = useState("Data");
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);
  
  const orderedAffiliates = affiliates.sort((a, b) => {
    switch (selectedOrder) {
      case 'Data':
        if (a.date.getTime() < b.date.getTime()) return 1;

        if (a.date.getTime() > b.date.getTime()) return -1;

      return 0; 

      default: 
        if (a.name < b.name) return -1;

        if (a.name > b.name) return 1;

      return 0;
    }
  });
  const lastPage = Math.ceil(orderedAffiliates.length / itemsPerPage) || 1;

  const [isFilterModalVisible, setIsFilterModalVisible] = useState(false);
  
  const [isFiltering, setIsFiltering] = useState(false);
  const filterFormik = useFormik<FilterFormik>({
    initialValues: {
      employee: '',
      patient: '',
      initialDate: null,
      endDate: null,
    },
    onSubmit: (values) => {
      const areAllValuesFalsy = Object.values(values).every(e => !Boolean(e));
      if (areAllValuesFalsy) {
        setIsFiltering(false);
      } else {
        setIsFiltering(true);
      }

      alert(JSON.stringify(values));
      setIsFilterModalVisible(false);
    }
  });

  const [selectedAffiliate, setSelectedAffiliate] = useState<Affiliate | null>(null);
  const [isDetailsModalVisible, setIsDetailsModalVisible] = useState(false);
  const [isToggleStatusModalVisible, setIsToggleStatusModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const detailsFormik = useFormik<DetailsFormik>({
    enableReinitialize: true,
    initialValues: {
      name: selectedAffiliate?.name || '',
      guardian: '',
      mail: '',
      cpf: '',
      rg: '',
      birthDate: null,
      phone: '',
      whatsapp: '',
      uf: null,
      city: null,
      address: '',
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values));
    }
  });

  // Setting affiliates
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        
        const affiliatesData = await LeadsService.listFakerLeads();

        const parsedAffiliates: Affiliate[] = affiliatesData.map(affiliate => ({
          id: affiliate.id,
          date: affiliate.date,
          name: affiliate.name,
          goal: 299,
          affiliateCount: getRandomNumber(299, 0),
          tel: affiliate.tel,
          isActivated: getRandomNumber(2) === 1,
        }));

        setAffiliates(parsedAffiliates);
      } catch (error) {
        toast.error('Não foi possível carregar dados dos afiliados!');

        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })()
  }, []);

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  function handleChangeLimit(selectedOption: OnChangeOptionType) {
    if (selectedOption === null) return;

    setItemsPerPage(Number(selectedOption.value));
  }

  function handleToggleAffiliateStatus() {
    if (!selectedAffiliate) throw new Error('Affiliate must be selected');

    setAffiliates(affiliates => affiliates.map(affiliate => {
      if (affiliate.id === selectedAffiliate.id) {
        return {
          ...affiliate,
          isActivated: !affiliate.isActivated,
        }
      }

      return affiliate;
    }));

    setIsToggleStatusModalVisible(false);
    toast.success(`Afiliado ${selectedAffiliate.isActivated ? 'desativado' : 'ativado'} com sucesso!`);
  }

  function handleDeleteAffiliate() {
    if (!selectedAffiliate) throw new Error('Affiliate must be selected');
    
    setAffiliates(affiliates => affiliates.filter(affiliate => affiliate.id !== selectedAffiliate.id));

    setIsDeleteModalVisible(false);
    toast.success('Afiliado excluído com sucesso!');
  }

  return (
    <>
      <FilterModal
        visible={isFilterModalVisible}
        title="Filtrar por"
        onRequestClose={() => setIsFilterModalVisible(false)}
      >
        <form onSubmit={filterFormik.handleSubmit}>
          <Input 
            label='Colaborador'
            name='employee'
            value={filterFormik.values.employee}
            onChange={filterFormik.handleChange}
          />

          <InputGroup>
            <Input 
              label='Paciente'
              name='patient'
              value={filterFormik.values.patient}
              onChange={filterFormik.handleChange}
            />
          </InputGroup>

          <InputGroup>
            <DatePicker 
              label='Data de Ínicio'
              name='initialDate'
              selected={filterFormik.values.initialDate}
              onChange={(selectedDate) => filterFormik.setFieldValue('initialDate', selectedDate)}
              selectsStart
              startDate={filterFormik.values.initialDate}
              endDate={filterFormik.values.endDate}
              maxDate={new Date()}
            />

            <DatePicker 
              label='Data de Fim'
              name='endDate'
              selected={filterFormik.values.endDate}
              onChange={(selectedDate) => filterFormik.setFieldValue('endDate', selectedDate)}
              selectsEnd
              endDate={filterFormik.values.endDate}
              minDate={filterFormik.values.initialDate}
              maxDate={new Date()}
            />
          </InputGroup>

          <Button type='submit'>Filtrar</Button>
        </form>
      </FilterModal>
      
      <DetailsModal
        visible={selectedAffiliate !== null && isDetailsModalVisible}
        title="Detalhes do afiliado"
        onRequestClose={() => setIsDetailsModalVisible(false)} 
      >
        <form>
          <InputGroup>
            <Input
              label='Nome'
              name='name'
              value={detailsFormik.values.name}
              onChange={detailsFormik.handleChange}
            />

            <Input
              label='Responsável'
              name='guardian'
              value={detailsFormik.values.guardian}
              onChange={detailsFormik.handleChange}
            />
          </InputGroup>

          <InputGroup>
            <Input
              label='E-mail'
              name='mail'
              value={detailsFormik.values.mail}
              onChange={detailsFormik.handleChange}
            />

            <Input
              label='CPF'
              name='cpf'
              value={detailsFormik.values.cpf}
              onChange={detailsFormik.handleChange}
            />
          </InputGroup>

          <InputGroup>
            <Input
              label='RG'
              name='rg'
              value={detailsFormik.values.rg}
              onChange={detailsFormik.handleChange}
            />

            <DatePicker 
              label='Data de Nascimento'
              name='birthDate'
              selected={detailsFormik.values.birthDate}
              onChange={(selectedDate) => detailsFormik.setFieldValue('birthDate', selectedDate)}
            />
          </InputGroup>

          <InputGroup>
            <Input
              label='Telefone/Celular'
              name='phone'
              value={detailsFormik.values.phone}
              onChange={detailsFormik.handleChange}
            />

            <Input
              label='WhatsApp'
              name='whatsapp'
              value={detailsFormik.values.whatsapp}
              onChange={detailsFormik.handleChange}
            />
          </InputGroup>

          <InputGroup layout='1fr 2fr'>
            <Select 
              label='UF'
              name='uf'
              value={detailsFormik.values.uf}
              onChange={(selectedOption) => detailsFormik.setFieldValue('uf', selectedOption)}
            />

            <Select 
              label='Cidade'
              name='city'
              value={detailsFormik.values.city}
              onChange={(selectedOption) => detailsFormik.setFieldValue('city', selectedOption)}
            />
          </InputGroup>

          <Input
            label='Endereço'
            name='address'
            value={detailsFormik.values.address}
            onChange={detailsFormik.handleChange}
          />

          <Button type='submit'>Salvar</Button>
        </form>
      </DetailsModal>
      
      <Modal 
        visible={selectedAffiliate !== null && isToggleStatusModalVisible}
        title="Atenção"
        onRequestClose={() => setIsToggleStatusModalVisible(false)} 
      >
        <p className='text'>
          Deseja realmente {selectedAffiliate?.isActivated ? 'desativar' : 'ativar'} o afiliado <strong>{selectedAffiliate?.name}</strong>?
        </p>

        <div className='buttons'>
          <button onClick={() => setIsToggleStatusModalVisible(false)}>
            Não
          </button>

          <button onClick={handleToggleAffiliateStatus} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <Modal 
        visible={selectedAffiliate !== null && isDeleteModalVisible}
        title="Atenção"
        onRequestClose={() => setIsDeleteModalVisible(false)} 
      >
        <p className='text'>
          Deseja realmente excluir o afiliado <strong>{selectedAffiliate?.name}</strong>? Essa ação irá excluir todos os dados e é irreversível.
        </p>

        <div className='buttons'>
          <button onClick={() => setIsDeleteModalVisible(false)}>
            Não
          </button>

          <button onClick={handleDeleteAffiliate} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <Content>
        <ContentWrapper className="content">
          <Title>Afiliados</Title>
          <Description>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam massa sapien velit ipsum, ac.
          </Description>

          <FiltersContainer>
            <Select
              label="Classificar por"
              options={orderSelectItems}
              value={orderSelectItems.find(option => option.label === selectedOrder)}
              onChange={(option) => {
                setSelectedOrder(option?.label || "Data");
                setCurrentPage(1);
              }}
              isClearable={false}
            />

            <Select
              label="Exibir últimos"
              options={limitSelectItems}
              value={limitSelectItems.find(option => option.label === String(itemsPerPage))}
              onChange={handleChangeLimit}
              isClearable={false}
            />

            <Button 
              variant='tertiary' 
              onClick={() => setIsFilterModalVisible(true)}
            >
              <RiFilterLine />
              Filtrar
            </Button>

            {isFiltering && (
              <Button 
                variant='tertiary' 
                onClick={() => {
                  setIsFiltering(false);
                  filterFormik.resetForm();
                }}
              >
                <RiFilterOffLine />
                Remover filtro
              </Button>
            )}
          </FiltersContainer>

          <ResultsContainer>
            {
              isLoading
              ? <Loading />
              : orderedAffiliates.length > 0
                ? orderedAffiliates
                  .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
                  .map(affiliate => (
                    <ResultCard 
                      key={affiliate.id} 
                      onClick={() => setSelectedAffiliate(affiliate)}
                    >
                      <ItemContainer>
                        <div>
                          <ItemValue>{format(affiliate.date, 'dd/MM/yyyy HH:mm')}</ItemValue>
                          <ItemTitle>Data</ItemTitle>
                        </div>
                      </ItemContainer>

                      <ItemDivider />

                      <ItemContainer>
                        <div>
                          <ItemValue>{affiliate.name}</ItemValue>
                          <ItemTitle>Nome</ItemTitle>
                        </div>
                      </ItemContainer>

                      <ItemDivider />

                      <ItemContainer>
                        <div>
                          <ItemValue>{affiliate.affiliateCount}/{affiliate.goal}</ItemValue>
                          <ItemTitle>Meta</ItemTitle>
                        </div>
                      </ItemContainer>

                      <ItemDivider />

                      <ItemContainer>
                        <div>
                          <ItemValue>{affiliate.tel}</ItemValue>
                          <ItemTitle>Telefone</ItemTitle>
                        </div>
                      </ItemContainer>

                      <ActionsContainer>
                        <Tooltip content="Detalhes">
                          <ActionButton
                            color={theme.colors.helpers.info} 
                            onClick={() => setIsDetailsModalVisible(true)}
                          >
                            <RiEyeLine />
                          </ActionButton>
                        </Tooltip>

                        <Tooltip content={affiliate.isActivated ? 'Desativar' : 'Ativar'}>
                          <ActionButton
                            color={theme.colors.helpers.warning} 
                            onClick={() => setIsToggleStatusModalVisible(true)}
                          >
                            {affiliate.isActivated 
                              ? <FiLock strokeWidth={2.5} /> 
                              : <FiUnlock strokeWidth={2.5} />
                            }
                          </ActionButton>
                        </Tooltip>

                        <Tooltip content="Excluir">
                          <ActionButton 
                            color={theme.colors.helpers.danger} 
                            onClick={() => setIsDeleteModalVisible(true)}
                          >
                            <BiTrashAlt />
                          </ActionButton>
                        </Tooltip>
                      </ActionsContainer>
                    </ResultCard>
                  ))
                : 'Nenhum afiliado foi encontrado' 
            }
          </ResultsContainer>
        </ContentWrapper>

        <Footer>
          <div className="content">
            <Pagination>
              <PageButton
                className="prev"
                onClick={goToPreviousPage}
                disabled={currentPage === 1}
              >
                <ChevronDown />
              </PageButton>

              <Page>{String(currentPage).padStart(2, "0")}</Page>

              <PageButton
                className="next"
                onClick={goToNextPage}
                disabled={currentPage === lastPage}
              >
                <ChevronDown />
              </PageButton>
            </Pagination>
          </div>
        </Footer>
      </Content>
    </>
  );
}