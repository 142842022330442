import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';

import { Loading } from '@components/Loading';
import { Description, Title } from '@components/Header';
import { Input } from '@components/Input';
import ResultCard, { ItemValue, ItemContainer } from '@components/ResultCard';
import { Button } from '@components/Button';

import { useAuth } from '@contexts/auth';

import SpecialtiesService, { ProceduresRequest } from '@services/SpecialtiesService';

import { Search } from '@assets/images';

import { currencyMask } from '@utils/currencyMask';
import { convertBRLToNumber } from '@utils/convertBRLToNumber';
import { formatToBRL } from '@utils/formatToBRL';

import { AxiosAPIError } from '@/types/api';

import { Content, ContentWrapper, FiltersContainer, Footer, LoadingContainer, ResultsContainer } from './styles';

interface Procedure {
  id: number;
  name: string;
  normalValue: string;
  primeValue: string;
  minAge: number | null;
}

export function Procedures() {
  const params = useParams();
  const { selectedAccount } = useAuth();

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const [specialtyName, setSpecialtyName] = useState('');
  const [procedures, setProcedures] = useState<Procedure[]>([]);
  
  const [search, setSearch] = useState('');

  const filteredProcedures = procedures.filter(procedure => procedure.name.toLowerCase().includes(search.toLowerCase()));

  // Setting procedures
  useEffect(() => {
    (async () => {
      try {
        if (!params.id) throw new Error("Specialty ID must be provided");

        setIsLoading(true);

        const { data } = await SpecialtiesService.listProcedures(params.id);

        const parsedProcedures: Procedure[] = data.dados[0].map(procedure => ({
          id: procedure.id,
          name: procedure.nome,
          normalValue: formatToBRL(procedure.valor_normal),
          primeValue: formatToBRL(procedure.valor_prime),
          minAge: procedure.idade_minima
        }));
        
        setSpecialtyName(data.titulo_pagina);
        setProcedures(parsedProcedures);
      } catch (error) {
        toast.error('Não foi possível carregar dados das clínicas!');

        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })()
  }, [params.id]);

  function handleChangeProcedure(procedureID: number, value: Partial<Procedure>) {
    const changeProcedures = [...procedures].map(procedure => {
      if (procedureID !== procedure.id) return procedure;

      return { ...procedure, ...value }
    });

    setProcedures(changeProcedures);
  }

  async function handleSubmit() {
    try {
      if (!selectedAccount) throw new Error("User must be selected");

      setIsSubmitting(true);

      const parsedProcedures: ProceduresRequest[] = [...procedures]
        .filter(procedure => !!convertBRLToNumber(procedure.normalValue) || !!convertBRLToNumber(procedure.primeValue) || procedure.minAge !== null)
        .map(procedure => ({
          procedimento_id: procedure.id,
          valor_normal: convertBRLToNumber(procedure.normalValue),
          valor_prime: convertBRLToNumber(procedure.primeValue),
          idade_minima: procedure.minAge
        }))
      ;

      await SpecialtiesService.updateProcedures(selectedAccount.id, parsedProcedures);

      toast.success("Os procedimentos foram atualizados com sucesso!");
    } catch (err) {
      const error = err as AxiosAPIError;

      console.error(error);

      if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
        error.response.data.dados.forEach(err => toast.error(err));
      } else {
        toast.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
    }
  }


  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  if (!params.id) {
    return (
      <h1>ID da especialidade é obrigatório!</h1>
    );
  }

  return (
    <Content>
      <ContentWrapper className="content">
        <Title>Procedimentos | {specialtyName}</Title>
        <Description>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam massa sapien velit ipsum, ac.</Description>

        <FiltersContainer>
          <Input
            label='Buscar procedimento'
            name='search'
            placeholder='Pesquisar pelo nome'
            iconRight={<Search />}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </FiltersContainer>

        <ResultsContainer>
          {
            filteredProcedures.length > 0
            ? filteredProcedures
              .map(procedure => (
                <ResultCard key={procedure.id}>
                  <ItemContainer>
                    <div>
                      <ItemValue>{procedure.name}</ItemValue>
                    </div>
                  </ItemContainer>

                  <ItemContainer>
                    <div>
                      <Input
                        label="Valor Normal"
                        type="number"
                        mask={currencyMask}
                        name="normalValue"
                        value={convertBRLToNumber(procedure.normalValue) ? procedure.normalValue : undefined}
                        onChange={(e) => {
                          handleChangeProcedure(procedure.id, {
                            normalValue: e.target.value
                          })
                      }}
                      />
                    </div>
                  </ItemContainer>

                  <ItemContainer>
                    <div>
                      <Input 
                        label="Valor Prime"
                        type="number"
                        mask={currencyMask}
                        name="primeValue"
                        value={convertBRLToNumber(procedure.primeValue) ? procedure.primeValue : undefined}
                        onChange={(e) => handleChangeProcedure(procedure.id, {
                          primeValue: e.target.value
                        })}
                      />
                    </div>
                  </ItemContainer>

                  <ItemContainer>
                    <div>
                      <Input 
                        type="number"
                        label="Idade Mínima"
                        name="age"
                        value={procedure.minAge !== null ? procedure.minAge : undefined}
                        onChange={(e) => handleChangeProcedure(procedure.id, {
                          minAge: Number(e.target.value) || null
                        })}
                      />
                    </div>
                  </ItemContainer>
                </ResultCard>
              ))
            : 'Nenhum procedimento foi encontrado' 
          }
        </ResultsContainer>
      </ContentWrapper>

      <Footer>
        <div className="content">
          <Button isLoading={isSubmitting} onClick={handleSubmit}>Atualizar</Button>
        </div>
      </Footer>
    </Content>
  );
}