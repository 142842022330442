import styled from "styled-components";

import { Container as Button } from "@components/Button/styles";

import { convertToRem } from "@utils/convertToRem";

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  padding-top: ${convertToRem(40)};
`;

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 8fr 4fr;
  gap: ${convertToRem(30)};

  margin-top: ${convertToRem(40)};
`;

export const Form = styled.form`
  padding-bottom: ${convertToRem(100)};

  ${Button} {
    margin-top: ${convertToRem(80)};
    margin-left: auto;
  }
`;