import { createGlobalStyle } from 'styled-components';
import chroma from 'chroma-js';

import GraphikRegular from '@assets/fonts/Graphik/GraphikRegular.otf';
import GraphikMedium from '@assets/fonts/Graphik/GraphikMedium.otf';
import GraphikSemiBold from '@assets/fonts/Graphik/GraphikBold.otf';
import GraphikBold from '@assets/fonts/Graphik/GraphikBold.otf';

import { convertToRem } from '@utils/convertToRem';

export default createGlobalStyle`
  @font-face {
    font-family: 'Graphik';
    font-style: normal;
    font-weight: 400;
    src: url(${GraphikRegular});
  }

  @font-face {
    font-family: "Graphik";
    font-style: normal;
    font-weight: 500;
    src: url(${GraphikMedium});
  }

  @font-face {
    font-family: "Graphik";
    font-style: normal;
    font-weight: 600;
    src: url(${GraphikSemiBold});
  }

  @font-face {
    font-family: "Graphik";
    font-style: normal;
    font-weight: 700;
    src: url(${GraphikBold});
  }

  * {
    margin: 0;
    padding: 0;

    box-sizing: border-box;

    outline: 0;
  }

  html {
    height: 100%;
  }

  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale;
    
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: ${convertToRem(8)};
      
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.4).css()};
      
      border-radius: ${convertToRem(8)};
    }
  }

  body, #root {
    position: relative;
    min-height: 100vh;
  }
  
  body, input, button {
    font-family: "Graphik";
  }

  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }
  
  a {
    display: inline-block;
    background: none;
    
    border: 0;
    
    cursor: pointer;
    
    text-decoration: none;
  }

  button {
    cursor: pointer;
  }

  ul {
    padding: 0;
    text-align: left;
    
    list-style: none;
  }

  .content {
    width: 100%;
    height: 100%;
    
    max-width: 1170px;

    margin: 0 auto;
    padding-left: ${convertToRem(15)};
    padding-right: ${convertToRem(15)};
  }

  .hide-scroll {
    overflow: hidden !important;
  }
`