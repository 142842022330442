import { useLayoutEffect, useState } from "react";

export function useWindowSize() {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);

  useLayoutEffect(() => {
    function getUpdatedSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }

    window.addEventListener('resize', getUpdatedSize);

    // Update window size with initial values
    getUpdatedSize();

    return () => window.removeEventListener('resize', getUpdatedSize);
  }, []);

  return size;
}