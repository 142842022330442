import { Navigate, Route, Routes } from "react-router-dom";

// Pages
import { ListPatients } from "./ListPatients";
import { AddPatient } from "./AddPatient";

export function Patients() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="listagem" />} />
      <Route path="listagem/" element={<ListPatients />} />
      <Route path="adicionar/" element={<AddPatient />} />
    </Routes>
  );
}