import { Navigate, Route, Routes } from "react-router-dom";

// Pages
import { ListDependents } from "./ListDependents";
import { AddDependent } from "./AddDependent";
import { EditDependent } from "./EditDependent";

export function Dependents() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="listar" />} />
      <Route path="listar/" element={<ListDependents />} />
      <Route path="adicionar/" element={<AddDependent />} />
      <Route path="editar/:id/" element={<EditDependent />} />
    </Routes>
  );
}