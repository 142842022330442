import { MouseEvent, useEffect, useRef, useState } from "react";
import { Link, Navigate, Route, Routes, useNavigate } from "react-router-dom";
import { RiCloseLine, RiMenuLine } from "react-icons/ri";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import axios from "axios";

import { useAuth } from "@contexts/auth";

import { Input, InputGroup } from "@components/Input";
import { Button } from "@components/Button";

import { Dashboard } from "@pages/Affiliate/Dashboard";
import { Leads } from "@pages/Affiliate/Leads";
import { Payments } from "@pages/Affiliate/Payments";
import { MyData } from "@pages/Affiliate/MyData";

import AffiliatesService from "@services/AffiliatesService";
import SessionsService from "@services/SessionsService";

import { formatToBRL } from "@utils/formatToBRL";

import { AgendaConsulta } from "@assets/images";
import profilePic from '@assets/images/profile-pic.png';

import { AxiosAPIError } from "@/types/api";

import { Account, AccountDropdown, AccountMenu, Container, MenuDropdown, Header, Name, Photo, ChangePasswordModal } from "./AffiliateRoutes.styles";

interface DropdownMenuProps {
  title: string;
  path?: string;
  subnav?: Array<{
    title: string;
    path: string;
  }>;
  onClick?: () => any;
}

function DropdownMenu(props: DropdownMenuProps) {
  const menuRef = useRef<HTMLDivElement | null>(null);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    function handleClickOutside(event: globalThis.MouseEvent) {
      if (menuRef.current?.contains(event.target as Node)) {
        setIsActive((prevState) => !prevState);
        return;
      }

      setIsActive(false);
    }

    if (!!props.subnav) {
      document.addEventListener('click', handleClickOutside);

      return () => document.removeEventListener('click', handleClickOutside);
    }

  }, [props.subnav]);

  return (
    <MenuDropdown
      ref={menuRef}
      className={isActive ? 'active' : ''}
      onClick={() => !props.subnav && props.onClick ? props.onClick() : null}
    >
      <span>
        {props.path 
          ? <Link to={props.path}>{props.title}</Link>
          : props.title
        }

        {!!props.subnav 
          ? isActive ? <FiChevronUp /> : <FiChevronDown />
          : null
        }
      </span>

      {!!props.subnav && (
        <ul>
          {props.subnav.map(sub => (
            <li 
              key={sub.path}
              onClick={() => props.subnav && props.onClick ? props.onClick() : null}
            >
              <Link to={sub.path}>{sub.title}</Link>
            </li>
          ))}
        </ul>
      )}
    </MenuDropdown>
  );
}

interface ChangePasswordFormik {
  login: string;
  password: string;
  newPassword: string;
  confirmNewPassword: string;
  code: string;
}

let balanceInterval: NodeJS.Timer;

export function AffiliateRoutes() {
  const { user, selectedAccount, selectedProfile, handleSignOut } = useAuth();
  const navigate = useNavigate();

  const [balance, setBalance] = useState(0);

  const accountButtonRef = useRef<HTMLButtonElement | null>(null);
  const [isAccountDropdownActive, setIsAccountDropdownActive] = useState(false);
  
  const [isMenuMobileOpened, setIsMenuMobileOpened] = useState(false);
  
  const [isChangePasswordModalVisible, setIsChangePasswordModalVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const changePasswordFormik = useFormik<ChangePasswordFormik>({
    initialValues: {
      login: user?.login || '',
      code: user?.activationCode || '',
      password: '',
      newPassword: '',
      confirmNewPassword: '',
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .min(8, "Insira uma senha com no mínimo 8 caracteres")
        .required("O campo é obrigatório"),
      newPassword: Yup.string()
        .min(8, "Insira uma senha com no mínimo 8 caracteres")
        .required("O campo é obrigatório"),
      confirmNewPassword: Yup.string()
        .oneOf([Yup.ref("newPassword"), null], "As senhas não coincidem")
        .required("O campo é obrigatório"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        if (isSubmitting) return;
  
        setIsSubmitting(true);

        if (!values.login) throw new Error('CPF/CNPJ do usuário não informado');
        if (!values.code) throw new Error('Código de validação do usuário não informado');

        await SessionsService.resetPassword({
          login: values.login,
          codigo: values.code,
          senha: values.password,
          nova_senha: values.newPassword,
          nova_senha_confirmar: values.confirmNewPassword
        });

        toast.success('Senha alterada com sucesso!');
        resetForm();
        
        setIsChangePasswordModalVisible(false);
      } catch(err) {
        const error = err as AxiosAPIError;

        console.error(error);

        if (axios.isAxiosError(error) && error.response && error.response) {
          toast.error(error.response.data.menssagem);
        } else {
          toast.error(error.message);
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  async function getBalance() {
    try {
      const { data } = await AffiliatesService.listAffiliateBalance();

      setBalance(data.saldo);
    } catch(err) {
      const error = err as AxiosAPIError;

      console.error(error);

      if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
        error.response.data.dados.forEach(err => toast.error(err));
      } else {
        toast.error(error.message);
      }
    }
  }

  useEffect(() => {
    getBalance();
    
    // Refetching every 30 seconds
    balanceInterval = setInterval(getBalance, 30 * 1000);

    return () => clearInterval(balanceInterval);
  }, []);

  function handleClickOutside(e: MouseEvent<HTMLDivElement>) {
    if (window.innerWidth <= 570) return;
    
    if (accountButtonRef.current?.contains(e.target as any)) {
      setIsAccountDropdownActive((prevState) => !prevState);
      return;
    } 

    setIsAccountDropdownActive(false);
  }

  if (!user) {
    navigate('/login');
    return <div />;
  }

  if (!selectedProfile) {
    navigate('/selecionar-perfil');
    return <div />;
  }

  if (selectedProfile.hasMoreThanOneAccount && !selectedAccount) {
    navigate('/selecionar-conta');
    return <div />;
  }

  return (
    <>
      <ChangePasswordModal
        visible={isChangePasswordModalVisible}
        title="Alterar senha"
        onRequestClose={() => setIsChangePasswordModalVisible(false)}
      >
        <form onSubmit={changePasswordFormik.handleSubmit}>
          <Input 
            label="Senha atual"
            type="password"
            name="password"
            onChange={changePasswordFormik.handleChange}
            error={(changePasswordFormik.touched.password || undefined) && changePasswordFormik.errors.password}
          />

          <InputGroup>
            <Input 
              label="Nova Senha"
              type="password"
              name="newPassword"
              onChange={changePasswordFormik.handleChange}
              error={(changePasswordFormik.touched.newPassword || undefined) && changePasswordFormik.errors.newPassword}
            />

            <Input 
              label="Confirmar Nova Senha"
              type="password"
              name="confirmNewPassword"
              onChange={changePasswordFormik.handleChange}
              error={(changePasswordFormik.touched.confirmNewPassword || undefined) && changePasswordFormik.errors.confirmNewPassword}
            />
          </InputGroup>

          <Button 
            type="submit"
            isLoading={isSubmitting}
          >
            Alterar
          </Button>
        </form>
      </ChangePasswordModal>

      <Container onClick={handleClickOutside}>
        <Header>
          <div className="content">
            <Link 
              to="/" 
              className="logo" 
              onClick={() => setIsMenuMobileOpened(false)}
            >
              <AgendaConsulta />
            </Link>

            <nav>
              <ul>
                <li>
                  <DropdownMenu title="Dashboard" path="/afiliado/dashboard" />
                </li>

                <li>
                  <DropdownMenu 
                    title="Leads"
                    subnav={[
                      { path: '/afiliado/leads/cadastro', title: 'Cadastro' },
                      { path: '/afiliado/leads/historico', title: 'Histórico' },
                    ]}
                  />
                </li>

                <li>
                  <DropdownMenu 
                    title="Pagamentos"
                    subnav={[
                      { path: '/afiliado/pagamentos/historico', title: 'Histórico' },
                    ]}
                  />
                </li>
              </ul>
            </nav>
            
            <span className="balance">
              Saldo: <strong>{formatToBRL(balance)}</strong>
            </span>

            <AccountDropdown>
              <Account ref={accountButtonRef}>
                <Photo>
                  <img src={user.avatar || profilePic} alt={user.name} />
                </Photo>

                <Name>{user.name}</Name>
                
                {isAccountDropdownActive ? <FiChevronUp /> : <FiChevronDown />}
              </Account>

              <AccountMenu className={isAccountDropdownActive ? 'active' : ''}>
                {(user.hasMoreThanOneProfile || selectedProfile.hasMoreThanOneAccount) && (
                  <li className="change-account">
                    <Link to={selectedProfile.hasMoreThanOneAccount ? "/selecionar-conta" : "/selecionar-perfil"}>
                      Trocar conta
                    </Link>
                  </li>
                )}

                <li>
                  <Link to='/afiliado/dados-cadastrais'>
                    Editar cadastro
                  </Link>
                </li>

                <li>
                  <button 
                    type="button"
                    onClick={() => setIsChangePasswordModalVisible(true)}
                  >
                    Alterar senha
                  </button>
                </li>

                <li className='sign-out'>
                  <button 
                    type="button"
                    onClick={() => handleSignOut()}
                  >
                    Sair
                  </button>
                </li>
              </AccountMenu>
            </AccountDropdown>

            <button 
              type="button"
              className="menu-btn" 
              onClick={() => setIsMenuMobileOpened((prevState) => !prevState)}
            >
              {isMenuMobileOpened
                ? <RiCloseLine />
                : <RiMenuLine className="menu" />
              }
            </button>
            
            <nav className={`menu-mobile ${isMenuMobileOpened ? 'active' : ''}`}>
              <ul>
                <li>
                  <DropdownMenu 
                    title="Dashboard"
                    path="/afiliado/dashboard"
                    onClick={() => setIsMenuMobileOpened(false)}
                  />
                </li>

                <li>
                  <DropdownMenu 
                    title="Leads"
                    subnav={[
                      { path: '/afiliado/leads/cadastro', title: 'Cadastro' },
                      { path: '/afiliado/leads/historico', title: 'Histórico' },
                    ]}
                    onClick={() => setIsMenuMobileOpened(false)}
                  />
                </li>

                <li>
                  <DropdownMenu 
                    title="Pagamentos"
                    subnav={[
                      { path: '/afiliado/pagamentos/historico', title: 'Histórico' },
                    ]}
                    onClick={() => setIsMenuMobileOpened(false)}
                  />
                </li>
              </ul>

              <footer>
                {(user.hasMoreThanOneProfile || selectedProfile.hasMoreThanOneAccount) && (
                  <Link 
                    to={selectedProfile.hasMoreThanOneAccount ? "/selecionar-conta" : "/selecionar-perfil"} 
                    onClick={() => setIsMenuMobileOpened(false)}
                  >
                    Trocar conta
                  </Link>
                )}

                <Link 
                  to='/afiliado/dados-cadastrais'
                  onClick={() => setIsMenuMobileOpened(false)}
                >
                  Editar cadastro
                </Link>
                
                <button 
                  type="button"
                  onClick={() => {
                    setIsMenuMobileOpened(false);
                    setIsChangePasswordModalVisible(true);
                  }}
                >
                  Alterar senha
                </button>

                <button 
                  type="button"
                  className="logout"
                  onClick={() => {
                    setIsMenuMobileOpened(false);
                    handleSignOut();
                  }}
                >
                  Sair
                </button>
              </footer>
            </nav>
          </div>
        </Header>

        <Routes>
          <Route path="/" element={<Navigate to="/afiliado/dashboard" />} />
          <Route path="/afiliado/dashboard" element={<Dashboard />} />
          <Route path="/afiliado/dados-cadastrais" element={<MyData />} />
          <Route path="/afiliado/leads/*" element={<Leads />} />
          <Route path="/afiliado/pagamentos/*" element={<Payments />} />
        </Routes>
      </Container>
    </>
  );
}
