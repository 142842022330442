import { Route, Routes } from "react-router-dom";

import { useAuth } from "@contexts/auth";

import { ProtectedRoute } from "./ProtectedRoute";

import { Sidebar } from "@components/Sidebar";
import { Header } from "@components/Header";

import { Dashboard } from "@pages/Clinic/Dashboard";
import { CRM } from "@pages/Clinic/CRM";
import { Schedules } from "@pages/Clinic/Schedules";
import { DigitalSchedule } from "@pages/Clinic/DigitalSchedule";
import { Leads } from "@pages/Clinic/Leads";
import { Profile } from "@pages/Clinic/Profile";
import { Professionals } from "@pages/Clinic/Professionals";
import { Exams } from "@pages/Clinic/Exams";
import { Reports } from "@pages/Clinic/Reports";
import { Settings } from "@pages/Clinic/Settings";
import { Plan } from "@pages/Clinic/Plan";
import { Procedures } from "@pages/Clinic/Procedures";
import { ScheduleConfirmation } from "@/pages/Clinic/ScheduleConfirmation";

import { Container, Main } from "./styles";

export function ClinicRoutes() {
  const { selectedAccount } = useAuth();

  return (
    <Container>
      <Sidebar />

      <Main>
        <Header />

        <ProtectedRoute isAllowed={selectedAccount !== null} redirectPath="/selecionar-conta">
          <Routes>
            <Route path="/" element={<Dashboard />} />
            <Route path="/crm" element={<CRM />} />
            <Route path="agendamentos/" element={<Schedules />} />
            <Route path="perfil/*" element={<Profile />} />
            <Route path="profissionais/*" element={<Professionals />} />
            <Route path="exames/*" element={<Exams />} />
            <Route path="relatorios/*" element={<Reports />} />
            <Route path="configuracoes/*" element={<Settings />} />
            <Route path="planos/*" element={<Plan />} />
            <Route path="agenda-digital/" element={<DigitalSchedule />} />
            <Route path="leads/" element={<Leads />} />
            <Route path="confirmacao-de-agenda/" element={<ScheduleConfirmation />} />

            <Route path="procedimentos/:id/" element={<Procedures />} />
          </Routes>
        </ProtectedRoute>
      </Main>
    </Container>
  );
}
