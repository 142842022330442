import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { addDays, format, subYears } from "date-fns";
import * as Yup from "yup";

import { useAuth } from "@contexts/auth";

import { Description, Title } from "@components/Header";
import { Input, InputGroup } from "@components/Input";
import { DatePicker } from "@components/DatePicker";
import { Callout } from "@components/Callout";
import { Button } from "@components/Button";

import { Content, ContentWrapper, Form, Main, PrimePromo } from "./styles";

export function AddDependent() {
  const { selectedAccount } = useAuth();

  const navigate = useNavigate();

  const minDate = format(subYears(addDays(new Date(), 1), 18), 'yyyy-MM-dd');

  const formik = useFormik({
    initialValues: {
      name: "",
      birthDate: null,
      cpf: "",
      rg: "",
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .matches(
          /^[a-zA-Z]{2,}(?: [a-zA-Z]+){1,}$/g,
          "Insira seu nome completo"
        )
        .required("O campo é obrigatório"),
      cpf: Yup.string()
        .matches(
          /^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/,
          "Insira um CPF válido"
        )
        .required("O campo é obrigatório"),
      rg: Yup.string()
        .matches(
          /^[0-9]{2,3}\.?[0-9]{2,3}\.?[0-9]{3}-?[A-Za-z0-9]{1}$/,
          "Insira um RG válido"
        )
        .required("O campo é obrigatório"),
      birthDate: Yup.date()
        .min(minDate, 'O dependente deve ser menor de 18 anos')
        .required("O campo é obrigatório")
        .typeError("O campo é obrigatório")
    }),
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <Content>
      <ContentWrapper className="content">
        <Title>Cadastrar novo dependente</Title>
        <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc, sed congue urna varius in condimentum.
        </Description>

        {selectedAccount?.isPrime && (
          <PrimePromo>
            <div>
              <p>Adicionar dependentes a partir de 18 anos está disponível para assinantes PRIME Familia</p>

              <Button onClick={() => navigate('/planos/')}>Adquira já</Button>
            </div>
          </PrimePromo>
        )}

        <Main isPrime={!!selectedAccount?.isPrime}>
          <Form onSubmit={formik.handleSubmit}>
            <InputGroup layout="6fr 2fr">
              <Input
                label="Nome completo"
                name="name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={(formik.touched.name || undefined) && formik.errors.name}
              />

              <DatePicker
                label="Nascimento"
                name="birthDate"
                showYearDropdown
                maxDate={new Date()}
                selected={formik.values.birthDate}
                onChange={(e) => formik.setFieldValue("birthDate", e)}
                onBlur={formik.handleBlur}
                error={
                  (formik.touched.birthDate || undefined) &&
                  formik.errors.birthDate
                }
              />
            </InputGroup>

            <InputGroup>
              <Input
                label="CPF"
                name="cpf"
                mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]}
                value={formik.values.cpf}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={(formik.touched.cpf || undefined) && formik.errors.cpf}
              />

              <Input
                label="RG"
                name="rg"
                value={formik.values.rg}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={(formik.touched.rg || undefined) && formik.errors.rg}
              />
            </InputGroup>

            <Callout>
              Dependentes devem possuir no <strong>máximo 17 anos</strong>.
            </Callout>

            <Button type="submit">Cadastrar</Button>
          </Form>
        </Main>
      </ContentWrapper>
    </Content>
  );
}
