import { faker } from '@faker-js/faker';
import { addMonths, addYears } from 'date-fns';

import { delay } from '@utils/delay';

export interface Card {
  id: string;
  name: string;
  number: number;
  monthly: number;
  due: Date;
  activatedAt: Date;
  isDependent: boolean;
  isActivated: boolean;
};

faker.setLocale('pt_BR');
class CardsService {
  private cards: Card[];

  constructor() {
    this.cards = [];

    for (let i = 0; i < 20; i++) {
      const { name, datatype } = faker;

      const randomGender = datatype.number({ min: 0, max: 1, precision: 1 });

      this.cards.push({
        id: datatype.uuid(),
        name: `${name.firstName(randomGender)} ${name.lastName(randomGender)}`,
        monthly: datatype.number({ min: 50, max: 150, precision: 1 }),
        due: datatype.datetime({
          min: addMonths(new Date(), 3).getTime(),
          max: addYears(new Date(), 5).getTime(),
        }),
        activatedAt: datatype.datetime({ max: new Date().getTime() }),
        number: datatype.number({ min: 10000000000, max: 99999999999, precision: 1 }),
        isDependent: i > 0,
        isActivated: datatype.boolean()
      });
    }
  }

  async listCards(): Promise<Card[]> {
    await delay();

    return new Promise((resolve) => {
      resolve(this.cards);
    });
  }
}

export default new CardsService();