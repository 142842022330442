import styled from "styled-components";
import chroma from "chroma-js";

import { Modal } from "@components/Modal";
import { Container as Button } from "@components/Button/styles";
import { InputGroup } from "@components/Input/styles";

import { convertToRem } from "@utils/convertToRem";

export const ChangePasswordModal = styled(Modal)`
  form {
    width: 100%;

    > ${Button} {
      margin-top: ${convertToRem(48)};
      margin-left: auto;
    }
  }

  @media (max-width: 500px) {
    ${InputGroup} {
      grid-template-columns: 1fr;
    }
  }
`;

export const Container = styled.div`
  position: relative;

  width: 100vw;
  min-height: 100vh;

  background-color: ${({ theme }) => chroma(theme.colors.neutral.border).alpha(0.3).css()};

  padding-bottom: ${convertToRem(40)};

  overflow-x: hidden;

  .content {
    padding: 0 ${convertToRem(15)};
  }

  .balance {
    flex-shrink: 0;

    margin-left: auto;
    padding: ${convertToRem(8)} ${convertToRem(12)};

    background-color: #feeded;
    color: ${({ theme }) => theme.colors.primary.base};

    font-weight: 400;
    font-size: ${convertToRem(14)};

    border-radius: ${convertToRem(6)};

    strong {
      font-weight: 500;
    }
  }
`;

export const MenuDropdown = styled.div`
  position: relative;

  cursor: pointer;

  span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  
    color: ${({ theme }) => theme.colors.primary.base};

    > svg {
      margin-left: ${convertToRem(4)};
    }
  }

  > ul {
    position: absolute;
    top: calc(100% + ${convertToRem(12)});
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: fit-content;
    min-width: ${convertToRem(120)};

    background-color: ${({ theme }) => theme.colors.neutral.white};

    border-radius: ${convertToRem(6)};
    box-shadow: 0px ${convertToRem(2)} ${convertToRem(4)} rgba(0, 0, 0, 0.05);

    opacity: 0;
    transform: translateY(-20px);
    pointer-events: none;

    transition: all ease 0.3s;

    overflow: hidden;
      
    li {
      width: 100%;
    }

    a {
      width: 100%;

      padding: ${convertToRem(16)} ${convertToRem(12)};

      font-size: ${convertToRem(14)};

      &:hover {
        background-color: ${({ theme }) => theme.colors.neutral.borderLight};
      }
    }
  }

  &.active {
    > ul {
      opacity: 1;
      transform: translateY(0);
      pointer-events: initial;
    }
  }
`;

export const AccountDropdown = styled.div`
  position: relative;
`;

export const Header = styled.header`
  width: 100vw;
  height: ${convertToRem(60)};

  margin-bottom: ${convertToRem(40)};

  background-color: ${({ theme }) => theme.colors.primary.lightest};

  .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .logo svg {
    width: auto;
    height: ${convertToRem(30)};
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 100%;

    margin-left: ${convertToRem(48)};

    > ul {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      font-weight: 500;
      font-size: ${convertToRem(15)};

      > li {
        &:not(:first-child) {
          margin-left: ${convertToRem(24)};
        }
      }

      a {
        color: ${({ theme }) => theme.colors.primary.base};
      }
    }
  }

  .menu-btn, .menu-mobile {
    display: none;
  }

  @media (max-width: 768px) {
    nav, ${AccountDropdown} {
      display: none;
    }

    .menu-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      
      background: none;
      color: ${({ theme }) => theme.colors.primary.base};

      margin-left: ${convertToRem(24)};

      border: 0;

      svg {
        width: auto;
        height: ${convertToRem(24)};
      }
    }

    .menu-mobile {
      position: absolute;
      top: ${convertToRem(60)};
      left: 0;
      right: 0;
      bottom: 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      margin-left: 0;
      padding: ${convertToRem(24)};

      background-color: ${({ theme }) => theme.colors.primary.lightest};
      color: ${({ theme }) => theme.colors.primary.base};

      z-index: 999;

      transform: translateX(100%);
      transition: all 0.5s ease;

      &.active {
        transform: translateX(0);
      }

      > ul {
        flex-direction: column; 
        gap: ${convertToRem(16)};

        font-size: ${convertToRem(18)};

        > li {
          width: 100%;

          margin-left: 0 !important;
        }

        ${MenuDropdown} {
          > ul {
            position: initial;
            top: initial;
            
            display: none;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            width: 100%;
                    
            margin-top: ${convertToRem(8)};

            a {
              color: ${({ theme }) => theme.colors.neutral.textDark};
              
              font-weight: 400;
              font-size: ${convertToRem(14)};
            }
          }

          &.active > ul {
            display: flex;
          }
        }
      }

      footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        padding-top: ${convertToRem(40)};

        a, button {
          color: ${({ theme }) => theme.colors.neutral.textDark};
          margin-top: ${convertToRem(16)};
    
          background: none;
      
          font-weight: 500;
          font-size: ${convertToRem(14)};
      
          border: 0;

          &.logout {
            color: ${({ theme }) => theme.colors.primary.base};
          }
        }
      }
    }
  }
`;

export const Account = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: max-content;

  margin-left: ${convertToRem(24)};
  padding: ${convertToRem(10)} 0;

  background-color: pink;
  background-color: transparent;

  border: 0;

  > svg {
    width: auto;
    height: ${convertToRem(14)};

    margin-top: ${convertToRem(-2)};
    margin-left: ${convertToRem(4)};

    color: ${({ theme }) => theme.colors.neutral.text};
  }
`;

export const Photo = styled.div`
  width: ${convertToRem(34)};
  height: ${convertToRem(34)};

  outline: solid ${convertToRem(1.5)} ${({ theme }) => theme.colors.neutral.border};
  border-radius: 50%;

  overflow: hidden;

  img {
    width: 100%;
    height: 100%;

    object-fit: cover;

    overflow: hidden;
  }
`;

export const Name = styled.span`
  margin-left: ${convertToRem(10)}; 

  color: ${({ theme }) => theme.colors.neutral.text};

  font-size: ${convertToRem(14)};
`;

export const AccountMenu = styled.ul`
  position: absolute;
  top: ${convertToRem(60)};
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  min-width: ${convertToRem(180)};
  padding: ${convertToRem(20)};

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.text};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.borderDark};
  border-radius: ${convertToRem(6)};

  box-shadow: 0 ${convertToRem(8)} ${convertToRem(20)} ${({ theme }) => chroma(theme.colors.neutral.black).alpha(0.06).css()};

  z-index: 99;

  transform: translateY(-10%);
  pointer-events: none;
  opacity: 0;

  transition: all ease 0.4s;

  li {
    color: ${({ theme }) => theme.colors.neutral.text};

    font-weight: 500;
    font-size: ${convertToRem(13)};
    line-height: 1;

    button, a {      
      font-weight: 500;
      font-size: ${convertToRem(13)};
      line-height: 1;
      
      background-color: transparent;
      color: inherit;

      border: 0;

      transition: color ease 0.4s;

      &:hover {
        color: ${({ theme }) => theme.colors.neutral.textDark};
      }
    }
    
    &.change-account {
      width: 100%;

      padding-bottom: ${convertToRem(16)} !important;

      border-bottom: solid 1px ${({ theme }) => theme.colors.neutral.borderDark};
    }

    &.sign-out {
      width: 100%;

      margin-top: ${convertToRem(16)} !important;
      padding-top: ${convertToRem(16)} !important;

      border-top: solid 1px ${({ theme }) => theme.colors.neutral.borderDark};

      button {
        color: ${({ theme }) => theme.colors.primary.highlight};
  
        &:hover {
          color: ${({ theme }) => theme.colors.primary.base};
        }
      }
    }

    &:not(:first-of-type) {
      margin-top: ${convertToRem(16)};
    }
  }

  &.active {
    transform: translateY(0);
    pointer-events: initial;
    opacity: 1;
  }
`;