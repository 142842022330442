import { Route, Routes } from "react-router-dom";

import { TabsProps } from "./Tabs";

import { TabContainer, TabContentContainer, TabTitle, TabTitleContainer } from "./styles";

export { TabsContainer } from './styles';

export function Tabs({ tabs }: TabsProps) {
  return (
    <TabContainer>
      <TabTitleContainer>
        <div>
          {
            tabs
            .filter(tab => tab.path !== "/")
            .map(tab => (
              <TabTitle
                key={tab.path}
                to={tab.path}
                onClick={e => tab.isDisabled && e.preventDefault()}
                disabled={tab.isDisabled}
              >
                {tab.title}
              </TabTitle>
            ))
          }
        </div>
      </TabTitleContainer>

      <TabContentContainer>
        <Routes>
          {tabs.map(tab => <Route key={tab.path} path={tab.path} element={tab.element} />)}
        </Routes>
      </TabContentContainer>
    </TabContainer>
  );
}