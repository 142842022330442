import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import { useAuth } from "@contexts/auth";
import { useEmployee } from "@contexts/employee";

import { Loading } from "@components/Loading";
import { Input, InputGroup } from "@components/Input";
import { Button } from "@components/Button";
import { Select } from "@components/Select";

import UsersService from "@services/UsersService";
import SpecialtiesService from "@services/SpecialtiesService";

import { genderOptions } from "@constants/genders";
import { clinicTypeOptions } from "@constants/clinic-type";

import { FormikEffect } from "@utils/FormikEffect";

import { OptionTypeBase } from "@components/Select/Select";

import { FormContainer } from "./styles";
import { DatePicker } from "@/components/DatePicker";

const positionOptions: OptionTypeBase[] = [
  { label: 'Vendedor', value: 'Vendedor' },
  { label: 'Gestor da Clínica', value: 'Gestor da Clínica' },
  { label: 'Clínica', value: 'Clínica' },
  { label: 'Promotor', value: 'Promotor' },
  { label: 'Dentista', value: 'Dentista' },
  { label: 'Recepcionista', value: 'Recepcionista' },
  { label: 'Financeiro', value: 'Financeiro' },
  { label: 'Auxiliar de Saúde Bucal', value: 'Auxiliar de Saúde Bucal' },
];

interface InitialValues {
  name: string;
  email: string;
  position: OptionTypeBase | null;
  birthDate: Date | null;
  avatar: File | null;
  rg: string;
  cpf: string;
  ctps: string;
  phone: string;
  whatsApp: string;
  paymentDate: Date | null;
  admissionDate: Date | null;
  demissionDate: Date | null;
};

export function PersonalDataTab() {
  const { selectedAccount } = useAuth();
  const { employee, addEmployeeInfo } = useEmployee();

  const navigate = useNavigate();
  const { uuid } = useParams();

  const [isLoading, setIsLoading] = useState(true);
  const [specialtyOptions, setSpecialtyOptions] = useState<OptionTypeBase[]>([]);

  const formik = useFormik<InitialValues>({
    enableReinitialize: true,
    initialValues: {
      name: employee.name,
      email: employee.email,
      position: employee.position,
      birthDate: employee.birthDate,
      avatar: employee.avatar,
      rg: employee.rg,
      cpf: employee.cpf,
      ctps: employee.ctps,
      phone: employee.phone,
      whatsApp: employee.whatsApp,
      paymentDate: employee.paymentDate,
      admissionDate: employee.admissionDate,
      demissionDate: employee.demissionDate,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .matches(/(\w.+\s).+/i, "Insira seu nome completo")
        .required("O campo é obrigatório"),
      email: Yup.string()
        .email("Insira um e-mail válido")
        .required("O campo é obrigatório"),
      position: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um cargo"),
      birthDate: Yup.date()
        .required("O campo é obrigatório")
        .typeError("Insira uma data de nascimento válida"),
      cpf: Yup.string()
        .matches(/^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/, "Insira um CPF válido")
        .required("O campo é obrigatório"), 
      rg: Yup.string()
        .matches(/^[0-9]{2,3}\.?[0-9]{2,3}\.?[0-9]{3}-?[A-Za-z0-9]{1}$/, "Insira um RG válido")
        .required("O campo é obrigatório"),
      ctps: Yup.string()
        .required("O campo é obrigatório"),
      phone: Yup.string()
        .matches(/\(\d{2}\) \d{5}-\d{4}/g, "Insira um telefone válido")
        .required("O campo é obrigatório"),
      whatsApp: Yup.string()
        .matches(/\(\d{2}\) \d{5}-\d{4}/g, "Insira um WhatsApp válido")
        .required("O campo é obrigatório"),
      paymentDate: Yup.date()
        .required("O campo é obrigatório")
        .typeError("Insira uma data de pagamento válida"),
      demissionDate: Yup.date()
        .required("O campo é obrigatório")
        .typeError("Insira uma data de demissão válida"),
      admissionDate: Yup.date()
        .required("O campo é obrigatório")
        .typeError("Insira uma data de admissão válida"),
    }),
    onSubmit: (values) => {
      addEmployeeInfo({
        name: values.name,
        email: values.email,
        position: values.position,
        birthDate: values.birthDate,
        avatar: values.avatar,
        rg: values.rg,
        cpf: values.cpf,
        ctps: values.ctps,
        phone: values.phone,
        whatsApp: values.whatsApp,
        paymentDate: values.paymentDate,
        admissionDate: values.admissionDate,
        demissionDate: values.demissionDate,
      })

      navigate(`/configuracoes/colaboradores/${uuid !== undefined ? `editar/${uuid}` : 'adicionar'}/dados-bancarios`);
    },
  });

  useEffect(() => {
    (async () => {
      try {
        if (!selectedAccount) throw new Error("User must be selected");

        setIsLoading(true);

        const { data: { clinica } } = await UsersService.listData(selectedAccount.uuid);

        const type = clinicTypeOptions.find(type => type.label === clinica.dados.tipo);
        if (!type) throw new Error("Clinic type doesn't exists");

        const { data: { dados } } = await SpecialtiesService.listSpecialties(type.value);
        const [specialties] = dados;

        const parsedSpecialties: OptionTypeBase[] = specialties.map(
          (specialty) => ({
            value: String(specialty.id),
            label: specialty.nome,
          })
        );

        setSpecialtyOptions(parsedSpecialties);
      } catch (error) {
        setSpecialtyOptions([]);

        toast.error("Não foi possível carregar as especialidades");

        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <Loading />

  return (
    <FormContainer onSubmit={formik.handleSubmit}>
      <FormikEffect formik={formik} />

      <Input 
        label="Nome Completo" 
        name="name" 
        value={formik.values.name}
        onChange={formik.handleChange}
        error={(formik.touched.name || undefined) && formik.errors.name}
      />

      <InputGroup>
        <Input 
          label="E-mail"
          type="email" 
          name="email" 
          value={formik.values.email}
          onChange={formik.handleChange}
          error={(formik.touched.email || undefined) && formik.errors.email}
        />

        <Select 
          label="Cargo"
          options={positionOptions}
          name="position"
          value={formik.values.position}
          onChange={option => formik.setFieldValue('position', option)}
          error={(formik.touched.position || undefined) && formik.errors.position}
        />
      </InputGroup>

      <InputGroup>
        <DatePicker 
          label="Data de Nascimento"
          name="birthDate"
          selected={formik.values.birthDate}
          onChange={selectedDate => formik.setFieldValue('birthDate', selectedDate)}
          maxDate={new Date()}
          showYearDropdown
          error={(formik.touched.birthDate || undefined) && formik.errors.birthDate}
        />

        <Input 
          label="Avatar"
          type="file" 
          name="avatar" 
          // value={formik.values.avatar}
          onChange={formik.handleChange}
          error={(formik.touched.avatar || undefined) && formik.errors.avatar}
        />
      </InputGroup>

      <InputGroup>
        <Input 
          label="CPF"
          type="tel"
          name="cpf" 
          mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]} 
          value={formik.values.cpf}
          onChange={formik.handleChange}
          error={(formik.touched.cpf || undefined) && formik.errors.cpf}
        />

        <Input 
          label="RG"
          type="tel" 
          name="rg" 
          value={formik.values.rg}
          onChange={formik.handleChange}
          error={(formik.touched.rg || undefined) && formik.errors.rg}
        />

        <Input 
          label="Carteira de Trabalho"
          type="tel" 
          name="ctps" 
          value={formik.values.ctps}
          onChange={formik.handleChange}
          error={(formik.touched.ctps || undefined) && formik.errors.ctps}
        />
      </InputGroup>

      <InputGroup>
        <Input 
          label="Celular"
          type="tel"
          name="phone"
          mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} 
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={(formik.touched.phone || undefined) && formik.errors.phone}
        />

        <Input 
          label="WhatsApp"
          type="tel"
          name="whatsApp"
          mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]} 
          value={formik.values.whatsApp}
          onChange={formik.handleChange}
          error={(formik.touched.whatsApp || undefined) && formik.errors.whatsApp}
        />
      </InputGroup>

      <InputGroup>
        <DatePicker 
          label="Data de Pagamento"
          name="paymentDate"
          selected={formik.values.paymentDate}
          onChange={selectedDate => formik.setFieldValue('paymentDate', selectedDate)}
          maxDate={new Date()}
          showYearDropdown
          error={(formik.touched.paymentDate || undefined) && formik.errors.paymentDate}
        />

        <DatePicker 
          label="Data de Demissão"
          name="demissionDate"
          selected={formik.values.demissionDate}
          onChange={selectedDate => formik.setFieldValue('demissionDate', selectedDate)}
          maxDate={new Date()}
          showYearDropdown
          error={(formik.touched.demissionDate || undefined) && formik.errors.demissionDate}
        />

        <DatePicker 
          label="Data de Admissão"
          name="admissionDate"
          selected={formik.values.admissionDate}
          onChange={selectedDate => formik.setFieldValue('admissionDate', selectedDate)}
          maxDate={new Date()}
          showYearDropdown
          error={(formik.touched.admissionDate || undefined) && formik.errors.admissionDate}
        />
      </InputGroup>
      
      <Button type="submit">Próximo</Button>
    </FormContainer>
  );
}