import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { toast } from 'react-toastify';
import { RiArrowLeftRightLine } from 'react-icons/ri';

import { Loading } from '@components/Loading';
import { Modal } from '@components/Modal';
import { Description, Title } from '@components/Header';
import { Input } from '@components/Input';
import { Select } from '@components/Select';
import { Button } from '@components/Button';
import ResultCard, { ItemDivider, ItemValue, ItemContainer, ItemTitle, ActionsContainer, ActionButton } from '@components/ResultCard';
import { Tooltip } from '@components/Tooltip';
import { TextArea } from '@components/TextArea';

import LeadsService, { FakerLead, FakerLeadStatus } from '@services/LeadsService';

import { CancelSquare, ChevronDown, Memo, PlusCircle, Search } from '@assets/images';

import { convertToRem } from '@utils/convertToRem';

import { OnChangeOptionType } from '@components/Select/Select.d';

import { Content, ContentWrapper, FiltersContainer, Footer, Header, LoadingContainer, ModalContent, Page, PageButton, Pagination, ResultsContainer } from './styles';

type LeadsOrder = 'Nome' | 'Data' | 'Status';

interface OrderSelect {
  label: LeadsOrder;
  value: LeadsOrder;
}

const orderSelectItems: OrderSelect[] = [
  { value: 'Nome', label: 'Nome' },
  { value: 'Data', label: 'Data' },
  { value: 'Status', label: 'Status' },
];

const limitSelectItems = [
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
];

interface StatusSelect {
  label: string; 
  value: FakerLeadStatus;
}

const statusSelectItems: StatusSelect[] = [
  { label: 'Atendido', value: 'attended' },
  { label: 'Não Atendido', value: 'not attended' },
  { label: 'Fechou o plano', value: 'closed plan' }
];

export function Leads() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [leads, setLeads] = useState<FakerLead[]>([]);
  
  const [search, setSearch] = useState('');
  const [selectedOrder, setSelectedOrder] = useState<LeadsOrder>("Data");
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);
  
  const orderedLeads = leads.sort((a, b) => {
    switch (selectedOrder) {
      case 'Data':
        if (a.date.getTime() < b.date.getTime()) return 1;

        if (a.date.getTime() > b.date.getTime()) return -1;

      return 0; 

        case 'Status':
          if (a.status < b.status) return 1;

          if (a.status > b.status) return -1;

        return 0;

        default: 
          if (a.name < b.name) return -1;

          if (a.name > b.name) return 1;

        return 0;
    }
  });
  const filteredLeads = orderedLeads.filter(lead => lead.name.toLowerCase().includes(search.toLowerCase()));
  const lastPage = Math.ceil(filteredLeads.length / itemsPerPage) || 1;

  const [selectedLead, setSelectedLead] = useState<FakerLead | null>(null);

  const [isNoteModalVisible, setIsNoteModalVisible] = useState(false);
  const [isStatusModalVisible, setIsStatusModalVisible] = useState(false);
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);

  const [note, setNote] = useState('');
  const [selectedStatus, setSelectedStatus] = useState<FakerLeadStatus | null>(null);

  // Setting dependents
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        
        const leadsData = await LeadsService.listFakerLeads();

        setLeads(leadsData);
      } catch (error) {
        toast.error('Não foi possível carregar dados dos pacientes!');

        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })()
  }, []);

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  function handleChangeLimit(selectedOption: OnChangeOptionType) {
    if (selectedOption === null) return;

    setItemsPerPage(Number(selectedOption.value));
  }

  function getColorDividerByStatus(status: FakerLeadStatus) {
    switch (status) {
      case 'attended': return theme.colors.helpers.info;
      case 'not attended': return theme.colors.helpers.warning;
      case 'closed plan': return theme.colors.helpers.success;
    }
  }

  function getStatusName(status: FakerLeadStatus) {
    switch (status) {
      case 'attended': return 'Atendido';
      case 'not attended': return 'Não Atendido';
      case 'closed plan': return 'Fechou o plano';
    }
  }

  function handleAddNote() {
    setIsNoteModalVisible(false);

    if (!note) return;

    toast.success('Observação adicionada com sucesso!');
  }

  function handleChangeStatus() {
    setIsStatusModalVisible(false);

    toast.success('Status alterado com sucesso!');
  }

  function handleDeletePatient() {
    setIsDeleteModalVisible(false);
    toast.success('Lead excluído com sucesso!');
  }

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  return (
    <Content>
      <Modal
        visible={selectedLead !== null && isNoteModalVisible}
        title="Adicionar observação"
        onRequestClose={() => setIsNoteModalVisible(false)}
        style={{ maxWidth: convertToRem(600) }}
      >
        <ModalContent>
          <TextArea 
            label='Observação' 
            name='note'
            placeholder='Inserir observações'
            value={note}
            onChange={e => setNote(e.target.value)} 
          />

          <Button onClick={handleAddNote}>Adicionar</Button>
        </ModalContent>
      </Modal>

      <Modal
        visible={selectedLead !== null && isStatusModalVisible}
        title="Alterar status"
        onRequestClose={() => setIsStatusModalVisible(false)}
        style={{ maxWidth: convertToRem(600) }}
      >
        <ModalContent>
          <Select
            label='Status' 
            options={statusSelectItems}
            value={statusSelectItems.find(status => status.value === selectedStatus)}
            onChange={selectedOption => setSelectedStatus(selectedOption?.value || null)}
            isClearable={false}
          />

          <Button onClick={handleChangeStatus}>Alterar</Button>
        </ModalContent>
      </Modal>

      <Modal 
        visible={selectedLead !== null && isDeleteModalVisible}
        title="Atenção"
        onRequestClose={() => setIsDeleteModalVisible(false)} 
      >
        <p className='text'>
          Deseja realmente excluir o lead <strong>{selectedLead?.name}</strong>? Essa ação irá excluir todos os dados e é irreversível.
        </p>

        <div className='buttons'>
          <button onClick={() => setIsDeleteModalVisible(false)}>
            Não
          </button>

          <button onClick={handleDeletePatient} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <ContentWrapper className="content">
        <Header>
          <div>
            <Title>Leads</Title>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam massa sapien velit ipsum, ac.
            </Description>
          </div>

          <Button 
            variant="tertiary" 
            onClick={() => navigate('/afiliado/leads/cadastro/')}
          >
            Cadastrar lead

            <PlusCircle strokeWidth={1.5} />
          </Button>
        </Header>

        <FiltersContainer>
          <Input
            label='Pesquisar'
            name='search'
            placeholder='Buscar por nome'
            iconRight={<Search />}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          
          <Select
            label="Classificar por"
            options={orderSelectItems}
            value={orderSelectItems.find(
              (option) => option.label === selectedOrder
            )}
            onChange={(option) => {
              setSelectedOrder(option?.label || "Data");
              setCurrentPage(1);
            }}
            isClearable={false}
          />

          <Select
            label="Exibir últimos"
            options={limitSelectItems}
            onChange={handleChangeLimit}
            defaultValue={limitSelectItems.find(
              (i) => i.label === String(itemsPerPage)
            )}
            isClearable={false}
          />
        </FiltersContainer>

        <ResultsContainer>
          {
            filteredLeads.length > 0
            ? filteredLeads
              .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
              .map(lead => (
                <ResultCard key={lead.id}>
                  <ItemContainer>
                    <div>
                      <ItemValue>{lead.name}</ItemValue>
                      <ItemTitle>Nome</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ItemDivider />

                  <ItemContainer>
                    <div>
                      <ItemValue>{lead.email}</ItemValue>
                      <ItemTitle>E-mail</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ItemDivider />

                  <ItemContainer>
                    <div>
                      <ItemValue>{lead.tel}</ItemValue>
                      <ItemTitle>Telefone</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ItemDivider />

                  <ItemContainer>
                    <div>
                      <ItemValue>{format(lead.date, 'dd/MM/yyyy')}</ItemValue>
                      <ItemTitle>Data</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ItemDivider color={getColorDividerByStatus(lead.status)} />

                  <ItemContainer>
                    <div>
                      <ItemValue 
                        style={{ 
                          color: getColorDividerByStatus(lead.status) 
                        }}
                      >
                        {getStatusName(lead.status)}
                      </ItemValue>

                      <ItemTitle>Status</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ActionsContainer>
                    <Tooltip content="Adicionar observação">
                      <ActionButton
                        color={theme.colors.helpers.success} 
                        onClick={() => {
                          setSelectedLead(lead);
                          setIsNoteModalVisible(true);
                        }}
                      >
                        <Memo />
                      </ActionButton>
                    </Tooltip>

                    <Tooltip content="Alterar status">
                      <ActionButton
                        color={theme.colors.helpers.warning} 
                        onClick={() => {
                          setSelectedLead(lead);
                          setSelectedStatus(lead.status);
                          setIsStatusModalVisible(true);
                        }}
                      >
                        <RiArrowLeftRightLine />
                      </ActionButton>
                    </Tooltip>

                    <Tooltip content="Excluir">
                      <ActionButton 
                        color={theme.colors.helpers.danger} 
                        onClick={() => {
                          setIsDeleteModalVisible(true);
                          setSelectedLead(lead);
                        }}
                      >
                        <CancelSquare />
                      </ActionButton>
                    </Tooltip>
                  </ActionsContainer>
                </ResultCard>
              ))
            : 'Nenhum lead foi encontrado' 
          }
        </ResultsContainer>
      </ContentWrapper>

      <Footer>
        <div className="content">
          <Pagination>
            <PageButton
              className="prev"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              <ChevronDown />
            </PageButton>

            <Page>{String(currentPage).padStart(2, "0")}</Page>

            <PageButton
              className="next"
              onClick={goToNextPage}
              disabled={currentPage === lastPage}
            >
              <ChevronDown />
            </PageButton>
          </Pagination>
        </div>
      </Footer>
    </Content>
  );
}