import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";

import { useAuth } from "@contexts/auth";
import { useProfessional } from "@contexts/professional";

import { Loading } from "@components/Loading";
import { Input, InputGroup } from "@components/Input";
import { Button } from "@components/Button";
import { Select } from "@components/Select";

import UsersService from "@services/UsersService";
import SpecialtiesService from "@services/SpecialtiesService";

import { genderOptions } from "@constants/genders";
import { clinicTypeOptions } from "@constants/clinic-type";

import { FormikEffect } from "@utils/FormikEffect";
import { formatCPFCNPJ } from "@utils/formatCPFCNPJ";

import { OptionTypeBase } from "@components/Select/Select";

import { FormContainer } from "./styles";

interface InitialValues {
  name: string;
  email: string;
  cpf: string;
  gender: OptionTypeBase | null;
  specialties: OptionTypeBase[] | null;
};

export function GeneralTab() {
  const { selectedAccount } = useAuth();
  const { professional, addProfessionalInfo } = useProfessional();

  const navigate = useNavigate();
  const { uuid } = useParams();
  
  const [isLoading, setIsLoading] = useState(true);
  const [specialtyOptions, setSpecialtyOptions] = useState<OptionTypeBase[]>([]);

  const formik = useFormik<InitialValues>({
    enableReinitialize: true,
    initialValues: {
      name: professional.name,
      email: professional.email,
      cpf: formatCPFCNPJ(professional.document),
      gender: genderOptions.find(gender => gender.value === professional.gender?.value) || null,
      specialties: specialtyOptions.filter(specialty => {
        const specialtyValues = professional.specialties?.map(specialty => specialty.value);

        return specialtyValues?.includes(specialty.value);
      }),
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .matches(/(\w.+\s).+/i, "Insira seu nome completo")
        .required("O campo é obrigatório"),
      email: Yup.string()
        .email("Insira um e-mail válido")
        .required("O campo é obrigatório"),
      cpf: Yup.string()
        .matches(/^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/, "Insira um CPF válido")
        .required("O campo é obrigatório"),
      gender: Yup.object()
        .shape({
          value: Yup.string(),
          label: Yup.string() 
        })
        .required("O campo é obrigatório")
        .typeError("Selecione um gênero"),
      specialties: Yup.array().of(
          Yup.object().shape({
            value: Yup.string(),
            label: Yup.string(),
          })
        )
        .min(1, "Selecione pelo menos uma especialidade")
        .required("O campo é obrigatório")
        .typeError("Selecione uma especialidade"),
    }),
    onSubmit: (values) => {
      addProfessionalInfo({ 
        name: values.name, 
        email: values.email,
        document: values.cpf, 
        gender: values.gender,
        specialties: values.specialties,
      });

      navigate(`/profissionais/${uuid !== undefined ? `editar/${uuid}` : 'adicionar'}/horarios`);
    },
  });

  useEffect(() => {
    (async () => {
      try {
        if (!selectedAccount) throw new Error("User must be selected");

        setIsLoading(true);

        const { data: { clinica } } = await UsersService.listData(selectedAccount.uuid);

        const type = clinicTypeOptions.find(type => type.label === clinica.dados.tipo);
        if (!type) throw new Error("Clinic type doesn't exists");

        const { data: { dados } } = await SpecialtiesService.listSpecialties(type.value);
        const [specialties] = dados;

        const parsedSpecialties: OptionTypeBase[] = specialties.map(
          (specialty) => ({
            value: String(specialty.id),
            label: specialty.nome,
          })
        );

        setSpecialtyOptions(parsedSpecialties);
      } catch (error) {
        setSpecialtyOptions([]);

        toast.error("Não foi possível carregar as especialidades");

        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) return <Loading />

  return (
    <FormContainer onSubmit={formik.handleSubmit}>
      <FormikEffect formik={formik} />

      <Input 
        label="Nome Completo" 
        name="name" 
        value={formik.values.name}
        onChange={formik.handleChange}
        error={(formik.touched.name || undefined) && formik.errors.name}
      />

      <InputGroup>
        <Input 
          label="Email" 
          name="email" 
          value={formik.values.email}
          onChange={formik.handleChange}
          error={(formik.touched.email || undefined) && formik.errors.email}
        />
      </InputGroup>

      <InputGroup>
        <Input 
          label="CPF" 
          name="cpf"
          mask={[/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]} 
          value={formik.values.cpf}
          onChange={formik.handleChange}
          error={(formik.touched.cpf || undefined) && formik.errors.cpf}
        />

        <Select 
          label="Gênero"
          options={genderOptions}
          name="gender"
          value={formik.values.gender}
          onChange={option => formik.setFieldValue('gender', option)}
          error={(formik.touched.gender || undefined) && formik.errors.gender}
          isClearable={false}
        />
      </InputGroup>

      <Select 
        label="Especialidades"
        options={specialtyOptions}
        name="specialties"
        value={formik.values.specialties}
        onChange={options => formik.setFieldValue('specialties', options)}
        error={(formik.touched.specialties || undefined) && formik.errors.specialties}
        isMulti
      />

      <Button type="submit">Próximo</Button>
    </FormContainer>
  );
}