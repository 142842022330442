import { useNavigate } from "react-router-dom";

import { useAuth } from "@contexts/auth";

import { Description, Title } from "@components/Header";
import { Input } from "@components/Input";
import { Button } from "@components/Button";

import familyPrime from '@assets/images/prime-card-family.png';
import familyPrimeBig from '@assets/images/prime-card-family-big.png';

import { Content, ContentWrapper, CardContainer, GridContent, SidebarCard, Header } from "./styles";

export function Plans() {
  const { selectedAccount } = useAuth();

  const navigate = useNavigate();

  return (
    <Content>
      <ContentWrapper className="content">
        <Header>
          <div>
            <Title>Plano Prime</Title>
            <Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus vestibulum, tellus ac rhoncus.
            </Description>
          </div>

          {selectedAccount?.isPrime && (
            <Button>Seu prime está ativo</Button>
          )}
        </Header>

        <GridContent hideSidebar={!!selectedAccount?.isPrime}>
          <CardContainer image={selectedAccount?.isPrime ? familyPrimeBig : familyPrime}>
            <div className="texts">
              <h2>Conheça o Cartão Agenda Consulta Prime para toda a sua família.</h2>
              <h3>Você, seu cônjuge e filhos aproveitam tudo que tem na Agenda Consulta pelo valor de uma única assinatura.</h3>

              <ul>
                <li>Médicos</li>
                <li>Dentistas</li>
                <li>Terapias</li>
                <li>Exames</li>
                <li>Farmácias</li>
              </ul>
              
              <h5>De <span className="old-price">R$ 89,90</span> por apenas</h5>
              <h4 className="price">R$ 0,00 - Grátis</h4>

              <div className="buttons">
                <Button>Adquira já</Button>

                {selectedAccount?.isPrime && (
                  <Button 
                    variant="tertiary"
                    onClick={() => navigate('/carteirinha-digital/')}
                  >
                    Ver carteirinha
                  </Button>
                )}
              </div>
            </div>
          </CardContainer>

          <SidebarCard>
            <h2>30 Dias Grátis</h2>
            <h3>Coloque o código promocional abaixo e garanta 30 dias grátis em nossa promoção.</h3>

            <Input 
              label="Código promocional"
              name="coupon"
            />

            <Button>Ativar</Button>
          </SidebarCard>
        </GridContent>
      </ContentWrapper>
    </Content>
  );
}