import styled from "styled-components";
import chroma from "chroma-js";

import { Container as Button } from "@components/Button/styles";
import { InputGroup } from "@components/Input";
import { Container as TextAreaContainer } from '@components/TextArea/styles';
import { ActionsContainer } from "@components/ResultCard/styles";

import { convertToRem } from "@utils/convertToRem";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 8fr 4fr;
  gap: ${convertToRem(30)};
  
  padding-top: ${convertToRem(36)};
  padding-bottom: ${convertToRem(80)};

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

export const FormContainer = styled.form`
  width: 100%;
  max-width: ${convertToRem(770)};

  padding-bottom: ${convertToRem(80)};

  > ${Button} {
    margin-top: ${convertToRem(60)};
    margin-left: auto;
  }

  @media (max-width: 992px) {
    max-width: 100%;
  }

  @media (max-width: 576px) {
    ${InputGroup} {
      grid-template-columns: 1fr;

      &.bank {
        grid-template-columns: 1fr 1fr;

        > *:first-child {
          grid-column: 1 / 3;
        }
      }

      &.neighborhood {
        grid-template-columns: 1fr 2fr;

        > *:last-child {
          grid-column: 1 / 3;
        }
      }
    }

    > ${Button} {
      width: 100%;
    }
  }
`;

export const Fieldset = styled.fieldset`
  border: none;

  legend {
    margin-bottom: ${convertToRem(30)};

    font-weight: 500;
    font-size: ${convertToRem(10)};
    line-height: ${convertToRem(11)};

    color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.5).css()};

    text-transform: uppercase;
  }

  & + & {
    margin-top: ${convertToRem(40)};
  }

  > ${TextAreaContainer} {
    margin-top: ${convertToRem(20)};
  }
`;

export const ButtonContainer = styled.div`
  position: fixed;
  left: ${convertToRem(80)};
  right: 0;
  bottom: 0;

  display: grid;
  grid-template-columns: 8fr 4fr;
  column-gap: ${convertToRem(30)};

  width: calc(100vw - ${convertToRem(80)});

  padding: ${convertToRem(25)} 0;

  background: ${({ theme }) => theme.colors.neutral.white};
  border-top: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

  > button {
    margin-left: auto;
  }
`;


export const SpecialtiesContainer = styled.div`
  position: sticky;
  top: ${convertToRem(36)};

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  height: fit-content;

  background: ${({ theme }) => theme.colors.neutral.white};

  padding: ${convertToRem(30)} ${convertToRem(20)};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.borderDark};
  border-radius: ${convertToRem(5)};

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    padding: ${convertToRem(10)} 0;

    color: ${({ theme }) => theme.colors.neutral.textDark};

    strong {
      font-size: ${convertToRem(13)};
    }

    ${ActionsContainer} {
      width: ${convertToRem(120)};
      height: ${convertToRem(40)};

      padding: ${convertToRem(10)};

      svg {
        height: ${convertToRem(16)};
      }
      /* padding: 0; */
    }

    .buttons {
      display: flex;
      align-items: center;
      justify-content: center;

      padding: ${convertToRem(5)} ${convertToRem(10)};

      background-color: cyan;
    }

    &:not(:first-child) {
      margin-top: ${convertToRem(20)};
    }
  }
`;
