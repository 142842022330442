import { useEffect, useState } from "react";
import { format } from "date-fns";
import { toast } from "react-toastify";

import { useAuth } from "@contexts/auth";

import { Modal } from "@components/Modal";
import { TimeScheduling } from "@components/TimeScheduling";
import { Button } from "@components/Button";
import { Checkbox } from "@components/Checkbox";
import { Select } from "@components/Select";

import { Professionals } from "@services/ProfessionalsService";
import DependentsService from "@services/DependentsService";

import { Users, VideoCamera } from "@assets/images";
import nullAvatar from "@assets/images/null-avatar.jpg";

import { convertToRem } from "@utils/convertToRem";
import { formatToBRL } from "@utils/formatToBRL";

import { OptionTypeBase } from "@components/Select/Select";

import { ModalContent, Container, Content } from "./styles";

interface ConsultationsTabProps {
  data: Professionals;
}

export function ConsultationsTab({ data }: ConsultationsTabProps) {
  const { selectedAccount } = useAuth();

  const [selectedLocal, setSelectedLocal] = useState<"presential" | "online">("presential");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [isConsultationModalVisible, setIsConsultationModalVisible] = useState(false);
  const [isConsultationForDependent, setIsConsultationForDependent] = useState(false);

  const [dependents, setDependents] = useState<OptionTypeBase[]>([]);
  const [selectedDependent, setSelectedDependent] = useState<OptionTypeBase | null>(null); 
  const [dependentError, setDependentError] = useState('');

  // Setting dependents
  useEffect(() => {
    (async () => {
      try {
        const dependentsData = await DependentsService.listDependents();

        setDependents(dependentsData.map(dependent => ({
          label: dependent.name,
          value: dependent.id,
        })));
      } catch (error) {
        toast.error('Não foi possível carregar dados dos dependentes!');

        console.log(error);
      }
    })()
  }, []);
  
  function handleChangeSelectLocal(selectedLocal: "presential" | "online") {
    setSelectedLocal(selectedLocal);
    setSelectedDate(null);
  }

  function handleSubmit() {
    if (selectedLocal === null) return alert("Selecione o local!");
    if (selectedDate === null) return alert("Selecione a data e hora da consulta!");

    setIsConsultationModalVisible(true);
  }

  function handleConfirmConsultation() {
    if (isConsultationForDependent && selectedDependent === null) {
      setDependentError('Selecione um dependente');
      return;
    }

    setDependentError('');
    setSelectedDate(null);
    setSelectedLocal("presential");

    setIsConsultationModalVisible(false);

    toast.success('Consulta agendada com sucesso!');
  }

  return (
    <Container>
      <Modal 
        visible={isConsultationModalVisible}
        disablePadding
        onRequestClose={() => setIsConsultationModalVisible(false)} 
        style={{
          maxWidth: convertToRem(700),
        }}
      >
        <ModalContent>
            <header>
              <div className="info">
                <img src={data.avatar || nullAvatar} alt={data.name} className="avatar" />

                <div>
                  <h3>{data.name}</h3>
                  <h4 className="primary">{data.specialty} | {data.council} {data.councilNumber}</h4>
                </div>

                <span className="separator" />

                <div>
                  <h3>{data.healthInsurance}</h3>
                  <h4>Presencial{data.isAttendOnline && ', Telemedicina'}</h4>
                </div>

                <span className="separator" />

                <div>
                  <h3>{formatToBRL(data.normalValue)} | {formatToBRL(data.primeValue)}</h3>
                  <h4>Preço Normal | Prime</h4>
                </div>
              </div>

              <div className="address">
                <div>
                  <h4>Endereço</h4>
                  <p>
                    {`${data.address.street}, ${data.address.number}${
                    data.address.complement ? ` - ${data.address.complement}` : ""}`}
                    .<br />
                    {`${data.address.zipCode} - ${data.address.city}, ${data.address.state}`}
                  </p>
                </div>

                <Button variant="secondary">Ver horários</Button>
              </div>
            </header>

            <footer>
              <h2>Confirmação</h2>
              <p>Confirma agendamento para o dia <span>{format(selectedDate as Date, "dd/MM/yy 'às' HH'h'mm")}</span>?</p>

              <Checkbox 
                label="Essa consulta é para outra pessoa"
                name="isConsultationForDependent"
                checked={isConsultationForDependent} 
                onChange={(e) => setIsConsultationForDependent(e.target.checked)}
              />

              {isConsultationForDependent && (
                <form className="dependent-data">
                  <Select 
                    label="Dependente"
                    name="dependent"
                    options={dependents}
                    value={dependents.find(dependent => dependent === selectedDependent)}
                    onChange={(selectedOption) => {
                      if (selectedOption !== null) setDependentError('');
                      setSelectedDependent(selectedOption);
                    }}
                    error={dependentError}
                  />
                </form>
              )}

              {!selectedAccount?.isPrime && (
                <div className="prime-promo">
                  <p>Seja um cliente Prime e tenha até 70% de desconto em consultas médicas, terapias, dentistas, exames e farmácias.</p>

                  <Button>Assinar</Button>
                </div>
              )}

              <div className="buttons-container">
                <Button 
                  variant="secondary"
                  onClick={() => setIsConsultationModalVisible(false)}
                >
                  Cancelar
                </Button>

                <Button 
                  variant="primary"
                  onClick={handleConfirmConsultation}
                >
                  Sim, concordo
                </Button>
              </div>
          </footer>
        </ModalContent>
      </Modal>

      <Content>
        <h3>Defina Data e horário</h3>

        <div className="local-buttons">
          <Button
            variant={selectedLocal === "presential" ? "primary" : "secondary"}
            onClick={() => handleChangeSelectLocal("presential")}
          >
            <Users />
            No Local
          </Button>

          <Button
            variant={selectedLocal === "online" ? "primary" : "secondary"}
            onClick={() => handleChangeSelectLocal("online")}
          >
            <VideoCamera />
            Telemedicina
          </Button>
        </div>

        <TimeScheduling 
          consultationDuration={data.schedule.duration}
          schedule={data.schedule[selectedLocal]}
          specials={data.schedule.specials[selectedLocal]}
          blockedTimes={data.consultations}
          selectedDate={selectedDate}
          onChangeSelectedDate={(selectedDate) => setSelectedDate(selectedDate)}
        />

        <Button 
          disabled={!selectedLocal || !selectedDate}
          onClick={handleSubmit}
        >
          Confirmar
        </Button>
      </Content>
    </Container>
  );
}