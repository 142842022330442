import styled from "styled-components";
import chroma from "chroma-js";

import { Container as Select } from "@components/Select/styles";
import { Container as Button } from "@components/Button/styles";

import { convertToRem } from "@utils/convertToRem";

export const LoadingFiltersContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: calc(100vh - ${convertToRem(80)});

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.text};

  h4 {
    margin-top: ${convertToRem(20)};
  }
`;

export const LoadingPharmaciesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: calc(100vh - ${convertToRem(80)} - ${convertToRem(50)} - ${convertToRem(40)} - ${convertToRem(56)} - ${convertToRem(30)});

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.text};

  h4 {
    margin-top: ${convertToRem(20)};
  }
`;


export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const FiltersContainer = styled.div`
  display: grid;
  grid-template-columns: 8fr 4fr;
  gap: ${convertToRem(30)};

  min-height: ${convertToRem(50)};

  margin-top: ${convertToRem(-1)} !important;

  > div {
    display: grid;
    grid-template-columns: ${convertToRem(40)} 1.5fr 2.5fr 5fr;
  }

  ${Select} {
    [class$="control"] {
      border-radius: 0;
      border-right: 0;
    }

    [class$="placeholder"] {
      color: ${({ theme }) => theme.colors.neutral.text};

      font-weight: 500;
      font-size: ${convertToRem(13)};
    }
  }
`;

export const FilterIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary.base};

  > svg {
    stroke-width: 1.25;
  }
`;

export const FilterItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 ${convertToRem(20)};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.borderDark};

  > ul {
    display: flex;

    li {
      padding: ${convertToRem(4)} ${convertToRem(15)};

      &:first-of-type {
        padding-left: 0;
      }

      &:not(:first-of-type) {
        padding-right: 0;

        border-left: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
      }
    }

    button {
      background: none;
      border: 0;

      color: ${({ theme }) => theme.colors.primary.highlight};

      font-weight: 400;
      font-size: ${convertToRem(13)};
      line-height: 1;

      &.active {
        font-weight: 600;
      }
    }
  }
`;

export const FilterLabel = styled.span`
  color: ${({ theme }) => theme.colors.neutral.text};

  font-weight: 500;
  font-size: ${convertToRem(13)};
  line-height: 1;
`;

export const ContentWrapper = styled.div`
  flex: 1;

  position: relative;

  display: grid;
  grid-template-columns: 8fr 4fr;
  gap: ${convertToRem(30)};

  padding-top: ${convertToRem(40)};
  padding-bottom: ${convertToRem(200)};
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const PageTitle = styled.div`
  margin-bottom: ${convertToRem(30)};
`;

export const PharmacyCard = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${convertToRem(80)};

  padding: ${convertToRem(35)} ${convertToRem(30)};

  background-color: ${({ theme }) => theme.colors.neutral.white};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
  border-radius: ${convertToRem(5)};

  h5 {
    margin-bottom: ${convertToRem(10)};

    color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.5).css()};

    font-weight: 500;
    font-size: ${convertToRem(12)};
    line-height: 1;
  }

  &:not(:first-of-type) {
    margin-top: ${convertToRem(30)};
  }
`;

export const PharmacyInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PharmacyName = styled.div`
  display: flex;

  margin-bottom: ${convertToRem(40)};

  > img {
    width: ${convertToRem(84)};
    height: ${convertToRem(84)};

    background-color: ${({ theme }) => theme.colors.neutral.white};

    object-fit: contain;
  }

  > div {
    margin-left: ${convertToRem(20)};

    h4 {
      color: ${({ theme }) => theme.colors.neutral.text};

      font-weight: 500;
      font-size: ${convertToRem(20)};
      line-height: 1;
    }

    .stars {
      margin-top: ${convertToRem(3)};

      color: ${({ theme }) => theme.colors.helpers.warning};
    }

    a {
      margin-top: ${convertToRem(5)};

      color: ${({ theme }) => theme.colors.neutral.text};

      font-weight: 400;
      font-size: ${convertToRem(12)};
      line-height: 1.4;

      text-decoration: underline;
    }
  }
`;

export const PharmacyPromos = styled.div`
  margin-top: auto;

  li {
    display: flex;
    align-items: center;
    justify-content: flex-start;


    span {
      display: flex;
      align-items: center;
      justify-content: center;

      width: ${convertToRem(70)};
      height: ${convertToRem(30)};

      background: #E7F9EB;
      color: #0FC237;
      
      font-weight: 400;
      font-size: ${convertToRem(12)};
      line-height: 1;

      border-radius: ${convertToRem(5)};
    }

    p {
      margin-left: ${convertToRem(16)};

      color: ${({ theme }) => theme.colors.neutral.text};

      font-size: ${convertToRem(14)};
      line-height: 1.5;
    }

    &:not(:first-child) {
      margin-top: ${convertToRem(10)};
    }
  }
`;

export const PharmacyAddress = styled.div`
  display: flex;
  flex-direction: column;

  p {
    margin-bottom: auto;

    color: ${({ theme }) => theme.colors.neutral.text};
    
    font-size: ${convertToRem(14)};
    line-height: 1.5;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: ${convertToRem(20)};
    padding: ${convertToRem(16)} 0;

    color: ${({ theme }) => theme.colors.neutral.text};

    font-weight: 500;
    font-size: ${convertToRem(15)};
    line-height: 1.4;

    border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
    border-radius: ${convertToRem(5)};

    &.routes {
      color: ${({ theme }) => theme.colors.primary.highlight};
      
      border-color: ${({ theme }) => theme.colors.primary.highlight};
    }
  }
`;

export const SidebarContainer = styled.div`
  height: fit-content;

  padding-bottom: ${convertToRem(30)};

  > h2 {
    padding-top: ${convertToRem(36)};
    margin-bottom: ${convertToRem(30)};

    color: ${({ theme }) => theme.colors.neutral.text};

    font-weight: 500;
    font-size: ${convertToRem(20)};
    line-height: 1;
  }

  .search-buttons {
    margin-top: ${convertToRem(30)};

    ${Button} {
      width: 100%;

      &:not(:first-child) {
        margin-top: ${convertToRem(16)};
      }
    }
  }
`;