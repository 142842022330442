import styled from "styled-components";

import { Container as Switch, SwitchLabel } from '@components/Switch/styles';
import { Container as Input } from '@components/Input/styles';
import { Container as Button } from '@components/Button/styles';

import { convertToRem } from "@utils/convertToRem";

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(40)};
  padding-bottom: ${convertToRem(80)};
`;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  max-width: ${convertToRem(770)};

  ${Switch} {
    flex: 1;

    flex-direction: row-reverse;

    width: 100%;
    max-width: ${convertToRem(370)};

    margin-top: ${convertToRem(40)};
    padding: ${convertToRem(30)} ${convertToRem(36)};

    background-color: ${({ theme }) => theme.colors.neutral.white};

    border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
    border-radius: ${convertToRem(5)};

    ${SwitchLabel} {
      margin-left: 0;
      margin-right: auto;
    }
  }

  > ${Button} {
    &[type="button"] {
      min-width: auto;
      min-height: auto;
  
      margin-top: ${convertToRem(40)};
      padding: ${convertToRem(10)} ${convertToRem(20)};

      svg {
        margin-right: ${convertToRem(8)};
      }
    }

    &[type="submit"] {
      margin-top: ${convertToRem(140)};
      margin-left: auto;
    }
  }
  
  @media (max-width: 576px) {
    > ${Button}[type="submit"] {
      width: 100%;
    }
  }
`;

export const HealthPlanContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${convertToRem(24)};

  width: 100%;
  
  margin-top: ${convertToRem(40)};

  .health-plan {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 100%;
    height: ${convertToRem(80)};

    > ${Input} {
      flex: 1;

      margin-bottom: auto;
    }

    .actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      margin-left: ${convertToRem(8)};

      button {
        background-color: transparent;

        border: 0;

        &.add {
          color: ${({ theme }) => theme.colors.helpers.success};
        }

        &.remove {
          margin-top: ${convertToRem(4)};

          color: ${({ theme }) => theme.colors.helpers.danger};
        }

        svg {
          stroke-width: 2.5;
        }
      }
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
`;