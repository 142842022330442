import styled from "styled-components";
import chroma from "chroma-js";
import { Link, NavLink } from "react-router-dom";

import { convertToRem } from "@utils/convertToRem";

export const MenuButton = styled.button`
  display: none;

  background: none;
  color: ${({ theme }) => theme.colors.primary.base};

  margin-left: ${convertToRem(16)};

  border: 0;

  svg {
    width: auto;
    height: ${convertToRem(24)};
  }

  &.notification {
    margin-left: auto;

    &.has-notification {
      position: relative;

      &::after {
        content: '';

        position: absolute;
        top: ${convertToRem(0.5)};
        right: ${convertToRem(0.5)};
        
        width: ${convertToRem(6)};
        height: ${convertToRem(6)};

        background: ${({ theme }) => theme.colors.primary.base};

        border: solid ${convertToRem(1.5)} ${({ theme }) => theme.colors.primary.lightest};
        border-radius: 50%;
      }
    }
  }
  
  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ExpandButton = styled.button`
  position: absolute;
  top: ${convertToRem(68)};
  right: ${convertToRem(-12)};

  display: flex;
  align-items: center;
  justify-content: center;

  width: ${convertToRem(24)};
  height: ${convertToRem(24)};

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.text};

  border: 0;
  border-radius: 50%;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);

  z-index: 9999;

  svg {
    width: auto;
    height: ${convertToRem(14)};

    stroke-width: 2;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const Logo = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: ${convertToRem(80)};

  padding: 0 ${convertToRem(24)};

  > svg {
    width: ${convertToRem(36)};
    height: auto;
  }
  
  @media (max-width: 768px) {
    width: auto;

    padding: 0;
  }
`;

export const MenuContainer = styled.nav`
  flex: 1;

  width: 100%;

  margin: ${convertToRem(32)} 0;

  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: ${convertToRem(4)};
    
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.4).css()};
    
    border-radius: ${convertToRem(8)};
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const MenuItem = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  
  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary.base};

  border: 0;

  transition: all ease 0.3s;
`;

export const MenuLink = styled(NavLink)`
  display: flex;
  align-items: center;

  padding: ${convertToRem(18)} ${convertToRem(24)};
  padding-left: ${convertToRem(28)};

  background-color: transparent;
  color: ${({ theme }) => theme.colors.primary.base};
      
  > svg {
    flex-shrink: 0;

    width: ${convertToRem(24)};
    height: ${convertToRem(24)};

    margin-right: ${convertToRem(24)};
  }
  
  span {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;
    
    font-family: "Graphik";
    font-weight: 400;
    font-size: ${convertToRem(15)};
    line-height: 1;
    
    transition: transform 0.3s ease-in-out;
    
    text-align: left;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    > svg {
      margin-left: ${convertToRem(4)};
      stroke-width: 2;
    }
  }

  &:hover  {
    background-color: ${({ theme }) => chroma(theme.colors.primary.light).alpha(0.05).css()};
  }

  &.active {
    background-color: ${({ theme }) => chroma(theme.colors.primary.light).alpha(0.15).css()};
    box-shadow: inset ${convertToRem(3)} 0 ${({ theme }) => theme.colors.primary.base};
  }
`;

export const DropdownLink = styled(NavLink)`
  display: none;

  padding: ${convertToRem(16)} ${convertToRem(24)};
  padding-left: ${convertToRem(84)};

  background-color: ${({ theme }) => theme.colors.primary.base};
  color: ${({ theme }) => theme.colors.primary.lightest};
    
  font-size: ${convertToRem(14)};

  &:hover  {
    background-color: ${({ theme }) => chroma(theme.colors.primary.dark).alpha(0.9).css()};
  }

  &.active {
    background-color: ${({ theme }) => theme.colors.primary.dark};
  }
`;

export const MenuMobileContainer = styled.nav`
  display: none;

  position: fixed;
  top: ${convertToRem(80)};
  left: 0;
  right: 0;
  bottom: 0;

  width: 100vw;
  min-height: 100vh;

  padding-bottom: ${convertToRem(32)};

  background-color: ${({ theme }) => theme.colors.primary.lightest};

  transition: all 0.3s ease-in-out;
  transform: translateX(100%);
  overflow-y: auto;
  z-index: 99999999;

  &::-webkit-scrollbar {
    width: ${convertToRem(4)};
    
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.4).css()};
    
    border-radius: ${convertToRem(8)};
  }

  &.opened {
    position: absolute;

    transform: translateX(0);
    overflow-y: initial;

    ${DropdownLink} {
      display: block;
    }
  }

  @media (max-width: 768px) {
    display: block;
  }
`;

export const Container = styled.aside`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  width: ${convertToRem(84)};
  min-height: 100vh;

  background-color: ${({ theme }) => theme.colors.primary.lightest};

  transition: all 0.3s ease-in-out;
  z-index: 999;

  &.expanded {
    width: ${convertToRem(290)};

    ${Logo} > svg {
      width: ${convertToRem(180)};
    }

    ${MenuLink} {
      padding-left: ${convertToRem(24)};
    }

    ${DropdownLink} {
      display: block;
    }
  }

  @media (max-width: 768px) {
    right: 0;
    bottom: initial;

    flex-direction: row;

    width: 100vw;
    height: ${convertToRem(80)};

    min-height: auto;

    padding-left: ${convertToRem(15)};
    padding-right: ${convertToRem(15)};

    &.expanded {
      width: 100vw;
    }
  }
`;