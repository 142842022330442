import styled, { css } from "styled-components";
import chroma from "chroma-js";

import { ButtonProps } from "./Button";

import { convertToRem } from "@utils/convertToRem";

const variants = {
  primary: css`
    background-color: ${({ theme }) => theme.colors.primary.highlight};
    color: ${({ theme }) => theme.colors.neutral.white};

    border: 0;

    &:hover {
      background-color: ${({ theme }) => chroma(theme.colors.primary.highlight).darken(0.2).css()};
    }
  `,
  secondary: css`
    background-color: transparent;
    color: ${({ theme }) => theme.colors.primary.highlight};

    border: ${({ theme }) => `solid ${convertToRem(1)} ${theme.colors.primary.highlight}`};

    &:hover {
      color: ${({ theme }) => chroma(theme.colors.primary.highlight).brighten(0.5).css()};

      border-color: ${({ theme }) => chroma(theme.colors.primary.highlight).brighten(0.5).css()};
    }
  `,
  tertiary: css`
    background-color: transparent;
    color: ${({ theme }) => theme.colors.neutral.text};

    border: ${({ theme }) => `solid ${convertToRem(1)} ${theme.colors.neutral.borderDark}`};

    &:hover {
      color: ${({ theme }) => chroma(theme.colors.neutral.text).brighten(0.5).css()};

      border-color: ${({ theme }) => chroma(theme.colors.neutral.borderDark).darken(0.5).css()};
    }

    > svg {
      width: auto;
      height: ${convertToRem(20)};
      
      margin-left: ${convertToRem(15)};

      color: ${({ theme }) => theme.colors.primary.base};
    }
  `,
}

export const Container = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  min-width: ${convertToRem(270)};
  min-height: ${convertToRem(50)};

  font-weight: 500;
  font-size: ${convertToRem(15)};

  border-radius: ${convertToRem(5)};

  transition: all ease 0.5s;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${({ variant }) => variant ? variants[variant] : ''};
`;