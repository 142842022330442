import styled from "styled-components";

import { convertToRem } from "@utils/convertToRem";


export const Container = styled.div<{ error: boolean; }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;

  label {
    display: block;
    
    color: ${({ theme }) => theme.colors.neutral.textDark};

    font-weight: 500;
    font-size: ${convertToRem(13)};
    line-height: 1;

    margin-bottom: ${convertToRem(8)};
  }

  textarea {
    width: 100%;
    min-height: ${convertToRem(180)};

    padding: ${convertToRem(16)};

    color: ${({ theme }) => theme.colors.neutral.text};

    font-family: 'Graphik';
    font-weight: 400;
    font-size: ${convertToRem(14)};
    line-height: 1;

    border: solid ${convertToRem(1)} ${({ error ,theme }) => error ? theme.colors.helpers.danger : theme.colors.neutral.borderDark};
    border-radius: ${convertToRem(5)};

    resize: vertical;
  }
`;

export const Error = styled.span`
  margin-top: ${convertToRem(4)};

  color: ${({ theme }) => theme.colors.helpers.danger};

  font-size: ${convertToRem(10)};
`;