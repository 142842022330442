import styled from "styled-components";
import chroma from "chroma-js";

import { Container as ResultCard, ItemContainer, ResponsiveCard } from "@components/ResultCard/styles";

import { convertToRem } from "@utils/convertToRem";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;

  margin-top: ${convertToRem(40)};

  background-color: ${({ theme }) => theme.colors.neutral.white};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.borderDark};
  border-radius: ${convertToRem(8)};

  overflow: hidden;
`;

export const Header = styled.header`
  width: 100%;

  padding: ${convertToRem(32)};

  border-bottom: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.borderDark};

  h1 {
    color: ${({ theme }) => theme.colors.neutral.textDark};

    font-size: ${convertToRem(18)};
  }

  .filters {
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: ${convertToRem(24)};

    max-width: ${convertToRem(400)};

    margin-top: ${convertToRem(24)};
  }

  @media (max-width: 768px) {
    padding: ${convertToRem(32)} ${convertToRem(24)};
  }

  @media (max-width: 576px) {
    .filters {
      grid-template-columns: 1fr;
    }
  }
`;

export const ResultsContainer = styled.div<{ isEmpty?: boolean; }>`
  width: 100%;
  
  padding: ${({ isEmpty }) => isEmpty ? `${convertToRem(40)} ${convertToRem(30)}` : '0'};

  ${ResultCard} {
    border: 0;
    border-radius: 0;
    
    &:not(:first-child) {
      border-top: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
    }

    ${ItemContainer} {
      flex: 1;

      img {
        width: ${convertToRem(44)};
        height: ${convertToRem(44)};
      }

      &:last-of-type {
        padding-right: 0;
      }
    }
  }

  @media (max-width: 768px) {
    ${ResultCard} {
      ${ResponsiveCard};
    }
  }
`;

export const Footer = styled.footer`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  width: 100%;

  padding: ${convertToRem(24)};

  border-top: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.borderDark};

  .page-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${convertToRem(34)};
    height: ${convertToRem(34)};

    background-color: ${({ theme }) => theme.colors.neutral.white};
    color: ${({ theme }) => theme.colors.primary.base};

    border: solid ${convertToRem(1)} ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.2).css()};
    border-radius: ${convertToRem(8)};

    &.prev {
      transform: rotate(90deg);
    }

    &.next {
      transform: rotate(270deg);
    }
    
    &:disabled {
      background-color: ${({ theme }) => chroma(theme.colors.neutral.border).brighten(0.4).css()};

      cursor: not-allowed;
    }

    > svg {
      width: auto;
      height: ${convertToRem(20)};
      
      stroke-width: 1.2;
    }
  }

  span {
    margin: 0 ${convertToRem(10)};

    color: ${({ theme }) => theme.colors.neutral.text};

    font-weight: 500;
    font-size: ${convertToRem(15)};
    line-height: 1;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`;
