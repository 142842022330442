import { Link } from "react-router-dom";

import { PlanCardIllustration } from "@assets/images";
import cartExample from "@assets/images/cart-example.png";

import { Container, PrimeContainer } from "./styles";

interface PlanCardProps {
  isPrime: boolean;
}

export function PlanCard({ isPrime }: PlanCardProps) {
  return isPrime ? (
    <PrimeContainer>
      <h5>Usuário Prime</h5>
      <ul>
        <li>Plano valido até 02/02/2021</li>
        <li>Cliente desde 00/00/0000</li>
      </ul>

      <p>Nº Cartão</p>
      <strong>000.000.000.000</strong>

      <Link to="/carteirinha-digital/" className="button">
        Ver Carteirinha Digital
      </Link>

      <img src={cartExample} alt="Exemplo de Carteirinha" />
    </PrimeContainer>
  ) : (
    <Container>
      <h5>Adquira o plano prime e tenha até 70% de desconto</h5>
      <Link to="/planos/" className="button">
        Adquira já
      </Link>

      <PlanCardIllustration />
    </Container>
  );
}
