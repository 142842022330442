import { RiBankCardLine, RiHealthBookLine, RiHistoryLine, RiHomeLine, RiPriceTag2Line, RiTeamLine, RiUser3Line } from 'react-icons/ri';

import { useAuth } from '@contexts/auth';

import { SidebarMenuItem } from '.';

export function PatientSidebar() {
  const { selectedAccount } = useAuth();

  return (
    <>
      <SidebarMenuItem 
        path='/' 
        icon={<RiHomeLine />} 
        title='Página Inicial' 
      />

      <SidebarMenuItem 
        path='/farmacias' 
        icon={<RiHealthBookLine />} 
        title='Farmácias' 
      />

      <SidebarMenuItem 
        path='/carteirinha-digital' 
        icon={<RiBankCardLine />} 
        title='Carteirinha Digital'
        isDisabled={!selectedAccount?.isPrime} 
      />

      <SidebarMenuItem 
        path='/editar-cadastro' 
        icon={<RiUser3Line />} 
        title='Editar Cadastro' 
      />

      <SidebarMenuItem 
        path='/historico' 
        icon={<RiHistoryLine />} 
        title='Históricos' 
        subnav={[
          { path: '/historico/consultas', title: 'Consultas' },
          { path: '/historico/exames', title: 'Exames' },
          { path: '/historico/prontuarios', title: 'Prontuários' },
        ]}
      />

      <SidebarMenuItem 
        path='/planos' 
        icon={<RiPriceTag2Line />} 
        title='Planos' 
      />

      <SidebarMenuItem 
        path='/dependentes' 
        icon={<RiTeamLine />} 
        title='Dependentes' 
      />
    </>
  );
}