import { createNumberMask } from "text-mask-addons";

export const currencyMask = createNumberMask({
  prefix: 'R$ ',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
  allowNegative: false,
  allowLeadingZeroes: false,
});