import styled from "styled-components";
import chroma from "chroma-js";

import { Modal } from "@components/Modal";
import { Container as Button } from "@components/Button/styles";

import { convertToRem } from "@utils/convertToRem";

export const CookiesModal = styled(Modal)`
  align-self: flex-end;

  flex-direction: row;

  max-width: ${convertToRem(820)};

  margin: ${convertToRem(48)} ${convertToRem(16)};

  p {
    flex: 1;

    a {
      color: ${({ theme }) => theme.colors.primary.base};

      line-height: 1.5;

      text-decoration: underline;
    }
  }

  ${Button} {
    min-width: auto;
    
    margin-left: ${convertToRem(24)};
    padding: 0 ${convertToRem(16)};
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;

    p {
      text-align: center;
    }
    
    ${Button} {
      margin-left: 0;
      margin-top: ${convertToRem(24)};
    }
  }
`;

export const LoadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100vw;
  height: 100vh;

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.text};

  h4 {
    margin-top: ${convertToRem(20)};
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  max-width: 100vw;
  min-height: 100vh;

  overflow-x: hidden;
`;

export const Main = styled.main`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: calc(100% - ${convertToRem(84)});
  height: fit-content;

  min-height: 100vh;

  margin-left: ${convertToRem(84)};

  @media (max-width: 768px) {
    width: 100%;

    margin-left: 0;
    margin-top: ${convertToRem(80)};
  }
`;

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(40)};
`;


export const AffiliateContainer = styled.div`
  position: relative;

  width: 100vw;
  min-height: 100vh;

  background-color: ${({ theme }) => chroma(theme.colors.neutral.border).alpha(0.3).css()};

  padding-bottom: ${convertToRem(40)};

  .content {
    padding: 0 ${convertToRem(15)};
  }
`;

export const AffiliateHeader = styled.header`
  width: 100vw;
  height: ${convertToRem(60)};

  margin-bottom: ${convertToRem(40)};

  background-color: ${({ theme }) => theme.colors.primary.lightest};

  .content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .logo {
    width: auto;
    height: ${convertToRem(30)};
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    width: 100%;

    margin-left: ${convertToRem(48)};

    > ul {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      font-weight: 500;
      font-size: ${convertToRem(15)};

      > li {
        &:not(:first-child) {
          margin-left: ${convertToRem(24)};
        }
      }

      a {
        color: ${({ theme }) => theme.colors.primary.base};
      }
    }
    
    .logout {
      margin-left: auto;
  
      background: none;
      color: ${({ theme }) => theme.colors.neutral.textDark};
  
      font-weight: 500;
      font-size: ${convertToRem(14)};
  
      border: 0;
    }
  }

  .menu-btn, .menu-mobile {
    display: none;
  }

  @media (max-width: 570px) {
    nav {
      display: none;
    }

    .menu-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      
      background: none;
      color: ${({ theme }) => theme.colors.primary.base};

      margin-left: auto;

      border: 0;

      svg {
        width: auto;
        height: ${convertToRem(24)};
      }
    }

    .menu-mobile {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      margin-left: 0;
      padding: ${convertToRem(24)};

      background-color: white;
      color: ${({ theme }) => theme.colors.primary.base};

      z-index: 999;

      transform: translateX(100%);
      transition: transform 0.5s ease;

      &.active {
        transform: translateX(0);
      }

      .close-btn {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-left: auto;
        margin-bottom: ${convertToRem(36)};

        background: none;

        border: 0;

        > svg {
          width: auto;
          height: ${convertToRem(24)};
        }
      }
      
      > ul, .logout {
        font-size: ${convertToRem(18)};
      }

      > ul {
        flex-direction: column; 

        li {
          cursor: pointer;

          svg {
            transition: transform 0.3s ease;
          }
          
          ul {
            display: none;

            a {
              color: ${({ theme }) => theme.colors.neutral.textDark};
            }
          }

          &:not(:first-child) {
            margin-left: 0;
            margin-top: ${convertToRem(16)};
          }

          &.active {
            svg {
              transform: rotate(-180deg);
            }

            ul {
              display: block;
              
              margin-top: ${convertToRem(8)};
            }
          }
        }
      }

      .logout {
        margin-left: 0;
        margin-top: ${convertToRem(16)};
      }
    }
  }
`;

export const AffiliateDropdown = styled.div<{ isActive?: boolean; }>`
  position: relative;

  cursor: pointer;

  span {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  
    color: ${({ theme }) => theme.colors.primary.base};

    > svg {
      margin-left: ${convertToRem(4)};

      stroke-width: 1.5;

      transform: ${({ isActive}) => isActive ? 'rotate(180deg)' : 'rotate(0deg)'};
      transition: transform ease 0.3s;
    }
  }

  > ul {
    position: absolute;
    top: calc(100% + ${convertToRem(12)});
    left: 0;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    width: fit-content;
    min-width: ${convertToRem(120)};

    background-color: ${({ theme }) => theme.colors.neutral.white};

    border-radius: ${convertToRem(6)};
    box-shadow: 0px ${convertToRem(2)} ${convertToRem(4)} rgba(0, 0, 0, 0.05);

    opacity: ${({ isActive }) => isActive ? '1' : '0'};
    transform: ${({ isActive }) => isActive ? 'translateY(0)' : 'translateY(-20px)'};
    pointer-events: ${({ isActive }) => isActive ? 'initial' : 'none'};

    transition: all ease 0.3s;

    overflow: hidden;
      
    li {
      width: 100%;
    }

    a {
      width: 100%;

      padding: ${convertToRem(16)} ${convertToRem(12)};

      font-size: ${convertToRem(14)};

      &:hover {
        background-color: ${({ theme }) => theme.colors.neutral.borderLight};
      }
    }
  }
`;
