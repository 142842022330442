import { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { toast } from 'react-toastify';

import { Loading } from '@components/Loading';
import { Modal } from '@components/Modal';
import { Description, Title } from '@components/Header';
import { Input } from '@components/Input';
import { Select } from '@components/Select';
import { Button } from '@components/Button';
import ResultCard, { ItemDivider, ItemValue, ItemContainer, ItemTitle, ActionsContainer, ActionButton } from '@components/ResultCard';
import { Tooltip } from '@components/Tooltip';

import DependentsService, { Dependent } from '@services/DependentsService';

import { CancelSquare, ChevronDown, Memo, PlusCircle, Search } from '@assets/images';

import { formatCPFCNPJ } from '@utils/formatCPFCNPJ';
import { formatRG } from '@utils/formatRG';

import { OnChangeOptionType } from '@components/Select/Select.d';

import { Content, ContentWrapper, FiltersContainer, Footer, LoadingContainer, Page, PageButton, Pagination, ResultsContainer } from './styles';

export const limitSelectItems = [
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
];

export function ListDependents() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [dependents, setDependents] = useState<Dependent[]>([]);
  
  const [search, setSearch] = useState('');
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const [lastPage, setLastPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  const [cancelModal, setCancelModal] = useState<Dependent | null>(null);

  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  function handleChangeLimit(selectedOption: OnChangeOptionType) {
    if (selectedOption === null) return;

    setItemsPerPage(Number(selectedOption.value));
  };

  function handleDeleteDependent() {
    setCancelModal(null);
    toast.success('Dependente excluído com sucesso!');
  }

  const filteredDependents = dependents.filter(dependent => dependent.name.toLowerCase().includes(search.toLowerCase()));

  // Setting dependents
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const dependentsData = await DependentsService.listDependents();

        setDependents(dependentsData);
      } catch (error) {
        toast.error('Não foi possível carregar dados dos dependentes!');

        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })()
  }, []);


  // Pagination
  useEffect(() => {
    if (!dependents) return;

    const lastPage = Math.ceil(dependents.length / itemsPerPage);
    setLastPage(lastPage);
    
    if (currentPage > lastPage || lastPage === 1) {
      setCurrentPage(1);
    }
  }, [dependents, itemsPerPage, currentPage]);

  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  return (
    <Content>
      <Modal 
        visible={cancelModal !== null }
        title="Confirmação de exclusão"
        onRequestClose={() => setCancelModal(null)} 
      >
        <p className='text'>
          Você confirma a exclusão do dependente <strong>{cancelModal?.name}</strong>?
        </p>

        <div className='buttons'>
          <button onClick={() => setCancelModal(null)}>
            Cancelar
          </button>

          <button onClick={handleDeleteDependent} className='primary'>
            Excluir
          </button>
        </div>
      </Modal>

      <ContentWrapper className="content">
        <Title>Lista de Dependentes</Title>
        <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam massa sapien velit ipsum, ac.
        </Description>

        <FiltersContainer>
          <Input
            label='Buscar dependente'
            name='search'
            placeholder='Pesquisar nome'
            iconRight={<Search />}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />

          <Select
            label="Exibir últimos"
            options={limitSelectItems}
            onChange={handleChangeLimit}
            defaultValue={limitSelectItems.find(
              (i) => i.label === String(itemsPerPage)
            )}
            isClearable={false}
          />

          <Button variant="tertiary" onClick={() => navigate('/dependentes/adicionar/')}>
            Adicionar dependente

            <PlusCircle strokeWidth={1.5} />
          </Button>
        </FiltersContainer>

        <ResultsContainer>
          {
            filteredDependents.length > 0
            ? filteredDependents
              .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
              .map((dependent, index) => (
                <ResultCard key={dependent.name + "--" + index}>
                  <ItemContainer>
                    <div>
                      <ItemValue>{dependent.name}</ItemValue>
                      <ItemTitle>Nome</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ItemDivider />

                  <ItemContainer>
                    <div>
                      <ItemValue>{format(dependent.birthDate, 'dd/MM/yyy')}</ItemValue>
                      <ItemTitle>Data de Nascimento</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ItemDivider />

                  <ItemContainer>
                    <div>
                      <ItemValue>{formatCPFCNPJ(dependent.cpf)}</ItemValue>
                      <ItemTitle>CPF</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ItemDivider />

                  <ItemContainer>
                    <div>
                      <ItemValue>{formatRG(dependent.rg)}</ItemValue>
                      <ItemTitle>RG</ItemTitle>
                    </div>
                  </ItemContainer>
                  
                  <ActionsContainer>
                    <Tooltip content="Excluir">
                      <ActionButton 
                        color={theme.colors.helpers.danger} 
                        onClick={() => setCancelModal(dependent)}
                      >
                        <CancelSquare />
                      </ActionButton>
                    </Tooltip>
                    
                    <Tooltip content="Editar">
                      <ActionButton
                        color={theme.colors.helpers.success} 
                        onClick={() => navigate(`/dependentes/editar/${dependent.id}`)}
                      >
                        <Memo strokeWidth={1.5} />
                      </ActionButton>
                    </Tooltip>
                  </ActionsContainer>
                </ResultCard>
              ))
            : 'Nenhum dependente foi encontrado' 
          }
        </ResultsContainer>
      </ContentWrapper>

      <Footer>
        <div className="content">
          <Pagination>
            <PageButton
              className="prev"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              <ChevronDown />
            </PageButton>

            <Page>{String(currentPage).padStart(2, "0")}</Page>

            <PageButton
              className="next"
              onClick={goToNextPage}
              disabled={currentPage === lastPage}
            >
              <ChevronDown />
            </PageButton>
          </Pagination>
        </div>
      </Footer>
    </Content>
  );
}