import styled from "styled-components";

import { Container as Button } from "@components/Button/styles";
import { Container as Callout } from "@components/Callout/styles";
import { InputGroup } from "@components/Input";

import { convertToRem } from "@utils/convertToRem";

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  
  width: 100%;
  height: 100%;

  max-width: ${convertToRem(770)};

  padding-top: ${convertToRem(36)};
  padding-bottom: ${convertToRem(80)};

  ${Callout} {
    margin-top: ${convertToRem(180)};
  }

  ${Button} {
    width: min-content;

    margin-top: ${convertToRem(100)};
    margin-left: auto;
  }

  @media (max-width: 992px) {
    max-width: 100%;
  }

  @media (max-width: 576px) {
    ${InputGroup} {
      grid-template-columns: 1fr;
    }

    > ${Button} {
      width: 100%;
    }
  }
`;