import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';
import axios from 'axios';

import { useAuth } from '@contexts/auth';

import { Description, Title } from '@components/Header';
import { LoadingModal, Modal } from '@components/Modal';
import { Button } from '@components/Button';
import { Select } from '@components/Select';
import { Loading } from '@components/Loading';
import ResultCard, { ItemDivider, ItemValue, ItemContainer, ItemTitle, ActionsContainer, ActionButton } from '@components/ResultCard';
import { Tooltip } from '@components/Tooltip';

import ProfessionalsService from '@services/ProfessionalsService';

import { delay } from '@utils/delay';

import { CancelSquare, ChevronDown, Memo, PlusCircle } from '@assets/images';

import { OnChangeOptionType } from '@components/Select/Select.d';
import { AxiosAPIError } from '@/types/api';

import { Content, ContentWrapper, FiltersContainer, Footer, Header, Page, PageButton, Pagination, ResultsContainer } from './styles';

type EmployeesOrder = 'Nome' | 'Cargo/Posição';

interface OrderSelect {
  label: EmployeesOrder;
  value: EmployeesOrder;
}

const orderSelectItems: OrderSelect[] = [
  { value: 'Nome', label: 'Nome' },
  { value: 'Cargo/Posição', label: 'Cargo/Posição' },
];

const limitSelectItems = [
  { value: '10', label: '10' },
  { value: '15', label: '15' },
  { value: '20', label: '20' },
  { value: '25', label: '25' },
];

interface Employee {
  id: string;
  name: string;
  position: string;
  address: {
    city: string;
    uf: string;
  };
}

export function ListEmployees() {
  const { selectedAccount } = useAuth();

  const theme = useTheme();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [employees, setEmployees] = useState<Employee[]>([]);

  const [selectedOrder, setSelectedOrder] = useState<EmployeesOrder>("Nome");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  
  const [currentPage, setCurrentPage] = useState(1);
  const [lastPage, setLastPage] = useState(1);

  const [selectedEmployee, setSelectedEmployee] = useState<Employee | null>(null);

  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  useEffect(() => {
    async function getEmployees() {
      try {
        if (!selectedAccount) return;
  
        setIsLoading(true);
        
        await delay();

        const fakerProfessionals = await ProfessionalsService.listProfessionals();

        const maxPage = Math.ceil(fakerProfessionals.length / itemsPerPage) || 1;
        if (currentPage > maxPage) {
          setCurrentPage(maxPage);
        }

        const parsedEmployees: Employee[] = fakerProfessionals
          .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage)
          .map(professional => ({
            id: professional.id,
            name: professional.name,
            position: professional.specialty,
            address: {
              city: professional.address.city,
              uf: professional.address.state,
            },
          }))
        ;

        setEmployees(parsedEmployees);
        setLastPage(maxPage);
      } catch (err) {
        const error = err as AxiosAPIError;
  
        console.error(error);
  
        if (axios.isAxiosError(error) && error.response) {
          toast.error(error.response.data.menssagem);
        } else {
          toast.error(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    }

    getEmployees();
  }, [selectedAccount, itemsPerPage, currentPage]);
  
  function goToPreviousPage() {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  function goToNextPage() {
    if (currentPage < lastPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  function handleChangeLimit(selectedOption: OnChangeOptionType) {
    if (selectedOption === null) return;

    setItemsPerPage(Number(selectedOption.value));
    setCurrentPage(1);
  };

  async function handleDeleteEmployee() {
    if (!selectedAccount || !selectedEmployee || isSubmitting) return;

    try {
      setIsDeleteModalVisible(false);
      setIsSubmitting(true);

      await delay();

      const updatedEmployees: Employee[] = employees.filter(employee => employee.id !== selectedEmployee.id);
      
      setEmployees(updatedEmployees);
      
      if (updatedEmployees.length < 1) {
        setCurrentPage((prevState) => (prevState - 1) || 1)
      }
      
      toast.success('Colaborador excluído com sucesso!');
    } catch (err) {
      const error = err as AxiosAPIError;
  
      console.error(error);

      if (axios.isAxiosError(error) && error.response) {
        toast.error(error.response.data.menssagem);
      } else {
        toast.error(error.message);
      }
    } finally {
      setIsSubmitting(false);
      setSelectedEmployee(null);
    }
  }

  return (
    <Content>
      <LoadingModal 
        visible={isSubmitting}
        title="Excluindo..."
      />

      <Modal 
        visible={selectedEmployee !== null && isDeleteModalVisible}
        title="Atenção"
        onRequestClose={() => setIsDeleteModalVisible(false)} 
      >
        <p className='text'>
          Deseja realmente excluir o profissional <strong>{selectedEmployee ? selectedEmployee.name : ''}</strong>? <br /><br />
          
          Essa ação irá excluir todos os dados e é irreversível.
        </p>

        <div className='buttons'>
          <button onClick={() => setIsDeleteModalVisible(false)}>
            Não
          </button>

          <button onClick={handleDeleteEmployee} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <ContentWrapper className="content">
        <Header>
          <div>
            <Title>Colaboradores | Configurações</Title>
            <Description>
              Mantenha o seu cadastro atualizado para aproveitar ao máximo nossas vantagens e funcionalidades.
            </Description>
          </div>

          <Button 
            variant="tertiary" 
            onClick={() => navigate('/configuracoes/colaboradores/adicionar')}
          >
            Adicionar

            <PlusCircle strokeWidth={1.5} />
          </Button>
        </Header>

        <FiltersContainer>
          <Select
            label="Classificar por"
            options={orderSelectItems}
            value={orderSelectItems.find(option => option.label === selectedOrder)}
            onChange={(option) => {
              setSelectedOrder(option?.label || "Nome");
              setCurrentPage(1);
            }}
            isClearable={false}
          />

          <Select
            label="Exibir últimos"
            options={limitSelectItems}
            value={limitSelectItems.find(option => option.label === String(itemsPerPage))}
            onChange={handleChangeLimit}
            isClearable={false}
          />
        </FiltersContainer>

        <ResultsContainer>
          {isLoading
            ? <Loading />
            : employees.length === 0
              ? 'Não existem colaboradores cadastrados...'
              : employees
                .map(employee => (
                  <ResultCard 
                    key={employee.id} 
                    onClick={() => setSelectedEmployee(employee)}
                  >
                    <ItemContainer>
                      <div>
                        <ItemValue title={employee.name}>
                          {employee.name || '-------'}
                        </ItemValue>
                        <ItemTitle>Nome</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ItemDivider />

                    <ItemContainer>
                      <div>
                        <ItemValue title={employee.position}>
                          {employee.position}
                        </ItemValue>
                        <ItemTitle>Cargo/Posição</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ItemDivider />

                    <ItemContainer>
                      <div>
                        <ItemValue title={`${employee.address.city}/${employee.address.uf}`}>
                          {employee.address.city || '-------'}/{employee.address.uf || '--'}
                        </ItemValue>
                        <ItemTitle>Cidade/UF</ItemTitle>
                      </div>
                    </ItemContainer>

                    <ActionsContainer>
                      <Tooltip content='Editar'>
                        <ActionButton 
                          color={theme.colors.helpers.success}
                          onClick={() => {
                            setSelectedEmployee(null);
                            navigate(`/configuracoes/colaboradores/editar/${employee.id}`);
                          }}
                        >
                          <Memo />
                        </ActionButton>
                      </Tooltip>

                      <Tooltip content='Excluir'>
                        <ActionButton 
                          color={theme.colors.helpers.danger}
                          onClick={() => setIsDeleteModalVisible(true)}
                        >
                          <CancelSquare />
                        </ActionButton>
                      </Tooltip>
                    </ActionsContainer>
                  </ResultCard>
                ))
          }
        </ResultsContainer>
      </ContentWrapper>

      <Footer>
        <div className="content">
          <Pagination>
            <PageButton
              className="prev"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              <ChevronDown />
            </PageButton>

            <Page>{String(currentPage).padStart(2, "0")}</Page>

            <PageButton
              className="next"
              onClick={goToNextPage}
              disabled={currentPage === lastPage}
            >
              <ChevronDown />
            </PageButton>
          </Pagination>
        </div>
      </Footer>
    </Content>
  );
}