import styled from "styled-components";

import { Modal } from "@/components/Modal";
import { Container as ResultCard, ActionsContainer } from "@components/ResultCard/styles";
import { Container as Button } from "@/components/Button/styles";

import { convertToRem } from "@utils/convertToRem";

export const ConsultationReasonModal = styled(Modal)`
  form {
    width: 100%;

    ${Button}[type='submit'] {
      margin-top: ${convertToRem(48)};
      margin-left: auto;
    }
  }
`;

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(40)};
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  gap: ${convertToRem(24)};

  > div {
    flex: 1;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const ResultsContainer = styled.div`
  margin-top: ${convertToRem(60)};

  ${ResultCard} {   
    ${ActionsContainer} {
      max-width: ${convertToRem(160)};
    }

    &:not(:first-child) {
      margin-top: ${convertToRem(10)};
    }
  }
`;