import { useEffect, useState } from "react";

import { Title, Description } from "@components/Header";
import { Button } from "@components/Button";
import { Schedule, ScheduleType } from "@components/Schedule";

import { PlusCircle } from "@assets/images";

import {
  Content,
  ContentWrapper,
  Wrapper,
  AddSchedulesContainer,
  SchedulesContainer,
} from "./styles";

const schedulesData: ScheduleType[] = [
  {
    day: "Segunda-feira",
    startTime: "08:00",
    endTime: "13:15",
  },
  {
    day: "Terça-feira",
    startTime: "08:00",
    endTime: "13:15",
  },
  {
    day: "Quinta-feira",
    startTime: "08:00",
    endTime: "13:15",
  },
];


export function Schedules() {
  const [schedules, setSchedules] = useState<ScheduleType[] | null>(null);

  useEffect(() => {
    setSchedules(schedulesData);
  }, []);

  function addNewSchedule() {
    if (!schedules) return;

    setSchedules([
      ...schedules,
      {
        day: "",
        startTime: "00:00",
        endTime: "00:00",
        isEditing: true,
        isNew: true,
      },
    ]);
  }

  function setEditedSchedule(position: number, value: ScheduleType) {
    if (!schedules) return;

    const updatedSchedule = schedules.map((scheduleItem, index) => {
      if (index !== position) return scheduleItem;

      return { ...scheduleItem, ...value };
    });

    setSchedules(updatedSchedule);
  }

  function removeScheduleItem(position: number) {
    if (!schedules) return;

    const updatedSchedule = schedules.filter((_, index) => index !== position);

    setSchedules(updatedSchedule);
  }

  if (!schedules) return <div />;

  return (
    <Content>
      <ContentWrapper className="content">
        <Title>Horários Disponíveis</Title>
        <Description>
          Mantenha o seu cadastro atualizado para aproveitar ao máximo nossas
          vantagens e funcionalidades.
        </Description>

        <Wrapper>
          <AddSchedulesContainer>
            <h3>Horários</h3>

            <button type="button" onClick={addNewSchedule}>
              <PlusCircle />
              Adicionar
            </button>
          </AddSchedulesContainer>

          <SchedulesContainer>
            {schedules.map((schedule, index) => (
              <Schedule
                key={schedule.day + "--" + index}
                day={schedule.day}
                startTime={schedule.startTime}
                endTime={schedule.endTime}
                isEditing={schedule.isEditing}
                isNew={schedule.isNew}
                onSave={(editedSchedule) =>
                  setEditedSchedule(index, editedSchedule)
                }
                onRemove={() => removeScheduleItem(index)}
              />
            ))}
          </SchedulesContainer>

          <Button>Definir</Button>
        </Wrapper>
      </ContentWrapper>
    </Content>
  );
}
