import HttpClient from './utils/HttpClient';

interface ListUFsResponse {
  id: number;
  estado: string;
  uf: string;
};

interface ListApprovedCitiesRequest {
  estado_id?: string | null;
}

interface ListCitiesByUFResponse {
  id: number;
  cidade: string;
};

interface GetAddressByZipCodeResponse {
  cep: string;
  logradouro: string;
  complemento: string;
  bairro: string;
  localidade: string;
  uf: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
  erro?: boolean;
}

interface ListApprovedCitiesResponse {
  codigo: number;
  dados: Array<{
    cidade_id: number;
    cidade_nome: string;
    estado_uf: string;
    status_nome: string;
    status_cor: string;
  }>;
};

interface ListApprovedUFsResponse {
  codigo: number;
  dados: Array<{
    estado_id: number;
    estado_uf: string;
    estado_nome: string;
  }>;
}

interface ApproveCityResponse {
  codigo: number;
  dados: string;
};

interface ApproveCityRequest {
  cidadeId: number | null;
};

class LocationsService {
  async listUFs() {
    return HttpClient.get<ListUFsResponse[]>('/base/estados');
  }

  async listApprovedUFs() {
    return HttpClient.get<ListApprovedUFsResponse>('/listar-estados');
  }

  async listApprovedCities(estado_id?: string) {
    if (!estado_id) {
      return HttpClient.get<ListApprovedCitiesResponse>('/listar-cidades');
    }
    return HttpClient.get<ListApprovedCitiesResponse>(`/listar-cidades?estado_id=${estado_id}`);
  }

  async approveCity(params: ApproveCityRequest) {
    return HttpClient.post<ApproveCityResponse>('/admin/aprovar-cidades', params);
  }

  async unapproveCity(params: ApproveCityRequest) {
    return HttpClient.post<ApproveCityResponse>('/admin/reprovar-cidades', params);
  }

  async listCitiesByUF(uf: string) {
    if (!['AC', 'AL', 'AP', 'AM', 'BA', 'CE', 'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 'MG', 'PA', 'PB', 'PR', 'PE', 'PI', 'RJ', 'RN', 'RS', 'RO', 'RR', 'SC', 'SP', 'SE', 'TO'].includes(uf.toUpperCase())) {
      throw new Error('Formato inválido! Forneça a sigla da UF.');
    }

    return HttpClient.get<ListCitiesByUFResponse[]>(`/base/cidades/${uf.toUpperCase()}`);
  }

  async getAddressByZipCode(zipCode: number) {
    return HttpClient.externalGet<GetAddressByZipCodeResponse>(`https://viacep.com.br/ws/${zipCode}/json`);
  }
}

export default new LocationsService(); 