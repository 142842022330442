import { useState } from "react";
import { RiEyeLine, RiEyeOffLine } from "react-icons/ri";
import InputMask from "react-text-mask";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CPFCNPJInputMask from "@react-br-forms/cpf-cnpj-mask";

import { InputProps } from "./Input";

import { CPFCNPJMaskedInputProps } from "@/types/cpf-cnpj-input";

import { Container, Error, Icon, Label, VisibilityButton } from "./styles";

export { InputGroup } from "./styles";

export function Input(props: InputProps) {
  const {
    name,
    label,
    type = "text",
    iconLeft,
    iconRight,
    error,
    mask,
    style,
    ...rest
  } = props;

  const [visibility, setVisibility] = useState(false);

  return (
    <Container style={style} isDisabled={props.disabled || false} error={error}>
      {label ? <Label htmlFor={name}>{label}</Label> : null}

      <div>
        {iconLeft ? <Icon>{iconLeft}</Icon> : null}

        {mask ? (
          <InputMask 
            mask={mask} 
            id={name} 
            name={name} 
            type={type}
            {...rest} 
          />
        ) : (
          <input
            id={name}
            name={name}
            type={
              type === "password" ? (visibility ? "text" : "password") : type
            }
            {...rest}
          />
        )}

        {type === "password" ? (
          <VisibilityButton
            type="button"
            onClick={() => setVisibility((prevState) => !prevState)}
          >
            {visibility ? <RiEyeLine /> : <RiEyeOffLine />}
          </VisibilityButton>
        ) : null}

        {iconRight ? <Icon>{iconRight}</Icon> : null}
      </div>
      {error ? <Error>{error}</Error> : null}
    </Container>
  );
}

export function CPFCNPJInput(props: CPFCNPJMaskedInputProps) {
  const {
    name,
    label,
    iconLeft,
    iconRight,
    error,
    mask,
    style,
    ...rest
  } = props;


  return (
    <Container style={style} isDisabled={props.disabled || false} error={error}>
      {label ? <Label htmlFor={name}>{label}</Label> : null}

      <div>
        {iconLeft ? <Icon>{iconLeft}</Icon> : null}

        <CPFCNPJInputMask id={name} name={name} {...rest} />

        {iconRight ? <Icon>{iconRight}</Icon> : null}
      </div>

      {error ? <Error>{error}</Error> : null}
    </Container>
  );
}