import * as PopoverPrimitive from '@radix-ui/react-popover';

import { StyledArrow, StyledContent } from './styles';

interface PopoverContentProps extends PopoverPrimitive.PopperContentProps {
  hideArrow?: boolean;
}

export const Popover = PopoverPrimitive.Root;

export function PopoverTrigger({ children, ...props }: PopoverPrimitive.PopoverTriggerProps) {
  return (
    <PopoverPrimitive.Trigger asChild {...props}>
      {children}
    </PopoverPrimitive.Trigger>
  );
}

export function PopoverContent({ children, hideArrow = false, ...props }: PopoverContentProps) {
  return (
    <StyledContent sideOffset={5} {...props}>
      {children}

      {!hideArrow && <StyledArrow />}
    </StyledContent>
  );
}