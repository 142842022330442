import styled from "styled-components";
import chroma from "chroma-js";

import { Container as DatePicker } from "@components/DatePicker/styles";
import { Container as Button } from "@components/Button/styles";
import { Container as Card, Footer as CardFooter } from "@components/Card/styles";
import { Container as ResultCard, ItemContainer, ResponsiveCard } from "@components/ResultCard/styles";
import { Container as Select } from "@components/Select/styles";

import { convertToRem } from "@utils/convertToRem";

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(40)};
`;

export const SearchContainer = styled.div`
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  gap: ${convertToRem(16)};

  margin-top: ${convertToRem(32)};

  ${DatePicker} {
    max-width: ${convertToRem(270)};
  }

  ${Button} {
    display: flex;
    align-items: center;
    justify-content: space-between;

    min-height: ${convertToRem(40)};
    min-width: ${convertToRem(150)};
    
    max-width: ${convertToRem(160)};
    
    margin-top: auto;
    margin-left: auto;
    padding: 0 ${convertToRem(16)};

    background-color: ${({ theme }) => theme.colors.primary.highlight};
  }

  @media (max-width: 576px) {
    flex-direction: column;

    ${DatePicker} {
      max-width: 100%;
    }
  
    ${Button} {
      margin-left: 0;
    }
  }
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${convertToRem(20)};

  max-width: ${convertToRem(670)};

  margin-top: ${convertToRem(32)};

  ${Card} {
    min-height: ${convertToRem(113)};

    ${CardFooter} {
      height: 0;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;

    max-width: 100%;
  }

  @media (max-width: 576px) {
    grid-template-columns: 1fr;
  }
`;

export const FiltersContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: ${convertToRem(30)};

  margin-top: ${convertToRem(28)};

  ${Select} {
    &:nth-of-type(1) {
      max-width: ${convertToRem(320)};
    }

    &:nth-of-type(2) {
      max-width: ${convertToRem(160)};
    }
  }

  @media (max-width: 576px) {
    flex-direction: column;

    ${Select} {
      max-width: none !important;
    }
  }
`;

export const ResultsContainer = styled.div`
  margin-top: ${convertToRem(30)};

  ${ResultCard} {
    &:not(:first-child) {
      margin-top: ${convertToRem(10)};
    }
    
    > div {
      &:nth-of-type(2),
      &:nth-of-type(3) {
        flex: 2;
      }
    }
  }

  @media (max-width: 992px) {
    ${ResultCard} {
      ${ResponsiveCard};

      > ${ItemContainer} {
        &:nth-of-type(2) ,
        &:nth-of-type(3) {
          > div {
            flex-direction: row;

            strong {
              color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.5).css()}
            }

            span {
              color: ${({ theme }) => theme.colors.neutral.text}
            }
          }
        }
      }
    }
  }
`;

export const Footer = styled.footer`
  width: 100%;
  height: ${convertToRem(100)};

  margin-top: ${convertToRem(60)};

  border-top: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

  .content {
    display: flex;
    align-items: center;
  }
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-left: auto;
`;

export const PageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${convertToRem(40)};
  height: ${convertToRem(40)};

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.primary.base};

  border: ${convertToRem(1)} solid ${({ theme }) => theme.colors.neutral.border};
  border-radius: ${convertToRem(5)};

  &.prev {
    transform: rotate(90deg);
  }

  &.next {
    transform: rotate(270deg);
  }
  
  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;
  }

  > svg {
    width: auto;
    height: ${convertToRem(20)};
    
    stroke-width: 1.2;
  }
`;

export const Page = styled.span`
  margin: 0 ${convertToRem(20)};
  
  color: ${({ theme }) => theme.colors.neutral.text};

  font-weight: 500;
  font-size: ${convertToRem(15)};
  line-height: 1;
`;

