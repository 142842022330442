import { Navigate, Route, Routes } from "react-router-dom";

import { Sidebar } from "@components/Sidebar";
import { Header } from "@components/Header";

import { Reports } from "@pages/Admin/Reports";
import { Prime } from "@pages/Admin/Prime";
import { Clinics } from "@pages/Admin/Clinics";
import { Patients } from "@pages/Admin/Patients";
import { Leads } from "@pages/Admin/Leads";
import { LeadsCampanhas } from "@pages/Admin/LeadsCampanhas";
import { Settings } from "@pages/Admin/Settings";

import { Container, Main } from "./styles";
import { LeadsAfiliados } from "@/pages/Admin/LeadsAfiliados";

export function AdminRoutes() {
  return (
    <Container>
      <Sidebar />

      <Main>
        <Header />

        <Routes>
          <Route path="/" element={<Navigate to="/painel-administrativo/" />} />
          <Route path="painel-administrativo/" element={<Reports />} />
          <Route path="cartoes-prime/" element={<Prime />} />
          <Route path="clinicas/*" element={<Clinics />} />
          <Route path="pacientes/*" element={<Patients />} />
          <Route path="leads/" element={<Leads />} />
          <Route path="leads-afiliados/" element={<LeadsAfiliados />} />
          <Route path="leads-campanhas/" element={<LeadsCampanhas />} />
          <Route path="configuracoes/*" element={<Settings />} />
        </Routes>
      </Main>
    </Container>
  );
}
