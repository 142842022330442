import styled from "styled-components";

import { Container as Button } from "@components/Button/styles";

import { convertToRem } from "@utils/convertToRem";

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  padding-top: ${convertToRem(40)};
`;

export const Main = styled.div`
  display: grid;
  grid-template-columns: 8fr 4fr;
  gap: ${convertToRem(30)};

  margin-top: ${convertToRem(30)};
`;

export const Form = styled.form`
  padding: ${convertToRem(50)} 0 ${convertToRem(100)};

  ${Button} {
    margin-top: ${convertToRem(80)};
    margin-left: auto;
  }
`;

export const Sidebar = styled.aside`
  height: fit-content;

  padding-bottom: ${convertToRem(30)};

  > h2 {
    margin-bottom: ${convertToRem(30)};

    color: ${({ theme }) => theme.colors.neutral.text};

    font-weight: 500;
    font-size: ${convertToRem(20)};
    line-height: 1;
  }
`;