import { useState } from "react";

import { Modal } from "@components/Modal";
import { Description, Title } from "@components/Header";
import { Button } from "@components/Button";

import doctorPrime from '@assets/images/prime-card-doctor.png';

import { Content, ContentWrapper, CardContainer } from "./styles";

export function Affiliate() {
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  async function handlePlanSubscription() {
    alert('Implementar rota API');
    setIsConfirmModalVisible(false);
  }

  return (
    <>
      <Modal 
        visible={isConfirmModalVisible}
        title="Atenção"
        onRequestClose={() => setIsConfirmModalVisible(false)} 
      >
        <p className='text'>
          Deseja assinar o plano de Afiliados? Após confirmada, essa ação é irreversível e não poderá ser desfeita.
        </p>

        <div className='buttons'>
          <button onClick={() => setIsConfirmModalVisible(false)}>
            Não
          </button>

          <button onClick={handlePlanSubscription} className='primary'>
            Sim
          </button>
        </div>
      </Modal>

      <Content>
        <ContentWrapper className="content">
          <Title>Plano Afiliados Clínicas</Title>
          <Description>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Faucibus vestibulum, tellus ac rhoncus.
          </Description>

          <CardContainer image={doctorPrime}>
            <div className="texts">
              <h2>Conheça a Agenda Consulta Afiliados para clínicas e consultórios</h2>
              <h3>Prospecte novos clientes de forma inteligente e ágil.</h3>

              <ul>
                <li>Redução de no-show</li>
                <li>Recebimento em até 30 dias</li>
                <li>Triplique o faturamento de sua clínica</li>
                <li>Sem taxa de adesão ou mensalidades</li>
                <li>Sem burocracia de emitir guias</li>
                <li>Mais de 20.000 vidas na região</li>
                <li>Aumento de pacientes</li>
              </ul>
              
              <Button onClick={() => setIsConfirmModalVisible(true)}>
                Quero assinar
              </Button>
            </div>
          </CardContainer>
        </ContentWrapper>
      </Content>
    </>
  );
}