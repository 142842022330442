import styled from "styled-components";

import { Container as Input } from "@components/Input/styles";
import { Container as Button } from "@components/Button/styles";

import { convertToRem } from "@utils/convertToRem";

export const Container = styled.section`
  display: flex;

  width: 100vw;
  height: 100%;

  min-height: 100vh;
`;

export const Main = styled.main`
  flex: 0 0 ${convertToRem(600)};
  align-self: stretch;

  background-color: ${({ theme }) => theme.colors.neutral.borderLight};
  color: ${({ theme }) => theme.colors.neutral.text};

  .wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;

    width: 100%;
    height: 100%;

    max-width: ${convertToRem(400)};

    margin: 0 auto;
    padding: ${convertToRem(40)} ${convertToRem(30)};
  }

  .logo {
    margin-bottom: auto;
  }

  h1 {
    color: ${({ theme }) => theme.colors.neutral.textDark};
  }

  p {
    margin-top: ${convertToRem(16)};

    line-height: 1.5;
  }

  form {
    margin: ${convertToRem(40)} 0 auto;

    ${Input} {
      margin-bottom: ${convertToRem(24)};
    }

    ${Button} {
      width: 100%;

      svg {
        margin-right: ${convertToRem(8)};
      }

      &:not(:first-child) {
        margin-top: ${convertToRem(16)};
      }
    }
  }

  @media(max-width: 1024px) {
    flex: 1;
  }
`;

export const Banner = styled.div`
  flex: 1 1 auto;

  display: flex;
  align-items: center;
  justify-content: center;
  
  background-color: ${({ theme }) => theme.colors.primary.base};

  @media(max-width: 1024px) {
    display: none;
  }
`;