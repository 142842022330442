import faker from "@faker-js/faker";
import { addDays, set, subDays } from "date-fns";

export type ConsultationStatus = "confirmado" | "pendente" | "cancelado";
export interface Consultation {
  id: string;
  date: Date;
  value: number;
  status: ConsultationStatus;
  due: Date | null;
  isOnline: boolean;
  professional: {
    avatar: string | null;
    name: string;
    specialty: string;
    council: string;
    councilNumber: string;
  };
}

class ConsultationsService {
  private consultations: Consultation[];

  constructor() {
    this.consultations = [];

    for (let i = 0; i < 20; i++) {
      const { datatype, name, internet, random } = faker;

      const randomGender = datatype.number({ min: 0, max: 1, precision: 1 });

      const randomSpecialties = ['Ginecologia e Obstetrícia', 'Fonoaudiologia', 'Pediatria'];
      const randomCouncils = ['CRM', 'CRO', 'CRF'];

      const consultationDate =  datatype.datetime({ 
        min: set(subDays(new Date(), 3), { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 }).getTime(), 
        max: set(addDays(new Date(), 3), { hours: 17, minutes: 0, seconds: 0, milliseconds: 0 }).getTime()
      });

      this.consultations.push({
        id: datatype.uuid(),
        date: consultationDate,
        value: datatype.float({ min: 100, max: 200, precision: 1 }),
        status: random.arrayElement(["confirmado", "pendente", "cancelado"]),
        due: random.arrayElement([addDays(consultationDate, 7), null]),
        isOnline: datatype.boolean(),
        professional: {
          name: `${randomGender === 0 ? 'Dr.' : 'Dra.'} ${name.firstName(randomGender)} ${name.lastName(randomGender)}`,
          avatar: (i + 1) % 3 === 0 ? null : internet.avatar(),
          specialty: random.arrayElement(randomSpecialties),
          council: random.arrayElement(randomCouncils),
          councilNumber: String(datatype.number({ min: 100000, max: 999999 })),
        },
      });
    }
  }

  async listNextConsultations(): Promise<Consultation[]> {
    return new Promise((resolve) => {
      const today = new Date();

      const orderedConsultations = [...this.consultations]
        .filter(consultation => consultation.date.getTime() > today.getTime())
        .sort((a, b) => a.date.getTime() - b.date.getTime())
      ;  

      resolve(orderedConsultations);
    });
  }

  async listAllConsultations(): Promise<Consultation[]> {
    return new Promise((resolve) => {
      resolve(this.consultations);
    });
  }
}

export default new ConsultationsService();
