import { UpdateDataResponse } from './UsersService';

import HttpClient from './utils/HttpClient';

export interface Specialty {
  id: number;
  nome: string;
}

interface ListSpecialtiesResponse {
  codigo: number;
  menssagem: string;
  dados: Array<Specialty[]>;
}

interface UpdateSpecialtiesResponse extends UpdateDataResponse {}

export interface Procedure extends Specialty {
  valor_normal: number;
  valor_prime: number;
  idade_minima: number | null;
}

export interface ProceduresRequest extends Omit<Procedure, "nome" | "id"> {
  procedimento_id: number;
}

interface ListProceduresResponse extends Omit<ListSpecialtiesResponse, "dados"> {
  titulo_pagina: string;
  dados: Array<Procedure[]>;
}

class SpecialtiesService {
  async listFakeSpecialties() {
    return await HttpClient.externalGet<Specialty[]>('http://localhost:8080/specialties?_sort=nome&_order=asc');
  }

  async listSpecialties(clinicTypeId: string) {
    return HttpClient.get<ListSpecialtiesResponse>(`/empresa/especialidades/listagem/${clinicTypeId}`);
  }

  async updateSpecialties(userId: string | number, specialties: string[] | number[]) {
    return HttpClient.post<UpdateSpecialtiesResponse>('/empresa/especialidades/cadastro', {
      clinica_id: userId,
      especialidades: specialties
    });
  }

  async listProcedures(specialtyID: string) {
    return HttpClient.get<ListProceduresResponse>(`/empresa/procedimentos/listagem/${specialtyID}`);
  }

  async updateProcedures(userId: string | number, procedures: ProceduresRequest[]) {
    return HttpClient.post<UpdateSpecialtiesResponse>('/empresa/procedimentos/cadastro', {
      clinica_id: userId,
      procedimentos: procedures
    });
  }
}

export default new SpecialtiesService();