import { MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';
import { RiCloseLine, RiMenuLine } from 'react-icons/ri';
import { FiChevronDown, FiChevronsLeft, FiChevronsRight, FiChevronUp } from 'react-icons/fi';

import { useAuth } from '@contexts/auth';
import { useSidebar } from '@contexts/sidebar';

// Sidebars
import { ClinicSidebar } from './ClinicSidebar';
import { ClinicAdminSidebar } from './ClinicAdminSidebar';
import { ProfessionalSidebar } from './ProfessionalSidebar';
import { AdminSidebar } from './AdminSidebar';
import { PatientSidebar } from './PatientSidebar';
import { AffiliateAdminSidebar } from './AffiliateAdminSidebar';

import { Tooltip } from '@components/Tooltip';

import { AgendaConsulta, AgendaConsultaExpand, Bell } from '@assets/images';
import { Popover, PopoverTrigger } from '@components/Popover';
import { NotificationContent } from '@components/Header/styles';

import { RoutesPerUser } from '@routes/index';

import { Container, DropdownLink, ExpandButton, Logo, MenuContainer, MenuItem, MenuLink, MenuMobileContainer, MenuButton } from './styles';

const sidebarPerUserProfile: RoutesPerUser = {
  "clinica": <ClinicSidebar />,
  "clinica_admin": <ClinicAdminSidebar />,
  "profissional": <ProfessionalSidebar />,
  "admin": <AdminSidebar />,
  "patient": <PatientSidebar />,
  "enterprise": <div />,
  "promotor": <div />, 
  "promotor_admin": <AffiliateAdminSidebar />, 
};

interface SidebarData {
  title: string;
  path: string;
  icon: JSX.Element;
  isDisabled?: boolean;
  subnav?: Array<{
    title: string;
    path: string;
    isDisabled?: boolean;
  }>;
}

export function SidebarMenuItem(props: SidebarData) {
  const { isOpened, setIsOpened, activeDropdown, setActiveDropdown } = useSidebar();
  
  function handleMenuLinkClick(event: MouseEvent) {
    
    if (!props.subnav) {
      setIsOpened(false);
      return;
    } else {
      event.preventDefault();
      setIsOpened(true);
    }
    
    if (isOpened) event.preventDefault();
    setActiveDropdown(prevState => isOpened && prevState === props.path ? '' : props.path);
  }

  if (props.isDisabled) return null;
  
  return (
    <MenuItem>
      <MenuLink 
        to={props.path} 
        onClick={handleMenuLinkClick}
        className={props.subnav ? 'dropdown' : ''}
      >
        {props.icon}

        <span>
          {props.title}

          {!!props.subnav
            ? activeDropdown === props.path 
              ? <FiChevronUp /> 
              : <FiChevronDown />
            : null
          }
        </span>
      </MenuLink>
      
      {!!props.subnav && activeDropdown === props.path && 
        props.subnav
          .filter(i => !i.isDisabled)
          .map(item => (
            <DropdownLink 
              key={item.path} 
              to={item.path}
              onClick={() => setIsOpened(false)}
            >
              {item.title}
            </DropdownLink>
          ))
      }
    </MenuItem>
  );
}

export function Sidebar() {
  const { selectedProfile } = useAuth();
  const { isOpened, setIsOpened } = useSidebar();
  const navigate = useNavigate();

  if (!selectedProfile) {
    navigate('/selecionar-perfil');
    return null;
  }

  return (
    <>
      <Container className={isOpened ? 'expanded' : ''}>
        <Logo to="/">
          {isOpened ? <AgendaConsultaExpand /> : <AgendaConsulta />}
        </Logo>

        <Tooltip 
          content={isOpened ? 'Ver menos' : 'Ver mais'} 
          side='right'
        >
          <ExpandButton 
            type='button'
            onClick={() => setIsOpened(prevState => !prevState)}
          >
            {isOpened ? <FiChevronsLeft /> : <FiChevronsRight />}
          </ExpandButton>
        </Tooltip>
        
        <MenuContainer>
          {sidebarPerUserProfile[selectedProfile.alias]}
        </MenuContainer>

        {/* --- Header versão mobile --- */}
        <Popover>
          <PopoverTrigger>
            <MenuButton
              className='notification has-notification'
              type='button'
            >
              <Bell />
            </MenuButton>
          </PopoverTrigger>

          <NotificationContent>
            <header>
              <h4>Notificações</h4>

              <button>Ler tudo</button>
            </header>

            <ul>
              <li>Notificações 1</li>
              <li>Notificações 2</li>
              <li>Notificações 3</li>
              <li>Notificações 4</li>
            </ul>
          </NotificationContent>
        </Popover>
        
        <MenuButton
          type='button'
          onClick={() => setIsOpened(prevState => {
            if (prevState === false) window.scrollTo(0, 0);
            
            return !prevState;
          })}
        >
          {isOpened ? <RiCloseLine /> : <RiMenuLine />}
        </MenuButton>
      </Container>

      {/* --- Sidebar versão mobile --- */}
      <MenuMobileContainer className={isOpened ? 'opened' : ''}>
        {sidebarPerUserProfile[selectedProfile.alias]}
      </MenuMobileContainer>
      {/* --- Sidebar versão mobile --- */}
      {/* --- Header versão mobile --- */}
    </>
  );
}
