import styled from "styled-components";
import chroma from "chroma-js";

import { convertToRem } from "@utils/convertToRem";

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.neutral.borderLight};
`;

export const Header = styled.header`
  width: 100vw;
  height: ${convertToRem(80)};

  margin-bottom: ${convertToRem(60)};

  background-color: ${({ theme }) => theme.colors.neutral.white};

  box-shadow: 0 ${convertToRem(4)} ${convertToRem(10)} ${({ theme }) => chroma(theme.colors.neutral.border).alpha(0.5).css()};

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  a {
    font-weight: 500;

    color: ${({ theme }) => theme.colors.primary.base};
  }
`;

export const Wrapper = styled.div`
  h1, h2, h3 {
    font-weight: 400;
  }

  h1 {
    margin-bottom: ${convertToRem(48)};

    font-size: ${convertToRem(36)};

    color: ${({ theme }) => theme.colors.neutral.textDark};
  }

  h2 {
    margin: ${convertToRem(48)} 0 ${convertToRem(24)};
  }

  h3 {
    margin: ${convertToRem(24)} 0 ${convertToRem(16)};
  }

  p, ul, ol {
    margin-top: ${convertToRem(16)};

    line-height: 1.5;

    color: ${({ theme }) => theme.colors.neutral.text};

    text-align: justify;
    text-justify: inter-word;
  } 
  
  ul {
    list-style-type: initial;
  }

  ul, ol {
    padding-left: ${convertToRem(48)};
  }

  a {
    color: ${({ theme }) => theme.colors.primary.base};

    text-decoration: underline;
  }
`;

export const Footer = styled.footer`
  width: 100vw;
  height: ${convertToRem(40)};

  margin-top: ${convertToRem(60)};

  background-color: ${({ theme }) => theme.colors.neutral.white};

  box-shadow: 0 ${convertToRem(-4)} ${convertToRem(10)} ${({ theme }) => chroma(theme.colors.neutral.border).alpha(0.5).css()};

  .content {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;