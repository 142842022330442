import { BrowserRouter } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ThemeProvider } from 'styled-components';
import { Provider as TooltipProvider } from "@radix-ui/react-tooltip";
import { ToastContainer } from 'react-toastify';

import { AuthProvider } from '@contexts/auth';
import { SearchProvider } from "@contexts/search";
import { SidebarProvider } from "@contexts/sidebar";

import { AppRoutes } from "./routes";

import { ScrollToTop } from "@utils/ScrollToTop";

import theme from '@styles/themes/default';
import 'react-toastify/dist/ReactToastify.css';
import GlobalStyles from '@styles/global';

import thumbnail from '@assets/images/thumbnail-agendaconsulta.png';

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <SearchProvider>
          <SidebarProvider>
            <ThemeProvider theme={theme}>
              <TooltipProvider delayDuration={400}>
                <ToastContainer />

                <GlobalStyles />

                <ScrollToTop />

                <Helmet>
                  <meta property="og:image" content={thumbnail} />
                  <meta property="twitter:image" content={thumbnail} />
                </Helmet>

                <AppRoutes />
              </TooltipProvider>
            </ThemeProvider>
          </SidebarProvider>
        </SearchProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
