import { RiBarChartLine, RiHistoryLine } from 'react-icons/ri';

import { SidebarMenuItem } from '.';

export function ProfessionalSidebar() {
  return (
    <>
      <SidebarMenuItem
        path='/'
        icon={<RiHistoryLine />}
        title='Histórico de Consultas'
      />

      <SidebarMenuItem
        path='/relatorios'
        icon={<RiBarChartLine />}
        title='Relatórios'
      />
    </>
  );
}