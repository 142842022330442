import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { format } from "date-fns";

import { useAuth } from "@contexts/auth";

import { Modal } from "@components/Modal";
import { Tooltip } from "@components/Tooltip";
import { Rating } from "@components/Rating";
import { TimeScheduling } from "@components/TimeScheduling";
import { Button } from "@components/Button";
import { Checkbox } from "@components/Checkbox";
import { Select } from "@components/Select";

import { Professionals } from "@services/ProfessionalsService";
import DependentsService from "@services/DependentsService";

import nullAvatar from "@assets/images/null-avatar.jpg";
import { Share, User, Users, VideoCamera, Whatsapp } from "@assets/images";

import { formatToBRL } from "@utils/formatToBRL";
import { clearPhoneNumber } from "@utils/clearPhoneNumber";
import { convertToRem } from "@utils/convertToRem";

import { OptionTypeBase } from "@components/Select/Select";

import {
  Avatar,
  Container,
  Content,
  Flag,
  Header,
  HeaderButton,
  InfoDescription,
  InfoTitle,
  ModalContent,
  Name,
  ProfessionalInfo,
  ReadMore,
  SchedulingContainer,
} from "./styles";

type ProfessionalCardProps = {
  data: Professionals;
};

export function ProfessionalCard({ data }: ProfessionalCardProps) {
  const professionalPath = `/profissionais/${data.id}`;

  const { selectedAccount } = useAuth();

  const [selectedLocal, setSelectedLocal] = useState<"presential" | "online">("presential");
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);

  const [isConsultationModalVisible, setIsConsultationModalVisible] = useState(false);
  const [isConsultationForDependent, setIsConsultationForDependent] = useState(false);

  const [dependents, setDependents] = useState<OptionTypeBase[]>([]);
  const [selectedDependent, setSelectedDependent] = useState<OptionTypeBase | null>(null); 
  const [dependentError, setDependentError] = useState('');

  // Setting dependents
  useEffect(() => {
    (async () => {
      try {
        const dependentsData = await DependentsService.listDependents();

        setDependents(dependentsData.map(dependent => ({
          label: dependent.name,
          value: dependent.id,
        })));
      } catch (error) {
        toast.error('Não foi possível carregar dados dos dependentes!');

        console.log(error);
      }
    })()
  }, []);

  function handleShareButtonClick() {
    const { origin } = window.location;

    const professionalLink = `${origin}/${professionalPath}`;
    navigator.clipboard.writeText(professionalLink);

    toast.success("Link de compartilhamento copiado com sucesso!");
  }

  function handleChangeSelectedLocal(selectedLocal: "presential" | "online") {
    setSelectedLocal(selectedLocal);
    setSelectedDate(null);
  }

  function handleSubmit() {
    if (selectedLocal === null) return alert("Selecione o local!");
    if (selectedDate === null) return alert("Selecione a data e hora da consulta!");

    setIsConsultationModalVisible(true);
  }

  function handleConfirmConsultation() {
    if (isConsultationForDependent && selectedDependent === null) {
      setDependentError('Selecione um dependente');
      return;
    }

    setDependentError('');
    setSelectedDate(null);
    setSelectedLocal("presential");

    setIsConsultationModalVisible(false);
    
    toast.success(`Consulta ${isConsultationForDependent ? `de ${selectedDependent?.label}` : ''} agendada com sucesso!`);
  }

  return (
    <Container>
      <Modal 
        visible={isConsultationModalVisible}
        disablePadding
        onRequestClose={() => setIsConsultationModalVisible(false)} 
        style={{
          maxWidth: convertToRem(700),
        }}
      >
        <ModalContent>
          <header>
            <div className="info">
              <img src={data.avatar || nullAvatar} alt={data.name} className="avatar" />

              <div>
                <h3>{data.name}</h3>
                <h4 className="primary">{data.specialty} | {data.council} {data.councilNumber}</h4>
              </div>

              <span className="separator" />

              <div>
                <h3>{data.healthInsurance}</h3>
                <h4>Presencial{data.isAttendOnline && ', Telemedicina'}</h4>
              </div>

              <span className="separator" />

              <div>
                <h3>{formatToBRL(data.normalValue)} | {formatToBRL(data.primeValue)}</h3>
                <h4>Preço Normal | Prime</h4>
              </div>
            </div>

            <div className="address">
              <div>
              <h4>Endereço</h4>
              <p>
                {`${data.address.street}, ${data.address.number}${
                data.address.complement ? ` - ${data.address.complement}` : ""}`}
                .<br />
                {`${data.address.zipCode} - ${data.address.city}, ${data.address.state}`}
              </p>
              </div>

              <Button variant="secondary">Ver horários</Button>
            </div>
          </header>

          <footer>
            <h2>Confirmação</h2>
            <p>Confirma agendamento para o dia <span>{selectedDate ? format(selectedDate, "dd/MM/yy 'às' HH'h'mm") : ''}</span>?</p>

            <Checkbox
              label="Essa consulta é para outra pessoa"
              name="isConsultationForDependent"
              checked={isConsultationForDependent} 
              onChange={(e) => setIsConsultationForDependent(e.target.checked)}
            />

            {isConsultationForDependent && (
              <form className="dependent-data">
                <Select 
                  label="Dependente"
                  name="dependent"
                  options={dependents}
                  value={dependents.find(dependent => dependent === selectedDependent)}
                  onChange={(selectedOption) => {
                    if (selectedOption !== null) setDependentError('');
                    setSelectedDependent(selectedOption);
                  }}
                  error={dependentError}
                />
              </form>
            )}

            {!selectedAccount?.isPrime && (
              <div className="prime-promo">
                <p>Seja um cliente Prime e tenha até 70% de desconto em consultas médicas, terapias, dentistas, exames e farmácias.</p>

                <Button>Assinar</Button>
              </div>
            )}

            <div className="buttons-container">
              <Button 
                variant="secondary"
                onClick={() => setIsConsultationModalVisible(false)}
              >
                Cancelar
              </Button>

              <Button 
                variant="primary"
                onClick={handleConfirmConsultation}
              >
                Sim, concordo
              </Button>
            </div>
          </footer>
        </ModalContent>
      </Modal>

      <Header>
        {selectedAccount?.isPrime && (
          <HeaderButton as="a" href={`https://api.whatsapp.com/send?phone=${clearPhoneNumber(data.whatsapp)}`} target="_blank" className="whatsapp">
            <Whatsapp />
            Falar no WhatsApp
          </HeaderButton>
        )}

        <HeaderButton className="share" onClick={handleShareButtonClick}>
          <Share />
          Compartilhar
        </HeaderButton>
      </Header>

      <Avatar src={data.avatar || nullAvatar} alt={data.name} />

      <Content>
        <ProfessionalInfo>
          <Name>
            <div className="info">
              <h4>{data.name}</h4>
              <h5>
                {data.specialty} | {data.council} {data.councilNumber}
              </h5>
            </div>

            <Tooltip 
              content={`Avaliação: ${data.rating.general.toFixed(2)}`} 
              side="bottom"
            >
              <span>
                <Rating rate={data.rating.general} readonly />
              </span>
            </Tooltip>
          </Name>

          <InfoDescription>{data.healthInsurance}</InfoDescription>

          <div className="flags">
            <Flag>{data.isPrime ? "Prime" : "Não Prime"}</Flag>
            <Flag>
              {data.isAttendOnline ? "Atende Online" : "Atendimento Presencial"}
            </Flag>
          </div>

          <InfoTitle>Endereço</InfoTitle>
          <InfoDescription>
            {`${data.address.street}, ${data.address.number}${
              data.address.complement ? ` - ${data.address.complement}` : ""
            }`}
            .<br />
            {`${data.address.zipCode} - ${data.address.city}, ${data.address.state}`}
          </InfoDescription>

          <InfoTitle>Preços</InfoTitle>
          <InfoDescription className="price">
            Normal <strong>{formatToBRL(data.normalValue)}</strong>
            Prime <strong>{formatToBRL(data.primeValue)}</strong>
          </InfoDescription>

          <InfoTitle>Cupom</InfoTitle>
          <InfoDescription>
            <strong>{data.coupon ? data.coupon : "---"}</strong>
          </InfoDescription>

          <ReadMore to={professionalPath}>
            <User />
            Saiba mais sobre o médico
          </ReadMore>
        </ProfessionalInfo>

        <SchedulingContainer>
          <h3>Defina Data e horário</h3>

          <div className="local-buttons">
            <Button
              variant={selectedLocal === "presential" ? "primary" : "secondary"}
              onClick={() => handleChangeSelectedLocal("presential")}
            >
              <Users />
              No Local
            </Button>

            <Button
              variant={selectedLocal === "online" ? "primary" : "secondary"}
              onClick={() => handleChangeSelectedLocal("online")}
            >
              <VideoCamera />
              Telemedicina
            </Button>
          </div>
          
          <TimeScheduling 
            consultationDuration={data.schedule.duration}
            schedule={data.schedule[selectedLocal]}
            specials={data.schedule.specials[selectedLocal]}
            blockedTimes={data.consultations}
            selectedDate={selectedDate}
            onChangeSelectedDate={(selectedDate) => setSelectedDate(selectedDate)}
          />

          <Button 
            disabled={!selectedLocal || !selectedDate}
            onClick={handleSubmit}
          >
            Confirmar
          </Button>
        </SchedulingContainer>
      </Content>
    </Container>
  );
}
