import { Navigate } from 'react-router-dom';

import { Title, Description } from '@components/Header';
import { Tabs } from '@components/Tabs';

// Tabs
import { EditRegisterTab } from './EditRegisterTab';
import { EditPasswordTab } from './EditPasswordTab';
import { EditLogoTab } from './EditLogoTab';

import { Content, ContentWrapper } from './styles';

export function Profile() {
  return (
    <Content>
      <ContentWrapper className="content">
        <Title>Cadastro</Title>
        <Description>Mantenha o seu cadastro atualizado para aproveitar ao máximo nossas vantagens e funcionalidades.</Description>

        <Tabs 
          tabs={[
            { path: '/', element:  <Navigate to='editar-cadastro' />, },
            { path: 'editar-cadastro', element:  <EditRegisterTab />, title: 'Editar Cadastro', },
            { path: 'alterar-senha', element:  <EditPasswordTab />, title: 'Alterar Senha', },
            { path: 'alterar-logotipo', element:  <EditLogoTab />, title: 'Alterar Logotipo', },
          ]}
        />
      </ContentWrapper>
    </Content>
  );
}