import { Navigate, Route, Routes } from "react-router-dom";

import { ExamsList } from "./ExamsList";
import { Schedules } from "./Schedules";


export function Exams() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="listagem/" />} />
      <Route path="listagem/" element={<ExamsList />} />
      <Route path="importar/" element={<Navigate to="/exames/listagem/" />} />
      <Route path="exportar/" element={<Navigate to="/exames/listagem/" />} />
      <Route path="horarios/" element={<Schedules />} />
    </Routes>
  );
}