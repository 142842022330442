import { useEffect, useRef, useState } from 'react';

import { Description, Title } from '@components/Header';
import { DatePicker } from '@components/DatePicker';
import { Select } from '@components/Select';
import { Button } from '@components/Button';
import ResultCard, { ItemContainer, ItemDivider, ItemTitle, ItemValue } from '@components/ResultCard';

import { ChevronDown } from '@assets/images';
import avatarUrl from '@assets/images/doctor-avatar.jpg';

import { formatToBRL } from '@utils/formatToBRL';

import { Content, ContentWrapper, DownButton, GenerateReportContainer, ReportContainer, ResultsContainer } from './styles';

import { convertToRem } from '@utils/convertToRem';


const doctorsData = {
  doctorName: "Dr. Juliano Luan",
  doctorAvatar: avatarUrl,
  doctorCRM: "123.5",
  exam: "Ultrassonografia Toráxica",
  patient: "Pedro Paulo João",
  value: 300,
};

export function GeneralMetrics() {
  const generateReportRef = useRef<HTMLFormElement>(null);

  const [doctors, setDoctors] = useState<Array<typeof doctorsData>  | null>(null);
  const [formHeight, setFormHeight] = useState<number | null>(null);

  const populateDoctors = () => {
    setDoctors(Array(10).fill(doctorsData));
  };

  useEffect(() => {
    if (!generateReportRef.current) return;

    const formHeight = generateReportRef.current.getBoundingClientRect().height;
    setFormHeight(formHeight);
  }, []);

  return (
    <Content>
      <ContentWrapper className="content">
        <Title>Métricas Gerais</Title>
        <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Description>

        <ReportContainer
          style={{
            maxHeight: formHeight ? convertToRem(formHeight) : "auto",
          }}
        >
          <GenerateReportContainer ref={generateReportRef}>
            <h3>Gerar Relatório</h3>

            <DatePicker
              label="Período"
              name="Period"
              onChange={(e) => console.log(e)}
            />

            <Select
              label="Comparecimento"
              options={[{ value: "1", label: "Todos" }]}
            />

            <Select
              label="Profissional"
              options={[{ value: "1", label: "Dra. Bianca Estevez" }]}
            />

            <Select
              label="Convênio"
              options={[{ value: "1", label: "Todos" }]}
            />

            <Select
              label="Avaliação Gratuita"
              options={[{ value: "1", label: "Todos" }]}
            />

            <Select
              label="Retornos"
              options={[{ value: "1", label: "Todos" }]}
            />

            <Button onClick={populateDoctors}>Gerar</Button>
          </GenerateReportContainer>

          <ResultsContainer style={{ display: doctors ? "block" : "none" }}>
            {doctors ? (
              doctors.map((doctor, index) => (
                <ResultCard key={`${doctor.doctorCRM}--${index}`}>
                  <ItemContainer>
                    <img src={doctor.doctorAvatar} alt={doctor.doctorName} />

                    <div>
                      <ItemValue>{doctor.doctorName}</ItemValue>
                      <ItemTitle>CRM {doctor.doctorCRM}</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ItemDivider />

                  <ItemContainer>
                    <div>
                      <ItemValue>{doctor.patient}</ItemValue>
                      <ItemTitle>{doctor.exam}</ItemTitle>
                    </div>
                  </ItemContainer>

                  <ItemDivider />

                  <ItemContainer>
                    <div>
                      <ItemValue>{formatToBRL(doctor.value)}</ItemValue>
                      <ItemTitle>Valor</ItemTitle>
                    </div>
                  </ItemContainer>

                  <DownButton>
                    <ChevronDown />
                  </DownButton>
                </ResultCard>
              ))
            ) : (
              <div />
            )}
          </ResultsContainer>
        </ReportContainer>
      </ContentWrapper>
    </Content>
  );
}