import styled from "styled-components";

import { Container as Button } from "@components/Button/styles";
import { InputGroup } from "@components/Input/styles";
import { Container as TimeScheduling, Day, DayWeek, Time } from "@components/TimeScheduling/styles";
import { Modal } from "@components/Modal";

import { convertToRem } from "@utils/convertToRem";

export const ScheduleModal = styled(Modal)`
  max-width: ${convertToRem(700)};

  form {
    width: 100%;

    > ${Button} {
      width: 100%;

      margin-top: ${convertToRem(24)};
    }
  }

  @media (max-width: 576px) {
    ${InputGroup} {
      grid-template-columns: 1fr;
    }
  }
`;

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(40)};
`;

export const PageContent = styled.div`
  display: grid;
  grid-template-columns: ${convertToRem(356)} 1fr;
  gap: ${convertToRem(30)};

  margin-top: ${convertToRem(28)};
  padding-bottom: ${convertToRem(80)};

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

export const Sidebar = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${convertToRem(24)};

  padding: ${convertToRem(24)} ${convertToRem(16)};

  background-color: ${({ theme }) => theme.colors.neutral.white};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.borderDark};
  border-radius: ${convertToRem(8)};

  .react-datepicker-popper {
    position: initial !important;
    transform: translate(0, 0) !important;
  }

  > ${Button} {
    width: 100%;
  }
`;

export const TimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: ${convertToRem(48)};

  background-color: ${({ theme }) => theme.colors.neutral.white};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.borderDark};
  border-radius: ${convertToRem(8)};

  ${TimeScheduling} {
    margin-bottom: auto;

    ${Day}, ${DayWeek}, ${Time} {
      font-size: ${convertToRem(12)};
    }
  
    ${Time} {
      width: ${convertToRem(54)};
      height: ${convertToRem(25)};
    }
  }

  > ${Button} {
    margin-top: ${convertToRem(48)};
  }

  @media (max-width: 992px) {
    padding: ${convertToRem(48)} ${convertToRem(24)};
  }
`;