import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { toast } from "react-toastify";

import { Loading } from "@components/Loading";
import { Button } from "@components/Button";
import { Description, Title } from "@components/Header";

import CardsService, { Card } from "@services/CardsService";

import { AgendaConsulta, Airplane, Friends, HealthHouse, PlusCircle } from "@assets/images";

import { formatCPFCNPJ } from "@utils/formatCPFCNPJ";

import { Content, ContentWrapper, LoadingContainer, PageContent, PlanCard, PlanCards, SidebarCard, Tab, Tabs, TitleContainer } from "./styles";

export function Cards() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [cards, setCards] = useState<Card[]>([]);

  const [selectedTab, setSelectedTab] = useState<'all' | 'owner' | 'dependents'>('all');

  const filteredCards = cards.filter(card => {
    switch (selectedTab) {
      case 'owner': return !card.isDependent;
      case 'dependents': return card.isDependent;
      default: return true;
    }
  })

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const cardsData = await CardsService.listCards();
        setCards(cardsData);

      } catch (error) {
        toast.error("Não foi possível carregar os dados das carteirinhas");

        console.log(error);
      } finally {
        setIsLoading(false);
      }
    })()
  }, []);
  
  if (isLoading) {
    return (
      <LoadingContainer>
        <Loading />
      </LoadingContainer>
    );
  }

  return (
    <Content>
      <ContentWrapper className="content">
        <TitleContainer>
          <div>
            <Title>Carteirinha Digital</Title>
            <Description>Mantenha o seu cadastro atualizado para aproveitar ao máximo nossas vantagens e funcionalidades.</Description>
          </div>

          <Button variant="tertiary" onClick={() => navigate('/dependentes/adicionar/')}>
            Adicionar dependente

            <PlusCircle strokeWidth={1.5} />
          </Button>
        </TitleContainer>

        <Tabs>
          <Tab 
            className={selectedTab === "all" ? 'active' : ''}
            onClick={() => setSelectedTab('all')}
          >
            Ver todos
          </Tab>

          <Tab 
            className={selectedTab === "owner" ? 'active' : ''}
            onClick={() => setSelectedTab('owner')}
          >
            Titular
          </Tab>
          
          <Tab 
            className={selectedTab === "dependents" ? 'active' : ''}
            onClick={() => setSelectedTab('dependents')}
          >
            Dependentes
          </Tab>
        </Tabs>

        <PageContent>
          <PlanCards>
            {filteredCards.map(card => (
              <PlanCard isOwner={!card.isDependent}>
                <div>
                  <div>
                    <h5>Nome</h5>
                    <h4>{card.name}</h4>
                  </div>

                  <AgendaConsulta className="logo" />
                </div>

                <div className="number">
                  <span>{formatCPFCNPJ(String(card.number))}</span>
                  <strong>{format(card.due, 'MM/yy')}</strong>
                </div>

                <AgendaConsulta className="logo-bg" />
              </PlanCard>
            ))}
          </PlanCards>

          <div>
            <SidebarCard>
              <Friends />

              <h3>Indique para um amigo</h3>
              <p>Indique amigos para aproveitar as vantagens da Agenda Consulta e ganhar descontos imperdíveis.</p>

              <Button>
                Indicar amigos
                <Airplane />
              </Button>
            </SidebarCard>

            <SidebarCard>
              <HealthHouse />

              <h3>Indique uma clínica</h3>
              <p>Gostaria de indicar uma clínica que não faz parte da Agenda Consulta?</p>

              <Button>
                Indicar clínica
                <Airplane />
              </Button>
            </SidebarCard>
          </div>
        </PageContent>
      </ContentWrapper>
    </Content>
  );
}