import styled from "styled-components";
import chroma from "chroma-js";

import { Content as Card, Footer as CardFooter } from "@components/Card/styles";

import { convertToRem } from "@utils/convertToRem";

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(40)};

  h2 {
    margin-bottom: ${convertToRem(30)};

    color: ${({ theme }) => theme.colors.neutral.text};

    font-weight: 500;
    font-size: ${convertToRem(20)};
  }
`;

export const CardsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: ${convertToRem(20)};

  margin-top: ${convertToRem(60)};

  ${Card} {
    height: ${convertToRem(110)};
    min-height: ${convertToRem(110)};

    ${CardFooter} {
      display: none;
    }
    
    &.income {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
`;

export const FutureIncome = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;

  padding: ${convertToRem(20)};

  background-color: ${({ theme }) => theme.colors.helpers.success};
  color: ${({ theme }) => theme.colors.neutral.white};

  border-bottom-left-radius: ${convertToRem(5)};
  border-bottom-right-radius: ${convertToRem(5)};

  span {
    font-weight: 500;
    font-size: ${convertToRem(12)};
  }

  strong {
    font-weight: 700;
    font-size: ${convertToRem(15)};
  }
`;

export const AccessContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: ${convertToRem(30)};

  width: 100%;

  margin-top: ${convertToRem(60)};

  .access-grid {
    display: grid;
    grid-template-rows: repeat(4, ${convertToRem(80)});
    gap: ${convertToRem(20)};
  }

  .nav-btns {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: ${convertToRem(40)};
    
    button:first-child {
      margin-right: ${convertToRem(20)};

      svg {
        transform: rotate(180deg);
      }
    }
  }
`;

export const AccessCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  padding: 0 ${convertToRem(20)};

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.text};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
  border-radius: ${convertToRem(5)};

  img {
    width: ${convertToRem(50)};
    height: ${convertToRem(50)};

    margin-right: ${convertToRem(16)};

    border-radius: 50%;
  }

  h5 {
    font-weight: 500;
    font-size: ${convertToRem(15)};
  }

  strong {
    color: ${({ theme }) => theme.colors.primary.highlight};

    font-weight: 500;
    font-size: ${convertToRem(10)};
  }

  &.span-card {
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: ${({ theme }) => theme.colors.neutral.text};

    padding: 0 ${convertToRem(50)};

    > div {
      h5 {
        font-weight: 400;
        font-size: ${convertToRem(13)};
        line-height: 1.8;
      }
  
      > div {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
  
      h4 {
        font-weight: 500;
        font-size: ${convertToRem(30)};
      }
      
      span {
        display: flex;
        align-items: center;
        justify-content: center;

        margin-left: ${convertToRem(12)};
        padding: ${convertToRem(6)} ${convertToRem(12)};

        background-color: ${({ theme }) => theme.colors.neutral.border};

        font-size: ${convertToRem(10)};

        border-radius: ${convertToRem(3)};
      }
    }

    > svg {
      width: auto;
      height: ${convertToRem(50)};

      color: ${({ theme }) => theme.colors.helpers.info};
    }

    &:first-child {
      grid-row: 1 / 3;
    }

    &:last-child {
      grid-row: 3 / 5;
    }
  }
`;

export const ClinicsContainer = styled.div`
  width: 100%;
  
  margin-top: ${convertToRem(90)};

  .clinics-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(3, ${convertToRem(80)});
    gap: ${convertToRem(30)};
  }
`;

export const ClinicCard = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding: 0 ${convertToRem(20)};

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.neutral.text};

  border: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};
  border-radius: ${convertToRem(5)};

  > div {
    flex: 1;
  }

  h4 {
    display: -webkit-box;

    font-size: ${convertToRem(15)};

    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  h5 {
    margin-top: ${convertToRem(4)};

    font-size: ${convertToRem(13)};

    opacity: 0.5;
  }

  button {
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${convertToRem(40)};
    height: ${convertToRem(40)};

    margin-left: ${convertToRem(16)};

    background-color: ${({ theme }) => theme.colors.primary.lightest};
    color: ${({ theme }) => theme.colors.primary.base};

    border: 0;

    > svg {
      width: auto;
      height: ${convertToRem(24)};
    }
  }
`;

export const Footer = styled.footer`
  width: 100%;
  height: ${convertToRem(100)};

  margin-top: ${convertToRem(60)};

  border-top: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

  .content {
    display: flex;
    align-items: center;
  }
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-left: auto;
`;

export const PageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${convertToRem(40)};
  height: ${convertToRem(40)};

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.primary.base};

  border: ${convertToRem(1)} solid ${({ theme }) => theme.colors.neutral.border};
  border-radius: ${convertToRem(5)};

  &.prev {
    transform: rotate(90deg);
  }

  &.next {
    transform: rotate(270deg);
  }
  
  &:disabled {
    background-color: ${({ theme }) => chroma(theme.colors.neutral.border).alpha(0.3).css()};

    cursor: not-allowed;
  }

  > svg {
    width: auto;
    height: ${convertToRem(20)};
    
    stroke-width: 1.2;
  }
`;

export const Page = styled.span`
  margin: 0 ${convertToRem(20)};
  
  color: ${({ theme }) => theme.colors.neutral.text};

  font-weight: 500;
  font-size: ${convertToRem(15)};
  line-height: 1;
`;

