import { Description, Title } from '@components/Header';

import { Content, ContentWrapper, PageContent } from './styles';

export function Reports() {
  return (
    <Content>
      <ContentWrapper className='content'>
        <Title>Relatórios</Title>
        <Description>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Diam massa sapien velit ipsum, ac.
        </Description>

        <PageContent>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio excepturi optio eos eius accusamus pariatur quaerat rem temporibus aut animi. Veritatis praesentium eos incidunt ea quidem assumenda ullam sunt voluptatum. Vitae illum temporibus iste repellendus voluptatum nostrum corrupti deserunt, explicabo adipisci delectus, pariatur doloremque est sunt aliquid voluptate doloribus odio?
        </PageContent>
      </ContentWrapper>
    </Content>
  );
}