import { useAuth } from "@contexts/auth";

import { Button } from "@components/Button";

import { AgendaConsultaExpand, Check, User } from "@assets/images";

import { Container, Wrapper } from "./styles";

export function SelectProfile() {
  const { user, changeSelectedProfile, selectedProfile, handleSignOut } = useAuth();

  if (!user) return null;

  return (
    <Wrapper>
      <Container className="content">
        <header>
          <AgendaConsultaExpand />

          <button onClick={() => handleSignOut()}>Sair</button>
        </header>

        <h1>Selecione um perfil</h1>

        <ul>
          {user.profiles.map(profile => (
            <li key={profile.id}>
              <span className="icon">
                <User />
              </span>
              
              <h3>{profile.name}</h3>
              <p>{profile.description}</p>

              <Button
                variant={selectedProfile?.alias === profile.alias ? "secondary" : "primary"}
                onClick={() => changeSelectedProfile(profile)}
              >
                {selectedProfile?.alias === profile.alias
                  ? (
                    <>
                      <Check />
                      Selecionado
                    </>
                  )
                  : 'Selecionar'
                }
              </Button>
            </li>
          ))}

          {/* <li>
            <span className="icon">
              <User />
            </span>
            
            <h3>Afiliado</h3>
            <p>Área de afiliados</p>

            <Button
              variant={selectedProfile?.alias === 'promotor' ? "secondary" : "primary"}
              onClick={() => changeSelectedProfile({
                id: 53,
                name: 'Afiliado',
                description: 'Área de afiliados',
                alias: 'promotor'
              })}
            >
              {selectedProfile?.alias === 'promotor'
                ? (
                  <>
                    <Check />
                    Selecionado
                  </>
                )
                : 'Selecionar'
              }
            </Button>
          </li>

          <li>
            <span className="icon">
              <User />
            </span>
            
            <h3>Paciente</h3>
            <p>Área de pacientes</p>

            <Button
              variant={selectedProfile?.alias === 'patient' ? "secondary" : "primary"}
              onClick={() => changeSelectedProfile({
                id: 53,
                name: 'Paciente',
                description: 'Área de pacientes',
                alias: 'patient'
              })}
            >
              {selectedProfile?.alias === 'patient'
                ? (
                  <>
                    <Check />
                    Selecionado
                  </>
                )
                : 'Selecionar'
              }
            </Button>
          </li>

          <li>
            <span className="icon">
              <User />
            </span>
            
            <h3>Profissional</h3>
            <p>Área do profissional</p>

            <Button
              variant={selectedProfile?.alias === 'profissional' ? "secondary" : "primary"}
              onClick={() => changeSelectedProfile({
                id: 326,
                name: 'Profissional',
                description: 'Área do profissional',
                alias: 'profissional'
              })}
            >
              {selectedProfile?.alias === 'profissional'
                ? (
                  <>
                    <Check />
                    Selecionado
                  </>
                )
                : 'Selecionar'
              }
            </Button>
          </li>

          <li>
            <span className="icon">
              <User />
            </span>
            
            <h3>Empresa</h3>
            <p>Área de empresas</p>

            <Button
              variant={selectedProfile?.alias === 'enterprise' ? "secondary" : "primary"}
              onClick={() => changeSelectedProfile({
                id: 326,
                name: 'Empresa',
                description: 'Área de empresas',
                alias: 'enterprise'
              })}
            >
              {selectedProfile?.alias === 'enterprise'
                ? (
                  <>
                    <Check />
                    Selecionado
                  </>
                )
                : 'Selecionar'
              }
            </Button>
          </li> */}
        </ul>
      </Container>
    </Wrapper>
  );
}