import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

import { Input } from "@components/Input";
import { Button } from "@components/Button";

import { delay } from "@utils/delay";

import { AgendaConsultaExpand } from "@assets/images";
import mockup from '@assets/images/mockup.png';

import { Banner, Container, Main } from "./styles";

interface InitialValues {
  email: string;
}

export function ForgotPassword() {
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  const formik = useFormik<InitialValues>({
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Insira um e-mail válido")
        .required("O campo é obrigatório")
      ,
    }),
    onSubmit: async () => {
      setIsSubmitting(true);

      await delay(2000);

      setIsSubmitting(false);
      toast.success('E-mail de redefinição enviado com sucesso!');
    }
  });
  
  return (
    <Container>
      <Main>
        <div className="wrapper">
          <Link to="/login" className="logo">
            <AgendaConsultaExpand />
          </Link>

          <h1>Esqueci minha senha</h1>
          <p>Sua redefinição de senha será enviada para o e-mail cadastrado na plataforma.</p>

          <form onSubmit={formik.handleSubmit}>
            <Input 
              label="E-mail"
              name="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              error={(formik.touched.email || undefined) && formik.errors.email}
            />

            <Button type="submit" isLoading={isSubmitting}>
              Enviar e-mail
            </Button>
          </form>
        </div>
      </Main>

      <Banner>
        <img src={mockup} alt="Mockup" />
      </Banner>
    </Container>
  );
}