import { Navigate, Route, Routes } from "react-router-dom";

// Pages
import { ListClinics } from "./ListClinics";
import { AddClinic } from "./AddClinic";

export function Clinics() {
  return (
    <Routes>
      <Route path="/" element={<Navigate to="listagem" />} />
      <Route path="listagem/" element={<ListClinics />} />
      <Route path="adicionar/" element={<AddClinic />} />
      <Route path="planos/" element={() => <h1>Planos</h1>} />
    </Routes>
  );
}