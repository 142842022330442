import HttpClient from "./utils/HttpClient";

import { ErrorResponse } from "@/types/api";

export type ProfileAliasType = "admin" | "clinica_admin" | "clinica" | "profissional" | "patient" | "enterprise" | "promotor" | "promotor_admin";

interface ValidateTokenResponse {
  codigo: string;
  menssagem: string;
}

export interface LoginResponse {
  codigo: string | null;
  uuid: string;
  token: string;
  usuario_nome: string;
  usuario_foto: string;
  usuario_id: number;
  usuario_perfil: Array<{
    id: number;
    nome: string;
    descricao: string;
    alias: ProfileAliasType;
    selecionar_conta: boolean;
  }>;
  status: string;
  selecionar_perfil: boolean;
}

export interface InactivatedAccountResponse extends ErrorResponse {
  acesso: string;
  status: string;
}

interface ActivateAccountRequest {
  login: string;
	senha: string;
	nova_senha: string;
	nova_senha_confirmar: string;
	codigo: string;
}

interface ActivateAccountResponse {
  mensagem: string;
}

interface ResetPasswordRequest extends ActivateAccountRequest {}

interface ResetPasswordResponse extends ActivateAccountResponse {}

class SessionsService {
  async validateToken() {
    return HttpClient.post<ValidateTokenResponse>('/token/validar');
  }

  async login(user: string, password: string) {
    return HttpClient.post<LoginResponse>('/token', {
      grant_type: "password",
	    login: user,
	    senha: password,
	    scope: "admin, usuario, profisional, clinica, suport"
    });
  }

  async activateAccount(body: ActivateAccountRequest) {
    return HttpClient.post<ActivateAccountResponse>('/base/ativar/conta-usuario', body);
  }

  async resetPassword(body: ResetPasswordRequest) {
    return HttpClient.post<ResetPasswordResponse>('/base/ativar/conta-usuario', body);
  }
}

export default new SessionsService();