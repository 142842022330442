import { ChangeEvent, FocusEvent, useEffect, useRef, useState } from "react";
import { format, parseISO, subYears } from "date-fns";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RiPencilLine } from "react-icons/ri";
import { toast } from "react-toastify";
import axios from "axios";

import { useAuth } from "@contexts/auth";

import { Loading } from "@components/Loading";
import { CPFCNPJInput, Input, InputGroup } from "@components/Input";
import { Select } from "@components/Select";
import { DatePicker } from "@components/DatePicker";
import { RadioGroup, RadioInput } from "@components/Radio";
import { Button } from "@components/Button";

import LocationsService from "@services/LocationsService";
import AffiliatesService, { UpdateAffiliateAddressDataRequest, UpdateAffiliateBankDataRequest, UpdateAffiliatePersonalDataRequest } from "@services/AffiliatesService";

import { genderOptions } from "@constants/genders";
import { pixKeyOptions } from "@constants/pix-keys";

import { formatCPFCNPJ } from "@utils/formatCPFCNPJ";
import { formatPhoneNumber } from "@utils/formatPhoneNumber";

import affiliateBanner from '@assets/images/affiliate-banner.png';
import affiliateBannerMobile from '@assets/images/affiliate-banner-mobile.png';
import profilePic from '@assets/images/profile-pic.png';

import { SingleValue } from "react-select";
import { OptionTypeBase } from "@components/Select/Select";
import { AxiosAPIError } from "@/types/api";

import { EditAddressDataModal, EditBankDataModal, EditPersonalDataModal, LoadingWrapper, Wrapper } from "./styles";

interface PersonalDataFormik {
  name: string;
  email: string;
  cpf: string;
  phone: string;
  gender: OptionTypeBase | null;
  birthDate: Date | null;
}

interface BankDataFormik {
  receiptType: "pix" | "transfer";
  bank: string;
  keyType: OptionTypeBase | null;
  keyValue: string;
  bankAgency: string;
  bankAccount: string;
}

interface AddressDataFormik {
  street: string;
  number: string;
  complement: string;
  neighborhood: string;
  city: OptionTypeBase | null;
  uf: OptionTypeBase | null;
  zipCode: string;
}

type Affiliate = Omit<PersonalDataFormik, "gender" | "birthDate"> & Omit<BankDataFormik, "keyType"> & {
  avatar: string;
  gender: string;
  birthDate: Date;
  keyType: string;
  
  address: Omit<AddressDataFormik, "city" | "uf"> & {
    city: string;
    uf: string;
  };
};

export function MyData() {
  const { user, updateUser } = useAuth();

  const [isEditPersonalDataModalVisible, setIsEditPersonalDataModalVisible] = useState(false);
  const [isEditBankDataModalVisible, setIsEditBankDataModalVisible] = useState(false);
  const [isEditAddressDataModalVisible, setIsEditAddressDataModalVisible] = useState(false);

  const maxDate = subYears(new Date(), 18);

  const [isLoading, setIsLoading] = useState(true); 
  const [affiliate, setAffiliate] = useState<Affiliate | null>(null); 

  const [isUpdatingAvatar, setIsUpdatingAvatar] = useState(false);
  const avatarRef = useRef<HTMLInputElement>(null);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const personalDataFormik = useFormik<PersonalDataFormik>({
    enableReinitialize: true,
    initialValues: {
      name: affiliate?.name || '',
      email: affiliate?.email || '',
      cpf: affiliate?.cpf || '',
      phone: affiliate?.phone || '',
      gender: genderOptions.find(gender => gender.value === affiliate?.gender) || null,
      birthDate: affiliate?.birthDate || null,
    },
    validationSchema: Yup.object().shape({
      name: Yup.string()
        .required("O campo é obrigatório"),
      email: Yup.string()
        .email("Informe um e-mail válido")
        .required("O campo é obrigatório"),
      cpf: Yup.string()
        .matches(/^[0-9]{3}.?[0-9]{3}.?[0-9]{3}-?[0-9]{2}/, "Insira um CPF válido")
        .required("O campo é obrigatório"),
      phone: Yup.string()
        .matches(/\(\d{2,}\) \d{5}-\d{4}/g, "Insira um telefone válido")
        .required("O campo é obrigatório"),
      gender: Yup.object()
        .shape({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um gênero"),
      birthDate: Yup.date()
        .max(format(maxDate, "yyyy-MM-dd"), 'O afiliado deve ser maior de 18 anos')
        .required("O campo é obrigatório")
        .typeError("O campo é obrigatório"),
    }),
    onSubmit: async (values) => {
      try {
        if (!user) throw new Error('Não foi possível capturar o uuid do afiliado');
        if (isSubmitting || values.birthDate === null || values.gender === null) return;

        setIsSubmitting(true);

        const parsedAffiliate: UpdateAffiliatePersonalDataRequest = {
          tipo_dado: 'dados',
          nome: values.name,
          email: values.email,
          telefone: values.phone,
          data_nascimento: format(values.birthDate, 'dd.MM.yyyy'),
          genero: values.gender.value,
        };

        const { data } = await AffiliatesService.updateAffiliatePersonalData(user.uuid, parsedAffiliate);

        updateAffiliateInfo({
          name: values.name,
          email: values.email,
          phone: values.phone,
          birthDate: values.birthDate,
          gender: values.gender.value,
        });
        updateUser({ name: values.name });
        
        toast.success(data.menssagem);
        setIsEditPersonalDataModalVisible(false);
      } catch (err) {
        const error = err as AxiosAPIError;

        console.error(error);

        if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
          error.response.data.dados.forEach(err => toast.error(err));
        } else {
          toast.error(error.message);
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  const bankDataFormik = useFormik<BankDataFormik>({
    enableReinitialize: true,
    initialValues: {
      receiptType: affiliate?.receiptType || 'pix',
      bank: affiliate?.bank || '',
      keyType: pixKeyOptions.find(key => key.value === affiliate?.keyType) || null,
      keyValue: affiliate?.keyValue || '',
      bankAgency: affiliate?.bankAgency || '',
      bankAccount: affiliate?.bankAccount || '',
    },
    validationSchema: Yup.object().shape({
      receiptType: Yup.string()
        .required("O campo é obrigatório"),
      bank: Yup.string()
        .required("O campo é obrigatório"),
      keyType: Yup.object()
        .when("receiptType", {
          is: "pix",
          then: Yup.object()
            .shape({ value: Yup.string(), label: Yup.string() })
            .required("O campo é obrigatório")
            .typeError("Selecione um tipo de chave"),
          otherwise: Yup.object().nullable()
        }),
      keyValue: Yup.string()
        .when("receiptType", {
          is: "pix",
          then: Yup.string()
            .when("keyType.label", {
              is: "CPF/CNPJ",
              then: Yup.string().matches(
                /([0-9]{2}[.]?[0-9]{3}[.]?[0-9]{3}[/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[.]?[0-9]{3}[.]?[0-9]{3}[-]?[0-9]{2})/gm,
                "Insira um CPF/CNPJ válido"
              )
            })
            .when("keyType.label", {
              is: "E-mail",
              then: Yup.string().email("Insira um e-mail válido")
            })
            .when("keyType.label", {
              is: "Telefone",
              then: Yup.string().matches(/\(\d{2,}\) \d{4,5}-\d{4}/g, "Insira um telefone válido")
            })
            .required("O campo é obrigatório")
        }),
      bankAgency: Yup.string()
        .when("receiptType", {
          is: "transfer",
          then: Yup.string().required("O campo é obrigatório"),
        }),
      bankAccount: Yup.string()
        .when("receiptType", {
          is: "transfer",
          then: Yup.string().required("O campo é obrigatório"),
        }),
    }),
    onSubmit: async (values) => {
      try {
        if (!user) throw new Error('Não foi possível capturar o uuid do afiliado');
        if (isSubmitting) return;

        setIsSubmitting(true);

        const parsedAffiliate: UpdateAffiliateBankDataRequest = {
          tipo_dado: 'banco',
          banco: values.bank,
          tipo_id: values.receiptType === 'pix' ? '2': '1',
          chave_conta: values.receiptType === 'pix' ? values.keyType?.value as string : values.bankAccount,
          valor_agencia:  values.receiptType === 'pix' ? values.keyValue : values.bankAgency,
        };

        const { data } = await AffiliatesService.updateAffiliateBankData(user.uuid, parsedAffiliate);

        updateAffiliateInfo({
          bank: values.bank,
          receiptType: values.receiptType,

          keyType: values.receiptType === 'pix' ? values.keyType?.value as string : '',
          keyValue: values.receiptType === 'pix' ? values.keyValue : '',
          
          bankAgency: values.receiptType === 'transfer' ? values.bankAgency : '',
          bankAccount: values.receiptType === 'transfer' ? values.bankAccount : '',
        });

        toast.success(data.menssagem);

        setIsEditBankDataModalVisible(false);
      } catch (err) {
        const error = err as AxiosAPIError;

        console.error(error);

        if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
          error.response.data.dados.forEach(err => toast.error(err));
        } else {
          toast.error(error.message);
        }
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  const [isLoadingUFOptions, setIsLoadingUFOptions] = useState(true);
  const [isLoadingCityOptions, setIsLoadingCityOptions] = useState(false);
  
  const [UFOptions, setUFOptions] = useState<OptionTypeBase[]>([]);
  const [cityOptions, setCityOptions] = useState<OptionTypeBase[]>([]);

  const [isLoadingCEPInfo, setIsLoadingCEPInfo] = useState(false);
  const [isCEPValid, setIsCEPValid] = useState(true);

  const addressDataFormik = useFormik<AddressDataFormik>({
    initialValues: {
      zipCode: '',
      street: '',
      number: '',
      complement: '',
      neighborhood: '',
      uf: null,
      city: null,
    },
    validationSchema: Yup.object().shape({
      zipCode: Yup.string()
        .matches(/^[0-9]{5}-[0-9]{3}$/, "Infome um CEP válido")
        .required("O campo é obrigatório"),
      street: Yup.string()
        .required("O campo é obrigatório"),
      number: Yup.number()
        .typeError("Apenas números")
        .required("O campo é obrigatório"),
      neighborhood: Yup.string()
        .required("O campo é obrigatório"),
      uf: Yup.object({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione um estado"),
      city: Yup.object({ value: Yup.string(), label: Yup.string() })
        .required("O campo é obrigatório")
        .typeError("Selecione uma cidade"),
    }),
    onSubmit: async (values) => {
      try {
        if (!user) throw new Error('Não foi possível capturar o uuid do afiliado');
        if (isSubmitting || !values.city || !values.uf) return;

        setIsSubmitting(true);

        const parsedAffiliate: UpdateAffiliateAddressDataRequest = {
          tipo_dado: 'endereco',
          endereco: {
            cep: values.zipCode,
            endereco: values.street,
            numero: values.number,
            complemento: values.complement,
            bairro: values.neighborhood,
            cidade: values.city.value,
            uf: values.uf.value,
          },
        };

        const { data } = await AffiliatesService.updateAffiliateAddressData(user.uuid, parsedAffiliate);

        updateAffiliateInfo({
          address: {
            zipCode: values.zipCode,
            street: values.street,
            number: values.number,
            complement: values.complement,
            neighborhood: values.neighborhood,
            city: values.city.label,
            uf: values.uf.label,
          },
        });

        toast.success(data.menssagem);

        setIsEditAddressDataModalVisible(false);
      } catch (err) {
        const error = err as AxiosAPIError;

        console.error(error);

        if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
          error.response.data.dados.forEach(err => toast.error(err));
        } else {
          toast.error(error.message);
        }
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  // Setting UF options
  useEffect(() => {
    async function getUFs() {
      try {
        setIsLoadingUFOptions(true);

        const { data: UFs } = await LocationsService.listUFs();

        const parsedUFs: OptionTypeBase[] = UFs.map((UF) => ({
          value: String(UF.id),
          label: UF.uf,
        }));

        setUFOptions(parsedUFs);
      } catch (error) {
        setUFOptions([]);

        toast.error("Não foi possível carregar as UFs");

        console.log(error);
      } finally {
        setIsLoadingUFOptions(false);
      }
    }

    getUFs();
  }, []);

  // Getting affiliate data
  useEffect(() => {
    async function getData() {
      try {
        setIsLoading(true);

        const { data: { afiliado, banco, endereco } } = await AffiliatesService.listAffiliate();

        const parsedData: Affiliate = {
          avatar: afiliado.foto,
          name: afiliado.nome,
          email: afiliado.email,
          cpf: formatCPFCNPJ(afiliado.documento),
          phone: formatPhoneNumber(afiliado.telefone),
          gender: afiliado.genero,
          birthDate: parseISO(afiliado.data_nascimento),

          receiptType: banco.tipo_id === 2 ? 'pix' : 'transfer',
          bank: banco.banco,

          keyType: banco.tipo_id === 2 ? banco.chave_conta : '',
          keyValue: banco.tipo_id === 2 ? banco.valor_agencia : '',

          bankAgency: banco.tipo_id === 1 ? banco.valor_agencia : '',
          bankAccount: banco.tipo_id === 1 ? banco.chave_conta : '',

          address: {
            zipCode: endereco.cep,
            street: endereco.endereco,
            number: endereco.numero,
            complement: endereco.complemento,
            neighborhood: endereco.bairro,
            city: endereco.cidade,
            uf: endereco.uf,
          },
        }

        const cities = await changeCityOptions(parsedData.address.uf);

        addressDataFormik.setFieldValue('zipCode', parsedData.address.zipCode);
        addressDataFormik.setFieldValue('street', parsedData.address.street);
        addressDataFormik.setFieldValue('number', parsedData.address.number);
        addressDataFormik.setFieldValue('complement', parsedData.address.complement);
        addressDataFormik.setFieldValue('neighborhood', parsedData.address.neighborhood);
        addressDataFormik.setFieldValue('uf', UFOptions.find(uf => uf.label === parsedData.address.uf) || null);
        addressDataFormik.setFieldValue('city', cities?.find(city => city.label === parsedData.address.city) || null);
        
        setAffiliate(parsedData);
      } catch (err) {
        const error = err as AxiosAPIError;

        console.error(error);

        if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
          error.response.data.dados.forEach(err => toast.error(err));
        } else {
          toast.error(error.message);
        }
      } finally {
        setIsLoading(false);
      }
    }

    getData();
  }, []);
    
  function updateAffiliateInfo(updatedAffiliate: Partial<Affiliate>) {
    const newAffiliate = { ...affiliate, ...updatedAffiliate };

    setAffiliate(newAffiliate as Affiliate);
  }

  async function handleChangeAvatarClick() {
    try {
      if (!affiliate?.avatar) {
        if (!avatarRef.current) return;
        avatarRef.current.click();
  
        return;
      }

      setIsUpdatingAvatar(true);

      const { data } = await AffiliatesService.deleteAffiliateAvatar();
      
      updateAffiliateInfo({ avatar: '' });
      updateUser({ avatar: '' });
      toast.success(data.menssagem);
    } catch (err) {
      const error = err as AxiosAPIError;
      
      console.error(error);

      if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
        error.response.data.dados.forEach(err => toast.error(err));
      } else {
        toast.error(error.message);
      }
    } finally {
      setIsUpdatingAvatar(false);
    }
  }

  async function handleChangeAvatar(event: ChangeEvent<HTMLInputElement>) {
    try {
      if (!event.target.files) throw new Error('Selecione uma foto!');

      if (!["image/jpg", "image/jpeg", "image/png"].includes(event.target.files[0].type)) throw new Error('Formato não pertido! Só é permitido o envio de arquivo de imagens (jpg/png).');
      
      const imageSizeMB = event.target.files[0].size / Math.pow(1024, 2);
      if (imageSizeMB > 3.0) throw new Error("Tamanho excedido! Selecione uma imagem com no máximo 3MB.");

      setIsUpdatingAvatar(true); 

      const img = {
        preview: URL.createObjectURL(event.target.files[0]),
        raw: event.target.files[0],
      }

      const data = await AffiliatesService.changeAffiliateAvatar(img.raw);

      updateAffiliateInfo({ avatar: data.foto });
      updateUser({ avatar: data.foto });
      toast.success(data.menssagem);
    } catch (err) {
      const error = err as AxiosAPIError;
      
      console.error(error);

      if (axios.isAxiosError(error) && error.response && error.response.data.dados) {
        error.response.data.dados.forEach(err => toast.error(err));
      } else {
        toast.error(error.message);
      }
    } finally {
      setIsUpdatingAvatar(false); 
    }
  }

  function clearAddressFields() {
    addressDataFormik.setFieldValue("street", "");
    addressDataFormik.setFieldValue("number", "");
    addressDataFormik.setFieldValue("complement", "");
    addressDataFormik.setFieldValue("neighborhood", "");
    addressDataFormik.setFieldValue("uf", null);
    addressDataFormik.setFieldValue("city", null);
  }

  async function changeCityOptions(uf: string) {
    try {
      setIsLoadingCityOptions(true);

      if (!uf) throw new Error("Estado inválido! Não foi possível buscar as cidades");

      const { data: cities } = await LocationsService.listCitiesByUF(uf);

      const parsedCities: OptionTypeBase[] = cities.map((city) => ({
        value: String(city.id),
        label: city.cidade,
      }));

      setCityOptions(parsedCities);

      return parsedCities;
    } catch (error) {
      toast.error("Não foi possível carregar as cidades");

      console.log(error);

      return null;
    } finally {
      setIsLoadingCityOptions(false);
    }
  }

  async function handleBlurZipCode(event: FocusEvent<HTMLInputElement>) {
    const cep = event.target.value.replace(/[^\d]/g, "");

    if (cep.length !== 8) return clearAddressFields();

    try {
      setIsLoadingCEPInfo(true);

      const data = await LocationsService.getAddressByZipCode(Number(cep));

      if (data.erro) throw new Error("CEP Inválido!");

      setIsCEPValid(true);

      clearAddressFields();
      
      const cities = await changeCityOptions(data.uf);
      
      addressDataFormik.setFieldValue("street", data.logradouro);
      addressDataFormik.setFieldValue("neighborhood", data.bairro);
      addressDataFormik.setFieldValue("uf", UFOptions.find(uf => uf.label.toUpperCase() === data.uf.toUpperCase()) || null);
      addressDataFormik.setFieldValue("city", cities?.find(city => city.label.toUpperCase() === data.localidade.toUpperCase()) || null);
    } catch (error) {
      console.log(error);

      setIsCEPValid(false);

      clearAddressFields();
    } finally {
      setIsLoadingCEPInfo(false);
    }
  }

  async function handleChangeUF(option: SingleValue<OptionTypeBase>) {
    if (!option) {
      addressDataFormik.setFieldValue("uf", null);
      addressDataFormik.setFieldValue("city", null);

      setCityOptions([]);
      return;
    }

    addressDataFormik.setFieldValue("uf", option);
    changeCityOptions(option.label);
  }

  if (isLoading) {
    return (
      <LoadingWrapper>
        <Loading />
      </LoadingWrapper>
    );
  }

  if (affiliate === null) {
    return (
      <LoadingWrapper>
        <p>Não foi possível carregar seus dados.</p>
      </LoadingWrapper>
    );
  }

  return (
    <>
      <EditPersonalDataModal
        visible={isEditPersonalDataModalVisible}
        title="Editar | Informações Pessoais"
        onRequestClose={() => setIsEditPersonalDataModalVisible(false)}
      >
        <form onSubmit={personalDataFormik.handleSubmit}>
          <Input 
            label="Nome"
            name="name"
            value={personalDataFormik.values.name}
            onChange={personalDataFormik.handleChange}
            error={(personalDataFormik.touched.name || undefined) && personalDataFormik.errors.name}
          />

          <InputGroup>
            <Input 
              label="E-mail"
              type='email'
              name="email"
              value={personalDataFormik.values.email}
              onChange={personalDataFormik.handleChange}
              error={(personalDataFormik.touched.email || undefined) && personalDataFormik.errors.email}
            />
          </InputGroup>

          <InputGroup>
            <Input 
              label="CPF"
              name="cpf"
              value={personalDataFormik.values.cpf}
              disabled
            />

            <Input 
              label="Telefone"
              type='tel'
              name="phone"
              mask={['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
              value={personalDataFormik.values.phone}
              onChange={personalDataFormik.handleChange}
              error={(personalDataFormik.touched.phone || undefined) && personalDataFormik.errors.phone}
            />
          </InputGroup>

          <InputGroup>
            <Select 
              label="Gênero"
              name="gender"
              options={genderOptions}
              value={personalDataFormik.values.gender}
              onChange={option => personalDataFormik.setFieldValue('gender', option)}
              error={(personalDataFormik.touched.gender || undefined) && personalDataFormik.errors.gender} 
              isClearable={false}
            />

            <DatePicker 
              label="Data de nascimento"
              name="birthDate"
              selected={personalDataFormik.values.birthDate}
              onChange={(date) => personalDataFormik.setFieldValue('birthDate', date)}
              error={(personalDataFormik.touched.birthDate || undefined) && personalDataFormik.errors.birthDate}
              maxDate={new Date()}
              isClearable={false}
              showYearDropdown
            />
          </InputGroup>
          
          <Button 
            type="submit"
            isLoading={isSubmitting}
          >
            Editar
          </Button>
        </form>
      </EditPersonalDataModal>

      <EditBankDataModal
        visible={isEditBankDataModalVisible}
        title="Editar | Dados Bancários"
        onRequestClose={() => setIsEditBankDataModalVisible(false)}
      >
        <form onSubmit={bankDataFormik.handleSubmit}>
          <RadioGroup label="Tipo de Recebimento">
            <RadioInput 
              label="Pix"
              name="pix"
              value="pix"
              isChecked={bankDataFormik.values.receiptType === 'pix'}
              handleChange={() => bankDataFormik.setFieldValue("receiptType", "pix")}
            />

            <RadioInput 
              label="Transferência"
              name="transfer"
              value="transfer"
              isChecked={bankDataFormik.values.receiptType === 'transfer'}
              handleChange={() => bankDataFormik.setFieldValue("receiptType", "transfer")}
            />
          </RadioGroup>

          <InputGroup>
            <Input 
              label="Banco"
              name="bank"
              value={bankDataFormik.values.bank}
              onChange={bankDataFormik.handleChange}
              error={(bankDataFormik.touched.bank || undefined) && bankDataFormik.errors.bank}
            />
          </InputGroup>
          
          {bankDataFormik.values.receiptType === 'pix'
            ? (
              <InputGroup>
                <Select 
                  label="Tipo de Chave"
                  name="keyType"
                  options={pixKeyOptions}
                  value={bankDataFormik.values.keyType}
                  onChange={(option) => {
                    bankDataFormik.setFieldValue("keyType", option);
                    bankDataFormik.setFieldValue("keyValue", "");
                  }}
                  error={(bankDataFormik.touched.keyType || undefined) && bankDataFormik.errors.keyType}
                  isClearable={false}
                />

                {bankDataFormik.values.keyType?.label === 'CPF/CNPJ'
                  ? (
                    <CPFCNPJInput 
                      label="Chave"
                      name="keyValue"
                      value={bankDataFormik.values.keyValue}
                      onChange={bankDataFormik.handleChange}
                      error={(bankDataFormik.touched.keyValue || undefined) && bankDataFormik.errors.keyValue}
                    />
                  )
                  : (
                    <Input 
                      label="Chave"
                      mask={
                        bankDataFormik.values.keyType?.label === 'Telefone'
                        ?['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
                        : undefined
                      }
                      type={
                        bankDataFormik.values.keyType?.label === 'Telefone'
                        ? 'tel'
                        : bankDataFormik.values.keyType?.label === 'E-mail'
                          ? 'email'
                          : 'text'
                      }
                      name="keyValue"
                      value={bankDataFormik.values.keyValue}
                      onChange={bankDataFormik.handleChange}
                      error={(bankDataFormik.touched.keyValue || undefined) && bankDataFormik.errors.keyValue}
                    />
                  )
                }
              </InputGroup>
            )
            : (
              <InputGroup>
                <Input 
                  label="Agência"
                  name="bankAgency"
                  value={bankDataFormik.values.bankAgency}
                  onChange={bankDataFormik.handleChange}
                  error={(bankDataFormik.touched.bankAgency || undefined) && bankDataFormik.errors.bankAgency}
                />

                <Input 
                  label="Conta"
                  name="bankAccount"
                  value={bankDataFormik.values.bankAccount}
                  onChange={bankDataFormik.handleChange}
                  error={(bankDataFormik.touched.bankAccount || undefined) && bankDataFormik.errors.bankAccount}
                />
              </InputGroup>
            )
          }

          <Button type="submit" isLoading={isSubmitting}>
            Editar
          </Button>
        </form>
      </EditBankDataModal>
      
      <EditAddressDataModal
        visible={isEditAddressDataModalVisible}
        title="Editar | Endereço"
        onRequestClose={() => setIsEditAddressDataModalVisible(false)}
      >
        <form onSubmit={addressDataFormik.handleSubmit}>
          <InputGroup layout="1fr 3fr">
            <Input
              label="CEP"
              name="zipCode"
              mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]}
              value={addressDataFormik.values.zipCode}
              onChange={addressDataFormik.handleChange}
              onBlur={handleBlurZipCode}
              error={isCEPValid ? (addressDataFormik.touched.zipCode || undefined) && addressDataFormik.errors.zipCode : 'CEP inválido!'}
            />

            <Input 
              label="Endereço" 
              name="street"
              value={addressDataFormik.values.street}
              onChange={addressDataFormik.handleChange}
              onBlur={addressDataFormik.handleBlur}
              error={(addressDataFormik.touched.street || undefined) && addressDataFormik.errors.street}
              disabled={isLoadingCEPInfo}
            />
          </InputGroup>

          <InputGroup layout="1fr 3fr 2fr" className="neighborhood">
            <Input 
              label="Número" 
              name="number" 
              value={addressDataFormik.values.number}
              onChange={addressDataFormik.handleChange}
              onBlur={addressDataFormik.handleBlur}
              error={(addressDataFormik.touched.number || undefined) && addressDataFormik.errors.number}
            />

            <Input
              label="Complemento"
              name="complement"
              value={addressDataFormik.values.complement}
              onChange={addressDataFormik.handleChange}
              onBlur={addressDataFormik.handleBlur}
            />

            <Input
              label="Bairro"
              name="neighborhood"
              value={addressDataFormik.values.neighborhood}
              onChange={addressDataFormik.handleChange}
              onBlur={addressDataFormik.handleBlur}
              error={(addressDataFormik.touched.neighborhood || undefined) && addressDataFormik.errors.neighborhood}
              disabled={isLoadingCEPInfo}
            />
          </InputGroup>

          <InputGroup layout="1fr 3fr">
            <Select
              label="UF"
              name="uf"
              options={UFOptions}
              value={addressDataFormik.values.uf}
              onChange={(selectedOption) => handleChangeUF(selectedOption)}
              error={(addressDataFormik.touched.uf || undefined) && addressDataFormik.errors.uf}
              isDisabled={isLoadingUFOptions || isLoadingCEPInfo} 
            />

            <Select
              label="Cidade"
              name="city"
              options={cityOptions}
              value={addressDataFormik.values.city}
              onChange={option => addressDataFormik.setFieldValue("city", option)}
              error={(addressDataFormik.touched.city || undefined) && addressDataFormik.errors.city}
              isDisabled={isLoadingCEPInfo || addressDataFormik.values.uf === null || isLoadingCityOptions}
            />
          </InputGroup>
          
          <Button 
            type="submit"
            isLoading={isSubmitting}
          >
            Editar
          </Button>
        </form>
      </EditAddressDataModal>

      <Wrapper>
        <header>
          <div className="promo-image">
            <img src={affiliateBanner} alt="Imagem Promocional" />
            <img src={affiliateBannerMobile} className="mobile" alt="Imagem Promocional" />
          </div>

          <h1>Dados Cadastrais</h1>
        </header>

        <section>
          <div className="card">
            <h3>
              Informações pessoais

              <button type="button" onClick={() => setIsEditPersonalDataModalVisible(true)}>
                <RiPencilLine />
                Editar
              </button>
            </h3>

            <div className="info">
              <span className="label">Foto</span>
              <span className="value">
                <div className="avatar-wrapper">
                  <div className="avatar">
                    {isUpdatingAvatar && (
                      <div className="loading">
                        <Loading />
                      </div>
                    )}

                    <img 
                      src={affiliate.avatar || profilePic} 
                      alt={affiliate.name} 
                    />
                  </div>

                  <button type="button" onClick={handleChangeAvatarClick}>
                    {affiliate.avatar ? 'Remover foto' : 'Adicionar foto'}
                  </button>

                  <input
                    ref={avatarRef}
                    type="file" 
                    accept="image/jpg, image/jpeg, image/png"
                    onChange={handleChangeAvatar}
                  />
                </div>
              </span>
            </div>

            <div className="info">
              <span className="label">Nome</span>
              <span className="value">{affiliate.name}</span>
            </div>

            <div className="info">
              <span className="label">E-mail</span>
              <span className="value">{affiliate.email}</span>
            </div>

            <InputGroup>
              <div className="info">
                <span className="label">CPF</span>
                <span className="value">{affiliate.cpf}</span>
              </div>

              <div className="info">
                <span className="label">Telefone</span>
                <span className="value">{affiliate.phone}</span>
              </div>
            </InputGroup>

            <InputGroup>
              <div className="info">
                <span className="label">Gênero</span>
                <span className="value">{genderOptions.filter(gender => gender.value === affiliate.gender)[0].label}</span>
              </div>

              <div className="info">
                <span className="label">Data de Nascimento</span>
                <span className="value">{format(affiliate.birthDate, 'dd/MM/yyyy')}</span>
              </div>
            </InputGroup>
          </div>
        </section>

        <section>
          <div className="card">
            <h3>
              Dados bancários
              
              <div>
                <button type="button" onClick={() => setIsEditBankDataModalVisible(true)}>
                  <RiPencilLine />
                  Editar
                </button>
              </div>
            </h3>
            
            <div className="info">
              <span className="label">Banco</span>
              <span className="value">{affiliate.bank}</span>
            </div>

            <InputGroup>
              <div className="info">
                <span className="label">Tipo de Recebimento</span>
                <span className="value">{affiliate.receiptType === 'pix' ? 'Pix' : 'Transferência'}</span>
              </div>

              <div className="info">
                <span className="label">{affiliate.receiptType === 'pix' ? 'Tipo de Chave' : 'Agência'}</span>
                <span className="value">{affiliate.receiptType === 'pix' ? pixKeyOptions.filter(pixKey => pixKey.value === affiliate.keyType)[0].label : affiliate.bankAgency}</span>
              </div>

              <div className="info">
                <span className="label">{affiliate.receiptType === 'pix' ? 'Chave' : 'Conta'}</span>
                <span className="value">{affiliate.receiptType === 'pix' ? affiliate.keyValue : affiliate.bankAccount}</span>
              </div>
            </InputGroup>
          </div>
        </section>

        <section>
          <div className="card">
            <h3>
              Endereço
              
              <div>
                <button type="button" onClick={() => setIsEditAddressDataModalVisible(true)}>
                  <RiPencilLine />
                  Editar
                </button>
              </div>
            </h3>

            <InputGroup>
              <div className="info">
                <span className="label">CEP</span>
                <span className="value">{affiliate.address.zipCode}</span>
              </div>

              <div className="info">
                <span className="label">Endereço</span>
                <span className="value">{affiliate.address.street}</span>
              </div>

              <div />
            </InputGroup>

            <InputGroup>
              <div className="info">
                <span className="label">Número</span>
                <span className="value">{affiliate.address.number}</span>
              </div>

              <div className="info">
                <span className="label">Complemento</span>
                <span className="value">{affiliate.address.complement}</span>
              </div>

              <div className="info">
                <span className="label">Bairro</span>
                <span className="value">{affiliate.address.neighborhood}</span>
              </div>
            </InputGroup>

            <InputGroup>
              <div className="info">
                <span className="label">Cidade</span>
                <span className="value">{affiliate.address.city}</span>
              </div>

              <div className="info">
                <span className="label">UF</span>
                <span className="value">{affiliate.address.uf}</span>
              </div>

              <div />
            </InputGroup>
          </div>
        </section>
      </Wrapper>
    </>
  );
}