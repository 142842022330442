import styled from "styled-components";
import chroma from "chroma-js";

import { Modal, Subtitle, List } from "@/components/Modal";
import { InputGroup } from "@/components/Input/styles";
import { Container as Button } from "@/components/Button/styles";
import { Container as Select } from "@/components/Select/styles";
import { Container as ResultCard, ResponsiveCard, ActionButton, ItemContainer } from "@/components/ResultCard/styles";

import { convertToRem } from "@utils/convertToRem";

export const FilterModal = styled(Modal)`
  form {
    display: flex;
    flex-direction: column;

    width: 100%;
    
    footer {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: ${convertToRem(24)};

      margin-top: ${convertToRem(36)};
      
      ${Button} {
        flex: 1;

        min-width: auto;
        max-width: ${convertToRem(270)};

        margin-left: auto;
      }
    }
  }

  @media (max-width: 576px) {
    ${InputGroup} {
      grid-template-columns: 1fr;
    }
    
    footer {
      flex-direction: column;

      ${Button} {
        width: 100%;

        max-width: none;
      }
    }
  }
`;

export const ChangeAffiliateSituationModal = styled(Modal)`
  > div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${convertToRem(24)};

    width: 100%;

    margin-top: ${convertToRem(36)};

    ${Button} {
      flex: 1;
    
      min-width: 0;
    }
  }
`;

export const DataModal = styled(Modal)`
  ${Subtitle} {
    margin-top: ${convertToRem(30)};
  }

  ${List} {
    width: 100%;

    float: left;

    > li {
      width: 100%;

      float: left;

      padding: 0.5rem 0;
      margin-bottom: 0;

      border-top: ${convertToRem(1)} solid rgba(0,0,0,0.09);

      &:first-of-type {
        border-top: 0;
      }

      > strong {
        max-width: 100%;

        float: left;

        word-wrap: break-word;
      }

      > span {
        max-width: 100%;

        float: right;

        word-wrap: break-word;

        margin-left: 0;
      }
    }
  }
`;

export const PaymentsListModal = styled(Modal)`
  max-width: ${convertToRem(800)};

  ${Subtitle}:not(:first-of-type) {
    margin-top: ${convertToRem(24)};
  }

  > div {
    width: 100%;

    ${ResultCard} {
      ${ItemContainer} {
        &:nth-of-type(2),
        &:nth-of-type(3) {
          flex: 1.5;
        }
      }

      ${Button} {
        min-width: auto;
        min-height: auto;

        height: 100%;

        padding: 0 ${convertToRem(40)};
      }

      &:not(:first-child) {
        margin-top: ${convertToRem(10)};
      }
    }
  }

  @media (max-width: 768px) {
    > div {
      ${ResultCard} {
        ${ResponsiveCard};

        ${Button} {
          margin-top: ${convertToRem(24)};
          padding: ${convertToRem(16)} ${convertToRem(40)};
        }
      }
    }
  }
`;

export const ReceiptFile = styled(Button)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: ${convertToRem(180)};

  min-width: auto;
  min-height: auto;

  margin-top: ${convertToRem(24)};

  background-color: transparent;
  color: ${({ theme }) => theme.colors.neutral.text};

  border-style: dashed;
  border-width: ${convertToRem(1)};
  border-color: ${({ theme }) => theme.colors.neutral.borderDark};

  border-radius: ${convertToRem(6)};

  cursor: pointer;
  transition: border-color ease 0.3s;
  
  &:hover {
    border-color: ${({ theme }) => theme.colors.neutral.text};
  }

  &.error {
    border-color: ${({ theme }) => theme.colors.primary.highlight};
  }

  > span {
    display: flex;
    align-items: center;
    justify-content: center;

    width: ${convertToRem(40)};
    height: ${convertToRem(40)};

    background-color: ${({ theme }) => theme.colors.primary.highlight};
    color: ${({ theme }) => theme.colors.neutral.white};

    border-radius: 50%;

    + p {
      margin-top: ${convertToRem(16)};
    }
  }

  > p {
    display: flex;
    align-items: center;
    justify-content: center;

    > svg {
      width: ${convertToRem(20)};
      height: auto;

      margin-right: ${convertToRem(6)};
    }
  }

  > input[type="file"] {
    display: none;
  }
`;

export const ConfirmPaymentModal = styled(Modal)`
  form {
    width: 100%;

    > * {
      &:not(:first-child) {
        margin-top: ${convertToRem(16)};
      }
    }

    > ${Button}[type='submit'] {
      margin-left: auto;
      margin-top: ${convertToRem(40)};
    }
  }

  @media (max-width: 500px) {
    ${InputGroup} {
      grid-template-columns: 1fr; 
    }
  }
`;

export const Content = styled.section`
  flex: 1;
  
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  width: 100%;
  min-height: calc(100vh - ${convertToRem(80)});
`;

export const ContentWrapper = styled.div`
  flex: 1;

  padding-top: ${convertToRem(40)};
`;

export const Header = styled.header`
  display: flex;
  align-items: flex-end;
  gap: ${convertToRem(24)};

  > div {
    flex: 1;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const Tab = styled.button`
  padding: 0 ${convertToRem(25)} ${convertToRem(8)};

  background-color: transparent;
  color: ${({ theme }) => chroma(theme.colors.neutral.text).alpha(0.5).css()};

  font-size: ${convertToRem(15)};

  border: 0;

  &.active {
    font-weight: 500;

    color: ${({ theme }) => theme.colors.primary.highlight};

    border-bottom: solid ${convertToRem(2)} ${({ theme }) => theme.colors.primary.highlight};
  }
`;

export const Tabs = styled.div`
  width: 100%;
  float: left;

  margin-top: ${convertToRem(16)};

  border-bottom: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

  @media screen and (max-width: 768px) {

    border-bottom: 0;

    ${Tab} {
      width: 50%;
      float: left;

      padding: 0.8rem 0;

      text-align: center;

      font-size: 0.8rem;

      border-bottom: solid ${convertToRem(2)} ${({ theme }) => theme.colors.neutral.border};

      &.active {
        font-weight: 500;
    
        color: ${({ theme }) => theme.colors.primary.highlight};
    
        border-bottom: solid ${convertToRem(2)} ${({ theme }) => theme.colors.primary.highlight};
      }
    }
  }
`;

export const OrderingContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  gap: ${convertToRem(20)};

  margin-top: ${convertToRem(48)};

  ${Select} {
    &:nth-of-type(1) {
      width: ${convertToRem(240)};
    }

    &:nth-of-type(2) {
      width: ${convertToRem(120)};
    }
  }

  ${Button} {
    min-width: auto;
    min-height: auto;
    
    height: ${convertToRem(40)};

    padding: 0 ${convertToRem(12)};

    font-size: ${convertToRem(13)};

    > svg {
      margin-left: 0;
      margin-right: ${convertToRem(8)};
    }
  }

  .order {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    
    ${Select} {
      width: ${convertToRem(110)};
    }

    ${Button} {
      display: flex;
      align-items: center;
      justify-content: center;

      width: ${convertToRem(40)};

      margin-left: ${convertToRem(8)};
      padding: 0;

      > svg {
        width: auto;
        height: ${convertToRem(20)};

        margin: 0;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: flex-start;
    gap: ${convertToRem(16)};

    ${Select}, .order {
      width: 100% !important;
    }
  }
`;

export const ResultsContainer = styled.div`
  margin-top: ${convertToRem(30)};

  > ${ResultCard} {   
    > div {
      &:nth-of-type(3) {
        flex: 0.5;
      }
    }

    &:not(:first-child) {
      margin-top: ${convertToRem(10)};
    }
  }

  @media (max-width: 992px) {
    ${ResultCard} {   
      ${ResponsiveCard};
    }
  }
`;

export const Footer = styled.footer`
  width: 100%;
  height: ${convertToRem(100)};

  margin-top: ${convertToRem(60)};

  border-top: solid ${convertToRem(1)} ${({ theme }) => theme.colors.neutral.border};

  .content {
    display: flex;
    align-items: center;
  }
`;

export const Pagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  margin-left: auto;
`;

export const PageButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  width: ${convertToRem(40)};
  height: ${convertToRem(40)};

  background-color: ${({ theme }) => theme.colors.neutral.white};
  color: ${({ theme }) => theme.colors.primary.base};

  border: ${convertToRem(1)} solid ${({ theme }) => theme.colors.neutral.border};
  border-radius: ${convertToRem(5)};

  &.prev {
    transform: rotate(90deg);
  }

  &.next {
    transform: rotate(270deg);
  }
  
  &:disabled {
    background-color: ${({ theme }) => chroma(theme.colors.neutral.border).brighten(0.4).css()};

    cursor: not-allowed;
  }

  > svg {
    width: auto;
    height: ${convertToRem(20)};
    
    stroke-width: 1.2;
  }
`;

export const Page = styled.span`
  margin: 0 ${convertToRem(20)};
  
  color: ${({ theme }) => theme.colors.neutral.text};

  font-weight: 500;
  font-size: ${convertToRem(15)};
  line-height: 1;
`;