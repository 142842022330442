import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

export interface SidebarContextProps {
  isOpened: boolean;
  setIsOpened: Dispatch<SetStateAction<boolean>>;
  activeDropdown: string;
  setActiveDropdown: Dispatch<SetStateAction<string>>;
}

export const SidebarContext = createContext<SidebarContextProps>(
  {} as SidebarContextProps
);

export function useSidebar(): SidebarContextProps {
  const context = useContext(SidebarContext);

  if (!context)
    throw new Error('useSidebar must be used within an SidebarProvider');

  return context;
}

interface SidebarProviderProps {
  children: ReactNode;
}

export function SidebarProvider({ children }: SidebarProviderProps) {
  const [isOpened, setIsOpened] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState('');

  return (
    <SidebarContext.Provider value={{ isOpened, setIsOpened, activeDropdown, setActiveDropdown }}>
      {children}
    </SidebarContext.Provider>
  );
}